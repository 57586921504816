<div class="primary-div" *ngIf="!loading">

    <div class="div-loading" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_loading div_loading" [style.background]="color_primary" role="status" aria-hidden="true"></span>
    </div>

    <div class="question" *ngFor="let question of listImage; let i = index">
        <div class="q-title" [style.color]="color_primary"> {{ question.description }} </div>

        <div *ngIf="question.survey_type == 'SINGLE'" style="width: 100%;">
            <mat-radio-group style="width: 100%; display: flex; flex-direction: column;">
                <mat-radio-button class="q-answer" *ngFor="let answer of question.answers" [value]="answer">
                    {{ answer.title }}
                </mat-radio-button> 
            </mat-radio-group>
        </div>

        <div *ngIf="question.survey_type == 'MULTIPLE'" style="width: 100%;">
            <div *ngFor="let answer of question.answers; let i = index" style="width: 100%;">
                <mat-checkbox [checked]="selection.isSelected(i)" [value]="answer" class="q-answer">
                    {{ answer.title }}
                </mat-checkbox>
            </div>
        </div>

    </div>

</div>