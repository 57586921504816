<main>
    <header>
        <span (click)="back()"> < </span> Informações de pagamento
    </header>

    <div class="card_inputs_coll">
        <!-- <div class="input">
            <span> Bandeira do cartão </span>
            <input type="text" mat-input [(ngModel)]="payment.flag" placeholder="Bandeira do cartão"
                [ngStyle]="{'border-color': warningMap.get('flag')? 'red' : ''}">
        </div> -->

        <mat-form-field class="card_select w_50">
            <label>Bandeira do cartão</label>

            <mat-select class="select-box" [(ngModel)]="payment.flag"
                [ngStyle]="{'border-color': warningMap.get('flag')? 'red' : ''}">
                <mat-option *ngFor="let item of list_brand;" [value]="item.api_name">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="input">
            <span> Nº do cartão </span>
            <input type="text" mat-input [(ngModel)]="payment.number" placeholder="Nº do cartão" mask="0000 0000 0000 0000 0000"
                [ngStyle]="{'border-color': warningMap.get('number')? 'red' : ''}">
        </div>

        <div class="input">
            <span> Nome do títular </span>
            <input type="text" mat-input [(ngModel)]="payment.name_card" placeholder="Nome do títular"
                [ngStyle]="{'border-color': warningMap.get('name_card')? 'red' : ''}">
        </div>

        <div class="input">
            <span> Validade (mm/aaaa) </span>
            <input type="text" mat-input [(ngModel)]="payment.date_card" placeholder="00/0000" mask="00/0000"
                [ngStyle]="{'border-color': warningMap.get('date_card')? 'red' : ''}">
        </div>

        <div class="input">
            <span> Código de segurança </span>
            <input type="text" mat-input [(ngModel)]="payment.code_card" placeholder="000" mask="00000"
                [ngStyle]="{'border-color': warningMap.get('code_card')? 'red' : ''}">
        </div>

        <br>

        <div class="input">
            <span> Endereço </span>
            <input type="text" mat-input [(ngModel)]="payment.address.name" placeholder="Endereço"
                [ngStyle]="{'border-color': warningMap.get('address_name')? 'red' : ''}">
        </div>

        <div class="input">
            <span> Bairro </span>
            <input type="text" mat-input [(ngModel)]="payment.address.district" placeholder="Bairro"
                [ngStyle]="{'border-color': warningMap.get('address_district')? 'red' : ''}">
        </div>

        <div class="input">
            <span> Numero </span>
            <input type="text" mat-input [(ngModel)]="payment.address.number" placeholder="Numero"
                [ngStyle]="{'border-color': warningMap.get('address_number')? 'red' : ''}">
        </div>

        <div class="input">
            <span> CEP </span>
            <input type="text" mat-input [(ngModel)]="payment.address.cep" placeholder="CEP" mask="00000-000"
                [ngStyle]="{'border-color': warningMap.get('address_cep')? 'red' : ''}">
        </div>
    </div>

    <button mat-button class="btn_finish" (click)="checkInput()">
        Finalizar <span *ngIf="loading" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>
    </button>
</main>