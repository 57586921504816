<div class="modal-content">
    <div class="screen_popup">
        <img src="../assets/image/iphone_x.png" class="img-phone">

        <button mat-button class="icon-close b-border" [style.background]="color_primary" (click)="modalRef.hide()">
            Fechar
        </button>
    
        <div class="custom-scrollbar component">
            <!-- <app-redirect [sendList]="campaign"></app-redirect> -->
            <app-redirect-sbs style="position: relative;" [sendList]="campaign" [preview]="true"></app-redirect-sbs>
        </div>
    </div>
</div>