import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  @Input() product: any;
  @Input() purchase: any;

  constructor(
    private storageService: StorageService,
		private _snackBar: MatSnackBar
  ) { }

  loading: boolean = true;
  loading_table: boolean = false;
	qtdItens: number = 0;

  displayedColumns: string[] = ['name', 'qtd', 'value'];


  ngOnInit(): void {
    // console.log(this.product);
    this.qtdItens = this.storageService.shoppingCart.qtd;

    setTimeout(() => {
      this.loading_table = true;
    }, 200);
  }

  backPage() {

  }

  addProduct(product) {
		this.storageService.addProduct(product, 1);
		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.openSnackBar("Produto adicionado ao carrinho!", "Fechar")
	}

  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 4000,
		});
	}
}
