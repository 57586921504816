import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';
import { TagService } from 'src/app/service/tag.service';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith, expand } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { CategoryService } from 'src/app/service/category.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-detail-tag',
    templateUrl: './detail-tag.component.html',
    styleUrls: ['./detail-tag.component.css']
})
export class DetailTagComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private router: Router,
        private storageService: StorageService,
        private tagService: TagService,
        private compressor: CompressorService,
        private categoryService: CategoryService

    ) { }


    displayedColumns: string[] = ['image', 'name', 'view'];
    dataSource = new MatTableDataSource<any>();


    action: Subject<any> = new Subject();

    loading: boolean = false;
    warningMap = new Map();

    title: string = '';
    button_text: string = '';

    tag;
    isEdit

    subtags = [];
    tags = [];

    subtag_response;

    name;
    url_image: string = '';
    name_image: string = ''
    id;

    subcategory: string = '';
    category: string = '';
    list_table = []
    list_delete = []

    compressed_image = []; // as the name says...

    color_primary

    /* pagination related variables */
    page: number = 1
    count_total: number = 0;
    page_count1: number = 0;
    page_count2: number = 0;
    page_size = 10;


    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        if (this.isEdit) {
            this.title = 'Editar Seção';
            this.button_text = 'Editar'
            this.name = this.tag.name;
            this.id = this.tag.id;
            this.url_image = this.tag.image;
            this.list_table = this.tag.sub_tags
            this.dataSource.data = this.tag.sub_tags
        }
        else {
            this.title = 'Nova Seção';
            this.button_text = 'Salvar';
        }
    }

    setTable() {
        this.list_table.push({ name: this.subcategory, id: null })
        this.dataSource.data = this.list_table;
        this.subcategory = '';
    }

    remover(obj, posi) {
        if (obj.id != null) { //trocar id por outra variavel, pra diferenciar um user ja cadastrado de um não cadastrado
            this.list_delete.push(obj)
        }
        this.list_table.splice(posi, 1)
        this.dataSource.data = this.list_table;
    }

    save() {
        this.warningMap = new Map();

        if (this.name == null || this.name == "") {
            this.warningMap.set('name', true);
        }
        if (this.url_image == null || this.url_image == "") {
            this.warningMap.set('image', true);
        }

        if (this.warningMap.size == 0) {
            if (this.id == null)
                this.postTag()
            else
                this.editTag()
        }
    }

    postTag() {
        if (!this.loading) {
            this.loading = true;

            this.tagService.postTag(this.name, this.url_image).subscribe(data => {

                this.list_table.forEach(sub => {
                    this.postSubTag(sub, data.id)
                });

                if (this.list_delete != null && this.list_delete.length > 0) {
                    this.list_delete.forEach(element => {
                        this.deletSubTag(element)
                    });

                    setTimeout(() => {
                        this.router.navigate(['application/section'])
                        this.loading = false;
                        this.action.next('yes');
                        this.modalRef.hide();
                    }, 300);
                }
                else {
                    setTimeout(() => {
                        this.router.navigate(['application/section'])
                        this.loading = false;
                        this.action.next('yes');
                        this.modalRef.hide();
                    }, 300);
                }
            },
                error => {
                    console.log(error)
                    this.loading = false;
                })
        }
    }

    editTag() {
        if (!this.loading) {
            this.loading = true;

            this.tagService.editTag(this.id, this.name, this.url_image).subscribe(data => {

                this.list_table.forEach(sub => {
                    if (sub?.id != null) this.editSubTag(sub)
                    else this.postSubTag(sub, data.id)
                });

                if (this.list_delete != null && this.list_delete.length > 0) {
                    this.list_delete.forEach(element => {
                        this.deletSubTag(element)
                    });

                    setTimeout(() => {
                        this.router.navigate(['application/section'])
                        this.loading = false;
                        this.action.next('yes');
                        this.modalRef.hide();
                    }, 300);
                }
                else {
                    setTimeout(() => {
                        this.router.navigate(['application/section'])
                        this.loading = false;
                        this.action.next('yes');
                        this.modalRef.hide();
                    }, 300);
                }
            },
                error => {
                    console.log(error)
                    this.loading = false;
                })
        }
    }

    postSubTag(sub, id) {
        this.tagService.postSubTag(id, sub).subscribe(data => {

        },
        error => {
            console.log(error)
            this.loading = false;
        })
    }

    editSubTag(sub) {
        this.tagService.editSubTag(sub).subscribe(data => {

        },
        error => {
            console.log(error)
            this.loading = false;
        })
    }

    deletSubTag(sub) {
        this.tagService.deletSubTag(sub).subscribe(data => {

        },
        error => {
            console.log(error)
            this.loading = false;
        })
    }

    close() {
        this.action.next('no');
        this.modalRef.hide();
    }

    deletImage() {
        this.url_image = '';
        this.name_image = '';
    }

    async addImage(event) {

        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {
                    this.name_image = this.compressed_image[0].name
                    this.url_image = this.compressed_image[0].url
                    this.compressed_image = []
                }
            });
        }

        // console.log(this.image_logo)
    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {

                this.compressed_image.push({ url: response, name: array[index].name })

                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );

    }

}
