import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { LikeService } from 'src/app/service/like.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
    @Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    constructor(
        private campaignService: CampaignService,
        private storageService: StorageService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private likeService: LikeService
    ) {
        this.url_screen = router.url;

    }

    url_screen;
    listImage;
    loading: boolean = true;
    url = AppConfig.path;
    type_page: string = '';
	qrcode_id;

    type_page_log: string = '';
    is_liked: boolean = false;

    ngOnInit(): void {
        // console.log("-> Post <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

        this.storageService.screen.format = 'Post';
        this.storageService.screen.id = this.list.id;

        this.campaignService.watchList().subscribe(data => {
            this.listImage = this.tratamento(this.list)
        })

        this.listImage = this.tratamento(this.list)
        
        this.is_liked = this.listImage != null && this.listImage[0]?.is_liked != null ? this.listImage[0].is_liked : false;
    }

    likePost(item){
        this.is_liked = !this.is_liked;

        this.likePage(this.listImage[0].id)
    }

    
    likePage(id){
        if (this.preview_campaign && this.type_campaign != 'feed'){
            if(this.type_page == 'homepage'){
                this.likeService.likeHomepage(id).subscribe(data=> {
                })
            }
            else if(this.type_page == 'page'){
                this.likeService.likePage(id).subscribe(data=> {
                })
            }
            else if(this.type_page == 'internal'){
                this.likeService.likeInternalPage(id).subscribe(data=> {
                })
            }
        }
    }

    async addNewItem(list) {
        if (this.url_screen != '/application/dashboard')
            await this.storageService.saveHistoric(this.campaign_id, list.id, (list.homepage_format || list.page_format), 2, this.type_page_log, this.qrcode_id)

            if (this.preview_campaign && this.type_campaign != 'feed') {
                if (list?.type?.name == "Link") {
                    this.openLink2(list)
                }
                else {
                    this.newItemEvent.emit(list);
                }
            }
            else {
                if (list?.page_format_obj?.name == "Link") {
                    if (list.link != null) {
                        this.openLink(list.link)
                    }
                    else if (list.page_images != null) {
                        this.openLink(list.page_images[0].link)
                    }
                    else if (list.internal_images != null) {
                        this.openLink(list.internal_images[0].link)
                    }
                }
                else if (list?.page_format_obj?.name == "Vazio") {
                    // console.log("-> Vazio <-")
                    return
                }
                else if (list?.page_format_obj?.name != null) {
                    this.newItemEvent.emit(list);
                }

            }

    }

    copyLink() {
        var copyText = document.getElementById("myLink") as HTMLInputElement
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        this.openSnackBar("Link copiado!", "Fechar")
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 6000,
        });
    }

    createLink(){
        return 'https://sbs.tools'+this.url_screen;     
    }

    openLink2(link) {
        if (link != null && link != '') {
            if (link.substring(0, 4) != 'http') {
                window.open(`http://${link}`);
            }
            else {
                window.open(link);
            }
        }
    }

    openLink(link) {
        if (link != null && link != '') {
            if (link.substring(0, 3) == 'tel') {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
            else if (link.substring(0, 6) == 'mailto') {
                window.open(link);
            }
            else if (link.substring(0, 4) != 'http') {
                var aux_link = link.replace(/\s/g, '');
                window.open(`http://${aux_link}`);
            }
            else {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
        }
    }

    tratamento(list) {
        if (this.preview_campaign && this.type_campaign != 'feed') {
            if (this.list?.images != undefined) {
                return list.images;
            }
            else
                return null
        }
        else {
            if (list?.home_images != undefined) {
                this.type_page = 'homepage';
                return list.home_images;
            }
            else if (list?.internal_images != undefined) {
                this.type_page = 'internal';
                return list.internal_images;
            }
            else if (list?.page_images != undefined) {
                this.type_page = 'page';
                return list.page_images;
            }
            else if (this.list?.home_images != undefined) {
                this.type_page = 'page';
                return list.home_images;
            }
            else
                return null
        }

    }
}
