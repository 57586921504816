import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { SpreadsheetPage, SpreadsheetTable, Spreadsheet, SpreadsheetTablePage } from './../model/spreadsheet';

@Injectable({
    providedIn: 'root'
})

export class SpreadsheetService {

    constructor(private http: HttpClient) { }

    getSpreadsheets(establishment, page): Observable<SpreadsheetPage> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`establishment`, establishment)
                .set(`page`, page)
        };

        return this.http.get<SpreadsheetPage>(AppConfig.path + `core/spreadsheet/`, options);
    }

    getSpreadsheetId(id): Observable<Spreadsheet> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<Spreadsheet>(AppConfig.path + `core/spreadsheet/${id}/`, { headers });
    }

    postSpreadsheet(title, type, description_json, establishment): Observable<Spreadsheet> {
        let body = new HttpParams()
            .set(`title`, title)
            .set(`type`, type)
            .set(`description_json`, description_json)
            .set(`establishment`, establishment)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<Spreadsheet>(AppConfig.path + `core/spreadsheet/`, body, { headers })
    }

    patchSpreadsheet(id, title, description_json): Observable<Spreadsheet> {
        let body = new HttpParams()
            .set(`title`, title)
            .set(`description_json`, description_json)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<Spreadsheet>(AppConfig.path + `core/spreadsheet/${id}/`, body, { headers })
    }

    deleteSpreadsheet(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/spreadsheet/${id}/`, { headers })
    }

    getSpreadsheetTable(spreadsheet_id, page): Observable<SpreadsheetTablePage> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`spreadsheet`, spreadsheet_id)
                .set(`page`, page)
        };

        return this.http.get<SpreadsheetTablePage>(AppConfig.path + `core/spreadsheet-table/`, options);
    }

    getSpreadsheetTableId(id): Observable<SpreadsheetTable> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<SpreadsheetTable>(AppConfig.path + `core/spreadsheet-table/${id}/`, { headers });
    }

    postSpreadsheetTable(
        spreadsheet, title, subtitle, description_json, columns_array, rows_array
    ): Observable<SpreadsheetTable> {

        let body = new HttpParams()
            .set(`spreadsheet`, spreadsheet)
            .set(`title`, title)
            .set(`spreadsheet_subtitle`, subtitle)
            .set(`description_json`, description_json)
            .set(`columns_array`, columns_array)
            .set(`rows_array`, rows_array)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<SpreadsheetTable>(AppConfig.path + `core/spreadsheet-table/`, body, { headers })
    }

    patchSpreadsheetTable(
        id, title, subtitle, description_json, rows_array
    ): Observable<SpreadsheetTable> {

        let body = new HttpParams()
            .set(`title`, title)
            .set(`spreadsheet_subtitle`, subtitle)
            .set(`description_json`, description_json)
            .set(`rows_array`, rows_array)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<SpreadsheetTable>(AppConfig.path + `core/spreadsheet-table/${id}/`, body, { headers })
    }

    deleteSpreadsheetTable(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/spreadsheet-table/${id}/`, { headers })
    }

}
