<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Catálogo </h3>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">

        </div>
    </div>

    <div class="card_row" style="justify-content: space-between;">
        <div class="card_image">
            <div class="logo_cardapio">
                <span *ngIf="catalog.logo_menu == null">Logo do catálogo</span>
                <img *ngIf="catalog.logo_menu != null" [src]="catalog.logo_menu"
                    style="width: 100%; height: 100%; object-fit: contain;">
            </div>
            <button mat-button class="button-cor" style="width: 100%; margin: 10px auto;"
                [style.background]="color_primary" (click)="fileLogo.click()">
                Updade
            </button>
            <input #fileLogo accept="image/*" type="file" multiple style="display: none;"
                (change)="onFileLogo($event)" />
        </div>
        <div style="width: calc(95% - 160px);">
            <input mat-input [(ngModel)]="catalog.name" placeholder="Escreva aqui o nome do catálogo"
                [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">

            <textarea mat-input [(ngModel)]="catalog.description" placeholder="Escreva aqui a descrição do catálogo..."
                style="resize: none;" [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}"
                class="input"></textarea>

            <mat-form-field class="select-option" style="width: 100%; margin: 8px 0 8px 0;">
                <mat-select [(ngModel)]="catalog.purchase" placeholder="Botão de adicionar ao carrinho">
                    <mat-option *ngFor="let obj of list_status" [value]="obj.status">
                        {{ obj.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <textarea mat-input [(ngModel)]="catalog.informative_text" placeholder="Escreva aqui um informação..."
        style="resize: none;" class="input"></textarea>

    <input mat-input [(ngModel)]="catalog.link" placeholder="Escreva aqui o link do informação..." class="input">

    <textarea mat-input [(ngModel)]="catalog.shipping_title" placeholder="Escreva aqui o titulo do frete"
        style="resize: none;" class="input"></textarea>

    <textarea mat-input [(ngModel)]="catalog.shippin_description"
        placeholder="Escreva aqui uam descrição do frete" style="resize: none;" class="input"></textarea>

    <textarea mat-input [(ngModel)]="catalog.footer_text" placeholder="Escreva aqui um de footer do catálogo..."
        style="resize: none;" class="input"></textarea>

    <div class="input">
        <div style="margin-bottom: 20px;">Selecione as imagens em destaque:</div>
        <div class="card_row custom-scrollbar">
            <label class="input-image-label" for="input-image">
                +
            </label>
            <div *ngFor="let img of carrouselImages"
                style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
                <div class="image-grid">
                    <img src="../../../assets/icon/icon_excluir.png" class="icons" (click)="removeImage(img)">
                    <img [src]="img.icon">
                </div>
            </div>
        </div>
        <!-- <div id="images-preview" class="img-preview">
        </div> -->
        <input id="input-image" accept="image/*" type="file" multiple
            (change)="changedImagesListener($event.target.files)" />
    </div>

    <div *ngIf="viewSection">
        <div *ngFor="let obj of catalog.section; let i = index" style="margin: 10px 0;">
            <mat-accordion
                style="position: relative; width: 100%; display: flex; align-items: center; justify-content: flex-start;">

                <input mat-input [(ngModel)]="obj.name" placeholder="Escreva aqui o nome da Seção"
                    class="input_expansion input-dark">

                <mat-expansion-panel
                    [ngStyle]="{'border-color': (warningMap.get('name'+i) && !panelOpenState)? 'red' : ''}"
                    [expanded]="indexExpanded == i" style="width: 100%;">

                    <mat-expansion-panel-header (click)="togglePanels(i)"
                        [ngStyle]="{'border-color': warningMap.get('name'+i)? 'red' : ''}">
                        <mat-panel-title>


                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="display: flex; flex-direction: column;">

                        <!-- <div *ngFor="let item of obj.products; let y = index"> -->
                        <div class="div-table">
                            <div *ngIf="!refresh_table" class="card_loading">
                                <span class="spinner-border spinner-border-sm m_loading" role="status"
                                    aria-hidden="true"></span>
                            </div>
                            <table mat-table [dataSource]="obj.products" matSort *ngIf="refresh_table">

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="title-borde" mat-sort-header> Nome </div>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="div-borde"> {{element.name}} </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="view">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="title-borde" mat-sort-header style="max-width: 120px;"> Ações </div>
                                    </th>

                                    <td mat-cell *matCellDef="let element; let y = index">
                                        <div class="div-borde div_row_icons mw_150 p_5">
                                            <div (click)="detailProduct(element, 'edit', i, y)">
                                                <img src="/assets/icon/icon_edit.png" matTooltip="Editar"
                                                    [style.background]="color_primary" class="icon">
                                            </div>

                                            <div (click)="deleteItem(i, y)">
                                                <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir"
                                                    class="icon view-delet">
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado
                                        encontrado. </td>
                                </tr>
                            </table>
                        </div>

                    </div>

                    <div class="div-row">

                        <mat-form-field class="select-option">
                            <mat-select [formControl]="list_viewSection[i].toppings" multiple
                                placeholder="Selecione o horário">
                                <mat-option *ngFor="let hour of list_hours" (click)="selectHour(hour.name, i)"
                                    [value]="hour.name">{{hour.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div>
                            <button mat-button class="button-cor m_auto" [style.background]="color_primary"
                                (click)="detailProduct(null, 'new', i, null)">
                                Adicionar produto
                            </button>

                            <button mat-button class="button-cor m_auto" [style.background]="color_primary"
                                (click)="deleteSectionId(i)">
                                Excluir seção
                            </button>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>


    <div class="div-buttons">
        <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()">
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span>
            Veja como ficou o catálogo
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="addSection()">
            Adicionar Seção
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()">
            {{ catalog_id == null ? 'Finalizar Catálogo' : 'Salvar Catálogo' }}
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status"
                aria-hidden="true"></span>
        </button>
    </div>

</div>