import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith, expand, groupBy } from 'rxjs/operators';
import { ModalPreviewComponent } from 'src/app/modals/modal-preview/modal-preview.component';
import { PopupGenerateFileLinkComponent } from 'src/app/modals/popup-generate-file-link/popup-generate-file-link.component';
import { Campaign, Image_campaign, theItem } from 'src/app/model/campaign';
import { CourseService } from 'src/app/service/course.service';
import { GroupService } from 'src/app/service/group.service';
import { NoticeService } from 'src/app/service/notice.service';
import { StorageService } from 'src/app/service/storage.service';
import { TabloideService } from 'src/app/service/tabloide.service';
import { TagService } from 'src/app/service/tag.service';
import { AppConfig } from '../../app.config';
import { ModalMessageComponent } from '../../modals/modal-message/modal-message.component';
import { CampaignService } from '../../service/campaign.service';
import { CatalogService } from '../../service/catalog.service';
import { CompressorService } from '../../service/compressor.service';
import { ConstantsService } from '../../service/constants.service';
import { CouponService } from '../../service/coupon.service';
import { QrCodeService } from '../../service/qrcode.service';
import { RegistrationService } from '../../service/registration.service';
import { SurveyService } from '../../service/survey.service';

@Component({
    selector: 'app-detail-campaign',
    templateUrl: './detail-campaign.component.html',
    styleUrls: ['./detail-campaign.component.css']
})

export class DetailCampaignComponent implements OnInit {

    constructor(
        private constantsService: ConstantsService,
        private campaignService: CampaignService,
        private compressor: CompressorService,
        private catalogService: CatalogService,
        private router: Router,
        private qrCodeService: QrCodeService,
        private modalService: MDBModalService,
        private surveyService: SurveyService,
        private couponService: CouponService,
        private route: ActivatedRoute,
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private tagService: TagService,
        private groupService: GroupService,
        private courseService: CourseService,
        private noticeService: NoticeService,
        private tabloideService: TabloideService
    ) {
        this.campaign_id = this.route.snapshot.paramMap.get('id');
    }

    @ViewChild('establishmentInput') establishmentInput: ElementRef<HTMLInputElement>;
    @ViewChild('establishmentInputTag') establishmentInputTag: ElementRef<HTMLInputElement>;
    @ViewChild('establishmentInputGroup') establishmentInputGroup: ElementRef<HTMLInputElement>;

    modalRef: MDBModalRef;
    modalImport: MDBModalRef;

    link: string = AppConfig.path;
    warningMap = new Map();
    error = {
        msg: "",
        check: false
    };
    loading = {
        screen: false,
        establishment: false,
        constants: false,
        finish: false
    }

    surveys = [];
    catalogs = [];
    campaigns = [];
    homepage_types = [];
    page_types = [];

    steps = { campaign: false, homepage: false, homepage_images: false, page_types: false, internal_pages: false };
    actual_step: string = "campaign";

    // campaign
    campaign_id = null;
    language: string;
    campaign_name: string = "";
    show_header: boolean = true;
    campaign_header: string = "";
    registration_type: string;
    mobile = { android: false, ios: false };
    color_background: string = "#000000";
    color_text: string = "#ffffff";
    color_icons: string = '#ffffff';
    selected_colors = { bg: false, text: false, icon: false };
    whatsapp: string = '';
    image_logo = { name: null, url: null };
    side_menu = { name: null, url: null };
    pdfTerm;
    pdfTerm_name;
    is_point_control: boolean = false;

    type_choices = [
        { name: 'Entrada', type: "ENTRANCE" },
        { name: 'Saida', type: "EXIT" }
    ]

    // qrcode chips
    qrcodes = [];
    arrayChips = [];
    listQrCode = [];
    deliteQrcode = [];
    status_qr: boolean = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    qrcodeChipsFormControl = new FormControl();
    filteredQrcodes: Observable<string[]>;
    id_qrcode;


    // group chips
    groups = [];
    arrayGroup = [];
    listGroup = [];
    separatorKeysCodesGroup: number[] = [ENTER, COMMA];
    groupChipsFormControl = new FormControl();
    filteredGroups: Observable<string[]>;
    id_group;


    // tag chips
    tags = [];
    list_tag_campaign = { homepage: [], page: [], internal: [] }

    tagArrayChipsHomepage = [];
    tagArrayChipsPage = [];
    tagArrayChipsInternal = [];
    listTag = { home: [], page: [], internal: [] };

    deliteTag = { homepage: [], page: [], internal: [] };
    separatorKeysCodesTag: number[] = [ENTER, COMMA];
    tagChipsFormControl = new FormControl();

    filteredTagHomepage: Observable<string[]>;
    filteredTagPage: Observable<string[]>;
    filteredTagInternal: Observable<string[]>;

    // coupons
    list_coupon = [{ id: -1, name: 'Nenhum cupom' }];
    coupon;
    id_coupon;

    // course
    list_course = []

    list_notice = []

    list_tabloide = [{ id: -1, name: 'Nenhum tabloide' }]


    // configuração
    list_config = [{ id: -1, title: 'Nenhuma configuração' }];
    config;
    id_config;

    selected_homepage_type; // homepage type object
    links = {}; // object for inputs of links/videos
    compressed_image = []; // as the name says...

    input_facebook: string = '';
    input_instagram: string = '';
    input_youtube: string = '';

    campaign_select;

    homepage: Image_campaign = {
        images: [],
    } as Image_campaign// everything

    homepage_id;
    page_id;

    cont = {
        home: 0,
        page: 0
    }

    option_link = [
        { name: 'Link', link: '', exem: 'Insira um link' },
        { name: 'WhatsApp', link: 'https://wa.me/', exem: '+00 (00) 0 0000 0000' },
        { name: 'Telefone', link: 'tel:', exem: '00 0000 0000' },
        { name: 'Email', link: 'mailto:', exem: 'Insira seu email' },
        { name: 'Relatório', link: 'sbs', exem: 'Insira seu email' }
    ]
    option_select_link;

    remove = {
        homepage: [],
        page: [],
        internal: []
    }

    page = {
        survey: 1,
        coupon: 1,
        qrcode: 1,
        tag: 1,
        group: 1,
        course: 1
    }

    // segmentation (device/group)
    accesses = []

    select_anterior;
    select_type_anterior;
    selected_homepage_image: number = 0; //var for index of homepage image selected in pages formats
    selected_page_image: number = 0; //var for index of page image selected in pages formats

    id_establishment = null;

    color_primary;

    view_level_page: boolean = false;

    validTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']

    point_1 = 0;

    async ngOnInit() {
        this.loading.screen = true;
        this.whatsapp = '55'
        this.color_primary = this.storageService.establishment.color;

        this.option_select_link = this.option_link[0]

        this.id_establishment = localStorage.getItem('id_establishment')

        this.language = 'PT'
        this.registration_type = 'HOMEPAGE'
        this.config = this.list_config[0].id

        if (this.campaign_id != null)
            this.getCampaignId()

        await this.getConstants()
        await this.getCatalogs(1)
        await this.getCampaigns(1)
        await this.getCoupons()
        await this.getRegistration(1)
        await this.getNotices(1)
        await this.getTabloide(1)
        await this.getQrCode(this.page.qrcode)
        await this.getTags(this.page.tag)
        await this.getGroups(this.page.group)
        await this.getSurveys()
        await this.getCouser(this.page.course)
    }

    getCampaignId() {
        this.campaignService.getCampaignId(this.campaign_id).subscribe(data => {
            // console.log('AFTER Campaign', data)

            this.campaign_name = data.name;
            this.campaign_header = data.header_text;

            if (data.qr_codes != null) {
                this.status_qr = true;
                data.qr_codes.forEach(qr => {
                    this.arrayChips.push(qr.qr_code_obj[0])
                });
            }

            if (data.accesses != null) {
                this.accesses = data.accesses;
                data.accesses.forEach(access => {
                    if (access.device != null) {
                        if (access.device == 1) {
                            this.mobile.ios = true
                        }
                        else if (access.device == 2) {
                            this.mobile.android = true
                        }
                    }
                    if (access.group != null) {
                        this.arrayGroup.push(access.group_obj)
                        this.listGroup.push(access)
                    }
                });
            }

            this.listQrCode = data.qr_codes;
            this.coupon = data?.coupon_obj != null ? data.coupon_obj : null;
            this.id_coupon = data?.coupon_obj?.id != null ? data.coupon_obj.id : null;
            this.registration_type = data.request_registration;
            this.color_background = data.header_color;
            this.color_text = data.text_color;
            this.color_icons = data.icons_color;
            this.selected_colors.bg = data.header_color != null ? true : false;
            this.selected_colors.text = data.text_color != null ? true : false;
            this.selected_colors.icon = data.icons_color != null ? true : false;
            this.whatsapp = (data.whatsapp != null && data.whatsapp != '') ? data.whatsapp : '+55';
            this.selected_homepage_type = data.homepage_format_obj;
            this.homepage.type = data.homepage_format_obj;
            this.homepage.link = (data?.homepage_format_obj?.name != null && data.homepage_format_obj.name == 'Vídeo' && data.home_images != null && data?.home_images[0]?.page_images != null) ? data?.home_images[0]?.page_images[0]?.link : null;
            this.homepage.background_image = { url: null }
            this.homepage.background_image.url = (data?.homepage_format_obj?.name != null && data.homepage_format_obj.name == 'Vídeo') ? (data.home_images != null ? data.home_images[0].image : null) : null;
            this.homepage.id = data.id;
            this.id_qrcode = data?.qr_codes != null ? data?.qr_codes[0]?.qr_code : null;
            this.image_logo.url = data?.logo != null ? data?.logo : '';
            this.side_menu.url = data?.side_menu_image != null ? data?.side_menu_image : '';
            this.input_facebook = data.facebook;
            this.input_instagram = data.instagram;
            this.input_youtube = data.youtube;
            this.campaign_select = data.campaing;
            this.show_header = data.show_header;
            this.is_point_control = data.is_point_control;

            if (data.show_header && data.term != null) {
                this.pdfTerm = data.term;
                let aux_term = data.terms.split('images/');
                this.pdfTerm_name = aux_term[1];
            }

            if (data?.home_images != null && data.home_images.length > 0) {
                data.home_images = data.home_images.sort(this.ordenarList)

                data.home_images.forEach((home, index) => { //home_images[n]

                    if (home?.image != null || home?.page_format_obj != null) {
                        this.getTag('homepage', home.id, 1)

                        this.homepage.images.push(this.storageService.setImageCampaignHome(home, data))

                        if (home.page_images != null && home.page_images.length > 0) {

                            home.page_images = home.page_images.sort(this.ordenarList)

                            home.page_images.forEach((page, index2) => {    //page_images[n]

                                if (this.homepage.images[this.cont.home].images == null) {
                                    this.homepage.images[this.cont.home].images = [];
                                }

                                this.getTag('page', page.id, 1)

                                this.homepage.images[this.cont.home].images.push(this.storageService.setImageCampaignHomepage(page, data))

                                if (page.page_format_obj.name == 'Banner' || page.page_format_obj.name == 'Mini Banner' || page.page_format_obj.name == 'Post' ||
                                    page.page_format_obj.name == 'Galeria' || page.page_format_obj.name == "Vídeo" || page.page_format_obj.name == "Cadastro") {

                                    if (page.internal_images != null && page.internal_images.length > 0) {

                                        page.internal_images = page.internal_images.sort(this.ordenarList)

                                        page.internal_images.forEach((internal, index3) => {    //internal_images[n]

                                            if (this.homepage?.images[this.cont.home]?.images[this.cont.page] != null) {

                                                this.getTag('internal', internal.id, 1)

                                                if (this.homepage.images[this.cont.home].images[this.cont.page].images == null)
                                                    this.homepage.images[this.cont.home].images[this.cont.page].images = []

                                                this.homepage.images[this.cont.home].images[this.cont.page].images.push(this.storageService.setImageCampaignInternal(internal, page))
                                            }

                                        });
                                    }
                                }
                                else if (page.page_format_obj.name == 'Ponto') {

                                    let aux_internal = {
                                        id: null, type: page.page_format_obj, quantity: null, link: '', option_link: '', placeholder: '', catalog: null, coupon: null, registration_config: null, background_image: { url: '' }, images: null, order: null, title: '', point_control_name: '', description: '', name: '', url: '', survey: null, video: '', save: false, course: '', notice: '', level: null, point_type: '', castbox: null, deezer: null, itunes: null, google_podcast: null, course_obj: null, token_hashlar: null,
                                    }

                                    var auxObj = Object.assign({}, aux_internal)
                                    var auxObj2 = Object.assign({}, aux_internal)

                                    if (page.internal_images != null && page.internal_images.length > 0) {

                                        console.log(1, page.internal_images.length);

                                        page.internal_images = page.internal_images.sort(this.ordenarList)

                                        page.internal_images.forEach((internal, index3) => {    //internal_images[n]

                                            if (this.homepage?.images[this.cont.home]?.images[this.cont.page] != null) {

                                                this.getTag('internal', internal.id, 1)

                                                if (this.homepage.images[this.cont.home].images[this.cont.page].images == null)
                                                    this.homepage.images[this.cont.home].images[this.cont.page].images = []

                                                this.homepage.images[this.cont.home].images[this.cont.page].images.push(this.storageService.setImageCampaignInternal(internal, page))

                                                if (page.internal_images.length == 1) {
                                                    console.log(5);

                                                    this.homepage.images[this.cont.home].images[this.cont.page].images.push(auxObj)
                                                }
                                            }

                                        });
                                    }
                                    else {
                                        console.log(2);

                                        this.homepage.images[this.cont.home].images[this.cont.page].images = []

                                        this.homepage.images[this.cont.home].images[this.cont.page].images.push(auxObj)
                                        this.homepage.images[this.cont.home].images[this.cont.page].images.push(auxObj2)

                                    }

                                }

                                this.cont.page += 1;
                            });

                            this.cont.page = 0;
                        }
                        this.cont.home += 1;

                    }

                });
            }
            else {
                this.homepage.images.push({} as Image_campaign)
            }
            this.loading.screen = false;
            console.log('BEFORE Campaign', this.homepage)

            setTimeout(() => {
                this.chechListTags()
            }, 1000);
        })
    }


    getSurveys() {
        this.surveyService.getSurvey_EstablishmentId(this.id_establishment, this.page.survey).subscribe(data => {
            this.surveys = this.surveys.concat(data.results);

            if (data.next != null) {
                this.page.survey += 1;
                this.getSurveys()
            }
        })
    }

    getCouser(page) {
        this.courseService.getCourse(page).subscribe(data => {
            // console.log(data)
            this.list_course = this.list_course.concat(data.results)

            if (data.next != null) {
                this.page.course += 1
                this.getCouser(this.page.course)
            }

        }, error => {
            console.log(error)
        })
    }

    getCampaigns(page) {
        this.campaignService.getCampaigns(page).subscribe(data => {
            this.campaigns = this.campaigns.concat(data.results);

            if (data.next != null) {
                this.getCampaigns(page + 1)
            }
            else {
                // console.log(this.campaigns)
            }
        })
    }

    getCatalogs(page) {
        this.catalogService.getCatalogs(page).subscribe(data => {
            this.catalogs = this.catalogs.concat(data.results);

            if (data.next != null) {
                this.getCatalogs(page + 1)
            }
        })
    }

    onFilePdf(event) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onloadend = (e) => {
                this.pdfTerm = reader.result
                this.pdfTerm_name = event?.target?.files[0]?.name;

            }
        }

    }

    openPdf(link) {
        if (link != null) {
            // if(link.substring(0,4) != 'http'){
            //     window.open(`${link}`);
            // }
            // else{
            var aux_link = link.replace(/\s/g, '');
            window.open(aux_link, '_blanck');
            // }
        }
    }

    getConstants() {
        this.loading.constants = true;

        this.constantsService.getConstants().subscribe(data => {
            this.homepage_types = data.homepage_types;
            this.page_types = data.page_types;

            // hotfix Lara
            data.homepage_types = data.homepage_types.splice(AppConfig.qtd_homepage_types)
            data.page_types = data.page_types.splice(AppConfig.qtd_page_types)

            // var position_1 = this.homepage_types.findIndex(el => el.id == 7)
            // this.homepage_types.splice(position_1, 1)

            this.loading.constants = false;

        },
            error => {
                this.loading.constants = false;
            })
    }

    getCoupons() {
        this.couponService.getCoupons(this.page.coupon).subscribe(data => {
            data.results.forEach(el => {
                this.list_coupon.push(el)
            });

            if (data.next != null) {
                this.page.coupon += 1;
                this.getCoupons()
            }
            // this.list_coupon = data.results;
        })
    }

    getRegistration(page) {
        this.registrationService.getRegistration(page).subscribe(data => {

            this.list_config = this.list_config.concat(data.results)
            if (data.next != null) {
                this.getRegistration(page + 1)
            }
        })
    }

    getNotices(page) {
        this.noticeService.getNotices(page).subscribe(data => {

            if (this.list_notice.length == 0)
                this.list_notice.push({ id: -1, name: 'Nenhum notícia' })

            this.list_notice = this.list_notice.concat(data.results)
            if (data.next != null) {
                this.getNotices(page + 1)
            }
        })
    }

    getTabloide(page) {
        this.tabloideService.getTabloide(page).subscribe(data => {
            this.list_tabloide = this.list_tabloide.concat(data.results);
            if (data.next != null) {
                this.getTabloide(page + 1)
            }
        })
    }

    nameImage(text) {
        if (text == null)
            return ''
        else {
            var list;
            if (text.name != null)
                return text.name
            else if (text.url != null) {
                list = text.url.split('/')
                return list[list.length - 1]
            }
            else
                return ''
        }
    }

    nextStep() {
        console.log(this.select_type_anterior)

        switch (this.actual_step) {
            case 'campaign':

                this.checkCampaign()
                break;
            case 'homepage':

                this.homepage.images = this.homepage.images.sort(this.ordenarList)

                // console.log(this.homepage.type.name)

                if (this.select_type_anterior != null &&
                    (this.select_type_anterior.name == 'Mini Banner' || this.select_type_anterior.name == 'Galeria' || this.select_type_anterior.name == 'Ponto') &&
                    (this.homepage.type.name == 'Banner' || this.homepage.type.name == 'Vídeo')) {

                    this.alertType(this.select_type_anterior, this.homepage.type, this.homepage.images, this.actual_step)
                }
                else {
                    this.steps.homepage = true;
                    this.homepage.type = this.selected_homepage_type
                    this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
                    this.actual_step = "homepage_images";
                }

                if (this.homepage.type.name == 'Post' || this.homepage.type.name == 'Banner') {
                    this.homepage.images.forEach((item, index_loop) => {
                        if (index_loop != 0) this.remove.homepage.push(item)
                    });
                    this.homepage.images.splice(1)

                    if (this.homepage.type.name == 'Post' && this.homepage?.images[0]?.images != null && this.homepage.images[0].images.length > 0) {
                        this.homepage.images[0].images.forEach(item => {
                            this.remove.page.push(item)
                        });
                        this.homepage.images[0].images = []
                    }
                }
                else if (this.homepage.type.name == 'Ponto') {

                    if (this.homepage.images != null && this.homepage.images.length > 0) {
                        this.homepage.images.forEach((home, index) => {
                            // home.title = '';
                            // home.description = '';
                            // home.home_page_image_type = '';

                            if (index == 0 && home.point_type == 'EXIT') {
                                this.homepage.images.reverse()
                            }
                        });
                    }
                }

                break;
            case 'homepage_images':

                if (this.homepage.images[this.selected_homepage_image].option_link == 'tel:') {
                    this.option_select_link = this.option_link[2]
                }
                else if (this.homepage.images[this.selected_homepage_image].option_link == 'mailto:') {
                    this.option_select_link = this.option_link[3]
                }
                else if (this.homepage.images[this.selected_homepage_image].option_link == '') {
                    this.option_select_link = this.option_link[0]
                }
                else
                    this.option_select_link = this.option_link[1]

                this.checkHomepageImages()
                break;
            case 'page_types':
                this.option_select_link = this.option_link[0]
                break;
        }

    }

    ordenarList(a, b) {
        if (a.order < b.order)
            return -1;
        if (a.order > b.order)
            return 1;
        return 0;
    }

    checkCampaign() {
        this.warningMap = new Map();
        if (this.campaign_name == "") {
            this.warningMap.set('campaign_name', true);
        }
        if (this.show_header && this.campaign_header == "") {
            this.warningMap.set('campaign_header', true);
        }
        if (!this.selected_colors.bg) {
            this.warningMap.set('bg_color', true)
        }
        if (!this.selected_colors.text) {
            this.warningMap.set('text_color', true)
        }
        // if(this.arrayChips.length <= 0){
        //     this.warningMap.set('qrcode', true)
        // }
        // if(this.coupon == null || this.coupon == ""){
        //     this.warningMap.set('coupon', true)
        // }
        // if(this.arrayGroup.length <= 0){
        //     this.warningMap.set('group', true)
        // }

        if (this.warningMap.size == 0) {
            this.steps.campaign = true
            this.actual_step = "homepage"
        }
    }

    checkHomepageImages() {

        this.warningMap = new Map();
        if (this.homepage.quantity == null) {
            if (this.homepage.link == null || this.homepage.link == "") {
                this.warningMap.set('homepage_link', true);
            }
            if (this.homepage.background_image == null || this.homepage.background_image.url == "") {
                this.warningMap.set('homepage_background', true);
            }
        }
        else if (this.homepage.images.length > this.homepage.quantity) {
            this.warningMap.set('homepage_images', true);
        }

        if (this.warningMap.size === 0) {
            this.steps.homepage_images = true
            this.actual_step = this.homepage.quantity == null ? null : 'page_types'
        }
    }

    getQrCode(page) {
        this.qrCodeService.getQrCodes(page).subscribe(data => {
            this.qrcodes = this.qrcodes.concat(data.results)

            if (data.next != null) {
                this.page.qrcode += 1
                this.getQrCode(this.page.qrcode)
            }
            else {
                this.filteredQrcodes = this.qrcodeChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter(item) : this.qrcodes.slice()));
            }

        })
    }

    private _filter(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.qrcodes.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    removeQrCodeChips(qrcode: string): void {
        const index = this.arrayChips.indexOf(qrcode);

        if (index >= 0) {
            this.deliteQrcode.push(this.listQrCode[index])
            this.arrayChips.splice(index, 1);
            this.listQrCode.splice(index, 1);
        }
    }

    qrcodeChipsSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.arrayChips.includes(event.option.value.name)) {

            this.arrayChips.push(event.option.value);

            if (this.listQrCode == null || this.listQrCode.length == 0) {
                this.listQrCode = []
                // this.listQrCode.push({ qr_code_obj: [] })
            }
            // var size = this.listQrCode.length != 0 ? this.listQrCode.length - 1 : 0
            // this.listQrCode[size].qr_code_obj.push(event.option.value);
            let aux = event.option.value
            this.listQrCode.push({ campaign: Number(this.campaign_id), campaign_obj: null, id: null, qr_code: aux.id, qr_code_obj: [aux] });
        }

        // console.log(this.listQrCode)

        this.establishmentInput.nativeElement.value = '';
        this.qrcodeChipsFormControl.setValue(null);
    }


    getGroups(page) {
        this.groupService.getGroups(page, null).subscribe(data => {
            this.groups = this.groups.concat(data.results)

            if (data.next != null) {
                this.page.group += 1
                this.getGroups(this.page.group)
            }
            else {
                this.filteredGroups = this.groupChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter3(item) : this.groups.slice()));
            }
        })
    }

    private _filter3(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.groups.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    removeGroupChips(group): void {
        const index = this.arrayGroup.indexOf(group);

        if (index >= 0) {
            this.arrayGroup.splice(index, 1);
        }
    }

    groupChipsSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.arrayGroup.includes(event.option.value.name)) {

            this.arrayGroup.push(event.option.value);

            // if (this.listGroup == null || this.listGroup.length == 0) {
            //     this.listGroup = []
            // }

            // let campaign_access_obj = 
            // this.listGroup.push(event.option.value);
        }

        this.establishmentInputGroup.nativeElement.value = '';
        this.groupChipsFormControl.setValue(null);
    }


    getTags(page) {
        this.tagService.getAllTags(page).subscribe(data => {
            this.tags = this.tags.concat(data.results)

            if (data.next != null) {
                this.page.tag += 1
                this.getTags(this.page.tag)
            }
            else {
                this.filteredTagHomepage = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

                this.filteredTagPage = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

                this.filteredTagInternal = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
                // this.filteredTag = this.tagChipsFormControl.valueChanges.pipe(
                //     startWith(null),
                //     map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
            }
        },
            error => {
            })
    }

    private _filter2(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.tags.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    chechListTags() {
        let filter_obj = [];

        // console.log(this.list_tag_campaign);

        this.list_tag_campaign.homepage.forEach(obj => {
            // console.log(obj);

            if (obj.home_page != null) {

                filter_obj = this.listTag.home.filter(el => el.id == obj.tag_obj.id);
                // console.log('1', filter_obj);

                if (filter_obj == null || filter_obj.length == 0) {
                    this.listTag.home.push({ id: obj.id, obj: obj.tag_obj })
                    this.tagArrayChipsHomepage.push(obj.tag_obj)
                }
            }
        });

        this.list_tag_campaign.page.forEach(obj => {
            // console.log(obj);

            if (obj.page != null) {

                filter_obj = this.listTag.home.filter(el => el.id == obj.tag_obj.id);
                // console.log('2', filter_obj);

                if (filter_obj == null || filter_obj.length == 0) {
                    this.listTag.page.push({ id: obj.id, obj: obj.tag_obj })
                    this.tagArrayChipsPage.push(obj.tag_obj)
                }
            }
        });

        this.list_tag_campaign.internal.forEach(obj => {
            // console.log(obj);

            if (obj.internal_page != null) {
                filter_obj = this.listTag.home.filter(el => el.id == obj.tag_obj.id);
                // console.log('3', filter_obj);

                if (filter_obj == null || filter_obj.length == 0) {
                    this.listTag.internal.push({ id: obj.id, obj: obj.tag_obj })
                    this.tagArrayChipsInternal.push(obj.tag_obj)
                }
            }
        });

        // console.log(this.listTag)
    }

    removeTagChips(tag: string, type): void {
        const indexH = this.tagArrayChipsHomepage.indexOf(tag);
        const indexP = this.tagArrayChipsPage.indexOf(tag);
        const indexI = this.tagArrayChipsInternal.indexOf(tag);

        if (type == 'home') {
            if (indexH >= 0) {
                this.deliteTag.homepage.push(this.listTag.home[indexH])
                this.tagArrayChipsHomepage.splice(indexH, 1);
                this.listTag.home.splice(indexH, 1);
            }
        }
        else if (type == 'page') {
            if (indexP >= 0) {
                this.deliteTag.page.push(this.listTag.page[indexP])
                this.tagArrayChipsPage.splice(indexP, 1);
                this.listTag.page.splice(indexP, 1);
            }
        }
        else if (type == 'internal') {
            if (indexI >= 0) {
                this.deliteTag.internal.push(this.listTag.internal[indexI])
                this.tagArrayChipsInternal.splice(indexI, 1);
                this.listTag.internal.splice(indexI, 1);
            }
        }
    }

    tagChipsSelectedTag(event: MatAutocompleteSelectedEvent, type): void {
        if (!this.tagArrayChipsHomepage.includes(event.option.value.name)) {

            if (type == 'home') {
                if (this.listTag.home == null || this.listTag.home.length == 0)
                    this.listTag.home = []

                this.tagArrayChipsHomepage.push(event.option.value);
                this.listTag.home.push({ id: null, obj: event.option.value });
            }
            else if (type == 'page') {
                if (this.listTag.page == null || this.listTag.page.length == 0)
                    this.listTag.page = []

                this.tagArrayChipsPage.push(event.option.value);
                this.listTag.page.push({ id: null, obj: event.option.value });
            }
            else if (type == 'internal') {
                if (this.listTag.internal == null || this.listTag.internal.length == 0)
                    this.listTag.internal = []

                this.tagArrayChipsInternal.push(event.option.value);
                this.listTag.internal.push({ id: null, obj: event.option.value });
            }
        }

        console.log(this.listTag);

        this.establishmentInputTag.nativeElement.value = '';
        this.tagChipsFormControl.setValue(null);
    }

    newLevel() {
        this.homepage.images[this.selected_homepage_image]['level'] = true;
        this.view_level_page = true;
    }

    drop(event: CdkDragDrop<any>, array, type) {
        moveItemInArray(array, event.previousIndex, event.currentIndex);

        if (type == 'homepage') {
            this.homepage.images = []
            array.forEach((element, index) => {
                element.order = index + 1
                this.homepage.images.push(element)
            });
        }
        else if (type == 'page') {
            this.homepage.images[this.selected_homepage_image].images = []
            array.forEach((element, index) => {
                element.order = index + 1
                this.homepage.images[this.selected_homepage_image].images.push(element)
            });
        }
        else if (type == 'internal') {
            // this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
            // array.forEach((element, index) => {
            //     element.order = index +1
            //     this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(element)
            // });
        }

    }

    getImagesQuantity(item): number {
        // console.log(item)

        if (item?.name != null) {
            if (item.name == "Banner")
                return 1;
            else if (item.name == "Mini Banner")
                return 20;
            else if (item.name == "Galeria")
                return 20;
            else if (item.name == "Post")
                return 1;
            else if (item.name == "Ponto")
                return 2;
            else
                return null;
        }
        else
            return null;
    }

    removeImage(posi, type) {

        if (type == 'homepage') {

            if (this.homepage.images[posi].id != null)
                this.remove.homepage.push(this.homepage.images[posi])

            this.homepage.images.splice(posi, 1);
        }
        else if (type == 'page') {

            if (this.homepage.images[this.selected_homepage_image].images[posi].id != null)
                this.remove.page.push(this.homepage.images[this.selected_homepage_image].images[posi])

            this.homepage.images[this.selected_homepage_image].images.splice(posi, 1);

            if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image] == null)
                this.selected_page_image = this.homepage.images[this.selected_homepage_image].images.length - 1;


            if (this.homepage.images[this.selected_homepage_image].images.length === 0) {
                this.homepage.images[this.selected_homepage_image].type = {
                    description: null, id: 6, image: "https://qrcontent-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
                    max_images: 0, min_images: 0, name: "Vazio"
                }

                this.selectType(this.homepage.images[this.selected_homepage_image].type, type)
            }
        }
        else if (type == 'internal') {
            if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi].id != null)
                this.remove.internal.push(this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi])

            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(posi, 1);

        }

    }

    getValieLink(item, type) {
        if (type == 'home') {
            if (item.name == 'Relatório') {
                this.option_select_link = item;
                this.homepage.images[this.selected_homepage_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
            }
            else {
                this.option_select_link = item;
            }
        }
        else if (type == 'page') {
            if (item.name == 'Relatório') {
                this.option_select_link = item;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
            }
            else {
                this.option_select_link = item;
            }
        }
    }

    createLink(type, link, option, obj) {
        var link
        var break_aux = [];

        if (type?.name == 'Link') {
            if (obj.option_link == 'sbs') {
                break_aux = link.split('.');
                return `sbs.${break_aux[1]}`
            }
            else {
                return option + link
            }
        }

        else if (type?.name == 'Post')
            return obj.images[0].link
        else
            return link

    }

    checkLink(link, type) {

        if (link != null) {
            if (link.substring(0, 3) == 'sbs') {
                if (type == 'link') return link
                else if (type == 'exem') return this.option_link[4].exem
                else return this.option_link[4].link
            }
            else if (link.substring(0, 3) == 'tel') {
                var link = link.split(':')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[2].exem
                else return this.option_link[2].link

            }
            else if (link.substring(0, 6) == 'mailto') {
                var link = link.split(':')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[3].exem
                else return this.option_link[3].link
            }
            else if (link.substring(0, 14) == 'https://wa.me/') {
                var link = link.split('me/')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[1].exem
                else return this.option_link[1].link
            }
            else {
                if (type == 'link') return link
                else if (type == 'exem') return this.option_link[0].exem
                else return this.option_link[0].link
            }
        }

    }

    deletLogo() {
        this.image_logo.name = '';
        this.image_logo.url = '';
    }

    deletSide() {
        this.side_menu.name = '';
        this.side_menu.url = '';
    }

    deletImageCkeckIn(type) {
        if (type == 'chckin') {
            this.homepage.images[0]['name'] = '';
            this.homepage.images[0].url = '';
        }
        else {
            this.homepage.images[1]['name'] = '';
            this.homepage.images[1].url = '';
        }
    }

    async addImageCkeck(event, type_check, type_obj, index_home, index_page) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    if (type_obj == 'home') {
                        if (type_check == 'checkin') {
                            this.homepage.images[0]['name'] = filename;
                            this.homepage.images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[1]['name'] = filename;
                            this.homepage.images[1].url = final_file;
                        }
                    }
                    else if (type_obj == 'page') {
                        if (type_check == 'checkin') {
                            this.homepage.images[index_home].images[0]['name'] = filename;
                            this.homepage.images[index_home].images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[index_home].images[1]['name'] = filename;
                            this.homepage.images[index_home].images[1].url = final_file;
                        }
                    }
                    else if (type_obj == 'internal') {
                        if (type_check == 'checkin') {
                            this.homepage.images[index_home].images[index_page].images[0]['name'] = filename;
                            this.homepage.images[index_home].images[index_page].images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[index_home].images[index_page].images[1]['name'] = filename;
                            this.homepage.images[index_home].images[index_page].images[1].url = final_file;
                        }
                    }
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        if (type_obj == 'home') {
                            if (type_check == 'checkin') {
                                this.homepage.images[0]['name'] = this.compressed_image[0].name;
                                this.homepage.images[0].url = this.compressed_image[0].url;
                            }
                            else {
                                this.homepage.images[1]['name'] = this.compressed_image[0].name;
                                this.homepage.images[1].url = this.compressed_image[0].url;
                            }
                        }
                        else if (type_obj == 'page') {
                            if (type_check == 'checkin') {
                                this.homepage.images[index_home].images[0]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[0].url = this.compressed_image[0].url;;
                            }
                            else {
                                this.homepage.images[index_home].images[1]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[1].url = this.compressed_image[0].url;;
                            }
                        }
                        else if (type_obj == 'internal') {
                            if (type_check == 'checkin') {
                                this.homepage.images[index_home].images[index_page].images[0]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[index_page].images[0].url = this.compressed_image[0].url;;
                            }
                            else {
                                this.homepage.images[index_home].images[index_page].images[1]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[index_page].images[1].url = this.compressed_image[0].url;;
                            }
                        }

                        this.compressed_image = []
                    }
                });
            }
        }

        // console.log('-> ',this.homepage)
    }


    async addImageSide(event) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])

                // subscribe file reader read event
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    this.side_menu.name = filename
                    this.side_menu.url = final_file

                    this.compressed_image = []
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {

                        this.side_menu.name = this.compressed_image[0].name
                        this.side_menu.url = this.compressed_image[0].url
                        this.compressed_image = []
                    }
                });
            }
        }

        // console.log(this.image_logo)
    }

    async addLogo(event) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])

                // subscribe file reader read event
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    this.image_logo.url = final_file

                    this.compressed_image = []
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompressLogo(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompressLogo(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        this.image_logo.url = this.compressed_image[0]
                        this.compressed_image = []

                    }
                });
            }
        }
    }

    async addImageCheck(event, position) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])

                // subscribe file reader read event
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    this.image_logo.url = final_file

                    this.compressed_image = []
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompressLogo(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompressLogo(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        this.image_logo.url = this.compressed_image[0]
                        this.compressed_image = []

                    }
                });
            }
        }
    }

    recursiveCompressLogo = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {
                this.compressed_image.push(response)
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );
    }

    openGerateLinkFile() {
        console.log('import file');

        this.modalImport = this.modalService.show(PopupGenerateFileLinkComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered',
            containerClass: '',
            animated: false,
            data: {
                color_primary: this.color_primary
            }
        })
        // this.modalImport.content.action.subscribe((result: any) => {
        //   if (result == 'yes') {

        //   }
        // });
    }

    async editImage(event, position_home, position_page, position_internal, type) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])

                // subscribe file reader read event
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    if (type == 'home') {
                        this.homepage.images[position_home].name = filename;
                        this.homepage.images[position_home].url = final_file;
                    }
                    else if (type == 'internal') {
                        this.homepage.images[position_home].images[position_page].images[position_internal].name = filename
                        this.homepage.images[position_home].images[position_page].images[position_internal].url = final_file
                    }

                    this.compressed_image = []
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        if (type == 'home') {
                            this.homepage.images[position_home].name = this.compressed_image[0].name
                            this.homepage.images[position_home].url = this.compressed_image[0].url
                        }
                        else if (type == 'internal') {
                            this.homepage.images[position_home].images[position_page].images[position_internal].name = this.compressed_image[0].name
                            this.homepage.images[position_home].images[position_page].images[position_internal].url = this.compressed_image[0].url
                        }

                        this.compressed_image = []
                    }
                });

                // console.log(compress);

            }

        }

    }

    async onFileChangedGif(event, type, index = null) {

        var aux_type = {
            description: null, id: 6, image: "https://qrcontent-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
            max_images: 0, min_images: 0, name: "Vazio"
        }

        var aux_id = null

        if (event.target.files && event.target.files[0]) {
            const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

            if (!event.target.files[0].name.includes(valid[0])) {
                return
            }

            var file = event.target.files[0];
            var filename = file.name;
            var target = event.target.files;

            var reader = new FileReader()
            reader.readAsDataURL(target[0])

            // subscribe file reader read event
            reader.onloadend = async (e) => {

                var final_file = reader.result
                this.compressed_image.push({ id: aux_id, url: final_file, name: filename, quantity: null, type: aux_type, link: null, images: [{ url: null, title: '', description: '', link: '' }] })

                let obj = null
                if (index != null) {
                    obj = this.homepage.images[index]
                }

                let now_homepage = this.homepage.images.length
                let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

                if (type == 'homepage_bg') {
                    this.homepage.background_image = this.compressed_image[0]
                }
                else if (type == 'homepage_post') {
                    this.homepage.images[this.selected_homepage_image].url = this.compressed_image[0].url
                }
                else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
                    this.compressed_image.forEach(el => {
                        this.homepage.images.push(el)
                    })
                }
                else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
                    if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
                    this.compressed_image.forEach(el => {
                        this.homepage.images[index].images.push(el)
                    })
                }
                else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
                    this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
                }
                else if (type == 'page_bg') {
                    obj.background_image = this.compressed_image[0]
                }
                else if (type == 'internalpage_bg') {
                    this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
                }
                else if (type == 'internal_image') {
                    let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images
                    if (obj2 == null)
                        this.homepage.images[this.selected_homepage_image].images[index].images = []

                    if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
                        this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
                        this.compressed_image.forEach(el => {
                            this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
                        })
                    }
                    else {
                        this.homepage.images[this.selected_homepage_image].images[index].images = this.compressed_image;
                    }
                }
                else if (type == 'post_page') {
                    this.homepage.images[this.selected_homepage_image].images[0].url = this.compressed_image[0]?.url
                }
                else if (type == 'post_internal') {
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
                }

                this.compressed_image = []
            }

        }
    }

    async onFileChanged(event, type, index = null) {
        if (event.target.files[0].type == 'image/gif') {
            this.onFileChangedGif(event, type, index)
        }
        else {
            this.onFileChangedImg(event, type, index)
        }
    }

    async onFileChangedImg(event, type, index = null) {

        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {

                    let obj = null
                    if (index != null) {
                        obj = this.homepage.images[index]
                    }

                    let now_homepage = this.homepage.images.length

                    let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

                    if (type == 'homepage_bg') {
                        this.homepage.background_image = this.compressed_image[0]
                    }
                    else if (type == 'homepage_post') {
                        this.homepage.images[this.selected_homepage_image].url = this.compressed_image[0].url
                    }
                    else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
                        this.compressed_image.forEach(el => {
                            this.homepage.images.push(el)
                        })
                    }
                    else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
                        if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
                        this.compressed_image.forEach(el => {
                            this.homepage.images[index].images.push(el)
                        })
                    }
                    else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
                        this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
                    }
                    else if (type == 'page_bg') {
                        obj.background_image = this.compressed_image[0]
                    }
                    else if (type == 'internalpage_bg') {
                        this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
                    }
                    else if (type == 'internal_image') {
                        let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images

                        if (obj2 == null)
                            this.homepage.images[this.selected_homepage_image].images[index].images = []


                        if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
                            this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
                            this.compressed_image.forEach(el => {
                                this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
                            })
                        }
                        else {
                            this.homepage.images[this.selected_homepage_image].images[index].images = this.compressed_image;
                        }
                    }
                    else if (type == 'post_page') {
                        this.homepage.images[this.selected_homepage_image].images[0].url = this.compressed_image[0]?.url
                    }
                    else if (type == 'post_internal') {
                        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
                    }

                    this.compressed_image = []
                }
            });
        }

    }

    recursiveCompress = (image: File, index, array) => {

        var aux_type = {
            description: null, id: 6, image: "https://qrcontent-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
            max_images: 0, min_images: 0, name: "Vazio"
        }

        return this.compressor.compress(image).pipe(
            map(response => {
                if (this.remove.homepage.length >= 1) {
                    // this.compressed_image.push(this.remove.homepage[index].url)
                    // var aux_id = this.remove?.homepage[index]?.id != null ? this.remove.homepage[index].id : null
                    var aux_id = null

                    this.compressed_image.push({ id: aux_id, url: response, name: array[index].name, quantity: null, type: aux_type, link: null, images: [{ url: null, title: '', description: '', link: '' }] })
                }
                else
                    this.compressed_image.push({ url: response, name: array[index].name, quantity: null, type: aux_type, link: null, images: [{ url: null, title: '', description: '', link: '' }] })
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );

    }

    openView() {
        this.homepage['name'] = this.campaign_name;
        this.homepage['header_text'] = this.campaign_header;
        this.homepage['header_color'] = this.color_background;
        this.homepage['text_color'] = this.color_text;
        this.homepage['whatsapp'] = this.whatsapp;
        this.homepage['show_header'] = this.show_header;

        this.modalRef = this.modalService.show(ModalPreviewComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                isCampaign: this.homepage,
            }
        })
    }

    // openCampain(){
    // 	this.router.navigate(['/redirect/'+this.id_qrcode])
    // }

    selectType(item, type_page) {

        var obj_aux: Image_campaign = {
            point_type: 'EMPTY'
        } as Image_campaign;

        var auxObj = Object.assign({}, obj_aux)
        var auxObj2 = Object.assign({}, obj_aux)

        this.select_anterior = this.homepage?.images[this.selected_homepage_image]?.type?.name;

        if (this.select_anterior != null &&
            (this.select_anterior != 'Banner' && this.select_anterior != 'Mini Banner' && this.select_anterior != 'Galeria')) {

            if (type_page == 'page') { }
            // this.homepage.images[this.selected_homepage_image].images = [];
            else if (type_page == 'internal')
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];

            this.select_anterior = item.name;
        }

        if (type_page == 'homepage') {

            this.select_type_anterior = this.homepage?.type

            this.selected_homepage_type = item;
            this.homepage.type = item;
            if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Vídeo" && item.name != "Tabloide" && item.name != "Post" && item.name != "Podcast" && item.name != "Cursos" && item.name != "Ponto" && item.name != "Notícia") {
                this.homepage.images = [];
                this.homepage.background_image = null;
                this.homepage.catalog = null;
                this.homepage.coupon = null;
                this.homepage.registration_config = null;
                this.homepage.survey = null;
                this.homepage.link = null;
            }
            else if (item.name == "Vídeo") {
                var aux_image = this.homepage.images[0]
                this.homepage.background_image = { url: aux_image.url };
                this.homepage.type.id = 4;
            }
            else if (item.name == "Tabloide") {
                this.homepage.images = []
                this.homepage.images.push(auxObj)
            }
            else if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images == null || this.homepage.images.length == 0) {

                    this.homepage.images = []

                    this.homepage.images.push(auxObj)
                    this.homepage.images.push(auxObj2)

                    this.homepage.images[0].point_type = "ENTRANCE";
                    this.homepage.images[1].point_type = "EXIT";
                }
                else if (this.homepage.images != null || this.homepage.images.length == 1) {
                    this.homepage.images.push(auxObj)
                    this.homepage.images[0].point_type = "ENTRANCE";
                    this.homepage.images[1].point_type = "EXIT";
                }
            }
            else if (item.name == "Notícia") {
                this.homepage.images = []
                this.homepage.images.push(auxObj)
            }
            // else if(item.name == "Podcast"){
            //     var aux_image = this.homepage.images[0]
            //     this.homepage.background_image = {url: aux_image.url}; 
            //     this.homepage.type.id = 4;
            // }

        }
        else if (type_page == 'page') {
            // console.log(type_page)

            this.homepage.images[this.selected_homepage_image].type = item;
            if (item.name == "Vazio") {
                if (this.homepage.images[this.selected_homepage_image].images != null &&
                    this.homepage.images[this.selected_homepage_image].images.length > 0) {

                    this.homepage.images[this.selected_homepage_image].images.forEach(element => {
                        this.remove.page.push(element)
                    });
                }

            }
            else if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Post" && item.name != "Podcast" && item.name != "Cursos" && item.name != "Ponto") {
                // this.homepage.images[this.selected_homepage_image].images = [];
                this.homepage.images[this.selected_homepage_image].background_image = null;
                this.homepage.images[this.selected_homepage_image].catalog = null;
                this.homepage.images[this.selected_homepage_image].coupon = null;
                this.homepage.images[this.selected_homepage_image].registration_config = null;
                this.homepage.images[this.selected_homepage_image].survey = null;
                this.homepage.images[this.selected_homepage_image].link = null;
                this.homepage.images[this.selected_homepage_image].level = null;
            }
            else if (item.name == "Banner" &&
                this.homepage.images[this.selected_homepage_image].images != null &&
                this.homepage.images[this.selected_homepage_image].images.length > 1) {

                var aux_image = this.homepage.images[this.selected_homepage_image].images[0]
                this.homepage.images[this.selected_homepage_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[0] = aux_image;

                this.homepage.images[this.selected_homepage_image].images.forEach((element, index) => {
                    if (index != 0) this.remove.page.push(element)
                });
            }
            else if ((item.name == "Banner" || item.name == "Mini Banner" || item.name == "Galeria") &&
                (this.homepage?.images[this.selected_homepage_image]?.images != null && this.homepage?.images[this.selected_homepage_image]?.images.length > 0)) {
                this.homepage.images[this.selected_homepage_image].images.forEach((element, index) => {
                    if (element.url == null && element?.background_image?.url == null) {
                        this.remove.page.push(element)
                        this.homepage.images[this.selected_homepage_image].images.splice(index, 1)
                    }
                });
            }
            if (item.name == "Post") {
                if (this.homepage.images[this.selected_homepage_image].images == null) {

                    this.homepage.images[this.selected_homepage_image].images = [obj_aux]

                }

            }
            if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images[this.selected_homepage_image] == null || this.homepage.images[this.selected_homepage_image].images.length == 0) {
                    this.homepage.images[this.selected_homepage_image].images = []

                    this.homepage.images[this.selected_homepage_image].images.push(auxObj)
                    this.homepage.images[this.selected_homepage_image].images.push(auxObj2)

                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }
                else if (this.homepage.images[this.selected_homepage_image] != null || this.homepage.images[this.selected_homepage_image].images.length == 1) {
                    this.homepage.images[this.selected_homepage_image].images.push(auxObj)
                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }
            }

        }
        else if (type_page == 'internal') {
            // console.log(type_page)

            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].type = item;
            if (item.name != "Vazio") {
                if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null &&
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 0) {

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach(element => {
                        // this.remove.internal.push(element)
                    });
                }

            }
            else if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Podcast" && item.name != "Post" && item.name != "Cursos" && item.name != "Ponto") {
                // this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].background_image = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].catalog = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].coupon = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].registration_config = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].survey = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].level = null;
            }
            else if (item.name == "Banner" &&
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null &&
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 1) {
                var aux_image = this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0]
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0] = aux_image;
            }
            else if ((item.name == "Banner" || item.name == "Mini Banner" || item.name == "Galeria") &&
                (this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images != null &&
                    this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images.length > 0)) {
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach((element, index) => {
                    if (element.url == null && element.background_image.url == null) {
                        this.remove.internal.push(element)
                        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(index, 1)
                    }
                });
            }
            if (item.name == "Post" || item.name == "Podcast") {
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)
            }
            if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images[this.selected_homepage_image] == null ||
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images == null ||
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 0) {

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj2)

                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }
                else if (this.homepage.images[this.selected_homepage_image] != null || this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 1) {
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
                }
            }

        }

    }

    async finish() {
        if (!this.loading.finish) {
            this.loading.finish = true;

            await this.patchCampaign()
        }
    }

    deletes() {
        // console.log(this.remove)

        if (this.remove.homepage.length > 0) {
            this.remove.homepage.forEach(el => {
                // console.log('Delet home',el)
                if (el.id != null) this.deleteHomepageImage(el.id)
            });
        }

        if (this.remove.page.length > 0) {
            this.remove.page.forEach(el => {
                // console.log('Delet page',el)
                if (el.id != null) this.deletePageImage(el.id)
            });
        }

        if (this.remove.internal.length > 0) {
            this.remove.internal.forEach(el => {
                // console.log('Delet inter',el)
                if (el.id != null) this.deleteInternalImage(el.id)
            });
        }

        setTimeout(() => {
            this.loading.finish = false;
            // console.log('\nDELET - FINISH\n')
            this.router.navigate(['/application/content'])
        }, 100);
    }

    deleteTagsList() {
        // console.log(this.deliteTag)

        this.deliteTag.homepage.forEach(tag => {
            if (tag.id != null) {
                this.deleteTag('homepage', tag.id)
            }
        });

        this.deliteTag.page.forEach(tag => {
            if (tag.id != null) {
                this.deleteTag('page', tag.id)
            }
        });

        this.deliteTag.internal.forEach(tag => {
            if (tag.id != null) {
                this.deleteTag('internal', tag.id)
            }
        });
    }

    checkSegmentationDevice(device_id, value) {
        let devices = this.accesses.filter(el => el.device == device_id)
        if (value && devices.length <= 0) {
            this.campaignService.postCampaignAccess(this.campaign_id, device_id, null).subscribe(
                // data => console.log('ok'),
                error => console.log('error')
            )
        }
        else if (!value && devices.length > 0) {
            this.campaignService.deleteCampaignAccess(devices[0].id).subscribe(
                // data => console.log('ok'),
                error => console.log('error')
            )
        }
    }

    patchCampaign() {
        this.campaignService.patchCampaign(this.campaign_id, this.campaign_name, this.show_header,
            this.campaign_header, this.registration_type, this.color_background,
            this.color_text, this.color_icons, this.pdfTerm, this.homepage.type.id,
            (this.coupon?.id != null ? this.coupon?.id : null), this.whatsapp,
            this.image_logo.url, this.input_facebook, this.input_instagram,
            this.input_youtube, this.side_menu.url, this.is_point_control).subscribe(data => {

                if (this.arrayChips.length != 0) {
                    this.listQrCode.forEach((qrcode, index) => {
                        if (qrcode.id == null) {
                            this.qrCodeService.postQrCodeCampaign(data.id, qrcode.qr_code).subscribe(data => {
                                // success
                            })
                        }
                    })
                }

                if (this.campaign_select != null) {
                    // console.log(this.campaign_select)

                    this.campaignService.postNextCampaign(this.campaign_id, this.campaign_select).subscribe(data => {
                        // console.log(data)
                    })
                }

                if (this.deliteQrcode.length > 0) {
                    this.deliteQrcode.forEach(qrcode => {
                        if (qrcode.id != null) {
                            this.qrCodeService.deleteQrCodeCampaign(qrcode.id).subscribe(data => {
                                // success
                            })
                        }
                    });
                }

                this.checkSegmentationDevice(1, this.mobile.ios)
                this.checkSegmentationDevice(2, this.mobile.android)

                this.accesses.filter(el => el.group != null).forEach(obj => {
                    this.campaignService.deleteCampaignAccess(obj.id).subscribe(
                        // data => console.log('ok'),
                        error => console.log('error')
                    )
                    //console.log('del ', obj.id)
                })

                this.arrayGroup.forEach(el => {
                    this.campaignService.postCampaignAccess(this.campaign_id, null, el.id).subscribe(
                        // data => console.log('ok'),
                        error => console.log('error')
                    )
                    //console.log('add ', el.id)
                })

                this.deleteTagsList()

                if (this.homepage.type.name == 'Vídeo' && (this.homepage.images != null || this.homepage.background_image != null)) {

                    this.homepage.images.forEach((item, index_loop) => {
                        if (index_loop != 0) this.remove.homepage.push(item)
                    });

                    if (this.homepage.images == null || this.homepage.images.length == 0 || this.homepage.images[0].id == null) {

                        // console.log('New Home', 0)
                        this.campaignService.postHomepageImages(this.homepage.background_image?.url, this.campaign_id, null, 1,
                            4, null, null, null, '', this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY', null, null, null, null, null).subscribe(data => {

                                var home_id = data.id;
                                this.listTag.home.forEach(tag => {
                                    if (tag?.id == null) {
                                        this.crieteTag('homepage', home_id, tag.obj.id)
                                    }
                                });

                                var link;
                                if (this.homepage.link != null)
                                    link = this.homepage.link;
                                else if (this.homepage?.images[0]?.link != null)
                                    link = this.homepage?.images[0]?.link;
                                else if (this.homepage.images[0]?.images[0].link != null)
                                    link = this.homepage.images[0]?.images[0].link

                                if (this.homepage?.id != null && (this.homepage.images != null || this.homepage.background_image != null)) {
                                    // console.log('Edit Page', this.homepage?.images[0]?.images[0]?.id)
                                    console.log('1--', this.homepage?.images[0]);

                                    this.campaignService.patchPageImages(this.homepage?.images[0]?.id, null, this.homepage.background_image?.url, 1, data.id,
                                        4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course, null, null, null, null, null).subscribe(data => {

                                            var page_id = data.id;
                                            this.listTag.page.forEach(tag => {
                                                if (tag?.id == null) {
                                                    console.log(tag);
                                                    this.crieteTag('page', page_id, tag.obj.id)
                                                }
                                            });
                                        });
                                }
                                else {
                                    // console.log('New Page', 0)
                                    this.campaignService.postPageImages(this.homepage.background_image?.url, null, 1, data.id,
                                        4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course, null, null, null, null, null).subscribe(data => {

                                            var page_id = data.id;
                                            this.listTag.page.forEach(tag => {
                                                if (tag?.id == null) {
                                                    this.crieteTag('page', page_id, tag.obj.id)
                                                }
                                            });
                                        });
                                }

                            });
                    }
                    else {
                        // console.log('Edit Home', this.homepage.images[0].id)

                        this.campaignService.patchHomepageImages(this.homepage.images[0].id, null, this.homepage.background_image?.url, 1,
                            4, null, null, '', this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
                            this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {

                                this.listTag.home.forEach(tag => {
                                    if (tag?.id == null) {
                                        console.log(tag);
                                        this.crieteTag('homepage', data.id, tag.obj.id)
                                    }
                                });

                                var link;
                                if (this.homepage.link != null)
                                    link = this.homepage.link;
                                else if (this.homepage?.images[0]?.link != null)
                                    link = this.homepage?.images[0]?.link;
                                else if (this.homepage.images[0]?.images[0].link != null)
                                    link = this.homepage.images[0]?.images[0].link

                                if (this.homepage?.images != null && this.homepage?.images[0]?.images != null && this.homepage?.images[0]?.images[0]?.id != null) {
                                    // console.log('Edit Page', this.homepage?.images[0]?.images[0]?.id)

                                    console.log('2--', this.homepage?.images[0]);

                                    this.campaignService.patchPageImages(this.homepage?.images[0]?.images[0]?.id, null, this.homepage.background_image?.url, 1, data.id,
                                        4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.images[0]?.course,
                                        this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.token_hashlar).subscribe(data => {

                                            var page_id = data.id;
                                            this.listTag.page.forEach(tag => {
                                                if (tag?.id == null) {
                                                    console.log(tag);
                                                    this.crieteTag('page', page_id, tag.obj.id)
                                                }
                                            });
                                        });
                                }
                                else {
                                    // console.log('New Page', 0)

                                    this.campaignService.postPageImages(this.homepage.background_image?.url, null, 1, data.id,
                                        4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course,
                                        this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.token_hashlar).subscribe(data => {

                                            var page_id = data.id;
                                            this.listTag.page.forEach(tag => {
                                                if (tag?.id == null) {
                                                    console.log(tag);
                                                    this.crieteTag('page', page_id, tag.obj.id)
                                                }
                                            });
                                        });
                                }

                            });
                    }

                }
                else if (this.homepage.type.name == 'Post') {
                    // console.log(this.homepage)

                    this.homepage.images.forEach((item, index_loop) => {
                        if (index_loop != 0) this.remove.homepage.push(item)
                    });

                    if (this.homepage?.images[0]?.images != null && this.homepage?.images[0]?.images.length > 0) {
                        this.homepage.images[0].images.forEach(item => {
                            this.remove.page.push(item)
                        });
                    }

                    // console.log(this.remove)

                    if (this.homepage.images == null || this.homepage.images.length == 0 || this.homepage.images[0].id == null) {

                        // console.log('New Home', this.homepage.images[0])
                        this.campaignService.postHomepageImages(this.homepage.images[0]?.url, this.campaign_id, null, 1,
                            4, this.homepage.images[0].title, null, this.homepage.images[0].description, this.homepage.images[0].link, this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
                            this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {

                                var home_id = data.id;
                                this.listTag.home.forEach(tag => {
                                    if (tag?.id == null) {
                                        console.log(tag);
                                        this.crieteTag('homepage', home_id, tag.obj.id)
                                    }
                                });
                            });
                    }
                    else {
                        // console.log('Edit Home', this.homepage.images[0])

                        this.campaignService.patchHomepageImages(this.homepage.images[0].id, null, this.homepage.images[0]?.url, 1,
                            4, this.homepage.images[0].title, this.homepage.images[0].description, this.homepage.images[0].link, this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
                            this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {

                                var home_id = data.id;
                                this.listTag.home.forEach(tag => {
                                    if (tag?.id == null) {
                                        console.log(tag);
                                        this.crieteTag('homepage', home_id, tag.obj.id)
                                    }
                                });
                            });
                    }

                }
                else {
                    this.sendHomepage(this.homepage.images, 0)
                }

            })

        setTimeout(() => {
            this.loading.finish = false;
            // this.router.navigate(['/application/content'])
            this.deletes()
        }, 500);

    }

    sendHomepage(homepage_list, index) {

        console.log(homepage_list[index]);

        if (homepage_list[index] == null) {

            this.loading.finish = false;
            // console.log('\nFINISH\n')

        }
        else {
            if (homepage_list[index]?.type?.name == 'Vídeo') var image = homepage_list[index]?.background_image?.url;
            else var image = homepage_list[index]?.url;

            if (homepage_list[index]?.id == null) {

                if (this.homepage.type.name == 'Ponto') {
                    // console.log('-> Ponto <-')

                    this.campaignService.postHomepageImages(homepage_list[index]?.url, this.campaign_id, null, index + 1,
                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                        homepage_list[index]?.title, null, homepage_list[index]?.description, homepage_list[index]?.link, null, null, homepage_list[index]?.home_page_image_type,
                        homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.tabloide).subscribe(data => {

                            this.sendHomepage(homepage_list, index + 1)
                        })
                }
                else {

                    // console.log('New Home', index)
                    this.campaignService.postHomepageImages(image, this.campaign_id, null, 1,
                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6), null, null, null, null, homepage_list[index]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
                        this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {
                            this.homepage_id = data.id;

                            var home_id = data.id;
                            this.listTag.home.forEach(tag => {
                                if (tag?.id == null) {
                                    console.log(tag);
                                    this.crieteTag('homepage', home_id, tag.obj.id)
                                }
                            });

                            if (homepage_list[index]?.type?.name == 'Catálogo' || homepage_list[index]?.type?.name == 'Enquete' || homepage_list[index]?.type?.name == 'Podcast' ||
                                homepage_list[index]?.type?.name == 'Link' || homepage_list[index]?.type?.name == 'Cupom' || homepage_list[index]?.type?.name == 'Vídeo') {

                                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                                    console.log('3--', homepage_list[index]);

                                    this.patchPageImages(homepage_list[index].images[0]?.id, homepage_list[index]?.url, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)
                                }
                                else {
                                    this.postPageImages(image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                                }

                            }
                            else if (homepage_list[index]?.type?.name == 'Cadastro' && (homepage_list[index].level == null || !homepage_list[index].level)) {

                                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                                    console.log('4--', homepage_list[index]);

                                    this.patchPageImages(homepage_list[index].images[0]?.id, homepage_list[index]?.url, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)
                                }
                                else {
                                    this.postPageImages(image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                                }

                            }
                            else {
                                this.sendPage(homepage_list, index, homepage_list[index].images, 0)
                            }
                        });
                }

            }
            else {
                if (this.homepage.type.name == 'Ponto') {
                    // console.log('-> Ponto <-')

                    this.campaignService.patchHomepageImages(homepage_list[index].id, null, homepage_list[index]?.url, index + 1,
                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                        this.homepage.images[index].title, this.homepage.images[index].description, this.homepage.images[index].link, this.homepage.images[index].course, this.homepage?.images[0]?.notice, homepage_list[index]?.home_page_image_type,
                        this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {
                            // console.log('->', data)

                            this.sendHomepage(homepage_list, index + 1)
                        })
                }
                else {

                    // console.log('Edit Home', homepage_list[index].id)
                    this.campaignService.patchHomepageImages(homepage_list[index].id, null, homepage_list[index]?.url, index + 1,
                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                        this.homepage.images[0].title, this.homepage.images[0].description, this.homepage.images[0].link, this.homepage.images[0].course, this.homepage?.images[0]?.notice, 'EMPTY',
                        this.homepage?.images[0]?.castbox, this.homepage?.images[0]?.deezer, this.homepage?.images[0]?.itunes, this.homepage?.images[0]?.google_podcast, this.homepage?.images[0]?.tabloide).subscribe(data => {
                            this.homepage_id = data.id;

                            var home_id = data.id;
                            this.listTag.home.forEach(tag => {
                                if (tag?.id == null) {
                                    console.log(tag);
                                    this.crieteTag('homepage', home_id, tag.obj.id)
                                }
                            });


                            if (homepage_list[index]?.type?.name == 'Catálogo' || homepage_list[index]?.type?.name == 'Enquete' || homepage_list[index]?.type?.name == 'Podcast' ||
                                homepage_list[index]?.type?.name == 'Link' || homepage_list[index]?.type?.name == 'Cupom' || homepage_list[index]?.type?.name == 'Vídeo' || homepage_list[index]?.type?.name == 'Hashlar') {

                                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                                    console.log('5--', homepage_list[index]);

                                    this.patchPageImages(homepage_list[index].images[0]?.id, image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.token_hashlar)
                                }
                                else {
                                    console.log('6--', homepage_list[index]);

                                    this.postPageImages(image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                                }

                            }
                            else if (homepage_list[index]?.type?.name == 'Cadastro' && (homepage_list[index].level == null || !homepage_list[index].level)) {

                                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                                    this.patchPageImages(homepage_list[index].images[0]?.id, image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)
                                }
                                else {
                                    this.postPageImages(image, index + 1, this.homepage_id,
                                        (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                                        this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                                        homepage_list[index]?.catalog, homepage_list[index]?.survey,
                                        homepage_list[index]?.registration_config,
                                        homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                                        homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                                        homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                                }

                            }
                            else {
                                this.sendPage(homepage_list, index, homepage_list[index].images, 0)
                            }
                        });
                }
            }

        }

    }

    sendPage(homepage_list, homepage_index, page_list, index) {

        if (page_list == null || page_list[index] == null) {
            this.sendHomepage(homepage_list, homepage_index + 1)
        }
        else {
            var image_homepage = page_list[index]?.url;

            if (homepage_list[homepage_index]?.type?.name == 'Cadastro') {
                if (page_list[index]?.id == null) {
                    // console.log('New Page', page_list[index])
                    this.campaignService.postPageImages(image_homepage, null, index + 1, this.homepage_id,
                        (page_list[index]?.type != null ? page_list[index]?.type?.id : 6),
                        this.createLink(page_list[index].type, page_list[index].link, page_list[index].option_link, page_list[index]),
                        page_list[index]?.catalog, page_list[index]?.survey, homepage_list[homepage_index]?.registration_config,
                        page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
                        page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

                            var page_id = data.id;
                            this.listTag.page.forEach(tag => {
                                if (tag?.id == null) {
                                    console.log(tag);
                                    this.crieteTag('page', page_id, tag.obj.id)
                                }
                            });

                            this.page_id = data.id;
                            this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
                        })

                }
                else {
                    this.campaignService.patchPageImages(page_list[index].id, null, image_homepage, index + 1, this.homepage_id,
                        (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), page_list[index]?.link,
                        page_list[index]?.catalog, page_list[index]?.survey, homepage_list[homepage_index]?.registration_config,
                        page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
                        page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

                            var page_id = data.id;
                            this.listTag.page.forEach(tag => {
                                if (tag?.id == null) {
                                    console.log(tag);
                                    this.crieteTag('page', page_id, tag.obj.id)
                                }
                            });

                            this.page_id = data.id;
                            this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);

                        })

                }
            }
            else {
                console.log('Edit Page', page_list[index])
                if (page_list[index]?.id == null) {
                    // console.log('New Page', page_list[index])
                    this.campaignService.postPageImages(image_homepage, null, index + 1, this.homepage_id,
                        (page_list[index]?.type != null ? page_list[index]?.type?.id : 6),
                        this.createLink(page_list[index].type, page_list[index].link, page_list[index].option_link, page_list[index]),
                        page_list[index]?.catalog, page_list[index]?.survey, page_list[index]?.registration_config,
                        page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
                        page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

                            var page_id = data.id;
                            this.listTag.page.forEach(tag => {
                                if (tag?.id == null) {
                                    this.crieteTag('page', page_id, tag.obj.id)
                                }
                            });

                            this.page_id = data.id;
                            this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
                        })

                }
                else {
                    this.campaignService.patchPageImages(page_list[index].id, null, image_homepage, index + 1, this.homepage_id,
                        (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), page_list[index]?.link,
                        page_list[index]?.catalog, page_list[index]?.survey, page_list[index]?.registration_config,
                        page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
                        page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

                            var page_id = data.id;
                            this.listTag.page.forEach(tag => {
                                if (tag?.id == null) {
                                    console.log(tag);
                                    this.crieteTag('page', page_id, tag.obj.id)
                                }
                            });

                            this.page_id = data.id;
                            this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);

                        })

                }
            }

        }
    }

    sendInternal(homepage_list, homepage_index, page_list, page_index, internal_list, index) {

        if (internal_list == null || internal_list[index] == null) {
            this.sendPage(homepage_list, homepage_index,
                page_list, page_index + 1)
        }
        else {
            if (page_list[index]?.type?.name == 'Vídeo') var image_page = page_list[index]?.background_image?.url;
            else var image_page = internal_list[index]?.url;


            if (internal_list[index]?.id == null) {

                this.postInternalPageImages(image_page, index + 1, this.page_id,
                    (internal_list[index]?.type != null ? internal_list[index]?.type?.id : 6),
                    internal_list[index]?.link, internal_list[index]?.catalog, internal_list[index]?.survey,
                    internal_list[index]?.registration_config, internal_list[index]?.coupun, internal_list[index]?.title, internal_list[0]?.point_control_name,
                    internal_list[index]?.description, homepage_list, homepage_index, page_list, page_index, internal_list, internal_list[index]?.point_type, internal_list[index]?.course, index,
                    internal_list[index]?.castbox, internal_list[index]?.deezer, internal_list[index]?.itunes, internal_list[index]?.google_podcast, internal_list[index]?.token_hashlar)
            }
            else {

                this.patchInternalPageImages(internal_list[index]?.id, image_page, index + 1, this.page_id,
                    (internal_list[index]?.type != null ? internal_list[index]?.type?.id : 6),
                    internal_list[index]?.link, internal_list[index]?.catalog, internal_list[index]?.survey,
                    internal_list[index]?.registration_config, internal_list[index]?.coupun, internal_list[index]?.title, internal_list[0]?.point_control_name,
                    internal_list[index]?.description, homepage_list, homepage_index, page_list, page_index, internal_list, internal_list[index]?.point_type, internal_list[index]?.course, index,
                    internal_list[index]?.castbox, internal_list[index]?.deezer, internal_list[index]?.itunes, internal_list[index]?.google_podcast, internal_list[index]?.token_hashlar)
            }

        }

    }

    postPageImages(image, order, homepage_id, type_id, link, catalog, survey, registration_config, coupun,
        title, point_control_name, description, homepage_list, homepage_index, page_list, point_type, course, index,
        castbox, deezer, itunes, google_podcast, token_hashlar) {

        this.campaignService.postPageImages(image, null, order, homepage_id,
            type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
            castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                var page_id = data.id;
                this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                        console.log(tag);
                        this.crieteTag('page', page_id, tag.obj.id)
                    }
                });

                this.sendPage(homepage_list, homepage_index, page_list, index + 1)

            }, error => {
                this.loading.finish = false;
            })
    }

    patchPageImages(id, image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
        title, point_control_name, description, homepage_list, homepage_index, page_list, point_type, course, index,
        castbox, deezer, itunes, google_podcast, token_hashlar) {
        // console.log('Edit Page', page_list[index])

        console.log('-- >>>>>>', token_hashlar);

        this.campaignService.patchPageImages(id, null, image, order, page_id,
            type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
            castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                var page_id = data.id;
                this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                        console.log(tag);
                        this.crieteTag('page', page_id, tag.obj.id)
                    }
                });


                this.sendPage(homepage_list, homepage_index, page_list, index + 1)

            }, error => {
                this.loading.finish = false;
            })
    }

    postInternalPageImages(image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
        title, point_control_name, description, homepage_list, homepage_index, page_list, page_index, internal_list, point_type, course, index,
        castbox, deezer, itunes, google_podcast, token_hashlar) {

        this.campaignService.postInternalPageImages(image, null, order, page_id,
            type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
            castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                var internal_id = data.id;
                this.listTag.internal.forEach(tag => {
                    if (tag?.id == null) {
                        this.crieteTag('internal', internal_id, tag.obj.id)
                    }
                });

                this.sendInternal(homepage_list, homepage_index, page_list, page_index,
                    internal_list, index + 1)

            }, error => {
                this.loading.finish = false;
            })
    }

    patchInternalPageImages(id, image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
        title, point_control_name, description, homepage_list, homepage_index, page_list, page_index, internal_list, point_type, course, index,
        castbox, deezer, itunes, google_podcast, token_hashlar) {

        this.campaignService.patchInternalPageImages(id, null,
            (internal_list[index].type.name == 'Post' ? internal_list[index].url : image), order, page_id,
            type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
            castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                var internal_id = data.id;
                this.listTag.internal.forEach(tag => {
                    if (tag?.id == null) {
                        console.log(tag);

                        this.crieteTag('internal', internal_id, tag.obj.id)
                    }
                });


                this.sendInternal(homepage_list, homepage_index, page_list, page_index,
                    internal_list, index + 1)

            }, error => {
                this.loading.finish = false;
            })
    }


    // DELETE
    deleteHomepageImage(id) {
        if (id != null) {
            // console.log('Delet Home', id)

            this.campaignService.deleteHomepageImage(id).subscribe(data => {
                // console.log('DELET')
            }, error => {
                this.loading.finish = false;
            })
        }
    }
    deletePageImage(id) {
        if (id != null) {
            // console.log('Delet Page', id)

            this.campaignService.deletePageImage(id).subscribe(data => {
            }, error => {
                this.loading.finish = false;
            })
        }
    }
    deleteInternalImage(id) {
        if (id != null) {
            // console.log('Delet Internal', id)

            this.campaignService.deleteInternalImage(id).subscribe(data => {
            }, error => {
                this.loading.finish = false;
            })
        }
    }

    crieteTag(typeTag, id, tag) {
        switch (typeTag) {
            case 'homepage':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(id, null, null, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })
                break;

            case 'page':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(null, id, null, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })

                // this.tagService.postTagPage(id, tag).subscribe(data => {
                //     // console.log('Page', data)
                // }, error => { console.log(error) })
                break;

            case 'internal':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(null, null, id, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })

                // this.tagService.postTagInternalPage(id, tag).subscribe(data => {
                //     // console.log('InternalPage', data)
                // }, error => { console.log(error) })
                break;
        }
    }

    deleteTag(typeTag, id) {
        switch (typeTag) {
            case 'homepage':
                this.tagService.deleteTagHomepage(id).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })
                break;

            case 'page':
                this.tagService.deleteTagHomepage(id).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })
                // this.tagService.deleteTagPage(id).subscribe(data => {
                //     // console.log('Page', data)
                // }, error => { console.log(error) })
                break;

            case 'internal':
                this.tagService.deleteTagHomepage(id).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })
                // this.tagService.deleteTagInternalpage(id).subscribe(data => {
                //     // console.log('InternalPage', data)
                // }, error => { console.log(error) })
                break;
        }
    }

    getTag(typeTag, id_page, page) {
        switch (typeTag) {
            case 'homepage':
                this.tagService.getTagHomepage(id_page, null, null, page).subscribe(data => {
                    // console.log('homepage:', id_page, ' - ', data)
                    this.list_tag_campaign.homepage = this.list_tag_campaign.homepage.concat(data.results)

                }, error => { console.log(error) })
                break;

            case 'page':
                this.tagService.getTagHomepage(null, id_page, null, page).subscribe(data => {
                    // console.log('page:', id_page, ' - ', data)
                    this.list_tag_campaign.page = this.list_tag_campaign.page.concat(data.results)

                }, error => { console.log(error) })
                break;

            case 'internal':
                this.tagService.getTagHomepage(null, null, id_page, page).subscribe(data => {
                    // console.log('internal:', id_page, ' - ', data)
                    this.list_tag_campaign.internal = this.list_tag_campaign.internal.concat(data.results)

                }, error => { console.log(error) })
                break;
        }
    }

    alertType(type, new_type, list, page_type) {
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                title: 'Mudança de tipo',
                text: 'Você esta mudando de ' + type.name + ' para ' + new_type.name +
                    ' isso poderá apagar algumas informações para se adaptar a quantidade de imagens do formato selecionado.\n Deseja continuar ?',
                has_button: true,
                button_text: 'Continuar',
                button_cancel: 'Cancelar'
            }
        })
        this.modalRef.content.action.subscribe((result: any) => {
            if (result == 'yes') {
                var aux_list = list

                for (let index = aux_list.length - 1; index > 0; index--) {

                    if (index != 0) this.removeImage(index, page_type)

                }

                if (page_type == 'homepage') {
                    for (let index2 = aux_list[0].images.length - 1; index2 > 0; index2--) {

                        if (index2 != 0) this.removeImage(index2, 'page')

                    }

                    this.steps.homepage = true;
                    this.homepage.type = this.selected_homepage_type
                    this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
                    this.actual_step = "homepage_images";
                }

            }
            // console.log(this.homepage)
        });
    }
}
