
export const getMatrizConfig = () => ({
    items: {
        worksheet: 'MATRIZ',
        type: 'list',
        // rowOffset: 17,
        columns: [
            { index: 1, key: 'lant'},
            { index: 2, key: 'location'},
            { index: 3, key: 'hydrant'},
            { index: 4, key: 'diameter'},
            { index: 5, key: 'type'},
            { index: 6, key: 'material'},
            { index: 7, key: 'adapter'},
            { index: 8, key: 'key'},
            { index: 9, key: 'splash'},
            { index: 10, key: 'plug'},
            { index: 10, key: 'qty_mang'},
        ],
    },

});
