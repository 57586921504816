import { dateMapper } from "src/app/utils/validator.utils";

export const getPage2Config = () => ({

    // Table 1
    header_table_1: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 55, col: 1, key: 'place' },
        ],
    },
    table_1: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 54,
        endRows: 63,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 2
    header_table_2: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 64, col: 1, key: 'place' },
        ],
    },
    table_2: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 63,
        endRows: 71,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 3
    header_table_3: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 72, col: 1, key: 'place' },
        ],
    },
    table_3: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 71,
        endRows: 82,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },


    // ------- RIGHT -------



    // Table 4
    header_table_4: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 55, col: 6, key: 'place' },
        ],
    },
    table_4: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 54,
        endRows: 63,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table 5
    header_table_5: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 64, col: 6, key: 'place' },
        ],
    },
    table_5: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 63,
        endRows: 71,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table 6
    header_table_6: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 54, col: 1, key: 'title' },
            { row: 72, col: 6, key: 'place' },
        ],
    },
    table_6: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 71,
        endRows: 82,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },
});
