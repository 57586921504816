import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-painting',
  templateUrl: './painting.component.html',
  styleUrls: ['./painting.component.css']
})
export class PaintingComponent implements OnInit {
  
  title: string;
  local: string;
  table: [];
  list_description: [];
  header: [];
  footer: [];

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.table, 'table');
    console.log(this.local);
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }
}
