import { dateMapper } from "src/app/utils/validator.utils";

export interface Paint_2 {
    header: Header;
    items: Item[];
}

export interface Header {
    place: string;
    verifiedBy: string;
    date: string;
    hour: string;
    month: string;
}

export interface Item {
    location: string;
    hydrant: string;
    diameter: string;
    adapter: string;
    key: string;
    squirt: string;
    plug: string;
    hose_quantity: string;
    according: string;
    not_according: string;
}

export const getPaint_2Config = () => ({
    header: {
        worksheet: 'paint',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 2, col: 2, key: 'place' },
            { row: 6, col: 1, key: 'verifiedBy' },
            { row: 6, col: 4, key: 'date' },
            { row: 6, col: 7, key: 'hour' },
            { row: 6, col: 9, key: 'month' },
        ],
    },

    items: {
        worksheet: 'paint',
        type: 'list',
        rowOffset: 17,
        // endRows: 67,
        endRows: 35,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'chassis_number'},
            { index: 3, key: 'diameter'},
            { index: 4, key: 'adapter'},
            { index: 5, key: 'key'},
            { index: 6, key: 'squirt'},
            { index: 7, key: 'plug'},
            { index: 8, key: 'hose_quantity'},
            { index: 9, key: 'according'},
            { index: 10, key: 'not_according'},
        ],
    },

    items_2: {
        worksheet: 'paint',
        type: 'list',
        rowOffset: 38,
        // endRows: 67,
        endRows: 66,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'chassis_number'},
            { index: 3, key: 'diameter'},
            { index: 4, key: 'adapter'},
            { index: 5, key: 'key'},
            { index: 6, key: 'squirt'},
            { index: 7, key: 'plug'},
            { index: 8, key: 'hose_quantity'},
            { index: 9, key: 'according'},
            { index: 10, key: 'not_according'},
        ],
    },

    footer: {
        worksheet: 'paint',
        type: 'list',
        rowOffset: 35,
        endRows: 37,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 4, key: 'approved' },
            { index: 7, key: 'date', mapper: dateMapper },
            { index: 8, key: 'reference' },
        ],
    },
});