import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class CouponService {

    constructor(private http : HttpClient) { }

    // establishment = "id_establishment"

    getCoupons(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem("id_establishment"))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/coupon/all-coupons/`, options);
    }

    getCouponId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`) // <-- REMOVER

        return this.http.get<any>(AppConfig.path + `core/coupon/${id}/`, {headers});
    }

    postCoupon(id_establishment, name, start_date, end_date, image, rule, rules, description, 
        login_required, automatic_after, registration_config): Observable<any>{

        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`establishment`, id_establishment)
            .set(`name`, name)
            .set(`start_date`, start_date)
            .set(`end_date`, end_date)
            .set(`image`, encodeURI(image))
            .set(`rule`, rule)
            // .set(`rules`, encodeURI(rules))
            .set(`description`, description)
            .set(`login_required`, login_required)
            .set(`automatic_after`, automatic_after)

            if(rules != null){
                body = body.set(`rules`, encodeURI(rules))
            }

            if(registration_config != null){
                body = body.set(`registration_config`, registration_config)
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/coupon/`, body, { headers })
    }

    patchCoupon(id_establishment, coupon): Observable<any>{

        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`establishment`, id_establishment)

            for (let key of Object.keys(coupon)) {
                if((key == 'image' || key == 'rules') && coupon[key] != null && coupon[key] != ""){
                    body = body.append(key, encodeURI(coupon[key]));
                }
                else if(key == 'registration_config' && coupon[key] == 0){
                    body = body.append(key, '');
                }
                else if(key != 'id' && coupon[key] != null && coupon[key] != ""){
                    body = body.append(key, coupon[key]);
                }
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/coupon/${coupon.id}/`, body, { headers })
    }

    deleteCoupon(id): Observable<any>{
        let body = new HttpParams()
            .set(`active`, 'false')

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/coupon/${id}/`, body, { headers })
    }

}
