import { dateMapper } from "src/app/utils/validator.utils";

export const getEmergencyConfig = () => ({
    header: {
        worksheet: 'Saídas',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 2, col: 3, key: 'place' },
            { row: 7, col: 1, key: 'verifiedBy' },
            { row: 7, col: 4, key: 'date' },
            { row: 7, col: 6, key: 'hour' },
            { row: 7, col: 7, key: 'month' },
        ],
    },

    items_1: {
        worksheet: 'Saídas',
        type: 'list',
        rowOffset: 21,
        endRows: 49,
        columns: [
            { index: 1, key: 'building'},
            { index: 2, key: 'location'},
            { index: 3, key: 'observation'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    items_2: {
        worksheet: 'Saídas',
        type: 'list',
        rowOffset: 51,
        endRows: 75,
        columns: [
            { index: 1, key: 'building'},
            { index: 2, key: 'location'},
            { index: 3, key: 'observation'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    footer: {
        worksheet: 'Saídas',
        type: 'list',
        rowOffset: 49,
        endRows: 51,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 3, key: 'approved' },
            { index: 5, key: 'date', mapper: dateMapper },
            { index: 6, key: 'reference' },
        ],
    },

});
