<div class="screen" *ngIf="!loading">

    <div class="card_title"> {{ notice_obj.name }} </div>
    <div *ngIf="!view_more" class="card_description"> {{ notice_obj.describe }} </div>

    <img *ngIf="!view_more && image_notice != null" loop=infinite [src]="image_notice" class="photo">

    <div class="div-imagens" *ngIf="view_more">
        <div *ngFor="let item of notice_obj.news" class="card_news">
            <div class="card_text line_break line_2"> {{ item.text }} </div>

            <img *ngIf="item.image!=null" loop=infinite [src]="item.image" class="photo">
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <button *ngIf="!view_more" mat-button class="btn_view_more" (click)="view_more = true" [style.background]="header.back" [style.color]="header.color">
            Ver mais
        </button>

        <button *ngIf="view_more" mat-button class="btn_view_more" (click)="view_more = false" [style.background]="header.back" [style.color]="header.color">
            Ver menos
        </button>
    </div>
</div>