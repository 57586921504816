import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CatalogService } from "src/app/service/catalog.service";

@Component({
    selector: 'more-details',
    templateUrl: './more-details.component.html',
    styleUrls: ['./more-details.component.css'],
})

export class MoreDetailsComponent implements OnInit {
    item = null;

    constructor(
        public dialog: MatDialog,
        private catalogService: CatalogService,
        private route: ActivatedRoute,
        private router: ActivatedRoute,
    ) {
        this.catalog_id = this.route.snapshot.paramMap.get('id');
        this.product_id = this.route.snapshot.paramMap.get('produ_id');
    }
    
    loading: boolean = true;
    catalog_id: string = '';
    product_id: string = '';
    catalog: any;

    displayedColumns: string[] = ['name', 'qtd', 'value'];
    
    ngOnInit(): void {        
        if (this.catalog_id != null && this.catalog_id != '') {
            this.getCatalog(this.catalog_id);
        }
    }

    getCatalog(id) {
        this.catalogService.getCatalog(id).subscribe(data => {
            this.catalog = data;
            // console.log(this.catalog);
            this.loading = false;
        })
    }
}