import { dateMapper } from "src/app/utils/validator.utils";

export const getVGsMinimaxConfig = () => ({
    header: {
        worksheet: 'Minimax',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 3, col: 5, key: 'place' },

            { row: 4, col: 4, key: 'verification_item' },
            { row: 5, col: 4, key: 'frequency' },
            { row: 5, col: 7, key: 'month' },
            { row: 4, col: 10, key: 'building' },
            { row: 5, col: 10, key: 'place_2' },
            { row: 6, col: 1, key: 'daytime_nighttime' },
        ],
    },

    verifications_list_1: {
        worksheet: 'Minimax',
        type: 'list',
        rowOffset: 6,
        endRows: 13,
        columns: [
            { index: 1, key: 'Questão' },
            { index: 2, key: 'Descrição' },

        ],
    },

    verifications_list_2: {
        worksheet: 'Minimax',
        type: 'list',
        rowOffset: 6,
        endRows: 10,
        columns: [
            { index: 8, key: 'Questão' },
            { index: 9, key: 'Descrição' },

        ],
    },

    days_1: {
        worksheet: 'Minimax',
        type: 'list',
        rowOffset: 13,
        endRows: 46,
        columns: [
            { index: 1, key: 'day' },
            { index: 2, key: 'first_hour' },
            { index: 3, key: 'vg_number_12' },
            { index: 4, key: 'vg_number_13' },
            { index: 5, key: 'vg_number_14' },
            { index: 6, key: 'name' },
        ],
    },

    days_2: {
        worksheet: 'Minimax',
        type: 'list',
        rowOffset: 13,
        endRows: 46,
        columns: [
            { index: 1, key: 'day' },
            { index: 2, key: 'second_hour' },
            { index: 3, key: 'vg_number_12' },
            { index: 4, key: 'vg_number_13' },
            { index: 5, key: 'vg_number_14' },
            { index: 6, key: 'name' },
        ],
    },

    footer: {
        worksheet: 'Minimax',
        type: 'list',
        rowOffset: 67,
        endRows: 69,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 6, key: 'approved' },
            { index: 10, key: 'date', mapper: dateMapper },
            { index: 12, key: 'reference' },
        ],
    },

});
