<div *ngIf="loading" class="loading">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
</div>

<div class="screen" *ngIf="!loading_screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Curso </h3>
    </div>

    <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

        <div style="width: 75%; position: relative;">
            <span class="title_video"> Titulo do curso </span>

            <input mat-input [(ngModel)]="course.title" placeholder="EX: Curso de ..." class="input"
                style="width: 100%;" [ngStyle]="{'border-color': warningMap.get('title')? 'red' : ''}">
        </div>

        <div style="width: 24%; position: relative;">
            <span class="title_video"> Carga Horária </span>

            <input type="number" mat-input [(ngModel)]="course.workload_in_minutes" placeholder="EX: 20"
                class="input w_100" [ngStyle]="{'border-color': warningMap.get('workload_in_minutes')? 'red' : ''}">
        </div>

    </div>

    <div class="w_100" style="position: relative;">
        <span class="title_video"> Descrição do Curso </span>

        <textarea mat-input [(ngModel)]="course.description" placeholder="EX:  O Curso é sobre ..."
            style="resize: none;" class="input" [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}">
        </textarea>
    </div>

    <div *ngIf="viewSection">

        <div style="width: 100%; margin-top: 20px; display: flex; justify-content: space-between; align-items: center;">
            <h3 style="margin: 0;"> Click e arraste os itens para mudar a ordem dos vídeos. </h3>
        </div>

        <mat-accordion>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">

                <div *ngFor="let obj of course.videos; let i = index" cdkDrag style="margin: 10px 0;">

                    <mat-expansion-panel [expanded]="indexExpanded == i">

                        <mat-expansion-panel-header (click)="togglePanels(i)">
                            <mat-panel-title>

                                {{ obj.order }} - {{ obj.title != '' ? obj.title : 'Novo Vídeo #' + (i+1) }}

                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div style="display: flex; flex-direction: column;">

                            <div style="width: 100%; display: flex; justify-content: space-between;">

                                <div class="div_60" style="position: relative;">
                                    <span class="title_video"> Titulo do vídeo </span>

                                    <input mat-input [(ngModel)]="obj.title" style="width: 100%;" class="input"
                                        [ngStyle]="{'border-color': warningMap.get('title'+i)? 'red' : ''}"
                                        placeholder="EX: Vídeo Aula 1">
                                </div>

                                <div class="div_40"
                                    style="display: flex; justify-content: space-between; align-items: center;">
                                    <div style="width: 100%; position: relative;">
                                        <span class="title_video"> Link do vídeo (Youtube ou Vimeo) </span>

                                        <input mat-input [(ngModel)]="obj.link" class="input" style="width: 100%;"
                                            [ngStyle]="{'border-color': warningMap.get('link'+i)? 'red' : ''}"
                                            placeholder="EX: www.yotube ....">
                                    </div>
                                    <!-- 
                                    <input type="number" mat-input [(ngModel)]="obj.order" class="input" style="position: absolute; left: -200%; opacity: 0; width: 100%;"
                                        [ngStyle]="{'border-color': warningMap.get('order'+i)? 'red' : ''}" [value]="i+1"> -->
                                </div>

                            </div>

                            <div style="width: 100%; position: relative;">
                                <span class="title_video"> Escreva aqui a descrição do vídeo </span>

                                <textarea mat-input [(ngModel)]="obj.description" class="input" style="resize: none;"
                                    [ngStyle]="{'border-color': warningMap.get('description'+i)? 'red' : ''}"></textarea>
                            </div>

                            <h3 style="margin: 10px 0 5px;"> Perguntas do vídeo. </h3>

                            <mat-accordion class="card_questions">
                                <div cdkDropList class="example-list">

                                    <div *ngFor="let question of obj.questions; let y = index" style="margin: 10px 0;">

                                        <mat-expansion-panel [expanded]="indexExpanded2 == y">

                                            <mat-expansion-panel-header (click)="togglePanels2(y)">
                                                <mat-panel-title>

                                                    Pergunta {{ y +1 }}

                                                </mat-panel-title>
                                            </mat-expansion-panel-header>

                                            <div class="card_row">
                                                <div class="div_100">
                                                    <div style="width: 100%; position: relative; margin-bottom: 10px;">
                                                        <span class="title_video"> Pergunta </span>

                                                        <input mat-input [(ngModel)]="question.title"
                                                            style="width: 100%;" class="input"
                                                            [ngStyle]="{'border-color': warningMap.get('question'+y)? 'red' : ''}"
                                                            placeholder="EX: 1- Pergunta sobre o tema do vídeo">
                                                    </div>
                                                    <div class="card_row card_center" style="color: black;">
                                                        <h3 style="margin: 10px 0 5px;"> Marque a resposta que for a
                                                            correta, e deixe as demais desmarcadas. </h3>
                                                        <button mat-button class="button-cor" style="line-height: 30px;"
                                                            [style.background]="color_primary"
                                                            (click)="addAnswer(question)">
                                                            Adicionar resposta
                                                        </button>
                                                    </div>
                                                    <div class="card_row card_center"
                                                        *ngFor="let answer of question.list_answers; let z = index">
                                                        <mat-checkbox class="example-margin"
                                                            [(ngModel)]="answer.correct"></mat-checkbox>

                                                        <div style="width: 90%; position: relative;">
                                                            <span class="title_video"> Resposta {{ z +1 }} </span>
                                                            <input mat-input [(ngModel)]="answer.text"
                                                                style="width: 100%;" class="input"
                                                                [ngStyle]="{'border-color': warningMap.get('answer'+z)? 'red' : ''}">
                                                        </div>

                                                        <span (click)="removeAnswer(answer, i, y, z)"
                                                            class="icon_delet">
                                                            <img src="../../../assets/icon/icon_excluir.png">
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style="width: 100%; display: flex; justify-content: flex-end;">
                                                <button mat-button class="button-cor mw_auto"
                                                    style="background-color: rgb(187, 4, 4);"
                                                    (click)="removeQuizz(question, i, y)">
                                                    Apagar Pergunta
                                                </button>
                                            </div>
                                        </mat-expansion-panel>
                                    </div>

                                    <button mat-button class="button-cor" [style.background]="color_primary"
                                        (click)="addQuizz(obj)" *ngIf="obj.questions.length < 2">
                                        Adicionar Pergunta
                                    </button>
                                </div>
                            </mat-accordion>

                            <h3 style="margin: 20px 0 5px;"> Importar arquivos. </h3>

                            <div *ngIf="obj.document == null || obj.document.length == 0"
                                style="width: 100%; display: flex; justify-content: flex-start; padding-left: 20px; margin: 5px 0 10px; color: gray; font-size: 14px;">
                                Nenhum arquivo importado.
                            </div>

                            <div *ngIf="obj.document != null && obj.document.length > 0"
                                style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; padding-left: 20px; margin: 5px 0 10px; color: gray; font-size: 14px;">
                                <div style="width: 100%; margin: 5px 0;" *ngFor="let file of obj.document; let z = index">
                                    {{ file.name }}
                                    <svg width="512.000000pt" height="512.000000pt" class="delet_icon"
                                        viewBox="0 0 512.000000 512.000000" (click)="removeFile(file, i, z)">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                            fill="#000000" stroke="none">
                                            <path
                                                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <input type="file" #fileInput style="display: none;" (change)="onFilePdf($event, obj, i)">

                            <div style="width: 100%; display: flex; justify-content: flex-start;">
                                <button mat-button class="button-cor" [style.background]="color_primary"
                                    (click)="fileInput.click()">
                                    Adicionar Arquivo
                                </button>
                            </div>

                        </div>

                        <div style="width: 100%; display: flex; justify-content: flex-end;">
                            <button mat-button class="button-cor mw_auto" style="background-color: rgb(187, 4, 4);"
                                (click)="removeVideo(obj, i)">
                                Apagar Vídeo
                            </button>
                        </div>

                    </mat-expansion-panel>
                </div>
            </div>
        </mat-accordion>
    </div>


    <div style="display: flex; margin-top: 10px;">
        <!-- <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou o catálogo
        </button> -->

        <button mat-button class="button-cor" style="margin-right: 10px; border: 1px solid;"
            [style.background]="color_primary" (click)="addVideo()">
            Adicionar Vídeo
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="saveCourse()">
            {{ course_id == null ? 'Finalizar Curso' : 'Salvar Curso' }}
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status"
                aria-hidden="true"></span>
        </button>
    </div>

</div>