import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';

@Component({
    selector: 'app-modal-registration-preview',
    templateUrl: './modal-registration-preview.component.html',
    styleUrls: ['./modal-registration-preview.component.css']
})
export class ModalRegistrationPreviewComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private storageService: StorageService
    ) { }

    action: Subject<any> = new Subject();
    user;

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
    }

    close(){
		this.action.next('no');
		this.modalRef.hide();
	}

}
