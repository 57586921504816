import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import Player from "@vimeo/player";
import YTPlayer from "yt-player";
import { CourseService } from 'src/app/service/course.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';

@Component({
  selector: 'app-course-video',
  templateUrl: './course-video.component.html',
  styleUrls: ['./course-video.component.css']
})

export class CourseVideoComponent implements OnInit {

  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
  @Input() type_campaign: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  private player: Player;

  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private storageService: StorageService,
    private courseService: CourseService,
    private sanitizer: DomSanitizer,
    private userCampaignService: UserCampaignService,
    private _snackBar: MatSnackBar,
  ) {

    this.url = this.router.url;
    var url = this.router.url;

    if (url.indexOf("redirect") > 0) {
      this.web = true;
    }
    else if (url.indexOf("content") > 0) {
      this.web = false;
    }

    var aux = url.split('/')
    var tam = aux.length
    this.urlPage = aux[tam - 2]
  }

  urlPage;
  listImage;
  url
  web: boolean = false;
  loading: boolean = true;
  refresh: boolean = true;
  qrcode_id;

  list_course_video = [];
  position_video: number = 0;
  watch_video: any;

  playerYT: any;
  iframeURL: any;
  type_video: string = '';
  code_video: string = '';
  url_link;
  check_login: boolean = false;

  course_obj;
  list_videos = [];
  video_view_list = []

  page: number = 1;
  page_user: number = 1;

  certificate: boolean = false;
  list_course = []
  loading_download: boolean = false;
  file_url;

  default_color: '';
  is_quizz: boolean = false;
  view_quizz: boolean = false;
  status_quizz: boolean = false;

  send_answer = [{ quizz: null, answer: null, status: null }, { quizz: null, answer: null, status: null }];

  user_campaign: any;
  user_course: any;

  async ngOnInit() {
    // console.log("-> Curso <-")    

    this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

    this.default_color = this.list.icons_color;
    this.storageService.screen.format = 'Vídeo';
    this.storageService.screen.id = this.list.id;
    this.campaign_id = this.list.id;

    this.campaignService.watchList().subscribe(data => {
      this.listImage = this.tratamento(this.list)
      this.url_link = this.listImage != null ? this.listImage : null
    })

    this.listImage = this.tratamento(this.list)
    this.url_link = this.listImage != null ? this.listImage : null

    if (localStorage.getItem('tokenUser') == null) {
      this.check_login = true;
      this.loading = false;
    }
    else {
      this.getCourse()
      this.check_login = false;
    }
  }

  getCourse() {
    var course_id;

    if (this.listImage == null && this.list.page_images == null) {
      course_id = this.list.course;
    }
    else {
      course_id = ((
        this.listImage != null &&
        this.listImage[0] != null &&
        this.listImage[0]?.course != null
      )
        ? this.listImage[0].course
        : null
      )
    }

    this.courseService.getCourseId(course_id).subscribe(data => {
      this.course_obj = data;

      data.videos.forEach(element => {
        this.list_course_video.push({
          id: element.id,
          title: element.title,
          description: element.description,
          link: element.link,
          course: element.course,
          order: element.order,
          is_finished: null,
          id_user_videos: null,
          quizz: element.course_video_quizz
        })
      });

      this.list_course_video.sort(this.ordenarList)
      this.getCourseUser()

      setTimeout(() => {
        this.getVideoView(1)
      }, 200);
    })
  }

  getCourseUser() {
    this.userCampaignService.getUserCampaignMe().subscribe(data => {
      this.user_campaign = data;
      this.filterUserCourse()
    })
  }

  getVideoView(page) {
    this.courseService.getVideoView(page, this.course_obj.id).subscribe(data => {
      this.video_view_list = this.video_view_list.concat(data.results)

      if (data.next != null) {
        page += 1;
        this.getVideoView(page)
      }
      else {
        this.checkVideoView()
      }
    })
  }

  filterUserCourse() {
    this.courseService.filterUserCourse(this.user_campaign.id).subscribe(data => {
      let filter = data.filter(el => (el.course || '') == this.course_obj?.id)

      if ((data == null || data.length == 0) || (filter == null || filter.length == 0)) {
        this.postCourseUser()
      }
      else {
        this.user_course = filter[0];
      }
    })
  }

  checkVideoView() {
    this.video_view_list.forEach((view, index) => {

      var position = this.list_course_video.findIndex(el => el.id == view.course_video)

      if (position != -1) {
        this.list_course_video[position].id_user_videos = view.id;
        this.list_course_video[position].is_finished = view.is_finished;
        this.position_video = position;

        console.log('----', this.list_course_video[this.position_video]);
        
        if (this.list_course_video[this.position_video].quizz.length >= 1) {
          this.view_quizz = true;
        }
        else {
          this.view_quizz = false;
          this.status_quizz = true;
        }
      }

      if (this.list_course_video.length - 1 == index) {
        this.checkCertificate()
      }
    });

    this.loading = false;

    setTimeout(() => {
      this.selectVideo(this.list_course_video[this.position_video])
    }, 100);
  }

  checkCertificate() {
    var check = this.list_course_video.findIndex(el => el.is_finished != true)

    if (check == -1) {
      this.userApproved()
    }

    this.loading = false;
  }

  selectVideo(video) {
    if (video?.link != null) {
      this.setLinkVideo(video.link)
    } else {
      this.loading = false;
    }

    // this.userApproved()
  }

  userApproved() {
    this.filterUserCourse()

    setTimeout(() => {
      if (this.user_course?.id != null) {
        this.courseService.userApproved(this.user_course).subscribe(data => {
          if (data.Approved)
            this.certificate = true;
        })
      }
    }, 200);
  }

  postCourseUser() {
    this.courseService.postCourseUser(this.user_campaign.id, this.course_obj.id).subscribe(data => {
      this.user_course = data;
    })
  }

  validateAnswer(answer) {
    this.courseService.validateAnswer(answer.answer).subscribe(data => {
      answer.status = data.answer;

      if (data.answer == true) {
        this.sendPointUser(answer)
      }

      this.checkAnswersSend()
    })
  }

  checkAnswersSend() {
    let answered = this.send_answer.filter(el => el.answer != null && el.quizz != null)
    let questions = this.list_course_video[this.position_video].quizz?.length

    if (questions != null && answered.length == questions) {
      this.status_quizz = true;
    } else {
      this.status_quizz = false;
    }

    console.log(this.checkButtons('right'))
    console.log(this.list_course_video)
  }

  sendPointUser(answer) {
    this.courseService.sendPointUser(this.user_course.id, answer.quizz).subscribe(data => {
      this.is_quizz = false;
      this.checkCertificate()
    })
  }

  refreshPage() {
    this.check_login = false;
    this.getCourse()
  }

  selectAnswer(idxQ, idxA, quizz, answer) {
    this.send_answer[idxQ].quizz = quizz.id;
    this.send_answer[idxQ].answer = answer.id;
  }

  sendAnswer() {
    console.log(this.send_answer)
    let answered = this.send_answer.filter(el => el.answer != null && el.quizz != null)
    let questions = this.list_course_video[this.position_video].quizz?.length

    if (questions != null && answered.length == questions) {
      this.send_answer.forEach(el => {
        setTimeout(() => {
          if (el.answer != null) {
            this.validateAnswer(el)
          }
        }, 500);
      });
    } else {
      this.openSnackBar(
        "Por favor, responda todas as perguntas",
        "Fechar"
      )
    }
  }

  checkButtons(type) {
    if (type == 'left') {
      if (
        this.list_course_video[this.position_video]?.is_finished == null ||
        this.list_course_video[this.position_video]?.is_finished == false ||
        this.list_course_video[this.position_video]?.is_finished == true
      ) {
        return true
      }
      else {
        return false
      }
    }
    else if (type == 'right') {
      if (
        this.list_course_video[this.position_video]?.is_finished != null &&
        this.list_course_video[this.position_video]?.is_finished == true
      ) {
        
        if (this.list_course_video[this.position_video].quizz.length >= 1) {
          this.view_quizz = true;
        }
        else {
          this.view_quizz = false;
          this.status_quizz = true;
        }
        
        if (this.status_quizz == true) {
          return true
        } else {
          return false
        }
      }
      else {
        return false
      }
    }
  }

  backVideo() {
    this.position_video -= 1;

    if (this.list_course_video[this.position_video] != null) {
      this.selectVideo(this.list_course_video[this.position_video])
    }
  }

  nextVideo() {
    this.loading = true;
    this.position_video += 1;

    console.log(this.list_course_video[this.position_video]);

    if (this.list_course_video[this.position_video] != null) {
      this.view_quizz = false;
      this.status_quizz = false;
      this.send_answer = [{ quizz: null, answer: null, status: null }, { quizz: null, answer: null, status: null }];
      this.selectVideo(this.list_course_video[this.position_video])
    }
    else {
      this.loading = false;
    }
  }

  gerarCertificate(page) {
    this.loading_download = true;

    this.courseService.getCourseCompleted(page).subscribe(data => {
      this.list_course = this.list_course.concat(data.results);

      if (data.results.length == 0) {
        this.loading_download = false
      }

      if (data.next != null) {
        this.page += 1;
        this.gerarCertificate(this.page)
      }
      else {
        this.downloadCertificate()
      }
    })
  }

  downloadCertificate() {
    var check = this.list_course.filter(el => el.course == this.course_obj.id)

    if (check.length > 0) {
      if (this.url != '/application/dashboard') {
        this.storageService.saveHistoric(
          this.campaign_id, this.campaign_id,
          (this.list.homepage_format || this.list.page_format),
          13,
          'campaign',
          this.qrcode_id
        )
      }

      this.courseService.getCertificate(check[0].id).subscribe(data => {
        let blob = data.body.slice(0, data.body.size, "text/xlsx")
        this.file_url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        setTimeout(() => {
          document.getElementById('downloadLink').click()
        }, 500);

        this.loading_download = false;
      })
    }

  }

  setLinkVideo(link) {
    this.loading = true;

    var gerar_link
    if (link != null) {

      var vimeoCheck = link.indexOf('vimeo')
      var youtubeCheck = link.indexOf('youtube')

      if (vimeoCheck == -1 && youtubeCheck != -1) {
        this.type_video = 'youtube';

        var param = link.split('watch?v=')
        if (param.length > 1) {
          this.code_video = param[1];
          gerar_link = 'https://www.youtube.com/embed/' + param[1] + '?enablejsapi=1';
        }
        else {
          gerar_link = link
        }

        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(gerar_link);
      }
      else if (vimeoCheck != -1 && youtubeCheck == -1) {
        this.type_video = 'vimeo';

        var param = link.split('https://vimeo.com/')
        if (param.length > 1) {
          this.code_video = param[1];
          gerar_link = 'https://player.vimeo.com/video/' + param[1] + '?enablejsapi=1';
        }
        else {
          gerar_link = link
        }

        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(gerar_link);
      }
      else {
        // console.log('Não compativel !');
      }

      if (this.type_video == 'vimeo') {
        this.configVimeo();
      }
      else if (this.type_video == 'youtube') {
        this.configYoutube();
      }
    }
    else {
      this.loading = false;
    }

  }

  configVimeo() {
    this.loading = false;

    const options = {
      id: this.code_video,
      width: (document.body.clientWidth > 1000 ? 1000 : document.body.clientWidth),
      loop: false
    };
    this.player = new Player('video_vimeo', options);

    this.player.on('play', function () { this.postStartVideo() }.bind(this));
    this.player.on('ended', function () { this.postFinishedVideo() }.bind(this));
  }

  configYoutube() {
    this.loading = false;

    if (this.playerYT != null && this.playerYT != '')
      this.playerYT.destroy()

    const options = {
      width: '100%',
      height: (window.screen.height / 2),
      loop: false
    };
    this.playerYT = new YTPlayer('#video_youtube', options)
    this.playerYT.load(this.code_video)

    this.playerYT.on('playing', function () { this.postStartVideo() }.bind(this));
    this.playerYT.on('ended', function () { this.postFinishedVideo() }.bind(this));
  }

  postStartVideo() {
    if (
      this.list_course_video[this.position_video] != null &&
      this.list_course_video[this.position_video].is_finished == null
    ) {

      var video = this.list_course_video[this.position_video] != null ? this.list_course_video[this.position_video] : null;

      if (video != null) {
        this.courseService.videoStart(video).subscribe(data => {

          this.watch_video = data;
          this.list_course_video[this.position_video].is_finished = false;
          this.list_course_video[this.position_video].id_user_videos = data.id;

          // this.getVideoView(1)
        })
      }
    }

    // console.log('\n-> ', this.list_course_video, '\n')
    document.getElementById("click").click();
  }

  postFinishedVideo() {
    // console.log('ended the video!');

    if (this.list_course_video[this.position_video] != null &&
      this.list_course_video[this.position_video].is_finished == false) {

      this.courseService.videoFinished(this.list_course_video[this.position_video]).subscribe(data => {

        console.log(this.list_course_video[this.position_video]);

        if (this.list_course_video[this.position_video].quizz.length >= 1) {
          this.view_quizz = true;
        }
        else {
          this.view_quizz = false;
          this.status_quizz = true;
        }

        this.list_course_video[this.position_video].is_finished = true;  //data.is_finished;

        if (data.detail == "Course finished") {
          this.userApproved()
        }

        setTimeout(() => {
          // this.nextVideo()
          document.getElementById("click").click();
        }, 500);
      })
    }

    // console.log('\n-> ', this.list_course_video, '\n')
    document.getElementById("click").click();
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  tratamento(list) {
    if (this.preview_campaign) {
      if (list?.background_image?.url != null) {
        return list;
      }
      else if (list?.images != null && list?.images.length > 0) {
        return list.images[0];
      }
      else {
        return list;
      }
    }
    else {
      if (list?.internal_images != undefined) {
        return list.internal_images;
      }
      else if (list?.page_images != undefined) {
        return list.page_images;
      }
      else if (this.list?.home_images != undefined) {
        return list.home_images;
      }
      else {
        return null
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

}
