import { Component, OnInit } from '@angular/core';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit {

  constructor(
    private storageService: StorageService
  ) { }

  loading: boolean = false;
  displayedColumns: string[] = ['codigo', 'payment', 'status', 'last_change', 'register', 'view'];
  // dataSource: MatTableDataSource<any>;
  dataSource = ELEMENT_DATA;

  /* pagination related variables */
  campaign_response;
  num: number;
  page: number = 1
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;
  total_results: number;
  color_primary: string = '';

  categories_list = [];

  ngOnInit(): void {
    this.num = 10;
    this.page = 1
    this.total_results = 0;

    this.color_primary = this.storageService.establishment.color;
  }

  nextPage(){
    if(this.campaign_response.next != null){
        this.page += 1;
        this.getPayment()
    }
  }

  backPage(){
      if(this.campaign_response.previous != null){
          this.page -= 1;
          this.getPayment()
      }
  }

  getPayment(){

  }

  openDelete(item){

  }

}

export interface PeriodicElement {
  id: number;
  name: string;
  created_at: string;
  active: boolean;
  last_change: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: 11, name: 'Lorem Ipsum', created_at: '2020-10-20', active: true, last_change: '2020-10-20'},
  {id: 12, name: 'Lorem Ipsum', created_at: '2020-10-10', active: true, last_change: '2020-10-10'},
  {id: 8, name: 'Lorem Ipsum', created_at: '2020-09-21', active: false, last_change: '2020-09-21'},
  {id: 10, name: 'Lorem Ipsum', created_at: '2020-10-22', active: true, last_change: '2020-10-22'},
  {id: 13, name: 'Lorem Ipsum', created_at: '2020-10-06', active: true, last_change: '2020-10-06'},
  {id: 14, name: 'Lorem Ipsum', created_at: '2020-05-30', active: false, last_change: '2020-05-30'},
];
