import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { CatalogService } from 'src/app/service/catalog.service';
import { CouponService } from 'src/app/service/coupon.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-modal-coupon-preview',
  templateUrl: './modal-coupon-preview.component.html',
  styleUrls: ['./modal-coupon-preview.component.css']
})
export class ModalCouponPreviewComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private couponService: CouponService,
        private storageService: StorageService
    ) { }

    @Input() isList: any;
    @Input() isEdit: any;

    loading: boolean = true;
    warningMap = new Map();

    // isList;
    // isEdit;
    url = AppConfig.path;

    name;
    email;
    password;

    view = {
        coupon: false,
        login: false
    };

    textButton = "Resgatar";

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.loading = false
    }

    convertMoney(money) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(money);
    }

    rescue(){
        if(this.isList.login_required && !this.view.coupon){
            this.view.login = true;
            // this.view.coupon = false;
        }
        else{
            this.textButton = 'Resgatado!'
        }
    }

    addItem(newItem) {
        this.view.login = false;
        this.view.coupon = true;
	}
}
