import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-cell-phone',
    templateUrl: './cell-phone.component.html',
    styleUrls: ['./cell-phone.component.css']
})
export class CellPhoneComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private storageService: StorageService
    ) { }

    warningMap = new Map();

    isList;
    listImage;
    isEdit;
    isPopup;
    
    url = AppConfig.path;

    header = {
        title: 'Preview',
        color: '#FFF',
        back: '#2fa1d6'
    }

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
    }

}
