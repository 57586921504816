import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})

export class ProductService {

    constructor(private http : HttpClient) { }

    getMeasurementUnit(): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/measurementunit/`,  { headers });
    }

    getProductId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/product/${id}/`,  { headers });
    }

    getAllProducts(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`page`, page)
        };
       
        return this.http.get<any>(AppConfig.path + `core/product/`, options);
    }

    postProduct(name, price, initial_inventory, initial_price, initial_quantity, active, 
        establishment, description, ingredients, midia): Observable<any> {
        let body = new HttpParams()
            .set(`name`, name)
            .set(`price`, price)
            .set(`active`, active)
            .set(`establishment`, establishment)
            
            if(description != null && description != ""){
                body = body.set(`description`, description)
            }
            if(ingredients != null && ingredients != ""){
                body = body.set(`ingredients`, ingredients)
            }
            if(midia[0].image != null && midia[0].image != "" && midia[0].image.substring(0,4) != 'http') body = body.set(`image_1`, midia[0].image)
            else body = body.set(`image_1`, "")

            if(midia[1].image != null && midia[1].image != "" && midia[1].image.substring(0,4) != 'http') body = body.set(`image_2`, midia[1].image)
            else body = body.set(`image_2`, "")

            if(midia[2].image != null && midia[2].image != "" && midia[2].image.substring(0,4) != 'http') body = body.set(`image_3`, midia[2].image)
            else body = body.set(`image_3`, "")

            if(midia[3].image != null && midia[3].image != "" && midia[3].image.substring(0,4) != 'http') body = body.set(`image_4`, midia[3].image)
            else body = body.set(`image_4`, "")

            if(midia[4].image != null && midia[4].image != "" && midia[4].image.substring(0,4) != 'http') body = body.set(`image_5`, midia[4].image)
            else body = body.set(`image_5`, "")

            
            // if(midia != null && midia.length > 0){
            //     var num = 1
            //     midia.forEach(item => {
            //         if(item.image != null && item.image.substring(0,4) != 'http'){
            //             body = body.set(`image_${num}`, item.image)
            //             num++;
            //         }
            //     });
            // }

            if(initial_inventory){
                body = body.set(`initial_price`, initial_price)
                body = body.set(`initial_quantity`, initial_quantity)
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/product/`, body, { headers })
    }

    postProductCategory(product, category): Observable<any> {
        let body = new HttpParams()
            .set(`product`, product)
            .set(`category`, category)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/productcategory/`, body, { headers })
    }

    postProductRelated(product, related_product): Observable<any> {
        let body = new HttpParams()
            .set(`product`, product)
            .set(`relatedproduct`, related_product)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/productrelated/`, body, { headers })
    }

    activeProduct(id_product, status): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`active`, status)
  
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/product/${id_product}/`, body, { headers })
    }

    editProduct(id_product, name, price, initial_inventory, initial_price, initial_quantity, active, 
        establishment, description, ingredients, midia): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, name)
            .set(`price`, price)
            .set(`active`, active)
            .set(`establishment`, establishment)
            // .set(`image_2`, '')
            
            if(description != null && description != ""){
                body = body.set(`description`, description)
            }
            if(ingredients != null && ingredients != ""){
                body = body.set(`ingredients`, ingredients)
            }
            if(midia[0].image != null && midia[0].image != "") body = body.set(`image_1`, midia[0].image)
            else body = body.set(`image_1`, "")

            if(midia[1].image != null && midia[1].image != "") body = body.set(`image_2`, midia[1].image)
            else body = body.set(`image_2`, "")

            if(midia[2].image != null && midia[2].image != "") body = body.set(`image_3`, midia[2].image)
            else body = body.set(`image_3`, "")

            if(midia[3].image != null && midia[3].image != "") body = body.set(`image_4`, midia[3].image)
            else body = body.set(`image_4`, "")

            if(midia[4].image != null && midia[4].image != "") body = body.set(`image_5`, midia[4].image)
            else body = body.set(`image_5`, "")



            // if(midia != null && midia.length > 0){
            //     var num = 1
            //     midia.forEach(item => {

            //         if(item.image != null && item.image.substring(0,4) != 'http'){
            //             body = body.set(`image_${num}`, item.image)
            //             num++;
            //         }
            //     });
            // }

            if(initial_inventory){
                body = body.set(`initial_price`, initial_price)
                body = body.set(`initial_quantity`, initial_quantity)
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.put<any>(AppConfig.path + `core/product/${id_product}/`, body, { headers })
    }

    patchProductCategory(product, category): Observable<any> {
        let body = new HttpParams()
            .set(`product`, product)
            .set(`category`, category)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/productcategory/`, body, { headers })
    }

    patchProductRelated(product, related_product): Observable<any> {
        let body = new HttpParams()
            .set(`product`, product)
            .set(`relatedproduct`, related_product)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/productrelated/`, body, { headers })
    }

    deleteProduct(id_product): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/product/${id_product}/`, { headers })
    }

    deleteProductCategory(id_category): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/productcategory/${id_category}/`, { headers })
    }

    deleteProductRelated(id_related): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/productrelated/${id_related}/`, { headers })
    }

}
