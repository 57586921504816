<div style="background-color: #383838;">
    <div #home id="home">
        <div class="banner">
            <img src="../../assets/home.png" class="note" style="position: absolute; left: 0; width: 50%; min-width: 150px; object-fit: contain;
            object-fit: contain; z-index: 50; margin-top: -10px;">

            <div class="info-banner div-center">
                <!-- <div class="title" >
                Conecte-se com <br>
                as possibilidades
            </div> -->

                <div class="banner-text texts">
                    Desenvolvemos soluções <span> NO CODE </span> para agilizar os seus projetos de comunicação,
                    marketing e vendas. <br><br>
                    Melhorando métricas e agilizando processos, resultando no aumento de <span>receitas</span>,
                    redução de <span> custos </span> e melhora da <span> experiência </span> do seu cliente. <br><br>
                    Quer saber mais?
                </div>
            </div>
        </div>
        <!-- <img src="/assets/image/city.png" class="img-banner"> -->
    </div>


    <div #contact id="contact" class="contact">

        <!-- <img src="/assets/icon/Ativo 3.png" class="img-phone"> -->

        <div class="div-inputs" style="padding: 0 0 20px 0;">
            <b class="subtitle" style="color: white">
                Entre em contato
            </b>

            <div class="line_w100" style="border-color: white;"></div>

            <div class="div-row div_central">
                <input matInput type="text" class="input" [(ngModel)]="name" placeholder="Nome">
                <input matInput type="text" class="input" [(ngModel)]="email" placeholder="E-mail">
            </div>

            <textarea placeholder="Mensagem" [(ngModel)]="message" style="margin-top: 20px; resize: none" cols="30"
                rows="7"></textarea>

            <div
                style="width: 100%; display: flex; align-items: center; flex-direction: row; justify-content: space-between;">
                <button mat-button class="btn-send style_text color_gradient" (click)="sendMessage()"> Enviar </button>

                <div class="text_error">
                    {{ msg_error }}
                </div>
            </div>
        </div>
    </div>
</div>