<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Grupo </h3>

    </div>

    <input mat-input [(ngModel)]="group.name" placeholder="Escreva aqui o nome do grupo" class="input"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" >

    <div style="height: 20px;"></div>

    <mat-form-field class="input" style="padding: 5px 20px; height: 55px; display: flex; align-items: center;"
        [ngStyle]="{'border-color': warningMap.get('user')? 'red' : ''}">

        <input [(ngModel)]="group.user" matInput placeholder="Selecione os usuários do grupo"
            (keyup)="filter(group.user)" 
            [matAutocomplete]="auto">

        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let obj of search_user" [value]="obj.name" (click)="setTable(obj)">
                {{ obj.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div class="div-table">
        <table mat-table [dataSource]="dataSource" matSort>
            
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Usuário </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.item.name}} </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="max-width: 100px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde" style="max-width: 100px; padding: 5px !important; display: flex; justify-content: center;">    
                        <div (click)="remover(element, i)">
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Remover" class="icon view-red"> 
                        </div>
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum usuário selecionado. </td>
            </tr>
        </table>
    </div>

    <div style="display: flex; margin-top: 20px; margin-left: 5px;">

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()" > 
            {{ group_id == null ? 'Finalizar Grupo' : 'Salvar Grupo' }} 
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status" aria-hidden="true"></span>
        </button>
    </div>
            
</div>