import { Component, Input, OnInit, Output, EventEmitter, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';
import Player from "@vimeo/player";
import YTPlayer from "yt-player";
import { CourseService } from 'src/app/service/course.service';
import { element } from 'protractor';


@Component({
  selector: 'app-view-course',
  templateUrl: './view-course.component.html',
  styleUrls: ['./view-course.component.css']
})
export class ViewCourseComponent implements OnInit {


  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
  @Input() type_campaign: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  private player: Player;

  constructor(
    private campaignService: CampaignService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private storageService: StorageService,
    private courseService: CourseService
  ) {

    var url = this.router.url;

    if (url.indexOf("redirect") > 0) {
      this.web = true;
    }
    else if (url.indexOf("content") > 0) {
      this.web = false;
    }

    var url = this.router.url;
    var aux = url.split('/')
    var tam = aux.length
    this.urlPage = aux[tam - 2]
  }

  urlPage;
  listImage;
  loading: boolean = true;
  url = AppConfig.path;

  url_link;
  iframeURL;
  send_log_video: boolean = true;

  web: boolean = false;
  check_login: boolean = false;

  playerYT: any;
  course;
  reload: boolean = true;
  certificate: boolean = false;
  position_video: number = 0;
  video_start;

  type_video: string = '';
  code_video: string = '';

  list_videos = [];

  // player.getPlayerState():Number

  ngOnInit(): void {
    // console.log("-> Curso <-")

    this.storageService.screen.format = 'Vídeo';
    this.storageService.screen.id = this.list.id;
    this.campaign_id = this.list.id;

    this.campaignService.watchList().subscribe(data => {
      this.listImage = this.tratamento(this.list)
      this.url_link = this.listImage != null ? this.listImage : null
    })

    this.listImage = this.tratamento(this.list)
    this.url_link = this.listImage != null ? this.listImage : null

    if (localStorage.getItem('tokenUser') == null) this.check_login = true;
    else if (localStorage.getItem('tokenUser') != null) {
      this.getCourse()
      this.check_login = false;
    }

    // console.log(this.listImage)
  }

  getCourse() {
    var course_id = (this.listImage[0] != null && this.listImage[0]?.course != null) ? this.listImage[0].course : null
    this.courseService.getCourseId(course_id).subscribe(data => {
      this.course = data;
      this.getVideoView(1)
    })
  }

  login(newItem) {
    this.check_login = false;
  }

  selectVideo(video) {

    if(video != null){
      this.reload = false;
      this.setLinkVideo(video.link)
    }
  }

  configVimeo() {
    const options = {
      id: this.code_video,
      width: (document.body.clientWidth > 1000 ? 1000 : document.body.clientWidth),
      loop: false
    };
    this.player = new Player('video_vimeo', options);

    this.player.on('play', function () { this.postStartVideo() }.bind(this));
    this.player.on('ended', function () { this.postFinishedVideo() }.bind(this));
  }

  configYoutube() {

    if (this.playerYT != null && this.playerYT != '')
      this.playerYT.destroy()

    const options = {
      width: '100%',
      height: (window.screen.height / 2),
      loop: false
    };
    this.playerYT = new YTPlayer('#video_youtube', options)
    this.playerYT.load(this.code_video)

    this.playerYT.on('playing', function () { this.postStartVideo() }.bind(this));
    this.playerYT.on('ended', function () { this.postFinishedVideo() }.bind(this));
  }

  getVideoView(page) {
    this.courseService.getVideoView(page, this.course.id).subscribe(data => {

      this.course.videos.forEach(element => {
        element['is_finished'] = null;
        element['videouser'] = null;

        let position = data.results.findIndex(x => x.course_video == element.id);
        if (position != -1) {

          element['is_finished'] = data.results[position].is_finished;
          element['videouser'] = data.results[position].id;

          if (element != null && element.is_finished == true) this.position_video++;
        }

        this.list_videos.push(element)
      });

      this.checkCertificate()

      setTimeout(() => {
        this.list_videos.sort(this.ordenarList)
        this.selectVideo(this.course.videos[this.position_video])
      }, 500);
    })
  }

  checkCertificate(){
    var check = this.list_videos.filter(el => el.is_finished != true)
    if(check.length == 0) this.certificate = true;
  }

  backVideo(){
    this.position_video--;
    this.selectVideo(this.course.videos[this.position_video])
  }

  nextVideo() {
    this.position_video++;
    this.selectVideo(this.course.videos[this.position_video])
  }

  setLinkVideo(link) {

    var gerar_link
    if (link != null) {

      var vimeoCheck = link.indexOf('vimeo')
      var youtubeCheck = link.indexOf('youtube')

      if (vimeoCheck == -1 && youtubeCheck != -1) {
        this.type_video = 'youtube';

        var param = link.split('watch?v=')
        if (param.length > 1) {
          this.code_video = param[1];
          gerar_link = 'https://www.youtube.com/embed/' + param[1] + '?enablejsapi=1';
        }
        else {
          gerar_link = link
        }

        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(gerar_link);
      }
      else if (vimeoCheck != -1 && youtubeCheck == -1) {
        this.type_video = 'vimeo';

        var param = link.split('https://vimeo.com/')
        if (param.length > 1) {
          this.code_video = param[1];
          gerar_link = 'https://player.vimeo.com/video/' + param[1] + '?enablejsapi=1';
        }
        else {
          gerar_link = link
        }

        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(gerar_link);
      }
      // else {
      //   console.log('Não compativel !');
      // }

    }

    this.reload = true;

    if(!this.certificate){
      if (this.type_video == 'vimeo') {
        this.configVimeo();
      }
      else if (this.type_video == 'youtube') {
        this.configYoutube();
      }
    }
  }

  postStartVideo() {
    // console.log('start the video!');

    if (this.list_videos[this.position_video] != null && this.list_videos[this.position_video].is_finished == null) {
      var video = this.course?.videos[this.position_video] != null ? this.course?.videos[this.position_video] : null;

      if (video != null) {
        this.courseService.videoStart(video).subscribe(data => {
          // console.log('->', data)
          this.video_start = data;
          // console.log(this.video_start)

          this.list_videos[this.position_video].is_finished = data.is_finished;
          this.list_videos[this.position_video].videouser = data.id;

          // console.log(this.position_video)
          // console.log(this.list_videos[this.position_video])

          this.getVideoView(1)
        })
      }
    }
  }
  
  postFinishedVideo() {
    // console.log('ended the video!');
    
    if (this.list_videos[this.position_video] != null && this.list_videos[this.position_video].is_finished == false) {
      var video = this.course?.videos[this.position_video] != null ? this.course?.videos[this.position_video] : null;
      
      // console.log(this.video_start)
      // console.log(this.position_video)
      // console.log(this.list_videos[this.position_video])

      this.courseService.videoFinished(this.video_start).subscribe(data => {
        // console.log('->', data)
        setTimeout(() => { this.nextVideo() }, 1000);
      })
    }
  }

  gerarCertificate() {
    if (this.certificate) {
      this.courseService.getCertificate(this.course.id).subscribe(data => {
        // console.log(data)
      })
    }
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  tratamento(list) {
    if (this.preview_campaign) {
      if (list?.background_image?.url != null) {
        return list;
      }
      else if (list?.images != null && list?.images.length > 0) {
        return list.images[0];
      }
      else
        return list;
    }
    else {
      if (list?.internal_images != undefined) {
        // console.log('3',list.internal_images)
        return list.internal_images;
      }
      else if (list?.page_images != undefined) {
        // console.log('2',list.page_images)
        return list.page_images;
      }
      else if (this.list?.home_images != undefined) {
        // console.log('1',list.home_images)
        return list.home_images;
      }
      else
        return null
    }

  }

}