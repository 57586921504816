import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { EMPTY, Subject } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { Category } from 'src/app/model/product';
import { CategoryService } from 'src/app/service/category.service';
import { StorageService } from 'src/app/service/storage.service';
import { CompressorService } from '../../service/compressor.service';

@Component({
    selector: 'app-detail-category',
    templateUrl: './detail-category.component.html',
    styleUrls: ['./detail-category.component.css']
})
export class DetailCategoryComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private router: Router,
        private compressor: CompressorService,
        private storageService: StorageService,
        private categoryService: CategoryService
    ) { }

    action: Subject<any> = new Subject();

    loading: boolean = false;
    warningMap = new Map();

    title: string = '';
    button_text: string = '';

    id_establishment;

    category;
    id;
    name;
    subcategory: string = '';
    list_subcategory = []
    img_category: ''
    indexExpanded: number = -1;

    compressed_image = []; // as the name says...

    list_delet = []

    color_primary

    page: number = 1;
    list_all_subcategory = []

    ngOnInit(): void {
        // console.log(this.category)
        
        this.color_primary = this.storageService.establishment.color;
        this.id_establishment = localStorage.getItem('id_establishment')
        
        if (this.category != null) {
            this.title = 'Editar Categoria';
            this.button_text = 'Salvar'
            this.name = this.category.name;
            this.id = this.category.id;
            this.list_subcategory = this.category.subcategory;
        }
        else {
            this.title = 'Novo Categoria';
            this.button_text = 'Salvar';
        }
    }

    addSub(subcategory) {
        if (this.subcategory == '' || this.subcategory == null) {
            // console.log('Não pode ser nulo')
        }
        else
            this.list_subcategory.push({ name: subcategory })
        this.subcategory = '';
    }

    deleteSub(idx) {
        this.list_delet.push(this.list_subcategory[idx]);

        this.list_subcategory.splice(idx, 1);
    }

    togglePanels(index: number) {
        this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }

    save() {
        this.warningMap = new Map();

        if (this.name == null || this.name == "") {
            this.warningMap.set('name', true);
        }
        // if (this.category.img_category == null || this.category.img_category == "") {
        //     this.warningMap.set('image', true);
        // }

        if (this.warningMap.size == 0) {
            if (this.id == null)
                this.postCategory()
            else
                this.editCategory()
        }
    }

    async onFileChanged(event) {
        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                // expand(res => {
                //   return res.index > res.array.length - 1
                //     ? EMPTY
                //     : this.recursiveCompress(target[res.index], res.index, target);
                // }),
            );
            //   compress.subscribe(res => {
            //     if (res.index > res.array.length - 1) {
            //       this.notice.paragraph[index].image = this.compressed_image[0]
            //       this.compressed_image = []

            //     }
            //   });
        }
    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {
                this.compressed_image.push(response)
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );
    }

    postCategory() {
        if (!this.loading) {
            this.loading = true;

            this.categoryService.postCategory(this.name, this.id_establishment, this.img_category).subscribe(data => {

                this.list_subcategory.forEach((el, index) => {
                    if (el?.id == null) this.postSubcategory(data.id, el)
                    else this.editSubcategory(el)

                    if (index == this.list_subcategory.length - 1)
                        this.checkListDelet()

                });

            },
                error => {
                    console.log(error)
                    this.loading = false;
                })
        }
    }

    editCategory() {
        if (!this.loading) {
            this.loading = true;

            this.categoryService.editCategory(this.id, this.name,).subscribe(data => {

                this.list_subcategory.forEach((el, index) => {
                    if (el?.id == null) this.postSubcategory(data.id, el)
                    else this.editSubcategory(el)

                    if (index == this.list_subcategory.length - 1)
                        this.checkListDelet()

                });
            },
                error => {
                    console.log(error)
                    this.loading = false;
                })
        }
    }

    postSubcategory(id, sub) {

        this.categoryService.postSubcategory(id, sub).subscribe(data => {

        },
            error => {
                console.log(error)
                this.loading = false;
            })
    }

    editSubcategory(sub) {
        this.categoryService.editSubcategory(sub).subscribe(data => {

        },
            error => {
                console.log(error)
                this.loading = false;
            })
    }

    deletSubcategory(sub) {
        this.categoryService.deletSubcategory(sub).subscribe(data => {

        },
            error => {
                console.log(error)
                this.loading = false;
            })
    }

    checkListDelet() {
        if (this.list_delet.length == 0) {
            this.loading = false;
            this.action.next('yes');
            this.modalRef.hide();
        }
        else{
            this.list_delet.forEach((element, index) => {
    
                this.deletSubcategory(element)
    
                if (index == this.list_delet.length - 1) {
                    this.loading = false;
                    this.action.next('yes');
                    this.modalRef.hide();
                }
            });
        }

    }

    close() {
        this.action.next('no');
        this.modalRef.hide();
    }
}
