<div class="screen">

    <div [ngStyle]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!loading && check_login">
        <app-login-user style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="null"
            (newItemEvent)="refreshPage()"></app-login-user>
    </div>

    <div style="width: 100%; margin-bottom: 10px;" *ngIf="!loading && !check_login">
        <div *ngFor="let button of listImage" class="card_check">

            <!-- IMAGEM -->
            <img *ngIf="button.image != null && button.image != '' " [src]="button.image" class="card_image">

            <!-- DESCRIÇÃO -->
            <div class="card_description">
                {{ button.description }}
            </div>
            
            <button mat-button class="btn_border" 
                [style.background]="header.back" [style.color]="header.color"
                (click)="checkButton(button)">
                {{ button.title }}
            </button>
        </div>
    </div>

</div>
