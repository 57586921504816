import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SectionService {

    constructor(private http : HttpClient) { }

    getSectionId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            // .set('Authorization', `token ${localStorage.getItem('token')}`) // <-- REMOVER

        return this.http.get<any>(AppConfig.path + `core/section/${id}/`, {headers});
    }

    postSection(name, id_catalog, morning_period, afternoon_period, night_period, dawn_period, order): Observable<any>{
        let body = new HttpParams()
            .set(`name`, name)
            .set(`catalog`, id_catalog)
            .set(`morning_period`, morning_period)
            .set(`afternoon_period`, afternoon_period)
            .set(`night_period`, night_period)
            .set(`dawn_period`, dawn_period)
            .set(`order`, order)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/section/`, body, { headers })
    }

    patchSection(name, id_section, morning_period, afternoon_period, night_period, dawn_period, order): Observable<any>{
        
        let body = new HttpParams()
            .set(`name`, name)
            .set(`morning_period`, morning_period)
            .set(`afternoon_period`, afternoon_period)
            .set(`night_period`, night_period)
            .set(`dawn_period`, dawn_period)
            .set(`order`, order)
            
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/section/${id_section}/`, body, { headers })
    }

    deleteSection(id_section): Observable<any> {
       
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/section/${id_section}/`, { headers })
    }
}