<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> Gerar o Relatório </b>
        </h1>

    </div>
    
    <!-- <div class="coll-date">
        
        <div class="select-date">
            <mat-form-field appearance="fill" style="width: 100%;" class="input" 
                [ngStyle]="{'border-color': warningMap.get('start')? 'red' : ''}">

                <input matInput [matDatepicker]="picker1" placeholder="Data inicial" 
                    [(ngModel)]="date.start">

                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="select-date">
            <mat-form-field appearance="fill" style="width: 100%;" class="input" 
                [ngStyle]="{'border-color': warningMap.get('end')? 'red' : ''}">

                <input matInput [matDatepicker]="picker2" placeholder="Data final" 
                    [(ngModel)]="date.end">

                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    
    </div> -->

    <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 100%; position: relative;">
            <span class="title_video"> Selecione a Data </span>
            
            <div style="margin: 0 auto 0;" style="width: 100%;">
                <mat-select [(ngModel)]="date_select"
                    class="input select-box" >
        
                    <mat-option *ngFor="let obj of list_date; let i = index"
                        (click)="setDateSelect(obj.day)"  [value]="obj.id">
                        {{ obj.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div> 
    
    <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 100%; position: relative;">
            <span class="title_video"> Selecione o QRCode </span>
            
            <div style="margin: 0 auto 0;" style="width: 100%;">
                <mat-select [(ngModel)]="qrcode_select"
                    class="input select-box" >
        
                    <mat-option *ngFor="let obj of list_qrcode; let i = index"
                        (click)="select(obj)"  [value]="obj.id">
                        {{ obj.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>  

    <div style="width: 70%; margin: 10px auto 0; display: flex; justify-content: space-between; align-items: center;">

        <button mat-button [style.background]="color_primary" class="b-border" (click)="close()">
            Fechar
        </button>

        <button mat-button [style.background]="color_primary" class="b-border" (click)="gerar()">
           Gerar
           <span *ngIf="loading_download" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <a download="Relatório.pdf" [href]="file_url" style="display: none;" #downloadLink id="downloadLink" ></a>

    </div>

</div>