import { Component, OnInit } from '@angular/core';
import { CompressorService } from 'src/app/service/compressor.service';
import { map, expand } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { MDBModalRef } from 'angular-bootstrap-md';
import { ConstantsService } from 'src/app/service/constants.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-popup-generate-file-link',
  templateUrl: './popup-generate-file-link.component.html',
  styleUrls: ['./popup-generate-file-link.component.css']
})
export class PopupGenerateFileLinkComponent implements OnInit {

  constructor(
    private compressor: CompressorService,
		public modalRef: MDBModalRef,
    private constantsService: ConstantsService,
    private _snackBar: MatSnackBar,

  ) { }

	action: Subject<any> = new Subject();
  loading: boolean = false;

  validTypes = ['pdf', 'PDF', 'png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']

  color_primary: string = '';

  file: any = null;
  compressed_image = [];

  link: string = '';

  nomeArquivo: string = '';
  
  ngOnInit(): void {
    console.log(this.color_primary);
  }

  close(){
		this.action.next('no');
		this.modalRef.hide();
	}

  next(){
    if(this.file != null && this.file != ''){
      this.fileSaved(this.file)
    }
  }

  fileSaved(file){
     this.constantsService.fileSaved(file).subscribe(data=>{
        console.log(data)
        this.link = data.file;
     },
     error=>{
        console.log(error)
     })
  }
  
  copyLink(){
    var content = document.getElementById('textArea') as HTMLInputElement;
    content.select();
    document.execCommand('copy');
    this.openSnackBar("Link copiado!", "Fechar")
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
        duration: 6000,
    });
}
  
  async onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

      if (!event.target.files[0].name.includes(valid[0])) {
        console.log('Arquivo não compativel.');
        this.nomeArquivo = 'Arquivo não compativel.'
        return
      }

      var target = event.target.files

      var reader = new FileReader()
      reader.readAsDataURL(target[0])

      // subscribe file reader read event
      reader.onloadend = async (e) => {
        console.log(reader.result);

        var final_file = reader.result
        this.file = final_file
        this.nomeArquivo = event?.target?.files[0]?.name;
      }

      // const compress = this.recursiveCompress(target[0], 0, target).pipe(
      //   expand(res => {
      //     return res.index > res.array.length - 1
      //       ? EMPTY
      //       : this.recursiveCompress(target[res.index], res.index, target);
      //   }),
      // );
      // compress.subscribe(res => {
      //   if (res.index > res.array.length - 1) {
      //     this.file = this.compressed_image[0]
      //     this.compressed_image = []
      //   }
      // });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

}
