import { Component } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {

	constructor (private _bottomSheet: MatBottomSheet) { }

  	title = 'SBSolutions';
	  
	ngOnInit() {

    }

	ngAfterViewInit(){
        if(localStorage.getItem("cookies") != "true") {
            this.openBottomSheet()
        }
    }

	openBottomSheet(): void {
        this._bottomSheet.open(BottomSheetCookies, {
            disableClose: true
        });
    }
    
}

@Component({
    selector: 'cookies-bottom-sheet',
    templateUrl: 'bottom-sheet-cookies.html',
    styles: [`
        .cookies-content{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 5vw;
        }
        
        .cookies-btn{
            background-color: #f77128;
            color: white;
            border-radius: 33px;
            font-family: 'Fira Sans', sans-serif !important;
            padding: 7px 25px;
            font-size: 16px;
            margin: 5px 20px;
            width: 200px;
            outline: none;
            border: none;
        }
        
        .cookies-btn:hover{
            color: white;
        }
        
        @media screen and (max-width: 750px){
            .cookies-content{
                flex-direction: column;
            }
            .cookies-btn{
                font-size: 12px;
            }
            .text{
                font-size: 13px;
                text-align: justify;
            }
        }
    `]
})

export class BottomSheetCookies {
    constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetCookies>) {}
  
    continue(): void {
        this._bottomSheetRef.dismiss();
        localStorage.setItem("cookies", "true")
    }
}
