<div class="screen">

    <div *ngIf="loading" class="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div [ngStyle]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!loading && check_login">
        <app-login-user style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="null"
            (newItemEvent)="refreshPage()"></app-login-user>
    </div>

    <div *ngIf="!loading && !check_login && course_obj != null && list_videos != null" class="card_curse">
        <div class="border-gray space-between-center" style="width: 100%;">
            <h3 style="margin: 0; color: white;"> {{ course_obj.title }} </h3>
        </div>

        <div class="description"> {{ course_obj.description }} </div>
    </div>

    <div class="card_video" *ngIf="!loading">
        <div class="card_list_videos">
            <div class="border-gray space-between-center" style="width: 100%;">
                <h3 style="margin: 0; color: white; width: 100%; display: flex; justify-content: space-between;">
                    Vídeos
                </h3>
            </div>

            <div class="options_video" *ngIf="refresh">
                <div *ngFor="let video of list_course_video; let i = index">
                    <div class="title_video" [style.text-decoration]="position_video == i ? 'underline' : 'none'"
                        [ngClass]="{'video_true': video.is_finished == true, 'video_false': video.is_finished != true}">

                        {{ video.title }}
                        <!-- {{ video.is_finished ? '- Completo' : 
                        (!video.is_finished && video.is_finished != null ? '- assistindo' : '') }} -->

                    </div>
                </div>
            </div>
        </div>

        <div class="plataform_video">
            <div style="width: 100%;" *ngIf="!loading">

                <!-- VIMEO -->
                <div id="video_vimeo" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
                    'video-mobile' : urlPage != 'redirect'}"></div>

                <!-- YOUTUBE -->
                <div id="video_youtube" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
                    'video-mobile' : urlPage != 'redirect'}"></div>

            </div>

            <div class="card_description"
                *ngIf="list_course_video[position_video]?.description != null && list_course_video[position_video]?.description != ''">
                {{ list_course_video[position_video]?.description }}
            </div>

            <div class="card_quizz" *ngIf="view_quizz" >
                <h3> Questionário do Vídeo </h3>
                <div style="width: 100%;" [ngStyle]="{'display': !view_quizz ? 'none' : 'block'}">
                    <div *ngFor="let quizz of list_course_video[position_video].quizz; let q = index">
                        <div class="card_title_quizz" [style.background]="header.back" [style.color]="header.color">
                            {{ quizz.quizz }}
                        </div>

                        <div *ngFor="let answer of quizz.answer; let a = index"
                            (click)="selectAnswer(q, a, quizz, answer)">
                            <div class="card_text_answer"
                                [ngStyle]="{'border-color': send_answer[q].answer == answer.id ? header.back : 'transparent' }">
                                {{ a + 1 }} - {{ answer.text }}
                            </div>
                        </div>
                    </div>
                    <button mat-button (click)="sendAnswer()" class="btn_border" 
                        [style.background]="header.back" [style.color]="header.color" style="margin: 20px 0 0 auto;">
                        <span> Enviar </span>
                    </button>
                </div>

            </div>

            <div style="width: 100%; margin: 20px auto 0; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                <div style="width: 50%; margin: 10px 0 0; display: flex; justify-content: flex-start;">
                    <button mat-button (click)="backVideo()" class="btn_border" *ngIf="position_video > 0"
                        [disabled]="!checkButtons('left')" [style.color]="header.color"
                        [style.background]="checkButtons('left') ? header.back : 'gray'">
                        <span> Voltar </span>
                    </button>
                </div>

                <div style="width: 50%; margin: 10px 0 0; display: flex; justify-content: flex-end;">
                    <button mat-button (click)="nextVideo()" class="btn_border"
                        *ngIf="list_course_video.length-1 > position_video" [disabled]="!checkButtons('right')"
                        [style.color]="header.color" [style.background]="checkButtons('right') ? header.back : 'gray'">
                        <span> Próximo {{ !checkButtons('right') ? '(termine de assistir o vídeo)' : '' }}</span>
                    </button>

                </div>
            </div>

            <div *ngIf="certificate"
                style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">

                <div style="font-size: 25px; font-weight: 600; margin-bottom: 20px;">
                    CURSO FINALIZADO!
                </div>

                <button mat-button (click)="gerarCertificate(1)" class="btn_border" [style.background]="header.back"
                    [style.color]="header.color">
                    <span> Baixar Certificado </span>
                    <span *ngIf="loading_download" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>

                <a download="Certificado.pdf" [href]="file_url" style="display: none;" #downloadLink
                    id="downloadLink"></a>

            </div>

            <div style="height: 100px;"></div>
        </div>

    </div>

    <div id="click"></div>

</div>