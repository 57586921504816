import { ModalChoiceComponent } from 'src/app/modals/modal-choice/modal-choice.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from 'src/app/service/storage.service';
import { SpreadsheetService } from './../service/spreadsheet.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { PopupImportFileComponent } from './popup-import-file/popup-import-file.component'

@Component({
    selector: 'app-file-screen',
    templateUrl: './file-screen.component.html',
    styleUrls: ['./file-screen.component.css']
})

export class FileScreenComponent implements OnInit {

    constructor(
        private modalService: MDBModalService,
        private spreadsheetService: SpreadsheetService,
        private storageService: StorageService,
        private _snackBar: MatSnackBar,
    ) { }

    modalRef: MDBModalRef;
    modalDelete: MDBModalRef;

    establishment_id;
    color_primary;

    displayedColumns: string[] = ['name', 'type', 'view'];
    dataSource = new MatTableDataSource<any>();

    spreadsheet_response;
    loading: boolean = true;

    /* pagination related variables */
    page: number = 1
    count_total: number = 0;
    page_count1: number = 0;
    page_count2: number = 0;
    page_size = 10;

    ngOnInit(): void {
        this.establishment_id = localStorage.getItem('id_establishment')
        this.color_primary = this.storageService.establishment.color;
        this.getSpreadsheets(this.page)
    }

    getSpreadsheets(page) {
        this.spreadsheetService.getSpreadsheets(this.establishment_id, page).subscribe((data) => {
            this.dataSource = new MatTableDataSource(data.results)
            this.spreadsheet_response = data
            this.loading = false;

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page - 1) * this.page_size)
            if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
                this.page_count2 = this.count_total;
            }
            else {
                this.page_count2 = this.page_count1 + (this.page_size - 1);
            }
        })
    }

    importCSV() {
        const modalRef = this.modalService.show(PopupImportFileComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered ',
            containerClass: '',
            animated: false,
        })
        modalRef.content.action.subscribe((result: any) => {
            if (result.status == 'yes') {
                this.getSpreadsheets(this.page)
            }
        });
    }

    nextPage() {
        if (this.spreadsheet_response.next != null) {
            this.page += 1;
            this.getSpreadsheets(this.page)
        }
    }

    backPage() {
        if (this.spreadsheet_response.previous != null) {
            this.page -= 1;
            this.getSpreadsheets(this.page)
        }
    }

    openDelete(obj) {
        this.modalDelete = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                title: 'Excluir planilha',
                text: 'Deseja excluir a planilha?',
            }
        })
        this.modalDelete.content.action.subscribe((result: any) => {
            if (result == 'yes') {
                this.deleteSpreadsheet(obj.id)
            }
        });
    }

    deleteSpreadsheet(id) {
        this.spreadsheetService.deleteSpreadsheet(id).subscribe(data => {
            this.getSpreadsheets(this.page)
            this.openSnackBar("Deletado com sucesso!", "Fechar")
        }, error => {
            this.openSnackBar("ERRO: Não foi possível deletar", "Fechar")
        })
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 10000,
        });
    }

}
