import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { GroupService } from 'src/app/service/group.service';
import { StorageService } from 'src/app/service/storage.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';

@Component({
    selector: 'app-group-detail',
    templateUrl: './group-detail.component.html',
    styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private groupService: GroupService,
        private userCampaignService: UserCampaignService,
        private storageService: StorageService
    ) { 
        this.group_id = this.route.snapshot.paramMap.get('id');
    }

    group_id;
    loading: boolean = false;
    warningMap = new Map();

    displayedColumns: string[] = ['name', 'view'];
    dataSource = new MatTableDataSource<any>();

    list_users = []

    search_user = []
    users_ids = []

    list_table = []
    list_delete = []

    id_establishment;
    group={
        name: null,
        user: null
    }

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')
        this.getUserCampaign(1)

        if(this.group_id != null){
            this.getGroupId(this.group_id)
        }
    }
    
    getUserCampaign(page){
        this.userCampaignService.getUserCampaignAll(page, this.id_establishment).subscribe(data=>{

            this.list_users = this.list_users.concat(data.results)

            if (data.next != null) {
                this.getUserCampaign(page + 1)
            }
            else{
                this.search_user = this.list_users
            }
        })
    }

    getGroupId(id){
        this.groupService.getGroupId(id).subscribe(data=>{
            this.group.name = data.name;
            this.getUserGroup(data.id)
        })
    }

    getUserGroup(id){
        this.groupService.getUserGroup(id).subscribe(data=>{
            // console.log(this.list_users)

            data.results.forEach(element => {
                let search = this.list_users.findIndex( x => x.id == element.user_campaign );
                if(search != -1)
                    this.list_table.push({item: this.list_users[search], id: element.id})
            });

            setTimeout(() => {
                this.dataSource.data = this.list_table;
            }, 200);
        })
    }
    
    
    setTable(obj){
        let search = this.list_users.findIndex( x => x.id === obj.id );
        this.list_users.splice(search, 1)
        
        this.list_table.push({item: obj, id: null})
        this.users_ids.push(obj.id)

        this.dataSource.data = this.list_table;

        this.group.user = '';
        this.search_user = this.list_users;
    }

    remover(obj, posi){
        if(obj.id != null){ //trocar id por outra variavel, pra diferenciar um user ja cadastrado de um não cadastrado
            this.list_delete.push(obj)
        }

        this.list_table.splice(posi, 1)
        this.users_ids.splice(posi, 1)
        this.list_users.push(obj)

        this.dataSource.data = this.list_table;
    }
    
    filter(text) {
        if (text != null) {
            this.search_user = this.list_users.filter(el =>
                el.name.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
        }

    }

    save(){
        this.warningMap = new Map();

        if(this.group.name == "" || this.group.name == null){
            this.warningMap.set('name', true);
        }
        if(this.list_table.length == 0){
            this.warningMap.set('user', true);
        }
        if(this.warningMap.size == 0){
            if(this.group_id == null)
                this.postGroup()
            else 
                this.pacthGroup()
        }

    }

    postGroup(){
        this.loading = true;
        this.groupService.postGroup(this.group.name, true, this.id_establishment).subscribe(data=>{

            this.group_id = data.id
            
            if(this.list_delete.length > 0){
                this.deleteUserGroup()
            }
            
            this.list_table.forEach((el) => {
                this.postUserGroup(el.item.id, this.group_id)
            });

            setTimeout(() => {
                this.router.navigate(['/application/group'])
                this.loading = false;
            }, 1000);
        })
    }

    pacthGroup(){
        this.loading = true;
        this.groupService.patchGroup(this.group_id ,this.group.name, true, this.id_establishment).subscribe(data=>{

            if(this.list_delete.length > 0){
                this.deleteUserGroup()
            }

            this.list_table.forEach((el) => {
                if(el.id == null){
                    this.postUserGroup(el.item.id, this.group_id)
                }
            });

            setTimeout(() => {
                this.router.navigate(['/application/group'])
                this.loading = false;
            }, 1000);
        })
    }

    deleteUserGroup(){
        this.list_delete.forEach((el) => {

            this.groupService.deleteUserGroup(el.id).subscribe(data=>{
                // console.log(data)
            },
            error=>{
                // console.log(error)
            })
        });
    }

    postUserGroup(user, group_id){
        
        this.groupService.postUserGroup(user, group_id).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            // console.log(error)
        })
    }
}
