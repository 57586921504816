import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../../modals/modal-choice/modal-choice.component';
import { CatalogService } from '../../service/catalog.service';
import { ReminderService } from '../../service/reminder.service';
import { StorageService } from '../../service/storage.service';

@Component({
    selector: 'app-whatsapp',
    templateUrl: './whatsapp.component.html',
    styleUrls: ['./whatsapp.component.css']
})

export class WhatsappComponent implements OnInit {

    constructor(
        private catalogService: CatalogService,
        private modalService: MDBModalService,
        private reminderService: ReminderService,
        private storageService: StorageService
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalDelet: MDBModalRef;

    displayedColumns: string[] = ['name', 'date', 'view'];
    dataSource = new MatTableDataSource<any>();

    loading: boolean = false;
    whatsapp_response; // var to save last get catalogs response

    id_establishment;

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')

        this.getRegistration(1)
    }

    nextPage(){
        if(this.whatsapp_response.next != null){
            this.page += 1;
            this.getRegistration(this.page)
        }
    }

    backPage(){
        if(this.whatsapp_response.previous != null){
            this.page -= 1;
            this.getRegistration(this.page)
        }
    }

    getRegistration(page){
        this.reminderService.getReminder(this.id_establishment, 'WHATSAPP', page).subscribe(data=>{
            this.dataSource.data = data.results

             // pagination calc
             this.whatsapp_response = data;
             this.count_total = data.results.length;
             this.page_count1 = 1 + ((this.page-1) * this.page_size)
             if((this.page_count1 + (this.page_size-1)) > this.count_total){
                 this.page_count2 = this.count_total;
             }
             else{
                 this.page_count2 = this.page_count1 + (this.page_size-1);
             }
        })
    }

    deleteRegistrations(id){
        this.reminderService.deleteReminder(id).subscribe(data=>{
            this.getRegistration(1)
        })
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir alerta',
                text: 'Deseja excluir o alerta '+obj.title+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.deleteRegistrations(obj.id)
            }
        });
    }

}