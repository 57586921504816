<div class="modal-content">
    <div class="screen_popup">
        <img src="../assets/image/iphone_x.png" class="img-phone">

        <button mat-button class="icon-close b-border" [style.background]="color_primary" (click)="modalRef.hide()">
            Fechar
        </button>
    
        <div class="custom-scrollbar component" [style.background]="type_preview == 'tabloide' ? '#e9edef' : '#fff'">
            <app-redirect *ngIf="type_preview != 'tabloide'" [sendList]="campaign" ></app-redirect>
            <app-view-tabloide *ngIf="type_preview == 'tabloide'" [list]="campaign"></app-view-tabloide>
        </div>
    </div>
</div>