import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalTypeLinkComponent } from 'src/app/redirect-sbs/sidebar-lateral/modal-type-link/modal-type-link.component';

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent implements OnInit {

  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
	@Input() type_campaign: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: MDBModalService,
  ) { }

  modalRef: MDBModalRef;

  loading: boolean = false;
  listImage;
  iframeURL;

  list_options = []


  ngOnInit(): void {
		// console.log("-> Podcast <-")
    // console.log(this.list)

    this.listImage = this.tratamento(this.list)  
    console.log(this.listImage)

    setTimeout(() => {
      let link = `https://open.spotify.com/embed/show/${this.tratamentoLinkPodcast(this.listImage != null && this.listImage[0]?.link != null ? this.listImage[0].link : this.list.link )}?utm_source=generator&theme=0`
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(link);
      this.loading = true;
    }, 10);
  }

  tratamentoLinkPodcast(link){
    if(link != null){
      var param = link.split('show/')

      if(param.length > 1) return param[1];
      else return link
    }
  }

  tratamento(list){
    if(this.preview_campaign){
        if(this.list?.images != undefined){
            return list.images.sort(this.ordenarList);
        }
        else
            return null
    }
    else{
        if(list?.home_images != undefined){
            return list.home_images.sort(this.ordenarList);
        }
        else if(list?.internal_images != undefined){
            return list.internal_images.sort(this.ordenarList);
        }
        else if(list?.page_images != undefined){
            return list.page_images.sort(this.ordenarList);
        }
        else if(this.list?.home_images != undefined){
            return list.home_images.sort(this.ordenarList);
        }
        else
            return null
    }
   
  }

  ordenarList(a,b) {
		if (a.order < b.order)
			return -1;
		if (a.order > b.order)
			return 1;
		return 0;
	}

  openTypeLink() {
    console.log(this.listImage);

    this.list_options = []

    if (this.listImage[0]?.spotify != null && this.listImage[0]?.spotify != '') {
      this.list_options.push({ name: 'Spotify', link: this.listImage[0]?.spotify })
    }
    if (this.listImage[0]?.deezer != null && this.listImage[0]?.deezer != '') {
      this.list_options.push({ name: 'Deezer', link: this.listImage[0]?.deezer })
    }
    if (this.listImage[0]?.itunes != null && this.listImage[0]?.itunes != '') {
      this.list_options.push({ name: 'iTunes', link: this.listImage[0]?.itunes })
    }
    if (this.listImage[0]?.castbox != null && this.listImage[0]?.castbox != '') {
      this.list_options.push({ name: 'Castbox', link: this.listImage[0]?.castbox })
    }
    if (this.listImage[0]?.google_podcast != null && this.listImage[0]?.google_podcast != '') {
      this.list_options.push({ name: 'Google Podcast', link: this.listImage[0]?.google_podcast })
    }

    this.openPopup()
  }

  openPopup(){
    this.modalRef = this.modalService.show(ModalTypeLinkComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-ms',
      containerClass: '',
      animated: false,
      data: {
        sidebar: null,
        list_options: this.list_options
      }
    });
  }
}
