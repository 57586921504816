import { Component, Input } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-message-qrcode',
  templateUrl: './message-qrcode.component.html',
  styleUrls: ['./message-qrcode.component.css']
})
export class MessageQrcodeComponent {

	constructor(
		public modalRef: MDBModalRef,
		private storageService: StorageService
	) { }

	action: Subject<any> = new Subject();
	color_primary

	ngOnInit() {
		this.color_primary = this.storageService.establishment.color;
	}

	close(){
		this.modalRef.hide();
	}
	
}