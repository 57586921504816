<div style="margin: 0 50px;">
    <br><br>

    <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
        src="../../../../assets/image/logo_principal_SBS.png">
    <h2>VERIFICAÇÃO EXTERNA EXTINTORES</h2>

    <br><br>


    <table>
        <tr style="font-size: 9px;">
            <th> Local </th>
            <th> Verificado por </th>
            <th> Data </th>
            <th> Hora </th>
            <th> Mês </th>
        </tr>

        <tr style="font-size: 7px;">
            <td> {{ title }} </td>
            <td> {{ header['verification'] }} </td>
            <td> {{ header['data'] }} </td>
            <td> {{ header['hour'] }} </td>
            <td> {{ header['mother'] }} </td>
        </tr>
    </table>

    <br><br>

    <table *ngIf="local == 'ENERGY CENTER'">
        <tr style="font-size: 9px;">
            <th> Localização </th>
            <th> Numero Chassis</th>
            <th> Tipo </th>
            <th>Capacidade</th>
            <th>Garantia Serv</th>
            <th>Validade teste</th>
            <th style="width: 5%"> C </th>
            <th style="width: 5%"> N/C </th>
        </tr>

        <tr *ngFor="let item of table" style="font-size: 8px;">
            <td>{{ item['Localização']}} </td>
            <td> {{item['Numero Chassis']}} </td>
            <td> {{item['Tipo']}} </td>
            <td> {{item['Capacidade']}} </td>
            <td> {{item['Garantia Serv.']}} </td>
            <td> {{item['Validade Teste']}} </td>
            <td style="width: 5%"> {{item['C']}} </td>
            <td style="width: 5%"> {{item['N/C']}} </td>
        </tr>
    </table>

    <table *ngIf="local == 'PRÉDIO DA PINTURA'">
        <tr style="font-size: 9px;">
            <th> Prédio </th>
            <th> Localização</th>
            <th style="width: 5%"> C </th>
            <th style="width: 5%"> N/C </th>
        </tr>

        <tr *ngFor="let item of table" style="font-size: 8px;">
            <td>{{ item['Prédio']}} </td>
            <td> {{item['Localização']}} </td>
            <td style="width: 5%"> {{item['C']}} </td>
            <td style="width: 5%"> {{item['N/C']}} </td>
        </tr>
    </table>

    <table *ngIf="local == 'EXTERNO'">
        <tr style="font-size: 9px;">
            <th> Localização </th>
            <th> Numero Chassis</th>
            <th> Tipo </th>
            <th>Capacidade</th>
            <th>Validade teste</th>
            <th style="width: 5%"> C </th>
            <th style="width: 5%"> N/C </th>
        </tr>

        <tr *ngFor="let item of table" style="font-size: 8px;">
            <td>{{ item['Localização']}} </td>
            <td> {{item['Numero Chassis']}} </td>
            <td> {{item['Tipo']}} </td>
            <td> {{item['Capacidade']}} </td>
            <td> {{item['Validade Teste']}} </td>
            <td style="width: 5%"> {{item['C']}} </td>
            <td style="width: 5%"> {{item['N/C']}} </td>
            <td> </td>
        </tr>
    </table>
    <br><br>
    <table>
        <tr style="font-size: 10px;">
            <th> Elaborado </th>
            <th> Revisado: </th>
            <th> Aprovado </th>
            <th> Revisão: </th>
            <th> Data: </th>
            <th> Referência </th>
        </tr>

        <tr style="font-size: 8px;">
            <td> {{ footer['Elaborado'] }} </td>
            <td> </td>
            <td> {{ footer['Aprovado'] }} </td>
            <td> </td>
            <td> {{ footer['Data'] }} </td>
            <td> {{ footer['Referência'] }} </td>
        </tr>
    </table>
</div>