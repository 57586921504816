import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { PreviewCampaignComponent } from '../modals/preview-campaign/preview-campaign.component';
import { PreviewPostComponent } from '../modals/preview-post/preview-post.component';
import { CampaignService } from '../service/campaign.service';
import { StorageService } from '../service/storage.service';
import { ReportCampaignComponent } from './report-campaign/report-campaign.component';

@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.css']
})

export class CampaignComponent implements OnInit {

    constructor(
        private campaignService: CampaignService,
        private modalService: MDBModalService,
        private _snackBar: MatSnackBar,
        private storageService: StorageService,
        private router: Router
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalRef: MDBModalRef;
    modalDelet: MDBModalRef;
    modalMsg: MDBModalRef; 

    displayedColumns: string[] = ['codigo', 'campaign', 'publication', 'type', 'status', 'view'];
    dataSource: MatTableDataSource<any>;

    loading: boolean = false;
    loading_scheduling: boolean = false;

    campaign_list;
    total_results: number;

    num: number;
    
    /* pagination related variables */
    campaign_response;
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    scheduleAfter = [];
    scheduleBefore = [];

    color_primary

    ngOnInit(): void {
        this.num = 10;
        this.page = 1
        this.total_results = 0;

        this.color_primary = this.storageService.establishment.color;
        
        this.getCampaigns()
    }

    getCampaigns(){
        this.loading = true;
        this.dataSource = null
        // this.total_results = 0

        this.campaignService.getCampaigns(this.page).subscribe(data=>{

            data.results.forEach(item => {
                item.start_date = new Date(item.start_date+'T00:00:00')
                item.end_date = new Date(item.end_date+'T00:00:00')
            });

            this.campaign_response = data;
            this.campaign_list = data.results;
            this.loading = false

            this.dataSource = new MatTableDataSource(this.campaign_list);
            this.dataSource.sort = this.sort;
            this.total_results = Object.keys(this.campaign_list).length;

            // if(this.total_results < 10){
            //     this.page = 1;
            // }

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            this.loading = false
        })
    }

    nextPage(){
        if(this.campaign_response.next != null){
            this.page += 1;
            this.getCampaigns()
        }
    }

    backPage(){
        if(this.campaign_response.previous != null){
            this.page -= 1;
            this.getCampaigns()
        }
    }

    createLink(item){
        if(item?.qr_codes != null && item?.qr_codes[0]?.qr_code != null){
            var link = 'https://sbs.tools/redirect-sbs/'+item?.qr_codes[0]?.qr_code_obj[0]?.slug
            return link
        }
        else
            return 'https://sbs.tools/redirect/'+item.id;     
    }

    copyLink(i){
		var copyText = document.getElementById("myLink"+i) as HTMLInputElement
		copyText.select();
		copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        this.openSnackBar("Link copiado!", "Fechar")
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 6000,
        });
    }

    publishCampaign(item){
        if(item.active)
            this.openMessage(item)
        else{
            this.statusPublishCampaign(item.id, true)
        }
    }

    addEvent(event: MatDatepickerInputEvent<Date>, item) {
        if(event.value != null){

            var start_date = this.convertDate(item.start_date)
            var end_date = this.convertDate(item.end_date)

            if(!this.loading_scheduling)
                this.editScheduling(item.id, start_date, end_date)
        }
    }

    editScheduling(id, start_date, end_date){
        this.loading_scheduling = true;
        this.campaignService.patchCampaignScheduling(id, start_date, end_date).subscribe(data=>{
            this.loading_scheduling = false;
            this.getCampaigns()
        },
        error=>{
            this.loading_scheduling = false;
        })
    }

    convertDate(data){
        if(data != null){
            var d = new Date(data),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            var aux_data = [year, month, day].join('-')
    
            return aux_data;
        }
        else{
            return null
        }
        
    }

    deleteCampaign(id){
        this.loading = true;
        this.campaignService.deleteCampaign(id).subscribe(data=>{
                this.loading = false;
               
                this.getCampaigns()
            },
            error=>{
                this.loading = false;
            })
    }

    statusPublishCampaign(id, status){
        this.loading = true;
        this.campaignService.statusPublishCampaign(id, status).subscribe(data=>{
                this.loading = false;
                this.getCampaigns()
            },
            error=>{
                this.loading = false;
            })
    }

    openPreview(campaign){
        
        if(!campaign.is_feed){
            this.modalRef = this.modalService.show(PreviewCampaignComponent, {
                backdrop: 'static',
                keyboard: false,
                focus: true,
                show: true,
                ignoreBackdropClick: false,
                class: 'modal-dialog-centered popup-transparent',
                containerClass: '',
                animated: false,
                data:{
                    campaign: campaign,
                }
            })    
        }
        else{
            this.modalRef = this.modalService.show(PreviewPostComponent, {
                backdrop: 'static',
                keyboard: false,
                focus: true,
                show: true,
                ignoreBackdropClick: false,
                class: 'modal-dialog-centered popup-transparent',
                containerClass: '',
                animated: false,
                data:{
                    campaign: campaign,
                }
            })  
        }
    
    }

    openMessage(campaign){
        this.modalMsg = this.modalService.show(ModalMessageComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Desativar publicação',
                text: 'Você tem certeza que quer desativar a campanha '+ campaign.header_text +' ?',
                has_button: true,
                button_text: 'Continuar',
                button_cancel: 'Cancelar'
            }
        })    
        this.modalMsg.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.statusPublishCampaign(campaign.id, false)
            }
        }); 
    
    }

    openEditContent(obj){
        if(obj.is_feed){
            this.router.navigate([`/application/content/content-feed/${obj.id}`])
        }
        else {
            this.router.navigate([`/application/content/${obj.id}`])
        }
    }

    openNewContent(){
        this.modalMsg = this.modalService.show(ModalMessageComponent, {
            // backdrop: 'static',
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Adicionar Content',
                text: 'Qual estilo de Content quer adicionar ?',
                has_button: true,
                button_text: 'Post',
                button_cancel: 'Campanha'
            }
        })    
        this.modalMsg.content.action.subscribe( (result: any) => { 
            if(result == 'no'){
                this.router.navigate(['/application/content/new-content'])
            }
            if(result == 'yes'){
                this.router.navigate(['/application/content/content-feed'])
            }
        }); 
    }

    openRelatorio(){
        this.modalDelet = this.modalService.show(ReportCampaignComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered',
            containerClass: '',
            animated: false,
        })   
        
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir content',
                text: 'Deseja excluir a content '+obj.name+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.deleteCampaign(obj.id)
            }
        }); 

    }

}
