import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-fire-extinguishers',
  templateUrl: './checklist-fire-extinguishers.component.html',
  styleUrls: ['./checklist-fire-extinguishers.component.css']
})

export class ChecklistFireExtinguishersComponent implements OnInit, AfterViewInit {

  table: [];
  header: [];
  footer: [];
  list_description: [];
  title: string;

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.footer)
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }
}
