import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../service/registration.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../service/compressor.service';
import { AppConfig } from '../../app.config';
import { RegistrationConfig } from 'src/app/model/registrationConfig';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalConfigPreviewComponent } from '../modal-config-preview/modal-config-preview.component';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { CdkDrag, CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { StorageService } from 'src/app/service/storage.service';

@Component({
    selector: 'app-registration-config-detail',
    templateUrl: './registration-config-detail.component.html',
    styleUrls: ['./registration-config-detail.component.css']
})
export class RegistrationConfigDetailComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private compressor: CompressorService,
        private registrationService: RegistrationService,
        private modalService: MDBModalService,
        private storageService: StorageService
    ) { 
        this.config_id = this.route.snapshot.paramMap.get('id');
    }
    modalRef: MDBModalRef;

    loading: boolean = false;
    warningMap = new Map();

    id_establishment = null;
    config_id = null;

    register = {
        id: null,
        title: null,
        description: null,
        terms: null,
        image: null,
        establishment: null,
        order_fields: null,
        birthdate: false,
        birthdate_mandatory: false,
        check_sms: false,
        cpf: false,
        cpf_mandatory: false,
        email: false,
        email_mandatory: false,
        name: false,
        name_mandatory: false,
        phone: false,
        phone_mandatory: false,
        sexual_orientation: false,
        sexual_orientation_mandatory: false,
        gender_identity: false,
        gender_identity_mandatory: false,
        cep: false,
        cep_mandatory: false
    }

    list_register = []


    todo1 = [
        { 
            title: 'Telefone', 
            active: false,
            active_mandatory: false,
            options: true,
            check_sms: false,
            checkbox_sms: false
        }
    ];
    todo2 = [
        { 
            title: 'E-mail', 
            active: true,
            active_mandatory: false,
            options: false,
        }
    ];
    todo3 = [
        { 
            title: 'Nome', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    todo4 = [
        { 
            title: 'CPF', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    todo5 = [
        { 
            title: 'Data de nascimento', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    todo6 = [
        { 
            title: 'Orientação Sexual', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    todo7 = [
        { 
            title: 'Identidade de Gênero', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    todo8 = [
        { 
            title: 'CEP', 
            active: false,
            active_mandatory: false,
            options: false,
        }
    ];
    
    list_order = []
    checkSms;

    order_fields;
    pdfTerm;
    compressed_image = []; // as the name says...

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')
        
        if(this.config_id != null) this.getRegistration()

    }

    getRegistration(){
        this.registrationService.getRegistrationId(this.config_id).subscribe(data=>{
            
            if(data != null){

                this.register.id = data.id;
                this.register.title = data.title;
                this.register.description = data.description;
                this.register.terms = data.terms;
                this.register.image = data.image;

                this.orderList(data)
               
                if(this.register.terms != null){
                    this.pdfTerm = 'termos.pdf';
                }
            }

        })
    }

    orderList(data){
        var list_aux = []
        list_aux = data.order_fields.split(', ')

        list_aux.forEach(item => {
            switch(item){
                case "Telefone":
                    this.list_order.push({title: 'Telefone', active: data.phone, active_mandatory: data.phone_mandatory, options: true, check_sms: data.check_sms, checkbox_sms: false})
                    break;
                case "E-mail":
                    this.list_order.push({title: 'E-mail', active: data.email, active_mandatory: data.email_mandatory, options: false})
                    break;
                case "Nome":
                    this.list_order.push({title: 'Nome', active: data.name, active_mandatory: data.name_mandatory, options: false})
                    break;
                case "CPF":
                    this.list_order.push({title: 'CPF', active: data.cpf, active_mandatory: data.cpf_mandatory, options: false})
                    break;
                case "Data de nascimento":
                    this.list_order.push({title: 'Data de nascimento', active: data.birthdate, active_mandatory: data.birthdate_mandatory, options: false})
                    break;
                case "Orientação Sexual":
                    this.list_order.push({title: 'Orientação Sexual', active: data.sexual_orientation, active_mandatory: data.sexual_orientation_mandatory, options: false})
                    break;
                case "Identidade de Gênero":
                    this.list_order.push({title: 'Identidade de Gênero', active: data.gender_identity, active_mandatory: data.gender_identity_mandatory, options: false})
                    break;
                case "CEP":
                    this.list_order.push({title: 'CEP', active: data.cep, active_mandatory: data.cep_mandatory, options: false})
                    break;           
            }
        });       

        this.todo1[0] = this.list_order[0]
        this.todo2[0] = this.list_order[1]
        this.todo3[0] = this.list_order[2]
        this.todo4[0] = this.list_order[3]
        this.todo5[0] = this.list_order[4]
        this.todo6[0] = this.list_order[5]
        this.todo7[0] = this.list_order[6]
        this.todo8[0] = this.list_order[7]
    }

    phoneOrEmail(text, value){

        if(text == 'E-mail' && !value){
            this.todo1.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo2.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo3.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo4.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo5.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo6.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo7.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
            this.todo8.forEach(item => {
                if(item.title == 'Telefone') item.active = true;
            });
           
        }
        if(text == 'Telefone' && !value){
            this.todo1.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo2.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo3.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo4.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo5.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo6.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo7.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
            this.todo8.forEach(item => {
                if(item.title == 'E-mail') item.active = true;
            });
        }
    }

    configOrder(){
        this.list_register = []

        this.list_register.push(this.todo1[0])
        this.list_register.push(this.todo2[0])
        this.list_register.push(this.todo3[0])
        this.list_register.push(this.todo4[0])
        this.list_register.push(this.todo5[0])
        this.list_register.push(this.todo6[0])
        this.list_register.push(this.todo7[0])
        this.list_register.push(this.todo8[0])

        this.order_fields = `${this.list_register[0].title}, ${this.list_register[1].title}, ${this.list_register[2].title}, ${this.list_register[3].title}, ${this.list_register[4].title}, ${this.list_register[5].title}, ${this.list_register[6].title}, ${this.list_register[7].title}`
    
    }

    drop(event: CdkDragDrop<any>) {
        if (event.previousContainer === event.container) {
          moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
        }

        if(this.todo1[0] == null){ this.todo1[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo2[0] == null){ this.todo2[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo3[0] == null){ this.todo3[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo4[0] == null){ this.todo4[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo5[0] == null){ this.todo5[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo6[0] == null){ this.todo6[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo7[0] == null){ this.todo7[0] = event.container.data[0]; event.container.data.splice(0, 1) }
        if(this.todo8[0] == null){ this.todo8[0] = event.container.data[0]; event.container.data.splice(0, 1) }
    }


    sortPredicate(index: number, item: CdkDrag<number>) {
        return (index + 1) % 2 === item.data % 2;
    }


    checkInputs(){
        this.warningMap = new Map();

        if(this.register.title == null || this.register.title == ""){
            this.warningMap.set('title', true);
        }
        if(this.register.description == null || this.register.description == ""){
            this.warningMap.set('description', true);
        }
        if(this.register.terms == null || this.register.terms == ""){
            this.warningMap.set('term', true);
        }
        if(this.register.image == null || this.register.image == ""){
            this.warningMap.set('image', true);
        }

        // if(!this.register.name){ this.register.name_mandatory = false; }
        // if(!this.register.email){ this.register.email_mandatory = false; }
        // if(!this.register.birthdate){ this.register.birthdate_mandatory = false; }
        // if(!this.register.cpf){ this.register.cpf_mandatory = false; }
        // if(!this.register.sexual_orientation){ this.register.sexual_orientation_mandatory = false; }
        // if(!this.register.gender_identity){ this.register.gender_identity_mandatory = false; }
        // if(!this.register.phone){ 
        //     this.register.phone_mandatory = false; 
        //     this.register.check_sms = false; 
        // }

        if(this.warningMap.size == 0){
            if(this.register.id == null)
                this.postRegistration()
            else{ 
                this.createListSend()
                this.patchRegistration()

            }   
        }
    }

    postRegistration(){

        this.configOrder()
        
        this.loading = true;

        this.registrationService.postRegistration(
            this.register.title, this.register.description, this.register.image,
            this.register.terms, this.order_fields, this.list_register ).subscribe(data=>{
            
                this.loading = false;
                this.router.navigate(['/application/register'])
            },
            error=>{
                this.loading = false;
            })
    }

    createListSend(){
        this.configOrder()

        this.register.id = this.register.id;
        this.register.establishment = this.id_establishment;
        this.register.title = this.register.title;
        this.register.description = this.register.description;
        this.register.image = this.register.image;
        this.register.terms = this.register.terms;
        this.register.order_fields = this.order_fields;

        this.list_register.forEach(item => {
            switch(item.title){
                case "Telefone":
                    this.register.phone = item.active;
                    this.register.phone_mandatory = item.active_mandatory;
                    this.register.check_sms = item.check_sms;
                    break;
                case "E-mail":
                    this.register.email = item.active;
                    this.register.email_mandatory = item.active_mandatory;
                    break;
                case "Nome":
                    this.register.name = item.active;
                    this.register.name_mandatory = item.active_mandatory;
                    break;
                case "CPF":
                    this.register.cpf = item.active;
                    this.register.cpf_mandatory = item.active_mandatory;
                    break;
                case "Data de nascimento":
                    this.register.birthdate = item.active;
                    this.register.birthdate_mandatory = item.active_mandatory;
                    break;
                case "Orientação Sexual":
                    this.register.sexual_orientation = item.active;
                    this.register.sexual_orientation_mandatory = item.active_mandatory;
                    break;
                case "Identidade de Gênero":
                    this.register.gender_identity = item.active;
                    this.register.gender_identity_mandatory = item.active_mandatory;
                    break;
                case "CEP":
                    this.register.cep = item.active;
                    this.register.cep_mandatory = item.active_mandatory;
                    break;           
            }
        });  
            
    }

    patchRegistration(){
        this.loading = true;

        this.registrationService.patchRegistration(this.register).subscribe(data=>{
            
                this.loading = false;
                this.router.navigate(['/application/register'])
            },
            error=>{
                this.loading = false;
            })
    }

    onFilePdf(event){
        if (event.target.files && event.target.files[0]) {
			var reader = new FileReader()
			reader.readAsDataURL(event.target.files[0])
			reader.onloadend = (e) => {
				this.register.terms = reader.result
                this.pdfTerm = event?.target?.files[0]?.name;

			}
		}

    }

    openPdf(link){
        if(link != null){
            // if(link.substring(0,4) != 'http'){
            //     window.open(`${link}`);
            // }
            // else{
                window.open(link, '_blanck');
            // }
        }
    }
    
    async onFileChanged(event){
		if (event.target.files && event.target.files[0]) {
			var target = event.target.files
			const compress = this.recursiveCompress( target[0], 0, target ).pipe(
				expand(res => {
					  return res.index > res.array.length - 1
					? EMPTY
					: this.recursiveCompress( target[res.index], res.index, target );
				}),
			);
			compress.subscribe(res => {
				if (res.index > res.array.length - 1) {
                    this.register.image = this.compressed_image[0]
					this.compressed_image = []

				}
			});
		}
	}

    recursiveCompress = (image: File, index, array) => {
		return this.compressor.compress(image).pipe (
		  	map(response => {
				this.compressed_image.push(response)
				return {
					data: response,
					index: index + 1,
					array: array,
				};
		  	}),
		);
	}

    openView(){
        this.createListSend()
        
        this.modalRef = this.modalService.show(CellPhoneComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                isList: this.register,
                isEdit: true,
                isPopup: 'config'
            }
        })   
    }

}
