<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Notícias </h3>

    </div>

    <input mat-input [(ngModel)]="notice.name" placeholder="Escreva o título da notícia..." class="input"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}">

    <textarea mat-input [(ngModel)]="notice.describe" style="resize: none;" class="input"
        placeholder="Escreva aqui o olho da notícia..."
        [ngStyle]="{'border-color': warningMap.get('describe')? 'red' : ''}">
    </textarea>

    <mat-accordion>
        <div cdkDropList (cdkDropListDropped)="drop($event)">

            <div *ngFor="let obj of notice.news; let i = index" cdkDrag style="margin: 10px 0;">

                <mat-expansion-panel [expanded]="indexExpanded == i">

                    <mat-expansion-panel-header (click)="togglePanels(i)">
                        <mat-panel-title>

                            {{ 'Paragráfo #' + (i+1) }}

                        </mat-panel-title>
                    </mat-expansion-panel-header>


                    <div class="card_row">
                        <textarea mat-input [(ngModel)]="obj.text" placeholder="Escreva aqui os paragráfos..."
                            [ngStyle]="{'border-color': warningMap.get('notice'+i)? 'red' : ''}" style="resize: none; height: 150px;"
                            class="input"></textarea>

                        <div class="row_image_date">
                            <div class="coll-image input-img"
                                [ngStyle]="{'border-color': warningMap.get('image'+i)? 'red' : ''}">

                                <div *ngIf="obj.image == null || obj.image == ''" class="button-image"
                                    (click)="fileImage.click()">
                                    adicione uma imagem
                                </div>

                                <input type="file" #fileImage style="display: none; " accept="image/*"
                                    (change)="onFileChanged($event, i)">

                                <img [src]="obj.image" (click)="fileImage.click()" *ngIf="obj.image != null"
                                    class="profile-image">
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%; margin-top: 20px; display: flex; justify-content: flex-end;">
                        <button mat-button class="button-cor mw_auto" style="background-color: rgb(187, 4, 4);"
                            (click)="removeParagraph(obj, i)">
                            Apagar Paragráfo
                        </button>
                    </div>

                </mat-expansion-panel>
            </div>
        </div>
    </mat-accordion>

    <div style="display: flex; margin-top: 10px;">
        <button mat-button class="button-cor" [style.background]="color_primary" style="margin-right: 10px;"
            (click)="addParagraph()">
            Adicionar um paragrafo
        </button>

        <!-- <button mat-button class="button-view" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou o catálogo
        </button> -->

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()">
            {{ notice_id == null ? 'Finalizar Notícia' : 'Salvar Notícia' }}
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status"
                aria-hidden="true"></span>
        </button>
    </div>

</div>