import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-rede',
	templateUrl: './rede.component.html',
	styleUrls: ['./rede.component.css']
})

export class RedeComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
		
	}

}
