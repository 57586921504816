import { dateMapper } from "src/app/utils/validator.utils";

export const getPage3Config = () => ({

    // Table 1
    header_table_1: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 1, key: 'title_3' },
            { row: 110, col: 1, key: 'place' },
        ],
    },
    table_1: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 109,
        endRows: 116,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 2
    header_table_2: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 1, key: 'title_3' },
            { row: 117, col: 1, key: 'place' },
        ],
    },
    table_2: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 116,
        endRows: 121,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 3
    header_table_3: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 1, key: 'title_3' },
            { row: 122, col: 1, key: 'place' },
        ],
    },
    table_3: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 121,
        endRows: 128,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },

    // Table 4
    header_table_4: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 1, key: 'title_3' },
            { row: 129, col: 1, key: 'place' },
        ],
    },
    table_4: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 128,
        endRows: 133,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },

    // ------- RIGHT -------


    // Table 5
    header_table_5: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 6, key: 'title_3' },
            { row: 110, col: 6, key: 'place' },
        ],
    },
    table_5: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 109,
        endRows: 116,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table 6
    header_table_6: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 6, key: 'title_3' },
            { row: 117, col: 6, key: 'place' },
        ],
    },
    table_6: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 116,
        endRows: 121,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table 7
    header_table_7: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 6, key: 'title_3' },
            { row: 122, col: 6, key: 'place' },
        ],
    },
    table_7: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 121,
        endRows: 128,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table 8
    header_table_8: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 107, col: 1, key: 'title' },
            { row: 108, col: 1, key: 'title_2' },
            { row: 109, col: 6, key: 'title_3' },
            { row: 129, col: 6, key: 'place' },
        ],
    },
    table_8: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 128,
        endRows: 133,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },
});
