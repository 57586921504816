<div style="margin: 0 50px;">
    <br><br>
  
    <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
      src="../../../../assets/image/logo_principal_SBS.png">
    <h2>Formulário de Inspeção nas saídas de emergências MENSAL</h2>
    <br><br>
<table *ngIf="local == 'PRÉDIOS EM GERAL'">
    <tr style="font-size: 9px;">
        <th> Prédio</th>
        <th> Localização </th>
        <th> Observação </th>
        <th style="width: 5%"> C </th>
        <th style="width: 5%"> N/C </th>
    </tr>

    <tr *ngFor="let row of table" style="font-size: 8px;">
        <td> {{ row['Prédio'] }} </td>
        <td> {{ row['Localização'] }} </td>
        <td> {{ row['Observação'] }} </td>
        <td> {{ row['Capacidade'] }} </td>
        <td> {{ row['Garantia Serv'] }} </td>
        <td> {{ row['Validade Teste'] }} </td>
        <td> {{ row['C'] }} </td>
        <td> {{ row['N/C'] }} </td>
    </tr>
</table>

<table *ngIf="local == 'EXTERNO'">
    <tr style="font-size: 9px;">
        <th> Localização </th>
        <th> Numero Chassis </th>
        <th> Tipo </th>
        <th> Capacidade </th>
        <th> Garantia Serv </th>
        <th> Validade Teste </th>
        <th> C </th>
        <th> NC </th>
    </tr>

    <tr *ngFor="let row of table" style="font-size: 8px;">
        <td> {{ row["Localização"] }} </td>
        <td> {{ row['Numero Chassis'] }} </td>
        <td> {{ row['Tipo'] }} </td>
        <td> {{ row['Capacidade'] }} </td>
        <td> {{ row['Garantia Serv'] }} </td>
        <td> {{ row['Validade Teste'] }} </td>
        <td> {{ row['C'] }} </td>
        <td> {{ row['NC'] }} </td>
    </tr>
</table>
<br><br>

<table *ngIf="local == 'ENERGY CENTER'">
    <tr style="font-size: 9px;">
        <th> Localização </th>
        <th> Numero Chassis </th>
        <th> Tipo </th>
        <th> Capacidade </th>
        <th> Garantia Serv </th>
        <th> Validade Teste </th>
        <th style="width: 5%"> C </th>
        <th style="width: 5%"> N/C </th>
    </tr>

    <tr *ngFor="let row of table" style="font-size: 8px;">
        <td> {{ row['Localização'] }} </td>
        <td> {{ row['Numero Chassis'] }} </td>
        <td> {{ row['Tipo'] }} </td>
        <td> {{ row['Capacidade'] }} </td>
        <td> {{ row['Garantia Serv'] }} </td>
        <td> {{ row['Validade Teste'] }} </td>
        <td> {{ row['C'] }} </td>
        <td> {{ row['N/C'] }} </td>
    </tr>
</table>

<br><br>

<table>
    <tr style="font-size: 9px;">
        <th> Elaborado </th>
        <th> Revisado: </th>
        <th> Aprovado </th>
        <th> Revisão: </th>
        <th> Data: </th>
        <th> Referência </th>
    </tr>

    <tr style="font-size: 8px;">
        <td> {{ footer['Elaborado'] }} </td>
        <td> </td>
        <td> {{ footer['Aprovado'] }} </td>
        <td> </td>
        <td> {{ footer['Data'] }} </td>
        <td> {{ footer['Referência'] }} </td>
    </tr>
</table>
</div>