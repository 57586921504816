import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LikeService {

    constructor(private http : HttpClient) { }

    likeHomepage(homepage_image): Observable<any>{

        const body = new HttpParams()
            .set(`homepage_image`, homepage_image)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('User', `${localStorage.getItem('tokenUser')}`)

        return this.http.post<any>(AppConfig.path + `core/like-homepage-image/`, body, { headers })
    }

    likePage(page_image): Observable<any>{

        const body = new HttpParams()
            .set(`page_image`, page_image)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('User', `${localStorage.getItem('tokenUser')}`)

        return this.http.post<any>(AppConfig.path + `core/like-page-image/`, body, { headers })
    }

    likeInternalPage(internal_image): Observable<any>{

        const body = new HttpParams()
            .set(`internal_page`, internal_image)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('User', `${localStorage.getItem('tokenUser')}`)

        return this.http.post<any>(AppConfig.path + `core/like-internal-page-image/`, body, { headers })
    }
}