import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from './custom-encoder';
import { Md5 } from "md5-typescript";

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(private http : HttpClient) { }

    auth(username, password): Observable<any>{
        
        var passwordMD5 = Md5.init(password).toUpperCase();

        const body = new HttpParams({encoder: new CustomEncoder()})
            .set(`username`, username)
            .set(`password`, passwordMD5)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

        return this.http.post<any>(AppConfig.path + `core/auth/`, body.toString(), { headers })
    }

    getUser() : Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/user/`, {headers});
    }

    patchUser(id, establishment) : Observable<any> {
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`establishment`, establishment)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/user/${id}/`, body, {headers});
    }
}