import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-hidrante',
  templateUrl: './control-hidrante.component.html',
  styleUrls: ['./control-hidrante.component.css']
})

export class ControlHidranteComponent implements OnInit, AfterViewInit {

  title: string;
  local: string;
  table: [];
  list_description: [];
  header: [];
  footer: [];

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.table);
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }
}