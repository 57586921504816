<div class="screen">

    <div style="width: 100%; position: absolute; top: 0; left: 0;">
        <!-- VIMEO -->
        <div id="video_vimeo" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
            'video-mobile' : urlPage != 'redirect'}"></div>

        <!-- YOUTUBE -->
        <div id="video_youtube" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
            'video-mobile' : urlPage != 'redirect'}"></div>

    </div>

    <div class="div-imagens" *ngIf="!preview_campaign && loading">
        <div *ngFor="let item of listImage" style="width: 100%;">

            <!-- preview screen campaign -->
            <img [src]="item.image" class="image_background" [ngStyle]="{'border-color': header.back}">
        </div>
    </div>

    <!-- preview new campaign -->
    <img *ngIf="preview_campaign && listImage.background_image != null" [src]="listImage.background_image.url"
        class="image_background" [ngStyle]="{'border-color': header.back}">
</div>