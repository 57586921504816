import { dateMapper } from "src/app/utils/validator.utils";

export const getEnergyCenterEmergencyConfig = () => ({
    header: {
        worksheet: 'ENERGY CENTER',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            // { row: 2, col: 2, key: 'place' },
            // { row: 6, col: 1, key: 'verifiedBy' },
            // { row: 6, col: 3, key: 'date' },
            // { row: 6, col: 5, key: 'hour' },
            // { row: 6, col: 6, key: 'month' },
        ],
    },

    items_1: {
        worksheet: 'ENERGY CENTER',
        type: 'list',
        rowOffset: 23,
        endRows: 40,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'chassis_number'},
            { index: 3, key: 'type'},
            { index: 4, key: 'capacity'},
            { index: 5, key: 'service_warranty', mapper: dateMapper},
            { index: 6, key: 'teste_validity', mapper: dateMapper},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    items_2: {
        worksheet: 'ENERGY CENTER',
        type: 'list',
        rowOffset: 43,
        endRows: 61,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'chassis_number'},
            { index: 3, key: 'type'},
            { index: 4, key: 'capacity'},
            { index: 5, key: 'service_warranty', mapper: dateMapper},
            { index: 6, key: 'teste_validity', mapper: dateMapper},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    description: {
        worksheet: 'ENERGY CENTER',
        type: 'list',
        rowOffset: 62,
        endRows: 69,
        columns: [
            { index: 1, key: 'name'},
            { index: 2, key: 'value'},
        ],
    },

    footer: {
        worksheet: 'ENERGY CENTER',
        type: 'list',
        rowOffset: 41,
        endRows: 43,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 3, key: 'approved' },
            { index: 5, key: 'date', mapper: dateMapper },
            { index: 6, key: 'reference' },
        ],
    },

});
