import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ScheduleReminderService {

    constructor(private http : HttpClient) { }

    getScheduleReminderByIdReminder(reminder): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`reminder`, reminder)
        };

        return this.http.get<any>(AppConfig.path + `notification/schedule/`, options)
    }

    postScheduleReminder(reminder, day, hours): Observable<any>{

        let body: any = JSON.stringify({
            reminder: reminder,
            day: day, 
            hours: hours
        })

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "notification/schedule/add/", body, { headers })
    }

    patchScheduleReminder(id, reminder, day, hours): Observable<any>{
        let body: any = JSON.stringify({
            reminder: reminder,
            day: day, 
            hours: hours
        })

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `notification/schedule/${id}/`, body, { headers });
    }

    deleteScheduleReminder(id): Observable<any>{

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`)
        };

        return this.http.delete<any>(AppConfig.path + `notification/schedule/${id}/`, options);
    }

}
