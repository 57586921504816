import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StorageService } from '../service/storage.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

	constructor(
		private router: Router,
		private storageService: StorageService
	){
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				if(this.router.url == "/home"){
					this.is_home = true;
				}
				else{
					this.is_home = false;
				}
			}
		});
	}

	
	
	is_home: boolean = false;

	page_scrol;
	is_scroll: boolean = true;

	eventsSubject: Subject<void> = new Subject<void>();

	ngOnInit(): void {
	}
	
	emitEventToChild() {
		this.eventsSubject.next(this.page_scrol);
	}
   
	select(text){
		this.page_scrol = text;
		this.emitEventToChild()
	}

}
