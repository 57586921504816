import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import { TabloideService } from 'src/app/service/tabloide.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-phase-order',
  templateUrl: './phase-order.component.html',
  styleUrls: ['./phase-order.component.css']
})
export class PhaseOrderComponent implements OnInit {

  constructor(
    private tabloideService: TabloideService,
    private orderService: OrderService,
  ) { }

  @Input() list_order: any
  @Input() tabloide: any
  @Input() establishment_id: any
  @Output() newItemEvent = new EventEmitter();

  loading: boolean = false;

  warningMap = new Map();
  validator = new ValidatorUtils()

  list_brand: any = []

  payment = {
    flag: '',
    number: '',
    name_card: '',
    date_card: '',
    code_card: '',
    address: {
      name: '',
      number: '',
      cep: '',
      district: '',
    }
  }

  ngOnInit(): void {
    console.log(this.tabloide);
    console.log(this.list_order);

    let token = localStorage.getItem('tokenUser')

    // if(token == null || token == '') {
    //   this.newItemEvent.emit('LOGIN');
    // }

    this.getListBrands()
  }

  getListBrands(){
    this.orderService.getListBrands().subscribe(data=>{
     this.list_brand = data;
    },
    error=>{
       console.log(error)
    })
  }

  checkInput(){
    this.warningMap = new Map();

    if(this.validator.isEmptyOrNull(this.payment.flag)){ 
      this.warningMap.set('flag', true);
      console.log('flag');
    }
    if(this.validator.isEmptyOrNull(this.payment.number)){ 
      this.warningMap.set('number', true);
      console.log('number');
    }
    if(this.validator.isEmptyOrNull(this.payment.name_card)){ 
      this.warningMap.set('name_card', true);
      console.log('name_card');
    }
    if(this.validator.isEmptyOrNull(this.payment.date_card)){ 
      this.warningMap.set('date_card', true);
      console.log('date_card');
    }
    if(this.validator.isEmptyOrNull(this.payment.code_card)){ 
      this.warningMap.set('code_card', true);
      console.log('code_card');
    }

    if (this.validator.isEmptyOrNull(this.payment.address.name)) {
      this.warningMap.set('address_name', true);
      console.log('address_name');
    }
    if (this.validator.isEmptyOrNull(this.payment.address.number)) {
      this.warningMap.set('address_number', true);
      console.log('address_number');
    }
    if (this.validator.isEmptyOrNull(this.payment.address.cep)) {
      this.warningMap.set('address_cep', true);
      console.log('address_cep');
    }
    if (this.validator.isEmptyOrNull(this.payment.address.district)) {
      this.warningMap.set('address_district', true);
      console.log('address_district');
    }

    console.log(this.warningMap.size);
    
    if (this.warningMap.size == 0){
      this.postOrder()
    }
  }

  back(){
    this.newItemEvent.emit('back');
  }

  postOrder(){
    this.loading = true;

    let list_send = []
    let token = localStorage.getItem('tokenUser')
    
    this.list_order.forEach(item => {
      let index = list_send.findIndex(el => el.id == item.id)

      if(index != null && index != -1){ list_send[index].count ++ }
      else{ list_send.push({ id: item.id, count: 1 }) }
    });

    console.log(this.tabloide);
    console.log(list_send);
                        
    if (token != null) {
      this.tabloideService.postOrder(this.tabloide.id, this.payment.address, list_send, token, this.establishment_id).subscribe(data=>{
          this.loading = false;
          console.log('--ORDER--');
          this.newItemEvent.emit('order-ok');
        },
        error=>{
         console.log('--ERROR--');
          this.loading = false;
          console.log(error)
       })
    }
    else {
      this.newItemEvent.emit('LOGIN');
    }
  }
  

}
