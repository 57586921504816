import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith, expand } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { ModalMessageComponent } from '../../modals/modal-message/modal-message.component';
import { CampaignService } from '../../service/campaign.service';
import { CatalogService } from '../../service/catalog.service';
import { CompressorService } from '../../service/compressor.service';
import { ConstantsService } from '../../service/constants.service';
import { CouponService } from '../../service/coupon.service';
import { QrCodeService } from '../../service/qrcode.service';
import { RegistrationService } from '../../service/registration.service';
import { SurveyService } from '../../service/survey.service';
import { ModalPreviewComponent } from '../../modals/modal-preview/modal-preview.component';
import { StorageService } from 'src/app/service/storage.service';
import { TagService } from 'src/app/service/tag.service';
import { GroupService } from 'src/app/service/group.service';
import { CourseService } from 'src/app/service/course.service';
import { PopupGenerateFileLinkComponent } from 'src/app/modals/popup-generate-file-link/popup-generate-file-link.component';
import { TabloideService } from 'src/app/service/tabloide.service';
import { Image_campaign } from 'src/app/model/campaign';

@Component({
    selector: 'app-create-campaign',
    templateUrl: './create-campaign.component.html',
    styleUrls: ['./create-campaign.component.css']
})

export class CreateCampaignComponent implements OnInit {

    constructor(
        private constantsService: ConstantsService,
        private campaignService: CampaignService,
        private compressor: CompressorService,
        private catalogService: CatalogService,
        private tabloideService: TabloideService,
        private router: Router,
        private qrCodeService: QrCodeService,
        private modalService: MDBModalService,
        private surveyService: SurveyService,
        private couponService: CouponService,
        private route: ActivatedRoute,
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private tagService: TagService,
        private groupService: GroupService,
        private courseService: CourseService,
    ) {
        // this.campaign_id = this.route.snapshot.paramMap.get('id');
    }

    @ViewChild('establishmentInput') establishmentInput: ElementRef<HTMLInputElement>;
    @ViewChild('establishmentInputTag') establishmentInputTag: ElementRef<HTMLInputElement>;
    @ViewChild('establishmentInputGroup') establishmentInputGroup: ElementRef<HTMLInputElement>;

    modalRef: MDBModalRef;
    modalImport: MDBModalRef;

    link: string = AppConfig.path;
    warningMap = new Map();
    error = {
        msg: "",
        check: false
    };

    loading = {
        establishment: false,
        constants: false,
        finish: false
    }

    surveys = [];
    catalogs = [];
    campaigns = [];
    homepage_types = [];
    page_types = [];

    steps = { campaign: false, homepage: false, homepage_images: false, page_types: false, internal_pages: false };
    actual_step: string = "campaign";

    // campaign
    campaign_id = null;
    language: string;
    campaign_name: string = "";
    show_header: boolean = true;
    campaign_header: string = "";
    registration_type: string;
    mobile = { android: false, ios: false };
    color_background: string = "#000000";
    color_text: string = "#ffffff";
    color_icons: string = '#ffffff'
    selected_colors = { bg: false, text: false, icon: false };
    whatsapp: string = '';
    image_logo = { name: '', url: null };
    side_menu = { name: '', url: null };
    pdfTerm;
    pdfTerm_name;
    is_point_control: boolean = false;

    // qrcode chips
    qrcodes = [];
    arrayChips = [];
    listQrCode = [];
    deliteQrcode = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    qrcodeChipsFormControl = new FormControl();
    filteredQrcodes: Observable<string[]>;

    // group chips
    groups = [];
    arrayGroup = [];
    listGroup = [];
    deliteGroup = [];
    separatorKeysCodesGroup: number[] = [ENTER, COMMA];
    groupChipsFormControl = new FormControl();
    filteredGroups: Observable<string[]>;

    // tag chips
    tags = [];
    list_tag_campaign = { homepage: [], page: [], internal: [] }

    // sub tags

    subtags = [];

    tagArrayChipsHomepage = [];
    tagArrayChipsPage = [];
    tagArrayChipsInternal = [];
    listTag = { home: [], page: [], internal: [] };
    // tagArrayChips = [];
    // listTag = [];

    deliteTag = { homepage: [], page: [], internal: [] };
    separatorKeysCodesTag: number[] = [ENTER, COMMA];
    tagChipsFormControl = new FormControl();

    filteredTagHomepage: Observable<string[]>;
    filteredTagPage: Observable<string[]>;
    filteredTagInternal: Observable<string[]>;
    // filteredTag: Observable<string[]>;

    // coupons
    list_coupon = [{ id: -1, name: 'Nenhum cupom' }];
    coupon;
    id_coupon;

    // course
    list_course = [];

    // configuração
    list_config = [{ id: -1, title: 'Nenhuma configuração' }];
    config;
    id_config;

    list_notice = [{ id: -1, name: 'Nenhum notícia' }]

    list_tabloide = [{ id: -1, name: 'Nenhum tabloide' }]

    selected_homepage_type; // homepage type object
    links = {}; // object for inputs of links/videos
    compressed_image = []; // as the name says...

    input_facebook: string = '';
    input_instagram: string = '';
    input_youtube: string = '';

    campaign_select;

    homepage: Image_campaign = {
        id: null,
        type: null,
        quantity: null,
        link: null,
        option_link: null,
        catalog: null,
        coupon: null,
        registration_config: null,
        background_image: null,
        images: [],
        order: null,
        name: null,
        url: null,
        survey: null,
        video: null,
        level: null,
        title: null,
        point_control_name: null,
        description: null,
        course: null,
        notice: null,
        tabloide: null,
        point_type: null,
        castbox: null,
        deezer: null,
        itunes: null,
        google_podcast: null,
        token_hashlar: null
    } // everything

    cont = {
        home: 0,
        page: 0
    }

    option_link = [
        { name: 'Link', link: '', exem: 'Insira um link' },
        { name: 'WhatsApp', link: 'https://wa.me/', exem: '+00 (00) 0 0000 0000' },
        { name: 'Telefone', link: 'tel:', exem: '00 0000 0000' },
        { name: 'Email', link: 'mailto:', exem: 'Insira seu email' },
        { name: 'Relatório', link: '', exem: 'Insira seu email' }
    ]
    option_select_link;

    remove = {
        homepage: [],
        page: [],
        internal: []
    }

    page = {
        survey: 1,
        coupon: 1,
        qrcode: 1,
        tag: 1,
        group: 1,
        course: 1
    }

    id_tags: string = null;
    name: string = null;

    selected_homepage_image: number = 0; //var for index of homepage image selected in pages formats
    selected_page_image: number = 0; //var for index of page image selected in pages formats

    id_establishment = null;

    color_primary
    validTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']

    ngOnInit(): void {
        this.whatsapp = '55'

        this.color_primary = this.storageService.establishment.color;

        this.option_select_link = this.option_link[0]

        this.id_establishment = localStorage.getItem('id_establishment')

        this.language = 'PT'
        this.registration_type = 'HOMEPAGE'
        this.config = this.list_config[0].id

        this.getConstants()
        this.getCatalogs(1)
        this.getCampaigns(1)
        this.getCoupons()
        this.getRegistration(1)
        this.getNotices(1)
        this.getTabloide(1)
        this.getQrCode(this.page.qrcode)
        this.getTags(this.page.tag)
        this.getGroups(this.page.group)
        this.getSurveys()
        this.getCouser(this.page.course)
    }

    getConstants() {
        this.loading.constants = true;

        this.constantsService.getConstants().subscribe(data => {

            this.homepage_types = data.homepage_types;
            this.page_types = data.page_types;

            data.homepage_types = data.homepage_types.splice(AppConfig.qtd_homepage_types)
            data.page_types = data.page_types.splice(AppConfig.qtd_page_types)

            this.loading.constants = false;

        },
            error => {
                this.loading.constants = false;
            })
    }

    getCatalogs(page) {
        this.catalogService.getCatalogs(page).subscribe(data => {
            this.catalogs = this.catalogs.concat(data.results);
            if (data.next != null) {
                this.getCatalogs(page + 1)
            }
        })
    }

    getCampaigns(page) {
        this.campaignService.getCampaigns(page).subscribe(data => {
            this.campaigns = this.campaigns.concat(data.results);

            if (data.next != null) {
                this.getCampaigns(page + 1)
            }
        })
    }

    getCoupons() {
        this.couponService.getCoupons(this.page.coupon).subscribe(data => {
            data.results.forEach(el => {
                this.list_coupon.push(el)
            });

            if (data.next != null) {
                this.page.coupon += 1;
                this.getCoupons()
            }
        })
    }

    getRegistration(page) {
        this.registrationService.getRegistration(page).subscribe(data => {

            this.list_config = this.list_config.concat(data.results)
            if (data.next != null) {
                this.getRegistration(page + 1)
            }
        },
            error => {
            })
    }

    getNotices(page) {
        this.catalogService.getCatalogs(page).subscribe(data => {
            this.list_notice = this.list_notice.concat(data.results);
            if (data.next != null) {
                this.getNotices(page + 1)
            }
        })
    }

    getTabloide(page) {
        this.tabloideService.getTabloide(page).subscribe(data => {
            this.list_tabloide = this.list_tabloide.concat(data.results);
            if (data.next != null) {
                this.getTabloide(page + 1)
            }
        })
    }

    getSurveys() {
        this.surveyService.getSurvey_EstablishmentId(this.id_establishment, 1).subscribe(data => {
            this.surveys = this.surveys.concat(data.results);
        })
    }

    getCouser(page) {
        this.courseService.getCourse(page).subscribe(data => {
            this.list_course = this.list_course.concat(data.results)

            if (data.next != null) {
                this.page.course += 1
                this.getCouser(this.page.course)
            }

        }, error => {
            console.log(error)
        })
    }

    newNivel() {
        this.homepage.images[this.selected_homepage_image]['level'] = true;
    }

    onFilePdf(event) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onloadend = (e) => {
                this.pdfTerm = reader.result
                this.pdfTerm_name = event?.target?.files[0]?.name;
            }
        }
    }

    openPdf(link) {
        if (link != null) {
            // if(link.substring(0,4) != 'http'){
            //     window.open(`${link}`);
            // }
            // else{
            var aux_link = link.replace(/\s/g, '');
            window.open(aux_link, '_blanck');
            // }
        }
    }

    nameImage(text) {
        if (text == null)
            return ''
        else {
            var list;
            if (text.name != null)
                return text.name
            else if (text.url != null) {
                list = text.url.split('/')
                return list[list.length - 1]
            }
            else
                return ''
        }
    }

    openGerateLinkFile() {
        console.log('import file');
    
        this.modalImport = this.modalService.show(PopupGenerateFileLinkComponent, {
          backdrop: 'static',
          keyboard: false,
          focus: true,
          show: true,
          ignoreBackdropClick: false,
          class: 'modal-dialog-centered',
          containerClass: '',
          animated: false,
          data:{
            color_primary: this.color_primary
          }
        })
        // this.modalImport.content.action.subscribe((result: any) => {
        //   if (result == 'yes') {
    
        //   }
        // });
      }

    nextStep() {
        var obj_aux = {
            id: null,
            type: null,
            quantity: null,
            link: null,
            option_link: null,
            placeholder: null,
            catalog: null,
            coupon: null,
            registration_config: null,
            background_image: null,
            images: [],
            order: null,
            title: null,
            point_control_name: null,
            description: null,
            name: null,
            url: null,
            survey: null,
            video: null,
            level: null,
            save: false,
            course: null,
            notice: null,
            point_type: 'EMPTY',
            castbox: null,
            deezer: null,
            itunes: null,
            google_podcast: null,
            token_hashlar: null
        }

        var auxObj = Object.assign({}, obj_aux)

        // console.log(this.homepage)
        switch (this.actual_step) {
            case 'campaign':
                if (this.checkInputs('home', null)) {
                    this.steps.campaign = true
                    this.actual_step = "homepage"
                }
                break;
            case 'homepage':
                if (this.checkInputs('type_home', null)) {
                    if (this.selected_homepage_type != null) {
                        // if(this.selected_homepage_type?.name == 'Banner' || this.homepage?.type?.name == 'Vídeo'){
                        //     if(this.homepage.images.length > 1){
                        //         this.alertType()
                        //     }
                        // }else{
                        this.steps.homepage = true;
                        this.homepage.type = this.selected_homepage_type
                        this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
                        this.actual_step = "homepage_images";
                        // }
                    }
                }

                if (this.homepage.type.name == 'Ponto') {

                    this.homepage.images.push(obj_aux)
                    this.homepage.images.push(auxObj)

                    if (this.homepage.images.length > 2) {
                        this.homepage.images.splice(2)
                    }

                    this.homepage.images[0].point_type = "ENTRANCE";
                    this.homepage.images[1].point_type = "EXIT";
                }

                break;
            case 'homepage_images':
                if (this.checkInputs('select_imagens_home', null)) {
                    this.checkHomepageImages()
                }

                if (this.homepage?.images[this.selected_homepage_image]?.type?.name == 'Ponto') {

                    this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images.push(auxObj)

                    if (this.homepage.images[this.selected_homepage_image].images.length > 2) {
                        this.homepage.images[this.selected_homepage_image].images.splice(2)
                    }

                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }

                break;
            case 'page_types':
                if (this.homepage?.images[this.selected_homepage_image]?.images[this?.selected_page_image]?.type?.name == 'Ponto') {

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)

                    if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 2) {
                        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(2)
                    }

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
                }
                break;
        }

    }

    checkInputs(text, position) {
        this.warningMap = new Map();

        if (text == 'home') {
            if (this.campaign_name == "") {
                this.warningMap.set('campaign_name', true);
            }
            if (this.show_header && this.campaign_header == "") {
                this.warningMap.set('campaign_header', true);
            }
            if (!this.selected_colors.bg) {
                this.warningMap.set('bg_color', true)
            }
            if (!this.selected_colors.text) {
                this.warningMap.set('text_color', true)
            }
        }
        else if (text == 'type_home') {
            if (this.selected_homepage_type == "" || this.selected_homepage_type == null) {
                this.warningMap.set('type_home', true);
            }
        }
        else if (text == 'select_imagens_home') {
            if (this.homepage.images == null || this.homepage.images.length <= 0) {
                this.warningMap.set('select_imagens_home', true);
            }
        }
        else if (text == 'select_type_page') {
            if (this.homepage.images == null || this.homepage.images.length <= 0) {
                this.warningMap.set('select_type_page', true);
            }
        }

        if (this.warningMap.size == 0) {
            return true
        }
        else {
            return false
        }
    }

    selectImagePage(i) {
        this.selected_homepage_image = i;
    }

    checkHomepageImages() {
        this.warningMap = new Map();
        if (this.homepage.quantity == null) {
            if (this.homepage.link == null || this.homepage.link == "") {
                this.warningMap.set('homepage_link', true);
            }
            if (this.homepage.background_image == null || this.homepage.background_image.url == "") {
                this.warningMap.set('homepage_background', true);
            }
        }
        else if (this.homepage.images.length > this.homepage.quantity) {
            this.warningMap.set('homepage_images', true);
        }

        if (this.warningMap.size === 0) {
            this.steps.homepage_images = true
            this.actual_step = this.homepage.quantity == null ? null : 'page_types'
        }
    }

    getQrCode(page) {
        this.qrCodeService.getQrCodes(page).subscribe(data => {
            this.qrcodes = this.qrcodes.concat(data.results)

            if (data.next != null) {
                this.page.qrcode += 1
                this.getQrCode(this.page.qrcode)
            }
            else {
                this.filteredQrcodes = this.qrcodeChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter(item) : this.qrcodes.slice()));
            }
        },
            error => {
            })
    }

    private _filter(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.qrcodes.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    removeQrCodeChips(qrcode: string): void {
        const index = this.arrayChips.indexOf(qrcode);

        if (index >= 0) {
            this.deliteQrcode.push(this.listQrCode[index])
            this.arrayChips.splice(index, 1);
            this.listQrCode.splice(index, 1);
        }
    }

    qrcodeChipsSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.arrayChips.includes(event.option.value.name)) {

            this.arrayChips.push(event.option.value);

            if (this.listQrCode == null || this.listQrCode.length == 0) {
                this.listQrCode = []
                // this.listQrCode.push({ qr_code_obj: [] })
            }
            var size = this.listQrCode.length != 0 ? this.listQrCode.length - 1 : 0
            // this.listQrCode[size].qr_code_obj.push(event.option.value);
            let aux = event.option.value
            this.listQrCode.push({ campaign: Number(this.campaign_id), campaign_obj: null, id: null, qr_code: aux.id, qr_code_obj: [aux] });
        }

        // console.log(this.listQrCode)

        this.establishmentInput.nativeElement.value = '';
        this.qrcodeChipsFormControl.setValue(null);
    }

    getTags(page) {
        this.tagService.getAllTags(page).subscribe(data => {
            this.tags = this.tags.concat(data.results)

            if (data.next != null) {
                this.page.tag += 1
                // this.getTags(this.page.tag)
            }
            else {
                this.filteredTagHomepage = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

                this.filteredTagPage = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

                this.filteredTagInternal = this.tagChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
            }
        },
            error => {
            })
    }

    getSubTags(name, id_tags) {
        this.tagService.getAllSubTags(name, id_tags).subscribe(data => {
            this.subtags = this.subtags.concat(data.results)
        })
    }

    private _filter2(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.tags.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    removeTagChips(tag: string, type): void {
        const indexH = this.tagArrayChipsHomepage.indexOf(tag);
        const indexP = this.tagArrayChipsPage.indexOf(tag);
        const indexI = this.tagArrayChipsInternal.indexOf(tag);

        if (type == 'home') {
            if (indexH >= 0) {
                this.deliteTag.homepage.push(this.listTag.home[indexH])
                this.tagArrayChipsHomepage.splice(indexH, 1);
                this.listTag.home.splice(indexH, 1);
            }
        }
        else if (type == 'page') {
            if (indexP >= 0) {
                this.deliteTag.page.push(this.listTag.page[indexP])
                this.tagArrayChipsPage.splice(indexP, 1);
                this.listTag.page.splice(indexP, 1);
            }
        }
        else if (type == 'internal') {
            if (indexI >= 0) {
                this.deliteTag.internal.push(this.listTag.internal[indexI])
                this.tagArrayChipsInternal.splice(indexI, 1);
                this.listTag.internal.splice(indexI, 1);
            }
        }
    }

    tagChipsSelectedTag(event: MatAutocompleteSelectedEvent, type): void {
        if (!this.tagArrayChipsHomepage.includes(event.option.value.name)) {

            if (type == 'home') {
                if (this.listTag.home == null || this.listTag.home.length == 0)
                    this.listTag.home = []

                this.tagArrayChipsHomepage.push(event.option.value);
                this.listTag.home.push({ id: null, obj: event.option.value });
            }
            else if (type == 'page') {
                if (this.listTag.page == null || this.listTag.page.length == 0)
                    this.listTag.page = []

                this.tagArrayChipsPage.push(event.option.value);
                this.listTag.page.push({ id: null, obj: event.option.value });
            }
            else if (type == 'internal') {
                if (this.listTag.internal == null || this.listTag.internal.length == 0)
                    this.listTag.internal = []

                this.tagArrayChipsInternal.push(event.option.value);
                this.listTag.internal.push({ id: null, obj: event.option.value });
            }
        }

        console.log(this.listTag);

        this.establishmentInputTag.nativeElement.value = '';
        this.tagChipsFormControl.setValue(null);
    }

    getGroups(page) {
        this.groupService.getGroups(page, null).subscribe(data => {
            this.groups = this.groups.concat(data.results)

            if (data.next != null) {
                this.page.group += 1
                this.getGroups(this.page.group)
            }
            else {
                this.filteredGroups = this.groupChipsFormControl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => item ? this._filter3(item) : this.groups.slice()));
            }
        })
    }

    private _filter3(value: string): string[] {
        if (value != null) {
            const filterValue = value;
            return this.groups.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    removeGroupChips(group: string): void {
        const index = this.arrayGroup.indexOf(group);

        if (index >= 0) {
            this.deliteGroup.push(this.listGroup[index])
            this.arrayGroup.splice(index, 1);
            this.listGroup.splice(index, 1);
        }
    }

    groupChipsSelected(event: MatAutocompleteSelectedEvent): void {
        if (!this.arrayGroup.includes(event.option.value.name)) {

            this.arrayGroup.push(event.option.value);

            if (this.listGroup == null || this.listGroup.length == 0) {
                this.listGroup = []
            }

            this.listGroup.push(event.option.value);
        }

        this.establishmentInputGroup.nativeElement.value = '';
        this.groupChipsFormControl.setValue(null);
    }

    drop(event: CdkDragDrop<any>, array) {
        moveItemInArray(array, event.previousIndex, event.currentIndex);
    }

    getImagesQuantity(item): number {

        if (item?.name == "Banner")
            return 1;
        else if (item?.name == "Mini Banner")
            return 20;
        else if (item?.name == "Galeria")
            return 20;
        else if (item?.name == "Post")
            return 1;
        else
            return null;
    }

    removeImage(posi, type) {
        if (type == 'home') {
            if (this.homepage.images[posi].id != null)
                this.remove.homepage.push(this.homepage.images[posi])

            this.homepage.images.splice(posi, 1);
        }
        else if (type == 'page') {
            if (this.homepage.images[this.selected_homepage_image].images[posi] != null)
                this.homepage.images[this.selected_homepage_image].images[posi] != null;
            this.remove.page.push(this.homepage.images[this.selected_homepage_image].images[posi])

            this.homepage.images[this.selected_homepage_image].images.splice(posi, 1);
        }
        else if (type == 'internal') {
            if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi] != null)
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi] != null;
            this.remove.page.push(this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi])

            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(posi, 1);
        }

    }

    getValieLink(item, type) {

        if (type == 'home') {
            if (item.name == 'Relatório') {
                this.option_select_link = item;
                this.homepage.images[this.selected_homepage_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
            }
            else {
                this.option_select_link = item;
            }
        }
        else if (type == 'page') {
            if (item.name == 'Relatório') {
                this.option_select_link = item;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
            }
            else {
                this.option_select_link = item;
            }
        }
    }

    createLink(type, link, option, obj) {

        if (type?.name == 'Link')
            return option + link
        else if (type?.name == 'Post') {
            if (obj?.images != null && obj?.images[0]?.link != null)
                return obj.images[0].link
            else
                return link
        }
        else
            return link

    }

    deletLogo() {
        this.image_logo.name = '';
        this.image_logo.url = '';
    }

    deletSide() {
        this.side_menu.name = '';
        this.side_menu.url = '';
    }

    deletImageCkeckIn(type) {
        if (type == 'chckin') {
            this.homepage.images[0]['name'] = '';
            this.homepage.images[0].url = '';
        }
        else {
            this.homepage.images[1]['name'] = '';
            this.homepage.images[1].url = '';
        }
    }

    async addImageCkeck(event, type_check, type_obj, index_home, index_page) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    if (type_obj == 'home') {
                        if (type_check == 'checkin') {
                            this.homepage.images[0]['name'] = filename;
                            this.homepage.images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[1]['name'] = filename;
                            this.homepage.images[1].url = final_file;
                        }
                    }
                    else if (type_obj == 'page') {
                        if (type_check == 'checkin') {
                            this.homepage.images[index_home].images[0]['name'] = filename;
                            this.homepage.images[index_home].images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[index_home].images[1]['name'] = filename;
                            this.homepage.images[index_home].images[1].url = final_file;
                        }
                    }
                    else if (type_obj == 'internal') {
                        if (type_check == 'checkin') {
                            this.homepage.images[index_home].images[index_page].images[0]['name'] = filename;
                            this.homepage.images[index_home].images[index_page].images[0].url = final_file;
                        }
                        else {
                            this.homepage.images[index_home].images[index_page].images[1]['name'] = filename;
                            this.homepage.images[index_home].images[index_page].images[1].url = final_file;
                        }
                    }
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        if (type_obj == 'home') {
                            if (type_check == 'checkin') {
                                this.homepage.images[0]['name'] = this.compressed_image[0].name;
                                this.homepage.images[0].url = this.compressed_image[0].url;
                            }
                            else {
                                this.homepage.images[1]['name'] = this.compressed_image[0].name;
                                this.homepage.images[1].url = this.compressed_image[0].url;
                            }
                        }
                        else if (type_obj == 'page') {
                            if (type_check == 'checkin') {
                                this.homepage.images[index_home].images[0]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[0].url = this.compressed_image[0].url;;
                            }
                            else {
                                this.homepage.images[index_home].images[1]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[1].url = this.compressed_image[0].url;;
                            }
                        }
                        else if (type_obj == 'internal') {
                            if (type_check == 'checkin') {
                                this.homepage.images[index_home].images[index_page].images[0]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[index_page].images[0].url = this.compressed_image[0].url;;
                            }
                            else {
                                this.homepage.images[index_home].images[index_page].images[1]['name'] = this.compressed_image[0].name;;
                                this.homepage.images[index_home].images[index_page].images[1].url = this.compressed_image[0].url;;
                            }
                        }

                        this.compressed_image = []
                    }
                });
            }
        }

        // console.log(this.image_logo)
    }

    async addLogo(event) {

        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    this.image_logo.name = filename
                    this.image_logo.url = final_file
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        this.image_logo.name = this.compressed_image[0].name
                        this.image_logo.url = this.compressed_image[0].url
                        this.compressed_image = []
                    }
                });
            }
        }

        // console.log(this.image_logo)
    }

    async addImageSide(event) {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

                if (!event.target.files[0].name.includes(valid[0])) {
                    return
                }

                var file = event.target.files[0];
                var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])
                reader.onloadend = async (e) => {
                    var final_file = reader.result

                    this.side_menu.name = filename
                    this.side_menu.url = final_file
                }
            }
            else {
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        this.side_menu.name = this.compressed_image[0].name
                        this.side_menu.url = this.compressed_image[0].url
                        this.compressed_image = []
                    }
                });
            }
        }

        // console.log(this.image_logo)
    }

    async onFileChangedGif(event, type, index = null) {

        var aux_type = {
            description: null, id: 6, image: "https://qrcontent-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
            max_images: 0, min_images: 0, name: "Vazio"
        }

        var aux_id = null

        if (event.target.files && event.target.files[0]) {
            const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

            if (!event.target.files[0].name.includes(valid[0])) {
                this.id_tags
                return
            }

            var file = event.target.files[0];
            var filename = file.name;
            var target = event.target.files;

            var reader = new FileReader()
            reader.readAsDataURL(target[0])

            // subscribe file reader read event
            reader.onloadend = async (e) => {
                var final_file = reader.result
                this.compressed_image.push({ id: aux_id, url: final_file, name: filename, quantity: null, type: aux_type, link: null, images: [{ url: null, title: '', description: '', link: '' }] })

                let obj = null
                if (index != null) {
                    obj = this.homepage.images[index]
                }

                let now_homepage = this.homepage.images.length
                let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

                if (type == 'homepage_bg') {
                    this.homepage.background_image = this.compressed_image[0]
                }
                else if (type == 'homepage_post') {
                    this.homepage.images[this.selected_homepage_image].url = this.compressed_image[0].url
                }
                else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
                    this.compressed_image.forEach(el => {
                        this.homepage.images.push(el)
                    })
                }
                else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
                    if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
                    this.compressed_image.forEach(el => {
                        this.homepage.images[index].images.push(el)
                    })
                }
                else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
                    this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
                }
                else if (type == 'page_bg') {
                    obj.background_image = this.compressed_image[0]
                }
                else if (type == 'internalpage_bg') {
                    this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
                }
                else if (type == 'internal_image') {
                    let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images
                    if (obj2 == null)
                        this.homepage.images[this.selected_homepage_image].images[index].images = []

                    if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
                        this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
                        this.compressed_image.forEach(el => {
                            this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
                        })
                    }
                }
                else if (type == 'post_page') {
                    this.homepage.images[this.selected_homepage_image].images[0].url = this.compressed_image[0]?.url
                }
                else if (type == 'post_internal') {
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
                }

                this.compressed_image = []
            }

        }
    }

    async onFileChanged(event, type, index = null) {

        if (event.target.files[0].type == 'image/gif') {
            this.onFileChangedGif(event, type, index)
        }
        else {
            this.onFileChangedImg(event, type, index)
        }
    }

    async onFileChangedImg(event, type, index = null) {

        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {

                if (res.index > res.array.length - 1) {

                    let obj = null
                    if (index != null) {
                        obj = this.homepage.images[index]
                    }

                    let now_homepage = this.homepage.images.length

                    let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

                    if (type == 'homepage_bg') {
                        this.homepage.background_image = this.compressed_image[0]
                    }
                    else if (type == 'homepage_post') {
                        this.homepage.images[this.selected_homepage_image].url = this.compressed_image[0].url
                    }
                    else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
                        this.compressed_image.forEach(el => {
                            this.homepage.images.push(el)
                        })
                    }
                    else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
                        if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
                        this.compressed_image.forEach(el => {
                            this.homepage.images[index].images.push(el)
                        })
                    }
                    else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
                        this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
                    }
                    else if (type == 'page_bg') {
                        obj.background_image = this.compressed_image[0]
                    }
                    else if (type == 'internalpage_bg') {
                        this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
                    }
                    else if (type == 'internal_image') {
                        let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images

                        if (obj2 == null)
                            this.homepage.images[this.selected_homepage_image].images[index].images = []


                        if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
                            this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
                            this.compressed_image.forEach(el => {
                                this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
                            })
                        }
                        else {
                            this.homepage.images[this.selected_homepage_image].images[index].images = this.compressed_image;
                        }
                    }
                    else if (type == 'post_page') {
                        // console.log(this.homepage.images[this.selected_homepage_image].images)
                        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image] = this.compressed_image[0]
                    }
                    else if (type == 'post_internal') {
                        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
                    }

                    this.compressed_image = []
                }
            });
        }

        // console.log(this.homepage)
    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {
                if (this.remove.homepage.length >= 1) {
                    // this.compressed_image.push(this.remove.homepage[index].url)
                    var aux_id = this.remove.homepage[index].id != null ? this.remove.homepage[index].id : null
                    this.compressed_image.push({ id: aux_id, url: response, name: array[index].name, quantity: null, type: null, link: null, })
                }
                else
                    this.compressed_image.push({ url: response, name: array[index].name, quantity: null, type: null, link: null, })
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );

    }

    newLevel() {
        this.homepage.images[this.selected_homepage_image]['level'] = true
        this.homepage.images[this.selected_homepage_image].images.push({
            id: null, name: null, url: null, quantity: null, type: null,
            link: null, option_link: null, catalog: null, survey: null, coupon: null,
            registration_config: null, background_image: null, images: null,
            order: null, video: null, level: null, title: null, point_control_name: null, description: null, course: null,
            notice: null, point_type: null,
            castbox: null, deezer: null, itunes: null, google_podcast: null, token_hashlar: null
        })
    }

    openView() {
        this.homepage['name'] = this.campaign_name;
        this.homepage['header_text'] = this.campaign_header;
        this.homepage['header_color'] = this.color_background;
        this.homepage['text_color'] = this.color_text;
        this.homepage['whatsapp'] = this.whatsapp;
        this.homepage['show_header'] = this.show_header;

        this.modalRef = this.modalService.show(ModalPreviewComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                isCampaign: this.homepage,
            }
        })
    }

    fileSaved(file){
       this.constantsService.fileSaved(file).subscribe(data=>{
          console.log(data)
       },
       error=>{
          console.log(error)
       })
    }

    selectType(item, type_page) {
        console.log(item);

        var obj_aux: Image_campaign = {
            point_type: 'EMPTY'
        } as Image_campaign;

        var auxObj = Object.assign({}, obj_aux)

        if (type_page == 'homepage') {
            this.selected_homepage_type = item
            if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria") {
                this.homepage.images = [obj_aux];
                this.homepage.background_image = null;
                this.homepage.catalog = null;
                this.homepage.coupon = null;
                this.homepage.registration_config = null;
                this.homepage.survey = null;
                this.homepage.link = null;
            }
            else if (item.name == "Banner" &&
                this.homepage.images != null &&
                this.homepage.images.length > 1) {
                var aux_image = this.homepage.images[0]
                this.homepage.images = [];
                this.homepage.images[0] = aux_image;
            }
            else if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images == null || this.homepage.images.length == 0) {

                    this.homepage.images.push(obj_aux)
                    this.homepage.images.push(auxObj)

                    this.homepage.images[0].point_type = "ENTRANCE";
                    this.homepage.images[1].point_type = "EXIT";
                }
                else if (this.homepage.images != null || this.homepage.images.length == 1) {
                    this.homepage.images.push(obj_aux)
                    this.homepage.images[0].point_type = "ENTRANCE";
                    this.homepage.images[1].point_type = "EXIT";
                }
            }
        }
        else if (type_page == 'page') {
            this.homepage.images[this.selected_homepage_image].type = item;
            if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Ponto") {
                this.homepage.images[this.selected_homepage_image].images = [];
                this.homepage.images[this.selected_homepage_image].background_image = null;
                this.homepage.images[this.selected_homepage_image].catalog = null;
                this.homepage.images[this.selected_homepage_image].coupon = null;
                this.homepage.images[this.selected_homepage_image].registration_config = null;
                this.homepage.images[this.selected_homepage_image].survey = null;
                this.homepage.images[this.selected_homepage_image].link = null;
                this.homepage.images[this.selected_homepage_image].level = null;
            }
            else if (item.name == "Banner" &&
                this.homepage.images[this.selected_homepage_image].images != null &&
                this.homepage.images[this.selected_homepage_image].images.length > 1) {
                var aux_image = this.homepage.images[this.selected_homepage_image].images[0]
                this.homepage.images[this.selected_homepage_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[0] = aux_image;
            }
            else if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images[this.selected_homepage_image].images == null || this.homepage.images[this.selected_homepage_image].images.length == 0) {
                    this.homepage.images[this.selected_homepage_image].images = []
                    this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images.push(auxObj)

                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }
                else if (this.homepage.images[this.selected_homepage_image].images != null || this.homepage.images[this.selected_homepage_image].images.length == 1) {
                    this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
                }
            }

        }
        else if (type_page == 'internal') {
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].type = item;
            if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Podcast" && item.name != "Post" && item.name != "Ponto") {
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].background_image = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].catalog = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].coupon = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].registration_config = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].survey = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = null;
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].level = null;
            }
            else if (item.name == "Banner" &&
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null &&
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 1) {
                var aux_image = this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0]
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0] = aux_image;
            }
            if (item.name == "Post" || item.name == "Podcast") {
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
            }
            else if (item.name == "Ponto") {
                // this.homepage.images = []

                if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images == null || this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 0) {
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)

                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
                }
                else if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null || this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 1) {
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
                    this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
                }
            }
        }

    }

    finish() {
        if (!this.loading.finish) {
            this.loading.finish = true;

            this.postCampaign()
        }
    }

    postCampaign() {

        this.campaignService.postCampaign(this.campaign_name, this.show_header,
            this.campaign_header, this.registration_type, this.color_background,
            this.color_text, this.color_icons, this.pdfTerm, this.homepage.type.id,
            (this.coupon?.id != null ? this.coupon?.id : null), this.whatsapp,
            this.image_logo.url, this.input_facebook, this.input_instagram,
            this.input_youtube, this.side_menu.url, false).subscribe(data => {

                this.campaign_id = data.id

                if (this.arrayChips.length != 0) {
                    this.listQrCode.forEach((qrcode, index) => {
                        this.qrCodeService.postQrCodeCampaign(data.id, this.arrayChips[index].id).subscribe(data => {
                            // success
                        }, error => {
                        })
                    })
                }

                if (this.campaign_select != null) {
                    // console.log(this.campaign_select)

                    this.campaignService.postNextCampaign(this.campaign_id, this.campaign_select).subscribe(data => {
                        // console.log(data)
                    })
                }

                // hotfix Lara
                if (this.mobile.android) {
                    this.campaignService.postCampaignAccess(data.id, 2, null).subscribe(data => {
                        // success
                    }, error => { })
                }
                if (this.mobile.ios) {
                    this.campaignService.postCampaignAccess(data.id, 1, null).subscribe(data => {
                        // success
                    }, error => { })
                }

                if (this.arrayGroup.length != 0) {
                    this.listGroup.forEach((group, index) => {
                        this.campaignService.postCampaignAccess(data.id, null, this.arrayGroup[index].id).subscribe(data => {
                            // success
                        }, error => { })
                    })
                }

                if (this.homepage.type.name == 'Vídeo') {

                    // console.log('New Home', 0)
                    this.campaignService.postHomepageImages(
                        this.homepage.background_image?.url, this.campaign_id, null, 1,
                        (this.homepage.type != null ? this.homepage?.type?.id : 6),
                        this.homepage.images[0].title, null, this.homepage.images[0].description,
                        this.homepage.images[0].link, this.homepage.images[0].course, null, 'EMPTY',
                        this.homepage.images[0].castbox, this.homepage.images[0].deezer, this.homepage.images[0].itunes, this.homepage.images[0].google_podcast,
                        this.homepage.images[0].tabloide
                    ).subscribe(data => {
                        // console.log('New Page', 0)

                        var home_id = data.id;
                        this.listTag.home.forEach(tag => {
                            if (tag?.id == null) {
                                this.crieteTag('homepage', home_id, tag.obj.id)
                            }
                        });

                        this.campaignService.postPageImages(this.homepage.background_image?.url, null, 1, data.id,
                            6, this.createLink(this.homepage?.type, this.homepage?.link, this.homepage?.option_link, this.homepage), this.homepage?.catalog, this.homepage?.survey,
                            this.homepage?.registration_config, this.homepage?.coupon, this.homepage?.title, this.homepage?.point_control_name, this.homepage?.description, 'EMPTY', null,
                            this.homepage?.castbox, this.homepage?.deezer, this.homepage?.itunes, this.homepage?.google_podcast, this.homepage?.token_hashlar).subscribe(data => {
                                // console.log('New Page')

                                var page_id = data.id;
                                this.listTag.page.forEach(tag => {
                                    if (tag?.id == null) {
                                        this.crieteTag('page', page_id, tag.obj.id)
                                    }
                                });

                                this.loading.finish = false;
                                this.router.navigate(['/application/content'])

                            });


                    });

                }
                else if (this.homepage.type.name == 'Post') {
                    // console.log('New Home', 0)
                    this.campaignService.postHomepageImages(
                        this.homepage.images[0]?.url, this.campaign_id, null, 1,
                        (this.homepage.type != null ? this.homepage?.type?.id : 6),
                        this.homepage.images[0].title, null,
                        this.homepage.images[0].description, this.homepage.images[0].link,
                        this.homepage.images[0].course, null, 'EMPTY',
                        this.homepage.images[0].castbox, this.homepage.images[0].deezer, this.homepage.images[0].itunes, this.homepage.images[0].google_podcast,
                        this.homepage.images[0].tabloide
                    ).subscribe(data => {
                        // console.log('New Page', 0)

                        var home_id = data.id;
                        this.listTag.home.forEach(tag => {
                            if (tag?.id == null) {
                                this.crieteTag('homepage', home_id, tag.obj.id)
                            }
                        });

                        this.loading.finish = false;
                        this.router.navigate(['/application/content'])

                    });

                }
                // else if (this.homepage.type.name == 'Ponto') {

                //     this.homepage.images.forEach(image => {

                //         console.log('-> Ponto <-')
                //         console.log('->', image)

                //         this.campaignService.postHomepageImages(image?.url, this.campaign_id, null, index + 1,
                //             (image?.type != null ? image?.type?.id : 6),
                //             this.homepage[index]?.title, this.homepage[index]?.description, this.homepage[index]?.link, null, image?.point_type).subscribe(data => {
                //                 console.log('->',data)

                //                 this.sendHomepage(homepage_list, index + 1)
                //         })

                //     });



                // }
                else {
                    this.sendHomepage(this.homepage.images, 0)
                }

            })
    }

    sendHomepage(homepage_list, index) {

        if (homepage_list[index] == null) {
            this.loading.finish = false;
            this.router.navigate(['/application/content'])
        }
        else {
            if (this.homepage.type.name == 'Ponto') {
                // console.log('-> Ponto <-')

                this.campaignService.postHomepageImages(
                    homepage_list[index]?.url, this.campaign_id, null, index + 1,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    homepage_list[index]?.title, homepage_list[0]?.point_control_name,
                    homepage_list[index]?.description, homepage_list[index]?.link,
                    this.homepage.images[0].course, null, homepage_list[index]?.point_type,
                    homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast,
                    homepage_list[index]?.tabloide
                ).subscribe(data => {
                    this.sendHomepage(homepage_list, index + 1)
                })
            }
            else {

                this.campaignService.postHomepageImages(
                    homepage_list[index]?.url, this.campaign_id, null, index + 1,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.homepage.title, null, this.homepage.description, this.homepage.link,
                    this.homepage.images[0].course, homepage_list[index]?.notice,
                    homepage_list[index]?.point_type,
                    homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast,
                    homepage_list[index]?.tabloide
                ).subscribe(data => {
                    // console.log('New Home', homepage_list[index])

                    var homepage_id = data.id;
                    this.listTag.home.forEach(tag => {
                        if (tag?.id == null) {
                            this.crieteTag('homepage', homepage_id, tag.obj.id)
                        }
                    });

                    homepage_list[index].id = data.id;

                    if (homepage_list[index]?.type?.name == 'Vídeo') var image = homepage_list[index]?.background_image?.url;
                    else if (homepage_list[index]?.type?.name == 'Post') {
                        var image = (homepage_list[index]?.images != null && homepage_list[index]?.images[0]?.url != null) ? homepage_list[index]?.images[0].url : null;
                    }
                    else var image = null;

                    if (homepage_list[index]?.type?.name == 'Catálogo' || homepage_list[index]?.type?.name == 'Enquete' || homepage_list[index]?.type?.name == 'Post' || homepage_list[index]?.type?.name == 'Hashlar' ||
                        homepage_list[index]?.type?.name == 'Link' || homepage_list[index]?.type?.name == 'Cupom' || homepage_list[index]?.type?.name == 'Vídeo' || homepage_list[index]?.type?.name == 'Podcast') {

                        this.campaignService.postPageImages(image, null, index + 1, homepage_list[index].id,
                            6, this.createLink(homepage_list[index]?.type, homepage_list[index]?.link, homepage_list[index]?.option_link, homepage_list[index]), homepage_list[index]?.catalog, homepage_list[index]?.survey,
                            homepage_list[index]?.registration_config, homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0]?.point_control_name, homepage_list[index]?.description, 'EMPTY', null,
                            homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.token_hashlar).subscribe(data => {
                                // console.log('New Page')

                                var page_id = data.id;
                                this.listTag.page.forEach(tag => {
                                    if (tag?.id == null) {
                                        this.crieteTag('page', page_id, tag.obj.id)
                                    }
                                });

                                this.sendHomepage(homepage_list, index + 1)

                            });
                    }
                    else if (homepage_list[index]?.type?.name == 'Cadastro' && (homepage_list[index].level == null || !homepage_list[index].level)) {

                        this.campaignService.postPageImages(image, null, index + 1, homepage_list[index].id,
                            6, this.createLink(null, homepage_list[index]?.link, homepage_list[index]?.option_link, homepage_list[index]), homepage_list[index]?.catalog, homepage_list[index]?.survey,
                            homepage_list[index]?.registration_config, homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0]?.point_control_name, homepage_list[index]?.description, 'EMPTY', null,
                            homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.token_hashlar).subscribe(data => {
                                // console.log('New Page', index)

                                var page_id = data.id;
                                this.listTag.page.forEach(tag => {
                                    if (tag?.id == null) {
                                        this.crieteTag('page', page_id, tag.obj.id)
                                    }
                                });

                                this.sendHomepage(homepage_list, index + 1)

                            });
                    }
                    else {
                        if (homepage_list[index].type != null) {
                            this.sendPage(homepage_list, index,
                                homepage_list[index].images, 0)
                        }
                        else {
                            this.sendHomepage(homepage_list, index + 1)
                        }
                    }

                }, error => {
                })
            }

        }

    }

    sendPage(homepage_list, homepage_index, page_list, index) {

        if (page_list[index] == null) {
            this.sendHomepage(homepage_list, homepage_index + 1)
        }
        else {
            // if(page_list[index]?.type?.name == 'Vídeo'){
            //     var image_homepage = page_list[index]?.background_image?.url;
            // }
            // else{
            var image_homepage = page_list[index]?.url;
            // } 

            if (homepage_list[homepage_index]?.type?.name == 'Cadastro') {
                this.campaignService.postPageImages(image_homepage, null, index + 1, homepage_list[homepage_index].id,
                    (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), this.createLink(page_list[index]?.type, page_list[index]?.link, page_list[index]?.option_link, page_list[index]),
                    page_list[index]?.catalog, page_list[index]?.survey, homepage_list[homepage_index]?.registration_config,
                    page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, 'EMPTY', null,
                    page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {
                        // console.log('New Page', page_list[index])

                        var page_id = data.id;
                        this.listTag.page.forEach(tag => {
                            if (tag?.id == null) {
                                this.crieteTag('page', page_id, tag.obj.id)
                            }
                        });

                        page_list[index].id = data.id;

                        // if(page_list[index]?.type?.name == 'Vídeo') var image_page = page_list[index]?.background_image?.url;
                        // else if(page_list[index]?.type?.name == 'Post') var image_page = page_list[index]?.url;
                        // else var image_page = null;

                        var image_page = page_list[index]?.url;

                        if (page_list[index]?.type?.name == 'Catálogo' || page_list[index]?.type?.name == 'Enquete' || page_list[index]?.type?.name == 'Post' ||
                            page_list[index]?.type?.name == 'Link' || page_list[index]?.type?.name == 'Cupom' || page_list[index]?.type?.name == 'Vídeo') {

                            this.campaignService.postInternalPageImages(image_page, null, index + 1, page_list[index].id,
                                page_list[index].type.id, this.createLink(page_list[index]?.type, page_list[index]?.link, page_list[index]?.option_link, page_list[index]),
                                page_list[index].catalog, page_list[index].survey, page_list[index].registration_config, page_list[index].coupun,
                                page_list[index].title, page_list[0].point_control_name, page_list[index].description, 'EMPTY', null,
                                page_list[index].castbox, page_list[index].deezer, page_list[index].itunes, page_list[index].google_podcast, page_list[index].token_hashlar).subscribe(data => {
                                    // console.log('New Internal', page_list)

                                    var internal_id = data.id;
                                    this.listTag.internal.forEach(tag => {
                                        if (tag?.id == null) {
                                            this.crieteTag('internal', internal_id, tag.obj.id)
                                        }
                                    });

                                    this.sendPage(homepage_list, homepage_index, page_list, index + 1);

                                });
                        }
                        else {

                            if (page_list[index].type != null || (page_list[index].images != null && page_list[index].images.length > 0))
                                this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
                            else
                                this.sendPage(homepage_list, homepage_index, page_list, index + 1);

                        }

                    })
            }
            else if (homepage_list[homepage_index]?.type?.name == 'Ponto') {

                this.campaignService.postPageImages(image_homepage, null, index + 1, homepage_list[homepage_index].id,
                    (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), null, null, null, null, null,
                    page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, null,
                    page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

                        this.sendPage(homepage_list, homepage_index, page_list, index + 1);
                    })
            }
            else {
                this.campaignService.postPageImages(image_homepage, null, index + 1, homepage_list[homepage_index].id,
                    (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), this.createLink(page_list[index]?.type, page_list[index]?.link, page_list[index]?.option_link, page_list[index]),
                    page_list[index]?.catalog, page_list[index]?.survey, page_list[index]?.registration_config,
                    page_list[index]?.coupon, page_list[index]?.title, null, page_list[index]?.description, 'EMPTY', null,
                    page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {
                        // console.log('New Page')

                        var page_id = data.id;
                        this.listTag.page.forEach(tag => {
                            if (tag?.id == null) {
                                this.crieteTag('page', page_id, tag.obj.id)
                            }
                        });

                        page_list[index].id = data.id;

                        if (page_list[index]?.type?.name == 'Vídeo') var image_page = page_list[index]?.background_image?.url;
                        else if (page_list[index]?.type?.name == 'Post') {
                            var image_page = (page_list[index].images != null && page_list[index].images[0]?.url != null) ? page_list[index].images[0]?.url : null;
                        }
                        else var image_page = null;

                        if (page_list[index]?.type?.name == 'Catálogo' || page_list[index]?.type?.name == 'Enquete' || page_list[index]?.type?.name == 'Post' ||
                            page_list[index]?.type?.name == 'Link' || page_list[index]?.type?.name == 'Cupom' || page_list[index]?.type?.name == 'Vídeo') {

                            this.campaignService.postInternalPageImages(image_page, null, index + 1, page_list[index].id,
                                page_list[index].type.id, this.createLink(page_list[index]?.type, page_list[index]?.link, page_list[index]?.option_link, page_list[index]),
                                page_list[index].catalog, page_list[index].survey, page_list[index].registration_config, page_list[index].coupun,
                                page_list[index]?.images[0]?.title, page_list[index]?.images[0]?.point_control_name, page_list[index]?.images[0]?.description, 'EMPTY', null,
                                page_list[index]?.images[0]?.castbox, page_list[index]?.images[0]?.deezer, page_list[index]?.images[0]?.itunes, page_list[index]?.images[0]?.google_podcast,
                                page_list[index]?.images[0]?.token_hashlar).subscribe(data => {
                                    // console.log('New Internal', page_list[index])

                                    var internal_id = data.id;
                                    this.listTag.internal.forEach(tag => {
                                        if (tag?.id == null) {
                                            this.crieteTag('internal', internal_id, tag.obj.id)
                                        }
                                    });

                                    this.sendPage(homepage_list, homepage_index, page_list, index + 1);
                                });
                        }
                        else {

                            if (page_list[index].type != null || (page_list[index].images != null && page_list[index].images.length > 0))
                                this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
                            else
                                this.sendPage(homepage_list, homepage_index, page_list, index + 1);

                        }

                    })
            }
        }
    }

    sendInternal(homepage_list, homepage_index, page_list, page_index, internal_list, index) {
        // console.log('New', internal_list)

        if (internal_list == null || internal_list[index] == null) {
            this.sendPage(homepage_list, homepage_index,
                page_list, page_index + 1)
        }
        else {
            if (page_list[index]?.type?.name == 'Vídeo') var image_page = page_list[index]?.background_image?.url;
            else var image_page = internal_list[index]?.url;


            if (internal_list[index]?.id == null) {

                this.postInternalPageImages(image_page, index + 1, page_list[page_index].id,
                    (internal_list[index]?.type != null ? internal_list[index]?.type?.id : 6),
                    internal_list[index]?.link, internal_list[index]?.catalog, internal_list[index]?.survey,
                    internal_list[index]?.registration_config, internal_list[index]?.coupun, internal_list[index]?.title,
                    internal_list[0]?.point_control_name,
                    internal_list[index]?.description, internal_list[index]?.point_type, homepage_list, homepage_index, page_list, page_index, internal_list, index,
                    internal_list[index]?.castbox, internal_list[index]?.deezer, internal_list[index]?.itunes, internal_list[index]?.google_podcast, internal_list[index]?.token_hashlar)
            }
        }

    }

    postInternalPageImages(image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
        title, point_control_name, description, point_type, homepage_list, homepage_index, page_list, page_index, internal_list, index,
        castbox, deezer, itunes, google_podcast, token_hashlar) {
        // console.log('Post Internal')

        if (page_list[homepage_index]?.type?.name == 'Ponto') {

            this.campaignService.postInternalPageImages(image, null, order, page_id,
                type_id, null, null, null, null, null,
                title, point_control_name, description, point_type, null,
                castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                    this.sendInternal(homepage_list, homepage_index,
                        page_list, page_index,
                        internal_list, index + 1)
                })
        }
        else {
            this.campaignService.postInternalPageImages(image, null, order, page_id,
                type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, 'EMPTY', null,
                castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

                    var internal_id = data.id;
                    this.listTag.internal.forEach(tag => {
                        if (tag?.id == null) {
                            this.crieteTag('internal', internal_id, tag.obj.id)
                        }
                    });

                    this.sendInternal(homepage_list, homepage_index,
                        page_list, page_index,
                        internal_list, index + 1)

                }, error => {
                })
        }

    }

    crieteTag(typeTag, id, tag) {
        switch (typeTag) {
            case 'homepage':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(id, null, null, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })
                break;

            case 'page':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(null, id, null, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })

                // this.tagService.postTagPage(id, tag).subscribe(data => {
                //     // console.log('Page', data)
                // }, error => { console.log(error) })
                break;

            case 'internal':
                console.log(typeTag, id, tag);

                this.tagService.postTagHomepage(null, null, id, this.campaign_id, tag, null).subscribe(data => {
                    // console.log('Homepage', data)
                }, error => { console.log(error) })

                // this.tagService.postTagInternalPage(id, tag).subscribe(data => {
                //     // console.log('InternalPage', data)
                // }, error => { console.log(error) })
                break;
        }
    }

    alertType() {
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                title: 'Mudança de tipo',
                text: 'Esse tipo só aceita no máximo uma imagem.',
                has_button: true,
                button_text: 'Continuar',
                button_cancel: 'Cancelar'
            }
        })
        this.modalRef.content.action.subscribe((result: any) => {
            if (result == 'yes') {
                this.steps.homepage = true;
                this.homepage.type = this.selected_homepage_type
                this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
                this.actual_step = "homepage_images";
            }
        });
    }
}

export interface theItem {
    id: number,
    name: string,
    url: any,
    quantity: number,
    type: any,
    link: string,
    option_link: any,
    catalog: number,
    survey: number,
    coupon: number,
    registration_config: number,
    background_image: { url: null },
    images: theItem[]
    order: number,
    video: string,
    level: any,
    title: string,
    point_control_name: string,
    description: string
    course: any,
    notice: string,
    tabloide?: string,
    point_type: string,
    castbox: string,
    deezer: string,
    itunes: string,
    google_podcast: string,
    token_hashlar: string
}