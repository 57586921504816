import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConstantsService } from '../service/constants.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    @ViewChild("home", { static: false }) home;
    @ViewChild("platform", { static: false }) platform;
    @ViewChild("differential", { static: false }) differential;
    @ViewChild("application", { static: false }) application;
    @ViewChild("purchase", { static: false }) purchase;
    @ViewChild("contact", { static: false }) contact;
    
    constructor(private constantsService: ConstantsService) { }

    @Input() events: Observable<void>;
    eventsSubscription: Subscription;

    select: string = 'mensal';
    selectFlat: number = 1;

    name: string = '';
    email: string = '';
    message: string = '';

    msg_error: string = '';

    list_table = [
        {num: '01', title:'Gastronomia', local:'Restaurantes, bares, padarias e etc.', text:'Catálogos, promoções, enquetes, cupons, realizar pedidos e mais...'},
        {num: '02', title:'Varejo', local:'Lojas de roupas, eletro, supermercados, autos e etc.', text:'Publicidade, vídeos, promoções,enquetes, cupons e mais...'},
        {num: '03', title:'Beleza e Saúde', local:'Academias,  clubes, centros estéticos e etc ', text:'Instruções por vídeos, treinos personalizados, promoções, enquetes e mais..'},
        {num: '04', title:'Indústria', local:'Fábricas de remédios, eletrônicos, autos e etc. ', text:'Bulas e manuais sempre atualizados, instruções por vídeos, publicidade, enquetes e mais...'},
        {num: '05', title:'Proteção de dados', local:'Empresas de logística, entregas, portarias e mais... ', text:'O usuário terá acesso a dados protegidos após realizar cadastro para visualizar as informações'},
        {num: '06', title:'Meios de pagamento', local:'Pagamentos Digitais.', text:'Gerenciamos e unificamos as informações variáveis, em um único QRCODE, gerando o carrinho para finalizar o pagamento.'},
    ]

    elementos: any;

    ngOnInit(): void {
        this.events.subscribe((data) => this.navigateTo(data));
    }

    check(){
        this.msg_error = "";

        if(this.name == null || this.name == ""){
            this.msg_error = "Por favor, Insira seu nome";
            return false 
        }
        else if(this.email == null || this.email == ""){
            this.msg_error = "Por favor, Insira o seu email";
            return false 
        }
        else if(this.message == null || this.message == ""){
            this.msg_error = "Por favor, Digite uma mensagem.";
            return false 
        }
        else
            return true
    }

    sendMessage(){
        var check = this.check()

        if(check){
            this.constantsService.contact(this.message, this.name, this.email).subscribe(data=>{
                this.name = '';
                this.email = '';
                this.message = '';
                this.msg_error = 'Mensagem enviada!'
            })
        }
    }

    navigateTo(element) {
        if(element == 'home'){
            this.navigateTop()
        }
        else{
            this[element].nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
            const yOffset = -70; 
            const position = document.getElementById(element);
            const y = position.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});  
        }        
    }

    async scrollTo(name){
        var element = document.getElementById(name) as HTMLElement

        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        var yOffset = 0;
    
        if (window.innerWidth <= 800){
            yOffset = -255;
        } 
        else{
            yOffset = -165; 
        }

        const y = element.getBoundingClientRect().bottom + window.pageYOffset + yOffset;
    
        if(supportsNativeSmoothScroll == true){
            window.scrollTo({top: y, behavior: 'smooth'});
        } else {
            this.navigateTop()
        }
    }

    navigateTop(){
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

        if(supportsNativeSmoothScroll == true){
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            window.scroll(0,0);
        }
    }

}
