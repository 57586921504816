import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';

@Component({
	selector: 'app-modal-type-link',
	templateUrl: './modal-type-link.component.html',
	styleUrls: ['./modal-type-link.component.css']
})
export class ModalTypeLinkComponent implements OnInit {

	constructor(
		public modalRef: MDBModalRef,
		private storageService: StorageService
	) { }

	sidebar;
	list_options;

	action: Subject<any> = new Subject();
	text: string = "Links";
	title: string = "";
	has_button: boolean = false;
	button_cancel: string = "Fechar";

	color_primary

	ngOnInit() {
		this.color_primary = this.storageService.establishment.color;
	}

	close() {
		this.action.next('no');
		this.modalRef.hide();
	}

	closePopup() {
		this.modalRef.hide();
	}

	next() {
		this.action.next('yes');
		this.modalRef.hide();
	}

}
