import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class SurveyService {

    constructor(private http : HttpClient) { }

    getSurveyId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

        return this.http.get<any>(AppConfig.path + `core/survey/${id}/`, {headers});
    }

    getSurvey_EstablishmentId(id, page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`establishment`, id)
                .set(`page`, page)
        };   

        return this.http.get<any>(AppConfig.path + `core/survey/`, options);
    }

    postSurvey(name, description, id_establishment): Observable<any>{
        let body = new HttpParams()
            .set(`name`, name)
            .set(`description`, description)
            .set(`establishment`, id_establishment)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/survey/`, body, { headers })
    }

    patchSurvey(id, name, description): Observable<any>{
        let body = new HttpParams()
            .set(`name`, name)
            .set(`description`, description)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/survey/${id}/`, body, { headers })
    }

    postQuestion(id_survey, description, survey_type): Observable<any>{
        let body = new HttpParams()
            .set(`survey`, id_survey)
            .set(`description`, description)
            .set(`survey_type`, survey_type)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/survey-question/`, body, { headers })
    }

    getQuestion_SurveyId(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`survey`, id)
        };   

        return this.http.get<any>(AppConfig.path + `core/survey-question/`, options);
    }

    getQuestionId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/survey-question/${id}/`, {headers});
    }

    patchQuestion(id, description, survey_type): Observable<any>{
        let body = new HttpParams()
            .set(`description`, description)
            .set(`survey_type`, survey_type)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/survey-question/${id}/`, body, { headers })
    }

    deleteQuestion(id): Observable<any>{
       
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/survey-question/${id}/`, { headers })
    }

    postAnswer(id_question, title): Observable<any>{
        let body = new HttpParams()
            .set(`question`, id_question)
            .set(`title`, title)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/survey-answer/`, body, { headers })
    }

    getAnswer_QuestionId(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`question`, id)
        };   

        return this.http.get<any>(AppConfig.path + `core/survey-answer/`, options);
    }

    getAnswerId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/survey-answer/${id}/`, {headers});
    }

    patchAnswer(id, title): Observable<any>{
        let body = new HttpParams()
            .set(`title`, title)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/survey-answer/${id}/`, body, { headers })
    }

    deleteAnswer(id): Observable<any>{
       
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/survey-answer/${id}/`, { headers })
    }

    deleteSurvey(id): Observable<any>{
        let body = new HttpParams()
            .set(`active`, 'false')

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/survey/${id}/`, body, { headers })
    }

    getAnswerSurvey(page, campaign, qrcode){
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`page`, page)
                .set(`campaign`, campaign)
                // .set(`qrcode`, qrcode)
        };  

        return this.http.get<any>(AppConfig.path + `core/survey-user-answer/`, options)
    }

    answerSurvey(list_answers, token, id_qrcode, id_campaign){
        let send_answers={
            answers: list_answers,
        }

        if(id_campaign != null && id_campaign != ""){
            send_answers['campaign'] = id_campaign
        }
        if(id_qrcode != null && id_qrcode != ""){
            send_answers['qr_code'] = id_qrcode
        }

        let body: any = JSON.stringify( send_answers )
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('User', `${token}`)

        return this.http.post<any>(AppConfig.path + `core/survey-user-answer/`, body, { headers })
    }
}