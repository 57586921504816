<div class="screen">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin: auto; padding-left: 6px;">
        <h3> Seções - Lista atualizada à cada 5 minutos </h3>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <button mat-button (click)="detailTag('', false)"
            style="display: flex; align-items: center; justify-content: space-evenly;">
                <span style="margin-top: -5px;"> 
                    <svg class="icon-mini" viewBox="0 0 5100 5100" >
                        <path [style.fill]="color_primary"
                            d="M805 5090 c-124 -11 -233 -52 -361 -135 -244 -159 -396 -397 -425
                            -665 -6 -62 -9 -693 -7 -1810 3 -1561 5 -1720 20 -1775 89 -321 327 -569 638
                            -667 l75 -23 1815 0 1815 0 75 23 c299 94 531 329 627 639 l28 88 3 1680 c2
                            1041 -1 1729 -7 1810 -11 145 -35 232 -97 344 -129 236 -322 392 -585 473
                            l-74 23 -1730 1 c-952 1 -1766 -2 -1810 -6z m2230 -525 c28 -4 54 -11 60 -16
                            5 -5 16 -9 23 -9 20 0 82 -65 82 -85 0 -8 4 -15 9 -15 18 0 23 -96 22 -495 -1
                            -225 2 -480 6 -565 l8 -155 515 -6 c283 -4 551 -11 595 -16 117 -14 194 -65
                            210 -137 12 -56 24 -339 20 -446 -3 -58 -7 -208 -10 -335 -3 -126 -8 -243 -11
                            -260 -4 -18 -27 -49 -56 -78 -39 -37 -58 -47 -84 -47 -18 0 -35 -4 -38 -8 -3
                            -5 -258 -11 -567 -15 -309 -3 -565 -9 -570 -14 -5 -5 -13 -257 -19 -561 -11
                            -594 -16 -656 -63 -696 -39 -36 -65 -46 -150 -62 -69 -14 -129 -15 -392 -10
                            -170 4 -379 11 -463 15 l-153 8 -39 42 c-22 24 -42 52 -44 64 -20 109 -24 217
                            -25 672 -1 285 -5 523 -10 528 -5 5 -258 10 -563 12 -383 2 -562 6 -576 14
                            -11 6 -32 11 -46 11 -14 0 -26 5 -26 10 0 6 -7 10 -16 10 -8 0 -29 13 -46 30
                            -16 16 -26 30 -22 30 5 0 3 5 -4 12 -7 7 -12 21 -12 33 0 12 -5 26 -11 32 -16
                            16 -18 971 -1 1006 6 15 18 39 24 53 7 15 19 29 28 32 8 3 17 8 20 12 17 21
                            85 40 144 40 37 0 66 4 66 10 0 7 178 10 515 10 l515 0 0 508 c1 650 11 733
                            94 797 68 52 93 54 571 50 242 -2 463 -6 490 -10z"/>
                    </svg>
                    <!-- <img [style.fill]="color_primary" src="/assets/icon/icon-add.svg" class="icon-mini">  -->
                </span>
                <span> Adicionar Seção </span>
            </button>

            <button mat-button (click)="getTags()"
            style="display: flex; align-items: center; justify-content: space-evenly;">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>
        </div>
    </div>

    <div class="div-table" *ngIf="!loading">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- <ng-container matColumnDef="codigo" style="width: 100px !important;">
                <th mat-header-cell *matHeaderCellDef style="width: 100px !important;">
                    <div class="title-borde" mat-sort-header> Código  </div>
                </th>
                <td mat-cell *matCellDef="let element" style="width: 100px !important;">
                    <div class="div-borde"> {{element.id}} </div>
                </td>
            </ng-container> -->
          
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Nome </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.name}} </div>
                </td>
            </ng-container>
          
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Data de Criação </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.created_at | date: 'dd/MM/yyyy'}} </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="view" style="max-width: 200px;">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="max-width: 200px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index" style="max-width: 200px;">
                    <div class="div-borde p_5" style="max-width: 200px;">
   
                        <div (click)="orderSection(element, i+1, 'up')">
                            <img src="/assets/icon/arrow_downward.png" matTooltip="Descer" 
                            class="icon" [style.background]="color_primary"> 
                        </div>

                        <div (click)="orderSection(element, i-1, 'down')">
                            <img src="/assets/icon/arrow_upward.png" matTooltip="Subir" 
                            class="icon" [style.background]="color_primary"> 
                        </div>

                        <div (click)="detailTag(element, true)">
                            <img src="/assets/icon/icon_edit.png" matTooltip="Editar" [style.background]="color_primary" class="icon"> 
                        </div>
                        
                        <div (click)="openDelete(element)">
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" class="icon view-delet"> 
                        </div>
                    </div>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
            </tr>
        </table>
    
        <div class="table-paginator" *ngIf="count_total > 0">
            <div style="display: flex;">
                <div class="paginator-button prev" (click)="backPage()" matTooltip="Página anterior">
                    <img src="/assets/icon/prev.png" width="12px" height="12px">
                </div>
                <div class="paginator-button next" (click)="nextPage()" matTooltip="Próxima página">
                    <img src="/assets/icon/next.png" width="12px" height="12px">
                </div>
            </div>
    
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
    </div>
            
</div>