<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Enquete </h3>

    </div>

    <input mat-input [(ngModel)]="survey.name" placeholder="Escreva aqui o nome da Enquete" class="input"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}">

    <textarea mat-input [(ngModel)]="survey.description" placeholder="Escreva aqui a descrição da Enquete..." style="resize: none;" class="input"
        [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}">
    </textarea>

    <div *ngIf="viewSection">
        <div *ngFor="let obj of survey.questions; let i = index" style="margin: 10px 0;" >
            <mat-accordion>
   
                <mat-expansion-panel
                    (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    
                    <mat-expansion-panel-header >
                        <mat-panel-title>
                            
                            {{ obj.description != '' ? obj.description : 'Nova Enquete #' + (i+1) }}
                            
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <div style="display: flex; flex-direction: column;">

                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <input mat-input [(ngModel)]="obj.description" class="input div_70" 
                                [ngStyle]="{'border-color': warningMap.get('description'+i)? 'red' : ''}"
                                placeholder="Escreva aqui a pergunta">

                            <mat-form-field class="select div_30" 
                                [ngStyle]="{'border-color': warningMap.get('survey_type'+i)? 'red' : ''}">
                                <mat-label> Tipo de pergunta </mat-label>

                                <mat-select [(ngModel)]="obj.survey_type">
                                    <mat-option *ngFor="let question of list_question" [value]="question.type">
                                        {{ question.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div style="width: 100%; display: flex; flex-direction: column;">
                            <div *ngFor="let item of obj.answers; let y = index" 
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                                
                                <input mat-input [(ngModel)]="item.title" class="input" placeholder="Escreva aqui a resposta"
                                    style="width: calc(100% - 60px);"
                                    [ngStyle]="{'border-color': warningMap.get('title'+i+y)? 'red' : ''}">
                                
                                <img src="../../../assets/icon/icon_excluir.png" 
                                    (click)="addListDelete(item, 'answer', i, y)" [style.background]="color_primary" class="btn-delete">
                            <!-- <div *ngIf="obj.answer.length-1 == y" style="margin: 5px 0; border-bottom: 1px solid #b6b6b7;"></div> -->
                            </div>
                        </div>
                        
                    </div>

                    <button mat-button class="button-cor mw_auto" [style.background]="color_primary" (click)="addAnswer(i)" > 
                        Adicionar resposta
                    </button>

                    <button mat-button class="button-cor mw_auto" [style.background]="color_primary" (click)="addListDelete(obj, 'questions', i, null)"> 
                        Apagar Enquete
                    </button>
                
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    

    <div style="display: flex; margin-top: 10px;">
        <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou o catálogo
        </button>
        
        <button mat-button class="button-cor" [style.background]="color_primary" (click)="addQuestion()" style="margin-right: 10px;"> 
            Adicionar Enquete 
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()" > 
            {{ survey_id == null ? 'Finalizar Enquete' : 'Salvar Enquete' }} 
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status" aria-hidden="true"></span>
        </button>
    </div>
            
</div>