<div class="primary-div" [ngStyle]="{'font-size': (urlPage =='campaign' || urlPage =='new-campaign')? '11px' : '16px'}">

    <div class="div-loading" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
    </div>

    <div class="question" *ngFor="let question of listImage; let i = index">
        <div class="q-title"> {{ question.description }} </div>

        <div *ngIf="question.survey_type == 'SINGLE'" style="width: 100%;">
            <mat-radio-group style="width: 100%; display: flex; flex-direction: column;">
                <mat-radio-button class="q-answer" *ngFor="let answer of question.answers" [value]="answer" (click)="selectAnswers(answer, question)">
                    {{ answer.title }}
                </mat-radio-button> 
            </mat-radio-group>
        </div>

        <div *ngIf="question.survey_type == 'MULTIPLE'" style="width: 100%;">
            <div *ngFor="let answer of question.answers; let i = index" style="width: 100%;">
                <mat-checkbox class="q-answer" (click)="selectAnswers(answer, question)" 
                    [checked]="selection.isSelected(i)" 
                    [value]="answer" >

                    {{ answer.title }}
                </mat-checkbox>
            </div>
        </div>

    </div>
  
    <div *ngIf="!loading && !status_answer" style="width: 90%; margin: 0 auto; display: flex; justify-content: center; align-items: center;">
        <button mat-button [style.background]="header.back" [style.color]="header.color" class="button-cor" (click)="answerSurvey()"> 
            Enviar <span *ngIf="loading_btn" class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
        </button>
    </div>
  
    <div *ngIf="!loading && status_answer" style="width: 90%; margin: 0 auto; text-align: center; font-size: 15px;" class="q-title">
        Pergunta respondida. <br> Obrigado!
    </div>

    <div style="width: 100%; text-align: center; font-size: 12px; color: red; margin: 5px 0;">
        {{ msg_error }}
    </div>
</div>