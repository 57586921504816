<div style="margin: 0 50px;">
  <br><br>

  <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
    src="../../../../assets/image/logo_principal_SBS.png">
  <h2>Formulário de Inspeção de Hidrantes MENSAL</h2>

  <br><br>

  <table>
    <tr style="font-size: 9px;">
      <th> Local </th>
      <th> Verificado por </th>
      <th> Data </th>
      <th> Hora </th>
      <th> Mês </th> 
    </tr>

    <tr style="font-size: 7px;">
      <td> {{ title }} </td>
      <td> {{ header['verification'] }} </td>
      <td> {{ header['data'] }} </td>
      <td> {{ header['hour'] }} </td>
      <td> {{ header['mother'] }} </td>
    </tr>
  </table>

  <br><br>

  <table>
    <tr style="font-size: 9px;">
      <th> Localização </th>
      <th> Hidrante </th>
      <th> Diâmetro </th>
      <th> Adaptador </th>
      <th> Chave </th>
      <th> Esguicho (F) </th>
      <th> Esguicho (R) </th>
      <th> Tampão </th>
      <th> Qtde Mang. </th>
      <th style="width: 5%"> C </th>
      <!-- <th style="width: 5%"> N/C </th> -->
    </tr>

    <tr *ngFor="let row of table" style="font-size: 8px;">
      <td> {{ row['Localização'] }} </td>
      <td> {{ row['Hidrante'] }} </td>
      <td> {{ row['Diâmetro'] }} </td>
      <td> {{ row['Adaptador'] }} </td>
      <td> {{ row['Chave'] }} </td>
      <td> {{ row['Esguicho (F)'] }} </td>
      <td> {{ row['Esguicho (R)'] }} </td>
      <td> {{ row['Tampão'] }} </td>
      <td> {{ row['Qtde Mang.'] }} </td>
      <td> {{ row['C'] }} </td>
      <!-- <td> </td> -->
    </tr>
  </table>

  <br><br>

  <!-- <div>
    <p style="font-size: 12px !important; margin: 0 !important;">OBS:</p>
    <textarea style="max-width: 793px;" cols="56" rows="5"></textarea>
  </div> -->

  <!-- <br><br> -->

  <table>
    <tr style="font-size: 9px;">
      <th> Elaborado </th>
      <th> Revisado: </th>
      <th> Aprovado </th>
      <th> Revisão: </th>
      <th> Data: </th>
      <th> Referência </th>
    </tr>

    <tr style="font-size: 8px;">
      <td> {{ footer['Elaborado'] }} </td>
      <td> </td>
      <td> {{ footer['Aprovado'] }} </td>
      <td> </td>
      <td> {{ footer['Data'] }} </td>
      <td> {{ footer['Referência'] }} </td>
    </tr>
  </table>
</div>