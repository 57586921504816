export class ValidatorUtils {

	isEmptyOrNull(value: any): boolean {
		if (value == null || value == "" || value.length < 0 || value < 0)
		  return true
		return false
	}

	isValidCPF(cpf): boolean {
		if (typeof cpf !== "string") return false;

		cpf = cpf.replace(/[\s.-]*/igm, '')
		if (!cpf || cpf.length != 11 ||
			cpf == "00000000000" ||
			cpf == "11111111111" ||
			cpf == "22222222222" ||
			cpf == "33333333333" ||
			cpf == "44444444444" ||
			cpf == "55555555555" ||
			cpf == "66666666666" ||
			cpf == "77777777777" ||
			cpf == "88888888888" ||
			cpf == "99999999999") {
			return false
		}

		let sum = 0
		let remainder;
		for (let i = 1; i <= 9; i++) 
			sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);

		remainder = (sum * 10) % 11;
		if ((remainder == 10) || (remainder == 11))  remainder = 0;
		if (remainder != parseInt(cpf.substring(9, 10)) ) return false;

		sum = 0;
		for (let i = 1; i <= 10; i++) 
			sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i)

		remainder = (sum * 10) % 11
		if ((remainder == 10) || (remainder == 11))  remainder = 0
		if (remainder != parseInt(cpf.substring(10, 11) ) ) return false

		return true
	}

	isValidDate(date){
		let aux_date = date.split('/')
		let today = [new Date().getMonth()+1, new Date().getFullYear()]

		return false
	}

	isValidEmail(email): boolean {
		let re = /\S+@\S+\.\S+/;
		return !re.test(email)
	}


	brandCard(num_card) {
		if(num_card == null || num_card == ""){
		  return null;
		}
		else if((num_card.substr(0, 6) == 636368) || 
				(num_card.substr(0, 6) == 438935) ||
				(num_card.substr(0, 6) == 504175) ||
				(num_card.substr(0, 6) == 451416) ||
				(num_card.substr(0, 6) == 509048) ||
				(num_card.substr(0, 6) == 509067) ||
				(num_card.substr(0, 6) == 509049) ||
				(num_card.substr(0, 6) == 509069) ||
				(num_card.substr(0, 6) == 509050) ||
				(num_card.substr(0, 6) == 509074) ||
				(num_card.substr(0, 6) == 509068) ||
				(num_card.substr(0, 6) == 509040) ||
				(num_card.substr(0, 6) == 509045) ||
				(num_card.substr(0, 6) == 509051) ||
				(num_card.substr(0, 6) == 509046) ||
				(num_card.substr(0, 6) == 509066) ||
				(num_card.substr(0, 6) == 509047) ||
				(num_card.substr(0, 6) == 509042) ||
				(num_card.substr(0, 6) == 509052) ||
				(num_card.substr(0, 6) == 509043) ||
				(num_card.substr(0, 6) == 509064) ||
				(num_card.substr(0, 6) == 509040) ||
				(num_card.substr(0, 5) == 36297) ||
				(num_card.substr(0, 4) == 5067) ||
				(num_card.substr(0, 4) == 4576) ||
				(num_card.substr(0, 4) == 4011)) {
		  return 'Elo';
		}
		else if((num_card.substr(0, 4) == 6011) ||
				(num_card.substr(0, 3) == 622) ||
				(num_card.substr(0, 1) == 64) ||
				(num_card.substr(0, 2) == 65)){
		  return 'Discover';
		}
		else if((num_card.substr(0, 3) == 301) || 
				(num_card.substr(0, 3) == 305) ||
				(num_card.substr(0, 2) == 36) ||
				(num_card.substr(0, 2) == 38)){
		  return 'Diners';
		}
		else if((num_card.substr(0, 2) == 34) ||
				(num_card.substr(0, 2) == 37)){
		  return 'Amex';
		}
		else if((num_card.substr(0, 2) == 38) ||
				(num_card.substr(0, 2) == 60)){
		  return 'Hipercard';
		}
		else if(num_card.substr(0, 2) == 50){
		  return 'Aura';
		}
		else if(num_card.substr(0, 2) == 35){
		  return 'jcb';
		}
		else if(num_card.substr(0, 1) == 4){
		  return 'Visa';
		}
		else if(num_card.substr(0, 1) == 5){
		  return 'Mastercard';
		}
		else{
		  return null;
		}
	}

}

export function dateMapper (value) {
    let date = new Date(value)
    if (date.toString() == 'Invalid Date') 
        return value
    else{
        let a = date.toISOString()
        return `${a.slice(8, 10)}/${a.slice(5, 7)}/${a.slice(0, 4)}` // dd-mm-yyyy
    }
}
