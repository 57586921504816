<div *ngIf="!loading" class="more-details-container">

    <div *ngFor="let section of catalog.sections" style="width: 100%;">

        <div *ngFor="let prod of section.products;">
            <div class="back-btn" *ngIf="product_id == prod.id">
                <button [routerLink]="'/catalog-view/'+catalog.id">
                    <div class="btn-content">
                        <span class="btn-icon">
                            <img src="../../../../assets/icon/prev.png" alt="voltar" />
                        </span>
                        <span class='btn-text'>Voltar</span>
                    </div>
                </button>
            </div>

            <div style="width: 100%;" *ngIf="product_id == prod.id">
                <span class="details">
                    <div class="details-img">
                        <img src='{{ prod.image }}' alt="">
                    </div>
                    <div class="details-info">
                        <h1 class="item-title">
                            {{ prod.name }}
                        </h1>
                        <h2 class="item-price">
                            R$ {{ prod.price }}
                        </h2>
                        <p class="item-description">
                            {{ prod.description }}
                        </p>
                        <div class="button_add" *ngIf="catalog.purchase">
                            Adicionar 
                        </div>
                    </div>
                </span>
                <div class="card_classification_food">
                    <h2> Comida de Classificação </h2>
                    <div class="list_food">
                        <h3 *ngFor="let food of prod.classification_food"> {{ food.name }} </h3>
                    </div>
                </div>

                <div class="card_table_nutrition">
                    <h2> Informações Nutricionais </h2>

                    <div class="div-table">
                        <table mat-table [dataSource]="prod.table_nutrition" matSort>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Nome </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.name}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="qtd">
                                <th mat-header-cell *matHeaderCellDef> Quant. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.quant}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef> Valor </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.value}}%
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado
                                    encontrado. </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div style="height: 50px;"></div>
</div>