import { Component, Input, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { QrCodeService } from 'src/app/service/qrcode.service';
import { QRCodeComponent } from 'angularx-qrcode';
import { AppConfig } from 'src/app/app.config';
import { ConstantsService } from 'src/app/service/constants.service';
import { StorageService } from 'src/app/service/storage.service';
import { RegistrationService } from 'src/app/service/registration.service';

@Component({
  	selector: 'modal-qrcode',
  	templateUrl: './modal-qrcode.component.html',
  	styleUrls: ['./modal-qrcode.component.css']
})

export class ModalQrcodeComponent {

	constructor(
		public modalRef: MDBModalRef,
		private qrCodeService: QrCodeService,
		private constantsService: ConstantsService,
		private storageService: StorageService,
        private registrationService: RegistrationService,
	) { }

	// @ViewChild("myqrcode", {static : true}) qrcode: QRCodeComponent

	action: Subject<any> = new Subject();
	textButton: string = "";
	buttonClose: string = "";
	title: string = "";
    warningMap = new Map();
	message_erro: string = '';
	isQRCode;
	preview;
	
	loading={
		img_qrcode: false,
		create: false,
		new: true,
		edit: false
	}

	list_establishment = []
	list_qrcodes = [
		{
			active: true,
			adress: null,
			deleted: false,
			establishment: 9,
			establishment_obj: null,
			id: -1,
			information: null,
			is_in_flow: false,
			name: "Nenhum",
			next: null,
			qr_code: null,
			qr_code_link: null,
			slug: "teste-20210913120814",
			store: null}
	]

	name: string;
	store: string;
	send_next_qrcode: any;
	backup_next_qrcode: string;
	address: string;
	information: string;
	establishment: string;
	id_establishment: number;
	slug: string;
	first_of_flow: boolean = false;
	backup_first_of_flow: boolean = false;

	qr_code_link: string;
	qr_code: any;
	id_qrcode: number;

	page_registration: number = 1;
	list_registration = [{
		id: -1,
		title: 'Nenhum'
	}];
	registration = []

	color_primary
	page: number = 1;

	ngOnInit() {

		if(this.send_next_qrcode == undefined || this.send_next_qrcode == null || this.send_next_qrcode == ''){
			this.send_next_qrcode = this.list_qrcodes[0].id;
		}

		this.color_primary = this.storageService.establishment.color;

		if(this.isQRCode == null){
			this.title = 'Novo QRCode';
			this.textButton = 'Salvar';
			this.buttonClose = 'Cancelar';
			this.loading.new = true;
			this.loading.edit = true;
		}
		else{	
			this.title = 'Editar QRCode';
			this.textButton = 'Salvar';
			this.buttonClose = 'Fechar';

			this.loading.new = false;
			this.loading.edit = true;
			this.loading.create = false;

			this.name = this.isQRCode.name;
			this.registration = this.isQRCode.registration_config;
			this.first_of_flow = this.isQRCode.is_the_first_of_the_flow;
			this.backup_first_of_flow = this.isQRCode.is_the_first_of_the_flow;
			this.send_next_qrcode = this.isQRCode.next;
			this.backup_next_qrcode = this.isQRCode.next;
			this.store = (this.isQRCode.store != null && this.isQRCode.store != 'undefined' ? this.isQRCode.store : null);
			this.id_establishment = this.isQRCode.establishment;
			this.establishment = this.isQRCode.establishment_obj.name;
			this.address = (this.isQRCode.adress != null && this.isQRCode.adress != 'undefined') ? this.isQRCode.adress : null;
			this.information = (this.isQRCode.information != null && this.isQRCode.information != 'undefined') ? this.isQRCode.information : null;
			this.slug = (this.isQRCode.slug != null && this.isQRCode.slug != 'undefined') ? this.isQRCode.slug : null;

			this.qr_code = this.isQRCode.qr_code;
			this.id_qrcode = this.isQRCode.id;
			this.qr_code_link = `https://sbs.tools/redirect/${this.slug}`;
			
			this.loading.img_qrcode = true;
		}

		this.getEstablishment()
		this.getRegistration(this.page_registration)
		this.getQrCodes(this.page_registration)
	}

	createQrCode(){
        this.warningMap = new Map();

		if (this.name == "" || this.name == null) {
            this.warningMap.set('name', true);
        }
		if (this.id_establishment == null) {
            this.warningMap.set('establishment', true);
        }
		if (this.warningMap.size === 0) {
			this.title = this.name;
			this.postQRCode()
			this.loading.create = true;
        }
	}
	
	getRegistration(page){
        this.registrationService.getRegistration(page).subscribe(data=>{
		   
		   this.list_registration = this.list_registration.concat(data.results)

			if(data.next != null){
				this.page_registration++;
				this.getRegistration(this.page_registration)
			}

        },
        error=>{
        })
    }
	
	postQRCode(){
		// let send_name = this.removeAcentos(this.name)
		let send_name = this.name
		this.qrCodeService.postQrCode(send_name).subscribe(data=>{
			this.qr_code_link = `https://sbs.tools/redirect/${data.slug}`;
			this.id_qrcode = data.id;
			this.loading.img_qrcode = true
		})
	}

	removeAcentos(text){       
		text = text.toLowerCase();                                                         
		text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
		text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
		text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
		text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
		text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
		text = text.replace(new RegExp('[Ç]','gi'), 'c');
		return text;                 
	}

	getQrCode(){
        try {
            let qrcode = document.getElementById('qrcode') as any
            return qrcode.firstChild.firstChild.src
        } catch { return "" }
    }

	getEstablishment() {
        this.constantsService.getUserEstablishment(this.storageService.user_id).subscribe(data => {
			this.list_establishment = data.results
        })
    }

	selectEstablishment(obj){
		this.establishment = obj.name;
		this.id_establishment = obj.id
	}

	selectRegistration(obj){
		this.registration = obj.id;
	}

	getQrCodes(page) {
        this.qrCodeService.getQrCodes(page).subscribe(data => {
			this.list_qrcodes = this.list_qrcodes.concat(data.results)

			if(data.next != null){
				this.page++;
				this.getQrCodes(this.page)
			}
			else{
				if(this.isQRCode?.id != null){
					var qrcode_aux;
					qrcode_aux = this.list_qrcodes.findIndex(el => el.id == this.isQRCode.id);
					this.list_qrcodes.splice(qrcode_aux, 1);
				}
			}
        })
    }

	selectQrcode(obj){
		this.send_next_qrcode = obj.id
	}

	patchNewQrCode(){

		this.qr_code = this.getQrCode()
		this.qrCodeService.patchNewQrCode(this.qr_code, this.id_qrcode, this.name, 
			this.store, this.id_establishment, this.address, this.information, this.qr_code_link, this.registration).subscribe(data=>{

			if(this.send_next_qrcode != null && this.backup_next_qrcode != this.send_next_qrcode ) {
				this.editNextQrcode(this.id_qrcode, this.send_next_qrcode)
			}
			else{
				this.action.next('yes')
				this.modalRef.hide();
			}

		})
	}
	
	patchQrCode(){
		this.textButton = 'Salvando';
		this.qr_code = this.getQrCode()
		
		this.qrCodeService.patchQrCode(this.id_qrcode, this.name, this.store, this.id_establishment, this.address, 
			this.information, this.qr_code_link, this.registration).subscribe(data=>{
				
				
			if((this.send_next_qrcode != null && this.backup_next_qrcode != this.send_next_qrcode) || this.first_of_flow != this.backup_first_of_flow) {
				this.editNextQrcode(this.id_qrcode, this.send_next_qrcode)
			}
			else{
				this.textButton = 'Salvar';
				this.action.next('yes')
				this.modalRef.hide();
			}

		},
		error=>{
			this.textButton = 'Salvar';
			console.log(error)
			this.message_erro = error;
		})
	}

	editNextQrcode(qr_code, send_next_qrcode){

		this.qrCodeService.EditNextQrcode(qr_code, send_next_qrcode, this.first_of_flow).subscribe(data =>{

			if(data.worked == true){
				this.action.next('yes')
				this.modalRef.hide();
			}
			else if(data.detail != null){
				this.message_erro = data.detail;
			}
			else{
				this.message_erro = 'Selecione outro QRCode que não esteja sendo usado em um fluxo.';
			}
			
		},
		error=>{
			console.log(error)
			this.message_erro = error.error.detail;
		})
	}

}