import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class UserCampaignService {

    constructor(
        private http: HttpClient,
    ) { }

    listSubject = new Subject<String>();
    establishment = "id_establishment"

    getUserCampaign(token): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('user', `${token}`),
        };

        return this.http.get<any>(AppConfig.path + `core/user-campaign/`, options);
    }

    getUserCampaignMe(): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('user', `${localStorage.getItem('tokenUser')}`)
        };

        return this.http.get<any>(AppConfig.path + `core/user-campaign/`, options);
    }

    getUserCampaignAll(page, id_establishment): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, id_establishment)
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/user-campaign/all-user-campaign/`, options);
    }

    getUserCampaignLogs(token, campaign, user_campaign): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('user', `${token}`),
            params: new HttpParams()
                .set('campaign', `${campaign}`)
                .set('user_campaign', `${user_campaign}`)
        };

        return this.http.get<any>(AppConfig.path + `core/user-campaign/logs/`, options);
    }

    createLogs(token, campaign, user_campaign, qrcode, navegation): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set(`log`, JSON.stringify({
                    campaign: campaign,
                    qrcode: qrcode,
                    user_campaign: user_campaign,
                    navegation: navegation
                }))
        };

        if (token != null) {
            options.headers = options.headers.set('user', `token ${token}`)
        }

        return this.http.get<any>(AppConfig.path + `core/campaign/${campaign}/`, options);
    }

    getLogs(page, size) {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`page`, page)
                .set(`page_size`, size)
        };

        return this.http.get<any>(AppConfig.path + `core/log/`, options)
    }

    saveLogs(campaign, description) {
        let body = new HttpParams()
            .set(`campaign`, campaign)
            .set(`description`, JSON.stringify({
                action: description.action,
                data: description.data,
                format: description.format,
                id_item: description.id_item,
                user: description.user,
                type_page: description.type_page,
                qrcode: description.qrcode
            }))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/log/`, body, { headers })
    }

    postUserCampaignCoupon(token, campaign): Observable<any> {
        let body = new HttpParams()
            .set(`campaign`, campaign)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('user', `${token}`)

        return this.http.post<any>(AppConfig.path + `core/user-campaign/coupon/`, body, { headers });
    }

    postUserCampaign(establishment, user_token, list): Observable<any> {
        let body = new HttpParams()
            .set('establishment', establishment)
            .set('user_token', user_token)

        list.forEach(element => {
            if (element.active && (element.input != null && element.input != '')) {
                body = body.set(element.name_api, element.input);
            }
        });

        return this.http.post<any>(AppConfig.path + `core/user-campaign/`, body);
    }

    postUserCampaignAssai(establishment, user_token, list): Observable<any> {
        let body = new HttpParams()
            .set('establishment', establishment)
            .set('user_token', user_token)
            .set('name', list.name)
            .set('email', list.email)
            .set('cpf', list.cpf)
            .set('gender', list.gender)

        return this.http.post<any>(AppConfig.path + `core/user-campaign/`, body);
    }

    sendEmailUserCampaign(email, phone, token): Observable<any> {
        // console.log('SEND EMAIL')

        let body = new HttpParams()
        if (email != null) {
            body = body.set(`email`, email)
        }
        if (localStorage.getItem(this.establishment) != null) {
            body = body.set(`establishment`, localStorage.getItem(this.establishment))
        }
        // if(phone != null) body = body.set(`phone`, phone)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('user', `${token}`)        

        return this.http.post<any>(AppConfig.path + `core/user-campaign/user-campaign-validation/`, body, { headers });
    }

    validedHash(token, email, phone, hash): Observable<any> {
        // console.log('CHECK CODE')

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set(`hash`, hash)
                .set(`establishment`, localStorage.getItem(this.establishment)),

        };

        if (email != null) options.params = options.params.set(`email`, email)
        if (phone != null) options.params = options.params.set(`phone`, phone)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('user', `${token}`)

        return this.http.get<any>(AppConfig.path + `core/user-campaign/user-campaign-valided/`, options);
    }

    getLoginUser(email) {
        // console.log('CHECK USER')

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`email`, email)
                .set(`establishment`, localStorage.getItem(this.establishment))
        };

        return this.http.get<any>(AppConfig.path + `core/user-campaign/check-user/`, options)
    }


    exchangeTokenUserCampaign(hash, tokenUser): Observable<any> {
        let body = new HttpParams()
            .set(`hash`, hash)
            .set(`user_campain_token`, tokenUser)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

        return this.http.post<any>(AppConfig.path + `core/change-token/`, body, { headers });
    }

}
