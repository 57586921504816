import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClientModule } from "@angular/common/http";
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { MatExpansionModule } from '@angular/material/expansion';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { NgApexchartsModule } from "ng-apexcharts";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PumpHouseComponent } from './file-screen/pdf-pages/pump-house/pump-house.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SurveyComponent } from './redirect/survey/survey.component';
import { CatalogComponent } from './redirect/catalog/catalog.component';
import { RedeComponent } from './rede/rede.component';
import { CampaignComponent } from './campaign/campaign.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { ModalChoiceComponent } from './modals/modal-choice/modal-choice.component';
import { QrcodesComponent } from './channels/qrcodes/qrcodes.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { ModalQrcodeComponent } from './channels/qrcodes/modal-qrcode/modal-qrcode.component';
import { CatalogDetailComponent } from './catalogs/catalog-detail/catalog-detail.component';
import { CatalogViewComponent } from './catalogs/catalog-view/catalog-view.component';
import { MoreDetailsComponent } from './catalogs/catalog-view/more-details/more-details.component';
import { MiniBannerComponent } from './redirect/mini-banner/mini-banner.component';
import { BannerComponent } from './redirect/banner/banner.component';
import { VideoComponent } from './redirect/video/video.component';
import { LinkComponent } from './redirect/link/link.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NgxMaskModule } from 'ngx-mask';
import { LoginComponent } from './login/login.component';
import { PreviewCampaignComponent } from './modals/preview-campaign/preview-campaign.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { CreateSurveyDetailComponent } from './create-survey/create-survey-detail/create-survey-detail.component';
import { ModalPreviewComponent } from './modals/modal-preview/modal-preview.component';
import { GalleryComponent } from './redirect/gallery/gallery.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { ChannelsComponent } from './channels/channels.component';
import { CouponComponent } from './coupon/coupon.component';
import { CouponDetailComponent } from './coupon/coupon-detail/coupon-detail.component';
import { RegistrationConfigComponent } from './registration-config/registration-config.component';
import { RegistrationConfigDetailComponent } from './registration-config/registration-config-detail/registration-config-detail.component';
import { ModalCatalogPreviewComponent } from './catalogs/modal-catalog-preview/modal-catalog-preview.component';
import { ModalSurveyPreviewComponent } from './create-survey/modal-survey-preview/modal-survey-preview.component';
import { ModalConfigPreviewComponent } from './registration-config/modal-config-preview/modal-config-preview.component';
import { ModalCouponPreviewComponent } from './coupon/modal-coupon-preview/modal-coupon-preview.component';
import { CellPhoneComponent } from './modals/cell-phone/cell-phone.component';
import { WhatsappComponent } from './channels/whatsapp/whatsapp.component';
import { SmsComponent } from './channels/sms/sms.component';
import { AppChannelComponent } from './app-channel/app-channel.component';
import { EmbedComponent } from './channels/embed/embed.component';
import { SmsDetailComponent } from './channels/sms/sms-detail/sms-detail.component';
import { WhatsappDetailComponent } from './channels/whatsapp/whatsapp-detail/whatsapp-detail.component';
import { EmailsComponent } from './channels/emails/emails.component';
import { EmailsDetailComponent } from './channels/emails/emails-detail/emails-detail.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupDetailComponent } from './groups/group-detail/group-detail.component';
import { CupomComponent } from './redirect/cupom/cupom.component';
import { UserRegistrationComponent } from './redirect/user-registration/user-registration.component';
import { CreateCampaignComponent } from './campaign/create-campaign/create-campaign.component';
import { DetailCampaignComponent } from './campaign/detail-campaign/detail-campaign.component';
import { ModalRegistrationPreviewComponent } from './registrations/modal-registration-preview/modal-registration-preview.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { SalesComponent } from './sales/sales.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { ProductComponent } from './product/product.component';
import { CategoriesComponent } from './categories/categories.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { DetailProductComponent } from './product/detail-product/detail-product.component';
import { OrderComponent } from './order/order.component';
import { ShoppingCartComponent } from './redirect/catalog/shopping-cart/shopping-cart.component';
import { FinishBuyComponent } from './redirect/catalog/finish-buy/finish-buy.component';
import { DashboardMidiaComponent } from './dashboard-midia/dashboard-midia.component';
import { SurveyAnswersComponent } from './create-survey/survey-answers/survey-answers.component';
import { DetailCategoryComponent } from './categories/detail-category/detail-category.component';
import { DashboardPreviewLogComponent } from './dashboard-midia/dashboard-preview-log/dashboard-preview-log.component';
import { ScreenTagsComponent } from './screen-tags/screen-tags.component';
import { DetailTagComponent } from './screen-tags/detail-tag/detail-tag.component';
import { RedirectSbsComponent } from './redirect-sbs/redirect-sbs.component';
import { PostComponent } from './redirect/post/post.component';
import { LoginUserComponent } from './redirect/catalog/login-user/login-user.component';
import { LoginAssaiComponent } from './redirect-sbs/login-assai/login-assai.component';
import { PreviwOrderComponent } from './order/previw-order/previw-order.component';
import { SidebarLateralComponent } from './redirect-sbs/sidebar-lateral/sidebar-lateral.component';
import { ModalEditStatusComponent } from './order/modal-edit-status/modal-edit-status.component';
import { PodcastComponent } from './redirect/podcast/podcast.component';
import { CreateCampaignFeedComponent } from './campaign/create-campaign-feed/create-campaign-feed.component';
import { PostFeedComponent } from './campaign/post-feed/post-feed.component';
import { PreviewPostComponent } from './modals/preview-post/preview-post.component';
import { DetailSidebarComponent } from './campaign/detail-sidebar/detail-sidebar.component';
import { CourseComponent } from './course/course.component';
import { DetailCourseComponent } from './course/detail-course/detail-course.component';
import { CourseVideoComponent } from './redirect/course-video/course-video.component';
import { ViewCourseComponent } from './redirect/view-course/view-course.component';
import { MessageQrcodeComponent } from './modals/message-qrcode/message-qrcode.component';
import { PopupReportComponent } from './channels/qrcodes/popup-report/popup-report.component';
import { CheckPointComponent } from './redirect/check-point/check-point.component';
import { ReportCampaignComponent } from './campaign/report-campaign/report-campaign.component';
import { NoticeComponent } from './notice/notice.component';
import { DetailNoticeComponent } from './notice/detail-notice/detail-notice.component';
import { ViewNoticeComponent } from './redirect/view-notice/view-notice.component';
import { ModalTypeLinkComponent } from './redirect-sbs/sidebar-lateral/modal-type-link/modal-type-link.component';
import { ImportCsv } from './modals/import-csv/import-csv.component';
import { ViewMenuComponent } from './redirect/view-menu/view-menu.component';
import { ViewProductComponent } from './redirect/view-menu/view-product/view-product.component';
import { PopupEditProdutCatalogComponent } from './catalogs/popup-edit-produt-catalog/popup-edit-produt-catalog.component';
import { FileScreenComponent } from './file-screen/file-screen.component';
import { PopupImportFileComponent } from './file-screen/popup-import-file/popup-import-file.component';
import { PaymentComponent } from './redirect/payment/payment.component';
import { SpreadsheetComponent } from './file-screen/spreadsheet/spreadsheet.component';
import { SpreadsheetTableComponent } from './file-screen/spreadsheet-table/spreadsheet-table.component';
import { PopupGenerateFileLinkComponent } from './modals/popup-generate-file-link/popup-generate-file-link.component';
import { SiteComponent } from './channels/site/site.component';
import { ChecklistFireExtinguishersComponent } from './file-screen/pdf-pages/checklist-fire-extinguishers/checklist-fire-extinguishers.component';
import { ChecklistAlarmComponent } from './file-screen/pdf-pages/checklist-alarm/checklist-alarm.component';
import { ControlVgComponent } from './file-screen/pdf-pages/control-vg/control-vg.component';
import { PaintingComponent } from './file-screen/pdf-pages/painting/painting.component';
import { GasFmComponent } from './file-screen/pdf-pages/gas-fm/gas-fm.component';
import { EmergencyExitComponent } from './file-screen/pdf-pages/emergency-exit/emergency-exit.component';
import { GasEaselsComponent } from './file-screen/pdf-pages/gas-easels/gas-easels.component';
import { MinimaxComponent } from './file-screen/pdf-pages/minimax/minimax.component';
import { ExtinguisherChecklistComponent } from './file-screen/pdf-pages/extinguisher-checklist/extinguisher-checklist.component';
import { ControlHidranteComponent } from './file-screen/pdf-pages/control-hidrante/control-hidrante.component';
import { TabloideComponent } from './tabloide/tabloide.component';
import { ViewTabloideComponent } from './redirect/view-tabloide/view-tabloide.component';
import { DetailTabloideComponent } from './tabloide/detail-tabloide/detail-tabloide.component';
import { PhaseOrderComponent } from './redirect/view-tabloide/phase-order/phase-order.component';
import { OrderTabloideComponent } from './order-tabloide/order-tabloide.component';

registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		HomeComponent,
		RedirectComponent,
		SurveyComponent,
		CatalogComponent,
		RedeComponent,
		CampaignComponent,
		ModalMessageComponent,
		ModalChoiceComponent,
		QrcodesComponent,
		CatalogsComponent,
		ModalQrcodeComponent,
		CatalogDetailComponent,
		CatalogViewComponent,
		MoreDetailsComponent,
		MiniBannerComponent,
		BannerComponent,
		VideoComponent,
		LinkComponent,
		LoginComponent,
		PreviewCampaignComponent,
		CreateSurveyComponent,
		CreateSurveyDetailComponent,
		ModalPreviewComponent,
		GalleryComponent,
		RegistrationsComponent,
		ChannelsComponent,
		CouponComponent,
		CouponDetailComponent,
		RegistrationConfigComponent,
		RegistrationConfigDetailComponent,
		ModalCatalogPreviewComponent,
		ModalSurveyPreviewComponent,
		ModalConfigPreviewComponent,
		ModalCouponPreviewComponent,
		CellPhoneComponent,
		WhatsappComponent,
		SmsComponent,
		AppChannelComponent,
		EmbedComponent,
		SmsDetailComponent,
		WhatsappDetailComponent,
		EmailsComponent,
		EmailsDetailComponent,
		GroupsComponent,
		GroupDetailComponent,
		CupomComponent,
		UserRegistrationComponent,
		CreateCampaignComponent,
		DetailCampaignComponent,
		ModalRegistrationPreviewComponent,
		MenuLateralComponent,
		SalesComponent,
		WelcomeScreenComponent,
		ProductComponent,
		CategoriesComponent,
		PaymentOptionsComponent,
		DetailProductComponent,
		OrderComponent,
		ShoppingCartComponent,
		FinishBuyComponent,
		DashboardMidiaComponent,
		SurveyAnswersComponent,
		DetailCategoryComponent,
		DashboardPreviewLogComponent,
		ScreenTagsComponent,
		DetailTagComponent,
		RedirectSbsComponent,
		PostComponent,
		LoginUserComponent,
		LoginAssaiComponent,
		PreviwOrderComponent,
		SidebarLateralComponent,
		ModalEditStatusComponent,
		PodcastComponent,
		CreateCampaignFeedComponent,
		PostFeedComponent,
		PreviewPostComponent,
		DetailSidebarComponent,
		CourseComponent,
		DetailCourseComponent,
		PumpHouseComponent,
		CourseVideoComponent,
		ViewCourseComponent,
		MessageQrcodeComponent,
		PopupReportComponent,
		CheckPointComponent,
		ReportCampaignComponent,
		NoticeComponent,
		DetailNoticeComponent,
		ViewNoticeComponent,
		ModalTypeLinkComponent,
		ImportCsv,
		ViewMenuComponent,
		ViewProductComponent,
		PopupEditProdutCatalogComponent,
		FileScreenComponent,
		PopupImportFileComponent,
		PaymentComponent,
		SpreadsheetComponent,
		SpreadsheetTableComponent,
		PopupGenerateFileLinkComponent,
		SiteComponent,
		ChecklistFireExtinguishersComponent,
		ChecklistAlarmComponent,
		ControlVgComponent,
		PaintingComponent,
		GasFmComponent,
		EmergencyExitComponent,
		GasEaselsComponent,
		MinimaxComponent,
		ExtinguisherChecklistComponent,
		ControlHidranteComponent,
		TabloideComponent,
		ViewTabloideComponent,
		DetailTabloideComponent,
		PhaseOrderComponent,
		OrderTabloideComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatButtonModule,
		HttpClientModule,
		MatRadioModule,
		MatCheckboxModule,
		MatSelectModule,
		FormsModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatChipsModule,
		FormsModule,
		MatAutocompleteModule,
		ReactiveFormsModule,
		MatTooltipModule,
		DragDropModule,
		MatBottomSheetModule,
		QRCodeModule,
		MatExpansionModule,
		SlickCarouselModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatTabsModule,
		MDBBootstrapModule.forRoot(),
		NgxMaskModule.forRoot(),
		NgApexchartsModule,
		MatSnackBarModule,
		MatBadgeModule,
		MatSidenavModule,
		MatMenuModule,
		MatIconModule,
		ClipboardModule
	],
	providers: [
		DatePipe,
		MatFormFieldModule,
		MatDatepickerModule,
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
		{ provide: LOCALE_ID, useValue: "pt" } 
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
