<div *ngIf="!loading && !success" style="width: 100%;">
    <div class="screen">
        
        <div class="center-card">
            <div class="div-row">
            
                <div class="div-product">
                    <table mat-table [dataSource]="data_source" class="mat-elevation-z8">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef style="width: 60%;"
                                [ngClass]="{'font_phone': phone}"> Nome </th>
                            <td mat-cell *matCellDef="let data" [ngClass]="{'font_phone': phone}"> 
                                <span [ngClass]="{'font_phone': phone, 'break_line_phone' : phone}"> {{data.name}} </span>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"
                                [ngClass]="{'font_phone': phone}"> Qtd. </th>
                            <td mat-cell *matCellDef="let data"
                                [ngClass]="{'font_phone': phone}"> {{data.qtd}}x </td>
                        </ng-container>
                
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"
                                [ngClass]="{'font_phone': phone}"> Preço </th>
                            <td mat-cell *matCellDef="let data"
                                [ngClass]="{'font_phone': phone}"> {{convertMoney(data.price)}} </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                        <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
                    </table>
                    <div class="total-div" [ngStyle]="{'padding-right': web ? '60px' : '24px'}">
                        Total: {{convertMoney(cart.total)}}
                    </div>
                </div>
    
                <div class="info-payment">
                    <div style="width: 100%; font-size: 20px; font-weight: 600; margin-bottom: 10px; color: black;">
                        Informações de pagamento
                    </div>

                    <div [ngClass]="{'div_input_web' : web, 'div_input_phone' : phone}">
                        <mat-form-field class="input">
                            <mat-label>Nome</mat-label>
                            <input matInput placeholder="nome" autocomplete="off" [(ngModel)]="info.name">
                        </mat-form-field>
                        
                        <mat-form-field class="input">
                            <mat-label>E-mail</mat-label>
                            <input matInput placeholder="e-mail" autocomplete="off" [(ngModel)]="info.email">
                        </mat-form-field>
                        
                        <mat-form-field class="input">
                            <mat-label>CPF</mat-label>
                            <input matInput placeholder="000.000.000-00" mask="000.000.000-00" 
                                autocomplete="off" [(ngModel)]="info.cpf" [dropSpecialCharacters]="false">
                        </mat-form-field>
                
                        <mat-form-field class="input">
                            <mat-label>Tipo de pagamento</mat-label>
                            <mat-select [(ngModel)]="info.payment.selected_payment">
                                <mat-option *ngFor="let pay of type_payment" [value]="pay.name"> 
                                    {{ pay.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Bandeira do cartão</mat-label>
                            <input matInput placeholder="bandeira do cartão" disabled [(ngModel)]="info.payment.brand">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Nº do cartão</mat-label>
                            <input matInput placeholder="numero do cartão" autocomplete="off" 
                                (keyup)="checkBrandCard(info.payment.number_card)" [(ngModel)]="info.payment.number_card">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Nome do títular</mat-label>
                            <input matInput placeholder="nome do títular" autocomplete="off" [(ngModel)]="info.payment.holder">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Validade (mm/aaaa)</mat-label>
                            <input matInput placeholder="validade" autocomplete="off" mask="00/0000" 
                                [dropSpecialCharacters]="false" [(ngModel)]="info.payment.date">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Código de segurança</mat-label>
                            <input matInput placeholder="código" autocomplete="off" [(ngModel)]="info.payment.code">
                        </mat-form-field>

                    </div>

                    <div style="width: 100%; font-size: 20px; font-weight: 600; margin-bottom: 10px; color: black;">
                        Endereço de entrega
                    </div>

                    <div [ngClass]="{'div_input_web' : web, 'div_input_phone' : phone}">

                        <mat-form-field class="input">
                            <mat-label>CEP</mat-label>
                            <input matInput placeholder="00000-000" mask="00000-000" 
                                autocomplete="off" [(ngModel)]="info.address.cep" (keyup)="checkCEP(info.address.cep)">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Estado</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.state">
                        </mat-form-field>
        
                        <mat-form-field class="input">
                            <mat-label>Cidade</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.city">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Bairro</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.neighborhood">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Endereço</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.address">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Numero</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.number">
                        </mat-form-field>

                        <mat-form-field class="input">
                            <mat-label>Complemento</mat-label>
                            <input matInput 
                                autocomplete="off" [(ngModel)]="info.address.complement">
                        </mat-form-field>
                    </div>

                    <div class="finish">
                        <span style="color: rgb(75, 1, 1);"> {{error_message}} </span>
                        <button mat-button class="button" [style.color]="header.color" 
                        [ngStyle]="{'margin' : phone ? '0 auto' : '5px 0 0 auto'}"
                        [style.background]="header.back" (click)="check()" >
                            FINALIZAR COMPRA
                        </button>
                    </div>
                </div>

            </div>
        </div>
    
    </div>
</div>

<div style="width: 100%; height: 100vh; display: flex; align-items: center; justify-content: center;" *ngIf="loading">
    <!-- <mat-spinner></mat-spinner> -->
</div>

<div class="success" *ngIf="!loading && success">

    <div *ngIf="info.payment.selected_payment == 'picpay'" class="card-info">
        <b>Pedido realizado com sucesso!</b>
    </div>

    <button mat-button class="button" (click)="back(0)" style="margin: 20px auto;">
        VOLTAR
    </button>

</div>