<div *ngIf="!loading && page == 'shopping cart'">
    <div class="screen">
        <!-- <img class="img-logo" [src]=" constants.logo == null? '../../assets/image/logo2.png' : constants.logo" routerLink="/home"> -->

        <div class="page-title">
            Carrinho
        </div>

        <div *ngIf="arrayItens.length != 0"
            style="width: 90%; max-width: 1000px; display: flex; justify-content: center; flex-direction: column;">
            <div *ngFor="let product of arrayItens; let i = index"
                [ngClass]="{'div_row_web' : web, 'div_row_phone' : phone}">
                <img class="img-product" loop=infinite
                    [ngClass]="{'img_product_web' : web, 'img_product_phone' : phone}"
                    [src]="product.image == null? '../../../../assets/icon/icone-produtos.png' : product.image">
                <div [ngClass]="{'div_info_web' : web, 'div_info_phone' : phone}">
                    <div class="nameProduct"> {{ product.name == null? 'Produto' : product.name }} </div>

                    <div [ngClass]="{'descrition_web' : web, 'descrition_phone' : phone}">
                        {{ product.description == null? textEx : product.description }}
                    </div>

                    <div [ngClass]="{'price_product_web' : web, 'price_product_phone' : phone}"> {{
                        convertMoney(product.price) }} </div>

                    <div [ngClass]="{'div_button_web' : web, 'div_button_phone' : phone}">
                        <button mat-button class="btn-add" (click)="remQtd(product)" matTooltip="Diminuir quantidade">
                            -
                        </button>
                        <div class="btn-num" matTooltip="Quantidade de itens">
                            {{ product.qtd }}
                        </div>
                        <button mat-button class="btn-rem" (click)='addQtd(product)' matTooltip="Aumentar quantidade">
                            +
                        </button>

                        <div mat-button class="bnt-delet" [style.background]="header.back"
                            style="margin-left: 20px; display: flex; justify-content: center; align-items: center;"
                            (click)="removerProduct(i)" matTooltip="Remover item">
                            <!-- <img src="../../../../assets/icon/icon_excluir.png"> -->
                            <svg height="24px" viewBox="0 0 24 24" width="24px">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path fill="#fff"
                                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                            </svg>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card_row">
                <div class="div-input w_70">
                    <label>Endereço</label>
                    <input mat-input placeholder="Endereço" type="text" [(ngModel)]="address.name"
                        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">
                </div>

                <div class="div-input w_30">
                    <label>Numero</label>
                    <input mat-input placeholder="Numero" type="number" [(ngModel)]="address.number"
                        [ngStyle]="{'border-color': warningMap.get('number')? 'red' : ''}" class="input">
                </div>

                <div class="div-input w_50">
                    <label>CEP</label>
                    <input mat-input placeholder="CEP" type="text" [(ngModel)]="address.cep" mask="00000-000"
                        [ngStyle]="{'border-color': warningMap.get('cep')? 'red' : ''}" class="input">
                </div>

                <div class="div-input w_50">
                    <label>Bairro</label>
                    <input mat-input placeholder="Bairro" type="text" [(ngModel)]="address.district"
                        [ngStyle]="{'border-color': warningMap.get('district')? 'red' : ''}" class="input">
                </div>
            </div>

            <div class="total-buy" [ngClass]="{'div_buy_web' : web, 'div_buy_phone' : phone}">
                <div style="color: black;" [ngStyle]="{'margin': web ? '0' : '0 0 10px 0'}">
                    Total {{ convertMoney(valorTotal) }}
                </div>
                <!-- <button mat-button (click)="finish()" [style.background]="header.back"> FINALIZAR COMPRA </button> -->
                <button mat-button (click)="checkInput()" [style.color]="header.color" [style.background]="header.back">
                    ENVIAR PEDIDO
                    <span *ngIf="loading_order" class="spinner-border spinner-border-sm m_left" role="status"
                        aria-hidden="true"></span>
                </button>
            </div>

            <div class="card_frete">
                <h1>FRETE</h1>
                <h1> {{ catalog.shipping_title }} </h1>
                <p> {{ catalog.shippin_description }} </p>
            </div>
        </div>

        <div *ngIf="arrayItens.length == 0" class="none-product" [ngStyle]="{'color': text_color}">
            Nenhum produto selecionado.
        </div>
    </div>
</div>

<div class="card_payment" *ngIf="page == 'payment'">
    <div class="page-title" style="width: 100%;">
        Pagamento
    </div>

    <div class="card_row">
        <div class="div-input w_70">
            <label>Número do cartão</label>
            <input mat-input type="text" [(ngModel)]="card.number" placeholder="0000 0000 0000 0000" autocomplete="off"
                mask="0000 0000 0000 0000 000" class="input"
                [ngStyle]="{'border-color': warningMap.get('number')? 'red' : ''}">
        </div>

        <div class="div-input w_30">
            <label>Validade</label>
            <input mat-input type="text" [(ngModel)]="card.date" placeholder="Validade" autocomplete="off"
                mask="00/0000" [dropSpecialCharacters]="false" class="input"
                [ngStyle]="{'border-color': warningMap.get('date')? 'red' : ''}">
        </div>

        <div class="div-input w_70">
            <label>Nome do titular</label>
            <input mat-input type="text" [(ngModel)]="card.holder" placeholder="Nome do titular" autocomplete="off"
                class="input" [ngStyle]="{'border-color': warningMap.get('holder')? 'red' : ''}">
        </div>

        <div class="div-input w_30">
            <label>CVV</label>
            <input mat-input type="text" [(ngModel)]="card.code" placeholder="000" autocomplete="off" mask="000000"
                class="input" [ngStyle]="{'border-color': warningMap.get('code')? 'red' : ''}">
        </div>

        <mat-form-field class="card_select w_50">
            <label>Bandeira</label>

            <mat-select class="select-box" [(ngModel)]="card.brand">
                <mat-option *ngFor="let item of list_brand;" [value]="item.api_name">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- <div class="div-input w_50">
            <input mat-input type="text" [(ngModel)]="card.brand" placeholder="Bandeira" autocomplete="off"
                class="input" [ngStyle]="{'border-color': warningMap.get('brand')? 'red' : ''}">
        </div> -->
    </div>

    <div class="total-buy" [ngClass]="{'div_buy_web' : web, 'div_buy_phone' : phone}">

        <button mat-button (click)="checkPayment()" [style.color]="header.color" [style.background]="header.back">
            FINALIZAR COMPRA
            <span *ngIf="loading_order" class="spinner-border spinner-border-sm m_left" role="status"
                aria-hidden="true"></span>
        </button>
    </div>
</div>

<div class="card_success" [ngStyle]="{'height': preview ? '80vh' : '50vh'}" *ngIf="page == 'success'">
    <div class="title_success" style="color: black;" [ngStyle]="{'font-size': preview ? '25px' : '18px'}">
        Pedido enviado com sucesso!
    </div>

    <button mat-button class="button" (click)="backPageCatalog()" [style.color]="header.color"
        [style.background]="header.back">
        Voltar
    </button>
</div>

<!-- <div style="width: 100%; height: 100vh; display: flex; align-items: center; justify-content: center;" *ngIf="loading"
    [ngStyle]="{ 'background-color': constants == null || constants.background_color == null? '#020308' : constants.background_color }">
    <mat-spinner></mat-spinner>
</div> -->