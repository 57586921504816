<div class="screen">

    <div class="div-imagens" *ngIf="loading">

        <div *ngFor="let item of listImage" style="width: 100%;">

            <img *ngIf="item.image!=null"
                loop=infinite
                [src]="item.image" class="photo" 
                [ngStyle]="{'border-color': header.back}"
                (click)="addNewItem(item)">
            
            <img *ngIf="item.url!=null" 
                loop=infinite
                [src]="item.url" class="photo" 
                [ngStyle]="{'border-color': header.back}"
                (click)="addNewItem(item)">
        </div>

        <!-- <div *ngIf="listImage==null" style="width: 100%; text-align: center; padding: 25px 0;"> 
            Banner sem imagem salva 
        </div> -->
    </div>

</div>