<div *ngIf="page == 3" style="margin: 150px 0;">
    <div class="card" [ngClass]="{'card-web' : urlPage !='campaign', 'card-cell' : urlPage =='campaign'}">

        <h2> Login </h2>
        <div style="font-size: 10px; cursor: pointer;" (click)="page = 0"> Cadastre-se </div>

        <input mat-input [(ngModel)]="email" style="margin: 20px auto; background-color: #fff;
            width: 95%; display: flex; justify-content: center; font-size: 14px;"
            [ngStyle]="{'border-color': warningMap.get('email')? 'red' : ''}"
            placeholder="Digite e-mail para enviar o código" class="input">
        
        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <button mat-button class="button" [style.background]="header.back" [style.color]="header.color" (click)="sendCode()"> 
                Enviar código
                <span *ngIf="loading_login" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>
            </button>
        </div>

        <div style="max-width: 350px; padding: 0 10px; margin: 0 auto 0; 
            text-align: center; font-size: 12px; font-weight: 500;" *ngIf="loading_message">
            <span *ngIf="loading_send">
                Não recebeu o código ? <br>
                <span class="click" (click)="sendCode()">Clique aqui</span> para reenviar.
            </span>
            <span *ngIf="!loading_send">
                Código reenviado.
            </span>
        </div>
    </div>
</div>

<div class="primary-div" *ngIf="page == 0">
    <div style="width: 100%; margin-top: 15px; display: flex; justify-content: center; flex-direction: column; align-items: center;">
        <!-- <img [src]="isList.image" class="img-config"> -->
        <h2 style="text-align: center;"> {{ isList.title }} </h2>
        <div style="font-size: 10px; cursor: pointer;" (click)="page = 3"> Login </div>

    </div>

    <div *ngFor="let item of list_order" style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <input mat-input [(ngModel)]="item.input" [mask]="item.mask"
            *ngIf="item.active && (item.title == 'Telefone' || item.title == 'E-mail' || item.title == 'Nome' || item.title == 'CPF' || item.title == 'CEP')"
            placeholder="{{ item.active_mandatory? '*' : '' }}{{ item.placeholder }}"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}" class="input">

        <mat-select [(ngModel)]="item.input" class="input" 
            *ngIf="item.active && item.title == 'Orientação Sexual'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}"
            placeholder="{{ item.active_mandatory? '*' : '' }}Selecione a Orientação Sexual">
            <mat-option *ngFor="let item of list_select.orientation; let i = index" [value]="item.title">
                {{item.title}}
            </mat-option>
        </mat-select>
        
        <mat-select [(ngModel)]="item.input" class="input" 
            *ngIf="item.active && item.title == 'Identidade de Gênero'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}"
            placeholder="{{ item.active_mandatory? '*' : '' }}Selecione a Identidade de Gênero">
            <mat-option *ngFor="let item of list_select.identity; let i = index" [value]="item.title">
                {{item.title}}
            </mat-option>
        </mat-select>
        
        <mat-form-field color="accent" style="width: 100%; padding: 0 20px;" class="input" 
            *ngIf="item.active && item.title == 'Data de nascimento'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}">

            <input matInput [matDatepicker]="picker1" placeholder="{{ item.active_mandatory? '*' : '' }}{{ item.placeholder }}" 
                [(ngModel)]="item.input" readonly>

            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

    </div>

    <div class="terms" *ngIf="isList?.terms != null" 
        [ngStyle]="{'border-color': warningMap.get('check')? 'red' : ''}">

        <mat-slide-toggle [(ngModel)]="input.check">
            Concordar com os 
            <a href="{{openTerms()}}" [style.color]="header.back" target="_blank"> 
                Termos de Uso
            </a> 
        </mat-slide-toggle>
    </div>

    <div class="div-checkbox" [ngStyle]="{'border-color': warningMap.get('checkInfo')? 'red' : ''}">
        <mat-checkbox style="width: 100%; max-width: 350px; display: flex; justify-content: center; align-items: center;"
            [(ngModel)]="input.checkInfo">Confirmo que todas as informações <br> são verdadeiras.</mat-checkbox>
    </div>
    

    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <button mat-button class="button" [style.background]="header.back" [style.color]="header.color" (click)="checkRegister()" > 
            Cadastrar
        </button>
    </div>
</div>

<div *ngIf="page == 1" style="margin: 150px 0;">
    <div style="width: 100%; margin-top: 15px; display: flex; justify-content: center; 
        flex-direction: column; align-items: center; text-align: center;">
        <h2> Cadastro realizado com sucesso! </h2>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <button mat-button class="button" (click)="next()" > 
            Continuar
        </button>
    </div>
</div>

<div *ngIf="page == 2" style="margin: 150px 0;">
    <div class="card" [ngClass]="{'card-web' : urlPage !='campaign', 'card-cell' : urlPage =='campaign'}">

        <h2> Insira o código enviado para o seu e-mail ou celular </h2>

        <input mat-input [(ngModel)]="validation_code" style="margin: 20px auto; background-color: #fff;
            width: 85%; display: flex; justify-content: center; font-size: 15px;"
            [ngStyle]="{'border-color': warningMap.get('validation_code')? 'red' : ''}"
            placeholder="Digite o código"class="input">
        
        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <button mat-button class="button" [style.background]="header.back" [style.color]="header.color" (click)="checkCode()"> 
                Validar
                <span *ngIf="loading_code" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>

            </button>
        </div>
        <div *ngIf="textError != ''" style="font-size: 12px; color: red; text-align: right;">
            {{ textError }}
        </div>

        <div style="max-width: 350px; padding: 0 10px; margin: 0 auto 0; 
            text-align: center; font-size: 12px; font-weight: 500;" *ngIf="loading_message">
            <span *ngIf="loading_send">
                Não recebeu o código ? <br>
                <span class="click" (click)="sendCode()">Clique aqui</span> para reenviar.
            </span>
            <span *ngIf="!loading_send">
                Código reenviado.
            </span>
        </div>
    </div>
</div>