import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-gas-fm',
  templateUrl: './gas-fm.component.html',
  styleUrls: ['./gas-fm.component.css']
})
export class GasFmComponent implements OnInit {

  title: string;
  local: string;
  table: [];
  list_description: [];
  header: [];
  footer: [];

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.table);
    console.log(this.local);
    console.log(this.title, 'title');
    
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

}
