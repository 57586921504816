import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { StorageService } from 'src/app/service/storage.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})

export class LoginUserComponent implements OnInit {

  constructor(
    private userCampaignService: UserCampaignService,
    private storageService: StorageService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.url_screen = router.url;
    var url = this.router.url;
    var aux = url.split('/')
    var tam = aux.length
    this.urlPage = aux[tam - 2]
  }

  @Input() list: any;
  @Input() header: any;
  @Input() catalog: any;
  @Input() preview: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  warningMap = new Map();
  urlPage;
  url_screen;

  listImage;
  isList;
  loading: boolean = true;
  loading_message: boolean = false;
  loading_send: boolean = true;
  url = AppConfig.path;
  page: number = 3;

  list_order = []

  user = {
    birthdate: null,
    cep: null,
    cpf: null,
    email: null,
    email_hash_expire: null,
    establishment: null,
    gender: null,
    gender_identity: null,
    id: null,
    name: null,
    phone: null,
    sexual_orientation: null,
    token: null,
    verification_email_hash: null,
    verified_email: true
  };

  input = {
    name: null,
    email: null,
    birthdate: null,
    cpf: null,
    sexual_orientation: null,
    gender_identity: null,
    cep: null,
    phone: null,
    check: false,
    password: null,
    password2: null,
    checkInfo: false,
  }

  list_select = {
    orientation: [
      { title: 'Não Apurado' },
      { title: 'Heterossexual' },
      { title: 'Homossexual' },
      { title: 'Bissexual' },
      { title: 'Assexual' },
      { title: 'Panssexual' }
    ],
    identity: [
      { title: 'Não Apurado' },
      { title: 'Mulher Cisgênero' },
      { title: 'Homem Cisgênero' },
      { title: 'Mulher Transgênero' },
      { title: 'Homem Transgênero' },
      { title: 'Gênero não Binário' },
      { title: 'Agênero' },
    ]

  }

  phone = null;
  email = null;
  token = null;
  validation_code;
  loading_login: boolean = false;
  loading_code: boolean = false;
  time: number = 300000; // tempo de reenvio código
  array_user;
  textError: string = '';

  ngOnInit(): void {

    this.header = this.storageService.header;

    this.header.back = this.storageService.header.back != null ? this.storageService.header.back : '#000';
    this.header.color = this.storageService.header.color != null ? this.storageService.header.color : '#fff';

    this.storageService.checkLoginUser()


    if (this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code_obj[0]?.registration_config_obj != null) {
      // console.log(this.list.qr_codes[0].qr_code_obj[0].registration_config_obj)
      this.isList = this.list.qr_codes[0]?.qr_code_obj[0]?.registration_config_obj;
    }
    else if (this.list?.registration_config_obj != null) {
      this.isList = this.list.registration_config_obj;
    }
    else {
      this.isList = {
        birthdate: false,
        birthdate_mandatory: false,
        cep: false,
        cep_mandatory: false,
        check_sms: false,
        cpf: false,
        cpf_mandatory: false,
        description: "teste g",
        email: true,
        email_mandatory: false,
        establishment: 9,
        gender: false,
        gender_identity: false,
        gender_identity_mandatory: false,
        gender_mandatory: false,
        id: 7,
        name: true,
        name_mandatory: false,
        order_fields: "Nome, E-mail, Orientação Sexual, Data de nascimento, CPF, Telefone, Identidade de Gênero, CEP",
        phone: false,
        phone_mandatory: false,
        sexual_orientation: false,
        sexual_orientation_mandatory: false,
        terms: "https://sbs-api.s3-sa-east-1.amazonaws.com/media/images/64cecb7c-3529-449f-bd2f-8f9f87738d04.pdf",
        title: "Cadastro"
      }
    }

    // console.log(this.isList)

    this.orderList(this.isList)
  }

  orderList(data) {

    var list_aux = []
    list_aux = data.order_fields.split(', ')

    list_aux.forEach(item => {
      switch (item) {
        case "Telefone":
          this.list_order.push({ title: 'Telefone', name_api: 'phone', placeholder: 'Digite o seu telefone', mask: '(00) 0 0000-0000', input: null, active: data.phone, active_mandatory: data.phone_mandatory, options: true, check_sms: data.check_sms, checkbox_sms: false })
          break;
        case "E-mail":
          this.list_order.push({ title: 'E-mail', name_api: 'email', placeholder: 'Digite o seu email', mask: null, input: null, active: data.email, active_mandatory: data.email_mandatory, options: false })
          break;
        case "Nome":
          this.list_order.push({ title: 'Nome', name_api: 'name', placeholder: 'Digite o seu nome', mask: null, input: null, active: data.name, active_mandatory: data.name_mandatory, options: false })
          break;
        case "CPF":
          this.list_order.push({ title: 'CPF', name_api: 'cpf', placeholder: 'Digite o seu CPF', mask: '000.000.000-00', input: null, active: data.cpf, active_mandatory: data.cpf_mandatory, options: false })
          break;
        case "Data de nascimento":
          this.list_order.push({ title: 'Data de nascimento', name_api: 'birthdate', placeholder: 'Data de nascimento', mask: null, input: null, active: data.birthdate, active_mandatory: data.birthdate_mandatory, options: false })
          break;
        case "Orientação Sexual":
          this.list_order.push({ title: 'Orientação Sexual', name_api: 'sexual_orientation', placeholder: 'Selecione a Orientação Sexual', mask: null, input: null, active: data.sexual_orientation, active_mandatory: data.sexual_orientation_mandatory, options: false })
          break;
        case "Identidade de Gênero":
          this.list_order.push({ title: 'Identidade de Gênero', name_api: 'gender_identity', placeholder: 'Selecione a Identidade de Gênero', mask: null, input: null, active: data.gender_identity, active_mandatory: data.gender_identity_mandatory, options: false })
          break;
        case "CEP":
          this.list_order.push({ title: 'CEP', name_api: 'cep', placeholder: 'Digite o seu CEP', mask: '00000-000', input: null, active: data.cep, active_mandatory: data.cep_mandatory, options: false })
          break;
      }
    });

  }

  async sendCode() {
    this.warningMap = new Map();

    if (this.email == null || this.email == "") {
      this.warningMap.set('email', true);
    }

    if (this.warningMap.size == 0) {

      this.loading_login = true;
      let data = await this.checkLogin(this.email) 

      if (data != null && data.length > 0) {
        this.array_user = data[0];
      }
      else {
        this.array_user = null
      }

      setTimeout(() => {

        if (this.array_user != null) {
          if (this.loading_send) {
            this.loading_send = false
            this.userCampaignService.sendEmailUserCampaign(this.email, this.phone, this.token).subscribe(data => {
              this.loading_login = false;
              this.page = 2;
            })
          }

          setTimeout(() => {
            this.loading_send = true;
          }, this.time);
        }
        else {
          this.page = 0;
          this.loading_login = false;

          let email_obj = this.list_order.filter(el => el.title == 'E-mail')
          email_obj[0].input = this.email;

          this.openSnackBar("Não existe um usuário com esse e-mail!", "Fechar")
        }
      }, 1000);
    }
  }

  checkRegister() {
    this.warningMap = new Map();

    this.list_order.forEach(item => {
      if (item.active_mandatory && (item.input == null || item.input == "")) {
        this.warningMap.set(item.name_api, true);
      }
    });

    if (!this.input.check) {
      this.warningMap.set('check', true);
    }
    if (!this.input.checkInfo) {
      this.warningMap.set('checkInfo', true);
    }
    if (this.warningMap.size == 0) {
      this.postUserCampaign()
    }
    // this.next()
  }

  createToken() {
    var create_token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
    return create_token;
  };

  //  USER OBRIGATORIO 
  // createUser() {
  //   this.token = this.createToken()

  //   this.storageService.token_user = this.token
  // }


  //  ANONIMO
  createUser() {
    if (localStorage.getItem('tokenAnonimo') == null || localStorage.getItem('tokenAnonimo') == '') {
      this.token = this.createToken()
    }
    else {
      this.token = localStorage.getItem('tokenAnonimo');
    }

    this.storageService.token_user = this.token
  }

  convertDate(data) {
    if (data != null) {
      var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      var aux_data = [year, month, day].join('-')

      return aux_data
    }
    else {
      return null
    }

  }

  async postUserCampaign() {
    // console.log('-', this.urlPage)
    if (this.urlPage == 'redirect' || this.urlPage == 'redirect-sbs') {
      this.createUser()

      var establishment = this.storageService.id_establishment != null 
        ? this.storageService.id_establishment 
        : this.storageService.getEstablishment();

      let email_obj = this.list_order.filter(el => el.title == 'E-mail')

      let data = await this.checkLogin(email_obj[0].input)

      if (data != null && data.length > 0) {
        this.array_user = data[0];
      }
      else {
        this.array_user = null
      }

      await setTimeout(() => {
        if (this.array_user == null) {

          this.userCampaignService.postUserCampaign(establishment, this.token, this.list_order).subscribe(data => {
            this.saveUSerLocalStore(data)

            this.list_order.forEach(item => {
              if (item.title == 'Data de nascimento') {
                item.input = this.convertDate(item.input)
              }
              if ((this.phone == null && item.title == 'E-mail') && (item.input != null || item.input != "")) this.email = item.input
              else if ((this.email == null && item.title == 'Telefone') && (item.input != null || item.input != "")) this.phone = item.input
            });

            setTimeout(() => {
              this.userCampaignService.sendEmailUserCampaign(this.email, this.phone, this.token).subscribe(data => {
              })
            }, 1000);

            // if(this.list.page_images != null && this.storageService.token_user != null){
            //     // this.addNewItem(this.list.page_images[0]);
            // }
            // else{
            this.page = 2;
            // }

          })
        }
        else {
          this.page = 3;
          this.email = this.array_user.email;
          this.openSnackBar("Já existe um usuário com esse e-mail!", "Fechar")
        }

      }, 500);

    }

  }

  checkLogin(email) {
    //alert(email)
    // this.userCampaignService.getLoginUser(email).subscribe(data => {

    //   if (data != null && data.length > 0) {
    //     this.array_user = data[0];
    //   }
    //   else { this.array_user = null }
    // },
    //   error => {
    //     alert(error)
    //     console.log(error)
    //   })


    return this.userCampaignService.getLoginUser(email).toPromise()
  }

  saveUSerLocalStore(data) {

    this.list_order.forEach(item => {
      switch (item.title) {
        case "Telefone":
          this.user.phone = item.input
          break;
        case "E-mail":
          this.user.email = item.input
          break;
        case "Nome":
          this.user.name = item.input
          break;
        case "CPF":
          this.user.cpf = item.input
          break;
        case "Data de nascimento":
          this.user.birthdate = item.input
          break;
        case "Orientação Sexual":
          this.user.sexual_orientation = item.input
          break;
        case "Identidade de Gênero":
          this.user.gender_identity = item.input
          break;
        case "CEP":
          this.user.cep = item.input
          break;
      }
    });

    this.user.token = this.token;
    this.user.establishment = localStorage.getItem("id_establishment");
    this.user.id = data.id;
    this.storageService.token_user = this.token;
    // localStorage.setItem('tokenUser', this.token);
    // localStorage.setItem('_user', JSON.stringify(this.user));
    this.storageService.info_user = this.user;
    this.array_user = this.user;
    this.storageService.checkLoginUser()

  }

  checkCode() {
    this.textError = '';

    this.warningMap = new Map();
    this.loading_code = true;
    this.user = JSON.parse(localStorage.getItem("_user"));

    this.email = (this.email == null) ? (this.user?.email != null ? this.user.email : null) : this.email;
    this.phone = (this.phone == null) ? (this.user?.phone != null ? this.user.phone : null) : this.phone;

    if (this.validation_code == null || this.validation_code == "") {
      this.warningMap.set('validation_code', true);
      this.loading_code = false;
    }

    if (this.warningMap.size == 0) {
      this.userCampaignService.validedHash(this.token, this.email, null, this.validation_code).subscribe(data => {
        this.loading_code = false;
        if (this.array_user != null) {

          var list_login_user = JSON.parse(localStorage.getItem("_list_accounts"));
          // console.log('3-', list_login_user)

          if (list_login_user == null) list_login_user = []
          list_login_user.push(this.array_user)

          localStorage.removeItem('_user')
          localStorage.removeItem('tokenUser')

          localStorage.setItem('_user', JSON.stringify(this.array_user))
          localStorage.setItem('tokenUser', this.array_user.token)
          localStorage.setItem('_list_accounts', JSON.stringify(list_login_user))

          var token_anonimo = localStorage.getItem('tokenAnonimo') 

          this.userCampaignService.exchangeTokenUserCampaign(token_anonimo, this.array_user.token).subscribe(data => {
            // console.log(data)
            localStorage.removeItem('tokenAnonimo')
          })

          this.storageService.checkLoginUser()
          this.next();
        }
      }, error => {
        this.textError = 'Código invalido';
        this.loading_code = false;
      })
    }
  }


  openTerms() {
    var link = this.isList?.terms.split('//')

    if (link[1].substring(0, 7) == 'sbs-api') {
      return this.isList?.terms
    }
    else {
      return this.url + this.isList?.terms
    }
  }

  next() {
    // console.log('NEXT')
    this.newItemEvent.emit('FINISH');
  }

  getUser(token) {
    this.userCampaignService.getUserCampaign(token).subscribe(data => {

      if (data.establishment != null && data.verified_email) {
        // this.next()

        setTimeout(() => {
          this.loading_message = true;
          this.loading_send = true;
        }, this.time);

        return
      }
      else if (data.establishment != null && !data.verified_email) {
        this.page = 2;

        // this.email = data.email; 
        // this.phone = data.phone
        // this.token = token;
        // this.sendCode()
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
