import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/service/storage.service';

@Component({
    selector: 'app-preview-campaign',
    templateUrl: './preview-campaign.component.html',
    styleUrls: ['./preview-campaign.component.css']
})
export class PreviewCampaignComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private storageService: StorageService
    ) { }

    campaign;
    color_primary
    type_preview;

    ngOnInit(): void {
        console.log(this.campaign);
        
        this.color_primary = this.storageService.establishment.color;
    }

}