<div class="screen">

    <div class="div-imagens" *ngIf="loading">

        <div *ngFor="let item of listImage" style="width: 100%;">

            <div class="card_title line_break line_2"> {{ item.title }} </div>

            <img *ngIf="item.image!=null" loop=infinite [src]="item.image" class="photo" (click)="addNewItem(item)">

            <img *ngIf="item.url!=null" loop=infinite [src]="item.url" class="photo" (click)="addNewItem(item)">

            <div class="card_description">
                <div class="line_break line_3"> {{ item.description }} </div>
                <button mat-button class="btn_view_more" (click)="openLink2(item.link)"
                    [style.borderColor]="header.back" [style.color]="header.back">
                    Saiba Mais
                </button>
            </div>
            <div class="card_buttons">
                <div class="interation" [style.fontSize]="preview_campaign ? '9px' : ''" (click)="likePost(item)" 
                [ngStyle]="{'color': is_liked ? header.back : '#bfbfbf', 
                            'font-size': preview_campaign ? '9px' : 'auto'}">
                    <svg class="icons_social" [ngStyle]="{'width': preview_campaign ? '14px' : '20px', 
                                                          'height': preview_campaign ? '14px' : '20px'}"
                        viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none" class="icn"
                            [style.fill]="is_liked ? header.back : '#bfbfbf'">
                            <path
                                d="M2701 5109 c-86 -26 -153 -94 -190 -194 -29 -77 -44 -206 -36 -311 l6 -92 -59 -148 c-197 -492 -440 -963 -625 -1210 -41 -55 -113 -136 -160 -181 -97 -93 -143 -151 -211 -269 l-47 -80 -67 23 c-65 22 -77 22 -463 23 -421 0 -445 -2 -542 -52 -86 -43 -173 -147 -213 -253 -18 -48 -19 -95 -22 -965 -2 -567 1 -939 7 -978 30 -190 185 -345 373 -372 35 -5 216 -10 403 -10 372 0 435 7 525 56 l50 27 182 -22 c468 -55 796 -76 1459 -92 366 -8 521 -8 625 0 413 34 722 166 879 373 89 118 125 226 127 383 1 98 1 101 33 135 92 99 161 220 189 331 22 91 21 237 -4 333 l-20 78 31 44 c81 118 125 267 115 390 -8 97 -47 217 -96 293 l-41 63 15 44 c42 123 41 278 -1 394 -27 74 -101 178 -167 232 -247 206 -730 252 -1358 128 -84 -16 -159 -30 -167 -30 -11 0 -13 16 -7 85 8 107 37 232 114 490 75 251 99 359 111 512 11 132 3 248 -24 348 -60 224 -239 403 -464 460 -80 20 -215 28 -260 14z m212 -300 c149 -50 225 -156 246 -341 17 -153 -12 -326 -103 -619 -96 -308 -132 -498 -124 -659 10 -187 94 -290 239 -290 20 0 114 16 210 35 295 60 372 69 624 69 185 1 248 -3 320 -17 301 -61 410 -234 300 -478 -50 -109 -47 -130 31 -226 140 -171 134 -323 -19 -505 -55 -65 -60 -119 -21 -208 26 -58 29 -76 29 -175 0 -102 -2 -115 -29 -170 -20 -41 -57 -86 -117 -146 -52 -50 -91 -97 -95 -114 -4 -16 -1 -70 6 -121 14 -107 5 -168 -37 -247 -73 -133 -266 -233 -551 -284 -84 -15 -154 -17 -512 -15 -684 3 -1210 31 -1650 88 l-45 6 3 886 c3 620 0 919 -8 993 -11 106 -11 107 17 180 51 130 106 211 228 334 189 191 340 417 529 794 119 236 225 473 320 713 l66 168 -3 147 c-1 82 2 165 6 186 7 34 11 37 43 37 19 0 63 -9 97 -21z m-1663 -2451 c18 -13 43 -36 54 -51 21 -28 21 -31 21 -946 l0 -917 -27 -41 c-48 -72 -60 -74 -475 -71 -347 3 -363 4 -389 24 -15 11 -37 33 -48 48 -21 27 -21 36 -24 940 l-2 912 23 44 c42 80 40 80 463 80 368 0 371 0 404 -22z" />
                        </g>
                    </svg>
                    curtir
                </div>

                <div class="interation" [style.fontSize]="preview_campaign ? '9px' : ''" [matMenuTriggerFor]="menu" style="color: #bfbfbf;">
                    <svg class="icons_social" [ngStyle]="{'width': preview_campaign ? '14px' : '20px', 
                                                          'height': preview_campaign ? '14px' : '20px'}" fill="#bfbfbf"
                        viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none" class="icn">
                            <path
                                d="M3878 5101 c-47 -10 -113 -29 -145 -41 -192 -71 -374 -229 -478 -414 -109 -195 -142 -458 -85 -665 12 -41 17 -78 13 -82 -4 -4 -320 -182 -701 -394 l-693 -387 -55 61 c-225 248 -572 350 -909 265 -33 -8 -109 -39 -170 -69 -230 -114 -394 -309 -471 -560 -25 -82 -28 -105 -28 -250 -1 -138 2 -171 22 -238 91 -316 319 -549 627 -643 81 -25 105 -28 250 -28 137 -1 172 2 244 22 161 44 338 152 437 266 26 31 52 56 57 56 7 0 1381 -764 1395 -776 2 -1 -6 -45 -19 -96 -30 -127 -31 -297 0 -422 103 -425 497 -724 926 -703 233 12 427 95 598 256 115 109 217 287 258 451 21 81 18 347 -4 426 -110 396 -439 667 -839 691 -263 16 -511 -81 -703 -274 -62 -63 -79 -74 -94 -67 -10 5 -326 180 -700 389 -587 327 -681 383 -677 400 2 11 11 54 21 95 22 100 22 281 -1 380 -9 41 -17 85 -18 97 -1 20 87 72 694 411 l695 389 75 -76 c194 -196 443 -294 708 -278 257 15 502 142 662 343 71 89 142 229 172 339 31 116 31 345 0 460 -74 266 -259 486 -505 600 -168 77 -387 103 -559 66z m322 -287 c176 -41 342 -174 418 -334 49 -103 63 -167 63 -282 0 -245 -139 -460 -364 -563 -39 -18 -96 -38 -125 -44 -75 -16 -217 -14 -287 4 -225 57 -413 250 -460 471 -68 322 117 639 430 737 28 9 61 18 75 20 59 10 190 6 250 -9z m-2942 -1657 c99 -34 173 -80 246 -153 192 -191 239 -474 120 -722 -71 -148 -228 -277 -400 -328 -93 -28 -247 -23 -350 10 -211 68 -363 230 -419 447 -20 77 -20 226 0 304 57 223 249 409 475 460 94 21 235 13 328 -18z m2934 -1628 c222 -47 415 -234 473 -458 19 -72 21 -212 5 -291 -47 -223 -245 -421 -475 -476 -91 -21 -245 -14 -328 15 -103 36 -180 84 -252 156 -152 151 -215 367 -170 579 46 219 233 412 455 470 67 18 219 20 292 5z" />
                        </g>
                    </svg>
                    compartilhar
                </div>
                <mat-menu #menu="matMenu" xPosition="before">
                    <input id="{{'myLink'}}" style="width: 10px; position: absolute; opacity: 0; z-index: -5;"
                        [value]="createLink()" />

                    <button mat-menu-item (click)="copyLink()" class="btn_link">
                        <svg width="25px" height="25px" viewBox="0 0 256.000000 256.000000">
                            <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#000000"
                                stroke="none">
                                <path
                                    d="M885 2327 c-54 -31 -85 -79 -85 -133 0 -36 5 -47 26 -61 23 -15 29 -15 52 -2 14 8 29 27 33 42 16 60 -15 57 554 57 428 0 525 -2 534 -14 8 -9 10 -214 9 -752 l-3 -738 -44 -16 c-50 -17 -66 -44 -47 -81 9 -19 21 -25 57 -27 75 -5 143 55 160 139 11 57 11 1411 0 1468 -11 54 -37 90 -87 118 l-39 23 -540 0 -540 0 -40 -23z" />
                                <path
                                    d="M515 1961 c-49 -22 -73 -48 -91 -98 -20 -56 -20 -1470 0 -1526 18 -50 42 -76 91 -98 37 -17 77 -19 567 -19 348 0 536 4 555 11 34 13 80 55 99 91 12 23 14 151 14 779 0 739 0 752 -21 788 -11 20 -36 48 -56 61 l-37 25 -540 2 c-509 3 -543 2 -581 -16z m1096 -111 c19 -10 19 -29 19 -745 0 -654 -2 -735 -16 -749 -14 -14 -76 -16 -534 -16 l-519 0 -15 22 c-14 20 -16 107 -16 738 0 631 2 718 16 738 l15 22 515 0 c336 0 522 -4 535 -10z" />
                            </g>
                        </svg>
                        <span> Copiar Link </span>
                    </button>
                    <button mat-menu-item class="btn_link">
                        <a target="_blank"
                            href="https://www.facebook.com/sharer/sharer.php?u=https://sbs.tools/{{url_screen}}">
                            <svg width="25px" height="25px" viewBox="0 0 628.000000 628.000000">
                                <g transform="translate(0.000000,628.000000) scale(0.100000,-0.100000)" fill="#000000"
                                    stroke="none">
                                    <path
                                        d="M315 6260 c-123 -23 -225 -102 -266 -207 l-23 -58 -7 -745 c-3 -409 -8 -1687 -10 -2840 l-4 -2095 25 -69 c32 -88 105 -165 188 -197 54 -22 76 -23 452 -30 217 -4 904 -7 1526 -8 l1131 -1 6 653 c4 358 7 901 7 1205 l0 552 -405 0 -405 0 -6 27 c-9 35 3 919 12 935 5 8 107 9 406 4 l398 -6 0 332 c0 325 15 575 41 708 30 151 118 348 210 469 157 210 366 336 670 408 128 30 889 20 1027 -14 l22 -5 -2 -427 -3 -426 -335 -6 c-184 -3 -353 -11 -375 -17 -100 -25 -173 -86 -219 -182 l-31 -65 -3 -387 -3 -388 460 0 c302 0 462 -4 466 -10 4 -6 -3 -77 -14 -158 -24 -168 -65 -480 -87 -656 -8 -65 -17 -123 -20 -127 -3 -5 -187 -9 -410 -9 l-404 0 0 -1204 0 -1204 143 -7 c78 -3 448 -5 822 -3 l680 3 57 23 c39 16 77 42 116 81 59 59 75 93 101 211 6 29 11 1044 13 2850 l3 2805 -23 58 c-30 74 -104 150 -179 184 l-58 27 -355 7 c-497 10 -5286 18 -5335 9z" />
                                </g>
                            </svg>
                            Facebook
                        </a>
                    </button>
                    <button mat-menu-item class="btn_link">
                        <a target="_blank" href="https://twitter.com/intent/tweet?url=https://sbs.tools/{{url_screen}}">
                            <svg width="25px" height="25px" viewBox="0 0 461.000000 460.000000">
                                <g transform="translate(0.000000,460.000000) scale(0.100000,-0.100000)" fill="#000000"
                                    stroke="none">
                                    <path
                                        d="M3040 3883 c-88 -14 -225 -69 -311 -125 -304 -196 -445 -500 -404 -872 l8 -69 -84 7 c-348 29 -684 132 -994 303 -116 65 -248 148 -276 175 -8 8 -43 36 -79 63 -36 28 -134 119 -219 203 -84 84 -155 152 -156 150 -13 -15 -57 -116 -73 -168 -102 -332 9 -699 278 -921 l64 -54 -42 -3 c-50 -4 -195 31 -271 64 -29 13 -57 24 -62 24 -25 0 6 -199 47 -309 42 -112 101 -200 203 -301 110 -109 211 -171 349 -216 l87 -28 -55 -13 c-31 -8 -114 -14 -187 -15 -101 0 -133 -4 -133 -13 0 -22 58 -143 92 -193 139 -203 391 -355 626 -378 39 -3 72 -10 72 -15 0 -14 -199 -140 -299 -189 -247 -121 -442 -168 -734 -177 -197 -6 -215 -11 -154 -47 152 -90 423 -202 607 -252 220 -59 311 -69 650 -69 403 0 485 13 830 127 590 195 1103 660 1388 1258 95 199 175 435 206 610 9 47 18 99 21 115 18 97 24 188 25 327 l0 156 29 19 c105 66 371 345 371 389 0 18 8 19 -112 -20 -98 -33 -285 -76 -325 -76 -17 1 -17 2 2 16 135 101 244 224 306 345 47 94 34 112 -47 64 -88 -52 -386 -156 -443 -155 -9 0 -38 21 -65 45 -108 99 -268 181 -408 209 -71 15 -262 20 -328 9z" />
                                </g>
                            </svg>
                            Twitter
                        </a>
                    </button>
                </mat-menu>

                <!-- <div class="interation" [style.fontSize]="preview_campaign ? '9px' : ''" style="color: #bfbfbf;">
                    <svg [ngStyle]="{'width': preview_campaign ? '14px' : '20px', 
                                     'height': preview_campaign ? '14px' : '20px'}" style="margin-right: 10px;"
                        viewBox="0 0 24 24" fill="#bfbfbf" class="icn" ><path d="M0 0h24v24H0V0z" fill="none"/><path class="icn" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/></svg>
                    comentar
                </div> -->
            </div>
        </div>

        <!-- <div *ngIf="listImage==null" style="width: 100%; text-align: center; padding: 25px 0;"> 
            Banner sem imagem salva 
        </div> -->
    </div>

</div>