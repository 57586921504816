import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MDBModalRef } from 'angular-bootstrap-md';
import { QrCodeService } from 'src/app/service/qrcode.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-popup-report',
  templateUrl: './popup-report.component.html',
  styleUrls: ['./popup-report.component.css']
})
export class PopupReportComponent implements OnInit {

  constructor(
		public modalRef: MDBModalRef,
		private storageService: StorageService,
    private qrCodeService: QrCodeService,
    private sanitizer: DomSanitizer,
    private qrcodeService: QrCodeService,
  ) { }

  loading: boolean = false;
  loading_download: boolean = false;

  list_qrcode = [];
  qrcode_select;

  date ={start: '', end: ''}
  warningMap = new Map();
  page: number = 1;

  color_primary: string = '';
  file_url;

  // ia anterior, ultimos 7 dias, ultimos 15 e ultimo mes

  list_date = [
    {id: 0, name: 'Últimas 24 horas', day: 1 },
    {id: 1, name: 'Ultimos 7 dias', day: 7 },
    {id: 2, name: 'Ultimos 15 dias', day: 15 },
    {id: 3, name: 'Ultimo mês', day: 30 },
  ]
  date_select

  ngOnInit(): void { 
    this.color_primary = this.storageService.establishment.color;
    
    this.getQrCodes(this.page)


    var day = new Date().getDate()
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear()
    
    this.date.end = `${year}/${month}/${day}`;
  }

  setDateSelect(value_day){
    var aux_date = new Date();

    if(value_day == 30){
      var date = aux_date.setDate(aux_date.getDate());      
    
      var day = new Date(date).getDate()
      var month = new Date(date).getMonth() + 1;
      var year = new Date(date).getFullYear()
      
      var month_aux = month -1;
      if(month_aux < 1){
        month = 12;
        year -= 1;
      }
      
      this.date.start = `${year}/${month}/${day}`;
    }
    else{
      var date = aux_date.setDate(aux_date.getDate() - value_day);      
    
      var day = new Date(date).getDate()
      var month = new Date(date).getMonth() + 1;
      var year = new Date(date).getFullYear()
      
      this.date.start = `${year}/${month}/${day}`;
    }

  }

  getQrCodes(page){
    this.loading = true;

    this.qrcodeService.getQrCodes(page).subscribe( data => {
      this.list_qrcode = this.list_qrcode.concat(data.results)

      if(data.next != null){
        this.page += 1; 
        this.getQrCodes(this.page)
      }
      else{
        this.loading = false;
      }
    })
  }

  select(obj){
    this.qrcode_select = obj.id;
  }

  close(){
		this.modalRef.hide();
  }

  gerar(){
    this.warningMap = new Map();

    if(this.date.start == "" || this.date.start == null){
      this.warningMap.set('start', true);
    }
    if(this.date.end == "" || this.date.end == null){
      this.warningMap.set('end', true);
    }
    
    if(this.warningMap.size == 0){
      if(this.loading_download) return;
  
      this.loading_download = true;
  
      var date_start = new Date(this.date.start).toISOString().split('T')
      var date_end = new Date(this.date.end).toISOString().split('T')
  
      //qr_code_name
      this.qrCodeService.getRelatorio(date_start[0], date_end[0], this.qrcode_select).subscribe(data => {
        let blob = data.body.slice(0, data.body.size, "text/xlsx")
        this.file_url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        setTimeout(() => {
          this.loading_download = false;
          document.getElementById('downloadLink').click()
        }, 500);

        this.loading_download = false;
      })
    }
  }
}
