import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { CatalogService } from '../service/catalog.service';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';
import { ModalCatalogPreviewComponent } from './modal-catalog-preview/modal-catalog-preview.component';
import { ImportCsv } from '../modals/import-csv/import-csv.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'app-catalogs',
    templateUrl: './catalogs.component.html',
    styleUrls: ['./catalogs.component.css']
})

export class CatalogsComponent implements OnInit {

    constructor(
        private catalogService: CatalogService,
        private _snackBar: MatSnackBar,
        private modalService: MDBModalService,
        private storageService: StorageService,
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalRef: MDBModalRef;
    modalDelet: MDBModalRef;

    displayedColumns: string[] = ['name', 'description', 'status', 'view'];
    dataSource = new MatTableDataSource<any>();

    loading: boolean = false;
    catalogs_response; // var to save last get catalogs response

    /* pagination related variables */
    page: number = 1
    count_total: number = 0;
    page_count1: number = 0;
    page_count2: number = 0;
    page_size = 10;

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
        this.getCatalogs()
    }

    getCatalogs() {
        this.loading = true;
        this.dataSource.data = []

        this.catalogService.getCatalogs(this.page).subscribe(data => {
            this.catalogs_response = data;
            this.dataSource.data = data.results
            this.loading = false

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page - 1) * this.page_size)
            if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
                this.page_count2 = this.count_total;
            }
            else {
                this.page_count2 = this.page_count1 + (this.page_size - 1);
            }
        },
            error => {
                this.loading = false
            })
    }

    nextPage() {
        if (this.catalogs_response.next != null) {
            this.page += 1;
            this.getCatalogs()
        }
    }

    backPage() {
        if (this.catalogs_response.previous != null) {
            this.page -= 1;
            this.getCatalogs()
        }
    }

    deleteCatalog(id) {
        this.loading = true;
        this.catalogService.deleteCatalog(id).subscribe(data => {
            this.loading = false;
            this.getCatalogs()
        },
            error => {
                this.loading = false;
            })
    }

    openPreview(obj) {
        this.modalRef = this.modalService.show(ModalCatalogPreviewComponent, {
            // backdrop: 'static',
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                isList: obj,
            }
        })

    }

    openDelete(obj) {
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                title: 'Excluir catálogo',
                text: 'Deseja excluir o catálogo ' + obj.name + '?',
            }
        })
        this.modalDelet.content.action.subscribe((result: any) => {
            if (result == 'yes') {
                this.deleteCatalog(obj.id)
            }
        });

    }

    importCSV() {
        const testeModal = this.modalService.show(ImportCsv, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered ',
            containerClass: '',
            animated: false,
        })
        testeModal.content.action.subscribe((result: any) => {
            if (result == 'yes') {
                this.getCatalogs()
            }
        });
    }

    createLink(item) {
        var localhost = window.location.href.indexOf('localhost')

        if (item?.qr_codes != null && item?.qr_codes[0]?.qr_code != null) {

            if (localhost != -1) return 'localhost:4200/catalog-view/' + item?.qr_codes[0]?.qr_code_obj[0]?.slug
            else return 'https://sbs.tools/catalog-view/' + item?.qr_codes[0]?.qr_code_obj[0]?.slug
        }
        else{
            if (localhost != -1) return 'localhost:4200/catalog-view/' + item.id;
            else return 'https://sbs.tools/catalog-view/'+item.id;  
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 6000,
        });
    }

    copyLink(i) {
        var copyText = document.getElementById("myLink" + i) as HTMLInputElement
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        this.openSnackBar("Link copiado!", "Fechar")
    }

}