<div class="modal-content">

    <svg (click)="closePopup()" class="icons_close"
        width="25px" height="25px" viewBox="0 0 24 24" [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path [style.fill]="color_primary" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
    </svg>

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>

    </div>

    <div class="modal-body" [style.color]="color_primary" *ngIf="text != null && text != ''">
        {{text}}
    </div>

    <div *ngIf="has_button" style="width: 100%; display: flex; justify-content: space-evenly; 
        align-items: center; text-align: center; margin-bottom: 15px;">
        
        <button *ngIf="button_cancel != null && button_cancel != ''" mat-button class="main-button button" [style.background]="color_primary" (click)="close()"> 
            {{button_cancel}}
        </button> 

        <button *ngIf="button_text != null && button_text != ''" mat-button class="main-button button" [style.background]="color_primary" (click)="next()"> 
            {{button_text}}
        </button> 
    </div>

</div>