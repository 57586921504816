<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> Informações do Usuário </b>
        </h1>

        <div class="info-user">
            <div class="text" *ngIf="user.name != null"> 
                <h4> {{ user.name }} </h4> 
            </div>
            <div class="text" *ngIf="user.email != null"> 
                <h4> {{ user.email }} </h4> 
            </div>
            <div class="text" *ngIf="user.birthdate != null"> 
                <h4> {{ user.birthdate | date: 'dd/MM/yyyy' }} </h4> 
            </div>
            <div class="text" *ngIf="user.cpf != null"> 
                <h4> {{ user.cpf }} </h4> 
            </div>
            <div class="text" *ngIf="user.cep != null"> 
                <h4> {{ user.cep }} </h4> 
            </div>
            <div class="text" *ngIf="user.phone != null"> 
                <h4> {{ user.phone }} </h4> 
            </div>
            <div class="text" *ngIf="user.gender != null"> 
                <h4> {{ user.gender }} </h4> 
            </div>
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: space-evenly; 
        align-items: center; text-align: center; margin-bottom: 15px;">
        
        <button mat-button class="main-button button" (click)="close()"> 
            Fechar
        </button> 
    </div>

</div>