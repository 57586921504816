import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/model/product';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { StorageService } from 'src/app/service/storage.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ProductService } from 'src/app/service/product.service';
import { CategoryService } from 'src/app/service/category.service';

@Component({
    selector: 'app-detail-product',
    templateUrl: './detail-product.component.html',
    styleUrls: ['./detail-product.component.css']
})
export class DetailProductComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private compressor: CompressorService,
        private storageService: StorageService,
        private productService: ProductService,
        private categoryService: CategoryService
    ) { 
        this.product_id = this.route.snapshot.paramMap.get('id');
    }

    // @ViewChild(MatSort) sort: MatSort;


    displayedColumns: string[] = ['name', 'view'];
    dataSource: MatTableDataSource<any>;

    displayedColumns2: string[] = ['name', 'porcent', 'valor', 'medida', 'view'];
    dataSource2: MatTableDataSource<any>;
    
    displayedColumns3: string[] = ['name', 'qtd', 'valor', 'view'];
    dataSource3: MatTableDataSource<any>;

    product_id;
    id_establishment;
    loading: boolean = false;
    warningMap = new Map();

    product = {} as Product;
    list_product_category = []
    list_product_cross = []
    list_remove: []

    compressed_image = []; // as the name says...
    color_primary: string = '';
    panelOpenState = false;

    ingredient: string = '';
    nutritional={
        information: { name: '', porcent: null, valor: null, medida: ''},
        table: { name: '', valor: null, qtd: null}
    }
    
    list_products = []
    list_products_filter = []
    select_cross_sell;
    crossSell;
    
    list_categories = []
    list_categories_filter = []
    select_category;
    category;
    
    list_measurement_unit = []
    list_measurement_unit_filter = []
    measurement;

    list_delet={
        categories: [],
        crossSell: [],
        image: []
    }

    /* pagination related variables */
    campaign_response;
    num: number;
    page: number = 1
    count_total: number = 0;
    page_count1: number = 0;
    page_count2: number = 0;
    page_size = 10;
    
    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
        this.id_establishment = localStorage.getItem('id_establishment')
        this.product.initial_inventory = false;

        this.loadingRequest()
    }

    async loadingRequest(){
        this.loading = true;
        await this.getAllProducts(1)
        await this.getAllCategories()
        await this.getMeasurementUnit()
        await setTimeout(() => {
            this.getProductId()
        }, 500);
    }

    getProductId(){
        if(this.product_id != null) {

            this.productService.getProductId(this.product_id).subscribe(data=>{
                // console.log(data)
    
                this.product.name = data.name;
                this.product.price = data.price;
                this.product.initial_price = data.initial_price;
                this.product.initial_quantity = data.initial_quantity;
                this.product.description = data.description;
                this.product.ingredients = data.ingredients;
                this.list_product_category = data.product_category;
                this.list_product_cross = data.product_related;
                this.product.categories = []
                this.product.crossSell = []
                this.product.midia = []
    
                for (let index = 0; index < 5; index++) {
                    if(data[`image_${index+1}`] != null){
                        this.product.midia.push({id: index+1, image: data[`image_${index+1}`], video: null})
                    }                    
                }

                data.product_category.forEach(item => {
                    let list = this.list_categories.filter(el => el.id === item.category);
                    if(list != null && list.length > 0){
                        this.product.categories.push({id: list[0].id, name: list[0].name})
                    }
                });
    
                // console.log(this.list_products)
                // console.log(data.product_related)
    
                data.product_related.forEach(item => {
                    let list = this.list_products.filter(el => el.id === item.relatedproduct);
                    if(list != null && list.length > 0){
                        this.product.crossSell.push(list[0])
                    }
                });
       
                this.loading = false;
            },
            error=>{
                console.log(error)
                this.loading = false;
            })
        }
        else{
            this.loading = false;
        }

    }

    getAllProducts(page){
        this.productService.getAllProducts(page).subscribe(data=>{
            this.list_products = data.results
            this.list_products_filter = data.results;
        },
        error=>{
            console.log(error)
            this.loading = false;
        })
    }
    

    getMeasurementUnit(){
        this.productService.getMeasurementUnit().subscribe(data=>{
            this.list_measurement_unit = data.results;
            this.list_measurement_unit_filter = data.results;
        },
        error=>{
            console.log(error)
            this.loading = false;
        })
    }

    getAllCategories(){
        this.categoryService.getAllCategories(this.page).subscribe(data=>{
            this.list_categories = data.results;
            this.list_categories_filter = data.results;

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            console.log(error)
            this.loading = false;
        })
    }
    
    drop(event: CdkDragDrop<any>, array) {
        moveItemInArray(array, event.previousIndex, event.currentIndex);
    }

    removeImage(posi, type) {
        if(this.product.midia[posi].image.substring(0,4) == 'http')
            this.list_delet.image.push(this.product.midia[posi].image)

        this.product.midia.splice(posi, 1);
        // console.log(this.list_delet)
        // console.log(this.product.midia)
    }


    // -- CROSS SELL --
    selectCrossSell(item){
        this.select_cross_sell = item;
    }
    addCrossSell(item){
        if(this.select_cross_sell != null && this.select_cross_sell != ""){
            if(this.product.crossSell == null) this.product.crossSell = [];
        
            let list = this.product.crossSell.filter(el => el.name.toLowerCase().indexOf(this.select_cross_sell.name.toLowerCase()) > -1);
            if(list.length == 0) this.product.crossSell.push(this.select_cross_sell);

            this.select_cross_sell = null
            this.crossSell = null
            this.list_products_filter = this.list_products;
        }
    }
    filterCrossSell(text){
        this.list_products_filter = this.list_products.filter(el =>
            el.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
    }
    removeCrossSell(posi){
        let list = this.list_product_cross.filter(el => el.relatedproduct == this.product.crossSell[posi].id);
        if(list.length != 0) this.list_delet.crossSell.push(list[0])

        this.product.crossSell.splice(posi, 1);
        // console.log(this.list_delet)
    }


    // -- CATEGORY --
    selectCategory(item){
        this.select_category = item;
    }
    addCategory(){
        if(this.select_category != null && this.select_category != ""){
            if(this.product.categories == null) this.product.categories = []
    
            let list = this.product.categories.filter(el => el.name.toLowerCase().indexOf(this.category.toLowerCase()) > -1);
            if(list.length == 0) this.product.categories.push({id: this.select_category.id, name: this.select_category.name})
        
            this.select_category = null
            this.category = null
            this.list_categories_filter = this.list_categories;
        }
    }
    filterCategory(text){
        this.list_categories_filter = this.list_categories.filter(el =>
            el.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
    }
    removeCategory(posi){
        let list = this.list_product_category.filter(el => el.category == this.product.categories[posi].id);
        if(list.length != 0) this.list_delet.categories.push(list[0])

        this.product.categories.splice(posi, 1);
        // console.log(this.list_delet)
    }


    addNutricional(){
        if(this.product.information == null) this.product.information = [];
        this.product.information.push({description: this.nutritional.information.name, value: this.nutritional.information.valor, porcent: this.nutritional.information.porcent, medida: this.measurement,});
        
        this.dataSource2 = new MatTableDataSource(this.product.information);
        // this.dataSource.sort = this.sort;

        this.nutritional.information.name = null
        this.nutritional.information.porcent = null
        this.nutritional.information.valor = null
        this.nutritional.information.medida = null
    }

    removeNutricional(posi){
        this.product.information.splice(posi, 1);
        this.dataSource2.data = this.product.information;
    }

    addTableNutricional(){
        if(this.product.table == null) this.product.table = [];
        this.product.table.push({description: this.nutritional.table.name, value: this.nutritional.table.valor, qtd: this.nutritional.table.qtd});
        
        this.dataSource3 = new MatTableDataSource(this.product.table);
        // this.dataSource.sort = this.sort;

        this.nutritional.table.name = null
        this.nutritional.table.valor = null
        this.nutritional.table.qtd = null
    }

    removeTableNutricional(posi){
        this.product.table.splice(posi, 1);
        this.dataSource3.data = this.product.table;
    }

    filterMeasurement(text){
        this.list_measurement_unit_filter = this.list_measurement_unit.filter(el =>
            el.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
    }


    saveProduct(){
        this.warningMap = new Map();

        if(this.product.name == null || this.product.name == ""){
            this.navigateTop()
            this.warningMap.set('name', true);
        }
        if(this.product.price == null || this.product.price == 0){
            this.navigateTop()
            this.warningMap.set('price', true);
        }
        // if(this.product.description == null || this.product.description == ""){
        //     this.warningMap.set('description', true);
        // }
        // if(this.product.ingredients == null || this.product.ingredients == ""){
        //     this.warningMap.set('ingredients', true);
        // }
        // if(this.product.crossSell == null || this.product.crossSell.length <= 0){
        //     this.warningMap.set('crossSell', true);
        // }
        if(this.product.initial_inventory && (this.product.initial_price <= 0  || this.product.initial_price == null)){
            this.navigateTop()
            this.warningMap.set('initial_price', true);
        }
        if(this.product.initial_inventory && (this.product.initial_quantity <= 0 || this.product.initial_quantity == null)){
            this.navigateTop()
            this.warningMap.set('initial_quantity', true);
        }
        if(this.product.midia == null || this.product.midia.length <= 0){
            this.warningMap.set('image', true);
        }
        // if(this.product.categories == null || this.product.categories.length <= 0){
        //     this.warningMap.set('category', true);
        // }
        // if(this.product.information == null || this.product.information.length <= 0){
        //     this.warningMap.set('information', true);
        // }
        // if(this.product.table == null || this.product.table.length <= 0){
        //     this.warningMap.set('table', true);
        // }

        if(this.warningMap.size == 0){
            if(this.product_id == null)
                this.postProduct()
            else 
                this.editProduct()

            // console.log(this.product.midia)
        }
    }

    checkImagens(){
        var array_midia = Object.assign({}, this.product.midia)

        for (let index = 0; index < 5; index++) {
            if(array_midia[index] == null){
                array_midia[index] = {id: null, image: "", video: null}
            }
        }
        return array_midia
    }

    postProduct(){
        // console.log(this.product.midia)
        let send_midia = this.checkImagens()
        // console.log(send_midia)

        this.productService.postProduct(this.product.name, this.product.price, this.product.initial_inventory, 
            this.product.initial_price, this.product.initial_quantity, true, this.id_establishment, 
            this.product.description, this.product.ingredients, send_midia).subscribe(data=>{
                // console.log(data)

                if(this.product.crossSell != null && this.product.crossSell.length > 0){
                    this.product.crossSell.forEach(element => {
                        let list = this.list_product_cross.filter(el => el.id == element.id);
                        if(list.length == 0) this.postProductRelated(data.id, element.id)
                    });
                }

                if(this.product.categories != null && this.product.categories.length > 0){
                    this.product.categories.forEach(element => {
                        let list = this.list_product_category.filter(el => el.id == element.id);
                        if(list.length == 0) this.postProductCategory(data.id, element.id)
                    });
                }
               
                this.router.navigate(['/application/products/'])
        },
        error=>{
            console.log(error)
        })
    }

    editProduct(){
        // console.log(this.product.midia)
        let send_midia = this.checkImagens()
        // console.log(send_midia)

        this.productService.editProduct(this.product_id, this.product.name, this.product.price, 
            this.product.initial_inventory, this.product.initial_price, this.product.initial_quantity, true, 
            this.id_establishment, this.product.description, this.product.ingredients, 
            send_midia).subscribe(data=>{
                
                // ADD ITENS AO PRODUCT
                if(this.product.crossSell != null && this.product.crossSell.length > 0){
                    this.product.crossSell.forEach(element => {
                        let list = this.list_product_cross.filter(el => el.relatedproduct == element.id);
                        if(list.length == 0) this.postProductRelated(data.id, element.id)
                    });
                }
                if(this.product.categories != null && this.product.categories.length > 0){
                    this.product.categories.forEach(element => {
                        let list = this.list_product_category.filter(el => el.category == element.id);
                        if(list.length == 0) this.postProductCategory(data.id, element.id)
                    });
                }


                // DELETE
                if(this.list_delet.crossSell != null && this.list_delet.crossSell.length > 0){
                    this.list_delet.crossSell.forEach(item => {
                        this.deleteProductRelated(item.id)
                    });
                }
                if(this.list_delet.categories != null && this.list_delet.categories.length > 0){
                    this.list_delet.categories.forEach(item => {
                        this.deleteProductCategory(item.id)
                    });
                }

                this.router.navigate(['/application/products/'])
        },
        error=>{
            console.log(error)
        })
    }

    postProductCategory(id_product, id_category){
        this.productService.postProductCategory(id_product, id_category).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    postProductRelated(id_product, id_related){
        this.productService.postProductRelated(id_product, id_related).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    patchProductCategory(id_product, id_category){
        this.productService.patchProductCategory(id_product, id_category).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    patchProductRelated(id_product, id_related){
        this.productService.patchProductRelated(id_product, id_related).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    deleteProductCategory(idProductCategory){
        this.productService.deleteProductCategory(idProductCategory).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    deleteProductRelated(idProductRelated){
        this.productService.deleteProductRelated(idProductRelated).subscribe(data=>{
            // console.log(data)
        },
        error=>{
            console.log(error)
        })
    }

    async editImage(event, position, type) {

        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {
                    this.product.midia[position].image = this.compressed_image[0].url
                    
                    this.compressed_image = []
                }
            });
        }
    }

    async onFileChanged(event, type){
		if (event.target.files && event.target.files[0]) {
			var target = event.target.files
			const compress = this.recursiveCompress( target[0], 0, target ).pipe(
				expand(res => {
					return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress( target[res.index], res.index, target );
				}),
			);
			compress.subscribe(res => {
				if (res.index > res.array.length - 1) {
                    if(this.product.midia == null) this.product.midia= []

                    if(this.product.midia.length < 5){
                        this.product.midia.push({image: this.compressed_image[0], id: null, video: null}); 
                    }
                    this.compressed_image = []
				}
			});
		}
	}
    

    recursiveCompress = (image: File, index, array) => {
		return this.compressor.compress(image).pipe (
		  	map(response => {
				this.compressed_image.push(response)
				return {
					data: response,
					index: index + 1,
					array: array,
				};
		  	}),
		);
	}

    navigateTop(){
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

        if(supportsNativeSmoothScroll == true){
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            window.scroll(0,0);
        }
    }

}
