import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { AppConfig } from '../../app.config';
import { CampaignService } from '../../service/campaign.service';
import { CatalogService } from '../../service/catalog.service';
import { ConstantsService } from '../../service/constants.service';
import { StorageService } from '../../service/storage.service';

@Component({
    selector: 'app-modal-preview',
    templateUrl: './modal-preview.component.html',
    styleUrls: ['./modal-preview.component.css']
})
export class ModalPreviewComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private campaignService: CampaignService,
		private catalogService: CatalogService,
		private constantsService: ConstantsService,
		private storageService: StorageService,
        public modalRef: MDBModalRef
	) {}

    isCampaign

	campaign_id;
	active_campaign: any;
	sendList: any;
	sendFormat: any;

	loading: boolean = true;
	image_link: string;

	header = {
        title: null,
        color: null,
        back: null,
		whatsapp: null,
		logo: null,
		show_header: true
    }

	listType={
		banner: null,
		mini_banner: null,
		galeria: null,
		video: null,
		link: null,
		vazio: null,
		catalogo: null,
		pergunta: null
	}

	screenBack = [];

	color_primary
	
    ngOnInit(){
		// console.log(this.isCampaign)

		this.color_primary = this.storageService.establishment.color;
        this.campaign_id = this.route.snapshot.params.id
		
		this.setData(this.isCampaign)
    }

	openWhatsapp(){
		window.open('https://wa.me/'+this.header.whatsapp)
	}

	setData(data){
		this.active_campaign = data;
		// console.log(this.active_campaign.whatsapp)
		
		this.header.title = this.active_campaign.header_text;
		this.header.color = this.active_campaign.text_color;
		this.header.back = this.active_campaign.header_color;
		this.header.whatsapp = (this.active_campaign?.whatsapp  != '' && this.active_campaign?.whatsapp  != null && this.active_campaign.whatsapp  != '55') ? this.active_campaign.whatsapp : null;;
		this.header.logo = this.active_campaign.logo;
		this.header.show_header = this.active_campaign.show_header;
		
		this.sendList = this.active_campaign;
		
		this.sendFormat = this.active_campaign.type;

		this.screenBack.push(this.sendList)
		this.loading = true;
		

		if(this.active_campaign.active_type_campaign == "LINK"){
			if(this.active_campaign.link != null){
				if(this.active_campaign.link.substring(0,4) != 'http'){
					var aux_link = this.active_campaign.link.replace(/\s/g, '');
					window.location.href = `http://${aux_link}`;
				}
				else{
					var aux_link = this.active_campaign.link.replace(/\s/g, '');
					window.location.href = aux_link;
				}
			}
			else{
				window.location.href = "https://www.google.com";
			}
		}
		else if(this.active_campaign.active_type_campaign == "IMAGE"){
			this.image_link = `${AppConfig.path}${this.active_campaign.image}`;
		}

	}

	
	addItem(newItem) {
		
		// this.sendList = newItem;
		if(newItem == 'registerBack'){
			this.back()
		}
		if(newItem?.type != null){
			if(newItem.type.name == 'Link'){
			}
			else{
				this.sendFormat = newItem.type;

				this.sendList = newItem;
		
				var tam = (this.screenBack.length)-1
				if(this.sendList != this.screenBack[tam]){
					this.screenBack.push(this.sendList)
				}

				setTimeout(()=>{                          
					this.campaignService.changeList()
				}, 500);
			}
		}
		else if(newItem?.internal_images != null){
			this.sendFormat = newItem.internal_images[0].catalog_obj;

			if(this.sendFormat != null){
				this.sendList = newItem;

				var tam = (this.screenBack.length)-1
				if(this.sendList != this.screenBack[tam]){
					this.screenBack.push(this.sendList)
				}

				setTimeout(()=>{                          
					this.campaignService.changeList()
				}, 500);
			}
					
		}
		else if(newItem?.type != null){

			this.sendFormat = newItem.type;
			this.sendList = newItem;
	
			var tam = (this.screenBack.length)-1
			if(this.sendList != this.screenBack[tam]){
				this.screenBack.push(this.sendList)
			}

			setTimeout(()=>{                          
				this.campaignService.changeList()
			}, 500);
				
		}

	}

	back(){
		// console.log(this.storageService.getPageShoppingCart())
		
		if(this.storageService.getPageShoppingCart() != 0){
			if(this.storageService.getPageShoppingCart() == 1)
				this.storageService.page_shoppingCart = 0;
			else if(this.storageService.getPageShoppingCart() == 2)
				this.storageService.page_shoppingCart = 1;
		}
		else{
			var tam = (this.screenBack.length)-1;
	
			if(tam > 0){
				this.sendList = this.screenBack[tam-1];
	
				if(this.sendList?.type != null) 
					this.sendFormat = this.sendList.type;
	
				else if(this.sendList?.internal_images != null) 
					this.sendFormat = this.sendList.internal_images[0].catalog_obj;
	
				else if(this.sendList?.type != null) 
					this.sendFormat = this.sendList.type;
	
				this.screenBack.splice(tam, 1)
			}
	
			setTimeout(()=>{                          
				this.campaignService.changeList()
			}, 500);
		}

	}
	  
}
