<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Cupom </h3>

    </div>

    <input mat-input [(ngModel)]="coupon.name" placeholder="Escreva aqui o nome do cupom" class="input"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" >

    <textarea mat-input [(ngModel)]="coupon.description" style="resize: none;"class="input" 
        placeholder="Escreva aqui a descrição do cupom..." 
        [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}">
    </textarea>

    <div class="row_image_date">
        <div class="coll-image input-img" 
            [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}">

            <div *ngIf="coupon.image == null" class="button-image" (click)="fileImage.click()"> 
                adicione uma imagem 
            </div>

            <input type="file" #fileImage style="display: none; " accept="image/*" 
                (change)="onFileChanged($event)">

            <img [src]="coupon.image" (click)="fileImage.click()" *ngIf="coupon.image != null" 
                class="profile-image">
        </div>

        <div class="coll-date">
            <div class="select-date">
                <mat-form-field appearance="fill" style="width: 100%;" class="input" 
                    [ngStyle]="{'border-color': warningMap.get('start_date')? 'red' : ''}">

                    <input matInput [matDatepicker]="picker1" placeholder="Data inicial" 
                        [(ngModel)]="coupon.start_date" readonly>

                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
        
            <div class="select-date">
                <mat-form-field appearance="fill" style="width: 100%;" class="input" 
                    [ngStyle]="{'border-color': warningMap.get('end_date')? 'red' : ''}">

                    <input matInput [matDatepicker]="picker2" placeholder="Data final" 
                        [(ngModel)]="coupon.end_date" readonly>

                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>

    <textarea mat-input [(ngModel)]="coupon.rule" placeholder="Escreva aqui as regras do cupom..." 
        [ngStyle]="{'border-color': warningMap.get('rule')? 'red' : ''}"
        style="resize: none;" class="input">
    </textarea>

    <div class="input" style="width: 100%; display: flex; 
        justify-content: space-between; align-items: center;"
        [ngStyle]="{'border-color': warningMap.get('rules')? 'red' : ''}">

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="fileInput.click()" > 
            Selecione o arquivo
        </button>

        <input matInput [(ngModel)]="pdfTerm" readonly style="width: calc(100% - 210px);" 
            placeholder="Selecione o arquivo dos termos">

        <div target="_blank" *ngIf="coupon?.rules != null && (coupon?.rules?.substring(0,4) == 'http')" (click)="openPdf(coupon?.rules)"> 
            <svg style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;" viewBox="0 0 1300 1300" >
                    <path [style.fill]="color_primary" d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                    -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                    265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                    -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                    -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                    423 -1 120 24 203 92 304 134 200 375 280 608 204z"/>
                    <path [style.fill]="color_primary" d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                    -3 97 -22 19 -53 28 -73 21z"/>
                    <path [style.fill]="color_primary" d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                    50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z"/>
            </svg>

            <!-- <img src="../assets/image/information.png"
                style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;"> -->
        </div> 

        <input type="file" #fileInput style="display: none;" 
            accept="application/pdf" (change)="onFilePdf($event)">
    </div>

    <input type="number" mat-input [(ngModel)]="coupon.automatic_after" 
        placeholder="Escreva aqui a quantidade de acessos para deixar o cupom automático" class="input"
        [ngStyle]="{'border-color': warningMap.get('automatic_after')? 'red' : ''}">

    <input type="number" mat-input
        placeholder="Escreva aqui a quantidade de vezes que o cupom será utilizado" class="input"
        [ngStyle]="{'border-color': warningMap.get('outher')? 'red' : ''}">

    <div class="toggle">
        <mat-slide-toggle class="input" [(ngModel)]="coupon.login_required"> 
            Cadastro obrigatório 
        </mat-slide-toggle>
    </div>

    <mat-select [(ngModel)]="coupon.registration_config" 
        class="input select-box" placeholder="Selecione a configuração do cupom"
        [ngStyle]="{'border-color': warningMap.get('config')? 'red' : ''}">

        <mat-option *ngFor="let obj of list_registration; let i = index" [value]="obj.id">
            {{ obj.title }}
        </mat-option>
    </mat-select>

    <div style="display: flex; margin-top: 10px;">
        <!-- <button mat-button class="button-cor" style="margin-right: 10px;"> 
            Adicionar Pergunta 
        </button> -->
        
        <!-- <button mat-button class="button-view" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou o catálogo
        </button> -->

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()" > 
            {{ coupon_id == null ? 'Finalizar Cupom' : 'Salvar Cupom' }} 
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status" aria-hidden="true"></span>
        </button>
    </div>
            
</div>