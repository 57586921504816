<div class="more-details-container">

    <!-- <div class="back-btn">
        <button (click)="backPage()">
            <div class="btn-content">
                <span class="btn-icon">
                    <img src="../../../../assets/icon/prev.png" alt="voltar" />
                </span>
                <span class='btn-text'>Voltar</span>
            </div>
        </button>
    </div> -->

    <span class="details">
        <div class="details-img">
            <img src='{{ product.image }}' alt="">
        </div>
        <div class="details-info">
            <h1 class="item-title">
                {{ product.name }}
            </h1>
            <h2 class="item-price">
                R$ {{ product.price }}
            </h2>
            <p class="item-description">
                {{ product.description }}
            </p>
            <div class="button_add" (click)="addProduct(product)" *ngIf="purchase">
                Adicionar 
            </div>
        </div>
    </span>

    <div class="card_classification_food">
        <h2> Comida de Classificação </h2>
        <div class="list_food">
            <h3 *ngFor="let food of product.classification_food"> {{ food.name }} </h3>
        </div>
    </div>

    <div class="card_table_nutrition">
        <h2> Informações Nutricionais </h2>

        <div class="div-table" *ngIf="loading_table">
            <table mat-table [dataSource]="product.table_nutrition" matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Nome </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="qtd">
                    <th mat-header-cell *matHeaderCellDef> Quant. </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.quant}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Valor </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.value}}%
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado
                        encontrado. </td>
                </tr>
            </table>
        </div>
    </div>

    <div style="height: 50px;"></div>
</div>