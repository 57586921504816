import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { StorageService } from "src/app/service/storage.service";

@Component({
  selector: "app-view-tabloide",
  templateUrl: "./view-tabloide.component.html",
  styleUrls: ["./view-tabloide.component.css"],
})
export class ViewTabloideComponent implements OnInit {

  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
  @Input() type_campaign: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('slickCat') slickCat: SlickCarouselComponent;

  constructor(
    private storageService: StorageService,
    private _snackBar: MatSnackBar,

  ) { }

  cart_bool: boolean = false;
  cart_list: any = [];

  color_primary: any;

  tabloide_obj: any;

  slides_products: any;

  view_mode: boolean = false;
  is_dragging: boolean = false;

  nextCategory: boolean = false;
  backCategory: boolean = false;

  index_category: number = 0;

  is_order: boolean = false;
  is_login: boolean = false;

  ngOnInit(): void {
    // console.log("-> Tabloide <-")
    console.log(this.list)

    if (this.list?.category != null && this.list.category.length >= 1) {
      this.tabloide_obj = this.list?.category[0]?.id != null ? this.list : this.list.images[0].tabloide_obj
      this.slides_products = this.tabloide_obj.category[0];
    }
    else if (this.list?.home_images != null && this.list.home_images.length >= 1) {
      this.tabloide_obj = this.list?.home_images[0] != null && this.list?.home_images[0]?.tabloide_obj?.id != null ? this.list.home_images[0].tabloide_obj : this.list;
      this.slides_products = this.tabloide_obj.category[0];
    }
    else {
      this.tabloide_obj = null
    }

    console.log('OBJ ->', this.tabloide_obj)

    this.color_primary = this.storageService.establishment.color;
  }

  eventItem(event){
    console.log(event);
    
    if(event == 'back'){
      this.is_order = false;
    }
    if(event == 'order-ok'){
      this.openSnackBar("Compra feia com sucesso !", "Fechar")
      this.cart_list = []
      this.is_order = false;
      this.is_login = false;
      this.cart_bool = false;
      this.view_mode = false;
    }
    if (event == 'LOGIN') {
			this.is_login = true;
		}
    
    // this.newItemEvent.emit('back');
  }

  login(newItem) {
		this.is_login = false;
    this.is_order = true;
	}

  changeModeView() {
    if (this.cart_bool) {
      this.cart_bool = false;
    }
    else {
      this.view_mode = !this.view_mode;
    }
  }

  formatReal = (item: any): void =>
    item
      ? item.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
      : "";

  addItemCart(item: any) {
    this.cart_list.push(item);
    this.openSnackBar("Item adicionado!!", "Fechar")
  }

  selectItemList(slide, idx) {
    this.view_mode = !this.view_mode;

    setTimeout(() => {
      this.slickModal.slickGoTo(idx);
    }, 100);
  }

  selectCategory(category, idx) {

    if (!this.is_dragging) {
      this.slides_products = null;
      this.slides_products = category;
      this.index_category = idx
    }
  }

  beforeChange(e) {
    this.is_dragging = true;
  }

  afterChange(e) {
    this.is_dragging = false;
  }

  changeSlide(e) {

    if (this.nextCategory && e.last == true) {

      if (this.tabloide_obj.category[this.index_category + 1] != null) {
        this.index_category = this.index_category + 1
      } else {
        this.index_category = 0;
      }

      this.slides_products = this.tabloide_obj.category[this.index_category];
      this.slickModal.slickGoTo(0)
      this.nextCategory = false;
      this.backCategory = false;
    }
    else if (this.backCategory && e.first == true) {

      if (this.tabloide_obj.category[this.index_category - 1] != null) {
        this.index_category = this.index_category - 1
      } else {
        this.index_category = this.tabloide_obj.category.length - 1;
      }

      this.slides_products = this.tabloide_obj.category[this.index_category];
      this.slickModal.slickGoTo(this.tabloide_obj.category.length - 1)
      this.nextCategory = false;
      this.backCategory = false;
    }
    else {
      if (e.last == true) {
        this.nextCategory = true;
      }
      else if (e.first == true) {
        this.backCategory = true;
      }
      else {
        this.nextCategory = false;
        this.backCategory = false;
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    // nextArrow: '#customNext'
  };

  slideConfigProduct = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    // nextArrow: '#customNext'
  };
}
