import { MatSnackBar } from '@angular/material/snack-bar';
import { SpreadsheetTable } from './../../model/spreadsheet';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from 'src/app/service/storage.service';
import { SpreadsheetService } from '../../service/spreadsheet.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spreadsheet-table',
  templateUrl: './spreadsheet-table.component.html',
  styleUrls: ['./spreadsheet-table.component.css']
})

export class SpreadsheetTableComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private spreadsheetService: SpreadsheetService,
    private storageService: StorageService,
    private _snackBar: MatSnackBar,
  ) { 
    this.spreadsheet_id = this.route.snapshot.paramMap.get('id');
    this.spreadsheet_table_id = this.route.snapshot.paramMap.get('table');
    this.establishment_id = localStorage.getItem('id_establishment')
  }

  spreadsheet_id;
  spreadsheet_table_id;
  establishment_id;
  spreadsheet_table: SpreadsheetTable = {} as SpreadsheetTable

  description_keys: string[] = []
  description_json: DescriptionJson = {} as DescriptionJson
  rows_array: DescriptionJson[] = []

  color_primary: string;
  loading: boolean = false;

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;
    this.getSpreadsheetTable()
  }

  getSpreadsheetTable() {
    this.spreadsheetService.getSpreadsheetTableId(this.spreadsheet_table_id).subscribe( data => {
      this.spreadsheet_table = data

      if (data.description_json != null && data.description_json != "") {
        this.description_json = JSON.parse(data.description_json)
        this.description_keys = Object.keys(this.description_json)
      }
      if(data.columns_array != null && data.columns_array != "") {
        this.displayedColumns = JSON.parse(data.columns_array)
      }
      if(data.rows_array != null && data.rows_array != "") {
        this.rows_array = JSON.parse(data.rows_array)
        this.dataSource = new MatTableDataSource(this.rows_array)

        console.log(this.rows_array)
      }
      
    })
  }

  save() {
    if (this.loading) return
    this.loading = true

    this.spreadsheetService.patchSpreadsheetTable(
      this.spreadsheet_table_id, this.spreadsheet_table.title, 
      this.spreadsheet_table.spreadsheet_subtitle, 
      JSON.stringify(this.description_json),
      JSON.stringify(this.rows_array)
    ).subscribe( data => {
      this.openSnackBar("Salvo com sucesso!", "Fechar")
      this.loading = false;
    }, error => {
      this.openSnackBar("ERRO: Não foi possível salvar", "Fechar")
      this.loading = false;
    })
  }

  formatKey(key){
    return key.replaceAll("_", " ")
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

}

interface DescriptionJson {
  [value: string]: string
}
