import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-minimax',
  templateUrl: './minimax.component.html',
  styleUrls: ['./minimax.component.css']
})
export class MinimaxComponent implements OnInit {

  
  title: string;
  local: string;
  table_header: string;
  table_1: string;
  table_2: string;
  header: string;
  footer: string;

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.footer, 'footer');
    console.log(this.header,'header');
    console.log(this.table_header,'table_header');
    console.log(this.table_1,'table_1');
    console.log(this.table_2,'table_2');
    console.log(this.local,'local');
    console.log(this.title,'title');
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

}
