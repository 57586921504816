import { dateMapper } from "src/app/utils/validator.utils";

export const getAlarmConfig = () => ({
    header: {
        worksheet: 'Alarmes',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 2, col: 3, key: 'place' },
            { row: 8, col: 1, key: 'verifiedBy' },
            { row: 8, col: 4, key: 'date' },
            { row: 8, col: 6, key: 'hour' },
            { row: 8, col: 7, key: 'month' },
        ],
    },

    items_1: {
        worksheet: 'Alarmes',
        type: 'list',
        rowOffset: 22,
        endRows: 49,
        columns: [
            { index: 1, key: 'name'},
            { index: 2, key: 'building'},
            { index: 3, key: 'column'},
            { index: 4, key: 'according'},
            { index: 5, key: 'not_according'},
            { index: 6, key: 'sirens_lights'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    items_2: {
        worksheet: 'Alarmes',
        type: 'list',
        rowOffset: 50,
        endRows: 103,
        columns: [
            { index: 1, key: 'name'},
            { index: 2, key: 'building'},
            { index: 3, key: 'column'},
            { index: 4, key: 'according'},
            { index: 5, key: 'not_according'},
            { index: 6, key: 'sirens_lights'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    items_3: {
        worksheet: 'Alarmes',
        type: 'list',
        rowOffset: 104,
        endRows: 157,
        columns: [
            { index: 1, key: 'name'},
            { index: 2, key: 'building'},
            { index: 3, key: 'column'},
            { index: 4, key: 'according'},
            { index: 5, key: 'not_according'},
            { index: 6, key: 'sirens_lights'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    items_4: {
        worksheet: 'Alarmes',
        type: 'list',
        rowOffset: 158,
        endRows: 205,
        columns: [
            { index: 1, key: 'name'},
            { index: 2, key: 'building'},
            { index: 3, key: 'column'},
            { index: 4, key: 'according'},
            { index: 5, key: 'not_according'},
            { index: 6, key: 'sirens_lights'},
            { index: 7, key: 'according'},
            { index: 8, key: 'not_according'},
        ],
    },

    footer: {
        worksheet: 'Alarmes',
        type: 'list',
        rowOffset: 48,
        endRows: 51,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 3, key: 'approved' },
            { index: 5, key: 'date', mapper: dateMapper },
            { index: 6, key: 'reference' },
        ],
    },

});
