import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from "rxjs";
import { Catalog } from "src/app/catalogs/catalog-detail/catalog-detail.component";
import { CatalogService } from "src/app/service/catalog.service";
import { SectionService } from "src/app/service/section.service";
import { StorageService } from "src/app/service/storage.service";

@Component({
    templateUrl: './import-csv.component.html',
    styleUrls: ['./import-csv.component.css']
})

export class ImportCsv implements OnInit {
    constructor(
        private router: Router,
        private modalRef: MDBModalRef,
        private catalogService: CatalogService,
        private sectionService: SectionService,
        private storageService: StorageService
    ) { }

    action: Subject<any> = new Subject();
    loading: boolean = false;
    loading_count: number = 0;

    finalData = [];
    nomeArquivo: string = 'Nenhum arquivo selecionado.'

    list_post_catalog = [];

    list_catalog = []
    color_primary: any = null;

    ngOnInit() {
        this.color_primary = this.storageService.establishment.color;
    }

    closePopup() {
        this.modalRef.hide();
    }

    next() {
        if (this.loading) return

        this.loading = true;

        this.tratamentoCatalogo(this.finalData)
        this.tratamentoInput(this.list_catalog)

        this.list_post_catalog.forEach(catalog => {
            this.postCatalog(catalog)
        });

        setTimeout(() => {
            this.loading = false;

            this.modalRef.hide();
            this.action.next('yes');
        }, 1000);
    }

    changeListener(files: FileList) {
        // console.log(files)
        if (files.length > 0) {
            let file = files.item(0);
            let reader: FileReader = new FileReader();
            this.nomeArquivo = file.name;

            reader.readAsText(file, 'ISO-8859-1');
            reader.onload = (e) => {
                let csv: any = reader.result;
                csv.replace(/(\"\")/g, '"').slice(1, -1);
                let allTextLines = [];
                allTextLines = csv.split(/\r|\n|\r/);


                const headers = allTextLines[0].split(';');

                const finalData = []

                for (var i = 1; i < allTextLines.length; i++) {
                    var data = allTextLines[i].split(';');
                    var obj = {};
                    for (var j = 0; j < data.length; j++) {
                        obj[headers[j].trim()] = data[j].trim();
                    }
                    finalData.push(obj);
                }

                this.finalData = finalData.reduce((acc, cur) => {
                    const key = cur['Nome do catalogo'];
                    if (key != '') {
                        if (!acc[key]) {
                            acc[key] = {
                                name: key,
                                description: cur['Descricao do catalogo'],
                                sections: []
                            }
                        }
                        const sectionKey = cur['Categoria (Secao)'];
                        const morning_display = cur['Exibicao Manha'];
                        const afternoon_screening = cur['Exibicao Tarde'];
                        const morning_view = cur['Exibicao Madrugada'];
                        const night_view = cur['Exibicao Noite'];
                        if (sectionKey != '') {
                            if (!acc[key].sections[sectionKey]) {
                                acc[key].sections[sectionKey] = {
                                    name: sectionKey,
                                    morning_period: morning_display,
                                    afternoon_period: afternoon_screening,
                                    night_period: night_view,
                                    dawn_period: morning_view,
                                    items: []
                                }
                            }
                            acc[key].sections[sectionKey].items.push({
                                name: cur['Titulo do Item'],
                                subtitle: cur['Sub titulo do Item'],
                                price: cur['Preco do Item'],
                                weight: cur['Peso do Item (gramas)'],
                                descricao: cur['Descricao do Item'],
                                ingredients: cur['Ingredientes do Item'],
                                contain: cur['Contem'],
                                can_contain: cur['Pode Conter'],
                                energetic_value: cur['Valor Energetico'],
                                nutritional_carbohydrates: cur['Nutricional Carboidratos'],
                                nutritional_fiber: cur['Nutricional Fibra'],
                                total_fat: cur['Gorduras Totais'],
                                trans_fats: cur['Gorduras Trans'],
                                nutritional_protein: cur['Nutricional Proteina (%)'],
                                sodium: cur['Sodio'],
                                gluten_free: cur['Gluten Free'],
                                zero_lactose: cur['Zero Lactose'],
                                low_carb: cur['Low Carb'],
                                sugar_free: cur['Sugar Free'],
                                low_fodmap: cur['Low fodmap'],
                                // morning_display: cur['Exibicao Manha'],
                                // afternoon_screening: cur['Exibicao Tarde'],
                                // morning_view: cur['Exibicao Madrugada'],
                                // night_view: cur['Exibicao Noite'],
                            })
                        }
                    }
                    return acc;
                }, {});

                // console.log(this.finalData)
            }

        }
    }

    tratamentoCatalogo(data) {
        for (var prop_1 in data) {

            let aux_catalog = {
                name: '',
                description: '',
                section: [{
                    order: 0,
                    name: '',
                    morning_period: false,
                    afternoon_period: false,
                    night_period: false,
                    dawn_period: false,
                    products: [{
                        name: '',
                        subtitle: '',
                        price: 0,
                        weight: '',
                        description: '',
                        ingredients: '',
                        contain: '',
                        image: null,
                        section: null,
                        can_contain: '',
                        energetic_value: '',
                        nutritional_carbohydrates: '',
                        nutritional_fiber: '',
                        total_fat: '',
                        trans_fats: '',
                        nutritional_protein: '',
                        sodium: '',
                        gluten_free: '',
                        zero_lactose: '',
                        low_carb: '',
                        sugar_free: '',
                        low_fodmap: '',
                        // morning_display: '',
                        // afternoon_screening: '',
                        // morning_view: '',
                        // night_view: ''
                    }],
                    catalog_id: null
                }],
            };

            aux_catalog.name = data[prop_1].name;
            aux_catalog.description = data[prop_1].description;

            let keys = Object.keys(data[prop_1].sections)
            keys.forEach((prop_2, index) => {

                // for (var prop_2 in data[prop_1].sections) {
                aux_catalog.section.push(
                    {
                        order: index,
                        name: data[prop_1].sections[prop_2].name,
                        morning_period: data[prop_1].sections[prop_2].morning_period,
                        afternoon_period: data[prop_1].sections[prop_2].afternoon_period,
                        night_period: data[prop_1].sections[prop_2].night_period,
                        dawn_period: data[prop_1].sections[prop_2].dawn_period,
                        products: data[prop_1].sections[prop_2].items,
                        catalog_id: null
                    }
                )
                // }
            });

            this.list_catalog.push(aux_catalog)
        }
    }

    tratamentoInput(element) {
        // console.log(element);

        let idx_c = 0, idx_s = 0

        element.forEach(cardapio => {

            if (element != null && element.length > 0) {

                this.list_post_catalog.push(
                    {
                        name: cardapio.name != null && cardapio.name != '' ? cardapio.name : 'CATÁLOGO IMPORTADO',
                        description: cardapio.description != null && cardapio.description != '' ? cardapio.description : '',
                        section: [],
                    }
                )
                // console.log('cardapio ->', this.list_post_catalog);

                if (cardapio.section != null && cardapio.section.length > 0) {

                    cardapio.section.forEach(section => {

                        if (section.name != null && section.name != '') {

                            this.list_post_catalog[idx_c].section.push(
                                {
                                    order: section.order,
                                    name: section.name,
                                    morning_period: (section.morning_period == 'S' ? true : false),
                                    afternoon_period: (section.afternoon_period == 'S' ? true : false),
                                    night_period: (section.night_period == 'S' ? true : false),
                                    dawn_period: (section.dawn_period == 'S' ? true : false),
                                    products: [],
                                }
                            )
                            // console.log('section ->', this.list_post_catalog[idx_c].section);
                            if (section.products != null && section.products.length > 0) {
                                // console.log('product ->', idx_c, idx_s);

                                section.products.forEach(produ => {
                                    this.list_post_catalog[idx_c].section[idx_s].products.push(
                                        {
                                            name: (produ.name != null && produ.name != '' ? produ.name : 'Produto'),
                                            subtitle: produ.subtitle,
                                            price: (this.convertPrice(produ.price) > 0 ? this.convertPrice(produ.price) : 0),
                                            weight: produ.weight,
                                            ingredients: produ.ingredients,
                                            contain: produ.contain,
                                            can_contain: produ.can_contain,
                                            energetic_value: produ.energetic_value,
                                            nutritional: [
                                                {
                                                    name: 'Carboidratos',
                                                    value: this.setValue(produ.nutritional_carbohydrates),
                                                    porcent: this.setPorcent(produ.nutritional_carbohydrates)
                                                },
                                                {
                                                    name: 'Fibra',
                                                    value: this.setValue(produ.nutritional_fiber),
                                                    porcent: this.setPorcent(produ.nutritional_fiber)
                                                },
                                                {
                                                    name: 'Proteína',
                                                    value: this.setValue(produ.nutritional_protein),
                                                    porcent: this.setPorcent(produ.nutritional_protein)
                                                },
                                                {
                                                    name: 'Sódio',
                                                    value: this.setValue(produ.sodium),
                                                    porcent: this.setPorcent(produ.sodium)
                                                },
                                                {
                                                    name: 'Gordura total',
                                                    value: this.setValue(produ.total_fat),
                                                    porcent: this.setPorcent(produ.total_fat)
                                                },
                                                {
                                                    name: 'Gorduras Trans',
                                                    value: this.setValue(produ.trans_fats),
                                                    porcent: this.setPorcent(produ.trans_fats)
                                                },
                                            ],
                                            classification: [
                                                // { name: 'Exibição da tarde', status: produ.afternoon_screening },
                                                { name: 'Sem glúten', status: produ.gluten_free },
                                                { name: 'Baixo carboidrato', status: produ.low_carb },
                                                { name: 'Low fodmap', status: produ.low_fodmap },
                                                // { name: 'Exibição matinal', status: produ.morning_display },
                                                // { name: 'Visão matinal', status: produ.morning_view },
                                                { name: 'Zero açúcar', status: produ.sugar_free },
                                                { name: 'Zero lactose', status: produ.zero_lactose },
                                                // { name: 'Visão noturna', status: produ.night_view },
                                            ],
                                            description: produ.descricao,
                                            image: '',
                                            section: null
                                        })
                                    // console.log('product ->', this.list_post_catalog[idx_c].section[idx_s]);
                                });
                            }
                            idx_s++;
                        }
                    });
                }
                idx_c++;
                idx_s = 0
            }
        });

        console.log(this.list_post_catalog);
    }

    setValue(value) {
        // console.log(value);

        if (value != '' && value != null && value.indexOf(",") != -1) {
            let aux_array = value.split(', ')
            return aux_array[1]
        }
        return value
    }

    setPorcent(value) {
        if (value != '' && value != null && value.indexOf(",") != -1) {
            let aux_array = value.split(', ')
            return aux_array[0]
        }
        return value
    }

    postCatalog(catalog) {
        this.loading_count++;

        this.catalogService.postCatalog(catalog).subscribe(data_catalog => {
            // console.log(data_catalog);

            catalog.section.forEach(section => {
                this.postSection(section, data_catalog)
            })
        })
    }

    postSection(section, catalog) {

        this.loading_count++;

        this.sectionService.postSection(section.name, catalog.id, section.morning_period, section.afternoon_period, section.night_period, section.dawn_period, section.order).subscribe(data_section => {

            section.products.forEach(item => {

                this.catalogService.postCatalogItem(data_section.id, item.name,
                    item.description, item.price, item.image).subscribe(data_item => {

                        if (item.classification != null && item.classification.length > 0) {
                            item.classification.forEach(food => {

                                if (food.status == 'S') this.postClssificationFood(food.name, data_item.id)
                            });
                        }

                        if (item.nutritional != null && item.nutritional.length > 0) {
                            item.nutritional.forEach(table => {
                                this.postTableNutrition(table.name, table.porcent, table.value, data_item.id)
                            });
                        }
                    })
            });
        })
    }

    postClssificationFood(name, id) {
        this.catalogService.postClssificationFood(name, id).subscribe(data_section => {
        })
    }

    postTableNutrition(name, quant, value, id) {
        this.catalogService.postTableNutrition(name, quant, value, null, id).subscribe(data_section => {

        })
    }

    convertPrice(price) {
        if (price != null && price != '') {
            var aux_price = price.replace(',', '.');

            if (aux_price.substring(0, 3) == 'R$ ') {
                return Number(aux_price.substring(3))
            }

            return Number(aux_price)
        }
        else return 0
    }
}