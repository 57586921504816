import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { DetailCategoryComponent } from '../categories/detail-category/detail-category.component';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { PreviewCampaignComponent } from '../modals/preview-campaign/preview-campaign.component';
import { PreviewPostComponent } from '../modals/preview-post/preview-post.component';
import { Tabloide } from '../model/tabloide';
import { CategoryService } from '../service/category.service';
import { StorageService } from '../service/storage.service';
import { TabloideService } from '../service/tabloide.service';

@Component({
  selector: 'app-screen-tabloide',
  templateUrl: './tabloide.component.html',
  styleUrls: ['./tabloide.component.css']
})
export class TabloideComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private tabloideService: TabloideService,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  modalDelet: MDBModalRef;
  modalRef: MDBModalRef;

  loading: boolean = false;
  displayedColumns: string[] = ['campaign', 'created', 'view'];
  dataSource = new MatTableDataSource<any>();
  // dataSource = ELEMENT_DATA;

  /* pagination related variables */
  campaign_response;
  num: number;
  page: number = 1
  count_total: number = 0; 
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;
  total_results: number;
  color_primary: string = '';

  obj_response = null;
  tabloide_id: any
  categories_list = [];

  ngOnInit(): void {
    this.num = 10;
    this.page = 1
    this.total_results = 0;

    this.color_primary = this.storageService.establishment.color;

    this.getListTabloide()
    // this.openPreview(ELEMENT_DATA[0])
  }

  // TABLOIDE

  getListTabloide(){
    this.loading = true;
    this.tabloideService.getTabloide(this.page).subscribe(data=>{
      console.log(data)
      this.obj_response = data;
      this.dataSource.data = data.results
      this.loading = false

      // pagination calc
      this.count_total = data.count;
      this.page_count1 = 1 + ((this.page-1) * this.page_size)
      if((this.page_count1 + (this.page_size-1)) > this.count_total){
          this.page_count2 = this.count_total;
      }
      else{
          this.page_count2 = this.page_count1 + (this.page_size-1);
      }
    },
    error=>{
      console.log(error)
    })
  }

  deleteTabloidePopup(obj){
      this.modalDelet = this.modalService.show(ModalChoiceComponent, {
          backdrop: 'static',
          keyboard: false,
          focus: true,
          show: true,
          ignoreBackdropClick: false,
          class: 'modal-dialog-centered popup-transparent',
          containerClass: '',
          animated: false,
          data:{
              title: 'Excluir tabloide',
              text: 'Deseja excluir o tabloide '+obj.name+'?',
          }
      })   
      this.modalDelet.content.action.subscribe( (result: any) => { 
          if(result == 'yes'){
              this.deleteTabloideId(obj.id)
              this.openSnackBar("Tabloide excluído com sucesso!", "Fechar");
          }
      }); 
  }

  deleteTabloideId(id: number){
    this.loading = true;
    this.tabloideService.deleteTabloide(id).subscribe(data=>{
        this.loading = false;
        this.getListTabloide()
    },
    (error) => {
      console.log(error);
    })
  }

  nextPage(){
    if(this.obj_response.next != null){
        this.page += 1;
        this.getListTabloide()
    }
  }

  backPage(){
      if(this.obj_response.previous != null){
          this.page -= 1;
          this.getListTabloide()
      }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 1000,
      panelClass: ["edit-snackbar"],
    });
  }


  openPreview(campaign){
    if(!campaign.is_feed){
        this.modalRef = this.modalService.show(PreviewCampaignComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                campaign: campaign,
                type_preview: 'tabloide'
            }
        })    
    }
    else{
        this.modalRef = this.modalService.show(PreviewPostComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                campaign: campaign,
            }
        })  
    }

}

}

