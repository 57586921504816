import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class CatalogService {

    constructor(private http: HttpClient) { }

    establishment = "id_establishment"

    getCatalogs(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/catalog/`, options);
    }

    getCatalog(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

        return this.http.get<any>(AppConfig.path + `core/catalog/${id}/`, { headers });
    }

    postCatalog(catalog): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, catalog.name)
            .set(`description`, catalog.description)
            .set(`establishment`, localStorage.getItem(this.establishment))

        if (catalog.shipping_title != null) {
            body = body.set(`shipping_title`, catalog.shipping_title)
        }
        if (catalog.shippin_description != null) {
            body = body.set(`shippin_description`, catalog.shippin_description)
        }
        if (catalog.purchase != null && catalog.purchase != '') {
            body = body.set(`purchase`, catalog.purchase)
        }
        if (catalog.logo_menu != null && catalog.logo_menu != '') {
            body = body.set(`logo_menu`, encodeURI(catalog.logo_menu))
        }
        if (catalog.informative_text != null && catalog.informative_text != '') {
            body = body.set(`informative_text`, catalog.informative_text)
        }
        if (catalog.link != null && catalog.link != '') {
            body = body.set(`link`, catalog.link)
        }
        if (catalog.footer_text != null && catalog.footer_text != '') {
            body = body.set(`footer_text`, catalog.footer_text)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/catalog/`, body, { headers })
    }

    postCatalogItem(section, name, description, price, image): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, name)
            .set(`description`, description)
            .set(`price`, price)
            .set(`section`, section)

        if (image != null) {
            body = body.append(`image`, encodeURI(image))
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/catalog/product/`, body, { headers })
    }

    patchCatalog(catalog): Observable<any> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, catalog.name)
            .set(`description`, catalog.description)

        if (catalog.shipping_title != null) {
            body = body.append(`shipping_title`, catalog.shipping_title)
        }
        if (catalog.shippin_description != null) {
            body = body.append(`shippin_description`, catalog.shippin_description)
        }
        if (catalog.purchase != null) {
            console.log(catalog);
            body = body.append(`purchase`, catalog.purchase)
        }
        if (catalog.logo_menu != null && catalog.logo_menu != '') {
            body = body.append(`logo_menu`, encodeURI(catalog.logo_menu))
        }
        if (catalog.informative_text != null && catalog.informative_text != '') {
            body = body.append(`informative_text`, catalog.informative_text)
        }
        if (catalog.link != null && catalog.link != '') {
            body = body.append(`link`, catalog.link)
        }
        if (catalog.footer_text != null && catalog.footer_text != '') {
            body = body.append(`footer_text`, catalog.footer_text)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/catalog/${catalog.id}/`, body, { headers })
    }

    putCatalogItem(id, section, name, description, price, image): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, name)
            .set(`description`, description)
            .set(`price`, price)
            .set(`section`, section)

        if (image != null) {
            body = body.append(`image`, encodeURI(image))
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.put<any>(AppConfig.path + `core/catalog/${id}/update-product/`, body, { headers })
    }

    deleteCatalog(id): Observable<any> {
        let body = new HttpParams()
            .set(`active`, 'false')


        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/catalog/${id}/`, body, { headers })
    }

    deleteCatalogProduct(id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/catalog/${id}/delete-product/`, { headers })
    }

    // buy \ payment

    postOrder(kits, final_price, street, complement, neighborhood, number, cep,
        city, state, coupon, type, cpf_cnpj): Observable<any> {

        let token = localStorage.getItem('token');

        let body = new HttpParams()
            .append(`final_price`, final_price)
            .append(`street`, street)
            .append(`complement`, complement)
            .append(`number`, number)
            .append(`cep`, cep)
            .append(`city`, city)
            .append(`state`, state)
            .append(`type_user`, type) //cpf ou cnjp
            .append(`document`, cpf_cnpj)
        //   .append(`reference_point`, reference_point)

        if (neighborhood != null) {
            body = body.append(`neighborhood`, neighborhood)
        }

        /*if (coupon != null && coupon != "") {
          body = body.append(`coupon`, coupon)
        }*/

        if (kits != null && kits != "") {
            body = body.append(`kits`, kits)
        }

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.post<any>(AppConfig.path + `core/order/`, body, options)
    }

    payment(card_holder, card_brand, card_date, card_code, card_number, order): Observable<any> {

        let token = localStorage.getItem('token');

        let body = new HttpParams()
            .append(`order`, order)
            .append(`card_number`, card_number)
            .append(`card_code`, card_code)
            .append(`card_date`, card_date)
            .append(`card_brand`, card_brand)
            .append(`card_holder`, card_holder)

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.post<any>(AppConfig.path + `payment/acquisition/`, body, options)
    }



    // Imagens detalhes do cardapio
    getCatalogImages(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`catalog`, id)
        };

        return this.http.get<any>(AppConfig.path + `core/desktop-catalog-item/filter_catalog`, options)

    }

    postCatalogImages(image, catalog): Observable<any> {
        let token = localStorage.getItem('token');

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .append(`icon`, encodeURI(image))
            .append(`catalog`, catalog)

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.post<any>(AppConfig.path + `core/desktop-catalog-item/`, body, options)
    }

    patchCatalogImages(image): Observable<any> {
        let token = localStorage.getItem('token');

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .append(`icon`, encodeURI(image.icon))

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.patch<any>(AppConfig.path + `core/desktop-catalog-item/${image.id}/`, body, options)
    }

    deleteCatalogImages(image): Observable<any> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`)
        };

        return this.http.delete<any>(AppConfig.path + `core/desktop-catalog-item/${image.id}`, options)
    }


    // Clssification Food
    postClssificationFood(name, product): Observable<any> {
        let token = localStorage.getItem('token');

        let body = new HttpParams()
            .append(`name`, name)
            .append(`product`, product)

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.post<any>(AppConfig.path + `core/classification-food/`, body, options)
    }

    deleteClssificationFood(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/classification-food/${id}/`, { headers })
    }

    // Table Nutrition
    postTableNutrition(name, quant, value, catalog, product): Observable<any> {
        let token = localStorage.getItem('token');

        let body = new HttpParams()
            .append(`name`, name)
            .append(`quant`, (quant == '' || quant == null ? 1 : quant))
            .append(`value`, (value == '' || value == null ? 0 : value))
            // .append(`catalog`, catalog)
            .append(`product`, product)

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.post<any>(AppConfig.path + `core/table-nutrition/`, body, options)
    }

    patchTableNutrition(nutrition): Observable<any> {
        let token = localStorage.getItem('token');

        let body = new HttpParams()
            .set(`quant`, (nutrition.quant == '' || nutrition.quant == null ? 1 : nutrition.quant))
            .set(`value`, (nutrition.value == '' || nutrition.value == null ? 0 : nutrition.value))

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.patch<any>(AppConfig.path + `core/table-nutrition/${nutrition.id}/`, body, options)
    }
}
