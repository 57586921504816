<div class="import-csv-container">

    <svg (click)="close()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
        [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path [style.fill]="color_primary"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>

    <label for="fileUpload" class="import-btn" matTooltip="Selecione um arquivo"
        [style.background]="color_primary != null ? color_primary : 'rgb(247, 113, 40)'">
        <mat-icon class="icon">cloud_upload</mat-icon>
        <input id="fileUpload" type="file" style="display: none;" (change)="onFileChanged($event)" />
    </label>

    <span *ngIf="nomeArquivo != ''" class="filename">{{nomeArquivo}}</span>

    <div class="input_gray" *ngIf="link != null && link != ''">
        <input matInput type="text" id="textArea" [(ngModel)]="link" readonly>
        <svg (click)="copyLink()" height="48" width="48">
            <path d="M9 43.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.8h3v30.15h23.7v3Zm6-6q-1.2 0-2.1-.9-.9-.9-.9-2.1v-28q0-1.2.9-2.1.9-.9 2.1-.9h22q1.2 0 2.1.9.9.9.9 2.1v28q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h22v-28H15v28Zm0 0v-28 28Z" />
        </svg>
    </div>

    <button class="next-btn main-button" *ngIf="nomeArquivo != '' && nomeArquivo != null"
        [style.background]="color_primary != null ? color_primary : 'rgb(247, 113, 40)'" (click)="next()" mat-button>
        Gerar link
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
</div>