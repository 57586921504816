<mdb-navbar style="position: fixed; width: 100%; z-index: 999; background-color: #fff !important;" [SideClass]="is_home ? 
    'navbar navbar-expand-lg navbar-light fixed-top scrolling-navbar no-box-shadow' : 
    'navbar navbar-expand-lg navbar-light fixed-top top-nav-collapse'">

    <mdb-navbar-brand routerLink="/">
        <img src="/assets/image/logo-letra-preta.png" class="img-logo" (click)="select('top')">
    </mdb-navbar-brand>

    <links *ngIf="is_home">

        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('home')"> Home </a>
            </li>

            <!-- <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('platform')"> Plataforma </a>
            </li>

            <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('differential')"> Diferencial </a>
            </li>

            <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('application')"> Aplicação </a>
            </li>

            <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('purchase')"> Comprar </a>
            </li> -->

            <li class="nav-item" routerLink="/">
                <a class="nav-link" (click)="select('contact')"> Contato </a>
            </li>

            <li class="nav-item" routerLink="/cliente">
                <a class="nav-link">
                    <button mat-button class="b-border color_gradient">
                        Cliente
                    </button>
                </a>
            </li>
        </ul>
    </links>

    <links *ngIf="!is_home">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLink="/">
                <a class="nav-link"> Home </a>
            </li>
        </ul>
    </links>

</mdb-navbar>

<div class="space"></div>

<app-home [events]="eventsSubject.asObservable()"> </app-home>

<!-- <router-outlet></router-outlet> -->

<div class="background-footer color_gradient">
    <div class="footer">
        <img src="/assets/image/logo-terceira-opcao.png" class="img-logo" style="width: 12vw; min-width: 120px;">
        <div class="options">
            <div (click)="select('home')"> Home </div>
            <!-- <div (click)="select('platform')"> Plataforma </div>
            <div (click)="select('differential')"> Diferencial </div>
            <div (click)="select('application')"> Aplicação </div>
            <div (click)="select('purchase')"> Comprar </div> -->
            <div (click)="select('contact')"> Contato </div>
        </div>
        <!-- <div class="social">
            <img src="/assets/image/Icon-insta.png" class="icon">
            <img src="/assets/image/Icon-Twitter.png" class="icon">
            <img src="/assets/image/Icon-Youtube.png" class="icon">
            <img src="/assets/image/icon-face.png" class="icon">
            <img src="/assets/image/Icon-WhatsApp.png" class="icon">
        </div> -->
        <div style="opacity: 40%; font-size: 12px; font-weight: 500; padding: 0 5px; text-align: center;">
            Coryright@ SBSolutions 2021. All rights reserved
        </div>
        <div style="opacity: 40%; font-size: 12px; font-weight: 500; padding: 0 5px; text-align: center;">
            45.769.919/0001-82
        </div>
    </div>
</div>