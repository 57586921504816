<div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>
    </div>

    <div class="modal-body">

        <div class="card_row_input" *ngIf="obj_product != null">

            <div class="w_50">
                <div (click)="fileInput.click()" class="input-img"
                    [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}">
                    <div *ngIf="obj_product.image == null"> adicione uma imagem </div>
                    <img [src]="obj_product.image" *ngIf="obj_product.image != null" class="profile-image">
                </div>
                <input type="file" #fileInput style="display: none; " accept="image/*" (change)="onFileChanged($event)">

                <textarea matInput type="text" class="input" [(ngModel)]="obj_product.description"
                    style="height: 150px; resize: none;" placeholder="Escreva aqui uma descrição"
                    [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}"></textarea>
            </div>

            <div class="w_50">
                <input mat-input [(ngModel)]="obj_product.name" placeholder="Escreva aqui o nome do item"
                    [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">


                <input mat-input [(ngModel)]="obj_product.price" placeholder="Digite o preço" mask="separator.2"
                    [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ "
                    [ngStyle]="{'border-color': warningMap.get('price')? 'red' : ''}" class="input">

                <mat-form-field class="card_input" appearance="fill">
                    <mat-label>Comida de Classificação</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let food of obj_product.classification_food" (removed)="remove(food)">
                            {{food.name}}
                            <button mat-button matChipRemove class="btn_chip">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="Digite..." [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                </mat-form-field>
            </div>

            <div class="w_100 card_table_nutrition">
                <div class="title"> Tabela Nutricional </div>
                <div class="card_row">
                    <h4 style="width: 50%; text-align: left;"> Nome </h4>
                    <h4 style="width: 25%; text-align: center;"> Porcentagem </h4>
                    <h4 style="width: 25%; text-align: center;"> Valor </h4>
                </div>
                <div *ngFor="let nutrition of obj_product.table_nutrition" class="card_row">
                    <p> {{ nutrition.name }} </p>
                    <input matInput type="text" [(ngModel)]="nutrition.quant">
                    <input matInput type="text" [(ngModel)]="nutrition.value">
                </div>
            </div>
        </div>

        <div style="width: 100%; margin-top: 40px; margin-bottom: 5px;">
            <button mat-button class="main-button button" [style.background]="color_primary" (click)="close()">
                Fechar
            </button>

            <button mat-button class="main-button button" [style.background]="color_primary" (click)="save()">
                {{ text_button }}
            </button>
        </div>
    </div>
</div>