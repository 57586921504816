<div class="screen">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start; margin: 0 auto;">
        <div style="display: flex; flex-direction: row;">
            <div style="width: 30%; min-width: 255px;">
                <h3> Selecione a enquete </h3>
    
                <mat-select [(ngModel)]="id_question" class="select-box" style="margin-right: 10px;">
                    <mat-option *ngFor="let item of list_question; let i = index" [value]="item.id" (click)="selectQuestion(item)">
                        {{ item.description }}
                    </mat-option>
                </mat-select>
            </div>

            <div style="width: 30%; min-width: 255px; margin-left: 10px;">
                <h3> Selecione uma campanha </h3>
    
                <mat-select [(ngModel)]="id_campaign" class="select-box" style="margin-right: 10px;">
                    <mat-option *ngFor="let item of list_campaign; let i = index" [value]="item.id" (click)="selectCampaign(item.id)">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </div>
    
            <!-- <div style="margin-left: 10px;">
                <h3> Selecione um periodo </h3>
                <mat-form-field color="accent" style="width: 100%; padding: 0 20px;" class="input" >
                    <mat-date-range-input [rangePicker]="picker1">
                      <input matStartDate [(ngModel)]="date.start_date" readonly>
                      <input matEndDate [(ngModel)]="date.end_date" readonly (dateInput)="addEvent($event, date)">
                    </mat-date-range-input>
    
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker1></mat-date-range-picker>
                </mat-form-field>
            </div> -->
        </div>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <button mat-button style="display: flex; align-items: center; justify-content: space-evenly;"
                (click)="selectCampaign(id_campaign)">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>
        </div>
    </div>

    <div *ngIf="!loading">
        <div *ngIf="checkList.length <= 0"
            style="width: 100%; text-align: center; font-size: 18px; padding: 15px 0;">
            Nenhuma informação encontrada para essa campanha.
        </div>

        <div *ngIf="checkList.length > 0" style="width: 100%;">
            <div class="div-border" >
                <div class="title"> {{ question?.description }} </div>

                <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;">
                    <div class="graphics" id="chart-survey"> </div>
                    <!-- <div class="graphics" id="chart-survey-register"> </div> -->
                </div>
            </div>
        </div>

        <div class="list_user">
            <h3> Usuários cadastrados que responderam </h3>
    
            <table mat-table [dataSource]="dataSource" matSort>
              
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde" mat-sort-header> Nome </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="div-borde"> {{ element.user }} </div>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="answer">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde" mat-sort-header> Resposta </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="div-borde"> {{ element.answer }} </div>
                    </td>
                </ng-container>
              
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde" mat-sort-header> Data </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="div-borde"> {{ element.date | date: 'dd/MM/yyyy hh:mm' }} </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
                </tr>
            </table>
        </div>
        
    </div>
    
    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
    </div>

</div>