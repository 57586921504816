export class AppConfig {

	public static get path(): string { 
		// return "http://192.168.1.116:8080/";
		return "https://api.sbsolutions.com.br/"
		// return "http://sbsolutions-api-dev.sa-east-1.elasticbeanstalk.com/"
    }

	public static get qtd_homepage_types() { 
		return 10;
    }

	public static get qtd_page_types() { 
		return 15;
    }

	public static get hashlarItemsHomepage(): number[] {
		return [2692, 2766]
	}

	public static get hashlarItemsPage(): number[] {
		return [3151, 3183]
	}

	public static get hashlarItemsInternalPage(): number[] {
		return []
	}

}