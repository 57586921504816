import { Injectable } from "@angular/core";

// fire-extinguisher
import { getExternoExtintoresConfig } from "../file-screen/config-import/fire-extinguisher/externoConfig";
import { getEnergyCenterConfig } from "../file-screen/config-import/fire-extinguisher/energyCenterConfig";
import { getRestaurantConfig } from "../file-screen/config-import/fire-extinguisher/restaurantConfig";
import { getReservationConfig } from "../file-screen/config-import/fire-extinguisher/reservationConfig";
import { getAssemblyProductionConfig } from "../file-screen/config-import/fire-extinguisher/assemblyProductionConfig";
import { getPdiConfig } from "../file-screen/config-import/fire-extinguisher/pdiConfig";
import { getPaintConfig } from "../file-screen/config-import/fire-extinguisher/paintConfig";
import { getBodyConfig } from "../file-screen/config-import/fire-extinguisher/bodyConfig";
// import { getFormBasisConfig } from "../file-screen/config-import/fire-extinguisher/bodyConfig";


// hydrant
import { getBodyRestaurantConfig } from "../file-screen/config-import/hydrant/bodyRestaurantConfig";
import { getPdiConfig_2 } from "../file-screen/config-import/hydrant/pdiConfig";
import { getPaint_2Config } from "../file-screen/config-import/hydrant/paintConfig";
import { getAssemblyConfig } from "../file-screen/config-import/hydrant/assemblyProductionConfig";
import { getEnergyCenter_2Config } from "../file-screen/config-import/hydrant/energyCenterConfig";
import { getMatrizConfig } from "../file-screen/config-import/hydrant/matrizConfig";

// alarm
import { getExternoAlarmConfig } from "../file-screen/config-import/alarm/externoConfig";
import { getEnergyCenterAlarmConfig } from "../file-screen/config-import/alarm/energyCenterConfig";
import { getAlarmConfig } from "../file-screen/config-import/alarm/alarmConfig";

// vg
import { getExternoVgConfig } from "../file-screen/config-import/vg/externoConfig";
import { getEnergyCenterVgConfig } from "../file-screen/config-import/vg/energyCenterConfig";
import { getVgConfig } from "../file-screen/config-import/vg/vgConfig";

// paint
import { getExternoPaintConfig } from "../file-screen/config-import/paint/externoConfig";
import { getEnergyCenterPaintConfig } from "../file-screen/config-import/paint/energyCenterConfig";
import { getPaintCO2Config } from "../file-screen/config-import/paint/paintConfig";

// gas
import { getExternoGasFMConfig } from "../file-screen/config-import/gas_fm/externoConfig";
import { getEnergyCenterGasFMConfig } from "../file-screen/config-import/gas_fm/energyCenterConfig";
import { getGasFMConfig } from "../file-screen/config-import/gas_fm/gasFMConfig";

// emergency
import { getExternoEmergencyConfig } from "../file-screen/config-import/emergency/externoConfig";
import { getEnergyCenterEmergencyConfig } from "../file-screen/config-import/emergency/energyCenterConfig";
import { getEmergencyConfig } from "../file-screen/config-import/emergency/emergencyConfig";
import { getGasEaselsConfig } from "../file-screen/config-import/gas-easelsConfig";

// pump house
import { getPumpHouseConfig } from "../file-screen/config-import/pump-house/headerConfig";
import { getPage1Config } from "../file-screen/config-import/pump-house/table1Config";
import { getPage2Config } from "../file-screen/config-import/pump-house/table2Config";
import { getPage3Config } from "../file-screen/config-import/pump-house/table3Config";
import { getVGsMinimaxConfig } from "../file-screen/config-import/VGs-MinimaxConfig";

@Injectable({
  providedIn: "root",
})
export class ConfigImportService {
  constructor() {}

  setfireExtinguisherChecklist(importer) {
    var list_abas_excel = [];

    const configExterno = getExternoExtintoresConfig();
    var externo: any = this.importGeneral(importer, configExterno);
    externo = this.clearFile(externo.items, externo, "externo");
    list_abas_excel.push(
      this.setListAbasExcel(externo, null, "Checklist Extintores", null)
    );

    const configEnergyCenter = getEnergyCenterConfig();
    var energyCenter: any = this.importGeneral(importer, configEnergyCenter);
    energyCenter = this.clearFile(
      energyCenter.items,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Extintores", null)
    );

    const configRestaurant = getRestaurantConfig();
    var restaurant: any = this.importGeneral(importer, configRestaurant);
    restaurant = this.clearFile(restaurant.items, restaurant, "restaurant");
    list_abas_excel.push(
      this.setListAbasExcel(restaurant, null, "Checklist Extintores", null)
    );

    const configReservation = getReservationConfig();
    var reservation: any = this.importGeneral(importer, configReservation);
    reservation = this.clearFile(reservation.items, reservation, "reservation");
    list_abas_excel.push(
      this.setListAbasExcel(reservation, null, "Checklist Extintores", null)
    );

    const configAssemblyProduction = getAssemblyProductionConfig();
    var assembly: any = this.importGeneral(importer, configAssemblyProduction);
    assembly = this.clearFile(assembly.items, assembly, "assemblyProduction");
    list_abas_excel.push(
      this.setListAbasExcel(assembly, null, "Checklist Extintores", null)
    );

    const configPdi = getPdiConfig();
    var pdi: any = this.importGeneral(importer, configPdi);
    pdi = this.clearFile(pdi.items, pdi, "pdi");
    list_abas_excel.push(
      this.setListAbasExcel(pdi, null, "Checklist Extintores", null)
    );

    const configPaint = getPaintConfig();
    var paint: any = this.importGeneral(importer, configPaint);
    paint = this.clearFile(paint.items, paint, "paint");
    list_abas_excel.push(
      this.setListAbasExcel(paint, null, "Checklist Extintores", null)
    );

    const configBody = getBodyConfig();
    var body: any = this.importGeneral(importer, configBody);
    body = this.clearFile(body.items, body, "body");
    list_abas_excel.push(
      this.setListAbasExcel(body, null, "Checklist Extintores", null)
    );

    return list_abas_excel;
  }

  setHydrantChecklist(importer) {
    var list_abas_excel = [];
    console.log("setHydrantChecklist");
    // BODY
    const configBodyRestaurant = getBodyRestaurantConfig();
    var bodyRestaurant: any = this.importGeneral(
      importer,
      configBodyRestaurant
    );
    bodyRestaurant = this.joinListFile(bodyRestaurant, [
      bodyRestaurant.items,
      bodyRestaurant.items_2,
    ]);
    bodyRestaurant = this.clearFile(
      bodyRestaurant.items,
      bodyRestaurant,
      "bodyRestaurant"
    );
    list_abas_excel.push(
      this.setListAbasExcel(bodyRestaurant, null, "Checklist Hidrante", null)
    );

    // PDI
    const configPdi2 = getPdiConfig_2();
    var pdi: any = this.importGeneral(importer, configPdi2);
    pdi = this.clearFile(pdi.items, pdi, "pdi");
    list_abas_excel.push(
      this.setListAbasExcel(pdi, null, "Checklist Hidrante", null)
    );

    // PAINT
    const configPaint2 = getPaint_2Config();
    var paint: any = this.importGeneral(importer, configPaint2);
    paint = this.joinListFile(paint, [paint.items, paint.items_2]);
    paint = this.clearFile(paint.items, paint, "paint");
    list_abas_excel.push(
      this.setListAbasExcel(paint, null, "Checklist Hidrante", null)
    );

    // ASSEMBLY Table 1
    const configAssembly2 = getAssemblyConfig();
    var assembly_1: any = this.importGeneral(importer, configAssembly2);
    assembly_1 = this.clearFile(assembly_1.items_1, assembly_1, "assembly");
    list_abas_excel.push(
      this.setListAbasExcel(assembly_1, null, "Checklist Hidrante", 1)
    );

    // ASSEMBLY Table 2
    var assembly_2: any = this.importGeneral(importer, configAssembly2);
    assembly_2 = this.clearFile(assembly_2.items_2, assembly_2, "assembly");
    list_abas_excel.push(
      this.setListAbasExcel(assembly_2, null, "Checklist Hidrante", 2)
    );

    // ENERGY CENTER
    const configAEnergyCenter2 = getEnergyCenter_2Config();
    var energyCenter: any = this.importGeneral(importer, configAEnergyCenter2);
    energyCenter = this.clearFile(
      energyCenter.items,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Hidrante", null)
    );

    return list_abas_excel;
  }

  setAlarmlist(importer) {
    var list_abas_excel = [];

    // EXTERNO
    const configExterno = getExternoAlarmConfig();
    var externo: any = this.importGeneral(importer, configExterno);
    externo.header.place = "Externo";
    externo = this.clearFile(externo.items, externo, "externo");
    list_abas_excel.push(
      this.setListAbasExcel(externo, null, "Checklist Alarme", null)
    );

    // ENERGY Table 1
    const configEnergyCenter = getEnergyCenterAlarmConfig();
    var energyCenter: any = this.importGeneral(importer, configEnergyCenter);
    energyCenter.header.place = "Energy Center";
    energyCenter = this.clearFile(
      energyCenter.items_1,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 1)
    );

    // ENERGY Table 2
    var energyCenter: any = this.importGeneral(importer, configEnergyCenter);
    energyCenter.header.place = "Energy Center";
    energyCenter = this.clearFile(
      energyCenter.items_2,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 2)
    );

    // ALARMES Table 1 - CONTEM COLUNAS IGUAIS E NÃO MOSTRA NA TELA TODOS AS COLUNAS DUPLICADAS
    const configAlarm = getAlarmConfig();
    var energyCenter: any = this.importGeneral(importer, configAlarm);
    energyCenter = this.clearFile(
      energyCenter.items_1,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 1)
    );

    // ALARMES Table 2 - CONTEM COLUNAS IGUAIS E NÃO MOSTRA NA TELA TODOS AS COLUNAS DUPLICADAS
    var energyCenter: any = this.importGeneral(importer, configAlarm);
    energyCenter = this.clearFile(
      energyCenter.items_2,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 2)
    );

    // ALARMES Table 3 - CONTEM COLUNAS IGUAIS E NÃO MOSTRA NA TELA TODOS AS COLUNAS DUPLICADAS
    var energyCenter: any = this.importGeneral(importer, configAlarm);
    energyCenter = this.clearFile(
      energyCenter.items_3,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 3)
    );

    // ALARMES Table 4 - CONTEM COLUNAS IGUAIS E NÃO MOSTRA NA TELA TODOS AS COLUNAS DUPLICADAS
    var energyCenter: any = this.importGeneral(importer, configAlarm);
    energyCenter = this.clearFile(
      energyCenter.items_4,
      energyCenter,
      "energyCenter"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energyCenter, null, "Checklist Alarme", 4)
    );

    return list_abas_excel;
  }

  setVgList(importer) {
    var list_abas_excel = [];

    // EXTERNO
    const configExterno = getExternoVgConfig();
    console.log(configExterno);
    
    var externo: any = this.importGeneral(importer, configExterno);
    console.log('1--', externo);
    externo.observation = this.setObservation(externo.observation)
    console.log('2--', externo);
    
    externo.header.place = "EXTERNO";
    externo = this.clearFile(externo.items, externo, "externo");
    console.log('3--', externo);

    list_abas_excel.push(
      this.setListAbasExcel(externo, null, "Checklist VG", null)
    );

    // ENERGY CENTER Table 1
    const configEnergyCenter = getEnergyCenterVgConfig();
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_1,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Checklist VG", 1)
    );

    // ENERGY CENTER Table 1
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_2,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Checklist VG", 2)
    );

    // ENERGY CENTER Table 1
    const configVg = getVgConfig();
    var vg: any = this.importGeneral(importer, configVg);
    vg = this.clearFile(vg.items, vg, "vg");
    list_abas_excel.push(this.setListAbasExcel(vg, null, "Checklist VG", null));

    return list_abas_excel;
  }

  setPaintList(importer) {
    var list_abas_excel = [];

    // EXTERNO
    const configExterno = getExternoPaintConfig();
    var externo: any = this.importGeneral(importer, configExterno);
    externo.header.place = "EXTERNO";
    externo = this.clearFile(externo.items, externo, "externo");
    list_abas_excel.push(this.setListAbasExcel(externo, null, "Pintura", null));

    // ENERGY CENTER Table 1
    const configEnergyCenter = getEnergyCenterPaintConfig();
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_1,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Pintura", 1)
    );

    // ENERGY CENTER Table 2
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_2,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Pintura", 2)
    );

    // C02
    const configPint = getPaintCO2Config();
    var paint: any = this.importGeneral(importer, configPint);
    paint = this.clearFile(paint.items, paint, "paint");
    list_abas_excel.push(this.setListAbasExcel(paint, null, "Pintura", null));

    return list_abas_excel;
  }

  setGasFmList(importer) {
    var list_abas_excel = [];

    // EXTERNO
    const configExterno = getExternoGasFMConfig();
    var externo: any = this.importGeneral(importer, configExterno);
    externo.header.place = "EXTERNO";
    externo = this.clearFile(externo.items, externo, "externo");
    list_abas_excel.push(this.setListAbasExcel(externo, null, "Gás FM", null));

    // ENERGY CENTER Table 1
    const configEnergyCenter = getEnergyCenterGasFMConfig();
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_1,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Gás FM", 1)
    );

    // ENERGY CENTER Table 2
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_2,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Gás FM", 2)
    );

    // C02
    const configPint = getGasFMConfig();
    var paint: any = this.importGeneral(importer, configPint);
    paint = this.clearFile(paint.items, paint, "paint");
    list_abas_excel.push(this.setListAbasExcel(paint, null, "Gás FM", null));

    return list_abas_excel;
  }

  setEmergencyList(importer) {
    var list_abas_excel = [];

    // EXTERNO
    const configExterno = getExternoEmergencyConfig();
    var externo: any = this.importGeneral(importer, configExterno);
    externo.header.place = "EXTERNO";
    externo = this.clearFile(externo.items, externo, "externo");
    list_abas_excel.push(
      this.setListAbasExcel(externo, null, "Saídas Emergências", null)
    );

    // ENERGY CENTER Table 1
    const configEnergyCenter = getEnergyCenterEmergencyConfig();
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_1,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Saídas Emergências", 1)
    );

    // ENERGY CENTER Table 2
    var energy_center: any = this.importGeneral(importer, configEnergyCenter);
    energy_center.header.place = "ENERGY CENTER";
    energy_center = this.clearFile(
      energy_center.items_2,
      energy_center,
      "energy_center"
    );
    list_abas_excel.push(
      this.setListAbasExcel(energy_center, null, "Saídas Emergências", 2)
    );

    // EMERGENCY Table 1
    const configEmergency = getEmergencyConfig();
    var emergency: any = this.importGeneral(importer, configEmergency);
    emergency = this.clearFile(emergency.items_1, emergency, "emergency");
    list_abas_excel.push(
      this.setListAbasExcel(emergency, null, "Saídas Emergências", 2)
    );

    // EMERGENCY Table 2
    var emergency: any = this.importGeneral(importer, configEmergency);
    emergency = this.clearFile(emergency.items_2, emergency, "emergency");
    list_abas_excel.push(
      this.setListAbasExcel(emergency, null, "Saídas Emergências", 2)
    );

    var emergency: any = this.importGeneral(importer, configEmergency);
    emergency = this.clearFile(emergency.items_1, emergency, "emergency");
    list_abas_excel.push(
      this.setListAbasExcel(emergency, null, "Saídas Emergências", 1)
    );

    return list_abas_excel;
  }

  setGasEaselsList(importer) {
    var list_abas_excel = [];

    // List Dias
    const configEasels = getGasEaselsConfig();
    var list_days: any = this.importGeneral(importer, configEasels);
    list_days = this.joinListFile(list_days, [
      list_days.days_1,
      list_days.days_2,
      list_days.days_3,
      list_days.days_4,
    ]);
    list_abas_excel.push(
      this.setListAbasExcel(list_days, null, "Cavaletes Gases", null)
    );

    // Verifications
    var verifications: any = this.importGeneral(importer, configEasels);
    verifications = this.joinListFile(verifications, [
      [{ value: "Descrição", status: "Status" }],
      verifications.verifications_list_1,
      verifications.verifications_list_2,
    ]);
    verifications = this.setList(verifications, verifications.items);
    list_abas_excel.push(
      this.setListAbasExcel(verifications, null, "Cavaletes Gases", null)
    );

    return list_abas_excel;
  }

  setPumpHouseList(importer) {
    var list_abas_excel = [];

    // List Dias
    const configHeader = getPumpHouseConfig();
    var header: any = this.importGeneral(importer, configHeader);
    header = [header.coll_header[0], header.header];

    // PAGE 1

    // Table_1
    const configTable_1 = getPage1Config();
    var table_1: any = this.importGeneral(importer, configTable_1);
    table_1 = this.setTable(table_1.header_table_1, table_1.table_1, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_1, null, "Casa de Bombas", null)
    );

    // Table_2
    const configTable_2 = getPage1Config();
    var table_2: any = this.importGeneral(importer, configTable_2);
    table_2 = this.setTable(table_2.header_table_2, table_2.table_2, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_2, null, "Casa de Bombas", null)
    );

    // Table_3
    const configTable_3 = getPage1Config();
    var table_3: any = this.importGeneral(importer, configTable_3);
    table_3 = this.setTable(table_3.header_table_3, table_3.table_3, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_3, null, "Casa de Bombas", null)
    );

    // Table_4
    const configTable_4 = getPage1Config();
    var table_4: any = this.importGeneral(importer, configTable_4);
    table_4 = this.setTable(table_4.header_table_4, table_4.table_4, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_4, null, "Casa de Bombas", null)
    );

    // Table_5
    const configTable_5 = getPage1Config();
    var table_5: any = this.importGeneral(importer, configTable_5);
    table_5 = this.setTable(table_5.header_table_5, table_5.table_5, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_5, null, "Casa de Bombas", null)
    );

    // Table_6
    const configTable_6 = getPage1Config();
    var table_6: any = this.importGeneral(importer, configTable_6);
    table_6 = this.setTable(table_6.header_table_6, table_6.table_6, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_6, null, "Casa de Bombas", null)
    );

    // Table_7
    const configTable_7 = getPage1Config();
    var table_7: any = this.importGeneral(importer, configTable_7);
    table_7 = this.setTable(table_7.header_table_7, table_7.table_7, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_7, null, "Casa de Bombas", null)
    );

    // Table_8
    const configTable_8 = getPage1Config();
    var table_8: any = this.importGeneral(importer, configTable_8);
    table_8 = this.setTable(table_8.header_table_8, table_8.table_8, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_8, null, "Casa de Bombas", null)
    );

    // Table_9
    const configTable_9 = getPage1Config();
    var table_9: any = this.importGeneral(importer, configTable_9);
    table_9 = this.setTable(table_9.header_table_9, table_9.table_9, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_9, null, "Casa de Bombas", null)
    );

    // Table_10
    const configTable_10 = getPage1Config();
    var table_10: any = this.importGeneral(importer, configTable_10);
    table_10 = this.setTable(
      table_10.header_table_10,
      table_10.table_10,
      header
    );
    list_abas_excel.push(
      this.setListAbasExcel(table_10, null, "Casa de Bombas", null)
    );

    // Table_11
    const configTable_11 = getPage1Config();
    var table_11: any = this.importGeneral(importer, configTable_11);
    table_11 = this.setTable(
      table_11.header_table_11,
      table_11.table_11,
      header
    );
    list_abas_excel.push(
      this.setListAbasExcel(table_11, null, "Casa de Bombas", null)
    );

    // Table_12
    const configTable_12 = getPage1Config();
    var table_12: any = this.importGeneral(importer, configTable_12);
    table_12 = this.setTable(
      table_12.header_table_12,
      table_12.table_12,
      header
    );
    list_abas_excel.push(
      this.setListAbasExcel(table_12, null, "Casa de Bombas", null)
    );

    // PAGE 2

    // Table_1
    const config2Table_1 = getPage2Config();
    var table_1: any = this.importGeneral(importer, config2Table_1);
    table_1 = this.setTable(table_1.header_table_1, table_1.table_1, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_1, null, "Casa de Bombas", null)
    );

    // Table_2
    const config2Table_2 = getPage2Config();
    var table_2: any = this.importGeneral(importer, config2Table_2);
    table_2 = this.setTable(table_2.header_table_2, table_2.table_2, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_2, null, "Casa de Bombas", null)
    );

    // Table_3
    const config2Table_3 = getPage2Config();
    var table_3: any = this.importGeneral(importer, config2Table_3);
    table_3 = this.setTable(table_3.header_table_3, table_3.table_3, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_3, null, "Casa de Bombas", null)
    );

    // Table_4
    const config2Table_4 = getPage2Config();
    var table_4: any = this.importGeneral(importer, config2Table_4);
    table_4 = this.setTable(table_4.header_table_4, table_4.table_4, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_4, null, "Casa de Bombas", null)
    );

    // Table_5
    const config2Table_5 = getPage2Config();
    var table_5: any = this.importGeneral(importer, config2Table_5);
    table_5 = this.setTable(table_5.header_table_5, table_5.table_5, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_5, null, "Casa de Bombas", null)
    );

    // Table_6
    const config2Table_6 = getPage2Config();
    var table_6: any = this.importGeneral(importer, config2Table_6);
    table_6 = this.setTable(table_6.header_table_6, table_6.table_6, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_6, null, "Casa de Bombas", null)
    );

    // PAGE 3

    // Table_1
    const config3Table_1 = getPage3Config();
    var table_1: any = this.importGeneral(importer, config3Table_1);
    table_1 = this.setTableJoinHeader(
      table_1.header_table_1[0],
      table_1.table_1
    );
    table_1 = this.setTable(table_1.header, table_1.table_1, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_1, null, "Casa de Bombas", null)
    );

    // Table_2
    const config3Table_2 = getPage3Config();
    var table_2: any = this.importGeneral(importer, config3Table_2);
    table_2 = this.setTableJoinHeader(
      table_2.header_table_2[0],
      table_2.table_2
    );
    table_2 = this.setTable(table_2.header, table_2.table_2, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_2, null, "Casa de Bombas", null)
    );

    // Table_3
    const config3Table_3 = getPage3Config();
    var table_3: any = this.importGeneral(importer, config3Table_3);
    table_3 = this.setTableJoinHeader(
      table_3.header_table_3[0],
      table_3.table_3
    );
    table_3 = this.setTable(table_3.header, table_3.table_3, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_3, null, "Casa de Bombas", null)
    );

    // Table_4
    const config3Table_4 = getPage3Config();
    var table_4: any = this.importGeneral(importer, config3Table_4);
    table_4 = this.setTableJoinHeader(
      table_4.header_table_4[0],
      table_4.table_4
    );
    table_4 = this.setTable(table_4.header, table_4.table_4, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_4, null, "Casa de Bombas", null)
    );

    // Table_5
    const config3Table_5 = getPage3Config();
    var table_5: any = this.importGeneral(importer, config3Table_5);
    table_5 = this.setTableJoinHeader(
      table_5.header_table_5[0],
      table_5.table_5
    );
    table_5 = this.setTable(table_5.header, table_5.table_5, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_5, null, "Casa de Bombas", null)
    );

    // Table_6
    const config3Table_6 = getPage3Config();
    var table_6: any = this.importGeneral(importer, config3Table_6);
    table_6 = this.setTableJoinHeader(
      table_6.header_table_6[0],
      table_6.table_6
    );
    table_6 = this.setTable(table_6.header, table_6.table_6, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_6, null, "Casa de Bombas", null)
    );

    // Table_7
    const config3Table_7 = getPage3Config();
    var table_7: any = this.importGeneral(importer, config3Table_7);
    table_7 = this.setTableJoinHeader(
      table_7.header_table_7[0],
      table_7.table_7
    );
    table_7 = this.setTable(table_7.header, table_7.table_7, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_7, null, "Casa de Bombas", null)
    );

    // Table_8
    const config3Table_8 = getPage3Config();
    var table_8: any = this.importGeneral(importer, config3Table_8);
    table_8 = this.setTableJoinHeader(
      table_8.header_table_8[0],
      table_8.table_8
    );
    table_8 = this.setTable(table_8.header, table_8.table_8, header);
    list_abas_excel.push(
      this.setListAbasExcel(table_8, true, "Casa de Bombas", null)
    );

    return list_abas_excel;
  }

  setVGsMinimaxList(importer) {
    var list_abas_excel = [];

    // List Dias
    const configHeader = getVGsMinimaxConfig();
    var table_1: any = this.importGeneral(importer, configHeader);
    table_1["items"] = table_1.days_1;
    table_1.items.splice(1, 1);
    list_abas_excel.push(
      this.setListAbasExcel(table_1, null, "VG's Minimax", null)
    );

    // var table_2: any = this.importGeneral(importer, configHeader)
    // table_2['items'] = table_2.days_1;
    // table_2.items.splice(1, 1)
    // list_abas_excel.push(this.setListAbasExcel(table_2, null, "VG's Minimax", null))

    // var table_3: any = this.importGeneral(importer, configHeader)
    // console.log(table_3);
    // table_3['items'] = [{ Questão: 'Questão', Descrição: 'Descrição' }];
    // table_3.items = table_3.items.concat(table_3.verifications_list_1);
    // table_3.items = table_3.items.concat(table_3.verifications_list_2);
    // console.log(table_3);

    list_abas_excel.push(
      this.setListAbasExcel(
        table_1,
        { table_list: false },
        "VG's Minimax",
        null
      )
    );

    return list_abas_excel;
  }

  setListAbasExcel(table, status, type, num) {
    return {
      title: table.header.title != null ? table.header.title : "",
      subtitle: table.header.place != null ? table.header.place : "",
      type: type,
      descriptio_json: this.setDescription(table, num),
      columns_array:
        table.items != null && table.items.length > 0 ? table.items[0] : [],
      list_table:
        table.items != null && table.items.length > 0
          ? status?.table_list
            ? table.items
            : this.setTableList(table.items)
          : [],
      // list_table: (table.items != null && table.items.length > 0 ? this.setTableList(table.items) : []),
      //footer: (table.footer.length > 0 ? table.footer[0] : table.footer),
      footer:
        table.footer != null && table.footer.length > 0
          ? this.setFooterList(table.footer)
          : [],
      description:
        table.description != null && table.description.length > 0
          ? this.setDescriptionList(table.description).list
          : null,
      title_description:
        table.description != null && table.description.length > 0
          ? this.setDescriptionList(table.description).coll_title
          : null,
    };
  }

  setObservation(list){
    let observation = ''
    list.forEach(row => {
      observation = observation + row.text;
    });

    return observation
  }

  setDescription(table, num) {
    if (
      table?.header?.date != null &&
      table?.header?.hour != null &&
      table?.header?.month != null &&
      table?.header?.verifiedBy != null
    ) {
      return {
        Titulo_aba:
          num != null ? `${table.header.place} ${num}` : table.header.place,
        Local: table.header.place,
        Data: table.header.date.split("Data:")[1],
        Hora: table.header.hour.split("Hora:")[1],
        Mês: table.header.month.split("Mês")[1],
        Verificado_por: table.header.verifiedBy.split("Verificado por:")[1],
      };
    } else if (
      table?.header?.verifications != null &&
      table?.header?.daytime != null &&
      table?.header?.other_information != null
    ) {
      return {
        Titulo_aba:
          num != null ? `${table.header.place} ${num}` : table.header.place,
        Local: table.header.place,
        Verificações: table.header.verifications,
        Inspetor: table.header.inspector,
        Mês: table.header.month,
        Diurno: table.header.daytime,
        Lider: table.header.leader,
        Nota: table.header.note,
        Outras_Informações: table.header.verifications.split(
          "OUTRAS INFORMAÇÕES:"
        )[1],
      };
    } else if (
      table?.header?.building != null &&
      table?.header?.daytime_nighttime != null &&
      table?.header?.frequency != null
    ) {
      return {
        title: table.header.title != null ? table.header.title : "",
        Titulo_aba: "Minimax",
        Local: table.header.place,
        Item_de_verificação: table.header.verification_item,
        Frequência: table.header.frequency,
        Mês: table.header.month,
        Prédio: table.header.building,
        Local_: table.header.place_2,
        Período_Diurno_ou_Noturno: table.header.daytime_nighttime,
      };
    } else if (
      table?.footer[0]?.row_1 != null &&
      table?.footer[0]?.row_2 != null &&
      table?.footer[0]?.row_3 != null
    ) {
      return table.footer != null && table.footer.length > 0
        ? this.setFooterList(table.footer)
        : [];
    } else {
      return {
        Titulo_aba:
          num != null ? `${table.header.place} ${num}` : table.header.place,
        Local: table.header.place,
        Observação: table?.observation != null ? table.observation : ''
      };
    }
  }

  setTableList(list_table) {
    var _list = [];

    let keys = Object.keys(list_table[0]);
    let cols = list_table[0];

    list_table.forEach((obj, index) => {
      if (index == 0) return;
      _list[index - 1] = {};

      keys.forEach((key) => {
        _list[index - 1][cols[key]] = obj[key];
      });
    });

    return _list;
  }

  setDescriptionList(list_description) {
    var _list = [];

    list_description.forEach((obj) => {
      if (obj?.name != list_description[0]?.name) {
        _list.push({
          [list_description[0].name]: obj?.name,
          [list_description[0].value]: obj?.value,
        });
      }
    });

    return { list: _list, coll_title: list_description[0] };
  }

  setFooterList(list_footer) {
    let keys = Object.keys(list_footer[0]);
    if (list_footer.length > 2) {
      list_footer.splice(2, 1);
    }
    let cols = list_footer[0];
    var _footer = {};

    list_footer.forEach((obj, index) => {
      if (index == 0) return;

      _footer = {};

      keys.forEach((key) => {
        cols[key] = cols[key].replace(":", "");
        cols[key] = cols[key].replace(".", "");
        cols[key] = cols[key].replace('"', "");
        cols[key] = cols[key].replace('"', "");
        cols[key] = cols[key].replace(" ", "");

        if (key == "place") {
          _footer["Local"] = obj[key] != null ? obj[key] : "";
        } else if (key == "title") {
          _footer["Title"] = obj[key] != null ? obj[key] : "";
        } else {
          _footer[cols[key]] = obj[key] != null ? obj[key] : "";
        }
      });
    });

    return _footer;
  }

  importGeneral(importer: any, config) {
    let res = {} as any;
    Object.keys(config).forEach((key) => {
      let aux = importer.getAllItems(config[key]);
      if (key == "header") {
        res[key] = aux[0];
      } else {
        res[key] = aux;
      }
    });

    return res;
  }

  importMatriz(importer: any) {
    const config = getMatrizConfig();

    const items = importer.getAllItems(config.items);

    return {
      items,
    };
  }

  joinListFile(file, list) {
    let _array = {};
    let _list = [];
    let cols = list[0][0];
    let keys = Object.keys(list[0][0]);

    list.forEach((list_obj) => {
      list_obj.forEach((obj) => {
        if (obj[keys[0]] != "") {
          _array = {};

          keys.forEach((key) => {
            _array[cols[key]] = obj[key] != null ? obj[key] : "";
          });

          _list.push(_array);
        }
      });
    });

    return {
      header: file.header,
      items: _list,
      description: file.description,
      footer: file.footer,
      coll_footer:
        file.footer != null && file.footer.length > 0 ? file.footer[0] : [],
    };
  }

  joinListVgMimax(file, list) {
    let _array = {};
    let _list = [];
    let keys = Object.keys(list[0]);

    list.forEach((list_obj) => {
      list_obj.forEach((obj) => {
        if (obj.name != "") {
          _array = {};

          keys.forEach((key) => {
            _array[obj[key]] = obj[obj[key]] != null ? obj[obj[key]] : "";
          });

          _list.push(_array);
        }
      });
    });

    return {
      header: file.header,
      items: _list,
      description: file.description,
      footer: file.footer,
      coll_footer:
        file.footer != null && file.footer.length > 0 ? file.footer[0] : [],
    };
  }

  setHeader(list_keys, list_cell) {
    var _array = [];

    let keys = Object.keys(list_cell);
    let list = list_cell;

    keys.forEach((key) => {
      if (key == "place") {
        _array["place"] = list[key] != null ? list[key] : "";
      } else if (key == "title") {
        _array["title"] = list[key] != null ? list[key] : "";
      } else {
        _array[list_keys[key]] = list[key] != null ? list[key] : "";
      }
    });

    return _array;
  }

  setList(obj, list) {
    let _list = [];

    list.forEach((item) => {
      let keys = Object.keys(list[0]);

      if (item[keys[0]] != "") {
        _list.push(item);
      }
    });

    return {
      header: obj.header,
      items: _list,
      description: obj.description,
      footer: obj.footer,
      coll_footer:
        obj.footer != null && obj.footer.length > 0 ? obj.footer[0] : [],
    };
  }

  setTable(header, list, footer) {
    return {
      header: header[0],
      items: list,
      description: [],
      footer: footer,
      coll_footer: footer[0],
    };
  }

  setTableJoinHeader(header, list) {
    let title = `${header.title} ${header.title_2} - ${header.title_3}`;
    let place = header.place;

    return {
      header: [{ title: title, place: place }],
      items: list,
      description: [],
      footer: [],
      coll_footer: [],
    };
  }

  clearFile(list_itens, obj, type) {
    let save_next = false;

    let signature = { firefighter: "", GWM_manager: "" };
    let reduced = [];

    if (list_itens != null && list_itens.length > 0) {
      reduced = list_itens.reduce(
        function (acc, item) {
          if (
            !save_next &&
            item.location != "" &&
            item.location != "Elaborado" &&
            item.location != "Descrição" &&
            item.building != "" &&
            item.building != "Elaborado" &&
            item.building != "Descrição"
          ) {
            acc.push(item);
          }
          if (item.location == "Descrição" || item.building == "Descrição") {
            save_next = true;
          } else if (
            item.location == "" &&
            item.manufacturer == "" &&
            item.not_according == "" &&
            item.capacity == item.chassis_number &&
            item.chassis_number != "Bombeiro Responsável"
          ) {
            signature = {
              firefighter: item.capacity,
              GWM_manager: item.service_warranty,
            };
          }

          return acc;
        }.bind(this),
        []
      );
    }

    return {
      header: obj.header,
      observation: obj.observation,
      items: reduced,
      signature: signature,
      description: obj.description,
      footer: obj.footer,
      coll_footer:
        obj.footer != null && obj.footer.length > 0 ? obj.footer[0] : [],
    };
  }

  changeInfoPdf(header, list, type) {
    console.log(type);

    if (type == "Checklist Extintores" || type == "Checklist Hidrante") {
      return this.setListTypeTable(header, list, type);
    } else if (type == "Casa de Bombas") {
      return this.setListTypeBomba(header, list, type);
    } else {
      return this.setListTypeTable(header, list, type);
    }
  }

  setListTypeTable(header, list, type) {
    let list_aba = [];
    let description = JSON.parse(header.description_json);

    list.forEach((aba) => {
      if (aba.title.indexOf("Descrição") == -1) {
        let description_json = JSON.parse(aba.description_json);
        let rows_array = JSON.parse(aba.rows_array);
        // console.log(rows_array);

        let keys = Object.keys(rows_array[0]);

        rows_array.forEach((row, index) => {
          if (
            row[keys[0]].indexOf(keys[0]) != -1 ||
            row[keys[keys.length - 1]].indexOf(" de ") != -1
          ) {
            rows_array.splice(index, 1);
          }
        });

        list_aba.push({
          title: description[aba.spreadsheet_subtitle].Titulo_aba,
          local: description[aba.spreadsheet_subtitle].Local,
          observation: description[aba.spreadsheet_subtitle].Observação,
          header: {
            verification: description[aba.spreadsheet_subtitle].Verificado_por,
            data: description[aba.spreadsheet_subtitle].Data,
            hour: description[aba.spreadsheet_subtitle].Hora,
            mother: description[aba.spreadsheet_subtitle].Mês,
          },
          list: rows_array,
          footer: description_json,
        });
      }
    });

    return list_aba;
  }

  setListTypeBomba(header, list, type) {
    let list_table = [];
    let table_aux = [];
    let new_key: any = {};

    let description = JSON.parse(header.description_json);

    list.forEach((aba) => {
      let result = list_table.filter((el) => el.title == aba.title);
      let table = JSON.parse(aba.rows_array);
      if (table != null && table.length > 0) {
        // Lista de Tabelas
        table.forEach((row, idx) => {
          //tratamento nome de variavel
          
          new_key = {};
          let _title = "";

          let keys = Object.keys(row);
          keys.forEach((aux, idx) => {
            if (idx == 0) {
              _title = keys[idx];
              new_key["NAME"] = row[keys[idx]];
            } else {
              new_key[aux] = row[keys[idx]];
            }
          });
          table[idx] = { title: _title, table: new_key };
        });

        if (result == null || result.length == 0) {
          list_table.push({ title: aba.title, list_table: [{table: table, name_table: (table[0]?.title != null ? table[0].title : '-')}] });
        } else {
          result[0].list_table.push({table: table, name_table: (table[0]?.title != null ? table[0].title : '-')});
        }
      }
    });

    return {
      header: description,
      table: list_table,
    };
  }
}
