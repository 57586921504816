import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { Campaign_qrcode } from 'src/app/model/campaign';
import { StorageService } from 'src/app/service/storage.service';

@Component({
	selector: 'app-view-menu',
	templateUrl: './view-menu.component.html',
	styleUrls: ['./view-menu.component.css']
})
export class ViewMenuComponent implements OnInit {

	@Input() list: any;
	@Input() header: any;
	@Input() preview_campaign: any;
	@Input() type_campaign: any;
	@Input() campaign_id;
	@Input() campaign: Campaign_qrcode;
	@Output() newItemEvent = new EventEmitter();

	constructor(
		private router: Router,
		private storageService: StorageService,
		private _snackBar: MatSnackBar
	) {
		var url = this.router.url;
		this.url_screen = router.url;

		if (url.indexOf("redirect") > 0) {
			// console.log('WEB')
			this.web = true;
			this.phone = false;
		}
		else if (url.indexOf("content") > 0) {
			// console.log('PHONE')
			this.phone = true;
			this.web = false;
		}
	}

	web: boolean = false;
	phone: boolean = false;

	url_screen;
	url = AppConfig.path;

	loading: boolean = true;

	listImage = [];
	catalog;
	obj_array;
	qrcode_id;


	selectedIndex: number = 0;
	selectedSubIndex: number = 0;

	obj_product: any;

	shoppingCart: any;
	qtdItens: number = 0;
	viewShopping: number = 0;
	catalog_obj;
	check_login: boolean = false;
	type_page: string = '';

	hour_now: any;
	list_sections = []

	morning_period: boolean = false;
	afternoon_period: boolean = false;
	night_period: boolean = false;
	dawn_period: boolean = false;

	ngOnInit(): void {
		console.log("-> MENU <-")
		// console.log(this.list);

		this.hour_now = new Date().getHours();
		console.log(this.hour_now);

		if (0 < this.hour_now && this.hour_now <= 5) {
			console.log('Madrugada');
			this.dawn_period = true;
		}
		else if (5 < this.hour_now && this.hour_now < 12) {
			console.log('Manha');
			this.morning_period = true;
		}
		else if (12 <= this.hour_now && this.hour_now < 18) {
			console.log('Tarde');
			this.afternoon_period = true;
		}
		else if (18 <= this.hour_now && this.hour_now < 23) {
			console.log('Noite');
			this.night_period = true;
		}

		this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.shoppingCart = this.storageService.getShoppingCart()

		this.listImage = this.tratamento(this.list)

		console.log(this.catalog);

		setTimeout(() => {
			this.catalog.sections.forEach(section => {
				if (this.checkTimeView(section) != null)
					this.list_sections.push(this.checkTimeView(section))
			});

			console.log(this.list_sections);
		}, 200);

		this.trocaPage(0)
		this.loading = false
	}

	checkTimeView(section) {
		// console.log(section);
		if (this.morning_period && section.morning_period) {
			return section
		}
		else if (this.afternoon_period && section.afternoon_period) {
			return section
		}
		else if (this.night_period && section.night_period) {
			return section
		}
		else if (this.dawn_period && section.dawn_period) {
			return section
		}
		return null
	}

	setIndex(index) {
		this.selectedIndex = index;
	}

	setSubIndex(index) {
		const el: HTMLElement = document.getElementById(`target${index}`);
		el.scrollIntoView();
		this.selectedSubIndex = index;
	}

	back() {
		this.trocaPage(0)
	}

	openProduct(product) {
		this.obj_product = product

		this.trocaPage(4)
	}

	getQtd() {
		return this.storageService.shoppingCart.qtd
	}

	async addNewItem(list) {
		if (list == 'back') {
			setTimeout(() => {
				this.newItemEvent.emit('registerBack');
			}, 10);
		}

		if (this.url_screen != '/application/dashboard') {
			await this.storageService.saveHistoric(this.campaign_id, list.id, (list.homepage_format || list.page_format), 2, this.type_page, this.qrcode_id)
		}


		if (this.preview_campaign) {
			if (list?.type?.name == "Link") {
				if (list.link.substring(0, 4) != 'http') {
					window.open(`http://${list.link}`);
					return true
				}
				else {
					window.open(list.link);
					return true
				}
			}
			else
				this.newItemEvent.emit(list);
		}
		else {
			if (list?.page_format_obj?.name == "Link") {
				window.open(list.page_images[0].link)
			}
			else if (list?.internal_images != null) {
				list.internal_images.forEach(el => {
					if (el.catalog_obj != null) {
						this.newItemEvent.emit(list);
						return true
					}
					if (el.link != null && el.link != "") {
						if (el.link.substring(0, 4) != 'http') {
							window.open(`http://${el.link}`);
							return true
						}
						else {
							window.open(el.link);
							return true
						}
					}
				});
			}
			else
				this.newItemEvent.emit(list);
		}
	}

	openLink(link) {
		if (link != '' && link != null) {
			if (link.substring(0, 4) != 'http') {
				window.open(`http://${link}`);
			}
			else {
				window.open(link);
			}
		}
	}

	tratamento(list) {
		if (this.preview_campaign) {
			if (this.list?.images != undefined) {
				// console.log('5', list.images)
				return list.images;
			}
			else {
				// console.log('null')
				return null
			}
		}
		else {
			if (list?.catalog != null) {
				// console.log('4',list.catalog_obj)
				this.catalog = list.catalog_obj;
				this.type_page = 'home';
				this.obj_array = list;
				return list?.catalog_obj.sections;
			}
			else if (list?.internal_images != undefined) {
				// console.log('3',list.internal_images)
				this.catalog = list.internal_images[0].catalog_obj;
				this.type_page = 'internal';
				this.obj_array = list.internal_images[0];
				return list.internal_images[0].catalog_obj.sections;
			}
			else if (list?.page_images != undefined) {
				// console.log('2',list.page_images)
				this.catalog = list.page_images[0].catalog_obj;
				this.type_page = 'page';
				this.obj_array = list.page_images[0];
				return list.page_images[0].catalog_obj.sections;
			}
			else if (this.list?.home_images != undefined) {
				// console.log('1',list.home_images)
				this.catalog = list.home_images[0].catalog_obj;
				this.type_page = 'home';
				this.obj_array = list.home_images[0];
				return list.home_images[0].catalog_obj.sections;
			}
			else {
				this.catalog = list.catalog_obj;
				// console.log('0',list.catalog_obj)
				this.type_page = 'home';
				this.obj_array = list;
				return list.catalog_obj.sections;
			}
		}

	}

	checkPage(page) {
		if (this.storageService.getPageShoppingCart() == page) {
			return true
		}
		else {
			return false
		}
	}

	trocaPage(page) {
		this.storageService.page_shoppingCart = page;
	}

	addProduct(product) {
		this.storageService.addProduct(product, 1);
		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.openSnackBar("Produto adicionado ao carrinho!", "Fechar")
	}

	finish(newItem) {

		if (newItem == 'LOGIN') {
			this.check_login = true;
		}
		else if (newItem == 'FINISH') {
			this.check_login = false;
		}
		else if (newItem == null) {
			this.qtdItens = 0;
		}
		else {
			this.reset('')
			this.check_login = false;

			if (this.url_screen != '/application/dashboard') {
				this.storageService.saveHistoric(this.campaign_id, this.obj_array.id, (this.obj_array.homepage_format || this.obj_array.page_format), 11, this.type_page, this.qrcode_id)
			}

			// console.log('finish')
			this.trocaPage(0)
			this.newItemEvent.emit('registerBack');
			setTimeout(() => {
				this.trocaPage(0)
				this.newItemEvent.emit('registerBack');
			}, 100);
		}
	}

	reset(newItem) {
		this.qtdItens = 0;
		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.storageService.clear()
	}

	login(newItem) {
		this.trocaPage(1)
		this.check_login = false;
		// console.log(newItem)
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 4000,
		});
	}

}
