import { dateMapper } from "src/app/utils/validator.utils";

export const getBodyRestaurantConfig = () => ({
    header: {
        worksheet: 'BODY RESTAURANTE',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 2, col: 2, key: 'place' },
            { row: 8, col: 1, key: 'verifiedBy' },
            { row: 8, col: 4, key: 'date' },
            { row: 8, col: 7, key: 'hour' },
            { row: 8, col: 9, key: 'month' },
        ],
    },

    items: {
        worksheet: 'BODY RESTAURANTE',
        type: 'list',
        rowOffset: 19,
        endRows: 42,
        // endRows: 57,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'hydrant'},
            { index: 3, key: 'diameter'},
            { index: 4, key: 'adapter'},
            { index: 5, key: 'key'},
            { index: 6, key: 'squirt_F'},
            { index: 7, key: 'squirt_R'},
            { index: 8, key: 'plug'},
            { index: 9, key: 'hose_quantity'},
            { index: 10, key: 'according'},
            { index: 11, key: 'not_according'},
        ],
    },

    items_2: {
        worksheet: 'BODY RESTAURANTE',
        type: 'list',
        rowOffset: 43,
        endRows: 56,
        columns: [
            { index: 1, key: 'location'},
            { index: 2, key: 'hydrant'},
            { index: 3, key: 'diameter'},
            { index: 4, key: 'adapter'},
            { index: 5, key: 'key'},
            { index: 6, key: 'squirt_F'},
            { index: 7, key: 'squirt_R'},
            { index: 8, key: 'plug'},
            { index: 9, key: 'hose_quantity'},
            { index: 10, key: 'according'},
            { index: 11, key: 'not_according'},
        ],
    },

    footer: {
        worksheet: 'BODY RESTAURANTE',
        type: 'list',
        rowOffset: 41,
        endRows: 44,
        columns: [
            { index: 1, key: 'elaborate' },
            { index: 4, key: 'approved' },
            { index: 7, key: 'date', mapper: dateMapper },
            { index: 8, key: 'reference' },
        ],
    },

});