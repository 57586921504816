import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { CatalogService } from '../service/catalog.service';
import { GroupService } from '../service/group.service';
import { StorageService } from '../service/storage.service';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

    constructor(
        private catalogService: CatalogService,
        private modalService: MDBModalService,
        private groupService: GroupService,
        private storageService: StorageService
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalDelet: MDBModalRef;

    displayedColumns: string[] = ['name', 'email', 'view'];
    dataSource = new MatTableDataSource<any>();

    loading: boolean = false;
    group_response; // var to save last get catalogs response

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    id_establishment
    color_primary

    ngOnInit(): void {
        this.id_establishment = localStorage.getItem('id_establishment')

        this.color_primary = this.storageService.establishment.color;

        this.getGroups()
    }
 
    getGroups(){
        this.loading = true;

        this.groupService.getGroups(this.page, this.id_establishment).subscribe(data=>{
            this.group_response = data;
            this.dataSource.data = data.results;
            this.loading = false;

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            this.loading = false;
        })
    }

    nextPage(){
        if(this.group_response.next != null){
            this.page += 1;
            this.getGroups()
        }
    }

    backPage(){
        if(this.group_response.previous != null){
            this.page -= 1;
            this.getGroups()
        }
    }

    deleteGroup(id){
        this.loading = true;
        this.groupService.deleteGroup(id).subscribe(data=>{
            this.getGroups()
            this.loading = false;
        },
        error=>{
            this.loading = false;
        })
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir o grupo',
                text: 'Deseja excluir o grupo '+obj.name+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.deleteGroup(obj.id)
            }
        }); 

    }

}
