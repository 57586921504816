<div class="border-gray space-between-center" style="width: 100%;">
    <h3 style="margin: 0; color: white;"> Criar um alerta (Email) </h3>
</div>

<input mat-input [(ngModel)]="alert.title" [maxlength]="maxCharacters"
    placeholder="Escreva aqui o título..." style="resize: none;"
    [ngStyle]="{'border-color': warningMap.get('title')? 'red' : ''}" class="input">

<!-- <mat-select [(ngModel)]="alert.qrcode" class="input select-box" 
    [ngStyle]="{'border-color': warningMap.get('qrcode')? 'red' : ''}"
    placeholder="Selecione um qrcode..." style="width: 100%;">
    <mat-option *ngFor="let obj of qrcodes; let i = index" 
        (click)="selectQRCode(obj)" [value]="obj.name">
        {{ obj.name }}
    </mat-option>
</mat-select> -->

<mat-select [(ngModel)]="alert.group" class="input select-box" 
    [ngStyle]="{'border-color': warningMap.get('group')? 'red' : ''}"
    placeholder="Selecione um grupo..." style="width: 100%;">
    <mat-option *ngFor="let obj of groups; let i = index" 
    (click)="selectGroup(obj)"[value]="obj.name">
        {{ obj.name }}
    </mat-option>
</mat-select>

<!-- <mat-select [(ngModel)]="alert.user" class="input select-box" 
    [ngStyle]="{'border-color': warningMap.get('user')? 'red' : ''}"
    placeholder="Selecione um usuário..." style="width: 100%;">

</mat-select> -->
            
<div style="display: flex; flex-direction: column;">

    <div class="row_image_date">
        <div class="coll-image input-img" 
            [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}">
    
            <div *ngIf="alert.image == null" class="button-image" (click)="fileImage.click()"> 
                adicione uma imagem 
            </div>
    
            <input type="file" #fileImage style="display: none; " accept="image/*" 
                (change)="onFileChanged($event)">
    
            <img [src]="alert.image" (click)="fileImage.click()" *ngIf="alert.image != null" 
                class="profile-image">
        </div>
        
        <div class="coll-date">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <div class="select-date" style="width: 49%;">
                    <mat-form-field appearance="fill" class="input"
                        [ngStyle]="{'border-color': warningMap.get('start_date')? 'red' : ''}">
        
                        <input matInput [matDatepicker]="picker1" placeholder="Data inicial" 
                            [(ngModel)]="alert.start_date" readonly>
        
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            
                <input mat-input [(ngModel)]="alert.start_hour"  class="input" style="width: 49%;"
                    placeholder="Hora inicial" mask="00:00" [dropSpecialCharacters]="true"
                    [ngStyle]="{'border-color': warningMap.get('start_hour')? 'red' : ''}" >
            </div>
            

            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <div class="select-date" style="width: 49%;">
                    <mat-form-field appearance="fill" class="input"
                        [ngStyle]="{'border-color': warningMap.get('end_date')? 'red' : ''}">
        
                        <input matInput [matDatepicker]="picker2" placeholder="Data final" 
                            [(ngModel)]="alert.end_date" readonly>
        
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            
                <input mat-input [(ngModel)]="alert.end_hour" class="input" style="width: 49%;"
                    placeholder="Hora final" mask="00:00" [dropSpecialCharacters]="true"
                    [ngStyle]="{'border-color': warningMap.get('end_hour')? 'red' : ''}" >
            </div>

            <input mat-input type="number" [(ngModel)]="alert.notification" class="input" style="width: 100%;"
                matTooltip="Para enviar o email 15 minutos antes. Ex: 15"
                placeholder="Tempo em minutos para envio do email"
                [ngStyle]="{'border-color': warningMap.get('notification')? 'red' : ''}" >
        </div>
    </div>

    <!-- <textarea mat-input [(ngModel)]="alert.subject" [maxlength]="maxCharacters"
        placeholder="Escreva aqui o assunto..." style="resize: none;"
        [ngStyle]="{'border-color': warningMap.get('messenger')? 'red' : ''}" class="input"></textarea> -->

    <textarea mat-input [(ngModel)]="alert.messenger" [maxlength]="maxCharacters"
        placeholder="Escreva aqui a mensagem..." style="resize: none;"
        [ngStyle]="{'border-color': warningMap.get('messenger')? 'red' : ''}" class="input"></textarea>
            
</div>


<div class="border-gray space-between-center" style="width: 100%;">
    <h3 style="margin: 0; color: white;"> Agendar emails na semana </h3>
</div>

<mat-tab-group *ngIf="selected_tab != null" [(selectedIndex)]="selected_tab">
    <mat-tab label="Segunda" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day0')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Terça" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day1')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Quarta" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day2')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Quinta" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day3')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Sexta" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day4')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Sábado" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day5')? 'red' : ''}"> </mat-tab>

    <mat-tab label="Domingo" style="border-bottom: 1px solid transparent;"
        [ngStyle]="{'border-color': warningMap.get('day6')? 'red' : ''}"> </mat-tab>

</mat-tab-group>

<div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">
    <div style="width: 49%;"> 
        <div class="input" style="width: 100%;">
            <mat-slide-toggle class="options"
                [(ngModel)]="alert.reminder[selected_tab].active" (click)="checkSlider(alert.reminder[selected_tab], !alert.reminder[selected_tab].active)"> Lembrar </mat-slide-toggle>
        </div>
        
        <div style="display: flex; margin-top: 10px;">
            <button mat-button class="button-cor" [style.background]="color_primary" (click)="addHour()"> Adicionar horário </button>
        </div>
    </div>
    
   <div style="width: 49%; display: flex; flex-direction: column;">

    <div *ngFor="let obj of alert.reminder[selected_tab]?.hours; let i = index" 
        style="width: 100%; display: flex; justify-content: space-between; align-items: center;">

        <div class="input" [ngStyle]="{'border-color': warningMap.get('hour'+selected_tab+i)? 'red' : ''}">

            <input mat-input [(ngModel)]="obj.hour" style="width: calc(100% - 20px);"
                placeholder="Digite o horário (hh:mm)" mask="00:00" [dropSpecialCharacters]="true">

            <img src="../assets/icon/close.png" (click)="deletHour(i)"
                style="width: 15px; height: 15px; object-fit: contain; cursor: pointer;">
                
        </div>

    </div>

   </div>

</div>

<div style="display: flex; justify-content: flex-end; margin: 10px 0 0 0;">
    <button mat-button class="button-cor" [style.background]="color_primary" (click)="check()"> Salvar </button>
</div>
