import { SpreadsheetTableComponent } from './file-screen/spreadsheet-table/spreadsheet-table.component';
import { SpreadsheetComponent } from './file-screen/spreadsheet/spreadsheet.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignComponent } from './campaign/campaign.component';
import { CatalogDetailComponent } from './catalogs/catalog-detail/catalog-detail.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { CatalogViewComponent } from './catalogs/catalog-view/catalog-view.component';
import { ChannelsComponent } from './channels/channels.component';
import { CouponDetailComponent } from './coupon/coupon-detail/coupon-detail.component';
import { CouponComponent } from './coupon/coupon.component';
import { CreateSurveyDetailComponent } from './create-survey/create-survey-detail/create-survey-detail.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { EmailsDetailComponent } from './channels/emails/emails-detail/emails-detail.component';
import { GroupDetailComponent } from './groups/group-detail/group-detail.component';
import { GroupsComponent } from './groups/groups.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { QrcodesComponent } from './channels/qrcodes/qrcodes.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RegistrationConfigDetailComponent } from './registration-config/registration-config-detail/registration-config-detail.component';
import { RegistrationConfigComponent } from './registration-config/registration-config.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { SmsDetailComponent } from './channels/sms/sms-detail/sms-detail.component';
import { WhatsappDetailComponent } from './channels/whatsapp/whatsapp-detail/whatsapp-detail.component';
import { CreateCampaignComponent } from './campaign/create-campaign/create-campaign.component';
import { DetailCampaignComponent } from './campaign/detail-campaign/detail-campaign.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { SalesComponent } from './sales/sales.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { ProductComponent } from './product/product.component';
import { CategoriesComponent } from './categories/categories.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { DetailProductComponent } from './product/detail-product/detail-product.component';
import { OrderComponent } from './order/order.component';
import { DashboardMidiaComponent } from './dashboard-midia/dashboard-midia.component';
import { SurveyAnswersComponent } from './create-survey/survey-answers/survey-answers.component';
import { DetailCategoryComponent } from './categories/detail-category/detail-category.component';
import { ScreenTagsComponent } from './screen-tags/screen-tags.component';
import { RedirectSbsComponent } from './redirect-sbs/redirect-sbs.component';
import { CreateCampaignFeedComponent } from './campaign/create-campaign-feed/create-campaign-feed.component';
import { PostFeedComponent } from './campaign/post-feed/post-feed.component';
import { DetailSidebarComponent } from './campaign/detail-sidebar/detail-sidebar.component';
import { CourseComponent } from './course/course.component';
import { DetailCourseComponent } from './course/detail-course/detail-course.component';
import { MoreDetailsComponent } from './catalogs/catalog-view/more-details/more-details.component';
import { NoticeComponent } from './notice/notice.component';
import { DetailNoticeComponent } from './notice/detail-notice/detail-notice.component';
import { FileScreenComponent } from './file-screen/file-screen.component';
import { TabloideComponent } from './tabloide/tabloide.component';
import { DetailTabloideComponent } from './tabloide/detail-tabloide/detail-tabloide.component';
import { OrderTabloideComponent } from './order-tabloide/order-tabloide.component';

const routes: Routes = [
	{
		path: '', component: NavbarComponent,
		children: [
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{ path: 'home', component: HomeComponent },
		]
	},
	{ path: 'cliente', component: LoginComponent },
	{ path: 'catalog-view/:id', component: CatalogViewComponent },
	{ path: 'catalog-view/:id/more-details/:produ_id', component: MoreDetailsComponent },
	{
		path: 'application', component: MenuLateralComponent,
		children: [
			{ path: 'dashboard', component: DashboardMidiaComponent },
			{ path: 'content', component: CampaignComponent },
			{ path: 'content/new-content', component: CreateCampaignComponent },
			{ path: 'content/content-feed', component: CreateCampaignFeedComponent },
			{ path: 'content/content-feed/:id', component: CreateCampaignFeedComponent },
			{ path: 'content/content-feed/:id/new-posts', component: PostFeedComponent },
			{ path: 'content/content-feed/:id/posts/:id_post', component: PostFeedComponent },
			{ path: 'content/content-feed/:id/new-sidebar', component: DetailSidebarComponent },
			{ path: 'content/content-feed/:id/sidebar/:id_sidebar', component: DetailSidebarComponent },
			{ path: 'content/:id', component: DetailCampaignComponent },
			{ path: 'catalog', component: CatalogsComponent },
			{ path: 'catalog/new-catalog', component: CatalogDetailComponent },
			{ path: 'catalog/:id', component: CatalogDetailComponent },
			{ path: 'coupon', component: CouponComponent },
			{ path: 'coupon/new-coupon', component: CouponDetailComponent },
			{ path: 'coupon/:id', component: CouponDetailComponent },
			{ path: 'survey', component: CreateSurveyComponent },
			{ path: 'survey/new-survey', component: CreateSurveyDetailComponent },
			{ path: 'survey/:id', component: CreateSurveyDetailComponent },
			{ path: 'survey/answers/:id', component: SurveyAnswersComponent },
			{ path: 'register', component: RegistrationConfigComponent },
			{ path: 'register/new-register', component: RegistrationConfigDetailComponent },
			{ path: 'register/:id', component: RegistrationConfigDetailComponent },
			{ path: 'notice', component: NoticeComponent },
			{ path: 'notice/new-notice', component: DetailNoticeComponent },
			{ path: 'notice/:id', component: DetailNoticeComponent },
			{ path: 'products', component: ProductComponent },
			{ path: 'products/new-products', component: DetailProductComponent },
			{ path: 'products/:id', component: DetailProductComponent },
			{ path: 'tabloide', component: TabloideComponent },
			{ path: 'tabloide/new-tabloide', component: DetailTabloideComponent },
			{ path: 'tabloide/:id', component: DetailTabloideComponent },
			{ path: 'categories', component: CategoriesComponent },
			{ path: 'categories/new-category', component: DetailCategoryComponent },
			{ path: 'categories/:id', component: DetailCategoryComponent },
			{ path: 'payment', component: PaymentOptionsComponent },
			{ path: 'categories/new-category', component: PaymentOptionsComponent },
			{ path: 'categories/:id', component: PaymentOptionsComponent },
			{ path: 'registrations', component: RegistrationsComponent },
			{ path: 'group', component: GroupsComponent },
			{ path: 'group/new-group', component: GroupDetailComponent },
			{ path: 'group/:id', component: GroupDetailComponent },
			{ path: 'channels', component: ChannelsComponent },
			{ path: 'channels/qrcodes', component: QrcodesComponent },
			{ path: 'channels/sms', component: SmsDetailComponent },
			{ path: 'channels/sms/:id', component: SmsDetailComponent },
			{ path: 'channels/whatsapp', component: WhatsappDetailComponent },
			{ path: 'channels/whatsapp/:id', component: WhatsappDetailComponent },
			{ path: 'channels/emails', component: EmailsDetailComponent },
			{ path: 'channels/emails/:id', component: EmailsDetailComponent },
			{ path: 'section', component: ScreenTagsComponent },
			{ path: 'course', component: CourseComponent },
			{ path: 'course/new-course', component: DetailCourseComponent },
			{ path: 'course/:id', component: DetailCourseComponent },
			{ path: 'sales', component: SalesComponent },
			{ path: 'home', component: WelcomeScreenComponent },
			{ path: 'order', component: OrderComponent },
			{ path: 'file', component: FileScreenComponent },
			{ path: 'file/:id', component: SpreadsheetComponent },
			{ path: 'file/:id/:table', component: SpreadsheetTableComponent },
			{ path: 'order-tabloide', component: OrderTabloideComponent },
			{ path: '**', redirectTo: 'home' },
		]
	},
	{ path: 'redirect/:id', component: RedirectComponent },
	{ path: 'redirect-sbs/:id', component: RedirectSbsComponent },
	{ path: '**', redirectTo: 'home' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }
