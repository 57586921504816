import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ProductService } from '../service/product.service';
import { StorageService } from '../service/storage.service';
import { TagService } from '../service/tag.service';
import { DetailTagComponent } from './detail-tag/detail-tag.component';

@Component({
  selector: 'app-screen-tags',
  templateUrl: './screen-tags.component.html',
  styleUrls: ['./screen-tags.component.css']
})
export class ScreenTagsComponent implements OnInit {

    constructor(
        private storageService: StorageService,
        private productService: ProductService,
        private modalService: MDBModalService,
        private tagService: TagService
    ) { }

    modalDelet: MDBModalRef;

    loading: boolean = false;
    displayedColumns: string[] = ['name', 'created', 'view'];
    dataSource = new MatTableDataSource<any>();
    // dataSource = ELEMENT_DATA;

    /* pagination related variables */
    campaign_response;
    num: number;
    page: number = 1
    count_total: number = 0;
    page_count1: number = 0;
    page_count2: number = 0;
    page_size = 10;
    total_results: number;
    color_primary: string = '';

    product_response;
    tags_list = [];

    ngOnInit(): void {
        this.num = 10;
        this.page = 1
        this.total_results = 0;

        this.color_primary = this.storageService.establishment.color;

        this.getTags()
    }

    nextPage(){
        if(this.product_response.next != null){
            this.page += 1;
            this.getTags()
        }
    }

    backPage(){
        if(this.product_response.previous != null){
            this.page -= 1;
            this.getTags()
        }
    }

    orderSection(obj_main, index, type){
        // console.log(obj_main, this.tags_list[index], type);
        
        let obj_aux = this.tags_list[index]

        if(type == 'up'){
            obj_main.order = Number(obj_main.order) + 1;
            obj_aux.order = Number(obj_aux.order) - 1;
        }
        else if(type == 'down'){
            obj_aux.order = Number(obj_aux.order) + 1;
            obj_main.order = Number(obj_main.order) - 1;
        }

        if(obj_aux.order < 0) obj_aux.order = 0;
        if(obj_main.order < 0) obj_main.order = 0;

        this.editOrderProduct(obj_main)
        this.editOrderProduct(obj_aux)
    }

    getTags(){
        this.loading = true;
        this.dataSource.data = []

        this.tagService.getAllTags(this.page).subscribe(data=>{

            this.product_response = data;
            this.dataSource.data = data.results.sort(this.ordenarList)
            this.tags_list = data.results.sort(this.ordenarList)
            
            this.loading = false

            // pagination calc
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            console.log(error)
        })
    }

    ordenarList(a, b) {
        if (a.order < b.order)
            return -1;
        if (a.order > b.order)
            return 1;
        return 0;
    }
    
    editOrderProduct(item){
        this.tagService.editOrderProduct(item).subscribe(data=>{
            this.getTags()
        })
    }

    deleteTag(id){
        this.loading = true;
        this.tagService.deleteTag(id).subscribe(data=>{
            this.loading = false;
            this.page = 1;
            this.getTags()
        })
    }

    detailTag(obj, edit){
    this.modalDelet = this.modalService.show(DetailTagComponent, {
        backdrop: 'static',
        keyboard: false,
        focus: true,
        show: true,
        ignoreBackdropClick: false,
        class: 'modal-dialog-centered',
        containerClass: '',
        animated: false,
        data:{
        tag: obj,
        isEdit: edit,
        }
    })   
    this.modalDelet.content.action.subscribe( (result: any) => { 
        if(result == 'yes'){
        this.getTags()
        }
    }); 
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir categoria',
                text: 'Deseja excluir a categoria '+obj.name+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
            this.deleteTag(obj.id)
            }
        }); 

    }

    convertMoney(money) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(money);
    }

}

export interface PeriodicElement {
    id: number;
    name: string;
    price: number,
    category: string,
    created_at: string;
    active: boolean;
    start_date: string;
    end_date: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {id: 11, name: 'Lorem Ipsum', price: 2.99, category: 'Limpeza', created_at: '2020-10-20', active: true, start_date: '2020-10-20', end_date: '2021-10-20'},
    {id: 12, name: 'Lorem Ipsum', price: 7, category: 'Limpeza', created_at: '2020-10-10', active: true, start_date: '2020-10-10', end_date: '2021-10-10'},
    {id: 8, name: 'Lorem Ipsum', price: 5.39, category: 'Limpeza', created_at: '2020-09-21', active: false, start_date: '2020-09-21', end_date: '2021-09-21'},
    {id: 10, name: 'Lorem Ipsum', price: 3, category: 'Limpeza', created_at: '2020-10-22', active: true, start_date: '2020-10-22', end_date: '2021-10-22'},
    {id: 13, name: 'Lorem Ipsum', price: 4.7, category: 'Limpeza', created_at: '2020-10-06', active: true, start_date: '2020-10-06', end_date: '2021-10-06'},
    {id: 14, name: 'Lorem Ipsum', price: 8.39, category: 'Limpeza', created_at: '2020-05-30', active: false, start_date: '2020-05-30', end_date: '2021-05-30'},
];