import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from '../modals/cell-phone/cell-phone.component';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { RegistrationService } from '../service/registration.service';
import { StorageService } from '../service/storage.service';
import { ModalConfigPreviewComponent } from './modal-config-preview/modal-config-preview.component';

@Component({
    selector: 'app-registration-config',
    templateUrl: './registration-config.component.html',
    styleUrls: ['./registration-config.component.css']
})

export class RegistrationConfigComponent implements OnInit {

    constructor(
        private registrationService: RegistrationService,
        private modalService: MDBModalService,
        private storageService: StorageService
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalDelet: MDBModalRef;
    modalRef: MDBModalRef;

    displayedColumns: string[] = ['name', 'description', 'view'];
    dataSource = new MatTableDataSource<any>();

    loading: boolean = false;
    registration_response; // var to save last get catalogs response

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    id_establishment = null;

    list_registration = [];

    color_primary
    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')

        if(this.id_establishment != null){
            this.getRegistration(1)
        }
    }

    getRegistration(page){
        this.loading = true;

        this.registrationService.getRegistration(page).subscribe(data=>{
            this.list_registration = data.results;
            this.dataSource.data = this.list_registration;
            this.loading = false;
            
            // pagination calc
            this.registration_response = data;
            this.count_total = data.results.length;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            this.loading = false
        })
    }

    nextPage(){
        if(this.registration_response.next != null){
            this.page += 1;
            this.getRegistration(this.page)
        }
    }

    backPage(){
        if(this.registration_response.previous != null){
            this.page -= 1;
            this.getRegistration(this.page)
        }
    }

    deleteConfig(id){
        // this.page = 1;
        // this.loading = true;
        // this.registrationService.deleteRegistration(id).subscribe(data=>{
        //     this.loading = false;
        //     this.getRegistration(this.page)
        // },
        // error=>{
        //     this.loading = false;
        // })
    }

    openPreview(obj){
        this.modalRef = this.modalService.show(CellPhoneComponent, {
            // backdrop: 'static',
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                isList: obj,
                isPopup: 'config'
            }
        })   
        
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir configuração',
                text: 'Deseja excluir a configuração '+obj.title+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.deleteConfig(obj.id)
            }
        }); 

    }

}
