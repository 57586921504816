import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-mini-banner',
    templateUrl: './mini-banner.component.html',
    styleUrls: ['./mini-banner.component.css']
})

export class MiniBannerComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
	@Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    constructor(
        private storageService: StorageService,
        private campaignService: CampaignService,
        private router: Router
    ) { 
        this.url_screen = router.url;
    }

    url_screen;
    listImage;
    loading: boolean = true;
    url = AppConfig.path;
    qrcode_id;

    link_click;
    type_page: string ='';

    ngOnInit(): void {
        // console.log("-> Mini Banner <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null 
            ? this.list.qr_codes[0].qr_code 
            : null;

        this.storageService.screen.format = 'Mini Banner';
        this.storageService.screen.id = this.list.id;

        this.campaignService.watchList().subscribe( data => {
            this.listImage = this.tratamento(this.list)
        })
        
        this.listImage = this.tratamento(this.list)
    }

    addNewItem(list) {
        // mocked HASHLAR payment
        if (
			(list.page && AppConfig.hashlarItemsInternalPage.includes(list.id)) ||
			(list.homepage && AppConfig.hashlarItemsPage.includes(list.id)) ||
			(list.campaign && AppConfig.hashlarItemsHomepage.includes(list.id))
		) {
            console.log('hashlar')
            this.newItemEvent.emit(list);
            return
        }

        if(this.url_screen != '/application/dashboard') {
            this.storageService.saveHistoric(
                this.campaign_id, list.id, 
                (list.homepage_format || list.page_format), 
                2, this.type_page, this.qrcode_id
            )
        }
        
        this.link_click = list
        if(this.preview_campaign){
            if(list?.type?.name == "Link"){
                this.openLink2(list.link)
            }
            if(list?.type?.name == "Vazio"){
                // console.log("-> Vazio <-")
                return
            }
            else
                this.newItemEvent.emit(list);
        }
        else{
            if(list?.page_format_obj?.name == "Link"){
                if(list.page_images != null){
                    this.openLink(list.page_images[0].link)
                }
                else if(list.internal_images != null){
                    this.openLink(list.internal_images[0].link)
                }
                else    
                    this.openLink(list.link)
            }
            else if(list?.page_format_obj?.name == "Vazio"){
                // console.log("-> Vazio <-")
                return
            }
            else if(list?.page_format_obj != null){
                this.newItemEvent.emit(list);
            }
            else if(list?.internal_images != null){
                list.internal_images.forEach(el => {
                    if(el.catalog_obj != null){
                        this.newItemEvent.emit(list);
                    }
                    else if(el.link != null && el.link != ""){
                        this.openLink(el.link)
                    }
                });
            }
            else {
                this.newItemEvent.emit(list);
            }
        }

    }

    openLink2(link){
        this.storageService.saveHistoric(
            this.campaign_id, this.link_click.id, 
            (this.link_click.homepage_format || this.link_click.page_format), 
            5, this.type_page, this.qrcode_id
        )

        if(link.link != null && link.link != '' && link.option_link != null){
            if(link.option_link == ""){
                if(link.link.substring(0, 3) == 'sbs'){
                    this.newItemEvent.emit(`openReport/${link}`);
                }
                else if(link.link.substring(0,4) != 'http'){
                    window.open(`http://${link.link}`);
                }
                else{
                    window.open(link.link);
                }
            }
            else{
                window.open(link.option_link+link.link);
            }
        }
    }

    openLink(link){
        this.storageService.saveHistoric(
            this.campaign_id, this.link_click.id, 
            (this.link_click.homepage_format || this.link_click.page_format), 
            5, this.type_page, this.qrcode_id
        )
        
        if(link != null && link != '') {
            if(link.substring(0, 3) == 'sbs'){
                this.newItemEvent.emit(`openReport/${link}`);
            }
            else if(link.substring(0,3) == 'tel'){
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
            else if(link.substring(0,6) == 'mailto'){
                window.open(link);
            }
            else if(link.substring(0,4) != 'http'){
                var aux_link = link.replace(/\s/g, '');
                window.open(`http://${aux_link}`);
            }
            else{
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
        }
    }

    tratamento(list){        
        if(this.preview_campaign){
            if(this.list?.images != undefined){
                return list.images.sort(this.ordenarList);
            }
            else
                return null
        }
        else{
            if(list?.home_images != undefined){
                this.type_page = 'home';
                return list.home_images.sort(this.ordenarList);
            }
            else if(list?.internal_images != undefined){
                this.type_page = 'internal';
                return list.internal_images.sort(this.ordenarList);
            }
            else if(list?.page_images != undefined){
                this.type_page = 'page';
                return list.page_images.sort(this.ordenarList);
            }
            else if(this.list?.home_images != undefined){
                this.type_page = 'home';
                return list.home_images.sort(this.ordenarList);
            }
            else
                return null
        }
    }

    ordenarList(a,b) {
		if (a.order < b.order)
			return -1;
		if (a.order > b.order)
			return 1;
		return 0;
	}

}
