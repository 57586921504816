<div class="screen">

    <div class="div-loading" *ngIf="loading.establishment && loading.constants">
        <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
    </div>

    <div *ngIf="!loading.establishment" style="display: flex; flex-direction: column;">

        <div class="border-gray space-between-center w_100">
            <h3 style="margin: 0; color: white;"> Informações Principais {{steps.campaign ? ' - FINALIZADO' : ''}} </h3>
        </div>

        <div *ngIf="actual_step == 'campaign'" style="width: 100%; transition: all 0.5s;">

            <input mat-input [(ngModel)]="campaign_name" placeholder="Escreva aqui o nome do conteúdo"
                [ngStyle]="{'border-color': warningMap.get('campaign_name')? 'red' : ''}" class="input">

            <div *ngIf="!loading.establishment"
                style="margin: 10px 0; display: flex; justify-content: flex-start; align-items: center;">
                <h3 style="margin: 0;"> Mostrar seções na campanha? </h3>

                <mat-checkbox style="margin-left: 20px;" [(ngModel)]="show_header">{{ show_header ? 'Ativado' :
                    'Desativado'}}</mat-checkbox>
            </div>

            <span *ngIf="show_header">
                <input mat-input [(ngModel)]="campaign_header"
                    placeholder="Escreva aqui o texto do cabeçalho do conteúdo"
                    [ngStyle]="{'border-color': warningMap.get('campaign_header')? 'red' : ''}" class="input">

                <input mat-input [(ngModel)]="whatsapp" placeholder="Escreva aqui o whatsapp do cabeçalho"
                    [ngStyle]="{'border-color': warningMap.get('whatsapp')? 'red' : ''}" class="input"
                    mask="+00 (00) 0 0000-0000">

                <div style="position: relative; width: 100%;">
                    <img [src]="image_logo.url" (click)="filePages3.click()" class="image_logo"
                        *ngIf="(image_logo.url != '' && image_logo.url != null)">

                    <input mat-input [(ngModel)]="image_logo.name" placeholder="Selecione uma imagem para a logo"
                        (click)="filePages3.click()"
                        [style.paddingLeft]="(image_logo.url != '' && image_logo.url != null) ? '85px' : '20px'"
                        [ngStyle]="{'border-color': warningMap.get('image_logo')? 'red' : ''}" class="input" readonly>

                    <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                        *ngIf="(image_logo.url != '' && image_logo.url != null)" class="btn_delet"
                        (click)="deletLogo()">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                            stroke="none">
                            <path
                                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                        </g>
                    </svg>

                </div>

                <input #filePages3 type="file" accept="image/*" (change)="addLogo($event)" multiple="multiple"
                    style="display: none;">

                <!-- hotfix Lara -->
                <div style="position: relative; width: 100%;">
                    <img [src]="side_menu.url" (click)="filePages4.click()" class="image_logo"
                        *ngIf="(side_menu.url != '' && side_menu.url != null)">

                    <input mat-input [(ngModel)]="side_menu.name"
                        placeholder="Selecione uma imagem para o footer do menu lateral" (click)="filePages4.click()"
                        [style.paddingLeft]="(side_menu.url != '' && side_menu.url != null) ? '85px' : '20px'"
                        [ngStyle]="{'border-color': warningMap.get('side_menu')? 'red' : ''}" class="input" readonly>

                    <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                        *ngIf="(side_menu.url != '' && side_menu.url != null)" class="btn_delet" (click)="deletSide()">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                            stroke="none">
                            <path
                                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                        </g>
                    </svg>
                </div>

                <input #filePages4 type="file" accept="image/*" (change)="addImageSide($event)" multiple="multiple"
                    style="display: none;">
            </span>

            <div *ngIf="!loading.establishment"
                style="margin: 10px 0; display: flex; justify-content: flex-start; align-items: center;">
                <h3 style="margin: 0;"> Campanha de controle de ponto? </h3>

                <mat-checkbox style="margin-left: 20px;" [(ngModel)]="is_point_control">{{ is_point_control ? 'Ativado'
                    :
                    'Desativado'}}</mat-checkbox>
            </div>

            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('qrcode')? 'red' : ''}">
                <mat-label>Selecione um ou mais QRCODES onde este content será publicado</mat-label>

                <mat-chip-list #chipList aria-label="">
                    <mat-chip *ngFor="let item of arrayChips" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeQrCodeChips(item)">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInput placeholder="Digite aqui ..." [formControl]="qrcodeChipsFormControl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>

                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="qrcodeChipsSelected($event)">
                    <mat-option *ngFor="let item of filteredQrcodes | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>


            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('group')? 'red' : ''}">
                <mat-label>Selecione um ou mais Grupos que poderão ver essa campanha
                    <span style="font-size: 12px;">(se não selecionar nenhum ira aparecer pra todos)</span>
                </mat-label>

                <mat-chip-list #groupChipList aria-label="">
                    <mat-chip *ngFor="let item of arrayGroup" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeGroupChips(item)">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputGroup placeholder="Digite aqui ..." [formControl]="groupChipsFormControl"
                        [matAutocomplete]="autoGroup" [matChipInputFor]="groupChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesGroup">
                </mat-chip-list>

                <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="groupChipsSelected($event)">
                    <mat-option *ngFor="let item of filteredGroups | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <div *ngIf="!loading.establishment" style="margin: 10px 0;">
                <h3> Dispositivo que poderá visualizar a campanha?
                    <span style="font-size: 12px;">(se não selecionar nenhum, irá aparecer pra todos)</span>
                </h3>

                <section>
                    <mat-checkbox [(ngModel)]="mobile.android">Android</mat-checkbox>
                    <mat-checkbox [(ngModel)]="mobile.ios">iOS</mat-checkbox>
                </section>
            </div>


            <div *ngIf="!loading.establishment" style="display: flex; flex-wrap: wrap;">

                <div style="width: 31%; min-width: 300px; margin-top: 15px;">
                    <h3> Cor para o fundo do cabeçalho </h3>

                    <div style="display: flex; align-items: center;">
                        <input type="color" [(ngModel)]="color_background"
                            style="width: 80px; height: 36px; margin-right: 10px;">
                        <button mat-button (click)="selected_colors.bg = true" class="button-cor"
                            [style.background]="selected_colors.bg ? color_primary : '#626368'"
                            [ngStyle]="{'border-color': warningMap.get('bg_color')? 'red' : ''}">
                            Já escolhi
                        </button>
                    </div>
                </div>

                <div style="width: 31%; min-width: 300px; margin-top: 15px;">
                    <h3> Cor para o texto do cabeçalho </h3>

                    <div style="display: flex; align-items: center;">
                        <input type="color" [(ngModel)]="color_text"
                            style="width: 80px; height: 36px; margin-right: 10px;">
                        <button mat-button (click)="selected_colors.text = true" class="button-cor"
                            [style.background]="selected_colors.text ? color_primary : '#626368'"
                            [ngStyle]="{'border-color': warningMap.get('text_color')? 'red' : ''}">
                            Já escolhi
                        </button>
                    </div>
                </div>

                <div style="width: 31%; min-width: 300px; margin-top: 15px;">
                    <h3> Cor dos ícones </h3>

                    <div style="display: flex; align-items: center;">
                        <input type="color" [(ngModel)]="color_icons"
                            style="width: 80px; height: 36px; margin-right: 10px;">
                        <button mat-button (click)="selected_colors.icon = true" class="button-cor"
                            [style.background]="selected_colors.icon ? color_primary : '#626368'"
                            [ngStyle]="{'border-color': warningMap.get('icon_color')? 'red' : 'transparent'}">
                            Já escolhi
                        </button>
                    </div>
                </div>
            </div>

            <span *ngIf="show_header">
                <div class="input" style="width: 100%; display: flex; 
                    justify-content: space-between; align-items: center; margin: 20px 0 10px;">

                    <button mat-button class="button-cor" [style.background]="color_primary"
                        (click)="fileInputPdf.click()">
                        Selecione o arquivo
                    </button>

                    <input matInput [(ngModel)]="pdfTerm_name" readonly style="width: calc(100% - 210px);"
                        placeholder="Selecione o arquivo dos termos">

                    <div target="_blank" *ngIf="pdfTerm != null && (pdfTerm?.substring(0,4) == 'http')"
                        (click)="openPdf(pdfTerm)">
                        <svg style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;"
                            viewBox="0 0 1300 1300">
                            <path [style.fill]="color_primary" d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                                -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                                265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                                -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                                -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                                423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
                            <path [style.fill]="color_primary" d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                                -3 97 -22 19 -53 28 -73 21z" />
                            <path [style.fill]="color_primary" d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                                50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
                        </svg>
                    </div>

                    <input type="file" #fileInputPdf style="display: none;" accept="application/pdf"
                        (change)="onFilePdf($event)">
                </div>

            </span>

        </div>

        <div style="width: 100%; display: flex; justify-content: flex-end;">
            <button mat-button class="button-cor" [style.background]="color_primary" (click)="nextStep()"
                *ngIf="actual_step == 'campaign'">
                Ir para próxima etapa
            </button>
        </div>
    </div>

    <div *ngIf="!loading.constants" style="margin: 15px 0;">

        <h3 class="border-gray">
            Clique abaixo em uma das opções para selecionar como será apresentada a página inicial do seu content
            {{steps.homepage ? ' - FINALIZADO' : ''}}
        </h3>

        <div *ngIf="actual_step == 'homepage'" class="space"
            style="display: flex; flex-direction: row; align-items: 
            flex-start; justify-content: flex-start; overflow: hidden; overflow-x: auto; padding: 10px 0 65px 0; margin-bottom: 20px; border: 1px solid transparent;"
            [ngStyle]="{'border-color': warningMap.get('type_home')? 'red' : 'transparent'}">

            <div *ngFor="let item of homepage_types; let i = index">
                <img [src]="item.image " [style.borderColor]="color_primary"
                    [ngClass]="{'select_phone' : (selected_homepage_type == item || selected_homepage_type?.name == item?.name)}"
                    (click)="selectType(item, 'homepage')" class="img-phone" [matTooltip]="item?.name">
            </div>

            <button mat-button (click)="nextStep()" class="button-cor select m_3" [style.background]="color_primary">
                Selecionar
            </button>

        </div>

    </div>

    <div *ngIf="(steps.homepage && actual_step == 'homepage_images') || steps.homepage_images">

        <div class="border-gray space-between-center m_bottom" *ngIf="homepage.type.name != 'Ponto'">
            <h3 style="margin: 0; color: white;">
                {{ homepage.quantity != null ? 'Selecione abaixo as imagens' :
                (homepage.type.name == 'Cursos' ? 'Selecione abaixo o curso desejado' :
                (homepage.type.name == 'Tabloide' ? 'Selecione o tabloide': 'Insira abaixo o link do vídeo')) }}
                {{steps.homepage_images ? ' - FINALIZADO' : ''}}
            </h3>
        </div>

        <div
            *ngIf="homepage.quantity != null && !steps.homepage_images && homepage.type.name != 'Post' && homepage.type.name != 'Ponto'">
            <!-- commit gui -->
            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : ''}">
                <mat-label>Selecione um ou mais Seções </mat-label>

                <mat-chip-list #tagChipListHome aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsHomepage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'home')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagHome" [matChipInputFor]="tagChipListHome"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagHome="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'home')">
                    <mat-option *ngFor="let item of filteredTagHomepage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <div class="card-center">
                <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="drop($event, homepage.images)">
                    <div *ngFor="let image of homepage.images; let i = index" cdkDrag
                        style="display: flex; flex-direction: column; margin: 5px; padding: 5px; border: 1px solid #e9edef;">

                        <img src="/assets/icon/close.png" (click)="removeImage(i, 'home')" *ngIf="image.url != null"
                            style="margin: 5px 5px 0 auto; width: 15px; height: 15px; object-fit: contain; cursor: pointer;">
                        <img [src]="image.url" style="width: 150px; height: 150px; object-fit: contain;"
                            *ngIf="image.url != null">
                        <div class="ellipsis-one" *ngIf="image.url != null"> {{ nameImage(image) }} </div>
                    </div>
                </div>

                <button mat-button class="button-cor" [style.background]="color_primary" (click)="filePages.click()"
                    style="width: auto; height: 36px; margin: auto 0 auto 10px; border: 1px solid transparent;"
                    [ngStyle]="{'border-color': warningMap.get('select_imagens_home')? 'red' : 'transparent'}">
                    Adicionar imagens </button>
                <input #filePages type="file" accept="image/*" (change)="onFileChanged($event, 'pages')"
                    multiple="multiple" style="display: none;">
            </div>

            <div style="width: 100%; display: flex; justify-content: flex-end; margin-top: 15px;">
                <button mat-button class="button-cor" [style.background]="color_primary" (click)="nextStep()"
                    *ngIf="actual_step == 'homepage_images'">
                    Ir para próxima etapa
                </button>
            </div>

        </div>

        <div *ngIf="homepage.quantity != null && !steps.homepage_images && homepage.type.name == 'Post' ">
            <!-- commit gui -->
            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : 'gray'}">
                <mat-label>Selecione um ou mais Seções </mat-label>

                <mat-chip-list #tagChipListHome aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsHomepage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'home')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagHome" [matChipInputFor]="tagChipListHome"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagHome="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'home')">
                    <mat-option *ngFor="let item of filteredTagHomepage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <div style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 20px;">
                <input class="input width_50" style="width: 45%; border-color: transparent"
                    [(ngModel)]="homepage.images[selected_homepage_image].title" placeholder="Digite o titulo">

                <input class="input width_50" style="width: 45%; border-color: transparent"
                    [(ngModel)]="homepage.images[selected_homepage_image].link" placeholder="Digite o Link">

                <textarea class="input"
                    style="width: calc(90% + 10px); resize: none; margin: 21px 0 0 10px !important; border-color: transparent"
                    placeholder="Digite a descrição"
                    [(ngModel)]="homepage.images[selected_homepage_image].description"></textarea>
            </div>

            <div class="card-center" style="margin-bottom: 20px;">
                <img *ngIf="homepage.images[selected_homepage_image]?.url != null"
                    [src]="homepage.images[selected_homepage_image].url" class="main-image">

                <button mat-button class="button-cor height_36_m_0" [style.background]="color_primary"
                    (click)="fileHomepageBg.click()">
                    {{ homepage.url == null? 'Adicionar imagem de fundo' : 'Selecione outra imagem' }} </button>
                <input #fileHomepageBg type="file" accept="image/*" (change)="onFileChanged($event, 'homepage_post')"
                    style="display: none;">
            </div>
        </div>

        <div *ngIf="homepage.type.name == 'Podcast'"
            style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; flex-wrap: wrap; margin-bottom: 20px;">

            <!-- commit gui -->
            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : 'gray'}">
                <mat-label>Selecione um ou mais Seções </mat-label>

                <mat-chip-list #tagChipListHome aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsHomepage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'home')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagHome" [matChipInputFor]="tagChipListHome"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagHome="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'home')">
                    <mat-option *ngFor="let item of filteredTagHomepage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <input class="input width_50" style="border-color: transparent;" [(ngModel)]="homepage['title']"
                placeholder="Digite o titulo">

            <input class="input width_50" style="border-color: transparent;" [(ngModel)]="homepage['link']"
                placeholder="Digite o link do podcast">

            <div style="margin: 15px; font-weight: 600; color: gray; width: 100%; text-align: right;">
                *link do podcast EX: <i>"https://open.spotify.com/show/CODIGO_DO_PODCAST"</i>
            </div>

            <div style="width: 100%;">
                <input class="input width_50" style="margin-bottom: 20px; border-color: gray;"
                    [(ngModel)]="homepage['description']" placeholder="Digite texto do botão">
            </div>

            <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['deezer']"
                placeholder="Link do Deezer" class="input width_50" style="border-color: gray;">
            <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                *link do podcast EX: <i>"https://www.deezer.com/br/show/CODIGO_DO_PODCAST"</i>
            </div>

            <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['itunes']"
                placeholder="Link do iTunes" class="input width_50" style="border-color: gray;">
            <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                *link do podcast EX: <i>"https://podcasts.apple.com/br/podcast/"</i>
            </div>

            <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['castbox']"
                placeholder="Link do Castbox" class="input width_50" style="border-color: gray;">
            <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                *link do podcast EX: <i>"https://castbox.fm/channel/CODIGO_DO_PODCAST"</i>
            </div>

            <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['google_podcast']"
                placeholder="Link do Google Podcast" class="input width_50" style="border-color: gray;">
            <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                *link do podcast EX: <i>"https://podcasts.google.com/feed/CODIGO_DO_PODCAST"</i>
            </div>
        </div>

        <div *ngIf="homepage.type.name == 'Cursos'" style="margin: 10px 0;">
            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : ''}">
                <mat-label>Selecione um ou mais headers </mat-label>

                <mat-chip-list #tagChipListHome aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsHomepage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'home')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagHome" [matChipInputFor]="tagChipListHome"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagHome="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'home')">
                    <mat-option *ngFor="let item of filteredTagHomepage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            Por favor, selecione um curso
            <mat-select [(ngModel)]="homepage.images[selected_homepage_image]['course']" class="select-box">
                <mat-option *ngFor="let item of list_course; let i = index" [value]="item.id">
                    {{item.title}}
                </mat-option>
            </mat-select>
        </div>

        <div *ngIf="homepage.type.name == 'Ponto'" style="margin: 10px 0;">

            <div class="row-100"
                style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                <div class="row-100"
                    style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                    <input class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                        [(ngModel)]="homepage.images[selected_homepage_image]['point_control_name']"
                        placeholder="Titulo do ponto">

                    <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                        <h3 style="margin: 0; color: white;"> Selecione a configuração da Entrada </h3>
                    </div>

                    <div style="position: relative; width: 100%;">
                        <img [src]="homepage.images[selected_homepage_image]['url']" (click)="fileCheckIn.click()"
                            class="image_logo"
                            *ngIf="(homepage.images[selected_homepage_image]['url'] != '' && homepage.images[selected_homepage_image]['url'] != null)">

                        <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['name']"
                            placeholder="Selecione uma imagem para o ckeck in" (click)="fileCheckIn.click()"
                            [style.paddingLeft]="(homepage.images[selected_homepage_image]['url'] != '' && homepage.images[selected_homepage_image]['url'] != null) ? '85px' : '20px'"
                            [ngStyle]="{'border-color': warningMap.get('image_image_ckeckIn')? 'red' : ''}"
                            class="input" readonly>

                        <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            *ngIf="(homepage.images[selected_homepage_image]['url'] != '' && homepage.images[selected_homepage_image]['url'] != null)"
                            class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                                stroke="none">
                                <path
                                    d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                            </g>
                        </svg>

                    </div>

                    <input #fileCheckIn type="file" accept="image/*"
                        (change)="addImageCkeck($event, 'checkin', 'home', selected_homepage_image, selected_page_image)"
                        multiple="multiple" style="display: none;">

                    <input class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                        [(ngModel)]="homepage.images[selected_homepage_image]['title']" placeholder="Texto do botão">

                    <textarea class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                        style="resize: none;" [(ngModel)]="homepage.images[selected_homepage_image]['description']"
                        placeholder="Descrição"></textarea>


                    <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                        <h3 style="margin: 0; color: white;"> Selecione a configuração da Saida </h3>
                    </div>

                    <div style="position: relative; width: 100%;">
                        <img [src]="homepage.images[selected_homepage_image+1]['url']" (click)="fileCheckOut.click()"
                            class="image_logo"
                            *ngIf="(homepage.images[selected_homepage_image+1]['url'] != '' && homepage.images[selected_homepage_image+1]['url'] != null)">

                        <input mat-input [(ngModel)]="homepage.images[selected_homepage_image+1]['name']"
                            placeholder="Selecione uma imagem para o ckeck out" (click)="fileCheckOut.click()"
                            [style.paddingLeft]="(homepage.images[selected_homepage_image+1]['url'] != '' && homepage.images[selected_homepage_image+1]['url'] != null) ? '85px' : '20px'"
                            [ngStyle]="{'border-color': warningMap.get('image_logo')? 'red' : ''}" class="input"
                            readonly>

                        <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            *ngIf="(homepage.images[selected_homepage_image+1]['url'] != '' && homepage.images[selected_homepage_image+1]['url'] != null)"
                            class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                                stroke="none">
                                <path
                                    d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                            </g>
                        </svg>

                    </div>

                    <input #fileCheckOut type="file" accept="image/*"
                        (change)="addImageCkeck($event, 'checkout', 'home', selected_homepage_image, selected_page_image)"
                        multiple="multiple" style="display: none;">

                    <input class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                        [(ngModel)]="homepage.images[selected_homepage_image+1]['title']" placeholder="Texto do botão">

                    <textarea class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                        style="resize: none;" [(ngModel)]="homepage.images[selected_homepage_image+1]['description']"
                        placeholder="Descrição"></textarea>
                </div>

            </div>

        </div>

        <div *ngIf="homepage.type.name == 'Notícia'" style="margin: 10px 0;">
            Por favor, selecione a notícia
            <mat-select [(ngModel)]="homepage.images[selected_homepage_image].notice" class="select-box">
                <mat-option *ngFor="let item of list_notice; let i = index" [value]="item.id">
                    {{item.title}}
                </mat-option>
            </mat-select>
        </div>

        <div *ngIf="homepage.type.name == 'Tabloide'" style="margin: 10px 0;">
            Por favor, selecione o tabloide
            <mat-select [(ngModel)]="homepage.images[selected_homepage_image].tabloide" class="select-box">
                <mat-option *ngFor="let item of list_tabloide; let i = index" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </div>

        <div
            *ngIf="homepage.type.name != 'Podcast' && homepage.type.name != 'Cursos' && homepage.type.name != 'Ponto' && homepage.type.name != 'Notícia' && homepage.type.name != 'Tabloide' && homepage.quantity == null && !steps.homepage_images">
            <!-- commit gui -->
            <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : ''}">
                <mat-label>Selecione um ou mais Seções </mat-label>

                <mat-chip-list #tagChipListHome aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsHomepage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'home')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagHome" [matChipInputFor]="tagChipListHome"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagHome="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'home')">
                    <mat-option *ngFor="let item of filteredTagHomepage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <input class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                [(ngModel)]="homepage.link" placeholder="Insira um link de vídeo">

            <div class="card-center">
                <img *ngIf="homepage.background_image != null" [src]="homepage.background_image.url" class="main-image">

                <button mat-button class="button-cor" [style.background]="color_primary"
                    (click)="fileHomepageBg.click()" style="width: auto; height: 36px; margin: auto 0 auto 10px;">
                    Adicionar imagem de fundo </button>
                <input #fileHomepageBg type="file" accept="image/*" (change)="onFileChanged($event, 'homepage_bg')"
                    style="display: none;">
            </div>
        </div>

    </div>

    <div *ngIf="(steps.homepage_images && actual_step == 'page_types') || steps.page_types" style="margin: 15px 0;">

        <div class="border-gray space-between-center" style="width: 100%; margin: 0 0 10px 0;">
            <h3 style="margin: 0; color: white;">
                Selecione abaixo o formato das suas páginas internas
                {{steps.page_types ? ' - FINALIZADO' : ''}}
            </h3>

            <button mat-button class="button-cor" [style.background]="color_primary" (click)="nextStep()"
                *ngIf="actual_step == 'homepage_images'">
                Ir para próxima etapa
            </button>
        </div>

        <div class="card-center custom-scrollbar-x">

            <!-- commit gui -->
            <mat-form-field class="input p_input m_top"
                [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : 'gray'}">
                <mat-label>Selecione um ou mais Seções</mat-label>

                <mat-chip-list #tagChipListPage aria-label="">
                    <mat-chip *ngFor="let item of tagArrayChipsPage" [selectable]="'true'" [removable]="'true'"
                        (removed)="removeTagChips(item, 'page')">
                        {{item.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                        [matAutocomplete]="autoTagPage" [matChipInputFor]="tagChipListPage"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                </mat-chip-list>

                <mat-autocomplete #autoTagPage="matAutocomplete" (optionSelected)="tagChipsSelectedTag($event, 'page')">
                    <mat-option *ngFor="let item of filteredTagPage | async" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <div class="row-100">
                <div style="width: 200px;">
                    <img [src]="homepage.images[selected_homepage_image].url" class="main-image">
                    <mat-select [(ngModel)]="selected_homepage_image" class="select-box">
                        <mat-option *ngFor="let img of homepage.images; let i = index" (click)="selectImagePage(i)"
                            [value]="i">
                            {{ nameImage(img) }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="custom-scrollbar-x" style="display: flex; margin-left: 15px; max-width: calc(100% - 250px); 
                    overflow-x: auto; padding: 10px 0 65px 0; border: 1px solid transparent;"
                    [ngStyle]="{'border-color': warningMap.get('select_type_page')? 'red' : 'transparent'}">
                    <div *ngFor="let item of page_types">
                        <img [src]="item.image " [matTooltip]="item.name" [style.borderColor]="color_primary" [ngClass]="{'select_phone' : (homepage.images[selected_homepage_image].type == item || 
                            homepage.images[selected_homepage_image].type?.name == item?.name)}"
                            (click)="selectType(item, 'page')" class="img-phone">
                    </div>
                </div>
            </div>

            <div class="row-100 m_top" *ngIf="homepage.images[selected_homepage_image].type != null && 
                homepage.images[selected_homepage_image]['level'] == null">

                <div *ngIf="getImagesQuantity(homepage.images[selected_homepage_image].type) != null">
                    <div class="card-center" *ngIf="homepage.images[selected_homepage_image].type.name != 'Post'">
                        <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal"
                            (cdkDropListDropped)="drop($event, homepage.images[selected_homepage_image].images)">
                            <div *ngFor="let image of homepage.images[selected_homepage_image].images; let i = index"
                                cdkDrag
                                style="display: flex; flex-direction: column; margin: 5px; padding: 5px; border: 1px solid #e9edef;">

                                <img src="/assets/icon/close.png" (click)="removeImage(i, 'page')"
                                    *ngIf="image.url != null"
                                    style="margin: 5px 5px 0 auto; width: 15px; height: 15px; object-fit: contain; cursor: pointer;">

                                <img [src]="image.url" style="width: 150px; height: 150px; object-fit: contain;">

                                <div class="ellipsis-one"> {{ image.name }} </div>
                            </div>
                        </div>

                        <div style="display: flex;">
                            <button mat-button class="button-cor" [style.background]="color_primary"
                                (click)="filePages2.click()"
                                style="width: auto; height: 36px; margin: auto 0 auto 10px;"> Adicionar imagens
                            </button>
                            <button mat-button class="button-cor height_36" [style.background]="color_primary"
                                (click)="newNivel()" *ngIf="getImagesQuantity(homepage.images[selected_homepage_image].type) != null && 
                                    homepage.images[selected_homepage_image].images != null"> Adicionar nível </button>
                        </div>

                        <input #filePages2 type="file" accept="image/*" multiple="multiple" style="display: none;"
                            (change)="onFileChanged($event, 'homepage_image', selected_homepage_image)">
                    </div>
                </div>

                <!-- getImagesQuantity(homepage.images[selected_homepage_image].type) == null -->
                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Vídeo'">
                    <input class="input" [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}"
                        [(ngModel)]="homepage.images[selected_homepage_image].link"
                        placeholder="Insira um link de vídeo">

                    <div class="card-center">
                        <img *ngIf="homepage.images[selected_homepage_image].background_image != null"
                            [src]="homepage.images[selected_homepage_image].background_image.url" class="main-image">

                        <button mat-button class="button-cor height_36" [style.background]="color_primary"
                            (click)="filePageBg.click()"> Adicionar imagem de fundo </button>
                        <input #filePageBg type="file" accept="image/*" style="display: none;"
                            (change)="onFileChanged($event, 'page_bg', selected_homepage_image)">
                    </div>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Hashlar'"
                    style="width: 50%; display: flex; align-items: center;">

                    <input class="input" style="width: 100%;" placeholder="Insira o token"
                        [ngStyle]="{'border-color': warningMap.get('token_hashlar')? 'red' : 'gray'}"
                        [(ngModel)]="homepage.images[selected_homepage_image].token_hashlar">

                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Link'"
                    style="width: 70%; display: flex; align-items: center;">

                    <div style="width: 50%; min-width: 255px;">
                        Por favor, selecione o tipo do link
                        <mat-select [(ngModel)]="homepage.images[selected_homepage_image].option_link"
                            class="select-box m_right">
                            <mat-option *ngFor="let item of option_link; let i = index" [value]="item.link"
                                (click)="getValieLink(item, 'home')">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </div>

                    <input class="input width_50" *ngIf="option_select_link?.name != 'Relatório'"
                        [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}"
                        [(ngModel)]="homepage.images[selected_homepage_image].link"
                        [placeholder]="option_select_link.exem == null ? '' : option_select_link.exem">


                    <button mat-button [style.background]="color_primary" [style.color]="'#fff'"
                        *ngIf="option_select_link?.name == 'Link'" (click)="openGerateLinkFile()"
                        style="display: flex; align-items: center; justify-content: space-evenly; margin-left: 20px; margin-top: 20px;">
                        <span> Importar Arquivo </span>
                    </button>

                    <div class="input width_50" *ngIf="option_select_link?.name == 'Relatório'"
                        [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}">
                        {{ homepage?.images[selected_homepage_image]?.link }}
                    </div>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Catálogo'" style="min-width: 50%;">
                    Por favor, selecione o catálogo
                    <mat-select [(ngModel)]="homepage.images[selected_homepage_image]['catalog']" class="select-box">
                        <mat-option *ngFor="let item of catalogs; let i = index" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Enquete'" style="min-width: 50%;">
                    Por favor, selecione a pergunta
                    <mat-select [(ngModel)]="homepage.images[selected_homepage_image]['survey']" class="select-box">
                        <mat-option *ngFor="let item of surveys; let i = index" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Cupom'" style="min-width: 50%;">
                    Por favor, selecione um cupom
                    <mat-select [(ngModel)]="homepage.images[selected_homepage_image]['coupon']" class="select-box">
                        <mat-option *ngFor="let item of list_coupon; let i = index" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Cadastro'" style="min-width: 50%;">
                    Por favor, selecione um Cadastro
                    <mat-select [(ngModel)]="homepage.images[selected_homepage_image]['registration_config']"
                        class="select-box">
                        <mat-option *ngFor="let item of list_config; let i = index" [value]="item.id">
                            {{item.title}}
                        </mat-option>
                    </mat-select>

                    <button mat-button class="button-cor" [style.background]="color_primary" (click)="newLevel()"
                        style="width: auto; height: 36px; margin: 10px 0 auto 0;"> Adicionar nível </button>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Post'"
                    style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">

                    <div class="card-center">
                        <img *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image] != null"
                            [src]="homepage.images[selected_homepage_image].images[selected_page_image]['url']"
                            class="main-image">

                        <button mat-button class="button-cor" [style.background]="color_primary"
                            (click)="filePageBg.click()" style="width: auto; height: 36px; margin: auto 0 auto 10px;">
                            Adicionar imagem </button>
                        <input #filePageBg type="file" accept="image/*" style="display: none;"
                            (change)="onFileChanged($event, 'post_page', selected_homepage_image)">
                    </div>

                    <div style="width: 100%; display: flex; flex-wrap: wrap; align-items: center;">
                        <input class="input width_50" style="border-color: gray;"
                            [(ngModel)]="homepage.images[selected_homepage_image]['title']"
                            placeholder="Digite o titulo">

                        <input class="input width_50" style="border-color: gray;"
                            [(ngModel)]="homepage.images[selected_homepage_image]['link']" placeholder="Digite o Link">

                        <textarea class="input"
                            style="width: calc(90% + 10px); resize: none; margin: 21px 0 0 10px !important; border-color: gray;"
                            placeholder="Digite a mensagem que sera enviara para o usuário."
                            [(ngModel)]="homepage.images[selected_homepage_image]['description']"></textarea>
                    </div>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Podcast'"
                    style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; flex-wrap: wrap; margin-bottom: 20px;">

                    <input class="input width_50" style="border-color: gray;"
                        [(ngModel)]="homepage.images[selected_homepage_image]['title']" placeholder="Digite o titulo">

                    <input class="input width_50" style="border-color: gray;"
                        [(ngModel)]="homepage.images[selected_homepage_image]['link']"
                        placeholder="Digite o link do podcast">

                    <div style="margin: 15px; font-weight: 600; color: gray; width: 100%; text-align: right;">
                        *link do podcast EX: <i>"https://open.spotify.com/show/CODIGO_DO_PODCAST"</i>
                    </div>

                    <div style="width: 100%;">
                        <input class="input width_50" style="margin-bottom: 20px; border-color: gray;"
                            [(ngModel)]="homepage.images[selected_homepage_image]['description']"
                            placeholder="Digite texto do botão">
                    </div>

                    <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['deezer']"
                        placeholder="Link do Deezer" class="input width_50" style="border-color: gray;">
                    <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                        *link do podcast EX: <i>"https://www.deezer.com/br/show/CODIGO_DO_PODCAST"</i>
                    </div>

                    <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['itunes']"
                        placeholder="Link do iTunes" class="input width_50" style="border-color: gray;">
                    <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                        *link do podcast EX: <i>"https://podcasts.apple.com/br/podcast/"</i>
                    </div>

                    <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['castbox']"
                        placeholder="Link do Castbox" class="input width_50" style="border-color: gray;">
                    <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                        *link do podcast EX: <i>"https://castbox.fm/channel/CODIGO_DO_PODCAST"</i>
                    </div>

                    <input mat-input [(ngModel)]="homepage.images[selected_homepage_image]['google_podcast']"
                        placeholder="Link do Google Podcast" class="input width_50" style="border-color: gray;">
                    <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                        *link do podcast EX: <i>"https://podcasts.google.com/feed/CODIGO_DO_PODCAST"</i>
                    </div>
                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Cursos'"
                    style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 20px;">

                    Por favor, selecione um curso
                    <mat-select style="border-color: gray;  margin-left: 0;" class="select-box"
                        [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['course']">
                        <mat-option *ngFor="let item of list_course; let i = index" [value]="item.id">
                            {{item.title}}
                        </mat-option>
                    </mat-select>

                </div>

                <div *ngIf="homepage.images[selected_homepage_image].type.name == 'Ponto'"
                    style="width: 100%; margin: 10px 0;">

                    <div class="row-100"
                        style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                        <div class="row-100"
                            style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                            <input class="input_gray"
                                [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                style="border: 1px solid transparent; border-color: gray !important;"
                                [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image]['point_control_name']"
                                placeholder="Titulo do ponto">

                            <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                                <h3 style="margin: 0; color: white;"> Selecione a configuração da Entrada </h3>
                            </div>

                            <div style="position: relative; width: 100%;">
                                <img [src]="homepage.images[selected_homepage_image]?.images[selected_page_image]['url']"
                                    (click)="fileCheckIn.click()" class="image_logo" style="border-color: gray"
                                    *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != null)">

                                <input mat-input
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image]['name']"
                                    placeholder="Selecione uma imagem para o ckeck in" (click)="fileCheckIn.click()"
                                    style="border-color: gray"
                                    [style.paddingLeft]="(homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != null) ? '85px' : '20px'"
                                    [ngStyle]="{'border-color': warningMap.get('image_image_ckeckIn')? 'red' : ''}"
                                    class="input_gray" readonly>

                                <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    style="border-color: gray"
                                    *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image]['url'] != null)"
                                    class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                                        stroke="none">
                                        <path
                                            d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                                    </g>
                                </svg>

                            </div>

                            <input #fileCheckIn type="file" accept="image/*"
                                (change)="addImageCkeck($event, 'checkin', 'page', selected_homepage_image, selected_page_image)"
                                multiple="multiple" style="border-color: gray" style="display: none;">

                            <input class="input_gray"
                                [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                style="border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image]['title']"
                                placeholder="Texto do botão">

                            <textarea class="input_gray"
                                [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                style="resize: none;" style="border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image]['description']"
                                placeholder="Descrição"></textarea>


                            <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                                <h3 style="margin: 0; color: white;"> Selecione a configuração da Saida </h3>
                            </div>

                            <div style="position: relative; width: 100%;">
                                <img [src]="homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url']"
                                    (click)="fileCheckOut.click()" class="image_logo" style="border-color: gray"
                                    *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != null)">

                                <input mat-input
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image+1]['name']"
                                    placeholder="Selecione uma imagem para o ckeck out" (click)="fileCheckOut.click()"
                                    style="border-color: gray"
                                    [style.paddingLeft]="(homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != null) ? '85px' : '20px'"
                                    [ngStyle]="{'border-color': warningMap.get('image_logo')? 'red' : ''}"
                                    class="input_gray" readonly>

                                <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    style="border-color: gray"
                                    *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image+1]['url'] != null)"
                                    class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red"
                                        stroke="none">
                                        <path
                                            d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                                    </g>
                                </svg>

                            </div>

                            <input #fileCheckOut type="file" accept="image/*"
                                (change)="addImageCkeck($event, 'checkout', 'page', selected_homepage_image, selected_page_image)"
                                multiple="multiple" style="display: none;">

                            <input class="input_gray"
                                [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                style="border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image+1]['title']"
                                placeholder="Texto do botão">

                            <textarea class="input_gray"
                                [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                style="resize: none;" style="border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image+1]['description']"
                                placeholder="Descrição"></textarea>
                        </div>

                    </div>

                </div>

            </div>

            <!-- new level -->
            <div *ngIf="homepage?.images[selected_homepage_image] != null && homepage?.images[selected_homepage_image]?.images != null && homepage?.images[selected_homepage_image]?.level != null"
                style="width: calc(100% - 150px); margin-left: 150px;">
                <!-- commit gui -->
                <mat-form-field class="input p_input m_top"
                    [ngStyle]="{'border-color': warningMap.get('tag')? 'red' : 'gray'}">
                    <mat-label>Selecione um ou mais Seções</mat-label>

                    <mat-chip-list #tagChipListInternal aria-label="">
                        <mat-chip *ngFor="let item of tagArrayChipsInternal" [selectable]="'true'" [removable]="'true'"
                            (removed)="removeTagChips(item, 'internal')">
                            {{item.name}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>

                        <input #establishmentInputTag placeholder="Digite aqui ..." [formControl]="tagChipsFormControl"
                            [matAutocomplete]="autoTagInternal" [matChipInputFor]="tagChipListInternal"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag">
                    </mat-chip-list>

                    <mat-autocomplete #autoTagInternal="matAutocomplete"
                        (optionSelected)="tagChipsSelectedTag($event, 'internal')">
                        <mat-option *ngFor="let item of filteredTagInternal | async" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>

                <div class="row-100" *ngIf="homepage.images[selected_homepage_image].type != null && 
                    homepage.images[selected_homepage_image]['level'] != null" style="margin: 15px 0;">
                    <div style="width: 200px;">
                        <img [src]="homepage.images[selected_homepage_image]?.images[selected_page_image]?.url"
                            *ngIf="homepage.images[selected_homepage_image]?.images[selected_page_image]?.url != null"
                            class="main-image">
                        <mat-select [(ngModel)]="selected_page_image" class="select-box">
                            <mat-option
                                *ngFor="let img of homepage.images[selected_homepage_image].images; let i = index"
                                [value]="i">
                                {{ nameImage(img) }}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="custom-scrollbar-x"
                        style="display: flex; margin-left: 15px; max-width: calc(100% - 250px); overflow-x: auto; padding: 10px 0 65px 0;">
                        <div *ngFor="let item of page_types">
                            <img [src]="item?.image " [matTooltip]="item?.name" [style.borderColor]="color_primary"
                                [ngClass]="{'select_phone' : (homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type == item)}"
                                (click)="selectType(item, 'internal')" class="img-phone">
                        </div>
                    </div>
                </div>

                <div class="row-100" style="margin-top: 15px;"
                    *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type != null">

                    <div *ngIf="getImagesQuantity(homepage.images[selected_homepage_image].images[selected_page_image].type) != null"
                        style="margin-left: 150px;">
                        <div class="card-center">
                            <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal"
                                *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Banner' ||
                                       homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Mini Banner' ||
                                       homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Galeria'"
                                (cdkDropListDropped)="drop($event, homepage.images[selected_homepage_image].images[selected_page_image].images)">
                                <div *ngFor="let image of homepage.images[selected_homepage_image].images[selected_page_image].images; let i = index"
                                    cdkDrag
                                    style="display: flex; flex-direction: column; margin: 5px; padding: 5px; border: 1px solid #e9edef;">
                                    <img src="/assets/icon/close.png" (click)="removeImage(i, 'internal')"
                                        *ngIf="image.url != null"
                                        style="margin: 5px 5px 0 auto; width: 15px; height: 15px; object-fit: contain; cursor: pointer;">
                                    <img [src]="image.url" style="width: 150px; height: 150px; object-fit: contain;">
                                    <div class="ellipsis-one"> {{ image.name }} </div>
                                </div>
                            </div>

                            <div style="display: flex;">
                                <button mat-button class="button-cor height_36" [style.background]="color_primary"
                                    *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Banner' ||
                                    homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Mini Banner' ||
                                    homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Galeria'"
                                    (click)="filePages2.click()"> Adicionar imagens </button>
                            </div>

                            <input #filePages2 type="file" accept="image/*" multiple="multiple" style="display: none;"
                                (change)="onFileChanged($event, 'internal_image', selected_page_image)">
                        </div>
                    </div>

                    <div
                        *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Vídeo'">
                        <input class="input"
                            [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].link"
                            placeholder="Insira um link de vídeo">

                        <div class="card-center">
                            <img *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].background_image != null"
                                [src]="homepage.images[selected_homepage_image].images[selected_page_image].background_image.url"
                                class="main-image">

                            <button mat-button class="button-cor height_36" [style.background]="color_primary"
                                (click)="filePageBg.click()"> Adicionar imagem de fundo </button>
                            <input #filePageBg type="file" accept="image/*" style="display: none;"
                                (change)="onFileChanged($event, 'internalpage_bg', selected_page_image)">
                        </div>
                    </div>

                    <div *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Hashlar'"
                        style="width: 50%; display: flex; align-items: center;">

                        <input class="input width_50" *ngIf="option_select_link?.name != 'Relatório'"
                            [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].link"
                            [placeholder]="option_select_link.exem == null ? '' : option_select_link.exem">

                    </div>

                    <div *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Link'"
                        style="width: 70%; display: flex; align-items: center;">

                        <div style="width: 50%; min-width: 255px;">
                            Por favor, selecione o tipo do link
                            <mat-select
                                [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].option_link"
                                class="select-box m_right">
                                <mat-option *ngFor="let item of option_link; let i = index" [value]="item.link"
                                    (click)="getValieLink(item, 'page')">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </div>

                        <input class="input width_50" *ngIf="option_select_link?.name != 'Relatório'"
                            [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].link"
                            [placeholder]="option_select_link.exem == null ? '' : option_select_link.exem">

                        <button mat-button [style.background]="color_primary" [style.color]="'#fff'"
                            *ngIf="option_select_link?.name == 'Link'" (click)="openGerateLinkFile()"
                            style="display: flex; align-items: center; justify-content: space-evenly; margin-left: 20px; margin-top: 20px;">
                            <span> Importar Arquivo </span>
                        </button>

                        <div class="input width_50" *ngIf="option_select_link?.name == 'Relatório'"
                            [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : 'gray'}">
                            {{ homepage.images[selected_homepage_image].images[selected_page_image].link }}
                        </div>
                    </div>

                    <div *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Catálogo'"
                        style="min-width: 50%;">
                        Por favor, selecione o catálogo
                        <mat-select
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['catalog']"
                            class="select-box">
                            <mat-option *ngFor="let item of catalogs; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Enquete'"
                        style="min-width: 50%;">
                        Por favor, selecione a pergunta
                        <mat-select
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['survey']"
                            class="select-box">
                            <mat-option *ngFor="let item of surveys; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Cupom'"
                        style="min-width: 50%;">
                        Por favor, selecione um cupom
                        <mat-select
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['coupon']"
                            class="select-box">
                            <mat-option *ngFor="let item of list_coupon; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div *ngIf="homepage.images[selected_homepage_image].images[selected_page_image].type.name == 'Cadastro'"
                        style="min-width: 50%;">
                        Por favor, selecione um Cadastro
                        <mat-select
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['registration_config']"
                            class="select-box">
                            <mat-option *ngFor="let item of list_config; let i = index" [value]="item.id">
                                {{item.title}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Post'"
                        style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">

                        <div class="card-center">
                            <img *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.images != null &&
                                        homepage?.images[selected_homepage_image]?.images[selected_page_image]?.images[0]?.url != null &&
                                        homepage?.images[selected_homepage_image]?.images[selected_page_image]?.images[0]?.url != '' "
                                [src]="homepage.images[selected_homepage_image].images[selected_page_image].images[0].url"
                                class="main-image">

                            <button mat-button class="button-cor" [style.background]="color_primary"
                                (click)="filePageBg.click()"
                                style="width: auto; height: 36px; margin: auto 0 auto 10px;"> Adicionar imagem </button>
                            <input #filePageBg type="file" accept="image/*" style="display: none;"
                                (change)="onFileChanged($event, 'post_internal', selected_homepage_image)">
                        </div>

                        <div style="width: 100%; display: flex; flex-wrap: wrap; align-items: center;">
                            <input class="input width_50" style="width: 45%; border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['title']"
                                placeholder="Digite o titulo">

                            <input class="input width_50" style="width: 45%; border-color: gray"
                                [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['link']"
                                placeholder="Digite o Link">

                            <textarea class="input"
                                style="width: calc(90% + 10px); resize: none; margin: 21px 0 0 10px !important; border-color: gray"
                                placeholder="Digite a descrição"
                                [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['description']"></textarea>
                        </div>
                    </div>

                    <div *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Podcast'"
                        style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; flex-wrap: wrap; margin-bottom: 20px;">

                        <input class="input width_50" style="border-color: gray;"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0].title"
                            placeholder="Digite o titulo">

                        <input class="input width_50" style="border-color: gray;"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0].link"
                            placeholder="Digite o link do podcast">

                        <div style="margin: 15px; font-weight: 600; color: gray; width: 100%; text-align: right;">
                            *link do podcast EX: <i>"https://open.spotify.com/show/CODIGO_DO_PODCAST"</i>
                        </div>

                        <div style="width: 100%;">
                            <input class="input width_50" style="margin-bottom: 20px; border-color: gray;"
                                [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['description']"
                                placeholder="Digite texto do botão">
                        </div>

                        <input mat-input
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['deezer']"
                            placeholder="Link do Deezer" class="input width_50" style="border-color: gray;">
                        <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                            *link do podcast EX: <i>"https://www.deezer.com/br/show/CODIGO_DO_PODCAST"</i>
                        </div>

                        <input mat-input
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['itunes']"
                            placeholder="Link do iTunes" class="input width_50" style="border-color: gray;">
                        <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                            *link do podcast EX: <i>"https://podcasts.apple.com/br/podcast/"</i>
                        </div>

                        <input mat-input
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['castbox']"
                            placeholder="Link do Castbox" class="input width_50" style="border-color: gray;">
                        <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                            *link do podcast EX: <i>"https://castbox.fm/channel/CODIGO_DO_PODCAST"</i>
                        </div>

                        <input mat-input
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image].images[0]['google_podcast']"
                            placeholder="Link do Google Podcast" class="input width_50" style="border-color: gray;">
                        <div style="text-align: left; margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
                            *link do podcast EX: <i>"https://podcasts.google.com/feed/CODIGO_DO_PODCAST"</i>
                        </div>
                    </div>

                    <div *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Cursos'"
                        style="width: 100%; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 20px;">

                        Por favor, selecione um curso
                        <mat-select style="border-color: gray;  margin-left: 0;" class="select-box"
                            [(ngModel)]="homepage.images[selected_homepage_image].images[selected_page_image]['course']">
                            <mat-option *ngFor="let item of list_course; let i = index" [value]="item.id">
                                {{item.title}}
                            </mat-option>
                        </mat-select>

                    </div>

                    <div *ngIf="homepage?.images[selected_homepage_image]?.images[selected_page_image]?.type?.name == 'Ponto'"
                        style="width: 100%; margin: 10px 0;">

                        <div class="row-100"
                            style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                            <div class="row-100"
                                style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">

                                <input class="input_gray"
                                    [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                    style="border-color: gray !important;"
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['point_control_name']"
                                    placeholder="Titulo do ponto">

                                <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                                    <h3 style="margin: 0; color: white;"> Selecione a configuração da Entrada </h3>
                                </div>

                                <div style="position: relative; width: 100%;">
                                    <img [src]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url']"
                                        (click)="fileCheckIn.click()" class="image_logo" style="border-color: gray"
                                        *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != null)">

                                    <input mat-input
                                        [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['name']"
                                        placeholder="Selecione uma imagem para o ckeck in" (click)="fileCheckIn.click()"
                                        style="border-color: gray"
                                        [style.paddingLeft]="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != null) ? '85px' : '20px'"
                                        [ngStyle]="{'border-color': warningMap.get('image_image_ckeckIn')? 'red' : ''}"
                                        class="input_gray" readonly>

                                    <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                        style="border-color: gray"
                                        *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['url'] != null)"
                                        class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                            fill="red" stroke="none">
                                            <path
                                                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                                        </g>
                                    </svg>

                                </div>

                                <input #fileCheckIn type="file" accept="image/*"
                                    (change)="addImageCkeck($event, 'checkin', 'internal', selected_homepage_image, selected_page_image)"
                                    multiple="multiple" style="border-color: gray" style="display: none;">

                                <input class="input_gray"
                                    [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                    style="border-color: gray"
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['title']"
                                    placeholder="Texto do botão">

                                <textarea class="input_gray"
                                    [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                    style="resize: none;" style="border-color: gray"
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[0]['description']"
                                    placeholder="Descrição"></textarea>


                                <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
                                    <h3 style="margin: 0; color: white;"> Selecione a configuração da Saida </h3>
                                </div>

                                <div style="position: relative; width: 100%;">
                                    <img [src]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url']"
                                        (click)="fileCheckOut.click()" class="image_logo" style="border-color: gray"
                                        *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != null)">

                                    <input mat-input
                                        [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['name']"
                                        placeholder="Selecione uma imagem para o ckeck out"
                                        (click)="fileCheckOut.click()" style="border-color: gray"
                                        [style.paddingLeft]="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != null) ? '85px' : '20px'"
                                        [ngStyle]="{'border-color': warningMap.get('image_logo')? 'red' : ''}"
                                        class="input_gray" readonly>

                                    <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                        style="border-color: gray"
                                        *ngIf="(homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != '' && homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['url'] != null)"
                                        class="btn_delet" (click)="deletImageCkeckIn('checkin')">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                            fill="red" stroke="none">
                                            <path
                                                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
                                        </g>
                                    </svg>

                                </div>

                                <input #fileCheckOut type="file" accept="image/*"
                                    (change)="addImageCkeck($event, 'checkout', 'internal', selected_homepage_image, selected_page_image)"
                                    multiple="multiple" style="display: none;">

                                <input class="input_gray"
                                    [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                    style="border-color: gray"
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['title']"
                                    placeholder="Texto do botão">

                                <textarea class="input_gray"
                                    [ngStyle]="{'border-color': warningMap.get('homepage_link')? 'red' : ''}"
                                    style="resize: none;" style="border-color: gray"
                                    [(ngModel)]="homepage.images[selected_homepage_image]?.images[selected_page_image].images[1]['description']"
                                    placeholder="Descrição"></textarea>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>

    <!-- <div style="margin: 30px 0;" *ngIf="!loading.establishment && homepage.type != null"> -->

    <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()">
        <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span>
        Veja como ficou o seu content
    </button>

    <button mat-button class="button-save" [style.background]="color_primary" (click)="finish()"
        *ngIf="!loading.establishment && homepage.type != null">
        <span> <img src="/assets/icon/icon-ok.png" class="icon"> </span>
        Salvar content
        <span *ngIf="loading.finish" class="spinner-border spinner-border-sm m_loading" role="status"
            aria-hidden="true"></span>
    </button>

    <!-- </div>  -->

    <div style="height: 50px;"></div>

    <div style="width: 100%; text-align: right; font-size: 14px; color: rgb(80, 2, 2);">
        {{ error.msg }}
    </div>
</div>