import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class CategoryService {

    constructor(private http : HttpClient) { }

    establishment = "id_establishment"

    getCategoryId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/category/${id}`,  { headers });
    }

    getAllCategories(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/category/`,  options);
    }

    getAllSubcategory(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/subcategory/`,  options);
    }

    postCategory(name, establishment, img_category): Observable<any> {
        let body = new HttpParams()
            .set(`name`, name)
            .set(`establishment`, establishment)
            .set(`img_category`, img_category)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/category/`, body, { headers })
    }
   
    editCategory(id_category, name): Observable<any> {
        let body = new HttpParams()
        .set(`subcategory`, name)
        .set(`category`, id_category)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/category/${id_category}/`, body, { headers })
    }

    deleteCategory(id_category): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/category/${id_category}/`, { headers })
    }

    getSubcategory(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/subcategory/`,  options);
    }


    postSubcategory(id_category, subcategory): Observable<any> {
        let body = new HttpParams()
            .set(`name`, subcategory.name)
            .set(`category`, id_category)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/subcategory/`, body, { headers })
    }

    editSubcategory(subcategory): Observable<any> {
        let body = new HttpParams()
            .set(`name`, subcategory.name)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/subcategory/${subcategory.id}/`, body, { headers })
    }

    deletSubcategory(subcategory): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/subcategory/${subcategory.id}/`, { headers })
    }
}
