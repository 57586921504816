import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-alarm',
  templateUrl: './checklist-alarm.component.html',
  styleUrls: ['./checklist-alarm.component.css']
})
export class ChecklistAlarmComponent implements OnInit, AfterViewInit {

  title: string;
  local: string;
  table: [];
  list_description: [];
  header: [];
  footer: [];

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log( this.table);
    console.log( this.local);
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

}
