<div *ngIf="view.login && !view.coupon" class="div-login">
    <app-modal-config-preview
        [isList]="isList.registration_config_obj"
        (newItemEvent)="addItem($event)">
    </app-modal-config-preview>
</div>

<div *ngIf="!view.login" class="primary-div">

    <div class="coupon">
        <img [src]="isList.image" class="img-coupon">
        <!-- <img [src]="url + isList.image" class="img-coupon"> -->
        <h2> {{ isList.name }} </h2>
        <h3> {{ isList.description }} </h3>
    </div>

    <div class="rule">
        <div class="title" [style.color]="color_primary"> Regras: </div>
        {{ isList.rule }} <br>
        <a [href]="isList.rules" target="_blank" [style.color]="color_primary"> veja as regras </a>
    </div>

    <button mat-button class="b-border" [style.background]="color_primary" (click)="rescue()"> 
        {{ textButton }} 
    </button>
</div>
