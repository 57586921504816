import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../service/catalog.service';

@Component({
    selector: 'app-app-channel',
    templateUrl: './app-channel.component.html',
    styleUrls: ['./app-channel.component.css']
})

export class AppChannelComponent implements OnInit {

    constructor() { }

    loading: boolean = false;
    selected_tab = 0;
  
    ngOnInit(): void {
      
    }

}