<div class="screen">

    <div style="width: 93%; display: flex; justify-content: space-between; align-items: center; margin: auto;">
        <h3 style="font-weight: 400;">
            Ao inserir o SBSolutions em vários sites, você concorda com os
            <a href="/" target="_blank">Termos de Serviço da API do SBSolutions</a>
        </h3>
    </div>

    <div style="width: 93%; margin: auto; display: flex; justify-content: space-between;" *ngIf="!loading">
        <div style="width: 49%;">
            <input mat-input [(ngModel)]="destiny" placeholder="Nome de destino" class="input"
                [ngStyle]="{'border-color': warningMap.get('destiny')? 'red' : ''}" >

            <mat-select [(ngModel)]="selected_campaign" class="input select-box"
                [ngStyle]="{'border-color': warningMap.get('selected_campaign')? 'red' : ''}"
                placeholder="Selecione um content...">
                <mat-option *ngFor="let obj of campaigns; let i = index" [value]="obj">
                    {{ obj.name }}
                </mat-option>
            </mat-select>

            <button mat-button class="main-button m_5" [style.background]="color_primary" (click)="createIframe()"> Salvar </button>
        </div>

        <div style="width: 49%;">
            <textarea mat-input [(ngModel)]="iframe" readonly
            class="input" style="resize: none; height: 160px; color: gray; "></textarea>
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
    </div>

</div>