<div class="primary-div" *ngIf="checkPage(0)" style="position: relative;">

    <div class="div-loading" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
    </div>

    <div *ngFor="let section of listImage" style="display: flex; flex-direction: column;">
            
        <h2 style="width: 100%; max-width: 1000px; margin: 15px auto 0;">
            {{ section?.name }}
        </h2>
            
        <div class="div-row" *ngFor="let obj of section?.products">

            <div class="img-produto">
                <img [src]=" obj?.image " loop=infinite>
            </div>
            
            <div class="info-product" [ngStyle]="{'width': web? '80%' : '100%'}">

                <div class="div-description" [ngClass]="{'font-web' : web, 'font-cell' : phone}">

                    <div [ngClass]="{'line_2' : web}">
                        <!-- <b [ngStyle]="{'color': header.color}"> {{ obj?.name }} </b> -->
                        <b style="color: black;"> {{ obj?.name }} </b>
                    </div>

                    <br>
                
                    <div [ngClass]="{'line_4' : web}">
                        <p> {{ obj?.description }} </p>
                    </div>
                    
                </div>
            
                <div class="add-cart">
                    <div [ngStyle]="{'text-align': phone ? 'center' : 'left', 'width': phone ? '100%' : 'auto' }"
                        style="color: black; font-size: calc(15px + 0.3vw); font-weight: 500; margin: 5px;">
                        {{ convertMoney(obj?.price) }}
                    </div>

                    <button mat-raised-button (click)="addProduct(obj)"
                        [ngStyle]="{'font-size': phone ? '0.65rem' : '1rem'}"
                        [style.background]="header.back" [style.color]="header.color" class="button">
                        ADICIONAR AO CARRINHO
                    </button>
                </div>

            </div>

        </div>
        
    </div>

    <div *ngIf="getQtd() != 0" class="btn_circle" [ngClass]="{'btn_buy_web' : web, 'btn_buy_cellphone' : phone}" matTooltip="Ver carrinho"
        [style.background]="header.back" (click)="trocaPage(1)">
        <span [matBadge]="getQtd()" matBadgeOverlap="false" matBadgeColor="warn" class="qtd-itens"></span>
        <svg class="icons" viewBox="0 0 256.000000 256.000000">
            <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" [style.fill]="header.color" stroke="none">
                <path d="M115 2535 c-60 -59 -28 -90 185 -180 l175 -73 5 -819 c6 -912 0 -849 77 -933 37 -42 120 -90 156 -90 11 0 17 -5 13 -12 -55 -134 -42 -239 44 -332 168 -183 468 -79 487 168 4 45 1 79 -11 112 -9 26 -16 49 -16 51 0 2 142 3 315 3 173 0 315 -2 315 -4 0 -2 -7 -20 -15 -39 -21 -51 -19 -162 4 -213 27 -59 77 -112 135 -142 73 -37 179 -37 252 0 100 52 154 140 154 253 0 112 -56 205 -155 258 -40 22 -45 22 -775 27 -690 5 -737 6 -765 23 -61 38 -70 60 -73 195 -2 67 -2 122 1 122 2 0 24 -7 47 -15 36 -13 153 -15 810 -15 l767 0 61 31 c64 32 110 79 140 146 15 33 17 81 20 380 l3 343 -23 22 c-21 22 -88 32 -925 148 l-903 124 0 137 c0 116 -3 140 -17 157 -18 20 -419 192 -448 192 -9 0 -27 -11 -40 -25z m795 -640 c140 -20 500 -69 800 -110 300 -41 562 -78 583 -81 l38 -6 -3 -293 c-3 -280 -4 -295 -24 -322 -12 -15 -36 -37 -55 -48 -33 -20 -52 -20 -774 -20 -728 0 -741 0 -775 21 -19 11 -45 35 -57 54 -23 33 -23 34 -23 436 0 382 1 404 18 404 10 0 132 -16 272 -35z m144 -1484 c43 -29 66 -70 66 -121 0 -113 -113 -183 -208 -130 -102 57 -107 191 -9 250 42 26 113 26 151 1z m1134 -1 c43 -27 76 -99 67 -146 -3 -19 -20 -50 -36 -69 -117 -132 -314 10 -233 167 34 65 135 89 202 48z"/>
            </g>
        </svg>        
    </div>

</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!check_login && checkPage(1)">
    <app-shopping-cart style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog_obj" (newItemEvent)="finish($event)"></app-shopping-cart>
</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!check_login && checkPage(2)">
    <app-finish-buy style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog_obj" (newItemEvent)="reset($event)"></app-finish-buy>
</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="check_login && checkPage(1)">
    <app-login-user style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog_obj" (newItemEvent)="login($event)"></app-login-user>
</div>