import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { CatalogService } from 'src/app/service/catalog.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-modal-catalog-preview',
    templateUrl: './modal-catalog-preview.component.html',
    styleUrls: ['./modal-catalog-preview.component.css']
})
export class ModalCatalogPreviewComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
		private catalogService: CatalogService,
        private storageService : StorageService
    ) { }

	loading: boolean = true;

    isList;
    isEdit;
    listImage
	url = AppConfig.path;

    header = {
        title: 'Preview catálogo',
        color: '#000',
        back: '#2fa1d6'
    }

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        if(this.isList != null){ this.getCatalog() }
        else this.loading = false
    }

    getCatalog(){
        this.catalogService.getCatalog(this.isList.id).subscribe(data=>{
            this.loading = false;
            this.listImage = data.sections; 
        })
    }

    convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
    }

}
