import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-vg',
  templateUrl: './control-vg.component.html',
  styleUrls: ['./control-vg.component.css']
})
export class ControlVgComponent implements OnInit {
  
  table: any;
  header: any;
  footer: any;
  list_description: any;
  title: string;
  local: string;
  observation: string

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

}
