import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "src/app/service/storage.service";
import { SpreadsheetService } from "./../../service/spreadsheet.service";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Spreadsheet } from "src/app/model/spreadsheet";

import html2pdf from "html2pdf.js";
import { ConfigImportService } from "src/app/service/config-import.service";
import { ControlVgComponent } from "../pdf-pages/control-vg/control-vg.component";
import { ControlHidranteComponent } from "../pdf-pages/control-hidrante/control-hidrante.component";
import { ChecklistFireExtinguishersComponent } from "../pdf-pages/checklist-fire-extinguishers/checklist-fire-extinguishers.component";
import { PumpHouseComponent } from "../pdf-pages/pump-house/pump-house.component";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { ChecklistAlarmComponent } from "../pdf-pages/checklist-alarm/checklist-alarm.component";
import { PaintingComponent } from "../pdf-pages/painting/painting.component";
import { GasFmComponent } from "../pdf-pages/gas-fm/gas-fm.component";
import { EmergencyExitComponent } from "../pdf-pages/emergency-exit/emergency-exit.component";
import { GasEaselsComponent } from "../pdf-pages/gas-easels/gas-easels.component";
import { MinimaxComponent } from "../pdf-pages/minimax/minimax.component";
@Component({
  selector: "app-spreadsheet",
  templateUrl: "./spreadsheet.component.html",
  styleUrls: ["./spreadsheet.component.css"],
})
export class SpreadsheetComponent implements OnInit {
  @ViewChild("priceListPDF", { static: true, read: ViewContainerRef })
  priceListPDF: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private spreadsheetService: SpreadsheetService,
    private storageService: StorageService,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar,
    private readonly resolver: ComponentFactoryResolver,
    private configImport: ConfigImportService
  ) {
    this.spreadsheet_id = this.route.snapshot.paramMap.get("id");
    this.establishment_id = localStorage.getItem("id_establishment");
  }

  modalMsg: MDBModalRef;

  modalDelete: MDBModalRef;

  spreadsheet_id;
  establishment_id;
  spreadsheet: Spreadsheet = {} as Spreadsheet;
  spreadsheetTable: any;

  description_keys: DescriptionKeys = {} as DescriptionKeys;
  parent_keys: string[] = [];
  description_json: DescriptionJson = {} as DescriptionJson;

  pdf: any;
  color_primary: string;
  loading: boolean = false;
  loaded: boolean = false;

  displayedColumns: string[] = ["name", "view"];
  dataSource = new MatTableDataSource<any>();

  factory: any;
  componentRef: any;

  index_page: number = 0;
  list_aba_table: any;

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    this.getSpreadsheet();
    this.getSpreadsheetTable();
  }

  getSpreadsheet() {
    this.spreadsheetService
      .getSpreadsheetId(this.spreadsheet_id)
      .subscribe((data) => {
        this.spreadsheet = data;
        if (data.description_json != null && data.description_json != "") {
          this.description_json = JSON.parse(data.description_json);
          this.parent_keys = Object.keys(this.description_json);

          this.parent_keys.forEach((key) => {
            this.description_keys[key] = Object.keys(
              this.description_json[key]
            );
          });
        }
        this.loaded = true;
      });
  }

  getSpreadsheetTable(page = 1, results = []) {
    this.spreadsheetService
      .getSpreadsheetTable(this.spreadsheet_id, page)
      .subscribe((data) => {
        
        let res = results.concat(data.results);
        if (data.next != null) {
          console.log(res);
          
          this.getSpreadsheetTable(page + 1, res);
        } else {
          this.spreadsheetTable = res;
          this.dataSource = new MatTableDataSource(res);
        }
      });
  }

  save() {
    if (this.loading) return;
    this.loading = true;

    this.spreadsheetService
      .patchSpreadsheet(
        this.spreadsheet_id,
        this.spreadsheet.title,
        JSON.stringify(this.description_json)
      )
      .subscribe(
        (data) => {
          this.openSnackBar("Salvo com sucesso!", "Fechar");
          this.loading = false;
        },
        (error) => {
          this.openSnackBar("ERRO: Não foi possível salvar", "Fechar");
          this.loading = false;
        }
      );
  }

  formatKey(key) {
    return key.replaceAll("_", " ");
  }

  openDelete(obj) {
    this.modalDelete = this.modalService.show(ModalChoiceComponent, {
      backdrop: "static",
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered popup-transparent",
      containerClass: "",
      animated: false,
      data: {
        title: "Excluir tabela",
        text: "Deseja excluir a tabela?",
      },
    });
    this.modalDelete.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.deleteSpreadsheetTable(obj.id);
      }
    });
  }

  deleteSpreadsheetTable(id) {
    this.spreadsheetService.deleteSpreadsheetTable(id).subscribe(
      (data) => {
        this.getSpreadsheetTable();
        this.openSnackBar("Deletado com sucesso!", "Fechar");
      },
      (error) => {
        this.openSnackBar("ERRO: Não foi possível deletar", "Fechar");
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

  isCreatePdf() {
    this.index_page = 0;

    this.gerarPDF();
  }

  gerarPDF() {

    this.list_aba_table = [];

    this.list_aba_table.push(this.spreadsheetTable[this.index_page]);
    this.list_aba_table = this.configImport.changeInfoPdf(
      this.spreadsheet,
      this.spreadsheetTable,
      this.spreadsheet.type
    );

    // console.log(this.spreadsheet);
    // console.log(this.list_aba_table);
    
    if (this.spreadsheet.type == "VG's Minimax") {
      this.createPDFTypeVG(
        this.list_aba_table
      );  
    } 
    else if(this.spreadsheet.type == 'Casa de Bombas') {
      this.createPDFPumpHouse(
        this.list_aba_table
      );
    } 
    else  if (this.spreadsheet.type == "Cavaletes Gases") {
      this.createPDFEasels(this.list_aba_table);
    } 
    else {
      this.createPDF(
        this.list_aba_table[this.index_page],
        this.spreadsheet.type
      );
      
    }
  }
  private createPDFPumpHouse(obj: any) {
    try {
      // console.log(obj);
      
      this.factory = this.resolver.resolveComponentFactory(PumpHouseComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);
      
      this.componentRef.instance.header = obj?.header;
      this.componentRef.instance.list_table = obj?.table;
      


      this.componentRef.instance.emitter.subscribe(() => {
        const config = {
          html2canvas: {
            scale: 2,
            scrollX: 0,
            scrollY: 0,
          },
          margin: [20, 0, 20, 0],
          pagebreak: {
            mode: "avoid-all",
          },
        };

        this.print(
          this.componentRef.location.nativeElement,
          config,
          obj?.title,
          false
        );
      });
    } catch (error) {
      console.log("ERROR:", error);
    }
  }

  private createPDFEasels(obj: any) {
    try {
      this.factory = this.resolver.resolveComponentFactory(GasEaselsComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);

      this.componentRef.instance.header = obj[0]?.header;
      this.componentRef.instance.table_1 = obj[0]?.list;
      this.componentRef.instance.table_2 = obj[1]?.list;
      this.componentRef.instance.local = obj[0].local;
      this.componentRef.instance.footer = obj[0].footer;

      this.componentRef.instance.emitter.subscribe(() => {
        const config = {
          html2canvas: {
            scale: 2,
            scrollX: 0,
            scrollY: 0,
          },
          margin: [20, 0, 20, 0],
          pagebreak: {
            mode: "avoid-all",
          },
        };

        this.print(
          this.componentRef.location.nativeElement,
          config,
          obj?.title,
          false
        );
      });
    } catch (error) {
      console.log("ERROR:", error);
    }
  }

  private createPDFTypeVG(obj: any) {
    this.factory = this.resolver.resolveComponentFactory(MinimaxComponent);
    this.componentRef = this.priceListPDF.createComponent(this.factory);
    this.componentRef.instance.header = obj[0].header;
    this.componentRef.instance.table_header = obj[0].list;
    this.componentRef.instance.table_1 = obj[1].list;
    this.componentRef.instance.table_2 = obj[2].list;
    this.componentRef.instance.local = obj[0].local;
    this.componentRef.instance.footer = obj[0].footer;
    this.componentRef.instance.title = obj[1].title;
    this.componentRef.instance.observation = obj[1].observation;

    this.componentRef.instance.emitter.subscribe(() => {
      const config = {
        html2canvas: {
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
        margin: [20, 0, 20, 0],
        pagebreak: {
          mode: "avoid-all",
        },
      };

      this.print(
        this.componentRef.location.nativeElement,
        config,
        obj[1].title,
        false
      );
    });

    this.list_aba_table = []

   // console.log('-----', this.index_page);
    // console.log(this.spreadsheet.type,'88888' )
    // this.list_aba_table = []

    // this.list_aba_table = this.configImport.changeInfoPdf(this.spreadsheet, this.spreadsheetTable, this.spreadsheet.type,)
    // this.createPDF(this.list_aba_table[this.index_page], this.spreadsheet.type);



    // this.list_aba_table = this.configImport.changeInfoPdf(this.spreadsheet, this.spreadsheetTable, this.spreadsheet.type,)
    // this.createPDF(this.list_aba_table[this.index_page], this.spreadsheet.type);
  }

  private createPDF(obj: any, type: string): void {
    // console.log(obj);
    
    this.priceListPDF.clear();

    // F.002_Casa_de_bombas_GWM.xlsx
    if (type == "Casa de Bombas") {
      console.log("Casa");

      this.factory = this.resolver.resolveComponentFactory(PumpHouseComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }

    // F.006_Checklist_Extintores.xlsx
    else if (type == "Checklist Extintores") {
      console.log("Extintores");

      this.factory = this.resolver.resolveComponentFactory(
        ChecklistFireExtinguishersComponent
      );
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }

    // F.007_Checklist_Hidrant.xlsx
    else if (type == "Checklist Hidrante") {
      console.log("Hidrante");

      this.factory = this.resolver.resolveComponentFactory(
        ControlHidranteComponent
      );
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    // F.008_Checklist_Alarm.xlsx
    else if (type == "Checklist Alarme") {
      console.log("Alarme");

      this.factory = this.resolver.resolveComponentFactory(
        ChecklistAlarmComponent
      );
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    // F.009_Controle_vg
    else if (type == "Checklist VG") {
      console.log("VG");

      this.factory = this.resolver.resolveComponentFactory(ControlVgComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    //F.0010_pitura
    else if (type == "Pintura") {
      console.log("Pintura");

      this.factory = this.resolver.resolveComponentFactory(PaintingComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    // F.011_Gas_Fm
    else if (type == "Gás FM") {
      console.log("Gas");

      this.factory = this.resolver.resolveComponentFactory(GasFmComponent);
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    // F.012_saida_de_emergencia
    else if (type == "Saídas Emergências") {
      console.log("Saida");

      this.factory = this.resolver.resolveComponentFactory(
        EmergencyExitComponent
      );
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }
    // F.013_gas_easels
    // else if (type === "Cavaletes Gases") {
    //   console.log("Gases");

    //   console.log("GASES OBJ", obj);
    //   console.log("GASES TYPE", type);

    //   this.factory = this.resolver.resolveComponentFactory(GasEaselsComponent);
    //   this.componentRef = this.priceListPDF.createComponent(this.factory);
    //   console.log("terminou");
    // }
    // F.014_minimax
    // else if (type == "VG's Minimax") {
    //   console.log("Minimax");
    //   this.index_page = 1;
    //   this.factory = this.resolver.resolveComponentFactory(MinimaxComponent);
    //   this.componentRef = this.priceListPDF.createComponent(this.factory);
    // }
    // F.015_Checklist_Extintores
    else if (type == "Checklist Extintores") {
      console.log("Extintores");

      this.factory = this.resolver.resolveComponentFactory(
        ChecklistFireExtinguishersComponent
      );
      this.componentRef = this.priceListPDF.createComponent(this.factory);
    }

    if (
      type == "Checklist Extintores" ||
      type == "Checklist VG" ||
      type == "Checklist Alarme" ||
      type == "Casa de Bombas" ||
      type == "Checklist Extintores" ||
      type == "Checklist Hidrante" ||
      type == "Pintura" ||
      type == "Saídas Emergências" ||
      type == "Gás FM" ||
      type == "Minimax" ||
      type == "VG's Minimax"
    ) {

      this.componentRef.instance.title = obj?.title;
      this.componentRef.instance.local = obj?.local;
      this.componentRef.instance.table = obj?.list;
      this.componentRef.instance.list_description = obj?.list_description;
      this.componentRef.instance.header = obj?.header;
      this.componentRef.instance.footer = obj?.footer;
      this.componentRef.instance.observation = obj?.observation;

      this.componentRef.instance.emitter.subscribe(() => {
        const config = {
          html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
          },
          margin: [20, 0, 20, 0],
          pagebreak: {
            mode: "avoid-all",
          },
        };

        this.print(
          this.componentRef.location.nativeElement,
          config,
          obj?.title,
          true
        );
      });
    }
  }

  private print(content: any, config: any, title: string, next: boolean): void {
    html2pdf()
      .set(config)
      .from(content)
      .toPdf()
      .get("pdf")
      .save(`${this.spreadsheet.type} - ${title}.pdf`);
    // .then(function (pdf) {
    //   pdf.addPage();
    // }).
    // toPdf().save(`${this.spreadsheet.type} - ${title}.pdf`);
      // .save(`${this.spreadsheet.type} - ${title}.pdf`);

    if (this.index_page == 0) {
      this.openMessage();
    }

    if (next) this.nextAbaPdf();
  }

  nextAbaPdf() {
    this.index_page++;

    if (this.list_aba_table.length > this.index_page) {
      this.componentRef.destroy();
      setTimeout(() => {
        // document.getElementById('button_pdf').click();
        this.gerarPDF();
      }, 1000);
    }
  }

  openMessage() {
    this.modalMsg = this.modalService.show(ModalMessageComponent, {
      // backdrop: 'static',
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered popup-transparent",
      containerClass: "",
      animated: false,
      data: {
        title: "Gerando PDF",
        text: "O PDF esta sendo gerado para Download, esse procedimento pode levar alguns minutos.",
        has_button: false,
        button_text: "",
        button_cancel: "",
      },
    });
    this.modalMsg.content.action.subscribe((result: any) => {
      if (result == "yes") {
      }
    });
  }
}

interface DescriptionJson {
  [value: string]: DescriptionObject;
}

interface DescriptionObject {
  [value: string]: string;
}

interface DescriptionKeys {
  [value: string]: string[];
}
