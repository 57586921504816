<div class="screen">

    <div class="div-imagens" *ngIf="loading">

        <div *ngFor="let item of listImage" style="width: 100%;">
            
            <img *ngIf="item.image!=null" 
                loop=infinite
                [src]="item.image" class="photo" 
                (click)="addNewItem(item)">
            
            <img *ngIf="item.url!=null" 
                loop=infinite
                [src]="item.url" class="photo" 
                (click)="addNewItem(item)">

        </div>

    </div>
    
</div>