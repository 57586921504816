import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(private http: HttpClient) { }

  getNotices(page): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`),
      params: new HttpParams()
        .set(`establishment`, localStorage.getItem("id_establishment"))
        .set(`page`, page)
    };

    return this.http.get<any>(AppConfig.path + `core/eye-news/`, options);
  }

  getNoticeId(id): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      // .set('Authorization', `token ${localStorage.getItem('token')}`) // <-- REMOVER

    return this.http.get<any>(AppConfig.path + `core/eye-news/${id}/`, { headers });
  }


  postNotice(notice): Observable<any> {

    let body = new HttpParams()
      .set(`establishment`, localStorage.getItem("id_establishment"))
      .set(`name`, notice.name)
      .set(`describe`, notice.describe)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/eye-news/`, body, { headers })
  }

  patchNotice(notice): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`establishment`, localStorage.getItem("id_establishment"))
      .set(`name`, notice.name)
      .set(`describe`, notice.describe)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/eye-news/${notice.id}/`, body, { headers })
  }


  deleteNotice(id): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/eye-news/${id}/`, { headers })
  }

  postParagraphNotice(paragraph, id_notice): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`eye_news`, id_notice)
      .set(`text`, paragraph.text)
      .set(`image`, encodeURI(paragraph.image))
      .set(`order`, paragraph.order)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/news/`, body, { headers })
  }

  patchParagraphNotice(paragraph): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`text`, paragraph.text)
      .set(`image`, encodeURI(paragraph.image))
      .set(`order`, paragraph.order)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/news/${paragraph.id}/`, body, { headers })
  }
}
