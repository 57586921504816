<mdb-navbar style="position: fixed; width: 100%; z-index: 999; background-color: #fff !important;"
    SideClass="navbar navbar-expand-lg navbar-dark fixed-top top-nav-collapse"  >

    <mdb-navbar-brand> 
        <img src="../../assets/Tela Login/menu.png" class="image_user" style="cursor: pointer;">
    </mdb-navbar-brand>

    <img src="../../assets/image/logo-letra-branca.png" routerLink="/" class="img-logo">
  
    <links>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link"> Login </a>
                <img src="../../assets/Tela Login/login.png" class="image_user">
            </li>
        </ul>
    </links>

</mdb-navbar>

<div class="space"></div>

<div class="scrren">
    <div class="image_background"> </div>

    <div class="div-login" *ngIf="viewLogin">
        <div class="img" routerLink="/home" >
            <img src="../../assets/image/logo-letra-preta.png" >
        </div>

        <h1> Login </h1>

        <input type="text" [(ngModel)]="email" placeholder="email"
            class="input" [ngStyle]="{'border-color': warningMap.get('email')? 'red' : ''}" >

        <input type="password" [(ngModel)]="password" placeholder="senha" (keyup.enter)="login()"
            class="input" [ngStyle]="{'border-color': warningMap.get('password')? 'red' : ''}" >

        <button mat-button class="b-border btn-orange" (click)="login()"> {{ textButton }} 
            <span *ngIf="loading" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>
        </button>
        
        <div style="width: 100%; height: 22px; margin-top: 10px; text-align: center; font-size: 14px; color: rgb(75, 4, 4);"> 
            {{ msg_error }} 
        </div>

        <!-- <div class="text_options">
            Esqueceu a senha?
        </div>

        <div class="text_options">
            Não tem cadastro?
        </div> -->
    </div>

    <div class="div-login" *ngIf="!viewLogin">
        <h2 style="text-align: center;"> Selecione o estabelecimento desejado: </h2>

        <mat-select [(ngModel)]="establishment" class="select-box" placeholder="Selecione...">
            <mat-option *ngFor="let obj of list_establishment; let i = index" [value]="obj">
                {{ obj.name }}
            </mat-option>
        </mat-select>

        <button mat-button class="b-border m_top" (click)="nextPage()"> {{ textButton }} 
            <span *ngIf="loading" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>
        </button>
    </div>
</div>


