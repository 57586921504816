import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})

export class ReminderService {

    constructor(private http : HttpClient) { }

    getReminderById(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`) // <-- REMOVER

        return this.http.get<any>(AppConfig.path + `notification/reminder/${id}`, {headers})
    }

    getReminder(establishment, reminder_type, page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, establishment)
                .set(`reminder_type`, reminder_type)
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + "notification/reminder/", options)
    }

    postReminder(title, establishment, group, image, start_time, end_time, message, minutes_before, reminder_type): Observable<any>{
        var qrcode = '198';
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`title`, title)
            .set(`establishment`, establishment)
            .set(`qr_code`, qrcode)
            .set(`group`, group)
            .set(`image`, encodeURI(image))
            .set(`start_time`, start_time)
            .set(`end_time`, end_time)
            .set(`message`, message)
            .set(`minutes_before`, minutes_before)
            .set(`reminder_type`, reminder_type)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)


        return this.http.post<any>(AppConfig.path + `notification/reminder/`, body, { headers });
    }

    patchReminder(id, title, establishment, group, image, start_time, end_time, message, minutes_before): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`title`, title)
            .set(`establishment`, establishment)
            // .set(`qr_code`, qr_code)
            .set(`group`, group)
            .set(`image`, encodeURI(image))
            .set(`start_time`, start_time)
            .set(`end_time`, end_time)
            .set(`message`, message)
            .set(`minutes_before`, minutes_before)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `notification/reminder/${id}/`, body, { headers });
    }

    deleteReminder(id): Observable<any>{
     
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `notification/reminder/${id}/`, { headers });
    }

}
