import { dateMapper } from "src/app/utils/validator.utils";

export const getGasEaselsConfig = () => ({
    header: {
        worksheet: 'Gases',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 3, col: 8, key: 'place' },
            { row: 6, col: 2, key: 'verifications' },
            { row: 6, col: 9, key: 'inspector' },
            { row: 6, col: 13, key: 'month' },
            { row: 6, col: 15, key: 'daytime' },
            { row: 6, col: 17, key: 'leader' },
            { row: 6, col: 19, key: 'note' },
            { row: 54, col: 2, key: 'other_information' },
            // { row: 19, col: 5, key: 'DAY_TESTE' },
        ],
    },

    verifications_list_1: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 7,
        endRows: 14,
        columns: [
            { index: 2, key: 'value'},
            { index: 9, key: 'status'},

        ],
    },

    verifications_list_2: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 7,
        endRows: 14,
        columns: [
            { index: 11, key: 'value'},
            { index: 17, key: 'status'},

        ],
    },

    days_1: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 16,
        endRows: 41,
        columns: [
            { index: 2, key: 'day'},
            { index: 5, key: 'first_hour'},
            { index: 7, key: 'second_hour'},
            { index: 9, key: 'name'},
        ],
    },

    days_2: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 42,
        endRows: 49,
        columns: [
            { index: 2, key: 'day'},
            { index: 5, key: 'first_hour'},
            { index: 7, key: 'second_hour'},
            { index: 9, key: 'name'},
        ],
    },

    days_3: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 17,
        endRows: 41,
        columns: [
            { index: 11, key: 'day'},
            { index: 13, key: 'first_hour'},
            { index: 15, key: 'second_hour'},
            { index: 17, key: 'name'},
        ],
    },

    days_4: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 42,
        endRows: 49,
        columns: [
            { index: 11, key: 'day'},
            { index: 13, key: 'first_hour'},
            { index: 15, key: 'second_hour'},
            { index: 17, key: 'name'},
        ],
    },

    footer: {
        worksheet: 'Gases',
        type: 'list',
        rowOffset: 50,
        endRows: 52,
        columns: [
            { index: 2, key: 'elaborate' },
            { index: 11, key: 'approved' },
            { index: 18, key: 'date', mapper: dateMapper },
            { index: 21, key: 'reference' },
        ],
    },


});
