<div style="margin: 0 50px;">
    <table>
        <tr>
            <th>
                <div class="title">
                    <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
                        src="../../../../assets/image/logo_principal_SBS.png">
                    <h3>Formulário de Inspeção da Casa de Bombas</h3>
                </div>
            </th>
        </tr>
    </table>
    <br><br>

    <table>
        <tr style="font-size: 10px;">
            <th> Frequência dos testes </th>
            <th> Executante do teste </th>
            <th>Dia e mês da execução </th>
            <th> Inicio/hora do teste </th>
        </tr>
        <tr style="font-size: 8px;">
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
        </tr>
    </table>

    <div *ngFor="let section of list_table">
        <div class="title">
            {{ section.title }}
        </div>
        <div style="width: 100%;" *ngFor="let table_list of section.list_table">
            <table>
                <tr style="font-size: 10px;">
                    <th> {{ table_list['name_table'] }} </th>
                    <th> OK </th>
                    <th> NP </th>
                    <th> Valor </th>
                </tr>
                <tr *ngFor="let row of table_list.table" style="font-size: 8px;">
                    <td> {{ row.table['NAME'] }} </td>
                    <td> {{ row.table['OK'] }} </td>
                    <td> {{ row.table['NP'] }} </td>
                    <td> {{ row.table['VALOR'] }} </td>
                    <!-- <td> a </td>
                <td> a </td>
                <td> a </td>
                <td> a </td> -->
                </tr>
            </table>
            <br>
        </div>
        <br><br>
    </div>


    <!-- <table>
        <tr style="font-size: 10px;">
            <th> Leds do painel principal</th>
            <th>Falhas e advertências
            </th>
            <th>Voltagem da bateria 1
            </th>
            <th> Voltagem da bateria 2
            </th>
            <th>Pressão de rede
            </th>
            <th>Bombas limpas e desobstruídas
            </th>
            <th>Manômetros
            </th>
        </tr>
        <tr  *ngFor="let row of table.lis" style="font-size: 8px;">
            <td> {{ row[''] }} </td>
            <td> </td>
            <td> </td>
            <td> </td>
        </tr>
    </table>
    <br> <br>
    <div class="title">
        PAINEL SECUNDÁRIO

    </div>
    <table>
        <tr style="font-size: 10px;">
            <th> Leds do painel secundário</th>
            <th>Falhas e advertências
            </th>
            <th>Pressão de óleo</th>
            <th> Temperatura da água</th>
            <th>Botões acionadores sem avarias </th>
            <th>Chave de comando posição correta</th>
            <th>Chave de comando posição correta</th>
        </tr>
        <tr style="font-size: 8px;">
            <td> Quinzenal </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td></td>
            <td></td>
            <td> </td>
        </tr>
    </table>
    <br><br>
    <div class="title">
        MOTOR 1
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th> Nível do óleo do motor</th>
            <th>Nível de água do radiador</th>
            <th>Vazamentos</th>
            <th> Conjunto de baterias 1</th>
            <th>Conjunto de baterias 2</th>
            <th>Válvula de entrada e saída</th>
            <th>Motores, válvulas, tubulações sem vaz.</th>
        </tr>

        <tr style="font-size: 8px;">
            <td> Quinzenal </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td></td>
            <td></td>
            <td> </td>
        </tr>
    </table>
    <br><br>
    <div class="title">
        RESERVATÓRIO DIESEL
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Nível reservatório 1</th>
            <th>Linha de abastecimento de combustível</th>
            <th>Dique de contenção</th>
        </tr>

        <tr style="font-size: 8px;">
            <td>a </td>
            <td> </td>
            <td> </td>
        </tr>
    </table>

    <br><br>
    <div class="title">
        BOMBA JOCKEY
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Vazamento caixa 1</th>
            <th>Conservação caixa 1</th>
            <th>Conservação caixa 2</th>
        </tr>

        <tr style="font-size: 8px;">
            <td>a </td>
            <td> b</td>
            <td> </td>
        </tr>
    </table>

    <br><br>
    <div class="title">
        <h3> teste das Bombas de combate a incêndio </h3>
    </div>
    <div class="title">
        <p>Bomba diesel 1</p>
    </div>
    <div class="title">
        <p>painel principal</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Botão de parada</th>
            <th>Pressão de água no painel</th>
            <th>Tensão da bateria 1</th>
            <th>Tensão da bateria 2</th>
            <th>Motor em modo automático</th>
            <th>Manômetro
            </th>
        </tr>
        <tr>
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>

    <div class="title">
        <p>painel secundário</p>
    </div>

    <table>
        <tr style="font-size: 10px;">
            <th>Botão de parada</th>
            <th>Temperatura </th>
            <th>Tensão do alternador</th>
            <th>Horimetrp</th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>

            <td></td>
        </tr>
    </table>
    <div class="title">
        <p>Motor 1</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Afogador manual
            </th>
            <th>Manômetro entrada
            </th>
            <th>Manômetro saída
            </th>
            <th>Barulhos anormais
            </th>
            <th>vazamento
            </th>
            <th>Posição correta das válvulas
            </th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>

    </table>
    <div class="title">
        <p> Bomba JOCKEY</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>painel</th>
            <th>válvulas de entrada e saída</th>
            <th>Válvulas de retenção
            </th>
            <th>Estado geral
            </th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>
    <br><br>
    <div class="title">Bomba diesel 2</div>
    <div class="title">
        <p>painel principal</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Botão de parada</th>
            <th>Pressão de água no painel</th>
            <th>Tensão da bateria 1</th>
            <th>Tensão da bateria 2</th>
            <th>Motor em modo automático</th>
            <th>Manômetro
            </th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>

    <div class="title">
        <p>painel secundário</p>
    </div>

    <table>
        <tr style="font-size: 10px;">
            <th>Botão de parada</th>
            <th>Temperatura </th>
            <th>Tensão do alternador</th>
            <th>Horimetrp</th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>

            <td></td>
        </tr>
    </table>
    <div class="title">
        <p>Motor 2</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>Afogador manual
            </th>
            <th>Manômetro entrada
            </th>
            <th>Manômetro saída
            </th>
            <th>Barulhos anormais
            </th>
            <th>vazamento
            </th>
            <th>Posição correta das válvulas
            </th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>

    </table>
    <div class="title">
        <p> Bomba JOCKEY</p>
    </div>
    <table>
        <tr style="font-size: 10px;">
            <th>painel</th>
            <th>válvulas de entrada e saída</th>
            <th>Válvulas de retenção
            </th>
            <th>Estado geral
            </th>
        </tr>
        <tr style="font-size: 8px;">
            <td>a</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table> -->
</div>