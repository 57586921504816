import { Component, OnInit } from '@angular/core';
import { TransitionCheckState } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Survey } from 'src/app/model/survey';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { SurveyService } from 'src/app/service/survey.service';

@Component({
    selector: 'app-survey-answers',
    templateUrl: './survey-answers.component.html',
    styleUrls: ['./survey-answers.component.css']
})
export class SurveyAnswersComponent implements OnInit {

    constructor(
       private campaignService: CampaignService,
       private route: ActivatedRoute,
       private surveyService: SurveyService,
       private storageService: StorageService
    ) { 
        this.survey_id = this.route.snapshot.paramMap.get('id');
    }

    loading: boolean = true;
    survey_id;

    displayedColumns: string[] = ['name', 'answer', 'date'];
    dataSource = new MatTableDataSource<any>();

    id_campaign
    list_campaign = []

    id_question;
    list_question = []

    list_all_answers = []
    question;

    date = {
        start_date: null,
        end_date: null,
        send_start_date: null,
        send_end_date: null
    }

    checkList = []

    color_primary;
    list_color = []

    list_graphic_register = [{id: null, name: '', qtd: 0, user: null}]
    list_graphic_not_register = [{id: null, name: '', qtd: 0, user: null}]

    list_user_campaign = []

    list_survey = []

    // graphics 
    graphic_survey;

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
        this.list_color = this.storageService.setListColor(this.color_primary)        


        this.date.start_date = this.startDayWeek(new Date())
        this.date.end_date = this.endDayWeek(new Date())
        this.date.send_start_date = this.convertDate(this.startDayWeek(new Date()))
        this.date.send_end_date = this.convertDate(this.endDayWeek(new Date()))

        this.getSurveyId()
        this.getCampaigns(1)
    }
    
    getSurveyId(){
        this.surveyService.getSurveyId(this.survey_id).subscribe( data => {
            this.list_question = data.questions;
            this.question = data.questions[0]

            this.list_graphic_register = []
            this.list_graphic_not_register = []
            this.question.answers.forEach(obj => {
                // console.log(obj)
                this.list_graphic_register.push({id: obj.id, name: obj.title, qtd: 0, user: null})
                this.list_graphic_not_register.push({id: obj.id, name: obj.title, qtd: 0, user: null})
            });

            this.id_question = this.list_question[0].id;

        },erro=>{
            this.loading = false;
        })
    }    

    getCampaigns(page){
        this.campaignService.getCampaigns(page).subscribe(data=>{

            this.list_campaign = this.list_campaign.concat(data.results);

            if (data.next != null) {
                this.getCampaigns(page + 1)
            }
            else{
                this.id_campaign = this.list_campaign[0].id;
                this.selectCampaign(this.id_campaign)
            }
        },
        error=>{
            this.loading = false;
        })
    }

    selectQuestion(question){
        this.question = question
        this.reset()
        this.getAnswerSurvey(1)
    }

    selectCampaign(campaign){
        this.reset()
        this.id_campaign = campaign.id != null ? campaign.id : campaign;
        this.getAnswerSurvey(1)
    }

    getAnswerSurvey(page){
        this.surveyService.getAnswerSurvey(page, this.id_campaign, null).subscribe(data=>{
            this.list_all_answers = this.list_all_answers.concat(data.results);

            if(data.next != null){
                this.getAnswerSurvey(page+1)
            }
            else{
                // console.log(this.list_all_answers)
                this.tratamento()
            }
        })
    }

    reset(){
        this.list_all_answers = []
        this.checkList = []
        this.list_graphic_register = []
        this.list_graphic_not_register = []

        this.question.answers.forEach(obj => {
            this.list_graphic_register.push({id: obj.id, name: obj.title, qtd: 0, user: null})
            this.list_graphic_not_register.push({id: obj.id, name: obj.title, qtd: 0, user: null})
        });
    }

    tratamento(){
        let position;
        let list_user_register = []

        this.list_all_answers.forEach(obj => {

            if(obj.user_campaign == null){
                position = this.list_graphic_register.findIndex( x => x.id === obj.answer );
                if(position != -1){
                    this.list_graphic_register[position].qtd += 1;
                }
            }
            else{
                position = this.list_graphic_not_register.findIndex( x => x.id === obj.answer );
                if(position != -1){
                    this.list_graphic_not_register[position].qtd += 1;
                    list_user_register.push(obj)
                }
            }
            this.checkList.push(1)

        });

        // console.log(list_user_register)

        list_user_register.forEach(user => {
            position = this.question.answers.findIndex( x => x.id === user.answer );
            if(position != -1){
                this.list_user_campaign.push({user: user.user_campaign_obj.name, answer: this.question.answers[position].title, date: user.created_at })
            }
        });

        // console.log(this.list_user_campaign)
        this.dataSource.data = this.list_user_campaign;

        this.loading = false;
        setTimeout(() => {
            this.graphicSurveyRegister()
        }, 100);
    }

    graphicSurveyRegister(){
          
        var list_series = []
        var list_labels_register = []
        var list_labels_not_register = []
        var list_color = []

        if(this.checkList.length > 0){
            this.list_graphic_register.forEach((item, index) => {
                list_series.push(item.name)
                list_labels_register.push(item.qtd)
                // list_color.push(this.list_color[index])
            });
            this.list_graphic_not_register.forEach((item, index) => {
                // list_series.push(item.name)
                list_labels_not_register.push(item.qtd)
                // list_color.push(this.list_color[index])
            });
            
            var options = {
                series: [
                    {
                        name: "Cadastrados",
                        data: list_labels_register
                    },
                    {
                        name: "Não cadastrados",
                        data: list_labels_not_register
                    },
                ],
                colors: [this.list_color[0], this.list_color[2]],
                chart: {
                    type: "bar",
                    height: 250
                },
                plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: "35%",
                    endingShape: "rounded"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"]
                },
                xaxis: {
                    categories: list_series
                },
                yaxis: {
                    title: {
                        text: "Respostas dos usuários"
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val + " resposta";
                        }
                    }
                }
            };
            
            this.graphic_survey = new ApexCharts(document.querySelector("#chart-survey"), options);
            this.graphic_survey.render();
        }
        else{
            // this.graphic_navegation.destroy()
        }
        
    }

    addEvent(event: MatDatepickerInputEvent<Date>, item) {
        if(event.value != null){
            this.date.start_date = item.start_date
            this.date.end_date = item.end_date
            this.date.send_start_date = this.convertDate(item.start_date)
            this.date.send_end_date = this.convertDate(item.end_date)

            this.selectCampaign(this.id_campaign)
        }
    }

    convertDate(data){
        if(data != null){
            var d = new Date(data),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            var aux_data = [year, month, day].join('-')
    
            return aux_data;
        }
        else{
            return null
        }
        
    }

    endDayWeek(date){
        let day, month, year;
        day = new Date(date).getDate()
        month = (new Date(date).getMonth() + 1)
        year = new Date(date).getFullYear()
        
        return new Date(year+'-'+month+'-'+day)
    }

    startDayWeek(date){
        let day, month, year;
        var d = new Date(date);
        d.setDate(d.getDate() - 7);
        day = new Date(d).getDate() 
        month = (new Date(d).getMonth() + 1)
        year = new Date(d).getFullYear()

        return new Date(year+'-'+month+'-'+day)
    }
}
