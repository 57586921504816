import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { ActiveCampaign, Campaign_qrcode } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class CampaignService {

    constructor(private http: HttpClient) { }

    listSubject = new Subject<String>();
    establishment = "id_establishment"

    watchList(): Observable<any> {
        return this.listSubject.asObservable();
    }

    changeList(): void {
        this.listSubject.next('changed');
        console.log('changed')
    }


    getCampaigns(page): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };
        return this.http.get<any>(AppConfig.path + `core/campaign/`, options);
    }

    getCampaignsPosts(page, campaign, size): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`page_size`, size)
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
                .set(`campaign`, campaign),
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
        };
        return this.http.get<any>(AppConfig.path + `core/campaign-posts/`, options);
    }

    getCampaignsPostsId(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        };
        return this.http.get<any>(AppConfig.path + `core/campaign-posts/${id}/`, options);
    }

    getCampaignId(id): Observable<any> {

        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('user', `${localStorage.getItem('tokenUser')}`),
        }

        return this.http.get<any>(AppConfig.path + `core/campaign/${id}/`, options);
    }

    getActiveCampaign(id): Observable<ActiveCampaign> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        }

        return this.http.get<ActiveCampaign>(AppConfig.path + `core/campaign/${id}/active-campaing/`, options);
    }

    getLogCampaign(id_campaign, page, start_date, end_date, size): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`campaign`, id_campaign)
                .set(`page`, page)
                .set(`start_date`, start_date)
                .set(`end_date`, end_date)
                .set(`page_size`, size)
        };

        return this.http.get<any>(AppConfig.path + `core/campaign/logs/`, options);
    }


    postCampaign(name, show_header, header, request_registration, header_color, text_color, icons_color, pdfTerm,
        homepage_format, id_coupon, whatsapp, logo, facebook, instagram, youtube, side_image, is_feed): Observable<Campaign_qrcode> {

        let body = new HttpParams({ encoder: new CustomEncoder() })

            .set(`name`, name)
            .set(`header_text`, header)
            .set(`show_header`, show_header)
            // .set(`request_registration`, request_registration)
            .set(`header_color`, header_color)
            .set(`text_color`, text_color)
            .set(`icons_color`, icons_color)
            .set('establishment', localStorage.getItem(this.establishment))
            .set(`whatsapp`, whatsapp)
            .set(`facebook`, facebook)
            .set(`instagram`, instagram)
            .set(`youtube`, youtube)
            .set(`is_feed`, is_feed)

        if (pdfTerm != null && pdfTerm != '') {
            body = body.set(`terms`, encodeURI(pdfTerm))
        }
        if (homepage_format != null && homepage_format != '') {
            body = body.set(`homepage_format`, homepage_format)
        }
        if (logo != null && logo != '') {
            body = body.set(`logo`, encodeURI(logo))
        }
        if (side_image != null && side_image != '') {
            body = body.set(`side_menu_image`, encodeURI(side_image))
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "core/campaign/", body.toString(), { headers })
    }

    patchCampaign(id, name, show_header, header, request_registration, header_color, text_color, icons_color, pdfTerm,
        homepage_format, id_coupon, whatsapp, logo, facebook, instagram, youtube, side_image, is_point_control): Observable<Campaign_qrcode> {

        let body = new HttpParams({ encoder: new CustomEncoder() })

        if (name != null) {
            body = body.set(`name`, name)
        }
        if (show_header != null) {
            body = body.set(`show_header`, show_header)
        }
        if (header != null) {
            body = body.set(`header_text`, header)
        }
        // if (request_registration != null) {
        //     body = body.set(`request_registration`, request_registration)
        // }
        if (header_color != null) {
            body = body.set(`header_color`, header_color)
        }
        if (text_color != null) {
            body = body.set(`text_color`, text_color)
        }
        if (icons_color != null) {
            body = body.set(`icons_color`, icons_color)
        }
        if (homepage_format != null) {
            body = body.set(`homepage_format`, homepage_format)
        }
        if (id_coupon != null) {
            body = body.set(`coupon`, id_coupon)
        }
        //ERRO WHATSAPP
        if (whatsapp == '' || (whatsapp != null && whatsapp.length == 13)) {
            body = body.set(`whatsapp`, whatsapp)
        }
        if (logo != '' && logo != null) {
            body = body.set(`logo`, encodeURI(logo))
        }
        if (logo == '' || logo == null) {
            body = body.set(`logo`, '')
        }
        if (side_image == '' || side_image != null) {
            body = body.set(`side_menu_image`, encodeURI(side_image))
        }
        if (pdfTerm == '' || pdfTerm != null) {
            body = body.set(`terms`, encodeURI(pdfTerm))
        }
        if (facebook == '' || (facebook != null)) {
            body = body.set(`facebook`, facebook)
        }
        if (instagram == '' || (instagram != null)) {
            body = body.set(`instagram`, instagram)
        }
        if (youtube == '' || (youtube != null)) {
            body = body.set(`youtube`, youtube)
        }
        if (is_point_control != null) {
            body = body.set(`is_point_control`, is_point_control)
        }


        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign/${id}/`, body.toString(), { headers })
    }

    createPost(id_campaign, name, homepage_format): Observable<Campaign_qrcode> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`campaign`, id_campaign)
            .set(`name`, name)
            .set(`homepage_format`, homepage_format)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "core/campaign-posts/", body, { headers })
    }

    editPost(id_post, id_campaign, name, homepage_format): Observable<Campaign_qrcode> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`campaign`, id_campaign)
            .set(`name`, name)
            .set(`homepage_format`, homepage_format)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign-posts/${id_post}/`, body, { headers })
    }

    editOrderPost(post): Observable<Campaign_qrcode> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`order`, post.order)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign-posts/${post.id}/`, body, { headers })
    }

    patchCampaignScheduling(id, start_date, end_date): Observable<Campaign_qrcode> {

        let body = new HttpParams()
        if (start_date != null) {
            body = body.append(`start_date`, start_date)
        }
        if (end_date != null) {
            body = body.append(`end_date`, end_date)
        }


        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign/${id}/`, body.toString(), { headers })
    }

    statusPublishCampaign(id, active): Observable<Campaign_qrcode> {

        let body = new HttpParams()
        if (active != null) {
            body = body.append(`active`, active)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign/${id}/`, body.toString(), { headers })
    }

    postHomepageImages(image, campaign, campaign_post, order, page_format, title, point_control_name, description, link, course, notice, home_page_image_type, castbox, deezer, itunes, google_podcast, tabloide): Observable<any> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`page_format`, page_format)
        // .set(`image`, encodeURI(image))

        if (campaign_post != null) { body = body.append(`feed_campaign_post`, campaign_post) }
        if (description != null) { body = body.append(`description`, description) }
        if (image != null) { body = body.append(`image`, encodeURI(image)) }
        if (title != null) { body = body.append(`title`, title) }
        if (point_control_name != null) { body = body.append(`point_control_name`, point_control_name) }
        if (link != null) { body = body.append(`link`, link) }
        if (course != null) { body = body.append(`course`, course) }
        if (notice != null) { body = body.append(`eye_news`, notice) }
        if (tabloide != null) { body = body.append(`tabloide`, tabloide) }
        if (home_page_image_type != null) { body = body.append(`home_page_image_type`, home_page_image_type) }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        if (order == null) {
            body = body.append(`order`, '1')
        }
        else {
            body = body.append(`order`, order)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/campaign/${campaign}/homepage-image/`, body.toString(), { headers })
    }

    patchHomepageImages(id, campaign_post, image, order, page_format, title, description, link, course, notice, home_page_image_type, castbox, deezer, itunes, google_podcast, tabloide): Observable<any> {

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`page_format`, page_format)
        // .set(`image`, encodeURI(image))
        if (campaign_post != null) { body = body.append(`feed_campaign_post`, campaign_post) }
        if (image != null) { body = body.append(`image`, encodeURI(image)) }
        if (title != null) { body = body.append(`title`, title) }
        if (description != null) { body = body.append(`description`, description) }
        if (link != null) { body = body.append(`link`, link) }
        if (course != null) { body = body.append(`course`, course) }
        if (notice != null) { body = body.append(`eye_news`, notice) }
        if (tabloide != null) { body = body.append(`tabloide`, tabloide) }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        if (home_page_image_type != null) { body = body.append(`home_page_image_type`, home_page_image_type) }


        if (order == null) {
            body = body.append(`order`, '1')
        }
        else {
            body = body.append(`order`, order)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.put<any>(AppConfig.path + `core/campaign/${id}/homepage-image-change/`, body.toString(), { headers })
    }

    postPageImages(image, campaign_post, order, homepage, page_format, link, catalog, survey,
        registration, coupon, title, point_control_name, description, page_type, course, 
        castbox, deezer, itunes, google_podcast, token_hashlar): Observable<any> {
            console.log(token_hashlar);
            
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`homepage`, homepage)
            .set(`page_format`, page_format)

        if (campaign_post != null) {
            body = body.append(`feed_campaign_post`, campaign_post)
        }
        if (link != null) {
            body = body.set('link', link)
        }
        if (course != null) {
            body = body.set('course', course)
        }
        if (catalog != null) {
            body = body.set('catalog', catalog)
        }
        if (survey != null) {
            body = body.set('survey', survey)
        }
        if (registration != null && registration != '') {
            body = body.set('registration_config', registration)
        }
        if (coupon != null && coupon != '') {
            body = body.set('coupon', coupon)
        }
        if (title != null && title != '') {
            body = body.set('title', title)
        }
        if (point_control_name != null && point_control_name != '') {
            body = body.set('point_control_name', point_control_name)
        }
        if (description != null && description != '') {
            body = body.set('description', description)
        }
        if (page_type != null) {
            body = body.append(`page_type`, page_type)
        }
        if (token_hashlar != null) {
            body = body.append(`token_hashlar`, token_hashlar)
        }
        if (image != null && image != '') {
            body = body.set('image', encodeURI(image))
        }
        if (order == null) {
            body = body.set(`order`, '1')
        }
        else {
            body = body.set(`order`, order)
        }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/campaign/${homepage}/page-image/`, body.toString(), { headers })
    }

    patchPageImages(id, campaign_post, image, order, homepage, page_format, link, catalog, survey,
        registration, coupon, title, point_control_name, description, page_type, course,
        castbox, deezer, itunes, google_podcast, token_hashlar): Observable<any> {
            console.log(token_hashlar);

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`homepage`, homepage)
            .set(`page_format`, page_format)

        if (campaign_post != null) {
            body = body.append(`feed_campaign_post`, campaign_post)
        }
        if (link != null) {
            body = body.set('link', link)
        }
        if (course != null) {
            body = body.set('course', course)
        }
        if (catalog != null) {
            body = body.set('catalog', catalog)
        }
        if (survey != null) {
            body = body.set('survey', survey)
        }
        if (registration != null && registration != '') {
            body = body.set('registration_config', registration)
        }
        if (coupon != null && coupon != '') {
            body = body.set('coupon', coupon)
        }
        if (title != null && title != '') {
            body = body.set('title', title)
        }
        if (point_control_name != null && point_control_name != '') {
            body = body.set('point_control_name', point_control_name)
        }
        if (description != null && description != '') {
            body = body.set('description', description)
        }
        if (page_type != null) {
            body = body.append(`page_type`, page_type)
        }
        if (token_hashlar != null) {
            body = body.append(`token_hashlar`, token_hashlar)
        }
        if (image != null && image != '') {
            body = body.set('image', encodeURI(image))
        }
        if (order == null) {
            body = body.set(`order`, '1')
        }
        else {
            body = body.set(`order`, order)
        }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.put<any>(AppConfig.path + `core/campaign/${id}/page-image-change/`, body.toString(), { headers })
    }

    postInternalPageImages(image, campaign_post, order, id_page, page_format,
        link, catalog, survey, registration, coupon, title, point_control_name, description, internal_page_type, course,
        castbox, deezer, itunes, google_podcast, token_hashlar): Observable<any> {
            console.log(token_hashlar);

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`page`, id_page)
            .set(`page_format`, page_format)

        if (campaign_post != null) {
            body = body.append(`feed_campaign_post`, campaign_post)
        }
        if (link != null) {
            body = body.set('link', link)
        }
        if (course != null) {
            body = body.set('course', course)
        }
        if (catalog != null) {
            body = body.set('catalog', catalog)
        }
        if (survey != null) {
            body = body.set('survey', survey)
        }
        if (registration != null && registration != '') {
            body = body.set('registration_config', registration)
        }
        if (coupon != null && coupon != '') {
            body = body.set('coupon', coupon)
        }
        if (title != null && title != '') {
            body = body.set('title', title)
        }
        if (point_control_name != null && point_control_name != '') {
            body = body.set('point_control_name', point_control_name)
        }
        if (description != null && description != '') {
            body = body.set('description', description)
        }
        if (token_hashlar != null) {
            body = body.append(`token_hashlar`, token_hashlar)
        }
        if (internal_page_type != null) {
            body = body.append(`internal_page_type`, internal_page_type)
        }
        if (image != null && image != '') {
            body = body.set('image', encodeURI(image))
        }
        if (order == null) {
            body = body.set(`order`, '1')
        }
        else {
            body = body.set(`order`, order)
        }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/campaign/${id_page}/internal-page/`, body.toString(), { headers })
    }

    patchInternalPageImages(id, campaign_post, image, order, id_page, page_format,
        link, catalog, survey, registration, coupon, title, point_control_name, description, internal_page_type, course,
        castbox, deezer, itunes, google_podcast, token_hashlar): Observable<any> {
            console.log(token_hashlar);

        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`page`, id_page)
            .set(`page_format`, page_format)

        if (campaign_post != null) {
            body = body.append(`feed_campaign_post`, campaign_post)
        }
        if (link != null) {
            body = body.set('link', link)
        }
        if (course != null) {
            body = body.set('course', course)
        }
        if (catalog != null) {
            body = body.set('catalog', catalog)
        }
        if (survey != null) {
            body = body.set('survey', survey)
        }
        if (registration != null && registration != '') {
            body = body.set('registration_config', registration)
        }
        if (coupon != null && coupon != '') {
            body = body.set('coupon', coupon)
        }
        if (title != null && title != '') {
            body = body.set('title', title)
        }
        if (point_control_name != null && point_control_name != '') {
            body = body.set('point_control_name', point_control_name)
        }
        if (description != null && description != '') {
            body = body.set('description', description)
        }
        if (token_hashlar != null) {
            body = body.append(`token_hashlar`, token_hashlar)
        }
        if (internal_page_type != null) {
            body = body.append(`internal_page_type`, internal_page_type)
        }
        if (image != null && image != '') {
            body = body.set('image', encodeURI(image))
        }
        if (order == null) {
            body = body.set(`order`, '1')
        }
        else {
            body = body.set(`order`, order)
        }

        if (castbox != null) { body = body.append(`castbox`, castbox) }
        if (deezer != null) { body = body.append(`deezer`, deezer) }
        if (itunes != null) { body = body.append(`itunes`, itunes) }
        if (google_podcast != null) { body = body.append(`google_podcast`, google_podcast) }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.put<any>(AppConfig.path + `core/campaign/${id}/internal-page-change/`, body.toString(), { headers })
    }

    deleteCampaign(id): Observable<any> {
        let body = new HttpParams()
            .set(`deleted`, 'true')

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign/${id}/`, body, { headers })
    }

    deleteHomepageImage(id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/campaign/${id}/delete-homepage/`, { headers })
    }

    deletePageImage(id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/campaign/${id}/delete-page/`, { headers })
    }

    deleteInternalImage(id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/campaign/${id}/delete-internal/`, { headers })
    }

    deletePost(id_post): Observable<Campaign_qrcode> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/campaign-posts/${id_post}/`, { headers })
    }

    getCampaignAccess(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/campaign-access/`, options)
    }

    postCampaignAccess(campaign, device, group): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`campaign`, campaign)

        if (device != null && device != "") {
            body = body.append('device', device)
        }

        if (group != null && group != "") {
            body = body.append('group', group)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/campaign-access/`, body, { headers })
    }

    patchCampaignAccess(id, campaign, qr_code): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`campaign`, campaign)
            .set(`qr_code`, qr_code)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/campaign-access/${id}/`, body, { headers })
    }

    deleteCampaignAccess(id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/campaign-access/${id}/`, { headers })
    }

    postNextCampaign(campaign, next_campaign): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`campaign`, campaign)
            .set(`next_campaign`, next_campaign)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/next-campaing/`, body, { headers })
    }

    // getRelatorio(date_from, date_to, campaign_id): Observable<any> {

    //     const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //     // .set('Authorization', `token ${localStorage.getItem('token')}`)

    //     return this.http.get<any>(AppConfig.path + `core/generate-campaing-report/?campaign_id=${campaign_id}&date_from=${date_from}&date_to=${date_to}`,
    //         { headers, responseType: 'blob' as 'json', observe: 'response' })
    // }


    getRelatorioExcel(date_from, date_to, campaign_id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/generate-report/?campaign_id=${campaign_id}&date_from=${date_from}&date_to=${date_to}`,
            { headers, responseType: 'blob' as 'json', observe: 'response' })
    }

    getRelatorioExcelEmail(date_from, date_to, campaign_id, email): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/generate-report-background/?campaign_id=${campaign_id}&date_from=${date_from}&date_to=${date_to}&email=${email}`,
            { headers, responseType: 'blob' as 'json', observe: 'response' })
    }
    
    getCampaignsRelatorio(page, establishment): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`establishment`, establishment)
                .set(`page`, page)
        };
        return this.http.get<any>(AppConfig.path + `core/campaign/`, options);
    }

}
