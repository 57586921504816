<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>

    </div>

    <input mat-input [(ngModel)]="name" placeholder="Escreva aqui o nome da categoria"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">

    <div style="width: 100%; position: relative; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <input mat-input [(ngModel)]="subcategory" placeholder="Escreva aqui o nome da subcategoria" 
            [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" (keyup.enter)="addSub(subcategory)"
            class="input">

        <button mat-button class="btn_add button-cor" [style.background]="color_primary"
            style="width: 100px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;" (click)="addSub(subcategory)">
            Adicionar
        </button>
    </div>

    <div class="size_sub custom-scrollbar" *ngIf="list_subcategory.length > 0">
        <div *ngFor="let sub of list_subcategory; let idx = index" class="card_sub">
            <span> {{ sub.name }} </span>
            <img src="/assets/icon/icon_excluir.png" (click)="deleteSub(idx)" matTooltip="Excluir"
                class="icon view-delet">
            <div class="_line" *ngIf="idx < list_subcategory.length-1 "></div>
        </div>
    </div>
    <!-- <div class="row_img_category_date">
            <div class="coll-img_category input-img"
                [ngStyle]="{'border-color': warningMap.get('img_category')? 'red' : ''}">

                <div *ngIf="img_category == null || img_category == ''" class="button-img_category" (click)="fileImage.click()">
                    adicione uma imagem
                </div>

                <input type="file" #fileImage style="display: none; " accept="img_category/*"
                    (change)="onFileChanged($event)">

                <img [src]="img_category" (click)="fileImage.click()" *ngIf="img_category != null"
                    class="profile-img_category">
            </div>
        </div> -->

    <div style="width: 100%; display: flex; justify-content: space-evenly; 
        align-items: center; text-align: center; margin-bottom: 15px;">

        <button mat-button class="btn_add button-cor" [style.background]="color_primary"
            style="width: 150px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;" (click)="close()">
            Fechar
        </button>

        <button mat-button class="btn_add button-cor" [style.background]="color_primary"
            style="width: 150px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;" (click)="save()">
            {{button_text}}
        </button>
    </div>

</div>