<div class="screen" *ngIf="!loading">
    <h3 class="border-gray">
        Informações Principais da siderbar
        {{steps.siderbar ? ' - FINALIZADO' : ''}}
    </h3>

    <input mat-input [(ngModel)]="desktop.title" placeholder="Escreva aqui o titulo"
        [ngStyle]="{'border-color': warningMap.get('title')? 'red' : ''}" class="input">

    <mat-select [(ngModel)]="type_select" class="select-box" placeholder="Selecione o tipo"
        style="color: rgb(117 117 117) !important; font-size: calc(10px + 0.3vw); margin: 8px 0;"
        [ngStyle]="{'border-color': warningMap.get('type')? 'red' : ''}">
        <mat-option *ngFor="let obj of type_list; let i = index" [value]="obj.name" (click)="selectType(obj)">
            {{ obj.name }}
        </mat-option>
    </mat-select>

    <mat-select style="font-size: calc(10px + 0.3vw); margin: 8px 0;" [(ngModel)]="side_select" class="select-box"
        placeholder="Selecione o lado" [ngStyle]="{'border-color': warningMap.get('side')? 'red' : ''}">
        <mat-option *ngFor="let obj of side_list; let i = index" [value]="obj.name" (click)="selectSide(obj)">
            {{ obj.name }}
        </mat-option>
    </mat-select>

    <div style="position: relative; width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;"
        *ngIf="type_select != 'Banner'">
        <img [src]="desktop.icon" (click)="filePages3.click()" class="image_logo"
            *ngIf="desktop.icon != null && desktop.icon != ''">

        <input style="font-size: calc(10px + 0.3vw); margin: 8px 0;" mat-input [(ngModel)]="desktop.icon_name"
            placeholder="Selecione uma imagem para o ícone" (click)="filePages3.click()"
            [style.paddingLeft]="(desktop.icon != null && desktop.icon != '') ? '85px' : '20px'"
            [ngStyle]="{'border-color': warningMap.get('image_icon')? 'red' : ''}" class="input" readonly>

        <span [matTooltip]="setInfoSizeImage('Icone')" class="btn_info">
            <svg width="20px" height="20px" viewBox="0 0 128.000000 128.000000" style="margin: 0 0 4px 5px;">
                <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#626368" stroke="none">
                    <path d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                        -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                        265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                        -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                        -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                        423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
                    <path d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                        -3 97 -22 19 -53 28 -73 21z" />
                    <path d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                        50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
                </g>
            </svg>
        </span>

        <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            *ngIf="desktop.icon != null && desktop.icon != ''" class="btn_delet" (click)="deletLogo()">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
                <path
                    d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
            </g>
        </svg>

    </div>

    <input #filePages3 type="file" accept="image/*" (change)="addLogo($event)" multiple="multiple"
        style="display: none;">

    <input mat-input [(ngModel)]="desktop.link_text" placeholder="Texto do botão" *ngIf="type_select != 'Galeria'"
        [ngStyle]="{'border-color': warningMap.get('link_text')? 'red' : ''}" class="input">

    <input *ngIf="type_select != 'Podcast' && type_select != 'Galeria'" mat-input [(ngModel)]="desktop.link"
        placeholder="Link do botão" [ngStyle]="{'border-color': warningMap.get('link')? 'red' : ''}" class="input">

    <h3 class="border-gray">
        {{type_select != null && type_select == 'Podcast' ? 'Informações do Podcast' : 'Informações da Imagem'}}
        {{steps.siderbar ? ' - FINALIZADO' : ''}}
    </h3>

    <!-- BANNER -->
    <div *ngIf="type_select != null && type_select == 'Banner'">

        <div style="position: relative; width: 100%;">
            <img [src]="desktop.imagens[0].image" (click)="filePages.click()" class="image_logo"
                *ngIf="(desktop?.imagens != null && desktop?.imagens[0]?.image != null && desktop.imagens[0].image != '')"
                style="cursor: pointer;">

            <input mat-input [(ngModel)]="desktop.imagens[0].name" style="cursor: pointer;"
                placeholder="Selecione uma imagem" (click)="filePages.click()"
                [style.paddingLeft]="(desktop?.imagens != null && desktop?.imagens[0]?.image != null && desktop.imagens[0].url != '') ? '85px' : '20px'"
                [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}" class="input" readonly>

            <span [matTooltip]="setInfoSizeImage('Image')" class="btn_info" *ngIf="side_select != 'Centro'">
                <svg width="20px" height="20px" viewBox="0 0 128.000000 128.000000" style="margin: 0 0 4px 5px;">
                    <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#626368"
                        stroke="none">
                        <path d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                                -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                                265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                                -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                                -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                                423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
                        <path d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                                -3 97 -22 19 -53 28 -73 21z" />
                        <path d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                                50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
                    </g>
                </svg>
            </span>
        </div>
        <input #filePages type="file" accept="image/*" (change)="onFileChanged($event)" multiple="multiple"
            style="display: none;">

        <input mat-input [(ngModel)]="desktop.imagens[0].link_text" placeholder="Texto do botão"
            [ngStyle]="{'border-color': warningMap.get('link_text'+selected_image)? 'red' : ''}" class="input">

        <input mat-input [(ngModel)]="desktop.imagens[0].link" placeholder="Link do botão"
            [ngStyle]="{'border-color': warningMap.get('link'+selected_image)? 'red' : ''}" class="input">
    </div>


    <!-- CARROSEL -->
    <div *ngIf="type_select != null && type_select == 'Carrosel'">

        <div class="card-center">
            <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal"
                *ngIf="desktop.imagens != null && desktop.imagens.length > 0"
                (cdkDropListDropped)="drop($event, desktop.imagens)">
                <div *ngFor="let image of desktop.imagens; let i = index" cdkDrag
                    style="display: flex; flex-direction: column; margin: 5px; padding: 5px; border: 1px solid #e9edef;">

                    <div
                        style="min-width: 150px; width: calc(100% - 10px); margin: 5px auto; display: flex; justify-content: space-between; align-items: center;">
                        <img src="../../../assets/icon/icon_edit_gray.png" matTooltip="Editar"
                            (click)="filePagesEdit.click()"
                            style="width: 15px; height: 15px; object-fit: contain; cursor: pointer;">

                        <img src="/assets/icon/close.png" matTooltip="Remover" (click)="removeImage(i)"
                            style="width: 15px; height: 15px; object-fit: contain; cursor: pointer;">
                    </div>

                    <img [src]="image.image" style="width: 150px; height: 150px; object-fit: contain;"
                        *ngIf="image?.image != null">
                    <div class="ellipsis-one" *ngIf="image.image != null"> {{ nameImage(image) }} </div>

                    <input #filePagesEdit type="file" accept="image/*"
                        (change)="editImage($event, i, null, null, 'home')" multiple="multiple" style="display: none;">

                </div>
            </div>

            <button mat-button class="button-cor height_36" [style.background]="color_primary"
                style="margin: 0; display: flex; flex-direction: row; justify-content: center; align-items: center;"
                [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}" (click)="filePages.click()">
                Adicionar imagens
                <span [matTooltip]="setInfoSizeImage(type_select)">
                    <svg width="20px" height="20px" viewBox="0 0 128.000000 128.000000" style="margin: 0 0 4px 5px;">
                        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#fff"
                            stroke="none">
                            <path d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                        -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                        265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                        -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                        -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                        423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
                            <path d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                        -3 97 -22 19 -53 28 -73 21z" />
                            <path d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                        50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
                        </g>
                    </svg>
                </span>
            </button>

            <input #filePages type="file" accept="image/*" (change)="onFileChanged($event)" multiple="multiple"
                style="display: none;">
        </div>

        <div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: space-between; align-items: self-start;"
            *ngIf="desktop.imagens != null && desktop.imagens.length > 0">
            <div style="width: 200px; position: relative;">

                <img [src]="desktop.imagens[selected_image].image" class="main_image">

                <mat-select [(ngModel)]="selected_image" class="select-box">
                    <mat-option *ngFor="let img of desktop.imagens; let i = index" (click)="selectImagePage(i)"
                        [value]="i">
                        {{ nameImage(img) }}
                    </mat-option>
                </mat-select>
            </div>

            <div style="width: calc(100% - 220px);">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    style="resize: none;" class="input" id="TextInput"
                    [(ngModel)]="desktop.imagens[selected_image].text" placeholder="Descrição"
                    [ngStyle]="{'border-color': warningMap.get('description'+selected_image)? 'red' : ''}"></textarea>

                <input mat-input [(ngModel)]="desktop.imagens[selected_image].link_text" placeholder="Texto do botão"
                    [ngStyle]="{'border-color': warningMap.get('link_text'++selected_image)? 'red' : ''}" class="input">

                <input mat-input [(ngModel)]="desktop.imagens[selected_image].link" placeholder="Link do botão"
                    [ngStyle]="{'border-color': warningMap.get('link'++selected_image)? 'red' : ''}" class="input">
            </div>
        </div>
    </div>


    <!-- GALERIA -->
    <div *ngIf="type_select != null && type_select == 'Galeria'">

        <div class="card-center">
            <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal"
                *ngIf="desktop.imagens != null && desktop.imagens.length > 0"
                (cdkDropListDropped)="drop($event, desktop.imagens)">
                <div *ngFor="let image of desktop.imagens; let i = index" cdkDrag
                    style="display: flex; flex-direction: column; margin: 5px; padding: 5px; border: 1px solid #e9edef;">

                    <div
                        style="min-width: 150px; width: calc(100% - 10px); margin: 5px auto; display: flex; justify-content: space-between; align-items: center;">
                        <img src="../../../assets/icon/icon_edit_gray.png" matTooltip="Editar"
                            (click)="filePagesEdit.click()"
                            style="width: 15px; height: 15px; object-fit: contain; cursor: pointer;">

                        <img src="/assets/icon/close.png" matTooltip="Remover" (click)="removeImage(i)"
                            style="width: 15px; height: 15px; object-fit: contain; cursor: pointer;">
                    </div>

                    <img [src]="image.image" style="width: 150px; height: 150px; object-fit: contain;"
                        *ngIf="image?.image != null">

                    <div class="ellipsis-one" *ngIf="image.image != null"> {{ nameImage(image) }} </div>

                    <input #filePagesEdit type="file" accept="image/*"
                        (change)="editImage($event, i, null, null, 'home')" multiple="multiple" style="display: none;">

                </div>
            </div>

            <button mat-button class="button-cor height_36" [style.background]="color_primary"
                style="margin: 0; display: flex; flex-direction: row; justify-content: center; align-items: center;"
                (click)="filePages.click()"> Adicionar imagens
                <span [matTooltip]="setInfoSizeImage(type_select)">
                    <svg width="20px" height="20px" viewBox="0 0 128.000000 128.000000" style="margin: 0 0 4px 5px;">
                        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#fff"
                            stroke="none">
                            <path d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                        -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                        265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                        -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                        -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                        423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
                            <path d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                        -3 97 -22 19 -53 28 -73 21z" />
                            <path d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                        50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
                        </g>
                    </svg>
                </span>
            </button>

            <input #filePages type="file" accept="image/*" (change)="onFileChanged($event)" multiple="multiple"
                style="display: none;">
        </div>

        <div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: space-between; align-items: center;"
            *ngIf="desktop.imagens != null && desktop.imagens.length > 0">
            <div style="width: 200px; position: relative;">

                <img [src]="desktop.imagens[selected_image].image" class="main_image">

                <mat-select [(ngModel)]="selected_image" class="select-box">
                    <mat-option *ngFor="let img of desktop.imagens; let i = index" (click)="selectImagePage(i)"
                        [value]="i">
                        {{ nameImage(img) }}
                    </mat-option>
                </mat-select>
            </div>

            <div style="width: calc(100% - 220px);">
                <input mat-input [(ngModel)]="desktop.imagens[selected_image].title" placeholder="Titulo"
                    *ngIf="side_select != 'Centro'"
                    [ngStyle]="{'border-color': warningMap.get('title'++selected_image)? 'red' : ''}" class="input">


                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    style="resize: none;" [(ngModel)]="desktop.imagens[selected_image].text" placeholder="Descrição"
                    *ngIf="side_select != 'Centro'"
                    [ngStyle]="{'border-color': warningMap.get('description'+selected_image)? 'red' : ''}"
                    class="input"></textarea>

                <!-- <input mat-input [(ngModel)]="desktop.imagens[selected_image].link_text" placeholder="Texto do botão"
                    *ngIf="side_select != 'Centro'"
                    [ngStyle]="{'border-color': warningMap.get('link_text'++selected_image)? 'red' : ''}" class="input">

                <input mat-input [(ngModel)]="desktop.imagens[selected_image].link"
                    [placeholder]="side_select != 'Centro' ? 'Link do botão' : 'Link da imagem'"
                    [ngStyle]="{'border-color': warningMap.get('link'++selected_image)? 'red' : ''}" class="input"> -->
            </div>
        </div>
    </div>

    <!-- PODCAST -->
    <div *ngIf="type_select != null && type_select == 'Podcast'">

        <div style="position: relative; width: 100%;">
            <img [src]="desktop.imagens[0].image" (click)="filePages.click()" class="image_logo"
                *ngIf="(desktop?.imagens != null && desktop?.imagens[0]?.image != null && desktop.imagens[0].image != '')"
                style="cursor: pointer;">

            <input mat-input [(ngModel)]="desktop.imagens[0].name" style="cursor: pointer;"
                placeholder="Selecione uma imagem" (click)="filePages.click()"
                [style.paddingLeft]="(desktop?.imagens != null && desktop?.imagens[0]?.image != null && desktop.imagens[0].url != '') ? '85px' : '20px'"
                [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}" class="input" readonly>
        </div>

        <input #filePages type="file" accept="image/*" (change)="onFileChanged($event)" multiple="multiple"
            style="display: none;">

        <input mat-input [(ngModel)]="desktop.imagens[0].spotify" placeholder="Link do Spotify"
            [ngStyle]="{'border-color': warningMap.get('spotify'+selected_image)? 'red' : ''}" class="input">
        <div style="margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
            *link do podcast EX: <i>"https://open.spotify.com/show/CODIGO_DO_PODCAST"</i>
        </div>

        <input mat-input [(ngModel)]="desktop.imagens[0].deezer" placeholder="Link do Deezer"
            [ngStyle]="{'border-color': warningMap.get('deezer'+selected_image)? 'red' : ''}" class="input">
        <div style="margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
            *link do podcast EX: <i>"https://www.deezer.com/br/show/CODIGO_DO_PODCAST"</i>
        </div>

        <input mat-input [(ngModel)]="desktop.imagens[0].itunes" placeholder="Link do iTunes"
            [ngStyle]="{'border-color': warningMap.get('itunes'+selected_image)? 'red' : ''}" class="input">
        <div style="margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
            *link do podcast EX: <i>"https://podcasts.apple.com/br/podcast/"</i>
        </div>

        <input mat-input [(ngModel)]="desktop.imagens[0].castbox" placeholder="Link do Castbox"
            [ngStyle]="{'border-color': warningMap.get('castbox'+selected_image)? 'red' : ''}" class="input">
        <div style="margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
            *link do podcast EX: <i>"https://castbox.fm/channel/CODIGO_DO_PODCAST"</i>
        </div>

        <input mat-input [(ngModel)]="desktop.imagens[0].google_podcast" placeholder="Link do Google Podcast"
            [ngStyle]="{'border-color': warningMap.get('google_podcast'+selected_image)? 'red' : ''}" class="input">
        <div style="margin-bottom: 15px; font-weight: 600; color: gray; width: 100%;">
            *link do podcast EX: <i>"https://podcasts.google.com/feed/CODIGO_DO_PODCAST"</i>
        </div>

    </div>


    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px;">
        <button mat-button class="button-cor" style="width: 50%; max-width: 200px; margin-right: 10px;"
            [style.background]="color_primary" (click)="close()">
            Cancelar
        </button>

        <button mat-button class="button-cor" style="width: 50%; max-width: 200px;" [style.background]="color_primary"
            (click)="saveSidebar()">
            Salvar
            <span *ngIf="loading" class="spinner-border spinner-border-sm m_loading" role="status"
                aria-hidden="true"></span>
        </button>
    </div>
</div>