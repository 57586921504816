import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { OrderService } from 'src/app/service/order.service';

@Component({
  selector: 'app-modal-edit-status',
  templateUrl: './modal-edit-status.component.html',
  styleUrls: ['./modal-edit-status.component.css']
})
export class ModalEditStatusComponent implements OnInit {

  constructor(
		public modalRef: MDBModalRef,
    private orderService: OrderService
  ) { }

	action: Subject<any> = new Subject();

  order;
  color_primary;
  loading: boolean = false;

  list_status = [
    {id: 0, status: 'WAITING', name: 'Aguardando pagamento', message: 'Pedido em Esperando! '},
    {id: 1, status: 'IN PROGRESS', name: 'Em andamento', message: 'Pedido em andamento! '},
    {id: 2, status: 'FINISHED', name: 'Finalizado', message: 'Pedido Finalizado! '}
  ]
  filterStatus;
  send_status;

  send_message: string = '';
  
  ngOnInit(): void {
    let aux_obj = this.list_status.filter(el => el.status == this.order.status );
    
    this.filterStatus = aux_obj[0].id;
    this.send_status = aux_obj[0].status
  }

  close(){
    if(this.loading) return

    this.action.next('no')
    this.modalRef.hide();
  }

  saveEdit(){
    if(this.loading) return

    this.loading = true;
    this.orderService.editStatusOrder(this.order.id, this.send_status.status).subscribe(data =>{
      this.sendSmsUser()
    },
    error=>{
      this.loading = false;
      console.log(error)
    })
  }

  filterTable(obj){
    this.filterStatus = obj.id;
    this.send_status = obj;
  }

  sendSmsUser(){
    let message = this.send_status.message+''+this.send_message;
    this.orderService.sendSMS(this.order.user.id, message).subscribe(data =>{
      this.loading = false;
      this.action.next('yes')
      this.modalRef.hide();
    })
  }
}
