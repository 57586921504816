import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CatalogService } from 'src/app/service/catalog.service';
import { StorageService } from 'src/app/service/storage.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-finish-buy',
  templateUrl: './finish-buy.component.html',
  styleUrls: ['./finish-buy.component.css']
})
export class FinishBuyComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private catalogService: CatalogService,
  ) { 
    var url = this.router.url;

		if(url.indexOf("redirect") > 0) {
			// console.log('WEB')
			this.web = true;
			this.phone = false;
		}
		else if(url.indexOf("content") > 0) {
			// console.log('PHONE')
			this.phone = true;
			this.web = false;
		}
  }

  @Input() header: any;
  @Input() catalog: any;
	@Input() preview: any;
  @Output() newItemEvent = new EventEmitter();

  web: boolean = false;
	phone: boolean = false;
  
  loading_button: boolean = false;
  loading: boolean = false;
  success: boolean = false;
  mobile: boolean = false;
  error_message: string;

  validator = new ValidatorUtils()

  displayedColumns: string[] = ['name', 'quantity', 'price'];
  data_source = new MatTableDataSource<any>();

  info={
    cpf: "",
    name: "",
    email: "",
    address: {
      cep: "",
      state: "",
      city: "",
      neighborhood: "",
      address: "",
      number: "",
      complement: "",
    },
    payment:{
      selected_payment: "",
      brand: "",
      number_card: "",
      holder: "",
      date: "",
      code: "",
    }
  }

  type_payment = [{name: 'Shipay'}, {name: 'Merdado Pago'}, {name: 'Picpay'}]
  
  constants;
  cart;
  text_color: string = "#ffffff";

  link = '';
  qrcode: string = '';

  ngOnInit(): void {
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
			this.mobile = true;
		}
        
    // console.log(this.storageService.shoppingCart.itens)

    if(this.storageService.shoppingCart.itens.length > 0){
        this.cart = this.storageService.shoppingCart;
        // console.log(this.cart)
        
        this.data_source.data = this.cart.itens;
    }
    else{
      this.storageService.clear()
      this.storageService.page_shoppingCart = 0;
    }
  }

  check(){
    this.error_message = "";

    // if(this.info.name == null || this.info.name == ""){// info
    //     this.error_message = "Por favor, insira o nome"
    // }
    // else if(this.info.email == null || this.info.email == ""){
    //   this.error_message = "Por favor, insira o email"
    // }
    // else if(this.info.cpf == null || this.info.cpf == ""){ 
    //     this.error_message = "Por favor, insira o CPF"
    // }
    // else if(!this.validator.isValidCPF(this.info.cpf)){ 
    //   this.error_message = "Por favor, insira um CPF válido"
    // }
    // else if(this.info.payment.selected_payment == null || this.info.payment.selected_payment == ""){ // payment
    //     this.error_message = "Por favor, selecione a forma de pagamento"
    // }
    // // else if (this.info.payment.brand == "" || this.info.payment.brand == null) {
    // //   this.error_message = "Selecione a bandeira do cartão"
    // // }
    // else if (this.info.payment.number_card == "" || this.info.payment.number_card == null) {
    //   this.error_message = "Insira o número do cartão"
    // }
    // else if (this.info.payment.holder == "" || this.info.payment.holder == null) {
    //   this.error_message = "Insira o nome do titular do cartão"
    // }
    // else if (this.info.payment.date == "" || this.info.payment.date == null) {
    //   this.error_message = "Insira a validade do cartão"
    // }
    // else if (this.info.payment.date.length < 7) {
    //   this.error_message = "Data inválida"
    // }
    if (this.validator.isValidDate(this.info.payment.date)) {
      this.error_message = "Data inválida"
    }
    else if (this.info.payment.code == "" || this.info.payment.code == null) {
      this.error_message = "Insira o código de segurança do cartão"
    }
    else if(this.info.address.cep == null || this.info.address.cep == ""){ // address
      this.error_message = "Por favor, insira o cep"
    }
    else if(this.info.address.state == null || this.info.address.state == ""){
      this.error_message = "Por favor, insira o estado"
    }
    else if(this.info.address.city == null || this.info.address.city == ""){
      this.error_message = "Por favor, insira a cidade"
    }
    else if(this.info.address.neighborhood == null || this.info.address.neighborhood == ""){
      this.error_message = "Por favor, insira o bairro"
    }
    else if(this.info.address.address == null || this.info.address.address == ""){
      this.error_message = "Por favor, insira o endereço"
    }
    else if(this.info.address.number == null || this.info.address.number == ""){
      this.error_message = "Por favor, insira o numero"
    }
    else if(this.info.address.complement == null || this.info.address.complement == ""){
      this.error_message = "Por favor, insira o complemento"
    }
    else{
      this.finish()
    }
  }

  finish(){
    this.newItemEvent.emit(this.info);
    // console.log('FINSH')
  }

  checkCEP(input_cep){
    let cep =  (this.info.address.cep != null && this.info.address.cep.length > 7 ? this.info.address.cep : null)

    if (cep == null) return

    // console.log(cep)

    this.storageService.viaCep(cep).subscribe(data => {
      // console.log(data)
      // console.log(data.bairro)
      this.info.address.neighborhood = data?.bairro;
      this.info.address.city = data?.localidade;
      this.info.address.address = data?.logradouro;
      this.info.address.state = data?.uf;

    },error => {
      console.log(error);
      this.error_message = "CEP inválido";
    })
  }

  checkBrandCard(num_card) {
    this.info.payment.brand = this.validator.brandCard(num_card)
  }

  back(page){
    this.storageService.page_shoppingCart = page;
  }

  async buy() {
    var products = "";

    // console.log(this.storageService.shoppingCart)
    for (const [key, value] of Object.entries(this.storageService.shoppingCart)) {
      // console.log(key)
      // console.log(value)

      for (let i = 0; i < value['count']; i++) {
        if (products == "") {
          products = `${key}`
        }
        else {
          products = `${products}, ${key}`
        }
      }
    }

    this.buyOther(products)
  }

  buyOther(products) {
    this.loading_button = true;

    setTimeout(() => {
      this.setStorage()
    }, 3000);
    // var coupon = null
    // let type = 'CPF'

    // this.catalogService.postOrder(
    //   products, this.cart.total, this.info.address.address, this.info.address.complement, this.info.address.neighborhood,
    //   this.info.address.number, this.info.address.cep, this.info.address.city, this.info.address.state, coupon, type, this.info.cpf
    // ).subscribe(data => {
    //   this.payment(data.id)

    // }, error => {
    //   this.loading_button = false;
    //   this.error_message = "Erro ao finalizar a compra"
    // })
  }

  payment(order) {
    // this.catalogService.payment(this.info.payment.holder, this.info.payment.brand,
    //   this.info.payment.date, this.info.payment.code,
    //   this.info.payment.number_card, order
    // ).subscribe(data => {

    //   this.setStorage()

    // }, error => {
    //   this.loading_button = false;
    //   this.error_message = "Erro com as informações de pagamento"
    // })

  }

  setStorage() {
    this.loading_button = false;
    this.storageService.clear()
    this.storageService.page_shoppingCart = 0;
  }

  convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
  }

}
