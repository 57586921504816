<div class="modal-content">
    <div class="screen_popup">
        <img src="../assets/image/iphone_x.png" class="img-phone">

        <button mat-button class="icon-close b-border" [style.background]="color_primary" (click)="modalRef.hide()"> Fechar </button>
    
        <div class="custom-scrollbar component">
            <div class="div-header" [ngStyle]="{'background-color': header.back}">
                <div class="title" [ngStyle]="{'color': header.color}"> {{ header.title }} </div>
            </div>
            
            <!-- preview catalog -->
            <div class="primary-div">

                <div class="div-loading" *ngIf="loading">
                    <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
                </div>
            
                <div *ngIf="!loading && listImage == null" class="div-msg">
                    Nenhuma seção encontrada.
                </div>

                <div *ngFor="let section of listImage">
                        
                    <h2 style="width: 100%; max-width: 1000px; margin: 0 auto;">
                        {{ section?.name }}
                    </h2>
                        
                    <div class="div-row" *ngFor="let obj of section?.products">
            
                        <div class="img-produto">
                            <img *ngIf="!isEdit" [src]=" obj.image ">
                            <img *ngIf="isEdit" [src]="obj.image ">
                        </div>
                        
                        <div class="info-product">
            
                            <div class="div-description font-cell">
            
                                <div class="line_2">
                                    <b [ngStyle]="{'color': header.color}"> {{ obj.name }} </b>
                                </div>
                                
                            
                                <div class="line_4">
                                    <p> {{ obj?.description }} </p>
                                </div>
                                
                            </div>
                            
                            <div class="add-cart">
                                <div style="width: 100%; text-align: center; font-size: calc(15px + 20%); font-weight: 500; margin: 5px;">
                                    {{ convertMoney(obj.price) }}
                                </div>
                                <!-- <button mat-raised-button class="button">
                                    ADICIONAR AO CARRINHO
                                </button> -->
                            </div>
            
                        </div>
            
                    </div>
                        
                </div>
            
            </div>

        </div>
        
    </div>

</div>