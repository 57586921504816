import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Coupon } from 'src/app/model/coupon';
import { CouponService } from 'src/app/service/coupon.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../service/compressor.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppConfig } from '../../app.config';
import { RegistrationService } from 'src/app/service/registration.service';
import { RegistrationConfig } from 'src/app/model/registrationConfig';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { StorageService } from 'src/app/service/storage.service';
import { CombineLatestSubscriber } from 'rxjs/internal/observable/combineLatest';

@Component({
    selector: 'app-coupon-detail',
    templateUrl: './coupon-detail.component.html',
    styleUrls: ['./coupon-detail.component.css']
})
export class CouponDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private compressor: CompressorService,
        private couponService: CouponService,
        private router: Router,
        private registrationService: RegistrationService,
        private modalService: MDBModalService,
        private storageService: StorageService

    ) { 
        this.coupon_id = this.route.snapshot.paramMap.get('id');
    }

    modalRef: MDBModalRef;

    loading: boolean = false;
    warningMap = new Map();

    id_establishment = null;

    coupon_id = null;

    url = AppConfig.path

    coupon: Coupon = {
        id: null,
        establishment: null,
        name: null,
        start_date: null,
        end_date: null,
        image: null,
        rule: null,
        rules: null,
        description: null,
        automatic_after: null,
        active: true,
        login_required: false,
        registration_config: null,
        registration_config_obj: null
    };

    send_coupon: Coupon = {
        id: null,
        establishment: null,
        name: null,
        start_date: null,
        end_date: null,
        image: null,
        rule: null,
        rules: null,
        description: null,
        automatic_after: null,
        active: true,
        login_required: false,
        registration_config: null,
        registration_config_obj: null
    };

    config_obj = [];

    pdfTerm;

    list_registration = [{title: 'Nenhum', id: 0}];

    compressed_image = []; // as the name says...
    
    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')

        this.getRegistration(1)

        if(this.coupon_id != null){
            this.getCouponId()
        }
    }

    getRegistration(page){
        this.registrationService.getRegistration(page).subscribe(data =>{
            this.list_registration = this.list_registration.concat(data.results)
            if(data.next != null){
                this.getRegistration(page+1)
            }
        })
    }

    getCouponId(){
        this.couponService.getCouponId(this.coupon_id).subscribe(data=>{
            this.coupon = data;
            this.send_coupon = Object.assign({}, this.coupon);

            if(data.registration_config_obj != null)
                this.coupon.registration_config = data.registration_config_obj.id

            this.coupon.image = data.image;
            this.coupon.rules = data.rules;
            
            if(this.coupon.rules != null){ this.pdfTerm = 'termos.pdf'; }
        })
    }

    save(){
        this.warningMap = new Map();

        var now = new Date();
        var start_date = new Date(this.coupon.start_date);
        var end_date = new Date(this.coupon.end_date);

        var end_time = start_date.getTime() - end_date.getTime();
        end_time = end_time / 1000 / 60;

        var start_time = now.getTime() - start_date.getTime();
        start_time = start_time / 1000 / 60;
        

        if(this.coupon.name == "" || this.coupon.name == null){
            this.warningMap.set('name', true);
        }
        if(this.coupon.description == "" || this.coupon.description == null){
            this.warningMap.set('description', true);
        }
        if(this.coupon.image == "" || this.coupon.image == null){
            this.warningMap.set('image', true);
        }
        if(this.coupon.start_date == null){
            this.warningMap.set('start_date', true);
        }
        if(this.coupon.end_date == null){
            this.warningMap.set('end_date', true);
        }
        if(end_time > 0){
            this.warningMap.set('end_date', true);
        }
        if(this.coupon.rule == "" || this.coupon.rule == null){
            this.warningMap.set('rule', true);
        }
        if(this.coupon.automatic_after == null){
            this.warningMap.set('automatic_after', true);
        }
        if(this.coupon.login_required && this.coupon.registration_config == null){
            this.warningMap.set('config', true);
        }

        if(this.warningMap.size == 0){
            if(this.coupon_id == null)
                this.postCoupom()
            else 
                this.patchCoupom()
        }
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        if(event.value != null){}
        
    }

    postCoupom(){
        this.loading = true;

        this.couponService.postCoupon(this.id_establishment, this.coupon.name, 
            this.convertDate(this.coupon.start_date), this.convertDate(this.coupon.end_date), 
            this.coupon.image, this.coupon.rule, this.coupon.rules, this.coupon.description, this.coupon.login_required,
            this.coupon.automatic_after, this.coupon.registration_config).subscribe(data=>{
                this.loading = false;
                this.router.navigate(['/application/coupon'])
            },
            error=>{
                this.loading = false;
            })
    }

    onFilePdf(event){
        if (event.target.files && event.target.files[0]) {
			var reader = new FileReader()
			reader.readAsDataURL(event.target.files[0])
			reader.onloadend = (e) => {
				this.coupon.rules = reader.result
                this.pdfTerm = event?.target?.files[0]?.name;

			}
		}

    }

    openPdf(link){
        if(link != null){
            // if(link.substring(0,4) != 'http'){
            //     window.open(`${link}`);
            // }
            // else{
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link, '_blanck');
            // }
        }
    }

    patchCoupom(){
        this.loading = true;

        for (let key of Object.keys(this.coupon)) {

            if(key != 'id' && key != 'image' && key != 'start_date' && key != 'end_date'){
                
                if(this.coupon[key] == this.send_coupon[key]) 
                    this.send_coupon[key] = null; 
                else 
                    this.send_coupon[key] = this.coupon[key]; 

            }
            else if(key == 'start_date'){
                this.send_coupon.start_date = this.convertDate(this.coupon.start_date)
            }
            else if(key == 'end_date'){
                this.send_coupon.end_date = this.convertDate(this.coupon.end_date)
            }
            else if(key == 'image'){

                if(this.coupon[key].substring(0,4) == 'http')
                    this.send_coupon[key] = null;
                else
                    this.send_coupon[key] = this.coupon[key];

            }

		}

        // console.log(this.send_coupon)

        this.couponService.patchCoupon(this.id_establishment, this.send_coupon).subscribe(data=>{
            this.loading = false;
            this.router.navigate(['/application/coupon'])
        },
        error=>{
            this.loading = false;
        })
    }

    convertDate(data){
        if(data != null){
            var d = new Date(data),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hh = d.getHours(),
            mm = d.getMinutes(),
            ss = d.getSeconds();
    
            var aux_data = [year, month, day].join('-')
            var aux_hour = [hh, mm, ss].join(':')
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
    
            return [aux_data, aux_hour].join('T');
        }
        else{
            return null
        }
        
    }

    async onFileChanged(event){
		if (event.target.files && event.target.files[0]) {
			var target = event.target.files
			const compress = this.recursiveCompress( target[0], 0, target ).pipe(
				expand(res => {
					  return res.index > res.array.length - 1
					? EMPTY
					: this.recursiveCompress( target[res.index], res.index, target );
				}),
			);
			compress.subscribe(res => {
				if (res.index > res.array.length - 1) {
                    this.coupon.image = this.compressed_image[0]
					this.compressed_image = []

				}
			});
		}
	}

    recursiveCompress = (image: File, index, array) => {
		return this.compressor.compress(image).pipe (
		  	map(response => {
				this.compressed_image.push(response)
				return {
					data: response,
					index: index + 1,
					array: array,
				};
		  	}),
		);
	}

    openView(){
        this.modalRef = this.modalService.show(CellPhoneComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                isList: this.coupon,
                isEdit: true,
                isPopup: 'coupon'
            }
        })   
    }
}
