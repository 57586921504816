import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CampaignService } from 'src/app/service/campaign.service';
import { RegistrationService } from 'src/app/service/registration.service';
import { StorageService } from 'src/app/service/storage.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {

    constructor(
        private userCampaignService: UserCampaignService,
        private registrationService: RegistrationService,
        private storageService: StorageService,
        private deviceService: DeviceDetectorService,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {
        this.url_screen = router.url;
        var url = this.router.url;
        var aux = url.split('/')
        var tam = aux.length
        this.urlPage = aux[tam - 2]
    }

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
    @Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    warningMap = new Map();
    urlPage;
    url_screen;

    listImage;
    qrcode_id;
    isList;
    loading: boolean = true;
    loading_message: boolean = false;
    loading_send: boolean = true;
    loading_code: boolean = false;
    url = AppConfig.path;
    page: number = 0;

    list_select = {
        orientation: [
            { title: 'Não Apurado' },
            { title: 'Heterossexual' },
            { title: 'Homossexual' },
            { title: 'Bissexual' },
            { title: 'Assexual' },
            { title: 'Panssexual' }
        ],
        identity: [
            { title: 'Não Apurado' },
            { title: 'Mulher Cisgênero' },
            { title: 'Homem Cisgênero' },
            { title: 'Mulher Transgênero' },
            { title: 'Homem Transgênero' },
            { title: 'Gênero não Binário' },
            { title: 'Agênero' },
        ]

    }

    input = {
        name: null,
        email: null,
        birthdate: null,
        cpf: null,
        sexual_orientation: null,
        gender_identity: null,
        cep: null,
        phone: null,
        check: false,
        password: null,
        password2: null,
        checkInfo: false,
    }

    navegationInfo = null;
    token;
    user = {
        id: null,
        name: null,
        email: null,
        birthdate: null,
        establishment: null,
        cpf: null,
        sexual_orientation: null,
        gender_identity: null,
        cep: null,
        phone: null,
        token: null
    };

    list_order = []

    phone = null;
    email = null;
    validation_code;

    loading_login: boolean = false;

    time: number = 300000; // tempo de reenvio código

    type_page: string = '';
    textError: string = '';


    ngOnInit(): void {
        this.user.establishment = '';
        // console.log("-> Cadastro <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

        this.storageService.checkLoginUser()

        this.storageService.screen.format = 'Cadastro';
        this.storageService.screen.id = this.list.id;

        this.listImage = this.tratamento(this.list)

        if (localStorage.getItem("_user") != null) {
            this.user = JSON.parse(localStorage.getItem("_user"));
            this.page = 4;
        }

        if (this.list?.registration_config_obj != null) {
            this.loading = false;
            this.isList = this.listImage != null ? this.listImage : this.list?.registration_config_obj;
            this.orderList(this.isList)
            this.loading = false;
        }
        else if (this.list.registration_config_obj == null) {
            const id_config = this.list.registration_config != null ? this.list.registration_config : this.listImage[0].registration_config

            this.registrationService.getRegistrationId(id_config).subscribe(data => {
                this.isList = data
                this.orderList(this.isList)
                this.loading = false;
            })
        }
        else {
            this.loading = false;
            this.isList = this.list.registration_config_obj;
            this.orderList(this.isList)
        }

        // if (localStorage.getItem("_user") != null){
        //     this.page = 1;
        // }

    }

    orderList(data) {

        var list_aux = []
        list_aux = data.order_fields.split(', ')

        list_aux.forEach(item => {
            switch (item) {
                case "Telefone":
                    this.list_order.push({ title: 'Telefone', name_api: 'phone', placeholder: 'Digite o seu telefone', mask: '(00) 0 0000-0000', input: null, active: data.phone, active_mandatory: data.phone_mandatory, options: true, check_sms: data.check_sms, checkbox_sms: false })
                    break;
                case "E-mail":
                    // this.list_order.push({title: 'E-mail', name_api: 'email', placeholder: 'Digite o seu email', mask: null, input: null, active: data.email, active_mandatory: data.email_mandatory, options: false})
                    this.list_order.push({ title: 'E-mail', name_api: 'email', placeholder: 'Digite o seu email', mask: null, input: null, active: true, active_mandatory: true, options: false }) //EMAIL SEMPRE ATIVO E OBRIGATORIO 

                    break;
                case "Nome":
                    this.list_order.push({ title: 'Nome', name_api: 'name', placeholder: 'Digite o seu nome', mask: null, input: null, active: data.name, active_mandatory: data.name_mandatory, options: false })
                    break;
                case "CPF":
                    this.list_order.push({ title: 'CPF', name_api: 'cpf', placeholder: 'Digite o seu CPF', mask: '000.000.000-00', input: null, active: data.cpf, active_mandatory: data.cpf_mandatory, options: false })
                    break;
                case "Data de nascimento":
                    this.list_order.push({ title: 'Data de nascimento', name_api: 'birthdate', placeholder: 'Data de nascimento', mask: null, input: null, active: data.birthdate, active_mandatory: data.birthdate_mandatory, options: false })
                    break;
                case "Orientação Sexual":
                    this.list_order.push({ title: 'Orientação Sexual', name_api: 'sexual_orientation', placeholder: 'Selecione a Orientação Sexual', mask: null, input: null, active: data.sexual_orientation, active_mandatory: data.sexual_orientation_mandatory, options: false })
                    break;
                case "Identidade de Gênero":
                    this.list_order.push({ title: 'Identidade de Gênero', name_api: 'gender_identity', placeholder: 'Selecione a Identidade de Gênero', mask: null, input: null, active: data.gender_identity, active_mandatory: data.gender_identity_mandatory, options: false })
                    break;
                case "CEP":
                    this.list_order.push({ title: 'CEP', name_api: 'cep', placeholder: 'Digite o seu CEP', mask: '00000-000', input: null, active: data.cep, active_mandatory: data.cep_mandatory, options: false })
                    break;
            }
        });

    }

    getUser(token) {
        this.userCampaignService.getUserCampaign(token).subscribe(data => {

            if (data.establishment != null && data.verified_email) {
                this.next()

                setTimeout(() => {
                    this.loading_message = true;
                    this.loading_send = true;
                }, this.time);

                return
            }
            else if (data.establishment != null && !data.verified_email) {
                this.page = 2;
                this.email = data.email;
                this.phone = data.phone
                this.token = token;
                this.sendCode()
            }
        })
    }

    checkRegister() {
        this.warningMap = new Map();

        this.list_order.forEach(item => {
            if (item.active_mandatory && (item.input == null || item.input == "")) {
                this.warningMap.set(item.name_api, true);
            }
        });

        if (!this.input.check) {
            this.warningMap.set('check', true);
        }
        if (!this.input.checkInfo) {
            this.warningMap.set('checkInfo', true);
        }
        if (this.warningMap.size == 0) {
            this.postUserCampaign()
        }
        // this.next()
    }

    createToken() {
        var create_token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
        return create_token;
    };

    createTokenUser() {
        if (localStorage.getItem('tokenAnonimo') == null || localStorage.getItem('tokenAnonimo') == '') {
            this.token = this.createToken()
        }
        else {
            this.token = localStorage.getItem('tokenAnonimo');
        }

        // this.token = this.createToken()
        this.storageService.token_user = this.token
    }

    checkLogin(email) {
        return this.userCampaignService.getLoginUser(email).toPromise()

        // this.userCampaignService.getLoginUser(email).subscribe(data => {

        //     if (data != null && data.length > 0) {
        //         this.user.birthdate = data[0].birthdate != null ? data[0].birthdate : '';
        //         this.user.cep = data[0].cep != null ? data[0].cep : '';
        //         this.user.cpf = data[0].cpf != null ? data[0].cpf : '';
        //         this.user.email = data[0].email != null ? data[0].email : '';
        //         this.user.establishment = data[0].establishment != null ? data[0].establishment : '';
        //         this.user.gender_identity = data[0].gender_identity != null ? data[0].gender_identity : '';
        //         this.user.name = data[0].name != null ? data[0].name : '';
        //         this.user.phone = data[0].phone != null ? data[0].phone : '';
        //         this.user.sexual_orientation = data[0].sexual_orientation != null ? data[0].sexual_orientation : '';
        //         this.user.token = data[0].token != null ? data[0].token : '';
        //     }
        //     else { this.user = {
        //                 id: null,
        //                 name: null,
        //                 email: null,
        //                 birthdate: null,
        //                 establishment: null,
        //                 cpf: null,
        //                 sexual_orientation: null,
        //                 gender_identity: null,
        //                 cep: null,
        //                 phone: null,
        //                 token: null
        //             } 
        //         }
        // },
        //     error => {
        //         console.log(error)
        //     })
    }

    convertDate(data) {
        if (data != null) {
            var d = new Date(data),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            var aux_data = [year, month, day].join('-')

            return aux_data
        }
        else {
            return null
        }

    }

    async postUserCampaign() {
        if (this.url_screen != '/application/dashboard')
            this.storageService.saveHistoric(this.campaign_id, this.list.id, this.list.homepage_format, 4, this.type_page, this.qrcode_id)

        if (this.urlPage == 'redirect' || this.urlPage == 'redirect-sbs' || this.urlPage == 'redirect-teste') {
            this.createTokenUser()

            this.list_order.forEach(element => {
                if (element.title == 'Data de nascimento') {
                    element.input = this.convertDate(element.input)
                }
            });

            var establishment = this.storageService.getEstablishment();
            let email_obj = this.list_order.filter(el => el.title == 'E-mail')

            let data = await this.checkLogin(email_obj[0].input)

            if (data != null && data.length > 0) {
                this.user.birthdate = data[0].birthdate != null ? data[0].birthdate : '';
                this.user.cep = data[0].cep != null ? data[0].cep : '';
                this.user.cpf = data[0].cpf != null ? data[0].cpf : '';
                this.user.email = data[0].email != null ? data[0].email : '';
                this.user.establishment = data[0].establishment != null ? data[0].establishment : '';
                this.user.gender_identity = data[0].gender_identity != null ? data[0].gender_identity : '';
                this.user.name = data[0].name != null ? data[0].name : '';
                this.user.phone = data[0].phone != null ? data[0].phone : '';
                this.user.sexual_orientation = data[0].sexual_orientation != null ? data[0].sexual_orientation : '';
                this.user.token = data[0].token != null ? data[0].token : '';
            }
            else {
                this.user = {
                    id: null,
                    name: null,
                    email: null,
                    birthdate: null,
                    establishment: null,
                    cpf: null,
                    sexual_orientation: null,
                    gender_identity: null,
                    cep: null,
                    phone: null,
                    token: null
                }
            }

            await setTimeout(() => {
                if (this.user.email == null && this.user.name == null) {

                    this.userCampaignService.postUserCampaign(establishment, this.token, this.list_order).subscribe(data => {
                        // console.log('NEW USER')
                        // console.log(data)
                        this.saveUSerLocalStore(data)


                        this.list_order.forEach(item => {
                            if ((this.phone == null && item.title == 'E-mail') && (item.input != null || item.input != "")) this.email = item.input
                            else if ((this.email == null && item.title == 'Telefone') && (item.input != null || item.input != "")) this.phone = item.input
                        });

                        setTimeout(() => {
                            this.userCampaignService.sendEmailUserCampaign(this.email, this.phone, this.token).subscribe(data => {
                                // console.log(data)
                            })
                        }, 1000);

                        // if(this.list.page_images != null && this.storageService.token_user != null){
                        //     this.addNewItem(this.list.page_images[0]);
                        // }
                        // else{
                        this.page = 2;
                        // }

                    })
                }
                else {
                    this.page = 3;
                    this.email = this.user.email;
                    this.openSnackBar("Já existe um usuário com esse e-mail!", "Fechar")
                }

            }, 500);
        }

    }

    saveUSerLocalStore(data) {

        this.list_order.forEach(item => {
            switch (item.title) {
                case "Telefone":
                    this.user.phone = item.input;
                    break;
                case "E-mail":
                    this.user.email = item.input;
                    break;
                case "Nome":
                    this.user.name = item.input;
                    break;
                case "CPF":
                    this.user.cpf = item.input;
                    break;
                case "Data de nascimento":
                    this.user.birthdate = item.input;
                    break;
                case "Orientação Sexual":
                    this.user.sexual_orientation = item.input;
                    break;
                case "Identidade de Gênero":
                    this.user.gender_identity = item.input;
                    break;
                case "CEP":
                    this.user.cep = item.input;
                    break;
            }
        });

        this.user.token = this.token;
        this.user.establishment = localStorage.getItem("id_establishment");
        this.user.id = data.id;
        // localStorage.setItem('_user', JSON.stringify(this.user));
        // localStorage.setItem('tokenUser', this.user.token);
        this.storageService.checkLoginUser()
        this.storageService.info_user = this.user;

    }

    sendCode() {
        this.loading_login = true;
        this.checkLogin(this.email)

        setTimeout(() => {

            if (this.user != null) {
                if (this.loading_send) {
                    this.loading_send = false
                    this.userCampaignService.sendEmailUserCampaign(this.email, this.phone, this.token).subscribe(data => {
                        this.loading_login = false;
                        this.page = 2;
                    })
                }
                else {
                }
                setTimeout(() => {
                    this.loading_send = true;
                }, this.time);
            }
            else {
                this.page = 0;
                this.loading_login = false;

                let email_obj = this.list_order.filter(el => el.title == 'E-mail')
                email_obj[0].input = this.email;

                this.openSnackBar("Não existe um usuário com esse e-mail!", "Fechar")
            }
        }, 2000);
    }

    checkCode() {
        this.textError = '';
        this.warningMap = new Map();

        if (localStorage.getItem("_user") != null)
            this.user = JSON.parse(localStorage.getItem("_user"));

        this.email = (this.email == null) ? this.user.email : this.email;
        // this.phone = (this.phone == null) ? this.user.phone : this.phone;
        this.phone = null;

        if (this.validation_code == null || this.validation_code == "") {
            this.warningMap.set('validation_code', true);
        }
        if (this.warningMap.size == 0) {
            this.loading_code = true;

            this.userCampaignService.validedHash(this.token, this.email, this.phone, this.validation_code).subscribe(data => {

                if (this.user != null) {
                    var list_login_user = JSON.parse(localStorage.getItem("_list_accounts"));

                    if (list_login_user == null) list_login_user = []
                    list_login_user.push(this.user)

                    this.storageService.info_user = this.user
                    this.storageService.token_user = this.user.token;

                    localStorage.removeItem('_user')
                    localStorage.removeItem('tokenUser')

                    localStorage.setItem('_user', JSON.stringify(this.user))
                    localStorage.setItem('tokenUser', this.user.token)
                    localStorage.setItem('_list_accounts', JSON.stringify(list_login_user))

                    this.storageService.checkLoginUser()
                }

                // this.next();
                this.page = 4;

            },
                error => {
                    this.textError = 'Código invalido';
                    this.loading_code = false;
                })
        }
    }

    addNewItem(list) {
        if (this.preview_campaign) {
            if (list?.type?.name == "Link") {
                // console.log("-> Link <-")

                this.openLink(list.link)
            }
            if (list?.type?.name == "Vazio") {
                // console.log("-> Vazio <-")
                this.page = 1;
                return
            }
            else
                this.newItemEvent.emit(list);
        }
        else {
            if (list?.page_format_obj?.name == "Link") {
                // console.log("-> Link <-")

                if (list.page_images != null) {
                    this.openLink(list.page_images[0].link)
                }
                else if (list.internal_images != null) {
                    this.openLink(list.internal_images[0].link)
                }
            }
            else if (list?.page_format_obj?.name == "Vazio") {
                // console.log("-> Vazio <-")
                this.page = 1;
                return
            }
            else if (list?.page_format_obj != null) {
                this.newItemEvent.emit(list);
            }
        }

    }

    openLink(link) {
        if (link != null) {
            if (link.substring(0, 4) != 'http') {
                var aux_link = link.replace(/\s/g, '');
                window.open(`http://${aux_link}`);
            }
            else {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
        }
    }

    openTerms() {
        var link = this.isList?.terms.split('//')

        if (link[1].substring(0, 7) == 'sbs-api') {
            return this.isList?.terms
        }
        else {
            return this.url + this.isList?.terms
        }
    }

    next() {

        this.loading_code = false;

        if (this.listImage?.page_images != null) {
            this.page = 1;
            this.addNewItem(this.listImage.page_images[0])
            // console.log('NEXT')
        }
        if (this.listImage?.internal_images != null) {
            this.page = 1;
            this.addNewItem(this.listImage.internal_images[0])
            // console.log('NEXT')
        }
        if (this.page == 4) {
            this.back()
            // console.log('1 BACK')
        }
        else {
            this.back()
            // console.log('2 BACK')
        }
    }

    back() {
        this.newItemEvent.emit('registerBack');
    }

    tratamento(list) {
        if (this.preview_campaign) {
            if (this.list?.images != undefined && this.list?.images != null) {
                return list.images;
            }
            else
                return null
        }
        else {
            if (list?.home_images != undefined) {
                this.type_page = 'home';
                return list.home_images;
            }
            else if (list?.internal_images != undefined) {
                // console.log('3',list.internal_images)
                this.type_page = 'internal';
                return list.internal_images;
            }
            else if (list?.page_images != undefined) {
                // console.log('2',list.page_images)
                this.type_page = 'page';
                return list.page_images;
            }
            else if (this.list?.home_images != undefined) {
                // console.log('1',list.home_images)
                this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }

    }


    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 4000,
        });
    }

}
