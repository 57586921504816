import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConstantsService } from '../service/constants.service';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

    @ViewChild('drawer') public drawer;

    constructor(
        private router: Router,
        private constantsService: ConstantsService,
        private user: UserService,
        private storageService: StorageService
    ) {
        this.url = this.router.url;

        /* catch page change event */
        this.router.events.subscribe((ev) => {

            if(localStorage.getItem('token') == null){
                this.router.navigate(['/login'])
            }

            if (ev instanceof NavigationEnd) {
                this.list_url = this.router.url.split('/')
                var tam = this.list_url.length
                this.url = this.list_url[tam-1];
                this.urlPage = this.url;
        
                if(this.url.substring(0,3) == 'new'){
                    var aux = this.url.split('new-')
                    this.urlPage = aux[1]
                }
                else if(this.list_url.length == 4){
                    this.urlPage = this.list_url[tam-2]
                }

            }
            if(this.token == null){
                this.token = localStorage.getItem('token')
            }
        });
    }

    loading: boolean = false;
    url;
    urlPage;
    list_url

    openMenu: boolean = true;
    token
    list_establishment = [];
    establishmentSelect: any;
    establishment;
    id_establishment;

    name_establishment: string = null;
    id_user;
    info_user;
    image_logo: string = null;
    color_primary: string = null;
    profile_picture;

    input_search: string = '';
    listScreen = [
        {name: 'Conteúdo', link:'/application/content'},
        {name: 'Catalogo', link:'/application/catalog'},
        {name: 'Cupom', link:'/application/coupon'},
        {name: 'Seção', link:'/application/section'},
        {name: 'Curso', link:'/application/course'},
        {name: 'Notícias', link:'/application/notice'},
        {name: 'Pergunta', link:'/application/survey'},
        {name: 'Cadastro', link:'/application/register'},
        {name: 'Produtos', link:'/application/products'},
        {name: 'Categorias', link:'/application/categories'},
        {name: 'Tablóide', link:'/application/tabloide'},
        // {name: 'Estoque', link:'/application/categories'},
        {name: 'Vendas', link:'/application/sales'},
        // {name: 'Caixa', link:'/application/sales'},
        {name: 'Dashboard', link:'/application/dashboard'},
        {name: 'Meios de Pagamento', link:'/application/payment'},
        {name: 'Pedidos', link:'/application/order'},
        // {name: 'Nota Fiscal', link:'/application/order'},
        {name: 'Canais', link:'/application/channels'},
        {name: 'Grupos', link:'/application/group'},
        {name: 'Cadastros', link:'/application/registrations'},
        {name: 'Importar Arquivos', link:'/application/file'},
        {name: 'Pedidos Tabloide', link:'/application/order-tabloide'},
    ]
    list_filter = []

    showFiller = false;

    refresh: boolean = true;

    ngOnInit(): void {
        this.listScreen.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
        });
        // this.list_filter = this.listScreen;

        this.user.getUser().subscribe(data=>{
            // console.log(data)

            this.id_user = data.id
            this.storageService.user_id = data.id;
            this.info_user = data;
            this.profile_picture = data.profile_picture;
            this.name_establishment = data.name != null ? data.name : 'SBSolutions';
            this.image_logo = data.logo != null ? data.logo : '/assets/image/logo-terceira-opcao.png';
            this.color_primary = data.color != null ? data.color : '#f77128';
            this.storageService.establishment.color = this.color_primary;

            document.getElementsByTagName("title")[0].innerText = this.name_establishment;
            document.getElementsByTagName("link")[0].href = this.image_logo;
            
            this.id_establishment = localStorage.getItem('id_establishment')
    
            if(this.id_establishment != null){
                this.establishment = parseInt(this.id_establishment)
            }
            else{
                localStorage.setItem('id_establishment', this.info_user.establishment)
                this.id_establishment = this.info_user.establishment;
            }

            this.getEstablishment()
            this.loading = true;
        })
        

        if(this.token == null){
            this.router.navigate(['/cliente'])
        }

    }

    openNavbar(url){
        if(url == this.urlPage){
            this.drawer.toggle();
            // this.openMenu = !this.openMenu;
        }
        else if((this.urlPage == 'content' || this.urlPage == 'catalog' || this.urlPage == 'tabloide' || this.urlPage == 'coupon' || this.urlPage == 'tag' || this.urlPage == 'survey' || this.urlPage == 'course' || this.urlPage == 'register' || this.urlPage == 'dashboard' || this.urlPage == 'notice') &&
                (url == 'content' || url == 'catalog' || url == 'tabloide' || url == 'coupon' || url == 'tag' || url == 'survey' || url == 'register' || url == 'dashboard' || url == 'notice')){
            this.drawer.toggle();
        }
        else if((this.urlPage == 'products' || this.urlPage == 'categories' ||  this.urlPage == 'inventory' || this.urlPage == 'sales' || this.urlPage == 'sales-box') &&
                (url == 'products' || url == 'categories' || url == 'inventory' || url == 'sales' || url == 'sales-box')){
            this.drawer.toggle();
        }
        else if((this.urlPage == 'payment' || this.urlPage == 'order' || this.urlPage == 'order-tabloide' || this.urlPage == 'invoice') &&
                (url == 'payment' || url == 'order' || url == 'order-tabloide' || url == 'invoice')){
            this.drawer.toggle();
        }
        else if((this.urlPage == 'registrations' || this.urlPage == 'file') && (url == 'registrations' || url == 'file')){
            this.drawer.toggle();
        }
        else{
            if(url != null && url != '') this.router.navigate(['/application/'+url])
            this.drawer.open()
            this.openMenu = false;
        }
    }

    closeNavbar(url){
        // this.drawer.close();
        if(url != null && url != '') this.router.navigate(['/application/'+url])
        this.openMenu = false;
    }

    searchScreen(text){
        if (text != null) {
            this.list_filter = this.listScreen.filter(el =>
                el.name.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
        }
    }

    openScreen(obj){
        this.router.navigate([obj.link])
        this.input_search = '';
    }

    getEstablishment() {
        this.constantsService.getUserEstablishment(this.id_user).subscribe(data => {
            // console.log(data)

            this.list_establishment = data.results;
           
            this.establishmentSelect = Number(this.id_establishment);
        })
    }

    selectEstablishment(obj){
        localStorage.setItem('id_establishment', obj.id)
        this.user.patchUser(this.id_user, obj.id).subscribe(data=>{

            this.refresh = false;
            setTimeout(() => {
                this.refresh = true;
            }, 100);
            
        }, error =>{
            console.log(error)
        })
    }

    logout(){
        localStorage.removeItem('id_establishment')
        localStorage.removeItem('token')
        this.router.navigate(['/'])
    }


}
