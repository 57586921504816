import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/service/order.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private router: Router,
    private orderService: OrderService,
    private _snackBar: MatSnackBar
  ) {
    var url = this.router.url;

    if (url.indexOf("redirect") > 0) {
      // console.log('WEB')
      this.web = true;
      this.phone = false;
    }
    else if (url.indexOf("content") > 0) {
      // console.log('PHONE')
      this.phone = true;
      this.web = false;
    }
  }

  @Input() header: any;
  @Input() catalog: any;
  @Input() preview: any;
  @Output() newItemEvent = new EventEmitter();

  web: boolean = false;
  phone: boolean = false;

  loading: boolean = false;
  loading_order: boolean = false;

  textEx: string = '';
  constants;

  arrayItens;
  qtdItem = 1;
  valorTotal: number = 0;
  text_color: string = "#ffffff";
  page: string = 'shopping cart';
  // page: string = 'payment';

  warningMap = new Map();

  address = {
    name: null,
    number: null,
    cep: null,
    district: null
  };

  isOrder: any = null;

  card = {
    number: null,
    date: null,
    code: null,
    holder: null,
    brand: null
  }

  list_brand = []

  ngOnInit(): void {
    console.log(this.catalog)
    this.arrayItens = this.storageService.getShoppingCart();

    this.somaTotal()
    this.getListBrands()
  }

  getListBrands(){
     this.orderService.getListBrands().subscribe(data=>{
      this.list_brand = data;
     },
     error=>{
        console.log(error)
     })
  }
  

  addQtd(item) {
    this.storageService.addProduct(item, 1)
    // item.qtd += 1;
    this.somaTotal()
  }

  remQtd(item) {
    this.storageService.removeProduct(item, 1)

    // if(item.qtd > 1) item.qtd -= 1;
    this.somaTotal()
  }

  removerProduct(posi) {
    this.storageService.excluirProduct(posi)
    if (this.storageService.shoppingCart.itens.length <= 0) {
      this.storageService.page_shoppingCart = 0;
      this.newItemEvent.emit(null);
    }
  }

  somaTotal() {
    this.valorTotal = 0;

    for (let index = 0; index < this.arrayItens.length; index++) {
      this.valorTotal += (this.arrayItens[index].price * this.arrayItens[index].qtd)
    }
  }

  checkInput() {
    this.warningMap = new Map();

    if (this.address.name == null || this.address.name == "") {
      this.warningMap.set('name', true);
    }
    if (this.address.number == null || this.address.number == "") {
      this.warningMap.set('number', true);
    }
    if (this.address.cep == null || this.address.cep == "") {
      this.warningMap.set('cep', true);
    }
    if (this.address.district == null || this.address.district == "") {
      this.warningMap.set('district', true);
    }

    if (this.warningMap.size == 0) {
      this.finish()
    }
  }

  finish() {
    localStorage.getItem('_user')
    this.loading_order = true;
    // this.newItemEvent.emit(this.arrayItens);
    let token = localStorage.getItem('tokenUser')
    if (token != null) {
      // console.log(this.storageService.getShoppingCart())
      let list_send = []

      this.storageService.getShoppingCart().forEach(item => {
        list_send.push({ id: item.id, count: item.qtd })
        this.orderService.postOrder(this.catalog.id, this.address, list_send, token).subscribe(data => {
          console.log(data)
          this.isOrder = data;
          this.page = 'payment';
          this.loading_order = false;
        }, error => {
          this.loading_order = false;
        })

      });
    }
    else {
      this.newItemEvent.emit('LOGIN');
    }
  }

  checkPayment() {
    this.warningMap = new Map();

    if (this.card.number == null || this.card.number == "") {
      this.warningMap.set('number', true);
    }
    if (this.card.date == null || this.card.date == "") {
      this.warningMap.set('date', true);
    }
    if (this.card.holder == null || this.card.holder == "") {
      this.warningMap.set('holder', true);
    }
    if (this.card.code == null || this.card.code == "") {
      this.warningMap.set('code', true);
    }
    if (this.card.brand == null || this.card.brand == "") {
      this.warningMap.set('brand', true);
    }

    if (this.warningMap.size == 0) {
      this.payment()
    }
  }

  payment() {
    this.loading_order = true;
    let token = localStorage.getItem('tokenUser')
    
    if (token != null) {
      this.orderService.payment(this.card, this.isOrder.id, token).subscribe(data => {
        console.log(data)
        this.sendSmsUser(this.isOrder.user.id)
      },
      error => {
          this.loading_order = false;
          console.log(error)
        })
    }
    else {
      this.newItemEvent.emit('LOGIN');
    }

  }


  sendSmsUser(id_user) {
    let message = 'Recebemos seu pedido! Atualizaremos você por e-mail quando ele estiver em andamento';

    this.orderService.sendSMS(id_user, message).subscribe(data => {
      // console.log(data)
      this.router.navigate([this.router.url])
      this.openSnackBar("Pedido enviado!", "Fechar")
      this.loading_order = false;
      this.page = 'success';
    }, error => {
      this.loading_order = false;
    })
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(money);
  }

  backPageCatalog() {
    this.newItemEvent.emit('back');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
