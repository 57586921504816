<div style="width: 100%; height: 100%;" (click)="EventClick($event)" *ngIf="!blockScreen">
    <div [ngStyle]="{'background-color': header.back}" class="div-header" *ngIf="header.show_header && !loading">
        <div class="back" (click)="back()" *ngIf="screenBack.length != 1 && !error"
        [ngStyle]="{'color': header.color}">
            <
        </div>
        <div class="title" [ngStyle]="{'color': header.color,
            'padding': (screenBack.length != 1)? '10px 55px 10px 10px' : '10px'}"> 
    
            <img [src]="header.logo" class="logo_navbar" *ngIf="header.logo != null || header.logo != ''">
                
            {{ header.title }} 

        </div>
    
        <div class="options">
            <svg class="icons wpp" viewBox="0 0 461.000000 460.000000" (click)="openWhatsapp()" *ngIf="header.whatsapp != null && header.whatsapp != '' && header.whatsapp.length > 8">
                <g transform="translate(0.000000,460.000000) scale(0.100000,-0.100000)" [style.fill]="header.color" stroke="none">
                    <path d="M2155 4384 c-411 -39 -716 -155 -1040 -394 -274 -203 -503 -465 -653 -750 -216 -407 -282 -875 -191 -1347 39 -204 132 -455 239 -644 78 -137 80 -111 -37 -389 -65 -153 -203 -523 -219 -584 -5 -21 -3 -26 13 -26 18 0 443 150 707 249 151 57 164 56 312 -29 68 -38 163 -88 212 -109 713 -314 1568 -190 2169 313 764 638 969 1685 511 2601 -266 533 -762 904 -1408 1054 -178 41 -479 68 -615 55z m-678 -823 c119 -2 124 -8 273 -371 51 -124 99 -242 107 -263 25 -70 -7 -137 -141 -292 -71 -82 -77 -93 -73 -125 13 -98 266 -418 452 -570 158 -129 450 -289 545 -298 45 -4 47 -3 100 55 30 33 91 107 135 165 92 122 115 138 181 120 57 -15 583 -268 607 -291 27 -26 31 -84 14 -181 -26 -148 -49 -195 -136 -280 -141 -136 -264 -191 -451 -198 -144 -5 -236 11 -446 79 -207 67 -360 129 -469 191 -376 213 -661 493 -978 960 -187 276 -256 477 -245 716 6 122 41 241 99 338 41 67 160 199 200 220 38 19 98 33 129 29 14 -2 57 -4 97 -4z"/>
                </g>
            </svg>
    
            <!-- <svg class="icons perfil" viewBox="0 0 512.000000 512.000000" [style.background-color]="header.color" (click)="perfil = !perfil">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="header.back" stroke="none">
                    <path d="M2380 4541 c0 -5 -15 -11 -32 -14 -75 -13 -104 -20 -113 -26 -5 -4 -30 -14 -55 -21 -78 -24 -196 -91 -285 -161 -394 -309 -524 -843 -313 -1279 82 -168 255 -365 387 -440 14 -8 28 -17 31 -20 3 -3 43 -24 90 -47 124 -61 190 -80 362 -103 131 -18 313 9 473 70 67 25 176 87 254 145 21 15 184 177 203 202 82 105 153 258 199 428 20 74 18 363 -4 435 -9 30 -19 66 -22 80 -15 57 -76 184 -133 273 -56 89 -195 233 -280 290 -137 92 -260 145 -404 174 -16 3 -28 9 -28 14 0 5 -74 9 -165 9 -91 0 -165 -4 -165 -9z"/>
                    <path d="M2310 1880 c-151 -13 -344 -41 -490 -71 -25 -6 -58 -12 -75 -15 -16 -3 -39 -9 -50 -14 -11 -5 -33 -11 -50 -15 -16 -3 -41 -8 -55 -11 -14 -3 -34 -9 -45 -13 -11 -5 -87 -30 -170 -57 -82 -26 -166 -56 -185 -65 -19 -9 -53 -25 -75 -34 -243 -103 -503 -295 -593 -439 l-31 -49 62 -80 c85 -110 262 -293 377 -389 362 -303 808 -498 1290 -564 169 -23 481 -23 650 0 577 79 1086 335 1486 747 94 98 220 248 231 275 12 31 -176 244 -254 289 -18 11 -33 23 -33 27 0 4 -5 8 -11 8 -5 0 -36 17 -67 39 -57 38 -166 96 -277 146 -33 15 -67 30 -75 34 -8 4 -82 29 -165 56 -82 26 -159 51 -170 56 -11 4 -45 13 -75 20 -30 6 -64 15 -75 20 -11 4 -33 10 -50 13 -16 3 -50 9 -75 15 -324 68 -666 94 -950 71z"/>
                </g>
            </svg> -->
        </div>
                
    </div>
    
    <div *ngIf="loading" class="loading">
        <span class="spinner-border spinner-border-sm" 
            role="status" aria-hidden="true"></span>
    </div>
    
    <div *ngIf="!registration">
        <div *ngIf="!loading && error" class="loading">
            Nenhum Content encontrado.
        </div>
        
        <div class="screen_user" *ngIf="perfil">
        
            <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;" *ngIf="!send_code && !login_status">
        
                <h4 [style.color]="header.back"> Login </h4>
        
                <input mat-input [ngStyle]="{'border-color': warningMap.get(login_email)? 'red' : ''}" [ngModel]="login_email" placeholder="E-mail" class="input">
        
                <!-- <h2 [style.color]="header.back"> ou </h2>
        
                <input mat-input [ngStyle]="{'border-color': warningMap.get(phone)? 'red' : ''}" placeholder="Telefone" class="input"> -->
        
                <button mat-button class="button-cor" [style.background]="header.back" [style.color]="header.color" (click)="loginSendEmail()"> 
                    Entrar
                </button>
            </div>
        
            <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;" *ngIf="send_code && !login_status">
                <h4 [style.color]="header.back"> Insira o código enviado para o seu e-mail ou celular </h4>
            
                <input mat-input placeholder="Código" class="input">
            
                <button mat-button class="button-cor" [style.background]="header.back" [style.color]="header.color" (click)="login()"> 
                    Validar
                </button>
            </div>
        
        
            <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: flex-start;" *ngIf="login_status">
                <input mat-input placeholder="Nome" class="input">
        
                <input mat-input placeholder="Email" class="input">
            </div>
        </div>
        
        <div *ngIf="check_input && !loading && !error && !perfil">
            <app-mini-banner style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Mini Banner'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)"> 
            </app-mini-banner>
        
            <app-banner style="width: 100%;" *ngIf="!screen_report && (sendFormat?.name == 'Banner' || sendFormat?.name ==  'Vazio')" 
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-banner>
        
            <app-gallery style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Galeria'" 
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-gallery>
        
            <app-survey style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Enquete'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign]="campaign"
                (newItemEvent)="addItem($event)">
            </app-survey>
        
            <!-- <app-catalog style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Catálogo'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false"
                (newItemEvent)="addItem($event)">
            </app-catalog> -->
        
            <app-view-menu style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Catálogo'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false"
                (newItemEvent)="addItem($event)">
            </app-view-menu>

            <app-cupom style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Cupom'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-cupom>
        
            <app-user-registration style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Cadastro'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-user-registration>
                   
            <app-video style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Vídeo'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false">
            </app-video>
            
            <app-post style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Post'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false">
            </app-post>
    
            <app-podcast style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Podcast'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false">
            </app-podcast>
            
            <app-course-video style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Cursos'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false"
                (newItemEvent)="addItem($event)">
            </app-course-video>

            <app-check-point style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Ponto'"
                [list]="sendList"
                [header]="header"
                [campaign_id]="campaign.id"
                [preview_campaign]="false"
                [qrcode_id]="qrcode_id"
                (newItemEvent)="addItem($event)">
            </app-check-point>

            <app-view-notice style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Notícia'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-view-notice>
                
            <app-view-tabloide style="width: 100%;" *ngIf="!screen_report && sendFormat?.name == 'Tabloide'"
                [list]="sendList"
                [header]="header"
                [preview_campaign]="false"
                [campaign_id]="campaign.id"
                (newItemEvent)="addItem($event)">
            </app-view-tabloide>
            
            <app-link style="width: 100%;" *ngIf="screen_report"
                [establishment]="establishment_report"
                (newItemEvent)="closeReport($event)">
            </app-link>

            <!-- mocked HASHLAR payment -->
            <app-payment 
                *ngIf="!screen_report && (sendFormat?.name == 'Payment' || sendFormat?.name == 'Hashlar')"
                [header]="header" [list]="sendList" [preview_campaign]="false">
            </app-payment>
        </div>
    </div>

    <div class="card_register" *ngIf="!loading && registration">
        <app-login-user style="width: 100%;" [list]="registration_obj" [preview]="false" [header]="header" [catalog]="null"
            (newItemEvent)="returnLogin($event)"></app-login-user>
    </div>

    <!-- <div id="the_end" *ngIf="loading_more" 
        style="width: 100%; position: relative; display: flex; justify-content: center; align-items: center; padding: 10px 0;">
            Ver Mais 
            <span style="margin-left: 10px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div> -->
    
</div>