import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MDBModalRef } from 'angular-bootstrap-md';
import { CampaignService } from 'src/app/service/campaign.service';
import { QrCodeService } from 'src/app/service/qrcode.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-report-campaign',
  templateUrl: './report-campaign.component.html',
  styleUrls: ['./report-campaign.component.css']
})
export class ReportCampaignComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private storageService: StorageService,
    private campaignService: CampaignService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
  ) { }

  loading: boolean = false;
  loading_download: boolean = false;

  list_campaigns = [];
  campaign_select;

  date = { start: '', end: '' }
  warningMap = new Map();
  page: number = 1;

  color_primary: string = '';
  file_url;
  link_download: string = '';

  // ia anterior, ultimos 7 dias, ultimos 15 e ultimo mes

  list_date = [
    { id: 0, name: 'Últimas 24 horas', day: 1 },
    { id: 1, name: 'Últimos 7 dias', day: 7 },
    { id: 2, name: 'Últimos 15 dias', day: 15 },
    { id: 3, name: 'Último mês', day: 30 },
  ]
  date_select

  hour: string = '';

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    this.getCampaigns(this.page)

    var day = new Date().getDate() +1
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear()
    var hour = new Date().getHours()
    var minute = new Date().getMinutes()

    this.hour = `${hour}:${minute}`;
    
    this.date.end = `${year}/${month}/${day-1} ${this.hour}`;
  }

  setDateSelect(value_day) {

    var aux_date = new Date();
    // var aux_date = new Date('2022/01/15');

    if (value_day == 30) {
      var date = aux_date.setDate(aux_date.getDate());

      var day = new Date(date).getDate()
      var month = new Date(date).getMonth();
      var year = new Date(date).getFullYear()

      var month_aux = month - 1;
      if (month_aux < 0) {
        month = 12;
        year -= 1;
      }

      this.date.start = `${year}/${month}/${day} ${this.hour}`;
    }
    else {
      var date = aux_date.setDate(aux_date.getDate() - value_day);

      var day = new Date(date).getDate()
      var month = new Date(date).getMonth() + 1;
      var year = new Date(date).getFullYear()

      this.date.start = `${year}/${month}/${day} ${this.hour}`;
    }

  }

  getCampaigns(page) {
    this.loading = true;

    this.campaignService.getCampaigns(page).subscribe(data => {
      this.list_campaigns = this.list_campaigns.concat(data.results)

      if (data.next != null) {
        this.page += 1;
        this.getCampaigns(this.page)
      }
      else {
        this.loading = false;
      }
    })
  }

  selectCampaign(obj) {
    this.campaign_select = obj.id;
    
    // var dateSatart = new Date(this.date.start).toISOString().split('T')[0].toString()
    // var dateCampaign = obj.created_at.split('T')[0].toString()
    
    // if(new Date(dateSatart) < new Date(dateCampaign)){

    //   var day = new Date(obj.created_at).getDate() +1
    //   var month = new Date(obj.created_at).getMonth() + 1;
    //   var year = new Date(obj.created_at).getFullYear()

    //   this.date.start = `${year}/${month}/${day} ${this.hour}`;
    // }
  }

  close() {
    this.modalRef.hide();
  }

  gerar() {
    this.warningMap = new Map();

    if (this.date.start == "" || this.date.start == null) {
      this.warningMap.set('start', true);
    }
    if (this.date.end == "" || this.date.end == null) {
      this.warningMap.set('end', true);
    }

    if (this.warningMap.size == 0) {
      if (this.loading_download) return;

      this.loading_download = true;

      var date_start = new Date(this.date.start).toISOString().split('T')
      var date_end = new Date(this.date.end).toISOString().split('T')

      // console.log(this.date);

      //qr_code_name
      this.campaignService.getRelatorioExcel(`${date_start[0]} ${this.hour}`, `${date_end[0]} ${this.hour}`, this.campaign_select).subscribe(data => {
        let blob = data.body.slice(0, data.body.size, "text/xlsx")
        this.file_url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        this.link_download = `https://api.sbsolutions.com.br/core/generate-campaing-report/?campaign_id=${this.campaign_select}&date_from=${date_start[0]}&date_to=${date_end[0]}`;

        setTimeout(() => {
            this.loading_download = false;
            document.getElementById('downloadLink').click()
        }, 500);

        this.loading_download = false;
      })
    }
  }

  copyLink() {
    var copyText = document.getElementById("myLink") as HTMLInputElement
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
    this.openSnackBar("Link copiado!", "Fechar")
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

}
