<div class="screen" (click)="EventClick($event)" *ngIf="!blockScreen">

    <!-- MENU LATERAL -->
    <div class="menu_lateral sidenav" [ngClass]="{'open': !open_menu}">

        <div class="size_navbar custom-scrollbar" [ngClass]="{'size_navbar_web': preview != true}"
            [ngStyle]="{'min-height': preview ? 'auto' : '100vh'}">

            <div style="width: 100%; padding: 40px 20px 20px;">
                <div class="card_top_navbar">
                    <svg class="logo_navbar_right" *ngIf="header?.logo == null || header?.logo == ''"
                        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="grey"
                            stroke="none">
                            <path
                                d="M901 4739 c-228 -38 -435 -228 -508 -464 -17 -57 -18 -136 -18 -1715 0 -1579 1 -1658 18 -1715 66 -214 238 -386 452 -452 57 -17 136 -18 1715 -18 1579 0 1658 1 1715 18 214 66 386 238 452 452 17 57 18 136 18 1715 0 1579 -1 1658 -18 1715 -65 211 -231 379 -447 452 -52 17 -129 18 -1690 19 -934 1 -1658 -2 -1689 -7z m1473 -2747 l696 -633 153 126 c83 70 161 134 172 144 230 193 446 371 451 371 3 0 150 -120 326 -266 l319 -267 -3 -266 c-3 -244 -5 -270 -25 -319 -41 -102 -122 -183 -225 -225 l-53 -22 -1620 0 c-1545 0 -1622 1 -1673 18 -103 36 -192 123 -235 230 -20 48 -22 75 -25 323 l-3 271 518 577 c285 316 521 575 524 573 4 -1 320 -287 703 -635z" />
                        </g>
                    </svg>
                    <img [src]="header.logo" class="logo_navbar_right"
                        *ngIf="header != null && header.logo != null && header.logo != ''" style="cursor: pointer;"
                        (click)="resetScreen()">
                    <div style="position: absolute; right: 2px;">
                        <svg width="40px" height="40px" viewBox="0 0 24 24" [style.fill]="color_"
                            style="cursor: pointer;" (click)="open_menu = !open_menu">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                        </svg>
                    </div>
                </div>

                <div class="card_social">
                    <span *ngIf="header?.facebook != null && header?.facebook != '' &&
                                header?.instagram != null && header?.instagram != '' &&
                                header?.youtube != null && header?.youtube != ''"> Siga-nos nas Redes Sociais </span>

                    <!-- {{ header.description }} -->
                    <div class="card_icons_social">
                        <a (click)="openSite('facebook')" *ngIf="header?.facebook != null && header?.facebook != ''">
                            <svg class="icon" [style.fill]="color_" viewBox="0 0 1600.000000 1600.000000">
                                <g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)" stroke="none">
                                    <path
                                        d="M2670 14723 c-85 -10 -236 -46 -331 -79 -529 -184 -917 -627 -1042 -1188 l-22 -101 0 -5290 0 -5290 23 -103 c33 -146 65 -241 127 -369 247 -516 734 -852 1307 -903 156 -14 10250 -14 10407 0 391 35 720 188 997 464 185 186 306 379 384 615 48 145 68 239 80 389 13 160 13 10234 0 10394 -12 147 -32 244 -76 377 -137 413 -433 748 -827 936 -127 61 -197 86 -347 123 l-105 27 -5270 1 c-2898 0 -5286 -1 -5305 -3z m10500 -644 c100 -16 252 -71 342 -125 95 -57 226 -181 288 -273 96 -144 145 -289 161 -481 7 -84 9 -1801 7 -5235 -4 -4719 -5 -5115 -20 -5175 -89 -348 -305 -593 -618 -702 -142 -49 -102 -48 -1521 -48 l-1329 0 0 2215 0 2215 605 0 605 0 5 23 c17 70 435 2175 435 2189 0 17 -44 18 -825 18 l-825 0 0 368 c0 202 5 385 10 407 17 76 101 119 295 151 83 14 201 18 693 21 l592 5 0 892 0 892 -117 57 c-153 73 -213 97 -374 151 -606 201 -1320 280 -1819 201 -940 -151 -1580 -823 -1770 -1861 -41 -222 -51 -374 -57 -836 l-6 -448 -473 0 -474 0 0 -1115 0 -1115 475 0 475 0 0 -2215 0 -2215 -2627 2 -2628 3 -74 23 c-99 31 -204 80 -282 132 -92 62 -216 193 -273 288 -54 90 -109 242 -125 342 -8 44 -11 1711 -11 5240 0 5006 1 5178 19 5265 77 374 359 657 731 736 81 17 321 18 5265 18 3535 1 5201 -2 5245 -10z m-2650 -2860 c279 -25 557 -83 785 -163 l120 -42 3 -362 2 -362 -227 0 c-474 0 -700 -36 -914 -146 -188 -97 -312 -240 -388 -450 -58 -161 -61 -200 -61 -951 l0 -683 755 0 c713 0 755 -1 755 -17 -1 -10 -42 -223 -93 -473 l-92 -455 -662 -3 -663 -2 0 -2535 0 -2535 -635 0 -635 0 0 2535 0 2535 -475 0 -475 0 0 475 0 475 474 0 474 0 5 748 c4 622 7 769 21 882 37 293 103 527 210 745 207 422 537 679 980 765 56 11 117 21 136 23 80 9 494 6 600 -4z" />
                                </g>
                            </svg>
                        </a>
                        <a (click)="openSite('instagram')" *ngIf="header?.instagram != null && header?.instagram != ''">
                            <svg class="icon" [style.fill]="color_" viewBox="0 0 100 100" x="0px" y="0px"
                                style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g>
                                    <path
                                        d="M50.3,29c-11.6,0-21.1,9.5-21.1,21.1s9.5,21.1,21.1,21.1c11.6,0,21-9.5,21-21.1S61.8,29,50.3,29z M50.3,66.2 c-8.9,0-16.1-7.2-16.1-16.1c0-8.9,7.2-16.1,16.1-16.1c8.8,0,16,7.2,16,16.1C66.3,59,59.1,66.2,50.3,66.2z" />
                                    <path
                                        d="M73.8,17.1c-2.2,0-4.2,0.8-5.7,2.3c-1.5,1.5-2.3,3.5-2.3,5.7c0,2.2,0.8,4.2,2.3,5.7c1.5,1.5,3.5,2.3,5.7,2.3 s4.2-0.8,5.7-2.3v0c1.4-1.4,2.3-3.4,2.4-5.6l0-0.1c0-2.1-0.9-4.1-2.3-5.5C78.1,18,76,17.1,73.8,17.1z M75.9,27.2 c-1.1,1.1-3.2,1.1-4.3,0c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.6,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9c0.8,0,1.6,0.3,2.2,0.9 c0.5,0.5,0.9,1.3,0.9,2C76.8,25.9,76.5,26.7,75.9,27.2z" />
                                    <path
                                        d="M67.8,7H32.7C18.6,7,7.2,18.4,7.2,32.5v35.2c0,14.1,11.4,25.5,25.5,25.5h35.2c14.1,0,25.5-11.4,25.5-25.5V32.5 C93.3,18.4,81.9,7,67.8,7z M88.3,67.7c0,11.3-9.2,20.5-20.5,20.5H32.7c-11.3,0-20.5-9.2-20.5-20.5V32.5c0-11.3,9.2-20.5,20.5-20.5 h35.2c11.3,0,20.5,9.2,20.5,20.5V67.7z" />
                                </g>
                            </svg>
                        </a>
                        <a (click)="openSite('youtube')" *ngIf="header?.youtube != null && header?.youtube != ''">
                            <svg class="icon" [style.fill]="color_" viewBox="0 0 512.000000 512.000000">
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                    <path
                                        d="M1590 4098 c-157 -4 -294 -12 -305 -17 -11 -5 -74 -12 -140 -15 -66 -3 -154 -12 -196 -20 -74 -15 -159 -43 -169 -56 -3 -4 -26 -19 -52 -34 -65 -38 -168 -146 -199 -208 -59 -121 -70 -147 -89 -223 -58 -234 -90 -962 -62 -1435 24 -405 49 -580 97 -686 14 -30 25 -58 25 -62 0 -25 61 -116 114 -170 134 -137 278 -189 591 -213 66 -5 181 -14 255 -20 190 -17 2032 -17 2230 -1 80 7 212 17 294 22 229 14 319 36 431 105 85 53 162 130 211 210 34 56 93 216 94 253 1 10 7 35 16 57 8 22 22 121 31 220 25 260 25 1170 0 1428 -9 98 -21 186 -26 195 -5 9 -14 43 -21 76 -44 214 -148 368 -316 468 -98 59 -206 85 -384 94 -69 3 -134 10 -145 14 -59 26 -1615 38 -2285 18z m1731 -209 c10 -5 80 -9 156 -9 225 -1 651 -27 717 -45 79 -21 189 -94 223 -148 14 -23 31 -45 37 -49 6 -5 7 -8 1 -8 -6 0 -6 -3 1 -8 26 -18 83 -238 84 -322 0 -26 4 -50 9 -55 40 -43 40 -1416 0 -1459 -5 -6 -9 -27 -9 -48 0 -75 -58 -307 -83 -333 -7 -7 -24 -33 -39 -59 -17 -28 -46 -58 -75 -76 -27 -16 -54 -34 -61 -40 -45 -39 -172 -56 -532 -70 -151 -6 -315 -13 -365 -15 -505 -27 -1634 -14 -2160 24 -154 11 -309 37 -328 55 -7 6 -30 20 -51 31 -67 34 -121 97 -152 177 -15 40 -31 78 -35 83 -13 18 -39 159 -49 265 -48 499 -34 1350 26 1655 9 44 20 82 24 85 4 3 15 28 24 56 32 98 109 184 213 237 55 29 199 46 483 57 151 6 318 13 370 16 352 19 1537 22 1571 3z" />
                                    <path
                                        d="M2040 2566 c0 -675 4 -815 22 -767 3 9 94 57 268 142 47 23 87 45 90 50 3 4 40 23 83 43 43 19 90 44 105 54 15 10 61 34 102 53 41 18 77 36 80 40 5 7 73 42 265 139 44 22 88 46 98 52 19 14 138 75 260 134 49 24 77 43 77 53 -1 10 -32 31 -85 55 -47 22 -85 43 -85 48 0 4 -5 8 -12 8 -9 0 -566 287 -673 347 -61 35 -213 114 -305 159 -52 25 -97 50 -100 55 -3 4 -42 24 -88 45 -45 20 -82 41 -82 45 0 5 -4 9 -10 9 -7 0 -10 -261 -10 -764z m253 398 c18 -9 34 -20 37 -23 3 -4 43 -26 90 -49 142 -71 249 -126 267 -139 19 -13 132 -72 276 -143 48 -24 86 -47 85 -51 -2 -4 -79 -46 -173 -93 -93 -48 -176 -91 -183 -97 -7 -6 -47 -27 -90 -47 -42 -20 -79 -39 -82 -44 -3 -4 -46 -26 -95 -49 -50 -22 -92 -44 -95 -49 -5 -8 -65 -40 -75 -40 -3 0 -5 189 -5 420 0 231 2 420 5 420 4 0 20 -7 38 -16z" />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>

                <div class="card_list_tags">
                    <h2 [style.color]="color_"> Categorias </h2>
                    <h5> Navegue por nossas categorias </h5>
                    <div *ngFor="let obj of list_tags">
                        <div class="text_tag" [style.color]="color_"> {{ obj.name }} </div>
                    </div>
                </div>

                <div class="line"></div>


                <div class="card_list_tags">
                    <h2 [style.color]="color_"> Notícias </h2>
                    <h5> Confira as últimas notícias </h5>

                    <!-- <div class="custom-scrollbar" style="width: 100%; max-width: 3340px; overflow-x: auto; display: flex; flex-direction: row; align-items: center;">
                        <div *ngFor="let obj of list_tags" class="card_noticias">
                            <img [src]="obj.image" [ngStyle]="{'border-color': color_}" class="image_noticia">
                            <div class="text_tag" [style.color]="color_"> {{ obj.name }} </div>
                        </div>
                    </div> -->
                </div>

                <div class="line"></div>

                <div class="card_list_tags">
                    <h2 [style.color]="color_"> Revista </h2>
                    <h5> Leia a Edição #40 </h5>
                    <!-- <div *ngFor="let obj of list_tags">
                        <div class="text_tag" [style.color]="color_"> {{ obj.name }} </div>
                    </div> -->
                    <button mat-button class="btn_navbar" [style.background]="color_">
                        VER TODAS AS EDIÇÕES
                    </button>
                </div>

                <div class="line"></div>

                <div class="card_list_tags">
                    <h2 [style.color]="color_"> Playlist </h2>
                    <h5> Veja a nossa super playlist sobre negócios</h5>
                    <!-- <div *ngFor="let obj of list_tags">
                        <div class="text_tag" [style.color]="color_"> {{ obj.name }} </div>
                    </div> -->
                    <button mat-button class="btn_navbar" [style.background]="color_">
                        VER TODAS OS VÍDEOS
                    </button>
                </div>

                <div class="line"></div>

                <div style="font-size: 16px; font-weight: 600; margin: 10px 0;" [ngStyle]="{'color': color_}">
                    Sobre
                </div>
                <div style="font-size: 16px; font-weight: 600; margin: 10px 0;" [ngStyle]="{'color': color_}">
                    Política de Privacidade
                </div>
            </div>

            <div class="card_footer" *ngIf="header != null && header?.image_side != null && header?.image_side != ''">
                <img [src]="header.image_side" class="image_footer">
            </div>
        </div>

    </div>

    <div class="sidenav-content">
        <!-- MENU TOP -->
        <div class="aux_navBar" *ngIf="header.show_header" [ngClass]="{'fixed': !preview, 'static': preview}">

            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">

                <div class="back" (click)="backCampaign()" style="cursor: pointer;"
                    *ngIf="backup_list.length >= 1 && !error && !card_login">
                    <svg height="24px" viewBox="0 0 24 24" width="24px" [style.fill]="color_">
                        <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                        <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                    </svg>
                </div>

                <div class="back" (click)="backLogin()" style="cursor: pointer;" *ngIf="card_login">
                    <svg height="24px" viewBox="0 0 24 24" width="24px" [style.fill]="color_">
                        <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                        <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                    </svg>
                </div>

                <img [src]="header.logo" class="logo_navbar" style="cursor: pointer;" (click)="resetScreen()"
                    *ngIf="header != null && (header.logo != null || header.logo != '')"
                    routerLink="/redirect-sbs/{{screen_url}}">
            </div>

            <div class="itens_navbar" *ngIf="!card_search">
                <!-- <div class="card_search ocult_div" [ngClass]="{'ocult_div': !preview}"
                    [ngStyle]="{'display': preview ? 'none' : 'normal' }">
                    <input type="text" [ngStyle]="{'border-color': color_}" [(ngModel)]="search_text"  placeholder="Como podemos ajudar?" (keyup.enter)="search(search_text)">
                    <svg class="icon_search" [style.fill]="color_" viewBox="0 0 24 24" (click)="search(search_text)">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    </svg>
                </div> -->

                <div class="card_search border_card ocult_div" [ngStyle]="{'border-color': color_}"
                    [ngClass]="{'ocult_div': !preview}" [ngStyle]="{'display': preview ? 'none' : 'normal' }">

                    <svg class="icon_search" *ngIf="bar_search" viewBox="0 0 24 24" [style.fill]="color_"
                        style="margin-left: 5px; cursor: pointer;" (click)="bar_search = false">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                    </svg>

                    <input type="text" *ngIf="bar_search" [(ngModel)]="search_text" placeholder="Como podemos ajudar?"
                        (keyup)="search(search_text)">

                    <svg class="icon_search" [ngStyle]="{'margin-right': bar_search ? '5px' : '0px'}"
                        [style.fill]="color_" viewBox="0 0 24 24" (click)="bar_search = true">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    </svg>

                </div>

                <!-- <mat-tab-group class="card_tags ocult_tag" *ngIf="list_tags != null && list_tags.length > 0">
                    <mat-tab *ngFor="let tab of list_tags">
                        <ng-template mat-tab-label (click)="filterTag(tab)">
                            <div class="icon_tag" (click)="filterTag(tab)">
                                <img [src]="tab.tag_obj.image">
                            </div>
                            <span [ngStyle]="{'color': color_}" (click)="filterTag(tab)"> {{ tab.tag_obj.name }} </span>
                        </ng-template>

                    </mat-tab>
                </mat-tab-group> -->

                <div class="size_tags">
                    <div class="card_tags ocult_tag" *ngIf="list_tags != null && list_tags.length > 0">
                        <div class="tag" *ngFor="let tab of list_tags" (click)="filterTag(tab)"
                            [ngStyle]="{'border-color': (tag_select?.id == tab?.id) ? 'gray' : ''}">
                            <div class="icon_tag">
                                <img [src]="tab.tag_obj.image">
                            </div>
                            <span [ngStyle]="{'color': color_}"> {{ tab.tag_obj.name }} </span>
                        </div>
                    </div>
                </div>

                <button mat-button class="btn_login" [ngStyle]="{'background-color': color_}"
                    (click)="card_login = true"> {{checkUserLogin() ? 'Entrar' : 'Perfil'}} </button>

                <svg class="card_icon_search" (click)="openCardSearch()" [style.fill]="color_" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </svg>

                <svg width="40px" height="40px" style="cursor: pointer;" (click)="open_menu = !open_menu" viewBox="0 0 24 24"
                    [style.fill]="color_">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                </svg>
            </div>

            <div class="card_search input_w100" *ngIf="card_search">
                <svg height="24px" viewBox="0 0 24 24" width="24px" [style.fill]="color_" (click)="openCardSearch()"
                    style="position: absolute; top: 3px; left: -23px; cursor: pointer;">
                    <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                    <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                </svg>

                <input type="text" class="card_search_input" [ngStyle]="{'border-color': color_}"
                    placeholder="Como podemos ajudar?" [(ngModel)]="input_search" (keyup)="searchList(input_search)">

                <svg class="icon_search" [style.fill]="color_" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </svg>
            </div>
        </div>



        <!-- MAIN -->
        <div class="screen_content" (click)="EventClick($event)" (scroll)="scroll($event)"
            [ngStyle]="{'padding-left': preview != true ? '' : '0px'}">
            <div *ngIf="loading" class="loading" [ngStyle]="{'height': preview ? '80%' : '80vh'}">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>

            <div class="card_register" *ngIf="!loading && registration">
                <app-login-user style="width: 100%;" [list]="registration_obj" [preview]="false" [header]="header"
                    [catalog]="null" (newItemEvent)="returnLogin()"></app-login-user>
            </div>

            <div *ngIf="!registration" style="width: 100%;">
                <div *ngIf="!loading && error" class="loading" style="margin-top: 100px;">
                    Nenhum Content encontrado.
                </div>

                <!-- <mat-tab-group class="card_tags view_tag" *ngIf="list_tags != null && list_tags.length > 0"
                    [ngClass]="{'viewHeaderTop': viewHeaderTop}">
                    <mat-tab *ngFor="let tab of list_tags">
                        <ng-template mat-tab-label (click)="filterTag(tab)">
                            <div class="icon_tag" [ngStyle]="{'border-color': color_}" (click)="filterTag(tab)">
                                <img [src]="tab.tag_obj.image">
                            </div>
                            <span [ngStyle]="{'color': color_}" (click)="filterTag(tab)"> {{ tab.tag_obj.name }} </span>
                        </ng-template>

                    </mat-tab>
                </mat-tab-group> -->

                <div class="card_navbar" *ngIf="!preview && viewHeaderTop"></div>
                
                <div class="size_tags custom-scrollbar">
                    <div class="card_tags view_tag" *ngIf="list_tags != null && list_tags.length > 0"
                        style="margin-top: 10px;">
                        <div class="tag" *ngFor="let tab of list_tags" (click)="filterTag(tab)"
                            [ngStyle]="{'border-color': (tag_select?.id == tab?.id) ? 'gray' : ''}">
                            <div class="icon_tag">
                                <img [src]="tab.tag_obj.image">
                            </div>
                            <span [ngStyle]="{'color': color_}"> {{ tab.tag_obj.name }} </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="!card_login">
                    <div *ngFor="let obj of sidebar.center; let idx = index">
                        <app-sidebar-lateral [sidebar]="obj" [header]="header" [index]="idx"
                            [size]="sidebar.center.length" [preview_lateral]="true">
                        </app-sidebar-lateral>
                    </div>
                </div>

                <div *ngIf="check_input && !loading && !error" class="size_computer">

                    <app-login-assai class="screen_login" *ngIf="card_login" [header]="header"
                        (newItemEvent)="addItem($event, campaign)">
                    </app-login-assai>

                    <div class="advertising_left" *ngIf="!card_login && preview != true">
                        <div *ngFor="let obj of sidebar.left; let idx = index" class="div_scrool">
                            <app-sidebar-lateral [sidebar]="obj" [header]="header" [index]="idx"
                                [size]="sidebar.left.length" [preview_lateral]="true">
                            </app-sidebar-lateral>
                        </div>
                        <div style="height: 10px;"></div>
                    </div>

                    <!-- <div class="card_campaign"> -->


                    <div class="card_feed" *ngIf="!card_login" [ngStyle]="{'width': preview == true ? '100%' : ''}">

                        <div *ngIf="!advertising && check_input && !loading && !error && !screen_report"
                            id="scroll_check">

                            <div class="card_description" [style.color]="campaign.header_color">
                                {{ header.description }}
                            </div>

                            <div *ngFor="let campaign of list_campaign; let i = index">

                                <!-- <div style="width: 100%; text-align: center;" id="{{'card'+i}}"> 
                                    {{ i+1 }} - {{ campaign?.obj?.name != null ? campaign?.obj?.name : '-' }} ({{ campaign?.sendFormat?.name != null ? campaign?.sendFormat?.name : '-' }})
                                </div> -->

                                <app-mini-banner style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Mini Banner'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign_id]="screen_url"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-mini-banner>

                                <app-banner style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Banner'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign_id]="screen_url"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-banner>

                                <app-gallery style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Galeria'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign_id]="screen_url"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-gallery>

                                <app-survey style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Enquete'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign]="campaign"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-survey>

                                <!-- <app-catalog style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Catálogo'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="screen_url"
                                    [type_campaign]="'feed'" [preview_campaign]="false"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-catalog> -->

                                <app-view-menu style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Catálogo'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="screen_url"
                                    [type_campaign]="'feed'" [preview_campaign]="false"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-view-menu>

                                <app-cupom style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Cupom'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign_id]="screen_url"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-cupom>

                                <app-user-registration style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Cadastro'"
                                    [list]="campaign.obj" [header]="header" [type_campaign]="'feed'"
                                    [preview_campaign]="false" [campaign_id]="screen_url"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-user-registration>


                                <app-video style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Vídeo'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="screen_url"
                                    [type_campaign]="'feed'" [preview_campaign]="false">
                                </app-video>

                                <app-post style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Post'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="screen_url"
                                    [type_campaign]="'feed'" [preview_campaign]="preview">
                                </app-post>

                                <app-podcast style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Podcast'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="screen_url"
                                    [type_campaign]="'feed'" [preview_campaign]="false"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-podcast>

                                <app-course-video style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Cursos'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="campaign.id"
                                    [preview_campaign]="false" (newItemEvent)="addItem($event, campaign)">
                                </app-course-video>

                                <app-check-point style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Ponto'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="campaign.id"
                                    [preview_campaign]="false" [qrcode_id]="qrcode_id"
                                    (newItemEvent)="addItem($event, campaign)">
                                </app-check-point>

                                <app-view-notice style="width: 100%;"
                                    *ngIf="campaign?.sendFormat?.name != null && campaign?.sendFormat?.name == 'Notícia'"
                                    [list]="campaign.obj" [header]="header" [campaign_id]="campaign.id"
                                    [preview_campaign]="false" (newItemEvent)="addItem($event, campaign)">
                                </app-view-notice>

                            </div>
                        </div>

                        <app-link style="width: 100%;" *ngIf="screen_report" [establishment]="establishment_report"
                            (newItemEvent)="closeReport($event)">
                        </app-link>


                        <div *ngIf="next_campaign != null && loading_more"
                            style="width: 100%; position: relative; bottom: 0; display: flex; justify-content: center; align-items: center; padding: 20px 0; z-index: 1300 !important;">
                            Ver Mais
                            <span style="margin-left: 10px;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </div>

                        <div id="the_end"></div>

                        <div *ngIf="advertising" (click)="closeAdvertising()"
                            style="width: 90%; margin: 20px auto 0; cursor: pointer; display: flex; justify-content: end;">

                            <svg width="30px" height="30px" viewBox="0 0 24 24" [style.fill]="color_">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path [style.fill]="color_"
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                            </svg>
                        </div>

                        <app-sidebar-lateral *ngIf="advertising" [sidebar]="advertisingSelect" [header]="header"
                            [index]="null" [size]="null" [preview_lateral]="false">
                        </app-sidebar-lateral>

                        <div class="card_tags_bottom" [ngClass]="{'viewHeaderBottom': viewHeaderBottom}"
                            style="display: flex; justify-content: center; background: white;">
                            <div
                                style="width: 100%; max-width: 800px; background-color: white; padding: 10px 0; display: flex; flex-direction: row; justify-content: space-between; align-items: center; overflow-x: auto;">
                                <div *ngFor="let obj of sidebar_list; let idx = index">
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer; padding: 0 30px;"
                                        (click)="openAdvertising(idx)">
                                        <div class="icon_tag" [ngStyle]="{'border-color': color_}">
                                            <img [src]="obj.imagem">
                                        </div>
                                        <span [ngStyle]="{'color': color_}"> {{ obj.name }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="viewHeaderBottom" style="width: 100%; height: 80px;"></div>
                    </div>

                    <div class="advertising_right" *ngIf="!card_login && preview != true">
                        <div *ngFor="let obj of sidebar.right; let idx = index">
                            <app-sidebar-lateral [sidebar]="obj" [header]="header" [index]="idx"
                                [size]="sidebar.right.length" [preview_lateral]="true">
                            </app-sidebar-lateral>
                        </div>
                        <div style="height: 10px;"></div>
                    </div>
                </div>
            </div>



        </div>
    </div>

</div>