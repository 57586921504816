<div *ngIf="logged_in_user" class="screen_perfil">

    <div class="card_info">
        <div class="card_title">
            Perfil
        </div>

        <div class="card_info_user" *ngIf="info_user?.name != null && info_user?.name != ''">
            <span> Nome: </span>
            <div class="input_info"> {{ info_user.name }} </div>
        </div>

        <div class="card_info_user" *ngIf="info_user?.email != null && info_user?.email != ''">
            <span> E-mail: </span>
            <div class="input_info"> {{ info_user.email }} </div>
        </div>

        <div class="card_info_user" *ngIf="info_user?.cep != null && info_user?.cep != ''">
            <span> CEP: </span>
            <div class="input_info"> {{ info_user.cep }} </div>
        </div>

        <div class="card_info_user" *ngIf="info_user?.phone != null && info_user?.phone != ''">
            <span> Telefone: </span>
            <div class="input_info"> {{ info_user.phone }} </div>
        </div>

        <div class="card_info_user" *ngIf="info_user?.cpf != null && info_user?.cpf != ''">
            <span> CPF: </span>
            <div class="input_info"> {{ info_user.cpf }} </div>
        </div>

        <div class="card_info_user" *ngIf="info_user?.birthdate != null && info_user?.birthdate != ''">
            <span> Data de Nascimento: </span>
            <div class="input_info"> {{ info_user.birthdate }} </div>
        </div>

    </div>

    <button mat-button class="btn btn_new_user" style="margin-top: auto; border-color: #ef0000; color: #ef0000;"
        (click)="logoff()"> Sair </button>

</div>

<div *ngIf="!logged_in_user" style="width: 100%;">
    <div style="width: 100%; margin-top: 70px; background-color: #fff;">

        <div class="screen-login" *ngIf="screen.login">

            <img [src]="header.logo" class="logo">
            <h5> Cadastre-se agora e fique por dentro das novidades para seu negócio!</h5>

            <button mat-button class="btn btn_new_user" [style.borderColor]="header.back" [style.color]="header.back"
                (click)="openRegister()"> CRIAR NOVA CONTA </button>

            <div [style.color]="header.back" class="text_registered"> JÁ SOU CADASTRADO </div>

            <div style="width: 100%;" *ngIf="!screen.code">
                <div class="card_input">
                    <div class="title"> E-mail: </div>
                    <input type="text" [(ngModel)]="login_email"
                        [ngStyle]="{'border-color': warningMap.get('login_email')? 'red' : ''}">
                    <!-- <span> <b [style.color]="header.back"> Esqueci minha senha. </b> </span> -->
                </div>

                <button mat-button class="btn btn_enter" [style.borderColor]="header.back"
                    [style.background]="header.back" (click)="sendCode()">
                    ENTRAR
                    <span *ngIf="loading.sendEmail" class="spinner-border spinner-border-sm m_left" role="status"
                        aria-hidden="true"></span>
                </button>

                <!-- <div class="card_social">
                    <button mat-button class="btn btn_social face"> Entrar com Facebook </button>
                    <button mat-button class="btn btn_social google"> Entrar com Google </button>
                    <button mat-button class="btn btn_social twitter"> Entrar com Twitter </button>
                </div> -->
            </div>

            <div style="width: 100%;" class="screen-login" *ngIf="screen.code">
                <div class="card_input">
                    <div class="title"> Codigo: </div>
                    <input type="text" [(ngModel)]="login_code"
                        [ngStyle]="{'border-color': warningMap.get('login_code')? 'red' : ''}">
                    <span> <b [style.color]="header.back"> Reenviar código. </b> </span>
                </div>

                <button mat-button class="btn btn_enter" [style.borderColor]="header.back"
                    [style.background]="header.back" (click)="checkCode()">
                    ENTRAR
                    <span *ngIf="loading.sendCode" class="spinner-border spinner-border-sm m_left" role="status"
                        aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <div class="screen-login" *ngIf="screen.register">

            <img [src]="header.logo" class="logo">

            <div [style.color]="header.back" class="text_registered"> CRIAR NOVA CONTA </div>


            <div class="card_screen_fase">
                <div [style.background]="fase == 1 ? header.back : '#fff'"
                    [style.borderColor]="fase == 1 ? header.back : (fase > 1 ? '#29B84F' : 'gray')"
                    [style.color]="fase == 1 ? '#fff' : (fase > 1 ? '#29B84F' : 'gray')"
                    [style.fontSize]="fase > 1 ? '25px' : '14px'"> {{ fase == 1 ? '1' : '✓' }} </div>

                <span [style.background]="fase == 1 ? header.back : (fase > 1 ? '#29B84F' : 'gray')"
                    class="line"></span>

                <div [style.background]="fase == 2 ? header.back : '#fff'"
                    [style.borderColor]="fase == 2 ? header.back : (fase > 2 ? '#29B84F' : 'gray')"
                    [style.color]="fase == 2 ? '#fff' : (fase > 2 ? '#29B84F' : 'gray')"
                    [style.fontSize]="fase > 2 ? '25px' : '14px'"> {{ fase <= 2 ? '2' : '✓' }} </div>

                        <span [style.background]="fase == 2 ? header.back : (fase > 2 ? '#29B84F' : 'gray')"
                            class="line"></span>

                        <div [style.background]="fase == 3 ? header.back : '#fff'"
                            [style.borderColor]="fase == 3 ? header.back : (fase > 3 ? '#39B84F' : 'gray')"
                            [style.color]="fase == 3 ? '#fff' : (fase > 3 ? '#39B84F' : 'gray')"
                            [style.fontSize]="fase > 3 ? '25px' : '14px'"> {{ fase <= 3 ? '3' : '✓' }} </div>
                        </div>


                        <div class="card_fase">
                            <div *ngIf="fase == 1" [style.color]="header.back">Passo 1 - Dados do Usuário</div>
                            <span *ngIf="fase == 1"> Cadastre seus dados nos campos abaixo. </span>

                            <div *ngIf="fase == 2" [style.color]="header.back">Passo 2 - Dados da Conta</div>
                            <span *ngIf="fase == 2"> Informe seus dados para criar a conta. </span>

                            <div *ngIf="fase == 3" [style.color]="header.back">Passo 3 - Detalhes da Conta</div>
                            <span *ngIf="fase == 3"> Para finalizar seu cadastro informe os dados solicitados, leia e
                                aceite os termos de uso. </span>

                            <div *ngIf="fase == 4" style="text-align: center; color: #39B84F;"> PRONTO!</div>
                            <span *ngIf="fase == 4" style="text-align: center; color: #39B84F;"> Seu Cadastro foi
                                Realizado com Sucesso! </span>
                            <span *ngIf="fase == 4"> Para acessar a sua conta coloque o código recebido por email para
                                ativar sua conta. </span>
                        </div>

                        <div style="width: 100%;" *ngIf="fase == 1">
                            <div class="card_input">
                                <div class="title"> Nome: </div>
                                <input type="text" placeholder="Insira seu nome aqui" [(ngModel)]="user_register.name"
                                    [ngStyle]="{'border-color': warningMap.get('login_name')? 'red' : ''}">
                                <span> Insira seu nome completo.</span>
                            </div>

                            <div class="card_input">
                                <div class="title"> E-mail: </div>
                                <input type="text" placeholder="Insira seu e-mail" (keyup.enter)="next()"
                                    [(ngModel)]="user_register.email"
                                    [ngStyle]="{'border-color': warningMap.get('login_email')? 'red' : ''}">
                                <span> Insira seu e-mail válido.</span>
                            </div>

                        </div>

                        <div style="width: 100%;" *ngIf="fase == 2">
                            <div class="card_input">
                                <div class="title"> CPF: </div>
                                <input type="text" mask="000.000.000-00" placeholder="000.000.000-00"
                                    (keyup.enter)="next()" [(ngModel)]="user_register.cpf"
                                    [ngStyle]="{'border-color': warningMap.get('login_cpf')? 'red' : ''}">
                                <span> Este campo é obrigatório. </span>
                            </div>

                            <!-- <div class="card_input">
                    <div class="title"> Senha: </div>
                    <input type="password">
                    <span> Crie uma senha. Guarde sua senha para acessar sua conta. </span>
                </div>
    
                <div class="card_input">
                    <div class="title"> Confirme sua senha: </div>
                    <input type="password" placeholder="000.000.000-00">
                    <span> A senhas devems er iguais. </span>
                </div> -->
                        </div>

                        <div style="width: 100%;" *ngIf="fase == 3">
                            <div class="card_input">
                                <div class="title"
                                    [ngStyle]="{'text-decoration': warningMap.get('entrepreneur')? 'underline': '', 'color': warningMap.get('entrepreneur')? 'red' : ''}">
                                    Você é um: </div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="user_register.entrepreneur"
                                    style="display: flex; flex-direction: column; margin: 5px 0 5px 8px;">
                                    <mat-radio-button value="1">Empreendedor</mat-radio-button>
                                    <mat-radio-button value="2">Não Empreendedor</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div class="card_input">
                                <div class="title"
                                    [ngStyle]="{'text-decoration': warningMap.get('gender')? 'underline': '', 'color': warningMap.get('gender')? 'red' : ''}">
                                    Gênero: </div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="user_register.gender"
                                    style="display: flex; flex-direction: column; margin: 5px 0 5px 8px;">
                                    <mat-radio-button value="Heterossexual">Masculino</mat-radio-button>
                                    <mat-radio-button value="Heterossexual">Feminino</mat-radio-button>
                                    <mat-radio-button value="Não Apurado">Outros</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div class="card_input">
                                <div class="title"
                                    [ngStyle]="{'text-decoration': warningMap.get('authorization')? 'underline' : '', 'color': warningMap.get('authorization')? 'red' : ''}">
                                    Autoriza a utilização dos meus dados para análise do meu perfil de
                                    crédito e ofertas de produtos financeiros do Assai e de seus
                                    fornecedores: </div>
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="user_register.authorization"
                                    style="display: flex; flex-direction: column; margin: 5px 0 5px 8px;">
                                    <mat-radio-button value="true">Sim</mat-radio-button>
                                    <mat-radio-button value="false">Não</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <mat-checkbox class="example-margin" [(ngModel)]="user_register.checked_1"
                                [ngStyle]="{'text-decoration': warningMap.get('checked_1')? 'underline': '', 'color': warningMap.get('checked_1')? 'red' : ''}">
                                Li e estou ciente do <b [style.color]="header.back">Aviso de Privacidade</b>
                            </mat-checkbox>

                            <mat-checkbox class="example-margin" [(ngModel)]="user_register.checked_2"
                                [ngStyle]="{'text-decoration': warningMap.get('checked_2')? 'underline': '', 'color': warningMap.get('checked_2')? 'red' : ''}">
                                Li e estou ciente do <b [style.color]="header.back">Política de Privacidade</b>
                            </mat-checkbox>

                            <div class="card_input">
                                <span> Marque as caixas acima para aceitar os termos de uso. </span>
                                <span> Marque a caixa acima e faça a verificação das imagens. </span>
                            </div>

                        </div>

                        <div style="width: 100%; margin: 10px 0;" *ngIf="fase == 4">
                            <div style="width: 100%;" class="screen-login" *ngIf="screen.code">
                                <div class="card_input">
                                    <div class="title"> Codigo: </div>
                                    <input type="text" [(ngModel)]="login_code"
                                        [ngStyle]="{'border-color': warningMap.get('login_code')? 'red' : ''}">
                                    <span> <b [style.color]="header.back"> Reenviar código. </b> </span>
                                </div>

                                <button mat-button class="btn btn_enter" [style.borderColor]="header.back"
                                    [style.background]="header.back" (click)="checkCode()">
                                    ENTRAR
                                    <span *ngIf="loading.sendCode" class="spinner-border spinner-border-sm m_left"
                                        role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>

                        <button mat-button class="btn btn_enter" style="margin-top: 30px;"
                            [style.borderColor]="header.back" *ngIf="fase != 4" [style.background]="header.back"
                            (click)="next()"> {{ fase == 1 ? 'CONTINUAR' : (fase == 2 ? 'AVANÇAR' : (fase == 3 ?
                            'FINALIZAR CADASTRO' : 'ACESSAR MINHA CONTA')) }} </button>
                </div>
            </div>
        </div>