import { Component, Input, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';

@Component({
  selector: 'app-dashboard-preview-log',
  templateUrl: './dashboard-preview-log.component.html',
  styleUrls: ['./dashboard-preview-log.component.css']
})
export class DashboardPreviewLogComponent implements OnInit {

  @Input() isCampaign: any;

  constructor(
    private storageService: StorageService,
    private userCampaignService: UserCampaignService,
    private campaignService: CampaignService
  ) { }

  mobile: boolean = false;
  loading: boolean = false;
  list_logs_all = []
  list_logs = []
  list_acess = [];
  list_screen = []

  description;
  user_access;

  information = {acesso: 0, answer: 0, cupom: 0, register: 0, link: 0, video: 0}
  type_screen;

  date = {
    start_date: null,
    end_date: null,
    send_start_date: null,
    send_end_date: null
  }

	color_primary
  page_size: number = 100;
  ngOnInit(): void {
    this.date.start_date = this.startDayWeek(new Date())
    this.date.end_date = this.endDayWeek(new Date())
    this.date.send_start_date = this.convertDate(this.startDayWeek(new Date()))
    this.date.send_end_date = this.convertDate(this.endDayWeek(new Date()))
    
    this.color_primary = this.storageService.establishment.color;

    setTimeout(() => {
      this.loading = true;
      // this.getLogCampaign(this.isCampaign?.id, 1)
      this.getLogs(1)
      this.campaignService.changeList()
      this.mobile = true;
    }, 500);
  }

  getLogs(page){
    this.userCampaignService.getLogs(page, this.page_size).subscribe(data=>{
        // console.log(data)
        
        if(data.next != null){
          this.page_size = data.count;
          this.getLogs(page)
        }
        else{
            this.list_logs_all = this.list_logs_all.concat(data.results);
            this.loading = false;
            this.filterLogCampaign(this.isCampaign?.id)
            this.setScreen(this.isCampaign.home_images)
            this.getScreen()
        }
    })
  }

  getLogCampaign(id, page){
    this.loading = true;

    this.campaignService.getLogCampaign(id, page, this.date.send_start_date+'T00:00:00', this.date.send_end_date+'T23:59:59', 100).subscribe(data=>{
      this.list_logs_all = this.list_logs_all.concat(data.results);

            if(data.next != null){
                this.getLogCampaign(this.isCampaign?.id, page+1)
            }
            else{
                this.filterLogCampaign(this.isCampaign?.id)
                this.setScreen(this.isCampaign.home_images)
            }

    },
    error=>{
        this.loading = false;
    })

  }

  getScreen(){
    if(this.storageService.screen.id != null){
      if(this.list_logs != null && this.list_logs.length > 0)
        this.tratamentoInfo(this.storageService.screen.id)
        setTimeout(() => {
          this.type_screen = this.storageService.screen.format;
        }, 0);
      // return true;
    }
    else{
      // return false;
    }
  }

  tratamentoInfo(id){
    setTimeout(()=> {
      this.information.acesso = 0;
      this.information.answer = 0;
      this.information.cupom = 0;
      this.information.register = 0;
      this.information.link = 0;
      this.information.video = 0
  
      this.list_logs.forEach(log => {
        if(id == log.description.id_item && log.description.action == '1- Visualizou a campanha')
          this.information.acesso +=1
        if(id == log.description.id_item && log.description.action == '2- Abriu o content')
          this.information.acesso +=1
        if(id == log.description.id_item && log.description.action == '3- Resgatou o cupom')
          this.information.cupom +=1
        if(id == log.description.id_item && log.description.action == '4- Cadastrou na campanha')
          this.information.register +=1
        if(id == log.description.id_item && log.description.action == '5- Click no link')
          this.information.link +=1
        // if(id == log.description.id_item && log.description.action == '6- Abriu a pergunta')
          // this.information.acesso +=1
        if(id == log.description.id_item && log.description.action == '7- Respondeu a pergunta')
          this.information.answer +=1
        if(id == log.description.id_item && log.description.action == '8- Assistiu ao vídeo')
          this.information.video +=1
      });
    }, 10);


    // console.log(this.list_logs)
  }

  setScreen(list){
    this.list_screen = []

    if(list.home_images != null && list.home_images.length > 0){
        list.home_images.forEach(home => {
            this.list_screen = this.list_screen .concat(home)

            if(home.page_images != null && home.page_images.length > 0){
                home.page_images.forEach(page => {
                    this.list_screen = this.list_screen .concat(page)

                    if(page.internal_images != null && page.internal_images.length > 0){
                        page.internal_images.forEach(internal => {
                            this.list_screen = this.list_screen .concat(internal)
                        });
                    }
                });
            }
        });
    }

    // console.log('telas ->',this.list_screen)
  }

  filterLogCampaign(id_campaign){
    this.list_logs = this.list_logs_all.filter(item => item.campaign == id_campaign)

    // console.log(id_campaign)

    this.list_logs.forEach(item => {
      let description = JSON.parse(item.description) 
      item.description = description
    });
  }

  tratamento(log){
    this.description = JSON.parse(log.description) 
    this.user_access = this.description.user;
    
    // console.log('1->',log)               // list filter log
    // console.log('2->',this.description)

    let position = this.list_acess.findIndex( obj => obj.id_sreen === log.campaign);
    if(position > -1 )
      this.list_acess[position].acess += 1;

    // console.log('acesso ->',this.list_acess)
  }

  convertDate(data){
    if(data != null){
      var d = new Date(data),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      var aux_data = [year, month, day].join('-')

      return aux_data;
    }
    else{
      return null
    }
    
  }

  endDayWeek(date){
    let day, month, year;
    day = new Date(date).getDate()
    month = (new Date(date).getMonth() + 1)
    year = new Date(date).getFullYear()
    
    return new Date(year+'-'+month+'-'+day)
  }

  startDayWeek(date){
    let day, month, year;
    var d = new Date(date);
    d.setDate(d.getDate() - 7);
    day = new Date(d).getDate() 
    month = (new Date(d).getMonth() + 1)
    year = new Date(d).getFullYear()

    return new Date(year+'-'+month+'-'+day)
  }
}
