<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>

    </div>
    
    <div class="size_popup custom-scrollbar">
        
        <div style="width: 100%; display: flex; justify-content: center;">
            <div class="img-qr" *ngIf="loading.img_qrcode">
                <qrcode id="qrcode" [qrdata]="qr_code_link" [width]="250" 
                    [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
            </div>
        </div>
    
        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Nome </span>
                
                <div *ngIf="!loading.create" style="width: 100%;">
                    <input type="text" [(ngModel)]="name" 
                    [ngStyle]="{'border-color': warningMap.get('name')? 'red' : 'white'}"
                    [disabled]="preview" placeholder="Ex: QRCode ...">
                </div>
            </div>
        </div>
        
        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Nome da loja </span>
                
                <div *ngIf="!loading.create" style="width: 100%;">
                    <input class="border" type="text" [(ngModel)]="store" 
                    [disabled]="preview" placeholder="Ex: Loja ...">
                </div>
            </div>
        </div>        
    
        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Selecione o estabelecimento </span>
                
                <div *ngIf="!loading.create" style="width: 100%;">
                    <mat-select [(ngModel)]="establishment" [disabled]="preview"
                        [ngStyle]="{'border-color': warningMap.get('establishment')? 'red' : 'white'}"
                        class="input select-box">
            
                        <mat-option *ngFor="let obj of list_establishment; let i = index" 
                            (click)="selectEstablishment(obj)"  [value]="obj.name">
                            {{ obj.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Selecione o próximo QRCode </span>
                
                <div *ngIf="!loading.create" style="margin: 0 auto 0;" style="width: 100%;">
                    <mat-select [(ngModel)]="send_next_qrcode" [disabled]="preview"
                        class="input select-box" >
            
                        <mat-option *ngFor="let obj of list_qrcodes; let i = index"
                            (click)="selectQrcode(obj)"  [value]="obj.id">
                            {{ obj.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>   
            
        <div *ngIf="send_next_qrcode > 0" style="width: 70%; margin: 5px auto; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
            <mat-checkbox [(ngModel)]="first_of_flow">
                <h3 style="font-size: 14px; margin: 0;"> Primeiro QRCode do fluxo? </h3>
            </mat-checkbox>
        </div>
    
        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Selecione um cadastro </span>
                
                <div *ngIf="!loading.create" style="width: 100%;">
                    <mat-select [(ngModel)]="registration" [disabled]="preview"
                        [ngStyle]="{'border-color': warningMap.get('establishment')? 'red' : 'white'}"
                        class="input select-box">
            
                        <mat-option *ngFor="let obj of list_registration; let i = index" 
                            (click)="selectRegistration(obj)"  [value]="obj.id">
                            {{ obj.title }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>  

        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Endereço </span>
                
                <div style="width: 100%;" *ngIf="!loading.create">
                    <input class="border" type="text" [(ngModel)]="address" 
                    [disabled]="preview" placeholder="EX: Rua ...">
                </div>
            </div>
        </div>    

        <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%; position: relative;">
                <span class="title_video"> Informação adicional </span>
                
                <div style="width: 100%;" *ngIf="!loading.create">
                    <textarea class="border border_texture" type="text"
                        [(ngModel)]="information" [disabled]="preview" placeholder="EX: Descrição..."></textarea>
                </div>
            </div>
        </div>       
    </div>

    <div *ngIf="message_erro != null && message_erro != ''" style="width: 70%; margin: 5px auto; color: red; text-align: end; font-size: 12px;">
        {{ message_erro }}
    </div>

    <div style="width: 70%; margin: 0 auto; display: flex; justify-content: space-between; align-items: center;">
        <button mat-button *ngIf="!loading.create" [style.background]="color_primary" class="b-border" (click)="modalRef.hide()">
            {{ buttonClose }}
        </button>
        
        <button mat-button *ngIf="!loading.create && loading.new" [style.background]="color_primary" class="b-border" (click)="createQrCode()">
            Criar
        </button>

        <button mat-button *ngIf="loading.create && loading.new" [style.background]="color_primary" class="b-border m_20" (click)="patchNewQrCode()">
            Fechar
        </button>

        <button mat-button *ngIf="loading.edit && !loading.new" [style.background]="color_primary" class="b-border" (click)="patchQrCode()">
            {{ textButton }}
        </button>
    </div>    
</div>