import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { CampaignService } from 'src/app/service/campaign.service';
import { CouponService } from 'src/app/service/coupon.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-check-point',
  templateUrl: './check-point.component.html',
  styleUrls: ['./check-point.component.css']
})

export class CheckPointComponent implements OnInit {

  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
  @Input() type_campaign: any;
  @Input() campaign_id: any;
  @Input() qrcode_id: any;
  @Output() newItemEvent = new EventEmitter();

  constructor(
    private campaignService: CampaignService,
    private couponService: CouponService,
    private storageService: StorageService,
    private router: Router,
    private modalService: MDBModalService,
  ) {
    var url = this.router.url;

    if (url.indexOf("redirect") > 0) {
      this.web = true;
    }
    else if (url.indexOf("content") > 0) {
      this.web = false;
    }

    var url = this.router.url;
    var aux = url.split('/')
    var tam = aux.length
    this.urlPage = aux[tam - 2]
  }

  modalRef: MDBModalRef;

  urlPage;
  web: boolean = false;

  url_screen;
  loading: boolean = true;
  check_login: boolean = false;

  listImage = [];
  default_color: '';
  type_page: string = '';

  ngOnInit(): void {
    // console.log("-> Ponto <-")
    this.default_color = this.list.icons_color;

    this.listImage = this.tratamento(this.list)
    // console.log(this.listImage)

    // if (localStorage.getItem('tokenUser') == null && (localStorage.getItem('_user') == null || localStorage.getItem('_user') == '')) {
    if (localStorage.getItem('tokenUser') == null) {
      this.check_login = true;
      this.loading = false;
    }
    // else if(localStorage.getItem('_user') != null){
    //   var user = JSON.parse(localStorage.getItem("_user"));
    //   console.log('->', user)
    //   if(user.token == null || user.token == ''){
    //     this.check_login = true;
    //     this.loading = false;
    //   }
    //   else{
    //     localStorage.setItem('tokenUser', user.token)
    //     this.check_login = false;
    //     this.loading = false;
    //   }
    // }
    else {
      if (this.listImage != null && this.listImage.length > 0) {
        this.listImage.forEach((el, index) => {
          if (el.home_page_image_type == "EMPTY" || el.page_type == "EMPTY" || el.internal_page_type == "EMPTY") {
            this.listImage.splice(index, 1);
          }
        });
      }

      this.loading = false;
    }
  }

  refreshPage() {
    this.check_login = false;
  }

  checkButton(obj) {
    if (obj.home_page_image_type == 'ENTRANCE' || obj.page_type == 'ENTRANCE' || obj.internal_page_type == 'ENTRANCE') {
      this.checIn()
    }
    else {
      this.checOut()
    }
  }

  checIn() {
    if (this.url_screen != '/application/dashboard') {
      // console.log(this.listImage)
      this.storageService.saveHistoric(this.campaign_id, this.listImage[0].id, this.list.homepage_format, 9, this.type_page, this.qrcode_id)
      this.openMessage('Check In realizado com sucesso!');
    }
  }

  checOut() {
    if (this.url_screen != '/application/dashboard') {
      // console.log(this.listImage)
      this.storageService.saveHistoric(this.campaign_id, this.listImage[1].id, this.list.homepage_format, 10, this.type_page, this.qrcode_id)
      this.openMessage('Check Out realizado com sucesso!');
    }
  }

  openMessage(text) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        title: text,
        text: '',
        has_button: true,
        button_text: 'Fechar',
        button_cancel: ''
      }
    })
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.newItemEvent.emit('doubleBack');
      }
    });
  }

  tratamento(list) {
    if (this.preview_campaign) {
      if (this.list?.images != undefined) {
        return list.images;
      }
      else
        return null
    }
    else {
      if (list?.home_images != undefined) {
        this.type_page = 'home';

        return list.home_images;
      }
      else if (list?.internal_images != undefined) {
        // console.log('3',list.internal_images)
        this.type_page = 'internal';
        return list.internal_images;
      }
      else if (list?.page_images != undefined) {
        // console.log('2',list.page_images)
        this.type_page = 'page';
        return list.page_images;
      }
      else if (this.list?.home_images != undefined) {
        // console.log('1',list.home_images)
        this.type_page = 'home';
        return list.home_images;
      }
      else
        return null
    }

  }
}
