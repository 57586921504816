<div class="screen">
    <div class="card text_welcome">
        <div style="display: flex; flex-direction: column;">
            <h2> Olá {{ name_user }}, {{ text_welcome }}! </h2>
            <h3> Bem vindo a solução SBS </h3>
        </div>
        
        <img [src]="image_logo" class="logo">
    </div>

    <div class="card card_browser">
        <h3> Recomenda-se usar os navegadores Chrome ou o Opera. </h3>
    </div>

    <div class="cards_row">
        <div class="items">
           <div class="card">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <svg class="icon_item" viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                            <path d="M2320 5030 c-19 -5 -73 -14 -120 -20 -214 -31 -560 -140 -615 -195 -48 -49 -54 -113 -16 -184 14 -25 80 -61 113 -61 13 0 81 20 153 44 359 123 636 158 955 121 69 -8 134 -16 145 -19 11 -2 47 -10 80 -16 256 -52 528 -167 764 -325 442 -294 765 -752 902 -1277 25 -97 35 -153 54 -308 43 -366 -18 -754 -177 -1110 -35 -79 -152 -288 -182 -324 -21 -26 -22 -26 -81 -15 -204 38 -400 -62 -488 -249 -29 -62 -31 -75 -32 -177 0 -104 2 -114 33 -177 68 -140 184 -228 330 -250 140 -22 273 21 373 121 124 125 161 309 95 473 l-23 57 25 37 c104 153 222 383 287 557 43 117 98 338 115 457 6 47 16 104 21 127 12 54 12 427 0 490 -5 26 -14 86 -21 133 -7 47 -16 101 -20 120 -5 19 -14 58 -21 85 -147 624 -563 1192 -1121 1532 -279 170 -611 292 -908 333 -47 7 -107 16 -133 21 -58 11 -438 10 -487 -1z m1946 -3986 c52 -25 78 -68 78 -129 0 -87 -52 -139 -139 -139 -152 -1 -199 198 -64 266 43 22 81 23 125 2z"/>
                            <path d="M800 4624 c-248 -66 -383 -344 -286 -585 l23 -57 -64 -99 c-79 -120 -209 -383 -256 -519 -42 -119 -91 -317 -107 -429 -6 -44 -15 -102 -21 -128 -12 -62 -12 -431 0 -494 5 -26 14 -86 21 -133 27 -191 103 -450 186 -628 19 -41 34 -75 34 -77 0 -10 114 -206 163 -280 339 -512 896 -908 1462 -1039 39 -9 86 -21 105 -25 19 -5 73 -14 120 -21 47 -7 107 -16 133 -21 64 -12 417 -12 486 1 31 5 110 19 176 31 234 42 507 132 560 184 68 69 50 182 -37 226 -52 27 -82 23 -228 -29 -247 -87 -490 -132 -715 -132 -87 0 -310 21 -370 34 -11 2 -47 10 -80 16 -568 115 -1101 492 -1408 996 -136 223 -230 456 -277 689 -6 33 -14 69 -16 80 -3 11 -11 76 -19 145 -55 476 64 980 331 1393 l44 67 42 -7 c24 -5 84 -8 134 -7 82 1 97 4 165 37 93 45 168 120 212 215 30 63 32 74 31 177 0 101 -3 115 -30 175 -93 198 -306 300 -514 244z m200 -304 c15 -11 35 -35 44 -54 69 -146 -114 -275 -228 -161 -58 57 -58 143 0 200 18 19 43 36 56 38 39 8 99 -3 128 -23z"/>
                            <path d="M2390 4150 c-359 -40 -678 -191 -942 -449 -243 -236 -390 -506 -459 -846 -33 -158 -33 -432 0 -590 49 -243 137 -444 279 -645 80 -112 269 -296 387 -376 178 -122 393 -212 610 -255 157 -32 433 -32 587 -1 219 45 436 135 610 253 131 89 335 295 421 424 121 183 204 384 249 603 31 154 31 430 -1 587 -43 216 -133 430 -254 610 -81 119 -293 331 -412 412 -189 127 -383 208 -604 252 -119 24 -353 34 -471 21z m242 -312 c54 -38 111 -116 163 -223 102 -210 158 -436 187 -757 6 -76 9 -140 6 -143 -7 -7 -849 -7 -856 0 -8 7 15 256 33 365 53 330 155 589 281 717 76 77 121 87 186 41z m-572 -91 c0 -7 -4 -17 -9 -22 -17 -20 -73 -173 -110 -300 -49 -172 -100 -499 -101 -647 0 -31 -4 -59 -9 -63 -11 -6 -561 -9 -567 -2 -2 2 3 39 11 83 73 390 322 729 676 918 89 48 109 54 109 33z m1111 -34 c341 -179 593 -517 669 -895 10 -50 16 -96 14 -102 -4 -11 -533 -12 -561 -2 -9 4 -13 26 -13 63 0 143 -53 478 -102 648 -36 127 -92 280 -109 300 -13 13 -11 35 2 35 7 0 51 -21 100 -47z m-1334 -1348 c20 -355 86 -670 190 -913 19 -46 32 -86 28 -90 -3 -4 -48 15 -99 41 -345 180 -599 518 -676 900 -10 50 -16 95 -14 99 3 5 132 7 287 6 l282 -3 2 -40z m1153 5 c0 -117 -40 -389 -79 -543 -42 -160 -55 -198 -118 -327 -44 -91 -68 -127 -117 -175 -94 -94 -138 -94 -232 0 -49 48 -73 84 -117 175 -63 129 -76 167 -118 327 -39 154 -79 426 -79 543 l0 40 430 0 430 0 0 -40z m864 32 c8 -13 -23 -159 -56 -257 -104 -312 -341 -589 -634 -742 -51 -26 -96 -45 -99 -41 -4 4 9 44 28 90 68 158 114 327 152 551 14 85 35 286 35 338 0 31 3 59 7 62 9 10 561 9 567 -1z"/>
                        </g>
                    </svg>
                    <h2> SBS MÍDIA </h2>
                </div>
                <button mat-button class="btn click" [style.background-color]="color_primary" routerLink="/application/dashboard"> Dashboard </button>
           </div>

           <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/content"> CONTEÚDO </div>
           <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/channels"> CANAIS </div>
           <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/group"> GRUPOS </div>
           <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/registrations"> CADASTROS </div>
        </div>

        <div class="items">
            <div class="card">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <svg class="icon_item" viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                            <path d="M2895 5108 c-2 -7 -6 -58 -8 -112 -5 -121 -10 -127 -148 -175 -52 -18 -130 -51 -174 -72 -43 -21 -91 -39 -105 -39 -19 0 -52 22 -109 72 l-82 71 -110 -109 -109 -109 48 -50 c76 -79 102 -115 102 -141 0 -13 -16 -57 -36 -97 -21 -39 -56 -124 -80 -186 -24 -63 -49 -120 -54 -125 -6 -6 -63 -13 -126 -14 l-115 -4 3 -106 3 -107 283 0 284 0 4 70 c18 302 194 536 479 638 83 29 322 31 405 3 176 -60 336 -196 414 -353 40 -80 54 -135 70 -263 l11 -95 280 0 280 0 3 107 3 106 -110 4 c-95 3 -113 6 -129 23 -10 11 -41 76 -67 145 -27 69 -61 152 -77 185 -15 33 -28 68 -28 78 0 9 34 56 76 102 l75 85 -106 105 c-59 58 -109 105 -112 105 -3 0 -35 -26 -72 -58 -95 -83 -108 -91 -139 -82 -15 4 -58 23 -97 42 -38 20 -112 51 -164 69 -52 19 -107 46 -123 61 -27 25 -28 28 -28 132 l0 106 -155 0 c-116 0 -157 -3 -160 -12z"/>
                            <path d="M2915 4361 c-59 -15 -175 -84 -228 -135 -92 -88 -144 -201 -156 -338 l-7 -78 526 0 526 0 -8 83 c-8 97 -15 121 -58 207 -46 90 -135 176 -236 225 -78 38 -81 39 -204 42 -69 1 -139 -1 -155 -6z"/>
                            <path d="M410 4240 l0 -90 299 0 299 0 27 -112 c14 -62 48 -203 75 -313 26 -110 68 -288 94 -395 25 -107 70 -296 100 -420 60 -250 144 -610 174 -740 11 -47 29 -121 40 -165 11 -44 41 -168 66 -275 71 -303 105 -445 136 -575 15 -66 33 -144 40 -172 l12 -53 1222 0 c673 0 1225 2 1227 4 2 2 3 40 1 84 l-3 79 -1137 5 c-625 3 -1144 8 -1153 12 -11 5 -29 62 -59 184 -24 97 -56 229 -71 292 -19 77 -25 118 -18 125 7 7 415 11 1180 13 l1170 4 14 31 c27 64 96 299 90 305 -4 4 -243 8 -531 10 l-525 3 3 82 3 82 555 3 556 2 27 93 c16 50 37 121 48 156 21 69 23 90 12 92 -5 1 -354 5 -778 8 l-770 6 2 80 2 80 807 5 808 5 27 90 c15 50 38 123 50 162 13 40 19 76 15 80 -4 5 -469 9 -1032 11 l-1025 3 3 82 3 82 1060 5 1060 5 28 95 c46 154 66 234 61 240 -3 3 -767 7 -1698 9 l-1692 3 -23 92 c-12 50 -36 147 -52 216 -38 164 -76 307 -92 339 -6 14 -23 28 -37 32 -14 4 -177 7 -362 8 l-338 1 0 -90z"/>
                            <path d="M1825 781 c-101 -25 -218 -120 -265 -216 -22 -45 -25 -63 -25 -170 0 -107 3 -125 24 -168 35 -71 111 -149 183 -188 61 -33 66 -34 178 -34 107 0 119 2 169 29 69 36 151 117 187 187 27 51 29 61 29 174 0 115 -1 122 -30 174 -39 71 -110 143 -171 174 -83 42 -198 57 -279 38z m197 -150 c190 -83 192 -374 3 -462 -47 -22 -138 -25 -199 -7 -59 18 -129 91 -144 150 -28 111 -6 206 62 269 73 67 190 88 278 50z"/>
                            <path d="M1885 478 c-33 -18 -45 -40 -45 -83 0 -67 59 -102 118 -71 34 17 42 33 42 81 0 36 -19 61 -57 76 -30 11 -33 11 -58 -3z"/>
                            <path d="M3727 780 c-121 -31 -253 -158 -286 -277 -14 -49 -14 -167 0 -218 26 -94 119 -201 219 -252 50 -26 64 -28 165 -28 101 0 115 2 168 28 76 38 152 116 191 197 28 59 31 74 31 165 0 92 -3 105 -33 166 -41 86 -128 169 -209 199 -65 25 -187 34 -246 20z m230 -166 c26 -16 62 -51 81 -77 33 -46 35 -53 36 -135 1 -83 -1 -89 -32 -134 -58 -84 -122 -118 -221 -118 -77 0 -147 34 -197 95 -39 48 -39 49 -42 144 -3 94 -3 97 28 143 57 86 127 120 233 114 53 -3 77 -10 114 -32z"/>
                            <path d="M3779 467 c-28 -19 -35 -30 -37 -64 -3 -35 1 -44 28 -67 41 -34 74 -33 111 3 46 46 37 106 -21 136 -38 19 -41 19 -81 -8z"/>
                        </g>
                    </svg>
                    <h2> SBS COMMERCE </h2>
                </div>
                <button mat-button class="btn color_blue"> Dashboard </button>
            </div>

            <div class="w-50 click color_blue" routerLink="/application/order"> PEDIDOS </div>
            <div class="w-50 click color_blue" routerLink="/application/catalog"> CARDÁPIO </div>
            <div class="w-50 click color_blue" routerLink="/application/coupon"> CUPONS </div>
            <div class="w-50 color_blue"> NOTA FISCAL </div>
        </div>

        <div class="items">
            <div class="card">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <svg class="icon_item" viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                            <path d="M2824 5110 c-54 -22 -54 -18 -54 -503 0 -271 4 -455 10 -466 16 -31 39 -36 220 -41 l175 -5 0 -50 0 -50 -357 -3 -358 -2 -1 257 c0 292 -5 318 -59 345 -32 17 -88 18 -713 18 -596 0 -682 -2 -708 -16 -54 -28 -59 -56 -59 -344 l0 -260 -123 0 c-159 0 -208 -14 -281 -81 -57 -52 -103 -131 -125 -214 -6 -23 -42 -250 -81 -505 -39 -256 -82 -535 -95 -620 -14 -85 -65 -420 -115 -745 -49 -324 -93 -602 -96 -618 l-6 -27 2562 0 2562 0 -6 27 c-3 16 -37 235 -76 488 -39 253 -91 593 -116 755 -25 162 -76 502 -115 755 -38 253 -76 485 -85 515 -34 117 -92 193 -182 237 -57 28 -59 28 -302 33 l-245 5 0 50 0 50 178 5 179 5 24 28 24 28 0 449 0 449 -24 28 -24 28 -754 2 c-414 1 -763 -2 -774 -7z m-574 -1530 l0 -820 -560 0 -560 0 0 820 0 820 560 0 560 0 0 -820z m1964 -14 c48 -20 100 -70 119 -113 9 -21 30 -141 48 -268 36 -260 34 -293 -24 -353 -68 -71 -24 -67 -726 -70 -447 -2 -651 1 -689 9 -46 9 -64 20 -107 63 -43 42 -55 62 -64 105 -14 67 -14 429 0 485 13 53 58 110 107 136 35 18 64 19 670 20 515 0 639 -3 666 -14z m-1084 -1031 c38 -20 50 -64 50 -185 0 -187 -13 -200 -210 -200 -189 0 -200 11 -200 200 0 187 12 199 203 200 95 0 135 -4 157 -15z m631 -14 l29 -29 0 -142 0 -142 -29 -29 -29 -29 -142 0 -142 0 -29 29 -29 29 0 142 0 142 29 29 29 29 142 0 142 0 29 -29z m608 3 l31 -26 0 -148 0 -148 -31 -26 c-30 -25 -35 -26 -167 -26 -192 1 -202 10 -202 195 0 122 12 174 47 194 10 6 76 11 155 11 132 0 137 -1 167 -26z m-1222 -617 c26 -26 28 -33 31 -137 5 -133 -2 -175 -33 -206 -23 -23 -30 -24 -169 -24 -139 0 -146 1 -173 24 l-28 24 0 145 c0 139 1 146 24 171 31 33 42 35 193 33 125 -2 128 -3 155 -30z m582 22 c51 -18 61 -51 61 -194 0 -193 -2 -194 -203 -195 -144 0 -149 1 -172 25 -24 23 -25 29 -25 165 0 82 5 150 11 161 19 36 66 48 185 49 61 0 125 -5 143 -11z m616 -3 c18 -8 38 -23 44 -34 6 -12 11 -80 11 -162 0 -136 -1 -142 -25 -165 -23 -24 -28 -25 -169 -25 -139 0 -146 1 -173 24 l-28 24 0 145 c0 139 1 146 24 171 29 31 40 33 174 35 73 1 119 -4 142 -13z"/>
                            <path d="M1332 3993 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
                            <path d="M1332 3583 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
                            <path d="M1332 3173 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
                            <path d="M2 672 l3 -407 33 -67 c38 -77 92 -130 171 -167 l56 -26 2295 0 2295 0 56 26 c79 37 133 90 171 167 l33 67 3 407 3 408 -2561 0 -2561 0 3 -408z m2619 137 c89 -30 139 -101 139 -196 0 -72 -28 -125 -88 -168 -38 -26 -52 -30 -112 -30 -60 0 -74 4 -112 30 -60 43 -88 96 -88 168 0 69 25 125 72 160 60 45 125 57 189 36z"/>
                        </g>
                    </svg>
                    <h2> SBS VENDAS </h2>
                </div>
                <button mat-button class="btn" [style.background-color]="color_primary"> Dashboard </button>
            </div>

            <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/sales"> VENDA </div>
            <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/products"> PRODUTOS </div>
            <div class="w-50 click" [style.background-color]="color_primary" routerLink="/application/categories"> CATEGORIA </div>
            <div class="w-50" [style.background-color]="color_primary"> ESTOQUE </div>
        </div>
    </div>
</div>