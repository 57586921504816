import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CompressorService {

    constructor() { }

    max_width = 800;
    max_height = 800;

    compress(file: File): Observable<any> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
          return Observable.create(observer => {
               reader.onload = ev => {
                  const img = new Image();
                  img.src = (ev.target as any).result;
  
                  (img.onload = () => {
                      const elem = document.createElement('canvas');
                      var width = img.width;
                      var height = img.height;
  
                      if (width > height) {
                          if (width > this.max_width) {
                            //height *= max_width / width;
                            height = Math.round(height *= this.max_width / width);
                            width = this.max_width;
                          }
                        } else {
                          if (height > this.max_height) {
                            //width *= max_height / height;
                            width = Math.round(width *= this.max_height / height);
                            height = this.max_height;
                          }
                        }
  
                      elem.width = width;
                      elem.height = height;
                      const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
                      ctx.drawImage(img, 0, 0, width, height);
  
                      const base64img = ctx.canvas.toDataURL("image/png")
                      observer.next( base64img )
  
                  }),
                  (reader.onerror = error => observer.error(error));
              };
          });

        // }
    }
    
}