import { Component, OnInit } from '@angular/core';
import { StorageService } from '../service/storage.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../service/compressor.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private compressor: CompressorService,

  ) { }

  displayedColumns: string[] = ['name', 'qtd', 'valorUnid', 'valorTotal', 'icons'];
  dataSource = ELEMENT_DATA;

  color_primary: string = null;

  image: string = null;
  compressed_image = []; // as the name says...

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    return formatter.format(money);
  }

  async onFileChanged(event){
		if (event.target.files && event.target.files[0]) {
			var target = event.target.files
			const compress = this.recursiveCompress( target[0], 0, target ).pipe(
				expand(res => {
					  return res.index > res.array.length - 1
					? EMPTY
					: this.recursiveCompress( target[res.index], res.index, target );
				}),
			);
			compress.subscribe(res => {
				if (res.index > res.array.length - 1) {
          this.image = this.compressed_image[0]
					this.compressed_image = []

				}
			});
		}
	}

    recursiveCompress = (image: File, index, array) => {
		return this.compressor.compress(image).pipe (
		  	map(response => {
				this.compressed_image.push(response)
				return {
					data: response,
					index: index + 1,
					array: array,
				};
		  	}),
		);
	}
}

export interface PeriodicElement {
  name: string;
  qtd: number;
  valorUnid: number;
  valorTotal: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Hydrogen', qtd: 1, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Helium', qtd: 4, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Lithium', qtd: 6, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Beryllium', qtd: 9, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Boron', qtd: 10, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Carbon', qtd: 12, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Nitrogen', qtd: 14, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Oxygen', qtd: 15, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Fluorine', qtd: 18, valorUnid: 12.7, valorTotal: 12.7},
  {name: 'Neon', qtd: 20, valorUnid: 12.7, valorTotal: 12.7},
];
