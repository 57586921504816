<div class="catalog-v-container" *ngIf="!loading">
    <div class="catalog-v-header">
        <div class="header-logo">
            <img *ngIf="catalog.logo_menu == null" alt="logo" src="../../../assets/image/logo_principal_SBS.png" />
            <img *ngIf="catalog.logo_menu != null" alt="logo" [src]="catalog.logo_menu" />
        </div>
        <div>
            <div class="header-title"> {{ catalog.name != null ? catalog.name : ''}} </div>
            <div class="header-subtitle"> {{ catalog.description != null ? catalog.description : ''}} </div>
        </div>
        <div class="right-header">
            <span>.</span>
        </div>
    </div>

    <div class="catalog-v-caroussel">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img src="{{ slide.icon }}" class="image_slide" width="100%">
            </div>
        </ngx-slick-carousel>
    </div>

    <!-- <div class="catalog-v-categories">
        <ul>
            <li class="category-card" 
                *ngFor="let section of catalog.sections; let i = index" 
                [class.active]="selectedIndex === i"
                (click)="setIndex(i)" 
                style="background-image: url({{section.img}});"
            >
                {{section.name}}
            </li>
        </ul>
    </div> -->

    <!-- <div class="category-description">
        {{catalog.description}}
    </div> -->

    <div class="allergin">
        {{catalog.informative_text != null && catalog.informative_text != '' ? catalog.informative_text : 'Consulte a lista de alergênicos' }}
        <a style="cursor: pointer;" (click)="openLink(catalog.link)">aqui</a>
    </div>

    <div class='subcategories custom-scrollbar'>
        <ul>
            <li *ngFor="let section of catalog.sections; let i = index"
                [ngClass]="{'sub-active': selectedSubIndex == i}" (click)="setSubIndex(i)">
                <div class="sub-card">
                    {{ section.name }}
                </div>
            </li>
        </ul>
        <div *ngFor="let section of catalog.sections; let i = index">
            <div class="sub-header" attr.id="target{{i}}">
                {{ section.name }}
                <div class="sub-description">
                    {{ section.description }}
                </div>
            </div>
            <div class="sub-items-container">
                <div class="item-card" *ngFor="let item of section.products">
                    <div class="item-info">

                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-description">
                            {{ item.description }}
                        </div>
                        <div class="item-price">
                            R$ {{ item.price.toFixed(2)}}
                        </div>
                        <div class="item-more-info" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                            <a [routerLink]="'/catalog-view/'+catalog_id+'/more-details/'+item.id">Saiba mais</a>
                            <a style="cursor: pointer;" *ngIf="catalog.purchase">Adicionar</a>
                        </div>
                    </div>
                    <div *ngIf="item.image != null" class="item-img">
                        <img src="{{ item.image }}" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="footer-text" *ngIf="catalog.footer_text != null && catalog.footer_text != ''">
            {{catalog.footer_text}}
        </div>
        <div class="footer-img">
            <img src='' alt='' />
        </div>
    </div>
</div>