import { Component, OnInit } from '@angular/core';
import { StorageService } from '../service/storage.service';

@Component({
    selector: 'app-channels',
    templateUrl: './channels.component.html',
    styleUrls: ['./channels.component.css']
})

export class ChannelsComponent implements OnInit {

    constructor(
        private storageService: StorageService
    ) { }

    loading: boolean = false;
    selected_tab;

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;
        
    }

}
