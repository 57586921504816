import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extinguisher-checklist',
  templateUrl: './extinguisher-checklist.component.html',
  styleUrls: ['./extinguisher-checklist.component.css']
})
export class ExtinguisherChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
