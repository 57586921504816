import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../service/campaign.service';
import { StorageService } from '../../service/storage.service';

@Component({
    selector: 'app-embed',
    templateUrl: './embed.component.html',
    styleUrls: ['./embed.component.css']
})

export class EmbedComponent implements OnInit {

    constructor(
        private campaignService: CampaignService,
        private storageService: StorageService
    ) { }

    loading: boolean = false;
    warningMap = new Map();

    campaigns = [];
    selected_campaign = null;
    link: string = null;
    destiny: string = null;
    iframe = "<iframe width='560' height='315' src='https://www.sbs.tools/embed/oAaS9ix8pes'"+
    "title='SBSolutions' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'"+
    "allowfullscreen></iframe>"
  
    color_primary
    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.getCampaigns(1)
    }

    getCampaigns(page){
        this.loading = true;

        this.campaignService.getCampaigns(page).subscribe(data=>{

            this.campaigns = this.campaigns.concat(data.results);

            if (data.next != null) 
                this.getCampaigns(page + 1)
            else
                this.loading = false;
            
        },
        error=>{
            // console.log(error)
        })
    }

    createIframe(){
        this.warningMap = new Map();

        if(this.destiny == null || this.destiny == ""){
            this.warningMap.set('destiny', true);
        }
        if(this.selected_campaign == null || this.selected_campaign == ""){
            this.warningMap.set('selected_campaign', true);
        }
        if (this.warningMap.size == 0) {

            this.link = this.selected_campaign.qr_codes[0]?.qr_code_obj[0]?.qr_code_link;

            this.iframe = `<iframe width='560' height='315' src='${this.link}' title='${this.destiny}' frameborder='0'`+
            `allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'`+
            `allowfullscreen></iframe>`
        }
    }
    
}