<div class="screen">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start; margin: 20px auto;">
        <div style="display: flex; flex-direction: row;">
            <div style="width: 30%; min-width: 255px;" *ngIf="!preview">
                <h3> Selecione uma campanha </h3>
    
                <mat-select [(ngModel)]="campaign_select" class="select-box" style="margin-right: 10px;">
                    <mat-option *ngFor="let item of list_campaign; let i = index" [value]="item.id" (click)="selectCampaign(item)">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </div>
    
            <div style="margin-left: 10px;" *ngIf="!preview">
                <h3> Selecione um periodo </h3>
                <mat-form-field color="accent" style="width: 100%; padding: 0 20px;" class="input" >
                    <mat-date-range-input [rangePicker]="picker1">
                      <input matStartDate [(ngModel)]="date.start_date" readonly>
                      <input matEndDate [(ngModel)]="date.end_date" readonly (dateInput)="addEvent($event, date)">
                    </mat-date-range-input>
    
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker1></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>

        <div style="display: flex; flex-direction: column; justify-content: space-evenly; align-items: center; margin-left: auto;">

            <button mat-button style="display: flex; align-items: center; justify-content: space-evenly;" *ngIf="!preview"
                (click)="selectCampaign(campaign_select)">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>

            <button mat-button class="btn_screen" *ngIf="!preview" (click)="preview = true"
            [ngStyle]="{'background-color': color_primary}"> Clicks </button>

            <button mat-button class="btn_screen" *ngIf="preview" (click)="preview = false"
            [ngStyle]="{'background-color': color_primary}"> Dashboard da campanha </button>
        </div>
    </div>

    <app-dashboard-preview-log [isCampaign]="obj_campaign" *ngIf="preview">
    </app-dashboard-preview-log>

    <div *ngIf="!preview">
        <div *ngIf="!loading && list_navegation?.length == 0 && list_devices?.length == 0"
            style="width: 100%; text-align: center; font-size: 18px; padding: 15px 0;">
            Nenhuma informação encontrada para essa campanha.
        </div>
    
        <div *ngIf="!loading && list_navegation?.length != 0 && list_devices?.length != 0"
            style="width: 100%; display: flex; justify-content: space-between; flex-direction: row; align-items: center;">
            <!-- <div class="cards">
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <img src="../../assets/icon/azul/calendar.png" class="icons">
                </div>
                <div class="view"> {{ access_campaign }} </div>
                <div class="title"> Total da semana </div>
            </div>
    
            <div class="cards">
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <img src="../../assets/icon/azul/social-engagement.png" class="icons">
                </div>
                <div class="view"> {{ access_campaign }} </div>
                <div class="title"> Engajamento </div>
            </div> -->
    
            <div class="cards" style="margin-right: 10px;">
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <img src="../../assets/icon/azul/eye (1).png" class="icons">
                    <!-- <div class="porcent"> 12% ∧ </div> -->
                    <!-- <div class="porcent"> 12% ∨ </div> -->
                </div>
                <div class="view"> {{ number_access != null ? number_access : 0 }} </div>
                <div class="title"> Visualizações </div>
            </div>
    
            <div class="cards">
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <img src="../../assets/icon/azul/password.png" class="icons">
                </div>
                <div class="view"> {{ list_register != null ? list_register.length : 0 }} </div>
                <div class="title"> Cadastros </div>
            </div>
        </div>
    
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;"
           *ngIf="!loading && list_navegation?.length != 0 && list_devices?.length != 0 && !loading">
            <!-- <div class="graphics" id="chart-coll"> </div> -->
    
            <!-- <div class="div-border">
                <div class="title"> Navegadores </div>
                <div class="graphics" style="margin: 0;" id="chart-coll"> </div> 
    
            </div> -->
    
            <div class="div-row">
                <div class="div-border graphic_access">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <img src="../../assets/icon/azul/chave-de-acesso.png" class="icons" style="margin: 0 10px 0 0;">
                        <div class="title"> Picos de acesso por dia da semana</div>
                    </div>
                    <div class="graphics" style="margin: 0;" id="chart-line"> </div>
        
                </div>
        
                <div class="div-border new-user">
    
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <img src="../../assets/icon/azul/customer-feedback.png" class="icons" style="margin: 0 10px 0 0;">
                        <div class="title"> Novos clientes <br> cadastrados</div>
                    </div>
                    <div *ngFor="let user of list_register; let i = index" class="name_user">
                        {{ i+1 }}. {{ user.user }}
                    </div>
                    <div *ngIf="list_register == null || list_register.length <= 0" class="name_user" style="text-align: center; margin-top: 10px;">
                        Nenhum cadastro.
                    </div>
                </div>
            </div>
    
            <div class="div-border">
                <div class="title"> Navegadores </div>
                <div class="graphics" id="chart-navegation"> </div>
                <div style="width: 100%; display: flex; flex-direction: row-reverse; justify-content: space-evenly; 
                    margin-bottom: 20px; font-weight: 600;">
    
                    <div *ngFor="let label of list_navegation; let i = index"
                        [ngStyle]="{'color': i > -1 ? list_color[i] : ''}"> 
                        {{ label.name }} 
                    </div>
                </div>
            </div>
    
            <div class="div-border">
                <div class="title"> Dispositivos </div>
                <div class="graphics" id="chart-devices"> </div>
                <div style="width: 100%; display: flex; flex-direction: row-reverse; justify-content: space-evenly; 
                    margin-bottom: 20px; font-weight: 600;">
    
                    <div *ngFor="let label of list_devices; let i = index"
                        [ngStyle]="{'color': i > -1 ? list_color[i] : ''}"> 
                        {{ label.name }} 
                    </div>
                </div>
            </div>
    
        </div>
        
        <div class="div-loading" *ngIf="loading">
            <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
        </div>
    </div>

</div>