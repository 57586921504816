<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title" [style.color]="color_primary">
            <b [style.color]="color_primary"> Detalhes do Pedido </b>
        </h1>
    </div>

    <div class="modal-body">
        <div class="card_order">
            <h4> Usuário </h4>
            <span> {{ order?.user?.name != null ? order?.user?.name : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Data do pedido </h4>
            <span> {{ order?.created_at != null ? (order.created_at | date: 'dd/MM/yyyy') : '-' }} </span>
        </div>

        <div class="card_order" *ngIf="_type == 'tabloide'">
            <h4> Tabloide </h4>
            <span> {{ order?.tabloide_obj?.name != null ? order?.tabloide_obj?.name : '-' }} </span>
        </div>

        <div class="card_order" *ngIf="_type != 'tabloide'">
            <h4> Catalogo </h4>
            <span> {{ order?.catalog_obj?.name != null ? order?.catalog_obj?.name : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Preço </h4>
            <span> {{ order?.price != null ? convertMoney(order.price) : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Endereço </h4>
            <span> {{ order?.address != null ? order?.address : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Numero </h4>
            <span> {{ order?.number != null ? order?.number : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> CEP </h4>
            <span> {{ order?.cep != null ? (order.cep.toString() | mask: '0000-000') : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Bairro </h4>
            <span> {{ order?.district != null ? order?.district : '-' }} </span>
        </div>

        <div class="card_order">
            <h4> Status </h4>
            <span *ngIf="order.status == 'WAITING'"> Aguardando pagamento</span>
            <span *ngIf="order.status == 'PAYMENT APPROVED'"> Pagamento aprovado </span>
            <span *ngIf="order.status == 'PAYMENT REFUSED'"> Pagamento recusado </span>
            <span *ngIf="order.status == 'IN PROGRESS'"> Em andamento </span>
            <span *ngIf="order.status == 'FINISHED'"> Finalizado </span>
        </div>
    </div>

    <div class="div-table custom-scrollbar">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Produto </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.product.name}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Preço </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde"> {{ convertMoney(element.product.price) }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="qtd">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Qtd. </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde"> {{ element.count }} </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3" style="text-align: center;"> Nenhum resultado encontrado. </td>
            </tr>
        </table>
    </div>

    <div style="width: 70%; margin: 0 auto; display: flex; justify-content: center; align-items: center;">
        <button mat-button [style.background]="color_primary" class="b-border m_20" (click)="close()">
            Fechar
        </button>
    </div>

</div>