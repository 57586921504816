import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from './../../service/payment.service';
import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from 'src/app/service/campaign.service';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {

    constructor(
        private paymentService: PaymentService,
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private campaignService: CampaignService,

    ) {
        this.campaign_id = this.route.snapshot.params.id
    }

    @Input() header: any;
    @Input() list: any;
    @Input() preview_campaign: any;

    screen = 1;
    campaign_id;
    interval;

    loading_btn: boolean = false;

    email: string;
    value;
    value_coin;
    coin_price: any = null;
    order_network: string = null;
    memo: any;

    listImage;
    type_page;

    currency: Currency;
    address: string;

    payment_status: boolean = false;

    currencies: Currency[] = []
    warningMap = new Map();
    token:string;

    orders = []
    order_id;

    ngOnInit(): void {
        console.log('1', this.list);
        
        this.campaignService.watchList().subscribe(data => {
            this.listImage = this.tratamento(this.list)
        })

        this.listImage = this.tratamento(this.list)
        console.log('2',this.listImage);
        
        this.token = this.list?.token_hashlar != null ?
        this.list?.token_hashlar : this.listImage[0].token_hashlar;
        console.log('3',this.token);

        this.getCurrencies(1)

        if(this.order_id != null){
            this.checkPreviousOrder()
        }
    }

    tratamento(list) {
        if (this.preview_campaign) {
            if (this.list?.images != undefined) {
                return list.images;
            }
            else
                return null
        }
        else {
            if (list?.home_images != undefined) {
                this.type_page = 'home';
                return list.home_images;
            }
            else if (list?.internal_images != undefined) {
                // console.log('3',list.internal_images)
                this.type_page = 'internal';
                return list.internal_images;
            }
            else if (list?.page_images != undefined) {
                // console.log('2',list.page_images)
                this.type_page = 'page';
                return list.page_images;
            }
            else if (this.list?.home_images != undefined) {
                // console.log('1',list.home_images)
                this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }

    }

    checkPreviousOrder() {
        // let orders = JSON.parse(localStorage.getItem('payment_orders'))
        // let last_update = new Date(localStorage.getItem('payment_date'))

        // if (orders == null || last_update == null) return

        // let now = new Date()
        // var time = now.getTime() - last_update.getTime();
        // time = time / 1000 / 60;

        // if (time > (60 * 12)) { // 12h
        //     localStorage.removeItem('payment_date')
        //     localStorage.removeItem('payment_orders')
        // }
        // else {
        //     try {
        //         let order = orders.filter(el => el.campaign == this.campaign_id)
        //         if (order.length > 0) {
        //             this.address = order[0].address
        //             this.order_id = order[0].order
        //             this.order_network = order[0].network;
        //             this.value_coin = order[0].value;
        //             this.memo = order[0].memo;
                    this.checkStatus()
                    this.getOrder()
        //         }
        //         this.orders = orders
        //     }
        //     catch {
        //         this.screen = 1
        //         this.address = null
        //         this.order_id = null
        //         this.orders = []
        //     }
        // }

        // console.log(orders);

    }

    getCoinPrice(item) {
        // console.log(item);

        this.paymentService.getCoinPrice(item.id, this.token).subscribe(data => {
            // console.log(data);
            this.coin_price = data.price;
            this.convertCurrencyValue()
        })
    }

    convertCurrencyValue() {
        if (this.coin_price != null && this.coin_price != '') {
            this.value_coin = this.value / this.coin_price;
            // console.log(this.value_coin);
        }
    }

    getCurrencies(page) {
        this.paymentService.getCurrencies(page, this.token).subscribe(data => {
            console.log(data);
            
            this.currencies = this.currencies.concat(data)

            if (data.next != null) this.getCurrencies(page + 1)
        })
    }

    pay() {
        this.warningMap = new Map();
        if (this.email == null || this.email == "") {
            this.warningMap.set('email', true);
        }
        if (this.value == null || this.value == "") {
            this.warningMap.set('value', true);
        }
        if (this.currency == null) {
            this.warningMap.set('currency', true);
        }

        if (this.warningMap.size == 0) {
            this.createOrder()
        }
    }

    createOrder() {
        this.loading_btn = true;
        this.address = this.currency.address
        this.paymentService.createOrder(
            this.currency.id, this.value_coin, this.email, this.token
        ).subscribe(data => {
            this.screen = 2
            this.orders.push({
                campaign: this.campaign_id,
                address: this.address,
                order: data.id,
                network: data.coin_obj.network,
                value: data.amount,
                memo: data.coin_obj.memo
            })

            this.memo = data.coin_obj.memo;
            this.order_network = data.coin_obj.network;
            // localStorage.setItem('payment_orders', JSON.stringify(this.orders))
            // localStorage.setItem('payment_date', new Date().toString())
            this.order_id = data.id
            this.checkStatus()
        }, error => {
            this.loading_btn = false;

            this.openSnackBar(
                "Não foi possível fazer o pedido, por favor verifique os dados inseridos",
                "Fechar",
                []
            )
        })
    }

    checkStatus() {
        this.interval = setInterval(() => {
            this.getOrder()
        }, 15000);
    }

    getOrder() {
        this.paymentService.getOrder(this.order_id, this.token).subscribe(data => {
            this.value = data.amount
            this.currency = data.coin_obj
            this.screen = 2
            this.loading_btn = false;

            if (data.status == 'FINISH') {
                this.payment_status = true
                this.openSnackBar("Pagamento aprovado!", "Fechar", ['payment-snackbar'], 9999999)
                try {
                    clearInterval(this.interval)
                } catch { }
            }
        })
    }

    copy() {
        this.openSnackBar("Endereço copiado!", "Fechar", [])
    }

    openSnackBar(message: string, action: string, panelClass: string[], time: number = 10000,) {
        this._snackBar.open(message, action, {
            duration: time,
            panelClass: panelClass
        });
    }


    convertMoney(money) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(money);
    }
}

interface Currency {
    id: number
    name: string
    address: string
    network: string
}
