import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../service/compressor.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppConfig } from '../../app.config';
import { RegistrationService } from 'src/app/service/registration.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { StorageService } from 'src/app/service/storage.service';
import { NoticeService } from 'src/app/service/notice.service';


@Component({
  selector: 'app-detail-notice',
  templateUrl: './detail-notice.component.html',
  styleUrls: ['./detail-notice.component.css']
})
export class DetailNoticeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private compressor: CompressorService,
    private noticeService: NoticeService,
    private router: Router,
    private registrationService: RegistrationService,
    private modalService: MDBModalService,
    private storageService: StorageService

  ) {
    this.notice_id = this.route.snapshot.paramMap.get('id');
  }
  modalRef: MDBModalRef;

  loading: boolean = false;
  warningMap = new Map();

  id_establishment = null;

  notice_id = null;

  url = AppConfig.path

  notice = {
    id: null,
    describe: null,
    name: null,
    eye_of_notice: null,
    news: [{
      id: '',
      text: '',
      image: '',
      order: 0
    }]
  };

  config_obj = [];

  pdfTerm;

  list_registration = [{ title: 'Nenhum', id: 0 }];

  compressed_image = []; // as the name says...

  color_primary


  indexExpanded: number = -1;

  delete_paragraph = [];



  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    this.id_establishment = localStorage.getItem('id_establishment')

    this.getRegistration(1)

    if (this.notice_id != null) {
      this.getNoticeId()
    }
  }

  getRegistration(page) {
    this.registrationService.getRegistration(page).subscribe(data => {
      this.list_registration = this.list_registration.concat(data.results)
      if (data.next != null) {
        this.getRegistration(page + 1)
      }
    })
  }

  getNoticeId() {
    this.noticeService.getNoticeId(this.notice_id).subscribe(data => {
      // console.log(data);
      this.notice = data;
    })
  }

  togglePanels(index: number) {
    this.indexExpanded = index == this.indexExpanded ? -1 : index;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.notice.news, event.previousIndex, event.currentIndex);
    this.notice.news.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  addParagraph() {
    // console.log(this.notice.news);

    if (this.notice.news == null)
      this.notice.news = []


    this.notice.news.push({
      id: null,
      text: '',
      image: null,
      order: this.notice.news.length + 1,
    })

    this.togglePanels(this.notice.news.length - 1)
  }

  save() {
    this.warningMap = new Map();

    if (this.notice.name == "" || this.notice.name == null) {
      this.warningMap.set('name', true);
    }
    if (this.notice.describe == "" || this.notice.describe == null) {
      this.warningMap.set('describe', true);
    }

    this.notice.news.forEach((notice, index) => {
      if (notice.text == null || notice.text == "") {
        this.warningMap.set('notice' + index, true);
        if (this.indexExpanded != index) this.togglePanels(index);
      }
      if (notice.image == null || notice.image == "") {
        this.warningMap.set('image' + index, true);
        if (this.indexExpanded != index) this.togglePanels(index);
      }
    });

    if (this.warningMap.size == 0) {
      if (this.notice_id == null)
        this.postNotice()
      else
        this.patchNotice()
    }
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value != null) { }

  }

  postNotice() {
    this.loading = true;

    this.noticeService.postNotice(this.notice).subscribe(data => {
      this.notice.news.forEach((paragraph, index) => {
        this.postNew(paragraph, data.id)

        if (index == this.notice.news.length-1) {
          this.loading = false;
          this.router.navigate(['/application/notice'])
        }

      });
    },
      error => {
        this.loading = false;
      })
  }

  patchNotice() {
    this.loading = true;

    this.noticeService.patchNotice(this.notice).subscribe(data => {
      this.notice.news.forEach((paragraph, index) => {

        if (paragraph.id == null)
          this.postNew(paragraph, data.id)
        else
          this.patchNew(paragraph)


        if (index == this.notice.news.length-1) {
          this.loading = false;
          this.router.navigate(['/application/notice'])
        }

      });
    },
      error => {
        this.loading = false;
      })
  }

  postNew(paragraph, id) {
    this.noticeService.postParagraphNotice(paragraph, id).subscribe(data => {
    },
      error => {
        this.loading = false;
      })
  }

  patchNew(paragraph) {
    this.noticeService.patchParagraphNotice(paragraph).subscribe(data => {
    },
      error => {
        this.loading = false;
      })
  }

  convertDate(data) {
    if (data != null) {
      var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hh = d.getHours(),
        mm = d.getMinutes(),
        ss = d.getSeconds();

      var aux_data = [year, month, day].join('-')
      var aux_hour = [hh, mm, ss].join(':')

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [aux_data, aux_hour].join('T');
    }
    else {
      return null
    }

  }

  removeParagraph(obj, index) {

    if (obj.id != null) {
      this.delete_paragraph.push(obj)
      this.notice.news.splice(index, 1)
    }
    else {
      this.notice.news.splice(index, 1)
    }
  }

  async onFileChanged(event, index) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        }),
      );
      compress.subscribe(res => {
        if (res.index > res.array.length - 1) {
          this.notice.news[index].image = this.compressed_image[0]
          this.compressed_image = []

        }
      });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

  openView() {
    this.modalRef = this.modalService.show(CellPhoneComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        isList: this.notice,
        isEdit: true,
        isPopup: 'notice'
      }
    })
  }

}
