import { Component, OnInit } from '@angular/core';
import { StorageService } from '../service/storage.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.css']
})
export class WelcomeScreenComponent implements OnInit {

  constructor(
    private user: UserService,
    private storageService: StorageService
  ) { }

  name_user: string = '';
  text_welcome: string = '';
  color_primary: string = null;
  image_logo: string = null;


  ngOnInit(): void {
    this.user.getUser().subscribe(data=>{
      // console.log(data)
      
      this.name_user = data.name != null ? data.name : 'SBSolutions';
      this.image_logo = data.logo != null ? data.logo : '/assets/image/logo-terceira-opcao.png';
      this.color_primary = data.color != null ? data.color : '#f77128';
      this.storageService.establishment.color = this.color_primary;
  
  })

    var time = new Date().getHours()
    if(time >= 0 && time < 5) this.text_welcome = 'boa noite';
    else if(time >= 5 && time < 12) this.text_welcome = 'bom dia';
    else if(time >= 12 && time < 18) this.text_welcome = 'boa tarde';
    else if(time >= 18) this.text_welcome = 'boa noite';
  }

}
