<div class="screen">

  <div class="div-loading" *ngIf="loading.establishment && loading.constants">
    <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
  </div>

  <div *ngIf="!loading.establishment" style="display: flex; flex-direction: column;">

    <div class="border-gray space-between-center w_100">
      <h3 style="margin: 0; color: white;"> Informações Principais </h3>
    </div>

    <div style="width: 100%; transition: all 0.5s;">

      <input mat-input [(ngModel)]="campaign_name" placeholder="Escreva aqui o nome do conteúdo"
        [ngStyle]="{'border-color': warningMap.get('campaign_name')? 'red' : ''}" class="input">

      <div *ngIf="!loading.establishment"
        style="margin: 10px 0; display: flex; justify-content: flex-start; align-items: center;">
        <h3 style="margin: 0;"> Mostrar seções na campanha? </h3>

        <mat-checkbox style="margin-left: 20px;" [(ngModel)]="show_header">{{ show_header ? 'Ativado' :
          'Desativado'}}</mat-checkbox>
      </div>

      <span *ngIf="show_header">
        <input mat-input [(ngModel)]="campaign_header" placeholder="Escreva aqui o texto do cabeçalho do conteúdo"
          [ngStyle]="{'border-color': warningMap.get('campaign_header')? 'red' : ''}" class="input">

        <input mat-input [(ngModel)]="whatsapp" placeholder="Escreva aqui o whatsapp do cabeçalho"
          [ngStyle]="{'border-color': warningMap.get('whatsapp')? 'red' : ''}" class="input"
          mask="+00 (00) 0 0000-0000">

        <div style="position: relative; width: 100%;">
          <img [src]="image_logo.url" (click)="filePages3.click()" class="image_logo"
            *ngIf="(image_logo.url != '' && image_logo.url != null)">

          <input mat-input [(ngModel)]="image_logo.name" placeholder="Selecione uma imagem para a logo"
            (click)="filePages3.click()"
            [style.paddingLeft]="(image_logo.url != '' && image_logo.url != null) ? '85px' : '20px'"
            [ngStyle]="{'border-color': warningMap.get('image_logo')? 'red' : ''}" class="input" readonly>

          <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            *ngIf="(image_logo.url != '' && image_logo.url != null)" class="btn_delet" (click)="deletLogo()">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
              <path
                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
            </g>
          </svg>

        </div>

        <input #filePages3 type="file" accept="image/*" (change)="addLogo($event)" multiple="multiple"
          style="display: none;">

        <!-- hotfix Lara -->
        <div style="position: relative; width: 100%;">
          <img [src]="side_menu.url" (click)="filePages4.click()" class="image_logo"
            *ngIf="(side_menu.url != '' && side_menu.url != null)">

          <input mat-input [(ngModel)]="side_menu.name" placeholder="Selecione uma imagem para o footer do menu lateral"
            (click)="filePages4.click()"
            [style.paddingLeft]="(side_menu.url != '' && side_menu.url != null) ? '85px' : '20px'"
            [ngStyle]="{'border-color': warningMap.get('side_menu')? 'red' : ''}" class="input" readonly>

          <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            *ngIf="(side_menu.url != '' && side_menu.url != null)" class="btn_delet" (click)="deletSide()">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
              <path
                d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z" />
            </g>
          </svg>
        </div>

        <input #filePages4 type="file" accept="image/*" (change)="addImageSide($event)" multiple="multiple"
          style="display: none;">
      </span>

      <div *ngIf="!loading.establishment"
        style="margin: 10px 0; display: flex; justify-content: flex-start; align-items: center;">
        <h3 style="margin: 0;"> Campanha de controle de ponto? </h3>

        <mat-checkbox style="margin-left: 20px;" [(ngModel)]="is_point_control">{{ is_point_control ? 'Ativado' :
          'Desativado'}}</mat-checkbox>
      </div>

      <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('qrcode')? 'red' : ''}">
        <mat-label>Selecione um ou mais QRCODES onde este content será publicado</mat-label>

        <mat-chip-list #chipList aria-label="">
          <mat-chip *ngFor="let item of arrayChips" [selectable]="'true'" [removable]="'true'"
            (removed)="removeQrCodeChips(item)">
            {{item.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>

          <input #establishmentInput placeholder="Digite aqui ..." [formControl]="qrcodeChipsFormControl"
            [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="qrcodeChipsSelected($event)">
          <mat-option *ngFor="let item of filteredQrcodes | async" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>


      <mat-form-field class="input p_input" [ngStyle]="{'border-color': warningMap.get('group')? 'red' : ''}">
        <mat-label>Selecione um ou mais Grupos que poderão ver essa campanha
          <span style="font-size: 12px;">(se não selecionar nenhum ira aparecer pra todos)</span>
        </mat-label>

        <mat-chip-list #groupChipList aria-label="">
          <mat-chip *ngFor="let item of arrayGroup" [selectable]="'true'" [removable]="'true'"
            (removed)="removeGroupChips(item)">
            {{item.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>

          <input #establishmentInputGroup placeholder="Digite aqui ..." [formControl]="groupChipsFormControl"
            [matAutocomplete]="autoGroup" [matChipInputFor]="groupChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodesGroup">
        </mat-chip-list>

        <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="groupChipsSelected($event)">
          <mat-option *ngFor="let item of filteredGroups | async" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>

      <div *ngIf="!loading.establishment" style="margin: 10px 0;">
        <h3> Dispositivo que poderá visualizar a campanha?
          <span style="font-size: 12px;">(se não selecionar nenhum, irá aparecer pra todos)</span>
        </h3>

        <section>
          <mat-checkbox [(ngModel)]="mobile.android">Android</mat-checkbox>
          <mat-checkbox [(ngModel)]="mobile.ios">iOS</mat-checkbox>
        </section>
      </div>


      <div *ngIf="!loading.establishment" style="display: flex; flex-wrap: wrap;">

        <div style="width: 31%; min-width: 300px; margin-top: 15px;">
          <h3> Cor para o fundo do cabeçalho </h3>

          <div style="display: flex; align-items: center;">
            <input type="color" [(ngModel)]="color_background" style="width: 80px; height: 36px; margin-right: 10px;">
            <button mat-button (click)="selected_colors.bg = true" class="button-cor"
              [style.background]="selected_colors.bg ? color_primary : '#626368'"
              [ngStyle]="{'border-color': warningMap.get('bg_color')? 'red' : ''}">
              Já escolhi
            </button>
          </div>
        </div>

        <div style="width: 31%; min-width: 300px; margin-top: 15px;">
          <h3> Cor para o texto do cabeçalho </h3>

          <div style="display: flex; align-items: center;">
            <input type="color" [(ngModel)]="color_text" style="width: 80px; height: 36px; margin-right: 10px;">
            <button mat-button (click)="selected_colors.text = true" class="button-cor"
              [style.background]="selected_colors.text ? color_primary : '#626368'"
              [ngStyle]="{'border-color': warningMap.get('text_color')? 'red' : ''}">
              Já escolhi
            </button>
          </div>
        </div>

        <div style="width: 31%; min-width: 300px; margin-top: 15px;">
          <h3> Cor dos ícones </h3>

          <div style="display: flex; align-items: center;">
            <input type="color" [(ngModel)]="color_icons" style="width: 80px; height: 36px; margin-right: 10px;">
            <button mat-button (click)="selected_colors.icon = true" class="button-cor"
              [style.background]="selected_colors.icon ? color_primary : '#626368'"
              [ngStyle]="{'border-color': warningMap.get('icon_color')? 'red' : 'transparent'}">
              Já escolhi
            </button>
          </div>
        </div>
      </div>

      <span *ngIf="show_header">
        <div class="input" style="width: 100%; display: flex; 
                    justify-content: space-between; align-items: center; margin: 20px 0 10px;">

          <button mat-button class="button-cor" [style.background]="color_primary" (click)="fileInputPdf.click()">
            Selecione o arquivo
          </button>

          <input matInput [(ngModel)]="pdfTerm_name" readonly style="width: calc(100% - 210px);"
            placeholder="Selecione o arquivo dos termos">

          <div target="_blank" *ngIf="pdfTerm != null && (pdfTerm?.substring(0,4) == 'http')"
            (click)="openPdf(pdfTerm)">
            <svg style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;" viewBox="0 0 1300 1300">
              <path [style.fill]="color_primary" d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                                -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                                265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                                -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                                -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                                423 -1 120 24 203 92 304 134 200 375 280 608 204z" />
              <path [style.fill]="color_primary" d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                                -3 97 -22 19 -53 28 -73 21z" />
              <path [style.fill]="color_primary" d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                                50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z" />
            </svg>
          </div>

          <input type="file" #fileInputPdf style="display: none;" accept="application/pdf" (change)="onFilePdf($event)">
        </div>

      </span>

    </div>

    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px;">
      <button *ngIf="actual_step == 'table'" mat-button class="button-cor"
        style="width: 30%; max-width: 200px; margin-right: 10px;" [style.background]="color_primary"
        (click)="previewFeed()">
        Visualizar
      </button>

      <button mat-button class="button-cor" style="width: 30%; max-width: 200px; margin-right: 10px;"
        [style.background]="color_primary" (click)="nextStep()">
        Salvar
      </button>

      <a href="application/content" style="width: 30%; max-width: 200px;">
        <button mat-button class="button-cor" style="width: 100%; max-width: 200px;" [style.background]="color_primary">
          Voltar
        </button>
      </a>
    </div>

    <div *ngIf="actual_step == 'campaign'"
      style="width: 100%; text-align: center; font-size: 12px; color: #4e4f57; font-weight: 600;">
      *Você poderá adicionar os posts após salvar
    </div>

    <div class="border-gray space-between-center w_100" *ngIf="steps.campaign"
      style="margin: 30px 0 10px; display: flex; justify-content: space-between; align-items: center;">
      <h3 style="margin: 0; color: white;"> Posts </h3>
      <button mat-button class="button-cor" style="width: 50%; max-width: 200px;" [style.background]="color_primary"
        (click)="addPost()">
        Adicionar
      </button>
    </div>

    <div *ngIf="actual_step == 'table'" style="width: 100%; transition: all 0.5s; margin-bottom: 20px;">
      <div class="div-table" *ngIf="loading">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Código </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.id}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="campaign">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Content </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.name}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Tipo </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.homepage_format_obj.name}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="publication">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Publicação </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.created_at | date: 'dd/MM/yyyy'}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header style="max-width: 260px;"> Ações </div>
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div class="div-borde p_5">

                <div (click)="editPost(element)">
                  <img src="/assets/icon/icon_edit.png" matTooltip="Editar" [style.background]="color_primary"
                    class="icon">
                </div>

                <div (click)="orderSection(element, i+1, 'down')">
                  <img src="/assets/icon/arrow_downward.png" matTooltip="Descer" class="icon"
                    [style.background]="color_primary">
                </div>

                <div (click)="orderSection(element, i-1, 'up')">
                  <img src="/assets/icon/arrow_upward.png" matTooltip="Subir" class="icon"
                    [style.background]="color_primary">
                </div>

                <div (click)="checkDeletPost(element)">
                  <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" class="icon view-delet">
                </div>

              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
          </tr>
        </table>

        <div class="table-paginator">
          <div style="display: flex;">
            <div class="paginator-button prev" (click)="backPage()" matTooltip="Página anterior">
              <img src="/assets/icon/prev.png" width="12px" height="12px">
            </div>
            <div class="paginator-button next" (click)="nextPage()" matTooltip="Próxima página">
              <img src="/assets/icon/next.png" width="12px" height="12px">
            </div>
          </div>

          <div>
            Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
          </div>
        </div>
      </div>
    </div>

    <div class="border-gray space-between-center w_100" *ngIf="steps.campaign"
      style="margin: 30px 0 10px; display: flex; justify-content: space-between; align-items: center;">
      <h3 style="margin: 0; color: white;"> Sidebar </h3>
      <button mat-button class="button-cor" style="width: 50%; max-width: 200px;" [style.background]="color_primary"
        (click)="addSidebar()">
        Adicionar
      </button>
    </div>

    <div *ngIf="actual_step == 'table'" style="width: 100%; transition: all 0.5s; margin-bottom: 20px;">
      <div class="div-table" *ngIf="loading">
        <table mat-table [dataSource]="dataSourceSidebar" matSort>

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Código </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.id}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Titulo </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.title}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Posição </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{ element.side == 'LEFT' ? 'Esquerda' : (element.type == 'RIGHT' ? 'Direita' :
                'Centro')}} </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Tipo </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{ element.type == 'BANNER' ? 'Banner' : (element.type == 'CAROUSEL' ? 'Carousel'
                : (element.type == 'PODCAST' ? 'Podcast' : 'Galeria'))}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="publication">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Publicação </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="div-borde"> {{element.created_at | date: 'dd/MM/yyyy'}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title-borde" mat-sort-header> Ações </div>
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div class="div-borde p_5">

                <div (click)="editSidebar(element)">
                  <img src="/assets/icon/icon_edit.png" matTooltip="Editar" [style.background]="color_primary"
                    class="icon">
                </div>

                <div (click)="checkDeletSidebar(element)">
                  <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" class="icon view-delet">
                </div>

              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSidebar"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSidebar;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado.
            </td>
          </tr>
        </table>

        <div class="table-paginator">
          <div style="display: flex;">
            <div class="paginator-button prev" (click)="backPage()" matTooltip="Página anterior">
              <img src="/assets/icon/prev.png" width="12px" height="12px">
            </div>
            <div class="paginator-button next" (click)="nextPage()" matTooltip="Próxima página">
              <img src="/assets/icon/next.png" width="12px" height="12px">
            </div>
          </div>

          <div>
            Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>