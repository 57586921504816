import { CustomEncoder } from './custom-encoder';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class CourseService {

  constructor(private http: HttpClient) { }
  establishment = "id_establishment"


  getCourse(page): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`),
      params: new HttpParams()
        .set(`page`, page)
        .set(`establishment`, localStorage.getItem(this.establishment))
    };

    return this.http.get<any>(AppConfig.path + `core/course/`, options);
  }

  getCourseId(id): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

    if (localStorage.getItem('tokenUser') == null) {
      headers = headers.set('Authorization', `token ${localStorage.getItem('token')}`)
    }
    else {
      headers = headers.set('user', `${localStorage.getItem('tokenUser')}`)
    }

    return this.http.get<any>(AppConfig.path + `core/course/${id}/`, { headers });
  }


  postCourse(course): Observable<any> {
    let body: any = JSON.stringify({
      title: course.title,
      description: course.description,
      workload_in_minutes: course.workload_in_minutes,
      establishment: localStorage.getItem(this.establishment)
    })

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)


    return this.http.post<any>(AppConfig.path + `core/course/`, body, { headers })
  }

  editCourse(course): Observable<any> {
    let body: any = JSON.stringify({
      title: course.title,
      description: course.description,
      workload_in_minutes: course.workload_in_minutes,
      establishment: localStorage.getItem(this.establishment)
    })

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)


    return this.http.patch<any>(AppConfig.path + `core/course/${course.id}/`, body, { headers })
  }

  deleteCourse(course): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/course/${course.id}/`, { headers })
  }

  // COURSE VIDEO
  postCourseVideo(video, index): Observable<any> {
    let body: any = JSON.stringify({
      title: video.title,
      link: video.link,
      order: index,
      description: video.description,
      course: video.course_id
    })

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)


    return this.http.post<any>(AppConfig.path + `core/course-videos/`, body, { headers })
  }

  editCourseVideo(video, index): Observable<any> {
    let body: any = JSON.stringify({
      title: video.title,
      link: video.link,
      order: index,
      description: video.description,
      course: video.course_id
    })

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)


    return this.http.patch<any>(AppConfig.path + `core/course-videos/${video.id}/`, body, { headers })
  }

  deleteCourseVideo(video): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/course-videos/${video.id}/`, { headers })
  }


  // QUIZZ VIDEO
  postQuizzVideo(quizz, id): Observable<any> {
    let body = new HttpParams()
      .set(`quizz`, quizz.title)
      .set(`course_video`, id)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/course-video-quizz/`, body, { headers })
  }

  editQuizzVideo(quizz): Observable<any> {
    let body = new HttpParams()
      .set(`quizz`, quizz.title)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/course-video-quizz/${quizz.id}/`, body, { headers })
  }

  deleteQuizzVideo(quizz): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/course-video-quizz/${quizz.id}/`, { headers })
  }


  // ANSWER VIDEO
  postAnswerVideo(answer, id): Observable<any> {
    let body = new HttpParams()
      .set(`text`, answer.text)
      .set(`answers`, answer.correct)
      .set(`quizz`, id)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/course-video-answer/`, body, { headers })
  }

  editAnswerVideo(answer): Observable<any> {
    let body = new HttpParams()
      .set(`text`, answer.text)
      .set(`answers`, answer.correct)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/course-video-answer/${answer.id}/`, body, { headers })
  }

  deleteAnswerVideo(answer): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/course-video-answer/${answer.id}/`, { headers })
  }


  // REQUEST PLAY / THE END OF VIDEO

  getVideoView(page, course_id): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('user', `${localStorage.getItem('tokenUser')}`),
      params: new HttpParams()
        .set('course', course_id)
        .set('page', page)
    };

    return this.http.get<any>(AppConfig.path + `core/user-course-videos/`, options)
  }

  getCourseCompleted(page): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('user', `${localStorage.getItem('tokenUser')}`),
      // .set('user', `b9e0ef07333c3547a4af94ec591b159e5719ee1b`),
      params: new HttpParams()
      // .set('course', course_id)
      // .set('page', page)
    };

    return this.http.get<any>(AppConfig.path + `core/courses-completed/`, options)
  }

  videoStart(video): Observable<any> {
    const body = new HttpParams()
      .set(`course_video_id`, video.id)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/start-video/`, body, { headers })
  }

  videoFinished(video): Observable<any> {
    const body = new HttpParams()
      .set(`course_video_id`, video.id_user_videos)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/turn-course-video-finished/`, body, { headers })
  }

  getCertificate(course_id): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    // return this.http.get<any>(AppConfig.path + `core/generate-certificate/?course_completed_id=${course_id}`, { headers, responseType: 'blob' as 'json', observe: 'response' })
    return this.http.get<any>(AppConfig.path + `core/certificate/?course_completed_id=${course_id}`, { headers, responseType: 'blob' as 'json', observe: 'response' })
  }


  // Couser User
  filterUserCourse(user_campaign): Observable<any> {
    const options = {
      params: new HttpParams()
        .set(`user_campaign`, user_campaign),
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('user', `${localStorage.getItem('tokenUser')}`)
    };

    return this.http.get<any>(AppConfig.path + `core/course-user/filter`, options);
  }

  getCourseUser(page): Observable<any> {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`),
      params: new HttpParams()
        .set('page', page)
    };

    return this.http.get<any>(AppConfig.path + `core/course-user/`, options)
  }

  postCourseUser(user_campaign, course): Observable<any> {

    const body = new HttpParams()
      .set(`user_campaign`, user_campaign)
      .set(`course`, course)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    // .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/course-user/`, body, { headers })
  }

  sendPointUser(course_user, quizz): Observable<any> {

    const body = new HttpParams()
      .set(`course_user_id`, course_user)
      .set(`quizz_id`, quizz)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/course-user-point/`, body, { headers })
  }

  userApproved(video): Observable<any> {
    const body = new HttpParams()
      .set(`course_user_id`, video.id)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    // .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/course-user/is_approved/`, body, { headers })
  }

  validateAnswer(answer_id): Observable<any> {

    const body = new HttpParams()
      .set(`answer_id`, answer_id)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/course-video-answer/is_valid/`, body, { headers })
  }

  importDocument(support_file, course_video): Observable<any> {

    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`support_file`, encodeURI(support_file.file))
      .set(`course_video`, course_video)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/course-video-support/`, body, { headers })
  }

  deletDocument(id): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('user', `${localStorage.getItem('tokenUser')}`)

    return this.http.delete<any>(AppConfig.path + `core/course-video-support/${id}`, { headers })
  }
}
