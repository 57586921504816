<div class="screen">
        
    <div class="img-carousel" style="width: 100%;" *ngIf="loading">
        <!-- <ngx-slick-carousel class="carousel" id="slider" 
            #slickModal="slick-carousel" 
            [config]="slideConfigBanner"> -->

            <!-- <div style="width: 100%; height: 100%; display: flex; flex-direction: row;"> -->
                <div *ngFor="let banner of listImage; let i = index" 
                    [ngStyle]="{'width': i == position ? '100%' : '0px'}" class="slide">
                
                    <div class="img-slider">
    
                        <img *ngIf="!preview_campaign" [src]="banner.image" class="photo" loop=infinite
                            id="slideImage" (click)="addNewItem(banner)"> 
    
                        <img *ngIf="preview_campaign" [src]="banner.url" class="photo" loop=infinite
                            id="slideImage" (click)="addNewItem(banner)"> 
    
                    </div>
    
                    <div *ngIf="i == position">
                        <div class="btn-slide btnL" (click)="prev()"> < </div>
                        <div class="btn-slide btnR" id="bt_next" (click)="next()"> > </div>
                    </div>
                </div>
            <!-- </div> -->

        <!-- </ngx-slick-carousel> -->
    </div>
   
    
    
</div>