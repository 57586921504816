<main *ngIf="!is_order && !is_login">
  <header>
    <section class="card_image_herader">
      <img *ngIf="tabloide_obj?.banner_image != null" [src]="tabloide_obj.banner_image">
    </section>

    <section class="card_options_header">
      <img src="../../../assets/image/visualization.png" (click)="changeModeView()" [style.background]="color_primary"
        style="padding: 7px" class="icon_button" />

      <img src="../../../assets/icon/cesta_de_compras.png" (click)="cart_bool = !cart_bool"
        [style.background]="color_primary" class="icon_button" />
    </section>

    <img *ngIf="tabloide_obj?.header_image != null" [src]="tabloide_obj.header_image" class="card_image_backbround">
  </header>

  <div *ngIf="tabloide_obj != null" style="width: 100%;">
    <div *ngIf="!cart_bool" class="modo_carroseu">
      <section class="card_category" *ngIf="tabloide_obj?.category != null && tabloide_obj.category.length >= 1">
        <ngx-slick-carousel class="carousel" #slickCat="slick-carousel" [config]="slideConfig"
          (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let slide of tabloide_obj.category; let idx_c = index" class="slide">

            <img *ngIf="slide?.image == null" src="../../../assets/image/bebidas-alcoolicas.png" class="image_slide"
              (click)="selectCategory(slide, idx_c)"
              [style.borderColor]="slide.id == slides_products.id ? color_primary : 'transparent' " />

            <img *ngIf="slide?.image != null" [src]="slide.image" class="image_slide" (click)="selectCategory(slide, idx_c)"
              [style.borderColor]="slide.id == slides_products.id ? color_primary : 'transparent' " />

          </div>
        </ngx-slick-carousel>
      </section>

      <section *ngIf="!view_mode">
        <div class="card_produts" *ngIf="slides_products?.product != null && slides_products.product.length > 0">
          <ngx-slick-carousel class="carousel" id="slickModal" #slickModal="slick-carousel"
            [config]="slideConfigProduct"  (afterChange)="changeSlide($event)">
            <div ngxSlickItem *ngFor="let slide of slides_products.product; let idx = index" class="slide" [id]="idx">
              <img [src]="slide.image" class="image_products" />

              <div class="card_info">
                <h4>{{ slide.name }}</h4>
                <h4 *ngIf="slide?.text_description != null && slide?.text_description != ''"
                  style="height: 50px; display: flex; align-items: center;"> {{ slide?.text_description
                  }}
                </h4>
                <div
                  style="width: 100%; margin-top: 10px; display: flex; align-items: center; justify-content: space-evenly;">
                  <p [ngClass]="{'price_old': slide.promotion_price != null && slide.promotion_price != ''}">
                    {{ formatReal(slide.price) }}
                  </p>
                  <p style="font-size: 18px;" *ngIf="slide.promotion_price != null && slide.promotion_price != ''">
                    {{ formatReal(slide.promotion_price) }}
                  </p>
                </div>
              </div>

              <div class="card_buttons_footer" [style.background]="color_primary">
                <img src="../../../assets/image/like.png" class="icon_button" />
                <img (click)="addItemCart(slide)" src="../../../assets/icon/shopping-cart.png" class="icon_button" />
                <img src="../../../assets/image/go-back-arrow.png" class="icon_button" />
              </div>

            </div>
          </ngx-slick-carousel>
        </div>

        <footer *ngIf="tabloide_obj.footer_text != null && tabloide_obj.footer_text != ''" class="card_footer">
          {{ tabloide_obj.footer_text }}
        </footer>
      </section>

      <section *ngIf="view_mode">
        <div class="card_produts_grid" style="margin-top: 70px !important;">
          <div *ngFor="let slide of slides_products.product; let idx = index" class="card_image"
            (click)="selectItemList(slide, idx)">
            <img [src]="slide.image" class="image_products" />

            <div class="card_info">
              <h4 style="font-size: 10px; line-height: 1.5;">{{ slide.name }}</h4>
              <p style="font-size: 12px; line-height: 1.5;">{{ slide?.promotion_price != null && slide.promotion_price
                != '' ? formatReal(slide.promotion_price) : formatReal(slide.price) }}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div *ngIf="cart_bool" class="card_cart" style="margin-top: 70px !important;">
    <section>
      <div>
        <h2>Carrinho</h2>
      </div>

      <div *ngIf="cart_list.length > 0" class="card_produts_grid">
        <div *ngFor="let slide of cart_list" class="card_image">
          <img [src]="slide.image" class="image_products" />

          <div class="card_info">
            <h4>{{ slide.name }}</h4>
            <div
              style="width: 100%; margin-top: 10px; display: flex; align-items: center; justify-content: space-evenly;">
              <p *ngIf="slide.promotion_price == null || slide.promotion_price == ''">
                {{ formatReal(slide.price) }}
              </p>
              <p *ngIf="slide.promotion_price != null && slide.promotion_price != ''">
                {{ formatReal(slide.promotion_price) }}
              </p>
            </div>
          </div>
        </div>

        <button mat-button class="btn_finish" (click)="is_order = true;">
          Comprar pedido
        </button>
      </div>

      <div *ngIf="cart_list == null || cart_list.length == 0" class="without_items">
        <span>Carrinho vazio...</span>
      </div>
    </section>
  </div>
</main>

<app-phase-order *ngIf="is_order && !is_login" [establishment_id]="list.establishment" [list_order]="cart_list" [tabloide]="tabloide_obj" (newItemEvent)="eventItem($event)"></app-phase-order>

<app-login-user *ngIf="is_login" style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="tabloide_obj" (newItemEvent)="login($event)"></app-login-user>