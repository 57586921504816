<div class="screen">
    <div style="width: 93%; display: flex; justify-content: space-between; align-items: center; margin: auto;">
        <h3> Pedidos Tabloide - Lista atualizada à cada 5 minutos </h3>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">

            <mat-form-field class="card_select" style="padding: 0 !important; border: 1px solid transparent; margin-top: -14px;">
                <mat-select class="select-box" [(ngModel)]="filterStatus">
                    <mat-option *ngFor="let item of list_status;" [value]="item.id" (click)="filterTable(item)">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <button mat-button (click)="getOrder()"
            style="display: flex; align-items: center; justify-content: space-evenly;">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
    </div>

    <div class="div-table" *ngIf="!loading">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="products">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Tabloide </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element?.tabloide_obj?.name != null ? element.tabloide_obj.name : '-' }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Preço </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde"> {{ convertMoney(element.price) }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="qtd">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Qtd. Produtos </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde"> {{ element.products_tabloide.length }} </div>    
                </td>
            </ng-container>

            <ng-container matColumnDef="last_change">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Data do pedido </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.updated_at | date: 'dd/MM/yyyy'}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Status </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde" style="cursor: pointer;"> 
                        <div *ngIf="element.status == 'WAITING'"(click)="editStatus(element)"> Aguardando pagamento</div>
                        <div *ngIf="element.status == 'PAYMENT APPROVED'" (click)="editStatus(element)"> Pagamento aprovado </div>
                        <div *ngIf="element.status == 'PAYMENT REFUSED'" (click)="editStatus(element)"> Pagamento recusado </div>
                        <div *ngIf="element.status == 'IN PROGRESS'" (click)="editStatus(element)"> Em andamento </div>
                        <div *ngIf="element.status == 'FINISHED'" (click)="editStatus(element)"> Finalizado </div>
                    </div>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="width: 100px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index" style="width: 100px;">
                    <div class="div-borde p_5" style="width: 100px; display: flex; justify-content: center;">
 
                        <div (click)="openPreview(element)">
                            <img src="/assets/icon/icon-olho.png" matTooltip="Visualizar" [style.background]="color_primary" class="icon"> 
                        </div>

                    </div>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
            </tr>
        </table>
    
        <div class="table-paginator">
            <div style="display: flex;">
                <div class="paginator-button prev" (click)="backPage()" matTooltip="Página anterior">
                    <img src="/assets/icon/prev.png" width="12px" height="12px">
                </div>
                <div class="paginator-button next" (click)="nextPage()" matTooltip="Próxima página">
                    <img src="/assets/icon/next.png" width="12px" height="12px">
                </div>
            </div>
    
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
        </div>
    </div>
            
</div>