import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY, Observable } from 'rxjs';
import { map, expand, startWith } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { ModalMessageComponent } from '../../modals/modal-message/modal-message.component';
import { CampaignService } from '../../service/campaign.service';
import { CatalogService } from '../../service/catalog.service';
import { CompressorService } from '../../service/compressor.service';
import { ConstantsService } from '../../service/constants.service';
import { CouponService } from '../../service/coupon.service';
import { RegistrationService } from '../../service/registration.service';
import { SurveyService } from '../../service/survey.service';
import { StorageService } from 'src/app/service/storage.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { TagService } from 'src/app/service/tag.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CourseService } from 'src/app/service/course.service';
import { NoticeService } from 'src/app/service/notice.service';
import { PopupGenerateFileLinkComponent } from 'src/app/modals/popup-generate-file-link/popup-generate-file-link.component';
import { TabloideService } from 'src/app/service/tabloide.service';
import { Image_campaign, theItem } from 'src/app/model/campaign';

@Component({
  selector: 'app-post-feed',
  templateUrl: './post-feed.component.html',
  styleUrls: ['./post-feed.component.css']
})

export class PostFeedComponent {

  constructor(
    private constantsService: ConstantsService,
    private campaignService: CampaignService,
    private compressor: CompressorService,
    private catalogService: CatalogService,
    private router: Router,
    private modalService: MDBModalService,
    private surveyService: SurveyService,
    private couponService: CouponService,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private storageService: StorageService,
    private tagService: TagService,
    private courseService: CourseService,
    private noticeService: NoticeService,
    private tabloideService: TabloideService
  ) {
    this.campaign_id = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.paramMap.get('id_post') != null)
      this.post_id = this.route.snapshot.paramMap.get('id_post');
  }

  @ViewChild('establishmentInputTag') establishmentInputTag: ElementRef<HTMLInputElement>;
  @ViewChild('establishmentInputSubtag') establishmentInputSubtag: ElementRef<HTMLInputElement>;

  modalRef: MDBModalRef;
  modalImport: MDBModalRef;


  link: string = AppConfig.path;
  warningMap = new Map();
  error = {
    msg: "",
    check: false
  };

  loading = {
    constants: false,
    finish: false
  }

  // post
  name_post: string = '';
  post_obj: any = null;
  post_id = null;

  surveys = [];
  catalogs = [];
  campaigns = [];
  homepage_types = [];
  page_types = [];

  steps = { post: false, homepage: false, homepage_images: false, page_types: false, internal_pages: false };
  actual_step: string = "homepage";

  // coupons
  list_coupon = [{ id: -1, name: 'Nenhum cupom' }];
  coupon;
  id_coupon;

  // configuração
  list_config = [{ id: -1, title: 'Nenhuma configuração' }];
  config;
  id_config;

  // couorse
  list_course = []

  list_notice = []

  list_tabloide = [{ id: -1, name: 'Nenhum tabloide' }]

  selected_homepage_type; // homepage type object
  links = {}; // object for inputs of links/videos
  compressed_image = []; // as the name says...

  input_facebook: string = '';
  input_instagram: string = '';
  input_youtube: string = '';

  campaign_id
  campaign_select;

  homepage: Image_campaign = {
    id: null,
    type: null,
    quantity: null,
    link: null,
    option_link: null,
    placeholder: null,
    catalog: null,
    coupon: null,
    registration_config: null,
    background_image: null,
    images: [],
    order: null,
    title: null,
    point_control_name: null,
    description: null,
    name: null,
    url: null,
    survey: null,
    video: null,
    notice: null,
    level: null,
    save: false,
    course: null,
    point_type: null,
    castbox: null,
    deezer: null,
    itunes: null,
    google_podcast: null,
    course_obj: null,
    token_hashlar: null
  } // everything

  cont = {
    home: 0,
    page: 0
  }

  option_link = [
    { name: 'Link', link: '', exem: 'Insira um link' },
    { name: 'WhatsApp', link: 'https://wa.me/', exem: '+00 (00) 0 0000 0000' },
    { name: 'Telefone', link: 'tel:', exem: '00 0000 0000' },
    { name: 'Email', link: 'mailto:', exem: 'Insira seu email' },
    { name: 'Relatório', link: '', exem: 'Insira seu email' }
  ]
  option_select_link;

  remove = {
    homepage: [],
    page: [],
    internal: []
  }

  page = {
    survey: 1,
    coupon: 1,
    qrcode: 1,
    tag: 1,
    group: 1,
    course: 1
  }

  // tag chips
  tags = [];
  list_tag_campaign = { homepage: [], page: [], internal: [] }

  tagArrayChipsHomepage = [];
  tagArrayChipsPage = [];
  tagArrayChipsInternal = [];
  listTag = { home: [], page: [], internal: [] };
  // tagArrayChips = [];
  // listTag = [];

  deliteTag = { homepage: [], page: [], internal: [] };
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  tagChipsFormControl = new FormControl();

  filteredTagHomepage: Observable<string[]>;
  filteredTagPage: Observable<string[]>;
  filteredTagInternal: Observable<string[]>;
  // filteredTag: Observable<string[]>;

  // subtag chips
  subtags = [];
  list_subtag_campaign = { homepage: [], page: [], internal: [] }

  subtagArrayChipsHomepage = [];
  subtagArrayChipsPage = [];
  subtagArrayChipsInternal = [];
  listSubtag = { home: [], page: [], internal: [] };
  // tagArrayChips = [];
  // listTag = [];

  deliteSubtag = { homepage: [], page: [], internal: [] };
  separatorKeysCodesSubtag: number[] = [ENTER, COMMA];
  subtagChipsFormControl = new FormControl();

  filteredSubtagHomepage: Observable<string[]>;
  filteredSubtagPage: Observable<string[]>;
  filteredSubtagInternal: Observable<string[]>;
  // filteredSubtag: Observable<string[]>;

  homepage_id;
  page_id;

  selected_homepage_image: number = 0; //var for index of homepage image selected in pages formats
  selected_page_image: number = 0; //var for index of page image selected in pages formats

  id_establishment = null;
  select_anterior;
  select_type_anterior;

  color_primary;
  view_level_page: boolean = false;
  validTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;
    this.option_select_link = this.option_link[0]
    this.id_establishment = localStorage.getItem('id_establishment')
    this.config = this.list_config[0].id

    if (this.post_id != null && this.post_id != null) {
      // console.log(this.post_id)
      this.getCampaignsPostsId(this.post_id)
    }

    this.getConstants()
    this.getCatalogs(1)
    this.getCoupons()
    this.getRegistration(1)
    this.getSurveys()
    this.getNotices(1)
    this.getTabloide(1)
    this.getTags(this.page.tag)
    this.getCouser(this.page.course)
  }

  getCampaignsPostsId(id) {
    this.campaignService.getCampaignsPostsId(id).subscribe(data => {
      console.log('AFTER Campaign', data)

      this.name_post = data.name;
      this.selected_homepage_type = data.homepage_format_obj;

      if (data?.home_images != null && data.home_images.length > 0) {
        data.home_images = data.home_images.sort(this.ordenarList)

        data.home_images.forEach((home, index) => { //home_images[n]

          if (home?.image != null || home?.page_format_obj != null) {
            this.getTag('homepage', home.id, 1)

            this.homepage.images.push({
              id: home.id,
              type: home.page_format_obj,
              quantity: null,
              link: ((home?.page_format_obj?.name == "Link" ||
                home?.page_format_obj?.name == "Vídeo" ||
                home?.page_format_obj?.name == "Podcast")
                &&
                home?.page_images != null)
                ?
                this.checkLink(home?.page_images[0]?.link, 'link')
                :
                (data?.homepage_format_obj?.name != null &&
                  data.homepage_format_obj.name == 'Post' &&
                  home?.link != null
                  ?
                  this.checkLink(home?.link, null)
                  :
                  this.checkLink(home?.link, 'link')),

              option_link: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo") && home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, null) : this.checkLink(home?.link, null),
              placeholder: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo") && home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, 'exem') : this.checkLink(home?.link, 'exem'),
              catalog: (home?.page_format_obj?.name == "Catálogo" && home?.page_images != null) ? home?.page_images[0]?.catalog : null,
              coupon: (home.page_format_obj.name == "Cupom" && home?.page_images != null) ? home?.page_images[0]?.coupon : null,
              registration_config: (home.page_format_obj.name == "Cadastro" && home?.page_images != null) ? home?.page_images[0]?.registration_config : null,
              background_image: { url: (home.page_format_obj.name == "Vídeo" && home?.image != null) ? home?.image : null },
              images: null,
              order: home.order,
              title: (data.homepage_format_obj.name != 'Ponto' &&
                (home?.page_format_obj?.name == "Podcast" || home?.page_format_obj?.name == "Post")) ? home?.page_images[0]?.title : home.title,

              point_control_name: home.point_control_name,

              description: (data.homepage_format_obj.name != 'Ponto' &&
                home?.page_format_obj?.name == "Post") ? home?.page_images[0]?.description : home.description,
              name: '',
              url: data.homepage_format_obj.name != 'Ponto' && home?.page_format_obj?.name == "Post" ? home?.page_images[0]?.image : home.image,
              survey: (home.page_format_obj.name == "Enquete" && home?.page_images != null) ? home?.page_images[0]?.survey : null,
              video: null,
              save: false,
              point_type: home.home_page_image_type,
              course: home.course,
              course_obj: null,
              notice: home.eye_news,
              tabloide: home.tabloide,
              castbox: home.castbox,
              deezer: home.deezer,
              itunes: home.itunes,
              google_podcast: home.google_podcast,
              token_hashlar: home.token_hashlar,
              // course_obj: this.getCouser(home.course, 'home', index, null, null),
              level: ((home.page_format_obj?.name == 'Mini Banner') ||
                (home.page_format_obj?.name == 'Galeria') ||
                (home.page_format_obj?.name == 'Banner')) ? true : null,
            })


            if (home.page_images != null && home.page_images.length > 0) {

              home.page_images = home.page_images.sort(this.ordenarList)

              home.page_images.forEach((page, index2) => {    //page_images[n]

                if (this.homepage.images[this.cont.home].images == null) {
                  this.homepage.images[this.cont.home].images = [];
                }
                this.getTag('page', page.id, 1)

                this.homepage.images[this.cont.home].images.push(this.storageService.setImageCampaignHomepage(page, data))

                if ((page.page_format_obj.name == 'Banner' || page.page_format_obj.name == 'Mini Banner' || page.page_format_obj.name == 'Post' ||
                  page.page_format_obj.name == 'Galeria' || page.page_format_obj.name == "Vídeo" || page.page_format_obj.name == "Cadastro" || page.page_format_obj.name == "Ponto") && page.internal_images != null && page.internal_images.length > 0) {

                  page.internal_images = page.internal_images.sort(this.ordenarList)

                  page.internal_images.forEach((internal, index3) => {    //internal_images[n]

                    if (this.homepage?.images[this.cont.home]?.images[this.cont.page] != null) {

                      this.getTag('internal', internal.id, 1)

                      if (this.homepage.images[this.cont.home].images[this.cont.page].images == null)
                        this.homepage.images[this.cont.home].images[this.cont.page].images = []

                      this.homepage.images[this.cont.home].images[this.cont.page].images.push(this.storageService.setImageCampaignInternal(internal, page))

                    }

                  });

                }

                this.cont.page += 1;
              });

              this.cont.page = 0;
            }
            this.cont.home += 1;

          }

        });
      }
      this.loading.finish = false;
      // console.log('BEFORE Campaign', this.homepage)

      setTimeout(() => {
        this.chechListTags()
      }, 1000);
    })

  }

  getConstants() {
    this.loading.constants = true;

    this.constantsService.getConstants().subscribe(data => {

      this.homepage_types = data.homepage_types;
      this.page_types = data.page_types;

      // hotfix Lara
      data.homepage_types = data.homepage_types.splice(AppConfig.qtd_homepage_types)
      data.page_types = data.page_types.splice(AppConfig.qtd_page_types)

      var position_1 = this.homepage_types.findIndex(el => el.id == 7)
      this.homepage_types.splice(position_1, 1)

      this.loading.constants = false;
    }, error => {
      this.loading.constants = false;
    })
  }

  getCatalogs(page) {
    this.catalogService.getCatalogs(page).subscribe(data => {
      this.catalogs = this.catalogs.concat(data.results);
      if (data.next != null) {
        this.getCatalogs(page + 1)
      }
    })
  }

  getCoupons() {
    this.couponService.getCoupons(this.page.coupon).subscribe(data => {
      data.results.forEach(el => {
        this.list_coupon.push(el)
      });

      if (data.next != null) {
        this.page.coupon += 1;
        this.getCoupons()
      }
    })
  }

  getRegistration(page) {
    this.registrationService.getRegistration(page).subscribe(data => {

      this.list_config = this.list_config.concat(data.results)
      if (data.next != null) {
        this.getRegistration(page + 1)
      }
    })
  }

  getSurveys() {
    this.surveyService.getSurvey_EstablishmentId(this.id_establishment, 1).subscribe(data => {
      this.surveys = this.surveys.concat(data.results);
    })
  }

  getNotices(page) {
    this.noticeService.getNotices(page).subscribe(data => {

      if (this.list_notice.length == 0)
        this.list_notice.push({ id: -1, name: 'Nenhum notícia' })

      this.list_notice = this.list_notice.concat(data.results)
      if (data.next != null) {
        this.getNotices(page + 1)
      }
    })
  }

  getTabloide(page) {
    this.tabloideService.getTabloide(page).subscribe(data => {
      this.list_tabloide = this.list_tabloide.concat(data.results);
      if (data.next != null) {
        this.getTabloide(page + 1)
      }
    })
  }

  getCouser(page) {
    this.courseService.getCourse(page).subscribe(data => {
      // console.log(data)
      this.list_course = this.list_course.concat(data.results)

      if (data.next != null) {
        this.page.course += 1
        this.getCouser(this.page.course)
      }

    }, error => {
      console.log(error)
    })
  }

  getTags(page) {
    this.tagService.getAllTags(page).subscribe(data => {
      // console.log(data)

      this.tags = this.tags.concat(data.results)

      if (data.next != null) {
        this.page.tag += 1
        this.getTags(this.page.tag)
      }
      else {
        this.filteredTagHomepage = this.tagChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

        this.filteredTagPage = this.tagChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));

        this.filteredTagInternal = this.tagChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
        // this.filteredTag = this.tagChipsFormControl.valueChanges.pipe(
        //     startWith(null),
        //     map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
      }
    })
  }

  private _filter2(value: string): string[] {
    if (value != null) {
      const filterValue = value;
      return this.tags.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  chechListTags() {
    let filter_tag = [];
    let filter_subtag = [];

    // console.log(this.list_tag_campaign);

    this.list_tag_campaign.homepage.forEach(obj => {
      // console.log('----------------');

      if (obj.home_page != null) {
        filter_tag = this.listTag.home.filter(el => (el.id || '') == obj?.tag_obj?.id);
        filter_subtag = this.listSubtag.home.filter(el => (el.id || '') == obj?.sub_tags[0]?.id);

        if (obj?.tag_obj?.id != null && (filter_tag == null || filter_tag.length == 0)) {
          this.listTag.home.push({ id: obj.id, obj: obj.tag_obj })
          this.tagArrayChipsHomepage.push(obj.tag_obj)
        }
        if (obj?.sub_tags != null && obj?.sub_tags[0]?.id && (filter_subtag == null || filter_subtag.length == 0)) {
          this.listSubtag.home.push({ id: obj.id, obj: obj.sub_tags[0] })
          this.subtagArrayChipsHomepage.push(obj.sub_tags[0])
        }
      }
    });

    this.list_tag_campaign.page.forEach(obj => {
      console.log(obj);

      if (obj.page != null) {

        filter_tag = this.listTag.home.filter(el => el.id == obj.tag_obj.id);

        if (filter_tag == null || filter_tag.length == 0) {
          this.listTag.page.push({ id: obj.id, obj: obj.tag_obj })
          this.tagArrayChipsPage.push(obj.tag_obj)
        }
      }
    });

    this.list_tag_campaign.internal.forEach(obj => {
      console.log(obj);

      if (obj.internal_page != null) {
        filter_tag = this.listTag.home.filter(el => el.id == obj.tag_obj.id);

        if (filter_tag == null || filter_tag.length == 0) {
          this.listTag.internal.push({ id: obj.id, obj: obj.tag_obj })
          this.tagArrayChipsInternal.push(obj.tag_obj)
        }
      }
    });

    console.log(this.listTag)
    this.setSubtags()
    this.getSubtags()
  }

  removeTagChips(tag: string, type): void {
    const indexH = this.tagArrayChipsHomepage.indexOf(tag);
    const indexP = this.tagArrayChipsPage.indexOf(tag);
    const indexI = this.tagArrayChipsInternal.indexOf(tag);

    if (type == 'home') {
      if (indexH >= 0) {
        this.deliteTag.homepage.push(this.listTag.home[indexH])
        this.tagArrayChipsHomepage.splice(indexH, 1);
        this.listTag.home.splice(indexH, 1);
      }
    }
    else if (type == 'page') {
      if (indexP >= 0) {
        this.deliteTag.page.push(this.listTag.page[indexP])
        this.tagArrayChipsPage.splice(indexP, 1);
        this.listTag.page.splice(indexP, 1);
      }
    }
    else if (type == 'internal') {
      if (indexI >= 0) {
        this.deliteTag.internal.push(this.listTag.internal[indexI])
        this.tagArrayChipsInternal.splice(indexI, 1);
        this.listTag.internal.splice(indexI, 1);
      }
    }
  }

  tagChipsSelectedTag(event: MatAutocompleteSelectedEvent, type): void {
    if (!this.tagArrayChipsHomepage.includes(event.option.value.name)) {

      if (type == 'home') {
        if (this.listTag.home == null || this.listTag.home.length == 0)
          this.listTag.home = []

        this.tagArrayChipsHomepage.push(event.option.value);
        this.listTag.home.push({ id: null, obj: event.option.value });
      }
      else if (type == 'page') {
        if (this.listTag.page == null || this.listTag.page.length == 0)
          this.listTag.page = []

        this.tagArrayChipsPage.push(event.option.value);
        this.listTag.page.push({ id: null, obj: event.option.value });
      }
      else if (type == 'internal') {
        if (this.listTag.internal == null || this.listTag.internal.length == 0)
          this.listTag.internal = []

        this.tagArrayChipsInternal.push(event.option.value);
        this.listTag.internal.push({ id: null, obj: event.option.value });
      }
    }

    console.log(this.listTag);

    this.setSubtags()
    this.getSubtags()

    this.establishmentInputTag.nativeElement.value = '';
    this.tagChipsFormControl.setValue(null);
  }

  setSubtags() {
    this.subtags = []

    this.listTag.home.forEach(home => {
      this.subtags = this.subtags.concat(home.obj.sub_tags)
    });

    this.listTag.page.forEach(page => {
      this.subtags = this.subtags.concat(page.obj.sub_tags)
    });

    this.listTag.internal.forEach(internal => {
      this.subtags = this.subtags.concat(internal.obj.sub_tags)
    });

    // console.log(this.subtags);
  }

  getSubtags() {
    this.filteredSubtagHomepage = this.subtagChipsFormControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this._filterSub(item) : this.subtags.slice()));

    this.filteredSubtagPage = this.subtagChipsFormControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this._filterSub(item) : this.subtags.slice()));

    this.filteredSubtagInternal = this.subtagChipsFormControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this._filterSub(item) : this.subtags.slice()));
  }

  private _filterSub(value: string): string[] {
    if (value != null) {
      const filterValue = value;
      return this.subtags.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  chechListSubtags() {
    let filter_obj = [];

    console.log(this.list_subtag_campaign);

    this.list_subtag_campaign.homepage.forEach(obj => {
      console.log(obj);

      if (obj.home_page != null) {

        filter_obj = this.listSubtag.home.filter(el => el.id == obj.tag_obj.id);
        console.log('1', filter_obj);

        if (filter_obj == null || filter_obj.length == 0) {
          this.listSubtag.home.push({ id: obj.id, obj: obj.tag_obj })
          this.subtagArrayChipsHomepage.push(obj.tag_obj)
        }
      }
    });

    this.list_tag_campaign.page.forEach(obj => {
      console.log(obj);

      if (obj.page != null) {

        filter_obj = this.listSubtag.home.filter(el => el.id == obj.tag_obj.id);
        console.log('2', filter_obj);

        if (filter_obj == null || filter_obj.length == 0) {
          this.listSubtag.page.push({ id: obj.id, obj: obj.tag_obj })
          this.subtagArrayChipsPage.push(obj.tag_obj)
        }
      }
    });

    this.list_tag_campaign.internal.forEach(obj => {
      console.log(obj);

      if (obj.internal_page != null) {
        filter_obj = this.listSubtag.home.filter(el => el.id == obj.tag_obj.id);
        console.log('3', filter_obj);

        if (filter_obj == null || filter_obj.length == 0) {
          this.listSubtag.internal.push({ id: obj.id, obj: obj.tag_obj })
          this.subtagArrayChipsInternal.push(obj.tag_obj)
        }
      }
    });

    console.log(this.listSubtag)
  }

  removeSubtagChips(tag: string, type): void {
    const indexH = this.subtagArrayChipsHomepage.indexOf(tag);
    const indexP = this.subtagArrayChipsPage.indexOf(tag);
    const indexI = this.subtagArrayChipsInternal.indexOf(tag);

    if (type == 'home') {
      if (indexH >= 0) {
        this.deliteSubtag.homepage.push(this.listSubtag.home[indexH])
        this.subtagArrayChipsHomepage.splice(indexH, 1);
        this.listSubtag.home.splice(indexH, 1);
      }
    }
    else if (type == 'page') {
      if (indexP >= 0) {
        this.deliteSubtag.page.push(this.listSubtag.page[indexP])
        this.subtagArrayChipsPage.splice(indexP, 1);
        this.listSubtag.page.splice(indexP, 1);
      }
    }
    else if (type == 'internal') {
      if (indexI >= 0) {
        this.deliteSubtag.internal.push(this.listSubtag.internal[indexI])
        this.subtagArrayChipsInternal.splice(indexI, 1);
        this.listSubtag.internal.splice(indexI, 1);
      }
    }
  }

  subtagChipsSelectedSubtag(event: MatAutocompleteSelectedEvent, type): void {
    if (!this.subtagArrayChipsHomepage.includes(event.option.value.name)) {

      if (type == 'home') {
        if (this.listSubtag.home == null || this.listSubtag.home.length == 0)
          this.listSubtag.home = []

        this.subtagArrayChipsHomepage.push(event.option.value);
        this.listSubtag.home.push({ id: null, obj: event.option.value });
      }
      else if (type == 'page') {
        if (this.listSubtag.page == null || this.listSubtag.page.length == 0)
          this.listSubtag.page = []

        this.subtagArrayChipsPage.push(event.option.value);
        this.listSubtag.page.push({ id: null, obj: event.option.value });
      }
      else if (type == 'internal') {
        if (this.listSubtag.internal == null || this.listSubtag.internal.length == 0)
          this.listSubtag.internal = []

        this.subtagArrayChipsInternal.push(event.option.value);
        this.listSubtag.internal.push({ id: null, obj: event.option.value });
      }
    }

    console.log(this.listSubtag);

    this.establishmentInputSubtag.nativeElement.value = '';
    this.subtagChipsFormControl.setValue(null);
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  checkLink(link, type) {

    if (type == null) return link;
    if (link == null) return null;

    if (link != null) {
      if (link.substring(0, 3) == 'tel') {
        var link = link.split(':')

        if (type == 'link') return link[1]
        else if (type == 'exem') return this.option_link[2].exem
        else return this.option_link[2].link

      }
      else if (link.substring(0, 6) == 'mailto') {
        var link = link.split(':')

        if (type == 'link') return link[1]
        else if (type == 'exem') return this.option_link[3].exem
        else return this.option_link[3].link
      }
      else if (link.substring(0, 14) == 'https://wa.me/') {
        var link = link.split('me/')

        if (type == 'link') return link[1]
        else if (type == 'exem') return this.option_link[1].exem
        else return this.option_link[1].link
      }
      else {
        if (type == 'link') return link
        else if (type == 'exem') return this.option_link[0].exem
        else return this.option_link[0].link
      }
    }

  }

  selectType(item, type_page) {

    this.select_anterior = this.homepage?.images[this.selected_homepage_image]?.type?.name;

    var obj_aux = {
      id: null,
      type: null,
      quantity: null,
      link: null,
      option_link: null,
      placeholder: null,
      catalog: null,
      coupon: null,
      registration_config: null,
      background_image: null,
      images: [],
      order: null,
      title: null,
      point_control_name: null,
      description: null,
      name: null,
      url: null,
      survey: null,
      video: null,
      level: null,
      save: false,
      course: null,
      castbox: null,
      deezer: null,
      itunes: null,
      google_podcast: null,
      home_page_image_type: 'EMPTY',
      course_obj: {
        id: '',
        title: '',
        workload_in_minutes: '',
        description: '',
      },
      notice: null,
      point_type: 'EMPTY'
    };

    var auxObj = Object.assign({}, obj_aux)

    if (this.select_anterior != null &&
      (this.select_anterior != 'Banner' && this.select_anterior != 'Mini Banner' && this.select_anterior != 'Galeria')) {

      if (type_page == 'page') { }
      // this.homepage.images[this.selected_homepage_image].images = [];
      else if (type_page == 'internal')
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];

      this.select_anterior = item.name;
    }

    if (type_page == 'homepage') {
      // console.log(this.homepage);

      this.select_type_anterior = this.homepage?.type

      this.selected_homepage_type = item;
      this.homepage.type = item;
      if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Vídeo" && item.name != "Post" && item.name != "Podcast" && item.name != "Cursos" && item.name != "Ponto" && item.name != "Notícia") {
        this.homepage.images = [];
        this.homepage.background_image = null;
        this.homepage.catalog = null;
        this.homepage.coupon = null;
        this.homepage.registration_config = null;
        this.homepage.survey = null;
        this.homepage.link = null;
      }
      else if (item.name == "Vídeo") {
        var aux_image = this.homepage.images[0]
        this.homepage.images[0] = obj_aux;

        this.homepage.background_image = { url: aux_image?.url != null ? aux_image.url : null };
        this.homepage.type.id = 4;
      }
      else if (item.name == "Post") {
        this.homepage.images[0] = obj_aux;
      }
      else if (item.name == "Ponto") {
        // this.homepage.images = []

        if (this.homepage.images == null || this.homepage.images.length == 0) {
          this.homepage.images.push(obj_aux)
          this.homepage.images.push(auxObj)

          this.homepage.images[0].point_type = "ENTRANCE";
          this.homepage.images[1].point_type = "EXIT";
        }
        else if (this.homepage.images != null && this.homepage.images.length == 1) {
          this.homepage.images.push(obj_aux)
          this.homepage.images[0].point_type = "ENTRANCE";
          this.homepage.images[1].point_type = "EXIT";
        }
        else {
          this.homepage.images.forEach((home, index) => {
            if (index == 0 && home.point_type == 'EXIT') {
              this.homepage.images.reverse();
            }
          });
        }
      }
      else if (item.name == "Notícia") {
        this.homepage.images = []
        this.homepage.images.push(obj_aux)
      }
    }
    else if (type_page == 'page') {

      this.homepage.images[this.selected_homepage_image].type = item;
      if (item.name == "Vazio") {
        if (this.homepage.images[this.selected_homepage_image].images != null &&
          this.homepage.images[this.selected_homepage_image].images.length > 0) {

          this.homepage.images[this.selected_homepage_image].images.forEach(element => {
            this.remove.page.push(element)
          });
        }

      }
      else if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Post" && item.name != "Podcast" && item.name != "Cursos" && item.name != "Ponto") {
        // this.homepage.images[this.selected_homepage_image].images = [];
        this.homepage.images[this.selected_homepage_image].background_image = null;
        this.homepage.images[this.selected_homepage_image].catalog = null;
        this.homepage.images[this.selected_homepage_image].coupon = null;
        this.homepage.images[this.selected_homepage_image].registration_config = null;
        this.homepage.images[this.selected_homepage_image].survey = null;
        this.homepage.images[this.selected_homepage_image].link = null;
        this.homepage.images[this.selected_homepage_image].level = null;
      }
      else if (item.name == "Banner" &&
        this.homepage.images[this.selected_homepage_image].images != null &&
        this.homepage.images[this.selected_homepage_image].images.length > 1) {

        var aux_image = this.homepage.images[this.selected_homepage_image].images[0]
        this.homepage.images[this.selected_homepage_image].images = [];
        this.homepage.images[this.selected_homepage_image].images[0] = aux_image;

        this.homepage.images[this.selected_homepage_image].images.forEach((element, index) => {
          if (index != 0) this.remove.page.push(element)
        });
      }
      else if ((item.name == "Banner" || item.name == "Mini Banner" || item.name == "Galeria") &&
        (this.homepage?.images[this.selected_homepage_image]?.images != null && this.homepage?.images[this.selected_homepage_image]?.images.length > 0)) {
        this.homepage.images[this.selected_homepage_image].images.forEach((element, index) => {
          if (element.url == null && element?.background_image?.url == null) {
            this.remove.page.push(element)
            this.homepage.images[this.selected_homepage_image].images.splice(index, 1)
          }
        });
      }
      if (item.name == "Post" || item.name == "Podcast" || item.name == "Cursos") {
        this.homepage.images[this.selected_homepage_image].images = []
        this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
      }
      if (item.name == "Ponto") {
        // this.homepage.images = []

        if (this.homepage?.images[this.selected_homepage_image]?.images == null || this.homepage.images[this.selected_homepage_image].images.length == 0) {

          this.homepage.images[this.selected_homepage_image].images = []

          this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
          this.homepage.images[this.selected_homepage_image].images.push(auxObj)

          this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
          this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
        }
        else if (this.homepage.images[this.selected_homepage_image] != null && this.homepage.images[this.selected_homepage_image].images.length == 1) {
          this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
          this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
          this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
        }
        else {
          this.homepage.images[this.selected_homepage_image].images.forEach((home, index) => {
            if (index == 0 && home.point_type == 'EXIT') {
              this.homepage.images[this.selected_homepage_image].images.reverse();
            }
          });
        }
      }
    }
    else if (type_page == 'internal') {

      this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].type = item;
      if (item.name != "Vazio") {
        if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null &&
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 0) {

          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach(element => {
            // this.remove.internal.push(element)
          });
        }

      }
      else if (item.name != "Banner" && item.name != "Mini Banner" && item.name != "Galeria" && item.name != "Post" && item.name != "Podcast" && item.name != "Cursos" && item.name != "Ponto") {
        // this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].background_image = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].catalog = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].coupon = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].registration_config = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].survey = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = null;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].level = null;
      }
      else if (item.name == "Banner" &&
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null &&
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length > 1) {

        var aux_image = this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0]
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = [];
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0] = aux_image;
      }
      else if ((item.name == "Banner" || item.name == "Mini Banner" || item.name == "Galeria") &&
        (this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images != null &&
          this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images.length > 0)) {
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach((element, index) => {
          if (element.url == null && element.background_image.url == null) {
            this.remove.internal.push(element)
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(index, 1)
          }
        });
      }
      if (item.name == "Post" || item.name == "Podcast" || item.name == "Cursos") {
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
      }
      if (item.name == "Ponto") {
        // this.homepage.images = []

        if (this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images == null || this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 0) {
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []

          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)

          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
        }
        else if (this.homepage?.images[this.selected_homepage_image]?.images[this.selected_page_image]?.images != null && this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 1) {
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
        }
        else {
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach((internal, index) => {

            if (index == 0 && internal.point_type == 'EXIT') {
              this.homepage.images[this.selected_homepage_image].images.reverse();
            }
          });
        }
      }
    }

  }

  nextStep() {
    var obj_aux = {
      id: null,
      name: null,
      url: null,
      quantity: null,
      type: null,
      link: null,
      option_link: null,
      placeholder: null,
      catalog: null,
      survey: null,
      coupon: null,
      registration_config: null,
      background_image: null,
      images: [],
      order: null,
      title: null,
      point_control_name: null,
      description: null,
      video: null,
      level: null,
      save: false,
      course: null,
      course_obj: null,
      notice: null,
      tabloide: null,
      castbox: null,
      deezer: null,
      itunes: null,
      google_podcast: null,
      point_type: 'EMPTY'
    }

    var auxObj = Object.assign({}, obj_aux)

    switch (this.actual_step) {

      case 'homepage':

        if (this.checkInputs('type_home', null)) {
          if (this.selected_homepage_type != null) {
            if (this.post_id == null) {
              // console.log('NEW POST')
              this.createPost()
            }
            else {
              // console.log('EDIT POST')
              this.editPost()
            }
            this.steps.homepage = true;
            this.homepage.type = this.selected_homepage_type
            this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
            this.actual_step = "homepage_images";
          }
        }

        if (this.homepage.type.name == "Cursos") {
          this.homepage.images.push(auxObj)
        }

        if (this.homepage.type.name == "Tabloide") {
          this.homepage.images.push(auxObj)
        }

        if (this.homepage.type.name == 'Ponto') {

          this.homepage.images.push(obj_aux)
          this.homepage.images.push(auxObj)

          if (this.homepage.images.length > 2) {
            this.homepage.images.splice(2)
          }

          this.homepage.images[0].point_type = "ENTRANCE";
          this.homepage.images[1].point_type = "EXIT";
        }

        break;
      case 'homepage_images':

        if (this.checkInputs('select_imagens_home', null)) {
          this.checkHomepageImages()
        }
        if (this.selected_homepage_type.name == "Ponto") {
          if (this.homepage?.images[this.selected_homepage_image]?.images == null || this.homepage.images[this.selected_homepage_image].images.length == 0) {
            this.homepage.images[this.selected_homepage_image].images = []

            this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
            this.homepage.images[this.selected_homepage_image].images.push(auxObj)

            this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
            this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
          }
          else if (this.homepage.images[this.selected_homepage_image].images != null && this.homepage.images[this.selected_homepage_image].images.length == 1) {
            this.homepage.images[this.selected_homepage_image].images.push(obj_aux)
            this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
            this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
          }
          else {
            this.homepage.images[this.selected_homepage_image].images.forEach((internal, index) => {

              if (index == 0 && internal.point_type == 'EXIT') {
                this.homepage.images[this.selected_homepage_image].images.reverse();
              }
            });
          }
        }


        break;
      case 'page_types':

        if (this.selected_homepage_type.name == "Ponto") {

          if (this.homepage?.images[this.selected_homepage_image]?.images == null || this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 0) {
            this.homepage.images[this.selected_homepage_image].images = []

            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(auxObj)

            this.homepage.images[this.selected_homepage_image].images[0].point_type = "ENTRANCE";
            this.homepage.images[this.selected_homepage_image].images[1].point_type = "EXIT";
          }
          else if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images != null && this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.length == 1) {
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(obj_aux)
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].point_type = "ENTRANCE";
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[1].point_type = "EXIT";
          }
          else {
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.forEach((internal, index) => {

              if (index == 0 && internal.point_type == 'EXIT') {
                this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.reverse();
              }
            });
          }
        }
        break;
    }

  }

  checkInputs(text, position) {
    this.warningMap = new Map();

    if (text == 'type_home') {
      if (this.name_post == "" || this.name_post == null) {
        this.warningMap.set('name_post', true);
      }

      if (this.selected_homepage_type == "" || this.selected_homepage_type == null) {
        this.warningMap.set('type_home', true);
      }
    }
    else if (text == 'select_imagens_home') {
      if (this.homepage.images == null || this.homepage.images.length <= 0) {
        this.warningMap.set('select_imagens_home', true);
      }
    }
    else if (text == 'select_type_page') {
      if (this.homepage.images == null || this.homepage.images.length <= 0) {
        this.warningMap.set('select_type_page', true);
      }
    }

    if (this.warningMap.size == 0) {
      return true
    }
    else {
      return false
    }
  }

  getImagesQuantity(item): number {

    if (item?.name == "Banner")
      return 1;
    else if (item?.name == "Mini Banner")
      return 20;
    else if (item?.name == "Galeria")
      return 20;
    else if (item?.name == "Post")
      return 1;
    else if (item.name == "Ponto")
      return 2;
    else
      return null;
  }

  checkHomepageImages() {
    this.warningMap = new Map();
    if (this.homepage.quantity == null) {
      if (this.homepage.link == null || this.homepage.link == "") {
        this.warningMap.set('homepage_link', true);
      }
      if (this.homepage.background_image == null || this.homepage.background_image.url == "") {
        this.warningMap.set('homepage_background', true);
      }
    }
    else if (this.homepage.images.length > this.homepage.quantity) {
      this.warningMap.set('homepage_images', true);
    }

    if (this.warningMap.size === 0) {
      this.steps.homepage_images = true
      this.actual_step = this.homepage.quantity == null ? null : 'page_types'
    }
  }

  nameImage(text) {
    if (text == null)
      return ''
    else {
      var list;
      if (text.name != null)
        return text.name
      else if (text.url != null) {
        list = text.url.split('/')
        return list[list.length - 1]
      }
      else
        return ''
    }
  }

  newLevel() {
    // var type_aux = {
    //     description: null,
    //     id: 6,
    //     image: "https://sbs-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
    //     max_images: 0,
    //     min_images: 0,
    //     name: "Vazio"
    // }

    // this.homepage.images[this.selected_homepage_image].type = type_aux;
    this.homepage.images[this.selected_homepage_image].level = true;
    this.view_level_page = true;

    // console.log(this.homepage.images[this.selected_homepage_image])
  }

  drop(event: CdkDragDrop<any>, array, type) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);

    if (type == 'homepage') {
      this.homepage.images = []
      array.forEach((element, index) => {
        element.order = index + 1
        this.homepage.images.push(element)
      });
    }
    else if (type == 'page') {
      this.homepage.images[this.selected_homepage_image].images = []
      array.forEach((element, index) => {
        element.order = index + 1
        this.homepage.images[this.selected_homepage_image].images.push(element)
      });
    }
    else if (type == 'internal') {
      // this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images = []
      // array.forEach((element, index) => {
      //     element.order = index +1
      //     this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.push(element)
      // });
    }

  }

  removeImage(posi, type) {
    if (type == 'home') {
      if (this.homepage.images[posi].id != null)
        this.remove.homepage.push(this.homepage.images[posi])

      this.homepage.images.splice(posi, 1);
    }
    else if (type == 'page') {
      if (this.homepage.images[this.selected_homepage_image].images[posi] != null)
        this.homepage.images[this.selected_homepage_image].images[posi] != null;
      this.remove.page.push(this.homepage.images[this.selected_homepage_image].images[posi])

      this.homepage.images[this.selected_homepage_image].images.splice(posi, 1);
    }
    else if (type == 'internal') {
      if (this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi] != null)
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi] != null;
      this.remove.page.push(this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[posi])

      this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images.splice(posi, 1);
    }

  }

  getValieLink(item, type) {

    if (type == 'home') {
      if (item.name == 'Relatório') {
        this.option_select_link = item;
        this.homepage.images[this.selected_homepage_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
      }
      else {
        this.option_select_link = item;
      }
    }
    else if (type == 'page') {
      if (item.name == 'Relatório') {
        this.option_select_link = item;
        this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].link = `sbs.tools/?establishment${localStorage.getItem("id_establishment")}`;
      }
      else {
        this.option_select_link = item;
      }
    }
  }

  createLink(type, link, option, obj) {
    // console.log(obj)

    if (type?.name == 'Link')
      return option + link
    else if (type?.name == 'Post') {
      if (obj?.images != null && obj?.images[0]?.link != null)
        return obj.images[0].link
      else
        return link
    }
    else
      return link

  }

  deletImageCkeckIn(type) {
    if (type == 'chckin') {
      this.homepage.images[0]['name'] = '';
      this.homepage.images[0].url = '';
    }
    else {
      this.homepage.images[1]['name'] = '';
      this.homepage.images[1].url = '';
    }
  }

  async addImageCkeck(event, type_check, type_obj, index_home, index_page) {

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])
        reader.onloadend = async (e) => {
          var final_file = reader.result

          if (type_obj == 'home') {
            if (type_check == 'checkin') {
              this.homepage.images[0]['name'] = filename;
              this.homepage.images[0].url = final_file;
            }
            else {
              this.homepage.images[1]['name'] = filename;
              this.homepage.images[1].url = final_file;
            }
          }
          else if (type_obj == 'page') {
            if (type_check == 'checkin') {
              this.homepage.images[index_home].images[0]['name'] = filename;
              this.homepage.images[index_home].images[0].url = final_file;
            }
            else {
              this.homepage.images[index_home].images[1]['name'] = filename;
              this.homepage.images[index_home].images[1].url = final_file;
            }
          }
          else if (type_obj == 'internal') {
            if (type_check == 'checkin') {
              this.homepage.images[index_home].images[index_page].images[0]['name'] = filename;
              this.homepage.images[index_home].images[index_page].images[0].url = final_file;
            }
            else {
              this.homepage.images[index_home].images[index_page].images[1]['name'] = filename;
              this.homepage.images[index_home].images[index_page].images[1].url = final_file;
            }
          }
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            if (type_obj == 'home') {
              if (type_check == 'checkin') {
                this.homepage.images[0]['name'] = this.compressed_image[0].name;
                this.homepage.images[0].url = this.compressed_image[0].url;
              }
              else {
                this.homepage.images[1]['name'] = this.compressed_image[0].name;
                this.homepage.images[1].url = this.compressed_image[0].url;
              }
            }
            else if (type_obj == 'page') {
              if (type_check == 'checkin') {
                this.homepage.images[index_home].images[0]['name'] = this.compressed_image[0].name;;
                this.homepage.images[index_home].images[0].url = this.compressed_image[0].url;;
              }
              else {
                this.homepage.images[index_home].images[1]['name'] = this.compressed_image[0].name;;
                this.homepage.images[index_home].images[1].url = this.compressed_image[0].url;;
              }
            }
            else if (type_obj == 'internal') {
              if (type_check == 'checkin') {
                this.homepage.images[index_home].images[index_page].images[0]['name'] = this.compressed_image[0].name;;
                this.homepage.images[index_home].images[index_page].images[0].url = this.compressed_image[0].url;;
              }
              else {
                this.homepage.images[index_home].images[index_page].images[1]['name'] = this.compressed_image[0].name;;
                this.homepage.images[index_home].images[index_page].images[1].url = this.compressed_image[0].url;;
              }
            }

            this.compressed_image = []
          }
        });
      }
    }

    // console.log(this.image_logo)
  }

  async editImage(event, position_home, position_page, position_internal, type) {

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])

        // subscribe file reader read event
        reader.onloadend = async (e) => {
          var final_file = reader.result

          if (type == 'home') {
            this.homepage.images[position_home].name = filename;
            this.homepage.images[position_home].url = final_file;
          }
          else if (type == 'internal') {
            this.homepage.images[position_home].images[position_page].images[position_internal].name = filename
            this.homepage.images[position_home].images[position_page].images[position_internal].url = final_file
          }

          this.compressed_image = []
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            if (type == 'home') {
              this.homepage.images[position_home].name = this.compressed_image[0].name
              this.homepage.images[position_home].url = this.compressed_image[0].url
            }
            else if (type == 'internal') {
              this.homepage.images[position_home].images[position_page].images[position_internal].name = this.compressed_image[0].name
              this.homepage.images[position_home].images[position_page].images[position_internal].url = this.compressed_image[0].url
            }

            this.compressed_image = []
          }
        });

        // console.log(compress);

      }

    }

  }

  async onFileChangedGif(event, type, index = null) {

    var aux_type = {
      description: null, id: 6, image: "https://qrcontent-api.s3-sa-east-1.amazonaws.com/media/images/smartphone06_42flas4.png",
      max_images: 0, min_images: 0, name: "Vazio"
    }

    var aux_id = null

    if (event.target.files && event.target.files[0]) {
      const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

      if (!event.target.files[0].name.includes(valid[0])) {
        return
      }

      var file = event.target.files[0];
      var filename = file.name;
      var target = event.target.files;

      var reader = new FileReader()
      reader.readAsDataURL(target[0])

      // subscribe file reader read event
      reader.onloadend = async (e) => {
        var final_file = reader.result
        this.compressed_image.push({ id: aux_id, url: final_file, name: filename, quantity: null, type: aux_type, link: null, images: [{ url: null, title: '', description: '', link: '' }] })

        let obj = null
        if (index != null) {
          obj = this.homepage.images[index]
        }

        let now_homepage = this.homepage.images.length
        let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

        if (type == 'homepage_bg') {
          if (this.selected_homepage_type.name == 'Vídeo')
            this.homepage.images[this.selected_homepage_image].background_image = this.compressed_image[0]
          else
            this.homepage.background_image = this.compressed_image[0]
        }
        else if (type == 'homepage_post') {
          this.homepage.url = this.compressed_image[0].url
        }
        else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
          this.compressed_image.forEach(el => {
            this.homepage.images.push(el)
          })
        }
        else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
          if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
          this.compressed_image.forEach(el => {
            this.homepage.images[index].images.push(el)
          })
        }
        else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
          this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
        }
        else if (type == 'page_bg') {
          obj.background_image = this.compressed_image[0]
        }
        else if (type == 'internalpage_bg') {
          this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
        }
        else if (type == 'internal_image') {
          let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images
          if (obj2 == null)
            this.homepage.images[this.selected_homepage_image].images[index].images = []

          if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
            this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
            this.compressed_image.forEach(el => {
              this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
            })
          }
        }
        else if (type == 'post_page') {
          this.homepage.images[this.selected_homepage_image].images[0].url = this.compressed_image[0]?.url
        }
        else if (type == 'post_internal') {
          this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
        }

        this.compressed_image = []
      }

    }

  }

  async onFileChanged(event, type, index = null) {

    if (event.target.files[0].type == 'image/gif') {
      this.onFileChangedGif(event, type, index)
    }
    else {
      this.onFileChangedImg(event, type, index)
    }
  }

  async onFileChangedImg(event, type, index = null) {

    if (event.target.files && event.target.files[0]) {
      var target = event.target.files
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        }),
      );
      compress.subscribe(res => {

        if (res.index > res.array.length - 1) {

          let obj = null
          if (index != null) {
            obj = this.homepage.images[index]
          }

          let now_homepage = this.homepage.images.length

          let quantity = index != null ? this.getImagesQuantity(obj?.type) : 0

          if (type == 'homepage_bg') {
            if (this.selected_homepage_type.name == 'Vídeo')
              this.homepage.images[this.selected_homepage_image].background_image = this.compressed_image[0]
            else
              this.homepage.background_image = this.compressed_image[0]
          }
          else if (type == 'homepage_post') {
            this.homepage.images[this.selected_homepage_image].url = this.compressed_image[0].url
          }
          else if (type == 'pages' && (this.compressed_image.length + now_homepage) <= this.homepage.quantity) {
            this.compressed_image.forEach(el => {
              this.homepage.images.push(el)
            })
          }
          else if (type == 'homepage_image' && (obj.images == null || (this.compressed_image.length + obj.images.length) <= obj.type.max_images)) {
            if (this.homepage.images[index].images == null) this.homepage.images[index].images = []
            this.compressed_image.forEach(el => {
              this.homepage.images[index].images.push(el)
            })
          }
          else if (type == 'homepage_image' && obj?.type?.max_images == 1 && obj?.images?.url == null) {
            this.homepage.images[index].images[0].url = this.compressed_image[0]?.url
          }
          else if (type == 'page_bg') {
            obj.background_image = this.compressed_image[0]
          }
          else if (type == 'internalpage_bg') {
            this.homepage.images[this.selected_homepage_image].images[index].background_image.url = this.compressed_image[0].url;
          }
          else if (type == 'internal_image') {
            let obj2 = this.homepage.images[this.selected_homepage_image].images[index].images
            if (obj2 == null)
              this.homepage.images[this.selected_homepage_image].images[index].images = []

            if ((this.homepage.images[this.selected_homepage_image].images[index].images.length + this.compressed_image.length) <=
              this.homepage.images[this.selected_homepage_image].images[index].type.max_images) {
              this.compressed_image.forEach(el => {
                this.homepage.images[this.selected_homepage_image].images[index].images.push(el)
              })
            }
          }
          else if (type == 'post_page') {
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image] = this.compressed_image[0]
          }
          else if (type == 'post_internal') {
            this.homepage.images[this.selected_homepage_image].images[this.selected_page_image].images[0].url = this.compressed_image[0]?.url
          }

          this.compressed_image = []
        }
      });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        if (this.remove.homepage.length >= 1) {
          // this.compressed_image.push(this.remove.homepage[index].url)
          var aux_id = this.remove.homepage[index].id != null ? this.remove.homepage[index].id : null
          this.compressed_image.push({ id: aux_id, url: response, name: array[index].name, quantity: null, type: null, link: null, })
        }
        else
          this.compressed_image.push({ url: response, name: array[index].name, quantity: null, type: null, link: null, })
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );

  }

  selectImagePage(i) {
    this.selected_homepage_image = i;
  }

  finish() {
    if (!this.loading.finish) {
      this.loading.finish = true;

      this.savePost()
    }
  }

  postCourse(page, type, index, index2, index3) {
    // console.log(page, type)

    if (type == 'home' && this.homepage?.images[index]?.type?.name == 'Cursos') {
      this.courseService.postCourse(page.course_obj).subscribe(data => {
        // console.log(data)
        this.homepage.images[index].course_obj.id = data.id;
      })
    }
    else if (type == 'page' && this.homepage?.images[index]?.images[index2]?.type?.name == 'Cursos') {
      this.courseService.postCourse(page.course_obj).subscribe(data => {
        // console.log(data)
        this.homepage.images[index].images[index2].course_obj.id = data.id;
      })
    }
    else if (type == 'intenal' && this.homepage?.images[index]?.images[index2]?.images[index3]?.type?.name == 'Cursos') {
      this.courseService.postCourse(page.course_obj).subscribe(data => {
        // console.log(data)
        this.homepage.images[index].images[index2].images[index3].course_obj.id = data.id;
      })
    }
  }

  pacthCourse(page, type) {
    // console.log(page, type)

    if (page.course_obj.id != null && page.course_obj != '') {
      if (type == 'home') {
        this.courseService.editCourse(page.course_obj).subscribe(data => {
          // console.log(data)
        })
      }
      else if (type == 'page') {
        this.courseService.editCourse(page.course_obj).subscribe(data => {
          // console.log(data)
        })
      }
      else if (type == 'intenal') {
        this.courseService.editCourse(page.course_obj).subscribe(data => {
          // console.log(data)
        })
      }
    }
  }

  deleteTagsList() {
    // console.log(this.deliteTag)

    this.deliteTag.homepage.forEach(tag => {
      if (tag.id != null) {
        this.deleteTag('homepage', tag.id)
      }
    });

    this.deliteTag.page.forEach(tag => {
      if (tag.id != null) {
        this.deleteTag('page', tag.id)
      }
    });

    this.deliteTag.internal.forEach(tag => {
      if (tag.id != null) {
        this.deleteTag('internal', tag.id)
      }
    });
  }

  createPost() {
    this.campaignService.createPost(this.campaign_id, this.name_post, this.selected_homepage_type.id).subscribe(data => {
      this.post_id = data.id;
      this.post_obj = data;
    })
  }

  editPost() {
    this.campaignService.editPost(this.post_id, this.campaign_id, this.name_post, this.selected_homepage_type.id).subscribe(data => {
      this.post_id = data.id;
      this.post_obj = data;
    })
  }

  openGerateLinkFile() {
    console.log('import file');

    this.modalImport = this.modalService.show(PopupGenerateFileLinkComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      containerClass: '',
      animated: false,
      data: {
        color_primary: this.color_primary
      }
    })
    // this.modalImport.content.action.subscribe((result: any) => {
    //   if (result == 'yes') {

    //   }
    // });
  }

  savePost() {
    this.deleteTagsList()

    if (this.homepage.type.name == 'Vídeo' && (this.homepage.images != null || this.homepage.background_image != null)) {

      this.homepage.images.forEach((item, index_loop) => {
        if (index_loop != 0) this.remove.homepage.push(item)
      });

      if (this.homepage.images == null || this.homepage.images.length == 0 || this.homepage.images[0].id == null) {

        // console.log('New Home', 0)
        this.campaignService.postHomepageImages(this.homepage.background_image?.url, this.campaign_id, this.post_id, 1,
          4, null, null, null, '', this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY', null, null, null, null, null).subscribe(data => {

            var home_id = data.id;
            this.listTag.home.forEach(tag => {
              if (tag?.id == null) {
                console.log(tag);
                this.crieteTag('homepage', home_id, tag.obj.id, null)
              }
            });

            this.listSubtag.home.forEach(subtag => {
              if (subtag?.id == null) {
                console.log(subtag);
                this.crieteTag('homepage', home_id, null, subtag.obj.id)
              }
            });

            var link;
            if (this.homepage.link != null)
              link = this.homepage.link;
            else if (this.homepage?.images[0]?.link != null)
              link = this.homepage?.images[0]?.link;
            else if (this.homepage.images[0]?.images[0].link != null)
              link = this.homepage.images[0]?.images[0].link

            if (this.homepage?.id != null && (this.homepage.images != null || this.homepage.images[this.selected_homepage_image].background_image != null)) {
              // console.log('Edit Page', this.homepage?.images[0]?.images[0]?.id)
              this.campaignService.patchPageImages(this.homepage?.images[0]?.id, this.post_id, this.homepage.background_image?.url, 1, data.id,
                4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course, null, null, null, null, null).subscribe(data => {

                  var page_id = data.id;
                  this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                      console.log(tag);
                      this.crieteTag('page', page_id, tag.obj.id, null)
                    }
                  });

                  this.listSubtag.page.forEach(sub => {
                    if (sub?.id == null) {
                      console.log(sub);
                      this.crieteTag('page', page_id, null, sub.obj.id)
                    }
                  });
                });
            }
            else {
              // console.log('New Page', 0)
              this.campaignService.postPageImages(this.homepage.background_image?.url, this.post_id, 1, data.id,
                4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course, null, null, null, null, null).subscribe(data => {

                  var page_id = data.id;
                  this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                      console.log(tag);
                      this.crieteTag('page', page_id, tag.obj.id, null)
                    }
                  });

                  this.listSubtag.page.forEach(sub => {
                    if (sub?.id == null) {
                      console.log(sub);
                      this.crieteTag('page', page_id, null, sub.obj.id)
                    }
                  });

                });
            }

          });
      }
      else {
        // console.log('Edit Home', this.homepage.images[0].id)

        this.campaignService.patchHomepageImages(this.homepage.images[0].id, this.post_id, this.homepage.images[this.selected_homepage_image].background_image?.url, 1,
          4, null, null, '', this.homepage?.images[this.selected_homepage_image]?.course, this.homepage?.images[this.selected_homepage_image]?.notice, 'EMPTY',
          this.homepage?.images[this.selected_homepage_image]?.castbox, this.homepage?.images[this.selected_homepage_image]?.deezer, this.homepage?.images[this.selected_homepage_image]?.itunes,
          this.homepage?.images[this.selected_homepage_image]?.google_podcast, this.homepage?.images[this.selected_homepage_image]?.tabloide).subscribe(data => {

            this.listTag.home.forEach(tag => {
              if (tag?.id == null) {
                console.log(tag);
                this.crieteTag('homepage', data.id, tag.obj.id, null)
              }
            });

            this.listSubtag.home.forEach(sub => {
              if (sub?.id == null) {
                console.log(sub);
                this.crieteTag('homepage', data.id, null, sub.obj.id)
              }
            });

            var link;
            if (this.homepage.link != null)
              link = this.homepage.link;
            else if (this.homepage?.images[0]?.link != null)
              link = this.homepage?.images[0]?.link;
            else if (this.homepage.images[0]?.images[0].link != null)
              link = this.homepage.images[0]?.images[0].link

            if (this.homepage?.images != null && this.homepage?.images[0]?.images != null && this.homepage?.images[0]?.images[0]?.id != null) {
              // console.log('Edit Page', this.homepage?.images[0]?.images[0]?.id)

              this.campaignService.patchPageImages(this.homepage?.images[0]?.images[0]?.id, this.post_id, this.homepage.background_image?.url, 1, data.id,
                4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.images[0]?.course,
                this.homepage?.images[0]?.images[0]?.castbox, this.homepage?.images[0]?.images[0]?.deezer, this.homepage?.images[0]?.images[0]?.itunes, 
                this.homepage?.images[0]?.images[0]?.google_podcast, this.homepage?.images[0]?.images[0]?.token_hashlar).subscribe(data => {

                  var page_id = data.id;
                  this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                      console.log(tag);
                      this.crieteTag('page', page_id, tag.obj.id, null)
                    }
                  });

                  this.listSubtag.page.forEach(sub => {
                    if (sub?.id == null) {
                      console.log(sub);
                      this.crieteTag('page', page_id, null, sub.obj.id)
                    }
                  });
                });
            }
            else {
              // console.log('New Page', 0)

              this.campaignService.postPageImages(this.homepage.background_image?.url, this.post_id, 1, data.id,
                4, link, null, null, null, null, null, null, null, 'EMPTY', this.homepage?.images[0]?.course,
                this.homepage?.images[0]?.images[0]?.castbox, this.homepage?.images[0]?.images[0]?.deezer, this.homepage?.images[0]?.images[0]?.itunes, 
                this.homepage?.images[0]?.images[0]?.google_podcast, this.homepage?.images[0]?.images[0]?.token_hashlar).subscribe(data => {

                  var page_id = data.id;
                  this.listTag.page.forEach(tag => {
                    if (tag?.id == null) {
                      console.log(tag);
                      this.crieteTag('page', page_id, tag.obj.id, null)
                    }
                  });

                  this.listSubtag.page.forEach(sub => {
                    if (sub?.id == null) {
                      console.log(sub);
                      this.crieteTag('page', page_id, null, sub.obj.id)
                    }
                  });
                });
            }

          });
      }

    }
    else if (this.homepage.type.name == 'Post') {
      // console.log(this.homepage)

      this.homepage.images.forEach((item, index_loop) => {
        if (index_loop != 0) this.remove.homepage.push(item)
      });

      if (this.homepage?.images[0]?.images != null && this.homepage?.images[0]?.images.length > 0) {
        this.homepage.images[0].images.forEach(item => {
          this.remove.page.push(item)
        });
      }

      // console.log(this.remove)

      if (this.homepage.images == null || this.homepage.images.length == 0 || this.homepage.images[0].id == null) {

        // console.log('New Home', this.homepage.images[0])
        this.campaignService.postHomepageImages(this.homepage.images[0]?.url, this.campaign_id, this.post_id, 1,
          4, this.homepage.images[0].title, null, this.homepage.images[0].description, this.homepage.images[0].link, this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
          this.homepage.images[0].castbox, this.homepage.images[0].deezer, this.homepage.images[0].itunes, this.homepage.images[0].google_podcast, this.homepage.images[0].tabloide).subscribe(data => {

            var home_id = data.id;
            this.listTag.home.forEach(tag => {
              if (tag?.id == null) {
                console.log(tag);
                this.crieteTag('homepage', home_id, tag.obj.id, null)
              }
            });

            this.listSubtag.home.forEach(sub => {
              if (sub?.id == null) {
                console.log(sub);
                this.crieteTag('homepage', home_id, null, sub.obj.id)
              }
            });
          });
      }
      else {
        // console.log('Edit Home', this.homepage.images[0])

        this.campaignService.patchHomepageImages(this.homepage.images[0].id, this.post_id, this.homepage.images[this.selected_homepage_image]?.url, 1,
          4, this.homepage.images[this.selected_homepage_image].title, this.homepage.images[this.selected_homepage_image].description,
          this.homepage.images[this.selected_homepage_image].link, this.homepage?.images[this.selected_homepage_image]?.course, this.homepage?.images[this.selected_homepage_image]?.notice, 'EMPTY',
          this.homepage?.images[this.selected_homepage_image]?.castbox, this.homepage?.images[this.selected_homepage_image]?.deezer, this.homepage?.images[this.selected_homepage_image]?.itunes,
          this.homepage?.images[this.selected_homepage_image]?.google_podcast, this.homepage?.images[this.selected_homepage_image]?.tabloide).subscribe(data => {

            var home_id = data.id;
            this.listTag.home.forEach(tag => {
              if (tag?.id == null) {
                console.log(tag);
                this.crieteTag('homepage', home_id, tag.obj.id, null)
              }
            });

            this.listSubtag.home.forEach(sub => {
              if (sub?.id == null) {
                console.log(sub);
                this.crieteTag('homepage', home_id, null, sub.obj.id)
              }
            });
          });
      }

    }
    else {
      this.sendHomepage(this.homepage.images, 0)
    }
    setTimeout(() => {
      this.loading.finish = false;
      this.deletes()
    }, 500);
  }

  sendHomepage(homepage_list, index) {

    if (homepage_list[index] == null) {
      this.loading.finish = false;
      // console.log('\nFINISH\n')
    }
    else {
      if (homepage_list[index]?.type?.name == 'Vídeo') var image = homepage_list[index]?.background_image?.url;
      else var image = homepage_list[index]?.url;

      if (homepage_list[index]?.id == null) {

        if (this.homepage.type.name == 'Ponto') {
          // console.log('-> Ponto <-')

          this.campaignService.postHomepageImages(homepage_list[index]?.url, this.campaign_id, this.post_id, index + 1,
            (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
            homepage_list[index]?.title, null, homepage_list[index]?.description, homepage_list[index]?.link, null, null, homepage_list[index]?.home_page_image_type,
            homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.tabloide).subscribe(data => {

              this.sendHomepage(homepage_list, index + 1)
            })
        }
        else {

          this.campaignService.postHomepageImages(image, this.campaign_id, this.post_id, 1,
            (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6), null, null, null, null, homepage_list[index]?.course, homepage_list[index]?.notice, 'EMPTY',
            homepage_list[index]?.castbox, homepage_list[index]?.deezer, homepage_list[index]?.itunes, homepage_list[index]?.google_podcast, homepage_list[index]?.tabloide).subscribe(data => {
              this.homepage_id = data.id;

              var home_id = data.id;
              this.listTag.home.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('homepage', home_id, tag.obj.id, null)
                }
              });

              this.listSubtag.home.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('homepage', home_id, null, sub.obj.id)
                }
              });

              if (homepage_list[index]?.type?.name == 'Catálogo' || homepage_list[index]?.type?.name == 'Enquete' || homepage_list[index]?.type?.name == 'Podcast' ||
                homepage_list[index]?.type?.name == 'Link' || homepage_list[index]?.type?.name == 'Cupom' || homepage_list[index]?.type?.name == 'Vídeo' || homepage_list[index]?.type?.name == 'Post') {

                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                  this.patchPageImages(homepage_list[index].images[0]?.id, homepage_list[index]?.url, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast,
                    homepage_list[index].token_hashlar)
                }
                else {
                  this.postPageImages(image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast,
                    homepage_list[index].token_hashlar)

                }

              }
              else if (homepage_list[index]?.type?.name == 'Cadastro' && (homepage_list[index].level == null || !homepage_list[index].level)) {

                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                  this.patchPageImages(homepage_list[index].images[0]?.id, homepage_list[index]?.url, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast,
                    homepage_list[index].token_hashlar)
                }
                else {
                  this.postPageImages(image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast,
                    homepage_list[index].token_hashlar)

                }

              }
              else {
                this.sendPage(homepage_list, index, homepage_list[index].images, 0)
              }
            });
        }

      }
      else {
        if (this.homepage.type.name == 'Ponto') {
          // console.log('-> Ponto <-')

          this.campaignService.patchHomepageImages(homepage_list[index].id, this.post_id, homepage_list[index]?.url, index + 1,
            (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
            this.homepage.images[index].title, this.homepage.images[index].description, this.homepage.images[index].link, this.homepage.images[index].course, this.homepage.images[index].notice, homepage_list[index]?.home_page_image_type,
            this.homepage.images[index].castbox, this.homepage.images[index].deezer, this.homepage.images[index].itunes, this.homepage.images[index].google_podcast, this.homepage.images[index].tabloide).subscribe(data => {

              this.sendHomepage(homepage_list, index + 1)
            })
        }
        else {

          // console.log('Edit Home', homepage_list[index].id)
          this.campaignService.patchHomepageImages(homepage_list[index].id, this.post_id, homepage_list[index]?.url, index + 1,
            (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
            this.homepage.images[0].title, this.homepage.images[0].description, this.homepage.images[0].link, this.homepage?.images[0]?.course, this.homepage?.images[0]?.notice, 'EMPTY',
            this.homepage.images[index].castbox, this.homepage.images[index].deezer, this.homepage.images[index].itunes, this.homepage.images[index].google_podcast, this.homepage.images[index].tabloide).subscribe(data => {
              this.homepage_id = data.id;

              var home_id = data.id;
              this.listTag.home.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('homepage', home_id, tag.obj.id, null)
                }
              });

              this.listSubtag.home.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('homepage', home_id, null, sub.obj.id)
                }
              });

              if (homepage_list[index]?.type?.name == 'Catálogo' || homepage_list[index]?.type?.name == 'Enquete' || homepage_list[index]?.type?.name == 'Podcast' ||
                homepage_list[index]?.type?.name == 'Link' || homepage_list[index]?.type?.name == 'Cupom' || homepage_list[index]?.type?.name == 'Vídeo' || homepage_list[index]?.type?.name == 'Post') {

                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                  this.patchPageImages(homepage_list[index].images[0]?.id, image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)
                }
                else {
                  this.postPageImages(image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                }

              }
              else if (homepage_list[index]?.type?.name == 'Cadastro' && (homepage_list[index].level == null || !homepage_list[index].level)) {

                if (homepage_list[index].images != null && homepage_list[index].images.length > 0 && homepage_list[index].images[0]?.id != null) {

                  this.patchPageImages(homepage_list[index].images[0]?.id, image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)
                }
                else {
                  this.postPageImages(image, index + 1, this.homepage_id,
                    (homepage_list[index]?.type != null ? homepage_list[index]?.type?.id : 6),
                    this.createLink(homepage_list[index].type, homepage_list[index].link, homepage_list[index].option_link, homepage_list[index]),
                    homepage_list[index]?.catalog, homepage_list[index]?.survey,
                    homepage_list[index]?.registration_config,
                    homepage_list[index]?.coupon, homepage_list[index]?.title, homepage_list[0].point_control_name, homepage_list[index]?.description,
                    homepage_list, index, homepage_list[index].images, homepage_list[index].point_type, homepage_list[index].course, index,
                    homepage_list[index].castbox, homepage_list[index].deezer, homepage_list[index].itunes, homepage_list[index].google_podcast, homepage_list[index].token_hashlar)

                }

              }
              else {
                this.sendPage(homepage_list, index, homepage_list[index].images, 0)
              }
            });
        }
      }

    }

  }

  sendPage(homepage_list, homepage_index, page_list, index) {

    if (page_list == null || page_list[index] == null) {
      this.sendHomepage(homepage_list, homepage_index + 1)
    }
    else {
      // if(page_list[index]?.type?.name == 'Vídeo') var image_homepage = page_list[index]?.background_image?.url;
      // // else if(page_list[index]?.type?.name == 'Post') var image_homepage = page_list[index].images[0]?.url;
      // else var image_homepage = page_list[index]?.url;

      var image_homepage = page_list[index]?.url;

      if (homepage_list[homepage_index]?.type?.name == 'Cadastro') {
        if (page_list[index]?.id == null) {
          // console.log('New Page', page_list[index])
          this.campaignService.postPageImages(image_homepage, this.post_id, index + 1, this.homepage_id,
            (page_list[index]?.type != null ? page_list[index]?.type?.id : 6),
            this.createLink(page_list[index].type, page_list[index].link, page_list[index].option_link, page_list[index]),
            page_list[index]?.catalog, page_list[index]?.survey, homepage_list[homepage_index]?.registration_config,
            page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
            page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

              var page_id = data.id;
              this.listTag.page.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('page', page_id, tag.obj.id, null)
                }
              });

              this.listSubtag.page.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('page', page_id, null, sub.obj.id)
                }
              });

              this.page_id = data.id;
              this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
            })

        }
        else {
          // console.log('Edit Page', page_list[index])
          this.campaignService.patchPageImages(page_list[index].id, this.post_id, image_homepage, index + 1, this.homepage_id,
            (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), page_list[index]?.link,
            page_list[index]?.catalog, page_list[index]?.survey, homepage_list[homepage_index]?.registration_config,
            page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
            page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

              var page_id = data.id;
              this.listTag.page.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('page', page_id, tag.obj.id, null)
                }
              });

              this.listSubtag.page.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('page', page_id, null, sub.obj.id)
                }
              });

              this.page_id = data.id;
              this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);

            })

        }
      }
      else {
        if (page_list[index]?.id == null) {
          // console.log('New Page', page_list[index])
          this.campaignService.postPageImages(image_homepage, this.post_id, index + 1, this.homepage_id,
            (page_list[index]?.type != null ? page_list[index]?.type?.id : 6),
            this.createLink(page_list[index].type, page_list[index].link, page_list[index].option_link, page_list[index]),
            page_list[index]?.catalog, page_list[index]?.survey, page_list[index]?.registration_config,
            page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
            page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

              var page_id = data.id;
              this.listTag.page.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('page', page_id, tag.obj.id, null)
                }
              });

              this.listSubtag.page.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('page', page_id, null, sub.obj.id)
                }
              });

              this.page_id = data.id;
              this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);
            })

        }
        else {
          // console.log('Edit Page', page_list[index])
          this.campaignService.patchPageImages(page_list[index].id, this.post_id, image_homepage, index + 1, this.homepage_id,
            (page_list[index]?.type != null ? page_list[index]?.type?.id : 6), page_list[index]?.link,
            page_list[index]?.catalog, page_list[index]?.survey, page_list[index]?.registration_config,
            page_list[index]?.coupon, page_list[index]?.title, page_list[0]?.point_control_name, page_list[index]?.description, page_list[index]?.point_type, page_list[index]?.course,
            page_list[index]?.castbox, page_list[index]?.deezer, page_list[index]?.itunes, page_list[index]?.google_podcast, page_list[index]?.token_hashlar).subscribe(data => {

              var page_id = data.id;
              this.listTag.page.forEach(tag => {
                if (tag?.id == null) {
                  console.log(tag);
                  this.crieteTag('page', page_id, tag.obj.id, null)
                }
              });

              this.listSubtag.page.forEach(sub => {
                if (sub?.id == null) {
                  console.log(sub);
                  this.crieteTag('page', page_id, null, sub.obj.id)
                }
              });

              this.page_id = data.id;
              this.sendInternal(homepage_list, homepage_index, page_list, index, page_list[index].images, 0);

            })

        }
      }

    }
  }

  sendInternal(homepage_list, homepage_index, page_list, page_index, internal_list, index) {

    if (internal_list == null || internal_list[index] == null) {
      this.sendPage(homepage_list, homepage_index,
        page_list, page_index + 1)
    }
    else {
      if (page_list[index]?.type?.name == 'Vídeo') var image_page = page_list[index]?.background_image?.url;
      else var image_page = internal_list[index]?.url;

      if (internal_list[index]?.id == null) {

        this.postInternalPageImages(image_page, index + 1, this.page_id,
          (internal_list[index]?.type != null ? internal_list[index]?.type?.id : 6),
          internal_list[index]?.link, internal_list[index]?.catalog, internal_list[index]?.survey,
          internal_list[index]?.registration_config, internal_list[index]?.coupun, internal_list[index]?.title, internal_list[0]?.point_control_name,
          internal_list[index]?.description, homepage_list, homepage_index, page_list, page_index, internal_list, internal_list[index]?.point_type, internal_list[index]?.course, index,
          internal_list[index]?.castbox, internal_list[index]?.deezer, internal_list[index]?.itunes, internal_list[index]?.google_podcast, internal_list[index]?.token_hashlar)
      }
      else {

        this.patchInternalPageImages(internal_list[index]?.id, image_page, index + 1, this.page_id,
          (internal_list[index]?.type != null ? internal_list[index]?.type?.id : 6),
          internal_list[index]?.link, internal_list[index]?.catalog, internal_list[index]?.survey,
          internal_list[index]?.registration_config, internal_list[index]?.coupun, internal_list[index]?.title, internal_list[0]?.point_control_name,
          internal_list[index]?.description, homepage_list, homepage_index, page_list, page_index, internal_list, internal_list[index]?.point_type, internal_list[index]?.course, index,
          internal_list[index]?.castbox, internal_list[index]?.deezer, internal_list[index]?.itunes, internal_list[index]?.google_podcast, internal_list[index]?.token_hashlar)
      }

    }

  }

  postPageImages(image, order, homepage_id, type_id, link, catalog, survey, registration_config, coupun,
    title, point_control_name, description, homepage_list, homepage_index, page_list, point_type, course, index,
    castbox, deezer, itunes, google_podcast,token_hashlar) {

    this.campaignService.postPageImages(image, this.post_id, order, homepage_id,
      type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
      castbox, deezer, itunes, google_podcast,token_hashlar).subscribe(data => {

        var page_id = data.id;
        this.listTag.page.forEach(tag => {
          if (tag?.id == null) {
            console.log(tag);
            this.crieteTag('page', page_id, tag.obj.id, null)
          }
        });

        this.listSubtag.page.forEach(sub => {
          if (sub?.id == null) {
            console.log(sub);
            this.crieteTag('page', page_id, null, sub.obj.id)
          }
        });

        this.sendPage(homepage_list, homepage_index, page_list, index + 1)

      }, error => {
        this.loading.finish = false;
      })
  }

  patchPageImages(id, image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
    title, point_control_name, description, homepage_list, homepage_index, page_list, point_type, course, index,
    castbox, deezer, itunes, google_podcast,token_hashlar) {
    // console.log('Edit Page', page_list[index])

    this.campaignService.patchPageImages(id, this.post_id, image, order, page_id,
      type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
      castbox, deezer, itunes, google_podcast,token_hashlar).subscribe(data => {

        var page_id = data.id;
        this.listTag.page.forEach(tag => {
          if (tag?.id == null) {
            console.log(tag);
            this.crieteTag('page', page_id, tag.obj.id, null)
          }
        });

        this.listSubtag.page.forEach(sub => {
          if (sub?.id == null) {
            console.log(sub);
            this.crieteTag('page', page_id, null, sub.obj.id)
          }
        });


        this.sendPage(homepage_list, homepage_index, page_list, index + 1)

      }, error => {
        this.loading.finish = false;
      })
  }

  postInternalPageImages(image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
    title, point_control_name, description, homepage_list, homepage_index, page_list, page_index, internal_list, point_type, course, index,
    castbox, deezer, itunes, google_podcast, token_hashlar) {

    this.campaignService.postInternalPageImages(image, this.post_id, order, page_id,
      type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
      castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

        var internal_id = data.id;
        this.listTag.internal.forEach(tag => {
          if (tag?.id == null) {
            console.log(tag);
            this.crieteTag('internal', internal_id, tag.obj.id, null)
          }
        });

        this.listSubtag.internal.forEach(sub => {
          if (sub?.id == null) {
            console.log(sub);
            this.crieteTag('internal', internal_id, null, sub.obj.id)
          }
        });

        this.sendInternal(homepage_list, homepage_index, page_list, page_index,
          internal_list, index + 1)

      }, error => {
        this.loading.finish = false;
      })
  }

  patchInternalPageImages(id, image, order, page_id, type_id, link, catalog, survey, registration_config, coupun,
    title, point_control_name, description, homepage_list, homepage_index, page_list, page_index, internal_list, point_type, course, index,
    castbox, deezer, itunes, google_podcast, token_hashlar) {

    this.campaignService.patchInternalPageImages(id, this.post_id,
      (internal_list[index].type.name == 'Post' ? internal_list[index].url : image), order, page_id,
      type_id, link, catalog, survey, registration_config, coupun, title, point_control_name, description, point_type, course,
      castbox, deezer, itunes, google_podcast, token_hashlar).subscribe(data => {

        var internal_id = data.id;
        this.listTag.internal.forEach(tag => {
          if (tag?.id == null) {
            console.log(tag);
            this.crieteTag('internal', internal_id, tag.obj.id, null)
          }
        });

        this.listSubtag.internal.forEach(sub => {
          if (sub?.id == null) {
            console.log(sub);
            this.crieteTag('internal', internal_id, null, sub.obj.id)
          }
        });

        this.sendInternal(homepage_list, homepage_index, page_list, page_index,
          internal_list, index + 1)

      }, error => {
        this.loading.finish = false;
      })
  }

  deletes() {
    // console.log(this.remove)

    if (this.remove.homepage.length > 0) {
      this.remove.homepage.forEach(el => {
        // console.log('Delet home',el)
        if (el.id != null) this.deleteHomepageImage(el.id)
      });
    }

    if (this.remove.page.length > 0) {
      this.remove.page.forEach(el => {
        // console.log('Delet page',el)
        if (el.id != null) this.deletePageImage(el.id)
      });
    }

    if (this.remove.internal.length > 0) {
      this.remove.internal.forEach(el => {
        // console.log('Delet inter',el)
        if (el.id != null) this.deleteInternalImage(el.id)
      });
    }

    setTimeout(() => {
      this.loading.finish = false;
      // console.log('\nDELET - FINISH\n')
      this.router.navigate([`/application/content/content-feed/${this.campaign_id}`])

    }, 100);
  }

  // DELETE
  deleteHomepageImage(id) {
    if (id != null) {
      // console.log('Delet Home', id)

      this.campaignService.deleteHomepageImage(id).subscribe(data => {
        // console.log('DELET')
      }, error => {
        this.loading.finish = false;
      })
    }
  }

  deletePageImage(id) {
    if (id != null) {
      // console.log('Delet Page', id)

      this.campaignService.deletePageImage(id).subscribe(data => {
      }, error => {
        this.loading.finish = false;
      })
    }
  }

  deleteInternalImage(id) {
    if (id != null) {
      // console.log('Delet Internal', id)

      this.campaignService.deleteInternalImage(id).subscribe(data => {
      }, error => {
        this.loading.finish = false;
      })
    }
  }

  crieteTag(typeTag, id, tag, id_sub) {
    switch (typeTag) {
      case 'homepage':
        console.log(typeTag, id, tag);

        this.tagService.postTagHomepage(id, null, null, this.campaign_id, tag, id_sub).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })
        break;

      case 'page':
        console.log(typeTag, id, tag);

        this.tagService.postTagHomepage(null, id, null, this.campaign_id, tag, id_sub).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })

        // this.tagService.postTagPage(id, tag).subscribe(data => {
        //     // console.log('Page', data)
        // }, error => { console.log(error) })
        break;

      case 'internal':
        console.log(typeTag, id, tag);

        this.tagService.postTagHomepage(null, null, id, this.campaign_id, tag, id_sub).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })

        // this.tagService.postTagInternalPage(id, tag).subscribe(data => {
        //     // console.log('InternalPage', data)
        // }, error => { console.log(error) })
        break;
    }
  }

  deleteTag(typeTag, id) {
    switch (typeTag) {
      case 'homepage':
        this.tagService.deleteTagHomepage(id).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })
        break;

      case 'page':
        this.tagService.deleteTagHomepage(id).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })
        // this.tagService.deleteTagPage(id).subscribe(data => {
        //     // console.log('Page', data)
        // }, error => { console.log(error) })
        break;

      case 'internal':
        this.tagService.deleteTagHomepage(id).subscribe(data => {
          // console.log('Homepage', data)
        }, error => { console.log(error) })
        // this.tagService.deleteTagInternalpage(id).subscribe(data => {
        //     // console.log('InternalPage', data)
        // }, error => { console.log(error) })
        break;
    }
  }

  backPage() {
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}`])
  }

  getTag(typeTag, id_page, page) {
    switch (typeTag) {
      case 'homepage':
        this.tagService.getTagHomepage(id_page, null, null, page).subscribe(data => {
          // console.log('homepage:', id_page, ' - ', data)
          this.list_tag_campaign.homepage = this.list_tag_campaign.homepage.concat(data.results)

        }, error => { console.log(error) })
        break;

      case 'page':
        this.tagService.getTagHomepage(null, id_page, null, page).subscribe(data => {
          // console.log('page:', id_page, ' - ', data)
          this.list_tag_campaign.page = this.list_tag_campaign.page.concat(data.results)

        }, error => { console.log(error) })
        break;

      case 'internal':
        this.tagService.getTagHomepage(null, null, id_page, page).subscribe(data => {
          // console.log('internal:', id_page, ' - ', data)
          this.list_tag_campaign.internal = this.list_tag_campaign.internal.concat(data.results)

        }, error => { console.log(error) })
        break;
    }
  }

  alertType() {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        title: 'Mudança de tipo',
        text: 'Esse tipo só aceita no máximo uma imagem.',
        has_button: true,
        button_text: 'Continuar',
        button_cancel: 'Cancelar'
      }
    })
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.steps.homepage = true;
        this.homepage.type = this.selected_homepage_type
        this.homepage.quantity = this.getImagesQuantity(this.selected_homepage_type);
        this.actual_step = "homepage_images";
      }
    });
  }

}
