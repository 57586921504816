import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith, expand } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { DesktopService } from 'src/app/service/desktop.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-detail-sidebar',
  templateUrl: './detail-sidebar.component.html',
  styleUrls: ['./detail-sidebar.component.css']
})
export class DetailSidebarComponent implements OnInit {

  constructor(
    private compressor: CompressorService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private desktopService: DesktopService
  ) {
    this.campaign_id = this.route.snapshot.paramMap.get('id');
    this.sidebar_id = this.route.snapshot.paramMap.get('id_sidebar');
  }

  loading: boolean = false;

  warningMap = new Map();
  steps = { siderbar: false };
  color_primary: string = '';
  campaign_id;
  sidebar_id;

  type_list = [{ id: 0, name: 'Banner', send: 'BANNER' }, { id: 1, name: 'Galeria', send: 'GALLERY' }, { id: 2, name: 'Carrosel', send: 'CAROUSEL' }, { id: 3, name: 'Podcast', send: 'PODCAST' }];
  type_select: any;

  side_list = [{ name: 'Esquerda', send: 'LEFT' }, { name: 'Direita', send: 'RIGHT' }, { name: 'Centro', send: 'CENTER' }];
  side_select: any;

  compressed_image = [];
  validTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']

  desktop = {
    id: null,
    title: '',
    campaign: '',
    type: '',
    side: '',
    icon: null,
    icon_name: '',
    link: '',
    link_text: '',
    imagens: []
  }

  image = {
    id: null,
    name: '',
    image: null,
    order: 0,
    title: '',
    campaign_item: '',
    description: '',
    link: '',
    link_text: '',
    spotify: '',
    deezer: '',
    itunes: '',
    castbox: '',
    google_podcast: ''
  }

  selected_image: number = 0; //var for index of image selected

  remove_imagens = []

  ngOnInit(): void {
    this.desktop.campaign = this.campaign_id;

    this.color_primary = this.storageService.establishment.color;

    if (this.sidebar_id != null && this.sidebar_id != '')
      this.getDesktop()
  }

  getDesktop() {
    this.loading = true;
    this.desktopService.getDesktopId(this.sidebar_id).subscribe(data => {
      // console.log(data);

      let image_aux = []
      image_aux.push(this.image)

      this.desktop.campaign = data.campaign;
      this.desktop.icon = data.icon;
      this.desktop.id = data.id;
      this.desktop.imagens = data.images != null ? data.images : image_aux;
      this.desktop.link = data.link;
      this.desktop.link_text = data.link_text;
      this.desktop.title = data.title;
      this.desktop.side = data.side;
      this.desktop.type = data.type;

      this.type_select = (data.type == 'BANNER' ? this.type_list[0].name :
        (data.type == 'GALLERY' ? this.type_list[1].name :
          (data.type == 'CAROUSEL' ? this.type_list[2].name :
            (data.type == 'PODCAST' ? this.type_list[3].name : null))));

      this.side_select = (data.side == 'LEFT' ? this.side_list[0].name :
        (data.side == 'RIGHT' ? this.side_list[1].name : this.side_list[2].name));

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    })
  }

  drop(event: CdkDragDrop<any>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);

    this.desktop.imagens = []
    array.forEach((element, index) => {
      element.order = index + 1
      this.desktop.imagens.push(element)
    });
  }

  selectType(obj) {
    this.type_select = obj.name;
    this.desktop.type = obj.send;

    if (this.desktop?.imagens == null || this.desktop.imagens.length == 0)
      this.desktop.imagens.push(this.image)
  }

  selectSide(obj) {
    this.side_select = obj.name;
    this.desktop.side = obj.send;
  }

  removeImage(posi) {
    if (this.desktop.imagens[posi].id != null) {
      this.remove_imagens.push(this.desktop.imagens[posi])
    }
    this.desktop.imagens.splice(posi, 1);

    if (posi <= this.selected_image)
      this.selected_image--;

    if (this.selected_image < 0) this.selected_image = 0;
  }

  nameImage(obj) {
    if (obj == null)
      return ''
    else {
      var list;
      if (obj.name != null)
        return obj.name
      else if (obj.image != null) {
        list = obj.image.split('/')
        return list[list.length - 1]
      }
      else
        return ''
    }
  }

  selectImagePage(i) {
    this.selected_image = i;
  }

  deletLogo() {
    this.desktop.icon_name = '';
    this.desktop.icon = '';
  }


  setInfoSizeImage(type){
    if(type == 'Galeria'){
      return 'Tamanho 375px por 460px ( 9/11 )'
    }
    if(type == 'Carrosel'){
      return 'Tamanho 800px por 620px ( 9/7 )'
    }
    if(type == 'Icone'){
      return 'Tamanho 500px por 500px ( 1/1 )'
    }
    if(type == 'Image'){
      return 'Tamanho 820px por 455px ( 9/5 )'
    }
  }

  async addLogo(event) {

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])

        // subscribe file reader read event
        reader.onloadend = async (e) => {
          var final_file = reader.result

          this.desktop.icon = final_file;

          this.compressed_image = []
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompressLogo(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompressLogo(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            this.desktop.icon = this.compressed_image[0]
            this.compressed_image = []

          }
        });
      }
    }
  }

  recursiveCompressLogo = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }

  async onFileChanged(event) {

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])
        reader.onloadend = async (e) => {
          var final_file = reader.result

          this.image.name = filename
          this.image.image = final_file
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            if (this.type_select == 'Banner' || this.type_select == 'Podcast') {
              // console.log('Banner')

              this.desktop.imagens[0].image = this.compressed_image[0].image;
              this.desktop.imagens[0].name = this.compressed_image[0].name;
              this.compressed_image = []
            }
            else {
              // console.log('outros')

              if (this.compressed_image.length <= 7) {
                this.compressed_image.forEach((element, index) => {
                  // console.log(element)
                  this.desktop.imagens.push(element)
                });
              }

              // console.log(this.compressed_image)

              // this.desktop.imagens.push(this.compressed_image)

              this.compressed_image = []
            }
          }
        });

        // console.log(this.desktop.imagens)
      }
    }
  }

  async editImage(event, position_home, position_page, position_internal, type) {
    // console.log(position_home)

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])

        // subscribe file reader read event
        reader.onloadend = async (e) => {
          var final_file = reader.result

          this.desktop.imagens[position_home].name = filename;
          this.desktop.imagens[position_home].url = final_file;

          this.compressed_image = []
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            if (this.type_select == 'Banner' || this.type_select == 'Podcast') {
              // console.log('Banner')

              this.desktop.imagens[0].image = this.compressed_image[0].image;
              this.desktop.imagens[0].name = this.compressed_image[0].name;
              this.compressed_image = []
            }
            else {
              // console.log('outros')

              this.desktop.imagens[position_home].image = this.compressed_image[0].image;
              this.desktop.imagens[position_home].name = this.compressed_image[0].name;

              this.compressed_image = []
            }
          }
        });

        // console.log(compress);

      }

    }

  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {

        this.image.name = array[index].name;
        this.image.image = response;
        this.image.order = this.compressed_image.length;
        this.image.title = '';
        this.image.description = '';
        this.image.link = '';
        this.image.link_text = '';

        this.compressed_image.push(this.image)

        this.image = {
          id: null,
          name: '',
          image: null,
          order: 0,
          title: '',
          campaign_item: '',
          description: '',
          link: '',
          link_text: '',
          spotify: '',
          deezer: '',
          itunes: '',
          castbox: '',
          google_podcast: ''
        }

        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );

  }

  close() {
    this.loading = false;
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}`])
  }

  saveSidebar() {
    this.warningMap = new Map();

    if (this.desktop.title == null || this.desktop.title == "") {
      this.warningMap.set('title', true);
      console.log(1);
    }
    if (this.type_select != 'Banner') {
      if (this.desktop.icon == null || this.desktop.icon == "") {
        this.warningMap.set('image_icon', true);
        console.log(3);
      }
    }
    if (this.side_select == null || this.side_select == "") {
      this.warningMap.set('side', true);
      console.log(4);
    }
    if ((this.desktop.link_text != null && this.desktop.link_text != "") && this.type_select != 'Podcast' && this.type_select != 'Galeria' && (this.desktop.link == null || this.desktop.link == "")) {
      this.warningMap.set('link', true);
      console.log(5);
    }
    if (this.type_select == null || this.type_select == "") {
      this.warningMap.set('type', true);
      console.log(6);
    }

    if (this.type_select != 'Podcast') {
      this.desktop.imagens.forEach((el, index) => {
        if (el.image == null || el.image == "") {
          this.warningMap.set('imagen' + index, true);
          console.log(7);
        }
        // if (this.side_select != 'Centro' && (this.type_select != 'Banner' && this.type_select != 'Galeria' && this.type_select != 'Carrosel' && (el.text == null || el.text == ""))) {
        //   this.warningMap.set('description' + index, true);
        //   console.log(8);
        // }
      });
    }

    console.log(this.warningMap.size == 0);

    if (this.warningMap.size == 0) {

      this.loading = true;

      this.remove_imagens.forEach(el => {
        this.desktopService.deleteDesktopImage(el).subscribe(data => {
        })
      });

      if (this.sidebar_id == null) {
        this.newDesktop()
      }
      else {
        this.editDesktop()
      }
    }
  }

  newDesktop() {
    this.desktopService.postDesktop(this.desktop, this.campaign_id).subscribe(data => {

      this.desktop.imagens.forEach(image => {
        this.newDesktopImage(image, data.id)
      });
    }, error => {
      this.loading = false;
    })
  }

  editDesktop() {
    // console.log(this.desktop);

    this.desktopService.patchDesktop(this.desktop, this.campaign_id).subscribe(data => {

      this.desktop.imagens.forEach(image => {
        if (image.id != null) this.editDesktopImage(image)
        else this.newDesktopImage(image, data.id)
      });
    }, error => {
      this.loading = false;
    })
  }

  newDesktopImage(image, idDesktop) {
    this.desktopService.postDesktopImage(image, idDesktop).subscribe(data => {
      this.close()
    }, error => {
      this.loading = false;
    })
  }

  editDesktopImage(image) {
    this.desktopService.patchDesktopImage(image).subscribe(data => {
      this.close()
    }, error => {
      this.loading = false;
    })
  }

  autoResize(el) {
    console.log(el);

    while (el.scrollHeight > el.offsetHeight) {
      el.rows += 1;
    }
  }

}
