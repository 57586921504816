<div class="modal-content">

    <!-- <svg (click)="close()" class="icons_close"
        width="25px" height="25px" viewBox="0 0 24 24" [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path [style.fill]="color_primary" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
    </svg> -->

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> Você pulou o fluxo, volte e leia o QR Code anterior. </b>
        </h1>

    </div>

    <!-- <div style="width: 100%; display: flex; justify-content: space-evenly; 
        align-items: center; text-align: center; margin-bottom: 15px;">
        
        <button mat-button class="main-button button" [style.background]="color_primary" (click)="close()"> 
            Fechar
        </button> 

    </div> -->

</div>