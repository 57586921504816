<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title" [style.color]="color_primary">
            <b [style.color]="color_primary"> Detalhes do Pedido </b>
        </h1>
    </div>

    <div class="modal-body">
        <div class="card_order">
            <h4> Novo status do pedido </h4>

            <mat-form-field class="card_select" style="padding: 0 !important; border: 1px solid transparent; margin-top: -14px;">
                <mat-select class="select-box" [(ngModel)]="filterStatus">
                    <mat-option *ngFor="let item of list_status;" [value]="item.id" (click)="filterTable(item)">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="card_order">
            <h4> Mensagem para enviar por email </h4>

            <textarea class="input" style="width: 100%; resize: none; border-color: gray;" placeholder="Digite a descrição" 
                [(ngModel)]="send_message"></textarea>
        </div>
       
    </div>

    <div style="width: 70%; margin: 0 auto; display: flex; justify-content: space-evenly; align-items: center;">
        <button mat-button [style.boderColor]="color_primary" [style.color]="color_primary" class="b_close m_20" (click)="close()">
            Fechar
        </button>

        <button mat-button [style.background]="color_primary" class="b_save m_20" (click)="saveEdit()">
            Salvar
            <span *ngIf="loading" class="spinner-border spinner-border-sm m_left" role="status" aria-hidden="true"></span>
        </button>
    </div>

</div>