import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-gas-easels',
  templateUrl: './gas-easels.component.html',
  styleUrls: ['./gas-easels.component.css']
})
export class GasEaselsComponent implements OnInit {

  table_1: string;
  table_2: string;
  header: [];
  footer: [];
  list: [];
  title: string;
  local: string

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.table_2, 'table_2');
    console.log(this.header, 'header');
    console.log(this.table_1, 'footer');
    
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }


}
