<div class="screen" *ngIf="!loading">

    <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; align-self: flex-start;">
        <div class="coll-left">
            <h3 style="margin: 0 0 12px 0;"> Produto </h3>

            <input mat-input [(ngModel)]="product.name" placeholder="Escreva aqui o nome do Produto"
                [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">

            <input mat-input [(ngModel)]="product.price" placeholder="Digite o preço do Produto"
                [ngStyle]="{'border-color': warningMap.get('price')? 'red' : ''}" class="input"
                mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ ">
    
            <textarea mat-input [(ngModel)]="product.description" placeholder="Escreva aqui a descrição" style="resize: none;"
                [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}" class="input"></textarea>

            <textarea mat-input [(ngModel)]="product.ingredients" placeholder="Escreva os ingredientes" style="resize: none;"
                [ngStyle]="{'border-color': warningMap.get('ingredients')? 'red' : ''}" class="input"></textarea>
        
            <div style="width: 100%; margin: 0;">
                <h3 style="margin-top: 20px;"> Produtos parecidos </h3>

                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <input mat-input [(ngModel)]="crossSell" placeholder="Escreva aqui o nome do ingrediente"
                        style="width: calc(100% - 110px);" class="input" [matAutocomplete]="searchbarCrossSell" autocomplete="off"
                        [ngStyle]="{'border-color': warningMap.get('crossSell')? 'red' : ''}" (ngModelChange)="filterCrossSell(crossSell)">
                
                    <mat-autocomplete #searchbarCrossSell="matAutocomplete">
                        <mat-option *ngFor="let item of list_products_filter" (click)="selectCrossSell(item)" [value]="item.name">
                            {{item.name}} 
                        </mat-option>
                    </mat-autocomplete>

                    <button mat-button class="btn_add button-cor" [style.background]="color_primary" 
                        style="width: auto; height: 50px; margin: auto 0 auto 10px; border-radius: 10px; color: white;"
                        (click)="addCrossSell(crossSell)"> Adicionar </button>
                </div>
            
                <div class="card_list custom-scrollbar" style="height: 250px;">
                    <div *ngIf="product.crossSell == null || product.crossSell.length <= 0"
                        style="width: 100%; text-align: center; font-size: 12px; margin: auto;">
                        Nenhum ingrediente adicionado.
                    </div>
                    <div *ngFor="let item of product.crossSell; let i = index"
                        style="margin: 5px; display: flex; justify-content: space-between; align-items: center;">
                        {{ item.name }}
    
                        <svg class="icons" viewBox="0 0 512.000000 512.000000" (click)="removeCrossSell(i)" >
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c71c2c" stroke="none">
                                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>  
                        
        </div>

    
        <div class="coll-right">
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                <h3> Estoque inicial 
                    <span style="font-size: 10px; font-weight: 500;"> {{ product.initial_inventory ? '(Ativado)' : '(Desativado)' }} </span> </h3>
                <mat-slide-toggle [(ngModel)]="product.initial_inventory"></mat-slide-toggle>
            </div>

            <input mat-input [(ngModel)]="product.initial_price" placeholder="Digite o preço inicial" [disabled]="!product.initial_inventory"
                [ngStyle]="{'border-color': warningMap.get('initial_price')? 'red' : ''}" class="input"
                mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ ">

            <input mat-input type="number" [(ngModel)]="product.initial_quantity" placeholder="Digite a quantidade inicial"
                [ngStyle]="{'border-color': warningMap.get('initial_quantity')? 'red' : ''}" class="input" [disabled]="!product.initial_inventory">



            <h3 style="margin-top: 20px;"> Imagens </h3>
            <div class="card_border">
                <div class="center" [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}">
                    <div cdkDropList class="div-drop custom-scrollbar-x" cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event, product.midia)">
                        <div *ngFor="let midia of product.midia; let i = index" cdkDrag
                            style="position: relative; display: flex; flex-direction: column; margin: 0 5px; padding: 5px; border: 1px solid #e9edef;">
    
                            <img src="/assets/icon/close.png" (click)="removeImage(i, 'home')" *ngIf="midia.image != null" class="icon_remove">
                            <img [src]="midia.image" class="drop_image" *ngIf="midia.image != null">
                            <!-- <div class="ellipsis-one" *ngIf="midia.image != null"> {{ midia.image }} </div> -->
                        </div>
                    </div>
    
                    <button mat-button class="button-cor" [style.background]="color_primary" (click)="filePages.click()" *ngIf="product?.midia == null || product?.midia.length < 5"
                        style="width: auto; height: 36px; margin: auto 0 auto 10px; color: white;"> Adicionar imagens </button>
                    <input #filePages type="file" accept="image/*" (change)="onFileChanged($event, 'product')" multiple="multiple" style="display: none;">
                </div>
            </div>

                        
            <!-- Categorias -->
            <div style="width: 100%; margin: 0;">
                <h3 style="margin-top: 30px;"> Categorias </h3>

                <div style="display: flex; justify-content: space-between;">
                    <input class="input" matInput placeholder="{{ (category == null || category == '') ? 'Selecione as categorias' : '' }}" 
                        [(ngModel)]="category" style="width: calc(100% - 120px);" [matAutocomplete]="searchbarCategory" autocomplete="off"
                        [ngStyle]="{'border-color': warningMap.get('category')? 'red' : ''}" (ngModelChange)="filterCategory(category)">
                
                    <button mat-button class="button-cor" [style.background]="color_primary" (click)="addCategory()"
                        style="width: auto; height: 50px; margin: auto 0 auto 10px; border-radius: 10px; color: white;"> Adicionar </button>
                </div>
                        
                <mat-autocomplete #searchbarCategory="matAutocomplete">
                    <mat-option *ngFor="let item of list_categories_filter" [value]="item.name" (click)="selectCategory(item)">
                        {{item.name}} 
                    </mat-option>
                </mat-autocomplete>

                <div class="card_list custom-scrollbar">
                    <div *ngIf="product.categories == null || product.categories.length <=0 "
                        style="width: 100%; text-align: center; font-size: 12px; margin: auto;">
                        Nenhum categoria selecionada.
                    </div>
                    <div *ngFor="let item of product.categories; let i = index"
                        style="margin: 5px; display: flex; justify-content: space-between; align-items: center;">
                        {{ item.name }}

                        <svg class="icons" viewBox="0 0 512.000000 512.000000" (click)="removeCategory(i)" >
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c71c2c" stroke="none">
                                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>  
        </div>


        
        <!-- Information Nutritional -->
        <!-- <h3 style="margin-top: 30px;"> Informações Nutricionais </h3>
        <div class="ingredients" style="flex-wrap: wrap;"> 
            
            <input mat-input [(ngModel)]="nutritional.information.name" placeholder="Escreva aqui o nome" style="width: calc(100% - 120px);" 
                [ngStyle]="{'border-color': warningMap.get('information')? 'red' : ''}" class="input">

            <button mat-button class="btn_add button-cor" [style.background]="color_primary" 
                (click)="addNutricional()"> Adicionar </button>

            <input mat-input type="number" [(ngModel)]="nutritional.information.porcent" placeholder="Porcentagem"
                [ngStyle]="{'border-color': warningMap.get('information')? 'red' : ''}" class="input w-30">

            <input mat-input type="number" [(ngModel)]="nutritional.information.valor" placeholder="Valor nutricional"
                [ngStyle]="{'border-color': warningMap.get('information')? 'red' : ''}" class="input w-30">

            <input class="input w-30" matInput [matAutocomplete]="searchbarMesurement" autocomplete="off"
                [placeholder]="(measurement == null || measurement == '') ? 'Medidas' : ''" 
                [ngStyle]="{'border-color': warningMap.get('information')? 'red' : ''}"
                [(ngModel)]="measurement" (ngModelChange)="filterMeasurement(measurement)">
                    
            <mat-autocomplete #searchbarMesurement="matAutocomplete">
                <mat-option *ngFor="let item of list_measurement_unit" [value]="item.name">
                    {{item.name}} 
                </mat-option>
            </mat-autocomplete>

            <input #filePages type="file" accept="image/*" (change)="onFileChanged($event, 'product')" multiple="multiple" style="display: none;">
        </div>

        <div class="card_border" style="width: 100%; padding: 10px;">
            <table mat-table [dataSource]="dataSource2" style="width: 100%;">
        
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Descrição </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.description }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="porcent">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Porcentagem </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.porcent }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Valor </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.value }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="medida">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Medida </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.medida }} </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="view" style="width: 50px;">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;"> 
                        <div class="title-borde" style="max-width: 260px;">  </div> 
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" style="width: 50px;">
                        <div class="div-borde p_5" style="display: flex; justify-content: center;">
                            <svg class="icons" viewBox="0 0 512.000000 512.000000" (click)="removeNutricional(i)" >
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c71c2c" stroke="none">
                                    <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                                </g>
                            </svg>
                        </div>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="3" style="text-align: center;">  Nenhum ingrediente adicionado. </td>
                </tr>
            </table>
        </div> -->


        <!-- Table Nutritional -->
        <!-- <h3 style="margin-top: 30px;"> Tabela Nutricional </h3>
        <div class="ingredients" style="flex-wrap: wrap;"> 
            
            <input mat-input [(ngModel)]="nutritional.table.name" placeholder="Escreva aqui o nome" class="input" 
                [ngStyle]="{'border-color': warningMap.get('table')? 'red' : ''}" style="width: 65%;">

            <input mat-input type="number" [(ngModel)]="nutritional.table.qtd" placeholder="Qtd" class="input"
                [ngStyle]="{'border-color': warningMap.get('table')? 'red' : ''}" style="width: 10%;">
            
            <input mat-input type="number" [(ngModel)]="nutritional.table.valor" placeholder="Valor" class="input"
                [ngStyle]="{'border-color': warningMap.get('table')? 'red' : ''}" style="width: 10%;">
            
            <button mat-button class="btn_add button-cor" [style.background]="color_primary" 
                (click)="addTableNutricional()"> Adicionar </button>

            <input #filePages type="file" accept="image/*" (change)="onFileChanged($event, 'product')" multiple="multiple" style="display: none;">
        </div>

        <div class="card_border" style="width: 100%; padding: 10px;">
            <table mat-table [dataSource]="dataSource3" style="width: 100%;">
        
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Descrição </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.description }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="qtd">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Qtd </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.qtd }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="valor">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="title-borde"> Valor </div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="width: 90%; color: black; text-align: left;"> {{ element.valor }} </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="view" style="width: 50px;">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;"> 
                        <div class="title-borde" style="max-width: 260px;">  </div> 
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" style="width: 50px;">
                        <div class="div-borde p_5" style="display: flex; justify-content: center;">
                            <svg class="icons" viewBox="0 0 512.000000 512.000000" (click)="removeTableNutricional(i)" >
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#c71c2c" stroke="none">
                                    <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                                </g>
                            </svg>
                        </div>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="3" style="text-align: center;">  Nenhum ingrediente adicionado. </td>
                </tr>
            </table>
        </div>
    </div> -->

    <div style="width: 100%; display: flex; justify-content: flex-end;">
        <button mat-button class="btn_add button-cor" [style.background]="color_primary" 
            style="width: 150px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;"
            (click)="saveProduct()"> Salvar </button>
    </div>
</div>

<div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
    <span class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
</div>