<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Tabloide </h3>
    </div>

    <div class="card_response">
        <input mat-input [(ngModel)]="tabloide.name" placeholder="Escreva aqui o nome do tabloide" class="input"
            [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" [maxlength]="limit.name">
        <p style="font-size: 10px; margin: 0; position: absolute; bottom: 5px; right: 5px;">
            {{ tabloide?.name?.length != null ? tabloide.name.length : 0 }} / {{ limit.name }}
        </p>
    </div>

    <div class="card_response">
        <textarea mat-input [(ngModel)]="tabloide.footer_text" placeholder="Escreva aqui o footer do tabloide"
            class="input" [ngStyle]="{'border-color': warningMap.get('footer_text')? 'red' : ''}" style="resize: none"
            [maxlength]="limit.footer"></textarea>

        <p style="font-size: 10px; margin: 0; padding: 5px; position: absolute; bottom: 5px; right: 0;">
            {{ tabloide?.footer_text?.length != null ? tabloide.footer_text.length : 0 }} / {{ limit.footer }}
        </p>
    </div>


    <div class="card_row">
        <div class="card_image" (click)="fileFooter.click()"
            [ngStyle]="{'border-color': warningMap.get('banner_image')? 'red' : ''}">

            <p *ngIf="tabloide?.banner_image == null || tabloide?.banner_image == ''"> Importar Banner </p>
            <img *ngIf="tabloide?.banner_image != null && tabloide?.banner_image != ''" [src]="tabloide.banner_image">

            <input type="file" #fileFooter style="display: none; " accept="image/*"
                (change)="onFileChanged($event, null, null, 'banner')">
        </div>


        <div class="card_image" (click)="fileHeader.click()"
            [ngStyle]="{'border-color': warningMap.get('header_image')? 'red' : ''}">

            <p *ngIf="tabloide?.header_image == null || tabloide?.header_image == ''"> Importar Imagem de Fundo </p>
            <img *ngIf="tabloide?.header_image != null && tabloide?.header_image != ''" [src]="tabloide.header_image">

            <input type="file" #fileHeader style="display: none; " accept="image/*"
                (change)="onFileChanged($event, null, null, 'header')">
        </div>
    </div>


    <div *ngIf="viewSection">
        <div *ngFor="let category of tabloide.category; let i = index" style="margin: 10px 0;">
            <mat-accordion>

                <mat-expansion-panel [expanded]="indexExpanded == i"
                    [ngStyle]="{'border-color': warningMap.get('panel'+i)? 'red' : ''}">

                    <mat-expansion-panel-header (click)="togglePanels(i)"
                        [ngStyle]="{'border-color': warningMap.get('panel'+i)? 'red' : ''}">
                        <mat-panel-title>

                            {{ category.name != '' ? category.name : 'Categoria #' + (i+1) }}

                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="display: flex; flex-direction: column;">

                        <div style="width: 100%; display: flex; justify-content: space-between;">

                            <div class="card_response">
                                <input mat-input [(ngModel)]="category.name" class="input"
                                    style="width: calc(98% - 60px)"
                                    [ngStyle]="{'border-color': warningMap.get('name'+i)? 'red' : ''}"
                                    placeholder="Nome da categoria." [maxlength]="limit.name_c">

                                <p style="font-size: 10px; margin: 0; position: absolute; bottom: 5px; left: 0; width: calc(98% - 65px); color: black; text-align: end;">
                                    {{ category?.name?.length != null ? category.name.length : 0 }} / {{ limit.name_c }}
                                </p>
                            </div>


                            <div class="card_icon" (click)="fileIcon.click()"
                                [ngStyle]="{'border-color': warningMap.get('image'+i)? 'red' : ''}">

                                <p *ngIf="category?.image == ''"> Importar Icone </p>
                                <img *ngIf="category?.image != ''" [src]="category.image">
                            </div>
                            <input type="file" #fileIcon style="display: none; " accept="image/*"
                                (change)="onFileChanged($event, i, null, 'icon')">
                        </div>

                        <div style="width: 100%; display: flex; flex-direction: column; gap: 30px 0;">
                            <div *ngFor="let product of category.product; let y = index"
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;">

                                <div class="card_image" (click)="fileImage.click()"
                                    [ngStyle]="{'border-color': warningMap.get('image'+i+y)? 'red' : ''}">

                                    <p *ngIf="product.image == ''"> Importar Imagem </p>
                                    <img *ngIf="product.image != ''" [src]="product.image">
                                </div>
                                <input type="file" #fileImage style="display: none; " accept="image/*"
                                    (change)="onFileChanged($event, i, y, 'image')">

                                <div class="div_info_product">

                                    <div class="card_response">
                                        <input mat-input [(ngModel)]="product.name" class="input div_100"
                                            placeholder="Escreva o nome do produto" [maxlength]="limit.name_p"
                                            [ngStyle]="{'border-color': warningMap.get('name'+i+y)? 'red' : ''}">

                                        <p style="font-size: 10px; margin: 0; padding: 5px; position: absolute; bottom: 5px; right: 0; color: black;">
                                            {{ product?.name?.length != null ? product.name.length : 0 }} / {{ limit.name_p }}
                                        </p>
                                    </div>
                                    

                                    <div class="card_response">
                                            <input mat-input [(ngModel)]="product.text_description" class="input div_100"
                                                placeholder="Escreva a descrição para o produto" [maxlength]="limit.description_p"
                                                [ngStyle]="{'border-color': warningMap.get('text_description'+i+y)? 'red' : ''}">

                                        <p style="font-size: 10px; margin: 0; padding: 5px; position: absolute; bottom: 5px; right: 0; color: black;">
                                            {{ product?.text_description?.length != null ? product.text_description.length : 0 }} / {{ limit.description_p }}
                                        </p>
                                    </div>
                                    

                                    <input mat-input [(ngModel)]="product.price" class="input div_50"
                                        placeholder="Escreva o preço" mask="separator.2" [thousandSeparator]="'.'"
                                        [decimalMarker]="','" prefix="R$ "
                                        [ngStyle]="{'border-color': warningMap.get('price'+i+y)? 'red' : ''}">

                                    <input mat-input [(ngModel)]="product.promotion_price" class="input div_50"
                                        placeholder="Escreva o preço com desconto" mask="separator.2"
                                        [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ "
                                        [ngStyle]="{'border-color': warningMap.get('promotion_price'+i+y)? 'red' : ''}">

                                    <img src="../../../assets/icon/icon_excluir.png"
                                        (click)="addListDelete(product, 'product', i, y)"
                                        [style.background]="'#f72828'" class="btn-delete">
                                </div>

                                <!-- <div *ngIf="category.answer.length-1 == y" style="margin: 5px 0; border-bottom: 1px solid #b6b6b7;"></div> -->
                            </div>
                        </div>

                    </div>

                    <button mat-button class="button-cor mw_auto" [style.background]="color_primary"
                        (click)="addAnswer(i)">
                        Adicionar Produto
                    </button>

                    <button mat-button class="button_red mw_auto"
                        (click)="addListDelete(category, 'category', i, null)">
                        Apagar Categoria
                    </button>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>


    <div style="display: flex; margin-top: 10px;">
        <!-- <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou o catálogo
        </button> -->

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="addQuestion()"
            style="margin-right: 10px;">
            Adicionar Categoria
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()">
            {{ tabloide_id == null ? 'Finalizar Tabloide' : 'Salvar Tabloide' }}
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading_btn" role="status"
                aria-hidden="true"></span>
        </button>
    </div>

</div>