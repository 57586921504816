import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../app.config';
import { UserCampaignService } from './user-campaign.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor(
        private userService: UserService,
        private userCampaignService: UserCampaignService,
        private http: HttpClient,
    ) { }

    typePage;
    tamLoop: number = 3;
    contLoop: number = 0;

    options = []
    block = []
    component: any = null;
    qrcode_reading: boolean = true;

    scrollSubject = new Subject<any>();

    redirect = {
        campaign: null,
        qrcode: null,
        establishment: null,
    }

    info_user;
    list_login_user = []

    user_id;
    token_user: string = null;

    survey_answer: any = [];

    id_establishment;

    establishment = {
        logo: null,
        color: '#f77128'
    }

    shoppingCart = { itens: [], total: 0, qtd: 0 };
    page_shoppingCart: number = 0;

    historic;

    screen = { id: null, format: '' };

    header = {
        title: null,
        description: null,
        color: null,
        back: null,
        whatsapp: null,
        logo: null,
        image_side: null,
        facebook: null,
        instagram: null,
        youtube: null,
        show_header: true
    }

    option_link = [
        { name: 'Link', link: '', exem: 'Insira um link' },
        { name: 'WhatsApp', link: 'https://wa.me/', exem: '+00 (00) 0 0000 0000' },
        { name: 'Telefone', link: 'tel:', exem: '00 0000 0000' },
        { name: 'Email', link: 'mailto:', exem: 'Insira seu email' },
        { name: 'Relatório', link: 'sbs', exem: 'Insira seu email' }
    ]

    checkLoop() {
        if (this.contLoop <= this.tamLoop) {
            return true
        }
        else
            return false
    }

    checkLoginUser() {
        let user_obj

        this.list_login_user = JSON.parse(localStorage.getItem("_list_accounts"));
        this.info_user = this.getInfoUser();
        let establishment = this.getEstablishment()


        if (this.info_user != null && this.info_user.establishment != establishment) {

            if (this.list_login_user == null || this.list_login_user.length == 0) {
                this.list_login_user = []
                this.list_login_user.push(this.info_user)
            }
            else {
                user_obj = this.list_login_user.findIndex(el => el.establishment == establishment)

                if (user_obj == -1) {
                    localStorage.removeItem('tokenUser')
                    localStorage.removeItem('_user')
                }
            }
        }
        else if (this.list_login_user != null && this.list_login_user.length > 0) {
            user_obj = this.list_login_user.filter(el => el.establishment == establishment)

            if (user_obj != null && user_obj.length > 0) {
                this.info_user = user_obj[0];

                localStorage.removeItem('_user')
                localStorage.removeItem('tokenUser')

                localStorage.setItem('_user', JSON.stringify(this.info_user))
                localStorage.setItem('tokenUser', this.info_user.token)
            }
        }
        else {
            // console.log('OK')
        }


        if (this.list_login_user != null && this.list_login_user.length > 0)
            localStorage.setItem('_list_accounts', JSON.stringify(this.list_login_user))

        if (user_obj >= 0 && this.info_user != null && this.info_user != "")
            localStorage.setItem('_user', JSON.stringify(this.info_user))

        if (user_obj >= 0 && this.info_user?.token != null && this.info_user?.token != "")
            localStorage.setItem('tokenUser', this.info_user.token)

    }

    nextOption() {
        this.contLoop += 1;
    }

    addNewType(type, link, imagens) {
        this.options.push({ objType: type, objLink: link, objImg: imagens, status: false })
        this.block.push(false)
        this.contLoop += 1;
    }

    updateTypeImage(imagens) {
        this.options[this.contLoop - 1].objImg = imagens;
        this.options[this.contLoop - 1].status = true;
    }

    updateLink(link, type, posi) {

        if (this.contLoop > 0) {
            this.options[this.contLoop - 1].objImg[posi].link = link;
        }
        else {
            this.options.push({ objType: type, objLink: link, objImg: null, status: false })
        }
    }

    statusPosition(position) {
        this.options[position - 1].status = true;
        this.block[position - 1] = true;
        this.contLoop -= 1;
    }

    getType() {
        return this.options;
    }

    checkTypePage(list, id) {

        if (list.id == id) {
            // console.log('--Campaing--')
        }
        else {
            if (list.home_images != null && list.home_images.length > 0) {
                list.home_images.forEach(home => {
                    if (home.id == id) {
                        // console.log('--Home--')
                        return 'home';
                    }
                });
            }

            else if (list.page_images != null && list.page_images.length > 0) {
                list.page_images.forEach(page => {
                    if (page.id == id) {
                        // console.log('--Page--')
                        return 'page';
                    }
                });
            }

            else if (list.internal_images != null && list.internal_images.length > 0) {
                list.internal_images.forEach(inter => {
                    if (inter.id == id) {
                        // console.log('--Internal--')
                        return 'internal';
                    }
                });
            }
            else {
                return null;
            }
        }
    }

    saveHistoric(id_campaign, id_obj, format_obj, action, type_page, qrcode) {

        if (id_campaign == null)
            return false;

        let user = JSON.parse(localStorage.getItem("_user"));

        let text_action = '';
        this.historic = null;
        let date = new Date()

        date.setHours(date.getHours() - 3);
        let adjusted_date = new Date(date).toISOString()

        if (action == 1) text_action = '1- Visualizou a campanha';
        else if (action == 2) text_action = '2- Abriu o content';
        else if (action == 3) text_action = '3- Resgatou o cupom';
        else if (action == 4) text_action = '4- Cadastrou na campanha';
        else if (action == 5) text_action = '5- Click no link';
        else if (action == 6) text_action = '6- Abriu a pergunta';
        else if (action == 7) text_action = '7- Respondeu a pergunta';
        else if (action == 8) text_action = '8- Assistiu ao vídeo';
        else if (action == 9) text_action = '9- Ponto: Entrada';
        else if (action == 10) text_action = '10- Ponto: Saida';
        else if (action == 11) text_action = '11- Finalizou um pedido';
        else if (action == 12) text_action = '12- Play no Vídeo';
        else if (action == 13) text_action = '13- Gerou o certificado do curso';

        this.historic = { id_item: id_obj, format: format_obj, data: adjusted_date, action: text_action, user: (user?.id != null ? user.id : null), type_page: type_page, qrcode: qrcode }
        // console.log('---> ',this.historic)

        var localhost = window.location.href.indexOf('localhost')

        if (localhost == -1) {
            // console.log(localhost)
            this.saveLogs(id_campaign, this.historic)
        }

    }

    saveLogs(campaign, description) {
        this.userCampaignService.saveLogs(campaign, description).subscribe(data => {
            // console.log(data)
        })
    }

    getEstablishment() {
        var establishment

        if (localStorage.getItem('id_establishment') != null) {
            establishment = localStorage.getItem('id_establishment')
        }
        else {
            establishment = null
        }

        return establishment
    }

    getInfoUser() {
        var info

        if (localStorage.getItem('_user') != null) {
            info = JSON.parse(localStorage.getItem("_user"))
        }
        else {
            info = null
        }

        return info
    }

    checkComponent(list) {
        if (this.component == list) {
            return true;
        }
        else {
            this.component = list;
            return false
        }
    }

    watchScroll(): Observable<any> {
        return this.scrollSubject.asObservable();
    }

    changeScroll(): void {
        this.scrollSubject.next('changed');
    }

    viaCep(cep): Observable<any> {
        return this.http.get<any>("https://viacep.com.br/ws/" + cep + "/json/")
    }

    addProduct(item, qtd) {
        let index = this.shoppingCart.itens.findIndex(el => el.id == item.id)

        if (index != -1) {
            this.shoppingCart.itens[index].qtd += qtd
        }
        else {
            item.qtd = qtd;
            this.shoppingCart.itens.push(item);
        }

        this.update();
    }

    removeProduct(item, qtd) {
        let index = this.shoppingCart.itens.findIndex(el => el.id == item.id)

        if (index != -1) {
            this.shoppingCart.itens[index].qtd -= qtd
        }

        this.update();
    }

    excluirProduct(posi) {
        this.shoppingCart.itens.splice(posi, 1);
        this.update();
    }

    clear() {
        this.shoppingCart = { itens: [], total: 0, qtd: 0 };
    }

    update() {
        this.shoppingCart.total = 0
        this.shoppingCart.qtd = 0

        this.shoppingCart.itens.forEach(element => {
            this.shoppingCart.total += element.price * element.qtd
            this.shoppingCart.qtd += element.qtd
        })
    }

    getShoppingCart() {
        return this.shoppingCart.itens
    }

    getPageShoppingCart() {
        return this.page_shoppingCart;
    }

    setListColor(color) {
        var r, g, b, color;
        let list_color = []

        if (color.substr(0, 1) == '#') {
            color = this.HexToArray(color)

            for (let index = 0; index < 7; index++) {
                r = color[0] - (index * 2) * -5;
                g = color[1] - (index * 2) * -10;
                b = color[2] - (index * 2) * -15;

                if (r > 255) { r = 255; g = r - 255 }
                if (g > 255) { g = 255; b = g - 255 }
                if (b > 255) { b = 255; }

                if (r < 0) { r = 0; g = r + 255 }
                if (g < 0) { g = 0; b = g + 255 }
                if (b < 0) { b = 0; }


                list_color.push("#" + (this.rgbToHex(r) + this.rgbToHex(g) + this.rgbToHex(b)))
            }
            return list_color
        }
        else if (color.substr(0, 3) == 'rgb') {
            color = this.RgbToArray(color)

            for (let index = 0; index < 7; index++) {
                r = color[0] - (index * 2) * -5;
                g = color[1] - (index * 2) * -10;
                b = color[2] - (index * 2) * -15;

                if (r > 255) { r = 255; g = r - 255 }
                if (g > 255) { g = 255; b = g - 255 }
                if (b > 255) { b = 255; }

                if (r < 0) { r = 0; g = r + 255 }
                if (g < 0) { g = 0; b = g + 255 }
                if (b < 0) { b = 0; }

                list_color.push("#" + (this.rgbToHex(r) + this.rgbToHex(g) + this.rgbToHex(b)))
            }
            return list_color
        }
    }

    rgbToHex(rgb) {
        var hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    };

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    HexToArray(color) {
        color = color.replace('#', '');
        let red = color.substr(0, 2),
            green = color.substr(2, 2),
            blue = color.substr(4, 2);
        red = parseInt(red, 16);
        green = parseInt(green, 16);
        blue = parseInt(blue, 16);


        return [red, green, blue];
    }

    RgbToArray(color) {
        var aux = color.replace('rgb(', '');
        var aux2 = aux.replace(')', '');

        var aux_color = aux2.split(',')

        if (color.length != 3) {
            color = aux2.split(' ')
        }
        else {
            color = aux_color
        }

        let red = color[0],
            green = color[1],
            blue = color[2];


        red = parseInt(red, 16);
        green = parseInt(green, 16);
        blue = parseInt(blue, 16);


        return [this.rgbToHex(red), this.rgbToHex(green), this.rgbToHex(blue)];
    }

    setImageCampaignHome(home, data) {
        // console.log(home, data);

        return {
            id: home.id,
            type: home.page_format_obj,
            quantity: null,
            link: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo" || home?.page_format_obj?.name == "Podcast") &&
                home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, 'link') :
                (data?.homepage_format_obj?.name != null && data.homepage_format_obj.name == 'Post' && data?.home_images[0].link != null ? data.home_images[0].link : this.checkLink(home?.link, 'link')),

            // link: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo") && home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, 'link') : this.checkLink(home?.link, 'link'),
            option_link: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo") && home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, null) : this.checkLink(home?.link, null),
            placeholder: ((home?.page_format_obj?.name == "Link" || home?.page_format_obj?.name == "Vídeo") && home?.page_images != null) ? this.checkLink(home?.page_images[0]?.link, 'exem') : this.checkLink(home?.link, 'exem'),
            catalog: (home?.page_format_obj?.name == "Catálogo" && home?.page_images != null) ? home?.page_images[0]?.catalog : null,
            coupon: (home?.page_format_obj?.name == "Cupom" && home?.page_images != null) ? home?.page_images[0]?.coupon : null,
            registration_config: (home?.page_format_obj?.name == "Cadastro" && home?.page_images != null) ? home?.page_images[0]?.registration_config : null,
            background_image: { url: (home?.page_format_obj?.name == "Vídeo" && home?.page_images != null) ? home?.page_images[0]?.image : null },
            images: null,
            order: home.order,
            title: (home?.page_format_obj?.name == "Podcast" && home.title != null ? home.title : home?.page_images != null ? home?.page_images[0].title : ''),
            point_control_name: home.point_control_name,
            description: (home?.page_format_obj?.name == "Podcast" && home.description != null ? home.description : home?.page_images != null ? home?.page_images[0].description : ''),
            name: null,
            url: home.image,
            survey: (home?.page_format_obj?.name == "Enquete" && home?.page_images != null) ? home?.page_images[0]?.survey : null,
            video: null,
            save: false,
            point_type: home.home_page_image_type,
            course: home.course,
            notice: home.eye_news,
            tabloide: home.tabloide,
            castbox: home.castbox,
            deezer: home.deezer,
            itunes: home.itunes,
            course_obj: null,
            google_podcast: home.google_podcast,
            token_hashlar: (home?.page_format_obj?.name == "Hashlar" && home?.page_images != null) ? home?.page_images[0]?.token_hashlar : home.token_hashlar,
            level: ((home.page_format_obj?.name == 'Mini Banner') ||
                (home.page_format_obj?.name == 'Galeria') ||
                (home.page_format_obj?.name == 'Banner')) ? true : null,
        }

    }

    setImageCampaignHomepage(page, data) {
        // console.log(home, data);

        return {

            id: page.id,
            type: page.page_format_obj,
            quantity: null,
            link: ((page?.page_format_obj?.name == "Link" || page?.page_format_obj?.name == "Vídeo" || page?.page_format_obj?.name == "Podcast") &&
                page?.internal_images != null) ? this.checkLink(page?.internal_images[0]?.link, 'link') :
                (page?.page_format_obj?.name != null && page?.page_format_obj?.name == "Podcast" ? page.link : this.checkLink(page.link, 'link')),

            option_link: ((page?.page_format_obj?.name == "Link" ||
                page?.page_format_obj?.name == "Vídeo") &&
                page?.internal_images != null)
                ?
                this.checkLink(page?.internal_images[0]?.link, null)
                :
                this.checkLink(page.link, null),

            placeholder: ((page?.page_format_obj?.name == "Link" ||
                page?.page_format_obj?.name == "Vídeo") &&
                page?.internal_images != null)
                ?
                this.checkLink(page?.internal_images[0]?.link, 'exem')
                :
                this.checkLink(page.link, 'exem'),

            catalog: page.catalog,
            coupon: (page.page_format_obj.name == "Cupom" && page?.coupon != null) ? page?.coupon : (page?.internal_images != null ? page?.internal_images[0]?.coupon : page.coupon),
            registration_config: (page.page_format_obj.name == "Cadastro" && page?.internal_images != null) ? page?.internal_images[0]?.registration_config : page.registration_config,
            background_image: { url: (page.page_format_obj.name == "Vídeo" && page?.internal_images != null) ? page?.internal_images[0]?.image : null },
            images: null,
            order: page.order,
            title: ((page.page_format_obj.name == "Ponto" || page.page_format_obj.name == "Post" || page.page_format_obj.name == "Podcast") && page?.title != null) ? page?.title : (page?.internal_images != null ? page?.internal_images[0]?.title : page.title),
            point_control_name: page.point_control_name,
            description: ((page.page_format_obj.name == "Ponto" || page.page_format_obj.name == "Post" || page.page_format_obj.name == "Podcast") && page?.description != null) ? page?.description : (page?.internal_images != null ? page?.internal_images[0]?.description : page.description),
            name: null,
            url: page.image,
            survey: page.page_format_obj.name == "Enquete" ? page?.survey : null,
            video: null,
            save: false,
            point_type: page.page_type,
            course: page.course,
            notice: null,
            castbox: (page?.internal_images != null ? page?.internal_images[0]?.castbox : null),
            deezer: (page?.internal_images != null ? page?.internal_images[0]?.deezer : null),
            itunes: (page?.internal_images != null ? page?.internal_images[0]?.itunes : null),
            google_podcast: (page?.internal_images != null ? page?.internal_images[0]?.google_podcast : null),
            course_obj: null,
            token_hashlar: (page.page_format_obj.name == "Hashlar" && page?.internal_images != null) ? page?.internal_images[0]?.token_hashlar : page.token_hashlar,
            level: ((page.page_format_obj?.name == 'Mini Banner') ||
                (page.page_format_obj?.name == 'Galeria') ||
                (page.page_format_obj?.name == 'Banner')) ? true : null,

        }

    }

    setImageCampaignInternal(internal, page) {
        // console.log(home, data);

        return {
            id: internal.id,
            type: page.page_format_obj,
            quantity: null,
            link: page.page_format_obj.name == 'Podcast' ? internal.link : this.checkLink(internal.link, 'link'),
            option_link: this.checkLink(internal.link, null),
            placeholder: this.checkLink(internal.link, 'exem'),
            catalog: internal.catalog,
            coupon: internal.coupon,
            registration_config: (page.page_format_obj.name == 'Cadastro' && internal.registration_config != null) ? internal.registration_config :
                (page.registration_config != null ? page.registration_config : null),
            background_image: { url: page.page_format_obj?.name == "Vídeo" ? internal.image : null },
            images: null,
            order: internal.order,
            title: internal.title,
            point_control_name: internal.point_control_name,
            description: internal.description,
            name: null,
            url: internal.image,
            survey: internal.survey,
            video: internal.video,
            save: false,
            course: internal.course,
            notice: null,
            castbox: internal.castbox,
            deezer: internal.deezer,
            itunes: internal.itunes,
            google_podcast: internal.google_podcast,
            level: null,
            point_type: internal.internal_page_type,
            course_obj: null,
            token_hashlar: internal.token_hashlar,
        }

    }

    checkLink(link, type) {

        if (link != null) {
            if (link.substring(0, 3) == 'sbs') {
                if (type == 'link') return link
                else if (type == 'exem') return this.option_link[4].exem
                else return this.option_link[4].link
            }
            else if (link.substring(0, 3) == 'tel') {
                var link = link.split(':')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[2].exem
                else return this.option_link[2].link

            }
            else if (link.substring(0, 6) == 'mailto') {
                var link = link.split(':')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[3].exem
                else return this.option_link[3].link
            }
            else if (link.substring(0, 14) == 'https://wa.me/') {
                var link = link.split('me/')

                if (type == 'link') return link[1]
                else if (type == 'exem') return this.option_link[1].exem
                else return this.option_link[1].link
            }
            else {
                if (type == 'link') return link
                else if (type == 'exem') return this.option_link[0].exem
                else return this.option_link[0].link
            }
        }

    }
}