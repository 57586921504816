import { dateMapper } from "src/app/utils/validator.utils";

export const getPumpHouseConfig = () => ({
    coll_header: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 3, col: 1, key: 'place' },
            { row: 4, col: 1, key: 'row_1' },
            { row: 5, col: 1, key: 'row_2' },
            { row: 6, col: 1, key: 'row_3' },
            { row: 7, col: 1, key: 'row_4' },
            { row: 4, col: 6, key: 'row_5' },
            { row: 5, col: 6, key: 'row_6' },
            { row: 6, col: 6, key: 'row_7' },
            { row: 7, col: 6, key: 'row_8' },
        ],
    },

    header: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 1, col: 1, key: 'title' },
            { row: 3, col: 1, key: 'place' },
            { row: 4, col: 2, key: 'row_1' },
            { row: 5, col: 2, key: 'row_2' },
            { row: 6, col: 2, key: 'row_3' },
            { row: 7, col: 2, key: 'row_4' },
            { row: 4, col: 7, key: 'row_5' },
            { row: 5, col: 7, key: 'row_6' },
            { row: 6, col: 7, key: 'row_7' },
            { row: 7, col: 7, key: 'row_8' },
        ],
    },
});
