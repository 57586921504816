import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/service/campaign.service';
import { QrCodeService } from 'src/app/service/qrcode.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../../service/compressor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { ReminderService } from '../../../service/reminder.service'; 
import { ScheduleReminderService } from 'src/app/service/schedule-reminder.service';
import { GroupService } from 'src/app/service/group.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
    selector: 'app-whatsapp-detail',
    templateUrl: './whatsapp-detail.component.html',
    styleUrls: ['./whatsapp-detail.component.css']
})

export class WhatsappDetailComponent implements OnInit {

    constructor(
        private qrCodeService: QrCodeService,
        private userCampaignService: UserCampaignService,
        private compressor: CompressorService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: MDBModalService,
        private reminderService: ReminderService,
        private scheduleReminderService: ScheduleReminderService,
        private groupService: GroupService,
        private storageService: StorageService
    ) { 
        this.alert.id = this.route.snapshot.paramMap.get('id');
    } 

    modalRef: MDBModalRef;
    modalDelet: MDBModalRef;

    warningMap = new Map();

    qrcodes = [];
    groups = [];
    select_qrcode;
    
    maxCharacters = 50;
    
    selected_tab

    position_reminder;

    alert = {
        id: null,
        title: null,
        establishment: null,
        qrcode: null,
        qrcode_id: null,
        group: null,
        group_id: null,
        start_date: null,
        end_date: null,
        start_hour: null,
        end_hour: null,
        image: null,
        notification: null,
        messenger: null,
        reminder:[
            {send: 'segunda', active: true,  hours: [{hour: null, id: null}]},
            {send: 'terça', active: true,  hours: [{hour: null, id: null}]},
            {send: 'quarta', active: true,  hours: [{hour: null, id: null}]},
            {send: 'quinta', active: true,  hours: [{hour: null, id: null}]},
            {send: 'sexta', active: true,  hours: [{hour: null, id: null}]},
            {send: 'sabado', active: true,  hours: [{hour: null, id: null}]},
            {send: 'domingo', active: true,  hours: [{hour: null, id: null}]},
        ]
    }

    list_hour = []

    compressed_image = []; // as the name says...

    color_primary
    
    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.alert.establishment = localStorage.getItem('id_establishment')

        if(this.alert.id != null){
            this.getReminderById(this.alert.id)
        }

        this.selected_tab = 0;
        this.position_reminder = 0;

        // this.newAlert('0,10')

        // this.getUserCampaign()
        this.getGroups(1)
    }

    getReminderById(id){
        this.reminderService.getReminderById(id).subscribe(data=>{

            this.alert.title = data.title;
            this.alert.establishment = data.establishment;
            this.alert.image = data.image;
            this.alert.group = data?.group_obj?.name;
            this.alert.group_id = data?.group_obj?.id;
            if(data.start_time != null){
                var auxDateStart = data.start_time.split('T');
                this.alert.start_date = auxDateStart[0]
                var hour = auxDateStart[1].split(':')
                this.alert.start_hour = hour[0]+hour[1]
            }
            if(data.end_time != null){
                var auxDateEnd = data.end_time.split('T');
                this.alert.end_date = auxDateEnd[0]
                var hour = auxDateEnd[1].split(':')
                this.alert.end_hour = hour[0]+hour[1]
            }
            this.alert.notification = data.minutes_before;
            this.alert.messenger = data.message;

            this.alert.reminder[0].active = false;
            this.alert.reminder[1].active = false;
            this.alert.reminder[2].active = false;
            this.alert.reminder[3].active = false;
            this.alert.reminder[4].active = false;
            this.alert.reminder[5].active = false;
            this.alert.reminder[6].active = false;   
            
            this.getScheduleReminderByIdReminder(data.id)
        })
    }

    getGroups(page){
        this.groupService.getGroups(page, this.alert.establishment).subscribe(data=>{
            
            this.groups = this.groups.concat(data.results);
            if (data.next != null) {
                this.getGroups(page + 1)
            }
        })
    }

    getScheduleReminderByIdReminder(id){
        this.scheduleReminderService.getScheduleReminderByIdReminder(id).subscribe(data=>{
            
            data.forEach((el, index) => {
                
                if(el.day == 'segunda'){
                    this.alert.reminder[0].active = true;
                    if(this.alert.reminder[0].hours[0].hour != null)
                        this.alert.reminder[0].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[0].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'terça'){
                    this.alert.reminder[1].active = true;
                    if(this.alert.reminder[1].hours[0].hour != null)
                        this.alert.reminder[1].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[1].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'quarta'){
                    this.alert.reminder[2].active = true;
                    if(this.alert.reminder[2].hours[0].hour != null)
                        this.alert.reminder[2].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[2].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'quinta'){
                    this.alert.reminder[3].active = true;
                    if(this.alert.reminder[3].hours[0].hour != null)
                        this.alert.reminder[3].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[3].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'sexta'){
                    this.alert.reminder[4].active = true;
                    if(this.alert.reminder[4].hours[0].hour != null)
                        this.alert.reminder[4].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[4].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'sabado'){
                    this.alert.reminder[5].active = true;
                    if(this.alert.reminder[5].hours[0].hour != null)
                        this.alert.reminder[5].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[5].hours[0].hour = this.setHour(el.hour);
                }
                else if(el.day == 'domingo'){
                    this.alert.reminder[6].active = true;
                    if(this.alert.reminder[6].hours[0].hour != null)
                        this.alert.reminder[6].hours.push({hour: this.setHour(el.hour), id: el.id});
                    else
                        this.alert.reminder[6].hours[0].hour = this.setHour(el.hour);
                }
            
            });

            this.list_hour = data 
        })
    }
    

    getQrCode(){
        this.qrCodeService.getQrCodes(1).subscribe( data =>{
            this.qrcodes = data.results
        })
    }

    getUserCampaign(){
        // this.userCampaignService.getUserCampaign().subscribe(data=>{
        // })
    }

    setHour(hour){
        var aux_hour = hour.substring(0,2)+hour.substring(3,5)
        return aux_hour
    }

    selectGroup(obj){
        this.alert.group = obj.name;
        this.alert.group_id = obj.id
    }

    addHour(){
        this.alert.reminder[this.selected_tab]?.hours.push({hour: null, id: null})
    }

    deletHour(position){
        this.alert.reminder[this.selected_tab].hours.splice(position, 1)

        if(this.alert.reminder[this.selected_tab].hours.length == 0){
            this.alert.reminder[this.selected_tab]?.hours.push({hour: null, id: null})
        }
    }

    check(){
        this.warningMap = new Map();

        if(this.alert.title == "" || this.alert.title == null){
            this.warningMap.set('title', true);
        }
        if(this.alert.group == "" || this.alert.group == null){
            this.warningMap.set('group', true);
        }
        // if(this.alert.user == "" || this.alert.user == null){
        //     this.warningMap.set('user', true);
        // }
        if(this.alert.start_date == "" || this.alert.start_date == null){
            this.warningMap.set('start_date', true);
        }
        if(this.alert.start_hour == "" || this.alert.start_hour == null){
            this.warningMap.set('start_hour', true);
        }
        if((/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.alert.start_hour) == false)){
            this.warningMap.set('start_hour', true);
        }
        if(this.alert.end_date == "" || this.alert.end_date == null){
            this.warningMap.set('end_date', true);
        }
        if(this.alert.end_hour == "" || this.alert.end_hour == null){
            this.warningMap.set('end_hour', true);
        }
        if((/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.alert.end_hour) == false)){
            this.warningMap.set('end_hour', true);
        }
        if(this.alert.image == "" || this.alert.image == null){
            this.warningMap.set('image', true);
        }
        if(this.alert.notification == "" || this.alert.notification == null){
            this.warningMap.set('notification', true);
        }
        if(this.alert.messenger == "" || this.alert.messenger == null){
            this.warningMap.set('messenger', true);
        }

        this.alert.reminder.forEach((day, x) => {
            if(day.active){
                day.hours.forEach((hour, y) => {
                    if(hour.hour == "" || hour.hour == null){
                        this.warningMap.set('hour'+x+y, true);
                        this.warningMap.set('day'+x, true);
                    }
                    if((/^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(hour.hour) == false)){
                        this.warningMap.set('hour'+x+y, true);
                    }
                });
            }
        });

        if(this.warningMap.size == 0){
            this.newAlert('0,10')  
        }
    }
    
    postReminder(){
        this.reminderService.postReminder(this.alert.title, this.alert.establishment, 
            this.alert.group_id, this.alert.image, this.convertDate(this.alert.start_date, 
            this.alert.start_hour), this.convertDate(this.alert.end_date, this.alert.end_hour), 
            this.alert.messenger, this.alert.notification, 'WHATSAPP').subscribe(data=>{

            this.postScheduleReminder(this.alert.reminder, data.id)
        })
    }

    patchReminder(){
        this.reminderService.patchReminder(this.alert.id, this.alert.title, this.alert.establishment, 
            this.alert.group_id, this.alert.image, this.convertDate(this.alert.start_date, 
            this.alert.start_hour), this.convertDate(this.alert.end_date, this.alert.end_hour), 
            this.alert.messenger, this.alert.notification).subscribe(data=>{

                this.list_hour.forEach(el => {
                    this.scheduleReminderService.deleteScheduleReminder(el.id).subscribe(data=>{
                    })
                });

                this.postScheduleReminder(this.alert.reminder, data.id)
                this.router.navigate(['/application/channels'])

        })
    }

    postScheduleReminder(reminder, id){
        reminder.forEach(day => {

            if(day.active){
                var listHour = []

                day.hours.forEach(el => {
                    var hh = el.hour.substring(0,2)
                    var mm = el.hour.substring(2, el.length)
                    el = (hh+':'+mm+':00')
                    listHour.push(el)                    
                });

                this.scheduleReminderService.postScheduleReminder(id, day.send, listHour).subscribe(data=>{
                    this.router.navigate(['/application/channels'])
                })
            }
        });
        
    }

    convertDate(data, hour){
        if(data != null){
            var d = new Date(data),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hh = hour.substring(0,2),
            mm = hour.substring(2, hour.length),
            ss = d.getSeconds();
    
            var aux_data = [year, month, day].join('-')
            var aux_hour = [hh, mm, ss].join(':')
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
    
            return [aux_data, aux_hour].join('T');
        }
        else{
            return null
        }
        
    }

    async onFileChanged(event){

		if (event.target.files && event.target.files[0]) {
			var target = event.target.files
			const compress = this.recursiveCompress( target[0], 0, target ).pipe(
				expand(res => {
					  return res.index > res.array.length - 1
					? EMPTY
					: this.recursiveCompress( target[res.index], res.index, target );
				}),
			);
			compress.subscribe(res => {
				if (res.index > res.array.length - 1) {
                    this.alert.image = this.compressed_image[0]
					this.compressed_image = []

				}
			});
		}

	}

    recursiveCompress = (image: File, index, array) => {
		return this.compressor.compress(image).pipe (
		  	map(response => {
				this.compressed_image.push(response)
				return {
					data: response,
					index: index + 1,
					array: array,
				};
		  	}),
		);
	}

    newAlert(price){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Cobrança por Mensagem',
                text: 'Ao continuar, confirmo que aceito a cobrança de R$'+price+' por cada mensagem enviada.',
                has_button: true,
                button_text: 'Continuar',
                button_cancel: 'Cancelar'
            }
        })   
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                
                if(this.alert.id == null){
                    this.postReminder()
                }    
                else {
                    this.patchReminder()
                }  
            }
            else{
                this.router.navigate(['/application/channels'])
            }
        });
    }

}


