import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class DesktopService {

  constructor(private http: HttpClient) { }

  establishment = "id_establishment"

  getDesktops(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`)
    };

    return this.http.get<any>(AppConfig.path + `core/desktop-campaign-item/`, options);
  }

  getDesktopId(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`)
    };

    return this.http.get<any>(AppConfig.path + `core/desktop-campaign-item/${id}/`, options);
  }

  postDesktop(desktop, campaign_id): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`campaign`, campaign_id)
      .set(`title`, desktop.title)
      .set(`type`, desktop.type)  // CHOICES = BANNER, GALLERY, CAROUSEL
      .set(`side`, desktop.side)  // CHOICES = RIGHT, LEFT

    if (desktop.link != null && desktop.link != '') {
      body = body.set(`link`, desktop.link)
      body = body.set(`link_text`, desktop.link_text)
    }
    if (desktop.icon != null && desktop.icon != '') {
      body = body.set(`icon`, encodeURI(desktop.icon))
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/desktop-campaign-item/`, body, { headers })
  }

  patchDesktop(desktop, campaign_id): Observable<any> {

    let body = new HttpParams({ encoder: new CustomEncoder() })

    if (campaign_id != null && campaign_id != '') {
      body = body.set(`campaign`, campaign_id)
    }
    if (desktop.title != null && desktop.title != '') {
      body = body.set(`title`, desktop.title)
    }
    if (desktop.type != null && desktop.type != '') {
      body = body.set(`type`, desktop.type)
    }
    if (desktop.side != null && desktop.side != '') {
      body = body.set(`side`, desktop.side)
    }
    if (desktop.icon != null && desktop.icon != '') {
      body = body.set(`icon`, encodeURI(desktop.icon))
    }
    if (desktop.link != null) {
      body = body.set(`link`, desktop.link)
    }
    if (desktop.link_text != null) {
      body = body.set(`link_text`, desktop.link_text)
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/desktop-campaign-item/${desktop.id}/`, body, { headers })
  }

  deleteDesktop(desktop): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/desktop-campaign-item/${desktop.id}/`, { headers })
  }


  // ---------- IMAGE ----------
  getDesktopsImage(): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`)
    };

    return this.http.get<any>(AppConfig.path + `core/desktop-campaign-item-image/`, options);
  }

  getDesktopImageId(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`)
    };

    return this.http.get<any>(AppConfig.path + `core/desktop-campaign-item-image/${id}/`, options);
  }

  postDesktopImage(image, desktop_id): Observable<any> {

    let body_aux: any = {
      title: image.title,
      link_text: image.link_text != null ? image.link_text : '',
      link: image.link != null ? image.link : '',
      text: image.text != null ? image.text : '',
      campaign_item: desktop_id,
      spotify: image.spotify != null ? image.spotify : '',
      deezer: image.deezer != null ? image.deezer : '',
      itunes: image.itunes != null ? image.itunes : '',
      castbox: image.castbox != null ? image.castbox : '',
      google_podcast: image.google_podcast != null ? image.google_podcast : '',
    }

    if (image.image != null && image.image != '') {
      body_aux['image'] = encodeURI(image.image)
    }

    let body: any = JSON.stringify(body_aux)

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/desktop-campaign-item-image/`, body, { headers })
  }

  patchDesktopImage(image): Observable<any> {
    let body_aux: any = {
      title: image.title,
      link_text: image.link_text != null ? image.link_text : '',
      link: image.link != null ? image.link : '',
      text: image.text != null ? image.text : '',
      spotify: image.spotify != null ? image.spotify : '',
      deezer: image.deezer != null ? image.deezer : '',
      itunes: image.itunes != null ? image.itunes : '',
      castbox: image.castbox != null ? image.castbox : '',
      google_podcast: image.google_podcast != null ? image.google_podcast : '',
    }

    if (image.image != null && image.image != '') {
      body_aux['image'] = encodeURI(image.image)
    }

    let body: any = JSON.stringify(body_aux)


    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.patch<any>(AppConfig.path + `core/desktop-campaign-item-image/${image.id}/`, body, { headers })
  }

  deleteDesktopImage(image): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.delete<any>(AppConfig.path + `core/desktop-campaign-item-image/${image.id}/`, { headers })
  }
}
