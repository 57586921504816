import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})

export class ConstantsService {

    constructor(private http : HttpClient) { }

    getConstants(): Observable<any> {

        return this.http.get<any>(AppConfig.path + `core/constants/`);
    }

    getConfig(): Observable<any> {

        return this.http.get<any>(AppConfig.path + `core/config/`);
    }

    getEstablishment(): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/establishment/`, { headers });
    }

    getEstablishmentId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/establishment/${id}/`, { headers });
    }
    
    getUserEstablishment(user): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`user`, user)
        };

        return this.http.get<any>(AppConfig.path + `core/user-establishment/`, options);
    }

    contact(message, name, email): Observable<any>{
        let body = new HttpParams()
            .set(`message`, message)
            .set(`name`, name)
            .set(`email`, email)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/contact/`, body, { headers })
    }

    fileSaved(file): Observable<any>{
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`file`, encodeURI(file))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/file-saved/`, body, { headers })
    }

}
