import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  establishment = "id_establishment"

  getOrder(page, status): Observable<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        .set('Authorization', `token ${localStorage.getItem('token')}`),
      params: new HttpParams()
        .set(`catalog__establishment`, localStorage.getItem(this.establishment))
        .set(`page`, page)
    };
    
    if (status != null && status != ''  && status != 'ALL') {
      options.params = options.params.set(`status`, status)
    }

    return this.http.get<any>(AppConfig.path + `core/order/`, options);
  }

  getListBrands(): Observable<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return this.http.get<any>(AppConfig.path + `payment/list-brands/`, options);
  }

  postOrder(id_catalog, address, list_products, token): Observable<any> {
    let body: any = JSON.stringify({
      catalog: id_catalog,
      products: list_products,
      address: address.name,
      number: address.number,
      cep: address.cep,
      district: address.district,
    })

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      // .set('User', `token ${localStorage.getItem('token')}`)
      .set('User', `${token}`)

    return this.http.post<any>(AppConfig.path + `core/order/`, body, { headers })
  }

  editStatusOrder(id_order, status): Observable<any> {
    let body = new HttpParams()
      .set(`new_status`, status)
      .set(`order_id`, id_order)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + `core/change-order-status/`, body, { headers })
  }

  payment(card, id_order, token): Observable<any> {
    let body = new HttpParams()
      .set(`order`, id_order)
      .set(`card_number`, card.number)
      .set(`card_holder`, card.holder)
      .set(`card_date`, card.date)
      .set(`card_code`, card.code)
      .set(`card_brand`, card.brand)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('User', `${token}`)

    return this.http.post<any>(AppConfig.path + `payment/user-acquisition/`, body, { headers })
  }

  sendSMS(user_id, message): Observable<any> {
    let body = new HttpParams()
      .set(`user_id`, user_id)
      .set(`message`, message)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      .set('User', `${localStorage.getItem('tokenUser')}`)

    return this.http.post<any>(AppConfig.path + `core/order-email-sender/`, body, { headers })
  }
}
