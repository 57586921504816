import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { CategoryService } from '../service/category.service';
import { StorageService } from '../service/storage.service';
import { DetailCategoryComponent } from './detail-category/detail-category.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private categoryService: CategoryService,
    private modalService: MDBModalService,
  ) { }

  modalDelet: MDBModalRef;

  loading: boolean = false;
  displayedColumns: string[] = ['campaign', 'status', 'created', 'view'];
  dataSource = new MatTableDataSource<any>();
  // dataSource = ELEMENT_DATA;

  /* pagination related variables */
  campaign_response;
  num: number;
  page: number = 1
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;
  total_results: number;
  color_primary: string = '';

  caegory_response;
  categories_list = [];

  ngOnInit(): void {
    this.num = 10;
    this.page = 1
    this.total_results = 0;

    this.color_primary = this.storageService.establishment.color;

    this.getAllCategories()
  }

  nextPage(){
    if(this.campaign_response.next != null){
        this.page += 1;
        this.getAllCategories()
    }
  }

  backPage(){
      if(this.campaign_response.previous != null){
          this.page -= 1;
          this.getAllCategories()
      }
  }

  getAllCategories(){
    this.categoryService.getAllCategories(this.page).subscribe(data=>{
      // console.log(data)

      this.caegory_response = data;
      this.dataSource.data = data.results
      this.loading = false

      // pagination calc
      this.count_total = data.count;
      this.page_count1 = 1 + ((this.page-1) * this.page_size)
      if((this.page_count1 + (this.page_size-1)) > this.count_total){
          this.page_count2 = this.count_total;
      }
      else{
          this.page_count2 = this.page_count1 + (this.page_size-1);
      }
    },
    error=>{
      console.log(error)
    })
  }

  createCategory(obj){
    this.modalDelet = this.modalService.show(DetailCategoryComponent, {
        backdrop: 'static',
        keyboard: false,
        focus: true,
        show: true,
        ignoreBackdropClick: false,
        class: 'modal-dialog-centered popup-transparent',
        containerClass: '',
        animated: false,
        data:{
          category: obj,
        }
    })   
    this.modalDelet.content.action.subscribe( (result: any) => { 
        if(result == 'yes'){
            this.getAllCategories()
        }
    }); 

}

  deleteCategory(id){
    this.loading = true;
    this.categoryService.deleteCategory(id).subscribe(data=>{
        this.loading = false;
        this.getAllCategories()
    })
  }

  openDelete(obj){
      this.modalDelet = this.modalService.show(ModalChoiceComponent, {
          backdrop: 'static',
          keyboard: false,
          focus: true,
          show: true,
          ignoreBackdropClick: false,
          class: 'modal-dialog-centered popup-transparent',
          containerClass: '',
          animated: false,
          data:{
              title: 'Excluir categoria',
              text: 'Deseja excluir a categoria '+obj.name+'?',
          }
      })   
      this.modalDelet.content.action.subscribe( (result: any) => { 
          if(result == 'yes'){
              this.deleteCategory(obj.id)
          }
      }); 

  }

}

export interface PeriodicElement {
  id: number;
  name: string;
  created_at: string;
  active: boolean;
  start_date: string;
  end_date: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: 11, name: 'Lorem Ipsum', created_at: '2020-10-20', active: true, start_date: '2020-10-20', end_date: '2021-10-20'},
  {id: 12, name: 'Lorem Ipsum', created_at: '2020-10-10', active: true, start_date: '2020-10-10', end_date: '2021-10-10'},
  {id: 8, name: 'Lorem Ipsum', created_at: '2020-09-21', active: false, start_date: '2020-09-21', end_date: '2021-09-21'},
  {id: 10, name: 'Lorem Ipsum', created_at: '2020-10-22', active: true, start_date: '2020-10-22', end_date: '2021-10-22'},
  {id: 13, name: 'Lorem Ipsum', created_at: '2020-10-06', active: true, start_date: '2020-10-06', end_date: '2021-10-06'},
  {id: 14, name: 'Lorem Ipsum', created_at: '2020-05-30', active: false, start_date: '2020-05-30', end_date: '2021-05-30'},
];
