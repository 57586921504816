<div class="screen">

    <div class="title">
        <h3> Tabela </h3>
    </div>

    <div>
        <label> Título </label>
        <input mat-input [(ngModel)]="spreadsheet_table.title" placeholder="" class="input">
    </div>

    <div>
        <label> Subtítulo </label>
        <input mat-input [(ngModel)]="spreadsheet_table.spreadsheet_subtitle" placeholder="" class="input">
    </div>

    <div *ngFor="let key of description_keys">
        <label> {{ formatKey(key) }} </label>
        <input mat-input [(ngModel)]="description_json[key]" placeholder="" class="input">
    </div>

    <div class="div-table">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container *ngFor="let col of displayedColumns;" [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde"> {{ col }} </div>
                </th>

                <td mat-cell *matCellDef="let element; let idx = index">
                    <div class="div-borde" > 
                        <input mat-input [(ngModel)]="rows_array[idx][col]" class="input-table" [style.width.px]="col.length * 10"> 
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> 
                    Nenhum resultado encontrado. 
                </td>
            </tr>

        </table>
    </div>

    <div style="margin-top: 20px;">
        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()">
            Salvar <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true" style="margin-left: 10px;"></span>
        </button>
    </div>

</div>