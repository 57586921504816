<div class="screen">

    <div style="width: 93%; display: flex; justify-content: space-between; align-items: center; margin: auto;">
        <h3> Cadastro - Lista atualizada à cada 5 minutos </h3>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <button mat-button (click)="getUserCampaignAll()"
            style="display: flex; align-items: center; justify-content: space-evenly;">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>
        </div>
    </div>

    <div class="div-table" *ngIf="!loading">
        <table mat-table [dataSource]="dataSource" matSort>
          
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Nome </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.name}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Email </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.email}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="max-width: 150px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde" style="max-width: 150px; padding: 5px !important; display: flex; justify-content: space-evenly">
                        <div (click)="openPreview(element)">
                            <img src="/assets/icon/icon-olho.png" matTooltip="Visualizar" 
                            class="icon" [style.background]="color_primary"> 
                        </div>

                        <!-- <div>
                            <img src="/assets/icon/icon_edit.png" matTooltip="Editar" 
                            class="icon" [style.background]="color_primary"> 
                        </div> -->

                        <!-- <div>
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" 
                            class="icon" [style.background]="color_primary"> 
                        </div> -->
                    </div>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
            </tr>
        </table>
    
        <div class="table-paginator" *ngIf="!loading && dataSource.data.length != 0">
            <div style="display: flex;">
                <div class="paginator-button prev" (click)="backPage()">
                    <img src="/assets/icon/prev.png" width="12px" height="12px">
                </div>
                <div class="paginator-button next" (click)="nextPage()">
                    <img src="/assets/icon/next.png" width="12px" height="12px">
                </div>
            </div>
    
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
        </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
    </div>
            
</div>