import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { CourseService } from 'src/app/service/course.service';
import { StorageService } from 'src/app/service/storage.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.css']
})
export class DetailCourseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private modalService: MDBModalService,
    private storageService: StorageService
  ) {
    this.course_id = this.route.snapshot.paramMap.get('id');
  }

  modalRef: MDBModalRef;
  loading: boolean = true;
  loading_screen: boolean = true;

  course_id;
  warningMap = new Map();
  selection = new SelectionModel<any>(false, []);
  panelOpenState = false;

  id_establishment;

  course: any = {
    id: null,
    title: '',
    description: '',
    workload_in_minutes: 0,
    videos: [{
      id: null,
      course_id: null,
      title: '',
      link: '',
      order: 0,
      description: '',
      questions: [],
      document: []
    }],
  };

  delete_course = [];
  delete_quizz = [];
  delete_answer = [];
  delete_document = [];

  list_question = [{ name: 'Escolha única', type: 'SINGLE' }, { name: 'Múltipla escolha', type: 'MULTIPLE' }]
  selectedQuestion
  viewSection: boolean = false;

  color_primary
  indexExpanded: number = -1;
  indexExpanded2: number = -1;

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    this.id_establishment = localStorage.getItem('id_establishment')

    this.selectedQuestion = this.list_question[0]

    if (this.course_id != null) this.getCourseId()
    else if (this.course_id == null) {
      this.loading_screen = false;
      this.loading = false
    }

    if (this.course.videos != null && this.course.videos.length > 0) this.viewSection = true;
    else {
      this.loading_screen = false;
      this.loading = false
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.course.videos, event.previousIndex, event.currentIndex);
    // console.log(this.course.videos)
    this.course.videos.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  togglePanels(index: number) {
    this.indexExpanded = index == this.indexExpanded ? -1 : index;
  }

  togglePanels2(index: number) {
    this.indexExpanded2 = index == this.indexExpanded2 ? -1 : index;
  }

  getCourseId() {
    this.courseService.getCourseId(this.course_id).subscribe(data => {
      this.viewSection = true
      this.panelOpenState = true
      this.course = data;

      this.course.videos.sort(this.ordenarList)

      this.course.videos.forEach(video => {
        video['questions'] = []
        video['document'] = []

        if (video.course_video_quizz != null && video.course_video_quizz.length > 0) {
          video.course_video_quizz.forEach(quizz => {
            let aux_answer = []

            quizz.answer.forEach(answe => {
              aux_answer.push({ id: answe.id, correct: false, text: answe.text })
            });

            video.questions.push({ id: quizz.id, title: quizz.quizz, list_answers: aux_answer })
          });
        }

        if (video.course_video_support != null && video.course_video_support.length > 0) {
          video.course_video_support.forEach((docu, idx) => {
            video.document.push({ id: docu.id, name: `Arquivo ${idx + 1}`, file: docu.support_file })
          });
        }


      });

      this.loading_screen = false;
      this.loading = false;
    })
  }

  addVideo() {
    if (this.viewSection) {
      this.course.videos.push({
        id: null,
        course_id: null,
        title: '',
        link: '',
        order: this.course.videos.length + 1,
        description: '',
        questions: []
      })
    }
    else {
      this.viewSection = true;
    }

    this.togglePanels(this.course.videos.length - 1)
  }

  removeVideo(obj, index) {

    if (obj.id != null) {
      this.delete_course.push(obj)
      this.course.videos.splice(index, 1)
    }
    else {
      this.course.videos.splice(index, 1)
    }
  }

  onFilePdf(event, obj, i) {
    console.log(obj, i);

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onloadend = (e) => {

        obj.document.push({ id: null, name: event?.target?.files[0]?.name, file: reader.result })

      }
    }
  }

  removeFile(obj, idx, index) {
    if (obj.id != null) {
      this.delete_document.push(obj)
      this.course.videos[idx].document.splice(index, 1)
    }
    else {
      this.course.videos[idx].document.splice(index, 1)
    }
  }

  addQuizz(video) {
    console.log(video);

    if (video?.questions == null)
      video['questions'] = [];

    video.questions.push({ id: null, title: '', list_answers: [{ id: null, correct: false, text: '' }] })
  }

  removeQuizz(obj, idxV, idxQ) {
    console.log(obj);
    console.log(idxV, idxQ);

    if (obj.id != null) {
      this.delete_quizz.push(obj)
      this.course.videos[idxV].questions.splice(idxQ, 1)
    }
    else {
      this.course.videos[idxV].questions.splice(idxQ, 1)
    }
  }

  addAnswer(question) {
    console.log(question);

    if (question?.list_answers == null)
      question['list_answers'] = [];

    question.list_answers.push({ id: null, correct: false, text: '' })
  }
  
  removeAnswer(obj, idxV, idxQ, idxA) {
    if (obj.id != null) {
      this.delete_answer.push(obj)
      this.course.videos[idxV].questions[idxQ].list_answers.splice(idxA, 1)
    }
    else {
      this.course.videos[idxV].questions[idxQ].list_answers.splice(idxA, 1)
    }
  }

  async saveCourse() {
    this.warningMap = new Map();

    if (this.course.title == null || this.course.title == "") {
      this.warningMap.set('title', true);
    }
    if (this.course.description == null || this.course.description == "") {
      this.warningMap.set('description', true);
    }
    if (this.course.workload_in_minutes == null || this.course.workload_in_minutes == 0) {
      this.warningMap.set('workload_in_minutes', true);
    }
    if (this.course.videos == null || this.course.videos.length == 0) {
      this.warningMap.set('curse_video', true);
    }

    if (this.course.videos != null && this.course.videos.length > 0) {
      this.course.videos.forEach((el, x) => {
        if (el.title == null || el.title == "") {
          this.warningMap.set('title' + x, true);
          if (this.indexExpanded != x) this.togglePanels(x);
        }
        if (el.link == null || el.link == '') {
          this.warningMap.set('link' + x, true);
          if (this.indexExpanded != x) this.togglePanels(x);
        }
        if (el.description == null || el.description == '') {
          this.warningMap.set('description' + x, true);
          if (this.indexExpanded != x) this.togglePanels(x);
        }
        if (el.order == null) {
          this.warningMap.set('order' + x, true);
          if (this.indexExpanded != x) this.togglePanels(x);
        }
      })
    }

    if (this.warningMap.size == 0) {

      this.loading = true;

      // console.log(this.course.videos)

      if (this.course_id == null) this.postCourse()
      else this.patchCourse()

      if (this.delete_course != null && this.delete_course.length > 0) {
        this.delete_course.forEach(item => {
          this.deleteVideo(item)
        });
      }

      if (this.delete_quizz != null && this.delete_quizz.length > 0) {
        this.delete_quizz.forEach(quizz => {
          this.deleteQuizzVideo(quizz)
        });
      }

      if (this.delete_answer != null && this.delete_answer.length > 0) {
        this.delete_answer.forEach(answer => {
          this.deleteAnswerVideo(answer)
        });
      }

      if (this.delete_document != null && this.delete_document.length > 0) {
        console.log(this.delete_document);
        
        this.delete_document.forEach(document => {
          console.log(document);
          
          this.deletDocument(document.id)
        });
      }

      setTimeout(() => {
        this.router.navigate(['/application/course'])
        this.loading = false;
      }, 1000);
    }

  }

  postCourse() {
    this.courseService.postCourse(this.course).subscribe(data => {

      if (this.course.videos != null && this.course.videos.length > 0) {
        this.course.videos.forEach((video, index) => {
          video.course_id = data.id;
          this.postCourseVideo(video, index)
        });
      }
    })
  }

  patchCourse() {
    this.courseService.editCourse(this.course).subscribe(data => {

      if (this.course.videos != null && this.course.videos.length > 0) {
        this.course.videos.forEach((video, index) => {
          video.course_id = data.id;

          if (video.id == null) this.postCourseVideo(video, index)
          else if (video.id != null) this.patchCourseVideo(video, index)

        });
      }

    })
  }

  // VIDEO
  postCourseVideo(video, index) {
    this.courseService.postCourseVideo(video, index).subscribe(data => {
      if (video.questions != null && video.questions.length > 0) {
        video.questions.forEach(el => {
          if (el?.id == null) this.postQuizzVideo(el, data.id)
          else if (el?.id != null) this.editQuizzVideo(el)
        });

        video.document.forEach(el => {
          if (el?.id == null) this.importDocument(el, data.id)
        });
      }
    })
  }
  patchCourseVideo(video, index) {
    this.courseService.editCourseVideo(video, index).subscribe(data => {
      if (video.questions != null && video.questions.length > 0) {
        video.questions.forEach(el => {
          if (el?.id == null) this.postQuizzVideo(el, data.id)
          else if (el?.id != null) this.editQuizzVideo(el)
        });

        video.document.forEach(el => {
          if (el?.id == null) this.importDocument(el, data.id)
        });
      }
    })
  }
  deleteVideo(video) {
    this.courseService.deleteCourseVideo(video).subscribe(data => {
    })
  }

  // QUIZZ
  postQuizzVideo(quizz, video_id) {
    this.courseService.postQuizzVideo(quizz, video_id).subscribe(data => {
      if (quizz.list_answers != null && quizz.list_answers.length > 0) {
        quizz.list_answers.forEach(el => {
          if (el?.id == null) this.postAnswerVideo(el, data.id)
          else if (el?.id != null) this.editAnswerVideo(el)
        });
      }
    })
  }
  editQuizzVideo(quizz) {
    this.courseService.editQuizzVideo(quizz).subscribe(data => {
      if (quizz.list_answers != null && quizz.list_answers.length > 0) {
        quizz.list_answers.forEach(el => {
          if (el?.id == null) this.postAnswerVideo(el, data.id)
          else if (el?.id != null) this.editAnswerVideo(el)
        });
      }
    })
  }
  deleteQuizzVideo(quizz) {
    this.courseService.deleteQuizzVideo(quizz).subscribe(data => {
    })
  }

  // ANSWER
  postAnswerVideo(answer, quizz_id) {
    this.courseService.postAnswerVideo(answer, quizz_id).subscribe(data => {
    })
  }
  editAnswerVideo(answer) {
    this.courseService.editAnswerVideo(answer).subscribe(data => {
    })
  }
  deleteAnswerVideo(answer) {
    this.courseService.deleteAnswerVideo(answer).subscribe(data => {
    })
  }

  // DOCUMENT
  importDocument(file, id_course_video) {
    this.courseService.importDocument(file, id_course_video).subscribe(data => {
    })
  }
  deletDocument(id_document) {
    this.courseService.deletDocument(id_document).subscribe(data => {
    })
  }

  openView() {
    this.modalRef = this.modalService.show(CellPhoneComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        listImage: this.course.videos,
        isEdit: true,
        isPopup: 'course'
      }
    })
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }
}

