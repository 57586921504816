<div class="screen">

    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
        <h3> Cadastro </h3>

    </div>

    <div class="div-row">
        <div class="coll-info">
            <input mat-input [(ngModel)]="register.title" placeholder="Escreva aqui um título" 
                class="input" [ngStyle]="{'border-color': warningMap.get('title')? 'red' : ''}" >

            <textarea mat-input [(ngModel)]="register.description" style="resize: none;"class="input" 
                placeholder="Escreva aqui a descrição" 
                [ngStyle]="{'border-color': warningMap.get('description')? 'red' : ''}">
            </textarea>
            
            <div class="input" style="width: 100%; margin: 0; display: flex; 
                justify-content: space-between; align-items: center;"
                [ngStyle]="{'border-color': warningMap.get('term')? 'red' : ''}">

                <button mat-button class="button-cor" [style.background]="color_primary" (click)="fileInput.click()" > 
                    Selecione o arquivo
                </button>

                <input matInput [(ngModel)]="pdfTerm" readonly style="width: calc(100% - 210px);" 
                    placeholder="Selecione o arquivo dos termos">

                <a *ngIf="register?.terms != null && (register?.terms?.substring(0,4) == 'http')" (click)="openPdf(register?.terms)"> 
                    <svg style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;" viewBox="0 0 1300 1300" >
                        <path [style.fill]="color_primary" d="M490 1269 c-249 -71 -415 -239 -479 -484 -14 -52 -14 -238 0 -290 65
                        -249 249 -431 489 -485 66 -14 226 -12 297 5 213 50 379 206 459 430 22 63 31
                        265 15 333 -54 223 -205 394 -415 470 -60 22 -90 26 -201 28 -71 2 -146 -1
                        -165 -7z m319 -125 c174 -58 303 -198 351 -379 14 -56 12 -201 -4 -261 -22
                        -82 -76 -174 -141 -239 -188 -188 -480 -210 -691 -52 -142 107 -214 248 -215
                        423 -1 120 24 203 92 304 134 200 375 280 608 204z"/>
                        <path [style.fill]="color_primary" d="M620 943 c-48 -18 -66 -77 -34 -118 20 -27 90 -27 110 0 19 26 18 79
                        -3 97 -22 19 -53 28 -73 21z"/>
                        <path [style.fill]="color_primary" d="M608 739 c-16 -9 -18 -30 -18 -202 0 -207 2 -217 50 -217 48 0 50 10
                        50 217 0 180 -1 193 -19 203 -24 12 -41 12 -63 -1z"/>
                    </svg>

                    <!-- <img src="../assets/image/information.png"
                        style="width: 25px; height: 25px; object-fit: contain; cursor: pointer;"> -->
                </a> 

                <input type="file" #fileInput style="display: none;" 
                    accept="application/pdf" (change)="onFilePdf($event)">
            </div>
            

        </div>

        <div class="coll-img input-img">

            <div *ngIf="register.image == null" class="button-image" (click)="fileImage.click()"
                [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}"> 
                adicione uma imagem 
            </div>
    
            <input type="file" #fileImage style="display: none; " accept="image/*" 
                (change)="onFileChanged($event)">
    
            <img [src]="register.image" (click)="fileImage.click()" *ngIf="register.image != null" 
                class="profile-image">
        </div>
    </div>

    <div class="border-gray space-between-center w_100">
        <h3 style="margin: 0; color: white;"> Selecione as opções obrigatórias abaixo: </h3>
    </div>

    <div class="div-toggle">
        <div class="container">
            <div cdkDropList #todo1List="cdkDropList" [cdkDropListData]="todo1" class="exampe-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List]">

                <div class="example-box" *ngFor="let item of todo1" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo2List="cdkDropList" [cdkDropListData]="todo2" class="exampe-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List]">

                <div class="example-box" *ngFor="let item of todo2" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo3List="cdkDropList" [cdkDropListData]="todo3" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo3" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo4List="cdkDropList" [cdkDropListData]="todo4" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo4" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo5List="cdkDropList" [cdkDropListData]="todo5" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo5" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo6List="cdkDropList" [cdkDropListData]="todo6" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo6" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo7List="cdkDropList" [cdkDropListData]="todo7" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo7" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="container">
            <div cdkDropList #todo8List="cdkDropList" [cdkDropListData]="todo8" class="example-list toggle input" (cdkDropListDropped)="drop($event)"
            [cdkDropListSortPredicate]="sortPredicate"
            [cdkDropListConnectedTo]="[todo1List, todo2List, todo3List, todo4List, todo5List, todo6List, todo7List, todo8List]">

                <div class="example-box" *ngFor="let item of todo8" cdkDrag>
                    <h2> {{ item.title }} </h2>
                    <mat-slide-toggle class="options" [(ngModel)]="item.active" (click)="phoneOrEmail(item.title, !item.active)"> Ativo </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active" 
                        [(ngModel)]="item.active_mandatory"> Obrigatório </mat-slide-toggle>
                    <mat-slide-toggle class="options" *ngIf="item.active && item.options"
                        [(ngModel)]="item.check_sms"> Validar número por SMS </mat-slide-toggle>

                    <mat-checkbox class="item-margin" *ngIf="item.check_sms  && item.options" style="line-height: normal !important;"
                        [(ngModel)]="item.checkbox_sms">Concordo com a cobrança de <br> R$ 00,10 por SMS</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="div-empty"></div>
        <div class="div-empty"></div>
        <div class="div-empty"></div>
    </div>

    <div style="display: flex; margin-top: 10px;">
        <button mat-button class="button-view" [style.background]="color_primary" (click)="openView()"> 
            <span> <img src="/assets/icon/icon-olho.png" class="icon"> </span> 
            Veja como ficou a configuração
        </button>

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="checkInputs()" > 
            {{ config_id == null ? 'Finalizar Configuração' : 'Salvar Configuração' }} 
            <span class="spinner-border spinner-border-sm m_loading" *ngIf="loading" role="status" aria-hidden="true"></span>
        </button>
    </div>
            
</div>