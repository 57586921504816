<div style="margin: 0 50px;">
    <br><br>

    <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
        src="../../../../assets/image/logo_principal_SBS.png" />
    <h2>Formulário de Inspeção de Extintores MENSAL</h2>

    <br><br>

    <table>
        <tr style="font-size: 10px;">
            <th> Local </th>
            <th> Verificado por </th>
            <th> Data </th>
            <th> Hora </th>
            <th> Mês </th>
        </tr>

        <tr style="font-size: 8px;">
            <td> {{ title }} </td>
            <td> {{ header['verification'] }} </td>
            <td> {{ header['data'] }} </td>
            <td> {{ header['hour'] }} </td>
            <td> {{ header['mother'] }} </td>
        </tr>
    </table>

    <br><br>

    <table>
        <tr style="font-size: 10px;">
            <th style="width: 20%"> Localização </th>
            <th style="width: 20%"> Numero Chassis </th>
            <th style="width: 10%"> Tipo </th>
            <th style="width: 20%"> Capacidade </th>
            <th style="width: 20%"> Garantia Serv. </th>
            <th style="width: 20%"> Validade Teste </th>
            <th style="width: 5%"> C </th>
            <th style="width: 5%"> N/C </th>
        </tr>

        <tr *ngFor="let row of table" style="font-size: 8px;">
            <td> {{ row['Localização'] }} </td>
            <td> {{ row['Numero Chassis'] }} </td>
            <td> {{ row['Tipo'] }} </td>
            <td> {{ row['Capacidade'] }} </td>
            <td> {{ row['Garantia Serv'] }} </td>
            <td> {{ row['Validade Teste'] }} </td>
            <td> {{ row['C'] }} </td>
            <td> {{ row['N/C'] }} </td>
        </tr>
    </table>

    <br><br>

    <!-- <table style="max-width: 500px;">
      <tr style="font-size: 10px;">
          <th> Descrição </th>
          <th> Quantidade </th>
      </tr>
      
      <tr *ngFor="let row of list_description" style="font-size: 8px;">
          <td> EXT. AGUA PRESS. - 10 L </td>
          <td> 9 </td>
      </tr>
  </table>

  <br><br> -->

    <table>
        <tr style="font-size: 10px;">
            <th> Elaborado </th>
            <th> Revisado: </th>
            <th> Aprovado </th>
            <th> Revisão: </th>
            <th> Data: </th>
            <th> Referência </th>
        </tr>

        <tr style="font-size: 8px;">
            <td> {{ footer['Elaborado'] }} </td>
            <td> </td>
            <td> {{ footer['Aprovado'] }} </td>
            <td> </td>
            <td> {{ footer['Data'] }} </td>
            <td> {{ footer['Referência'] }} </td>
        </tr>
    </table>
</div>