import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
    @Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    @ViewChild('slickModal') slickModal: SlickCarouselComponent;

    constructor(
        private campaignService: CampaignService,
        private storageService: StorageService,
        private router: Router
    ) {
        this.url_screen = router.url;
    }

    url_screen;
    listImage;
    qrcode_id;

    loading: boolean = true;
    url = AppConfig.path;
    position: number = 0;
    refreshTimer

    type_page: string = '';

    ngOnInit(): void {
        // console.log("-> Galeria <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;


        this.storageService.screen.format = 'Galeria';
        this.storageService.screen.id = this.list.id;

        this.campaignService.watchList().subscribe(data => {
            this.listImage = this.tratamento(this.list)
            clearInterval(this.refreshTimer);
            this.timerNext()
        })


        this.listImage = this.tratamento(this.list)
        clearInterval(this.refreshTimer);
        this.timerNext()
    }

    timerNext() {
        var time = 0
        this.refreshTimer = setInterval(function () {
            if (time == 3) {
                if (document.getElementById('bt_next') != null) {
                    document.getElementById('bt_next').click()
                }
                time = 0
            }
            else time += 1;

        }, 1000);
    }

    next() {
        clearInterval(this.refreshTimer);
        this.timerNext()


        if (this.listImage.length - 1 > this.position) {
            this.position += 1;
        }
        else
            this.position = 0;

    }

    prev() {
        clearInterval(this.refreshTimer);
        this.timerNext()


        if (this.position > 0) {
            this.position -= 1;
        }
        else
            this.position = this.listImage.length - 1;

    }

    setCarrousel() {
        this.position += 1;
    }

    async addNewItem(list) {
        console.log('->', list);
        
        if (this.url_screen != '/application/dashboard')
            await this.storageService.saveHistoric(this.campaign_id, list.id, (list.homepage_format || list.page_format), 2, this.type_page, this.qrcode_id)

        if (this.preview_campaign) {
            console.log(1);
            
            if (list?.type?.name == "Link") {
                
                this.openLink2(list)
            }
            if (list?.type?.name == "Vazio") {
                return
            }
            else
            this.newItemEvent.emit(list);
        }
        else {
            if (list?.page_format_obj?.name == "Link") {
                if (list.page_images != null) {
                    this.openLink(list.page_images[0].link)
                }
                else if (list.internal_images != null) {
                    this.openLink(list.internal_images[0].link)
                }
            }
            else if (list?.page_format_obj?.name == "Vazio") {
                return
            }
            else if (list?.page_format_obj != null) {
                if(list.page_format_obj.id == 17){
                    this.newItemEvent.emit(list.page_images[0]);
                }
                else{
                    this.newItemEvent.emit(list);
                }
            }
        }

    }

    openLink2(link) {
        if (link.link != null && link.link != '' && link.option_link != null) {
            if (link.option_link == "") {
                if(link.link.substring(0, 3) == 'sbs'){
                    this.newItemEvent.emit(`openReport/${link}`);
                }
                else if (link.link.substring(0, 4) != 'http') {
                    var aux_link = link.link.replace(/\s/g, '');
                    window.open(`http://${aux_link}`);
                }
                else {
                    var aux_link = link.link.replace(/\s/g, '');
                    window.open(aux_link);
                }
            }
            else {
                var aux_link = link.link.replace(/\s/g, '');
                window.open(link.option_link + aux_link);
            }

        }
    }

    openLink(link) {
        if (link != null && link != '') {
            if(link.substring(0, 3) == 'sbs'){
                this.newItemEvent.emit(`openReport/${link}`);
            }
            else if (link.substring(0, 3) == 'tel') {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
            else if (link.substring(0, 6) == 'mailto') {
                window.open(link);
            }
            else if (link.substring(0, 4) != 'http') {
                var aux_link = link.replace(/\s/g, '');
                window.open(`http://${aux_link}`);
            }
            else {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
        }
    }

    tratamento(list) {

        if (this.preview_campaign) {
            if (this.list?.images != undefined) {
                return list.images.sort(this.ordenarList);
            }
            else
                return null
        }
        else {
            if (list?.home_images != undefined) {
				this.type_page = 'home';
                return list.home_images.sort(this.ordenarList);
            }
            else if (list?.internal_images != undefined) {
				this.type_page = 'internal';
                return list.internal_images.sort(this.ordenarList);
            }
            else if (list?.page_images != undefined) {
				this.type_page = 'page';
                return list.page_images.sort(this.ordenarList);
            }
            else if (this.list?.home_images != undefined) {
				this.type_page = 'home';
                return list.home_images.sort(this.ordenarList);
            }
            else
                return null
        }

    }

    ordenarList(a, b) {
        if (a.order < b.order)
            return -1;
        if (a.order > b.order)
            return 1;
        return 0;
    }

    slideConfigBanner = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "dots": false,
        "arrows": true,
        "infinite": true,
        "autoplay": false,
        "swipeToSlide": true,
        "autoplaySpeed": 5000,
        "lazyLoad": 'ondemand',
        "adaptiveHeight": true,
        "responsive":
            [{
                "breakpoint": 600,
                "settings": {
                    "dots": false,
                }
            }]
    };
}
