import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith, expand } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { ModalChoiceComponent } from 'src/app/modals/modal-choice/modal-choice.component';
import { PreviewPostComponent } from 'src/app/modals/preview-post/preview-post.component';
import { CampaignService } from 'src/app/service/campaign.service';
import { CatalogService } from 'src/app/service/catalog.service';
import { CompressorService } from 'src/app/service/compressor.service';
import { ConstantsService } from 'src/app/service/constants.service';
import { DesktopService } from 'src/app/service/desktop.service';
import { GroupService } from 'src/app/service/group.service';
import { QrCodeService } from 'src/app/service/qrcode.service';
import { StorageService } from 'src/app/service/storage.service';
import { TagService } from 'src/app/service/tag.service';

@Component({
  selector: 'app-create-campaign-feed',
  templateUrl: './create-campaign-feed.component.html',
  styleUrls: ['./create-campaign-feed.component.css']
})
export class CreateCampaignFeedComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private router: Router,
    private constantsService: ConstantsService,
    private catalogService: CatalogService,
    private campaignService: CampaignService,
    private qrCodeService: QrCodeService,
    private tagService: TagService,
    private groupService: GroupService,
    private compressor: CompressorService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private modalService: MDBModalService,
    private desktopService: DesktopService
  ) {
    this.campaign_id = this.route.snapshot.paramMap.get('id');
  }

  @ViewChild('establishmentInput') establishmentInput: ElementRef<HTMLInputElement>;
  @ViewChild('establishmentInputTag') establishmentInputTag: ElementRef<HTMLInputElement>;
  @ViewChild('establishmentInputGroup') establishmentInputGroup: ElementRef<HTMLInputElement>;

  warningMap = new Map();
  modalDelet: MDBModalRef;
  modalRef: MDBModalRef;


  loading = {
    establishment: false,
    constants: false,
    finish: false,
    screen: false
  }

  // campaign
  campaign_obj: any;
  create_campaign: boolean = true;
  campaign_id = null;
  language: string;
  campaign_name: string = "";
  show_header: boolean = true;
  campaign_header: string = "";
  registration_type: string;
  mobile = { android: false, ios: false };
  color_background: string = "#000000";
  color_text: string = "#ffffff";
  color_icons: string = '#ffffff'
  selected_colors = { bg: false, text: false, icon: false };
  whatsapp: string = '';
  image_logo = { name: '', url: null };
  side_menu = { name: '', url: null };
  pdfTerm = null;
  pdfTerm_name;
  accesses = []
  input_facebook: string = '';
  input_instagram: string = '';
  input_youtube: string = '';
  is_point_control: boolean = false;

  color_primary;
  actual_step: string = "campaign";
  campaign_select;

  homepage_types
  page_types

  surveys = [];
  catalogs = [];
  campaigns = [];

  // qrcode chips
  qrcodes = [];
  arrayChips = [];
  listQrCode = [];
  deliteQrcode = [];
  status_qr: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  qrcodeChipsFormControl = new FormControl();
  filteredQrcodes: Observable<string[]>;
  id_qrcode;

  // group chips
  groups = [];
  arrayGroup = [];
  listGroup = [];
  deliteGroup = [];
  separatorKeysCodesGroup: number[] = [ENTER, COMMA];
  groupChipsFormControl = new FormControl();
  filteredGroups: Observable<string[]>;

  // tag chips
  tags = [];
  list_tag_campaign = { homepage: [], page: [], internal: [] }
  tagArrayChips = [];
  listTag = [];
  deliteTag = [];
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  tagChipsFormControl = new FormControl();
  filteredTag: Observable<string[]>;

  page = {
    survey: 1,
    coupon: 1,
    qrcode: 1,
    tag: 1,
    group: 1,
    campaign: 1,
    backup: 1
  }
  /* pagination related variables */
  campaign_response;
  num: number;
  page_site: number = 1
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;
  total_results: number;

  remove = {
    homepage: [],
    page: [],
    internal: []
  }

  steps = { campaign: false, homepage: false, homepage_images: false, page_types: false, internal_pages: false };

  validTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF']
  selected_homepage_type; // homepage type object
  compressed_image = []; // as the name says...

  feed_obj: any;
  list_campaign_feed: any;
  displayedColumns: string[] = ['codigo', 'campaign', 'type', 'publication', 'view'];
  dataSource: MatTableDataSource<any>;

  displayedColumnsSidebar: string[] = ['codigo', 'title', 'type', 'position', 'publication', 'view'];
  dataSourceSidebar: MatTableDataSource<any>;

  posts_list = []
  posts_list_backup = []

  ngOnInit(): void {
    this.loading.screen = true;

    this.whatsapp = '55'
    this.color_primary = this.storageService.establishment.color;

    // this.steps.campaign = true  //remove
    // this.actual_step = "table"; //remove

    if (this.campaign_id != null) {
      this.getCampaignId()
      this.getCampaignsPosts()
      this.steps.campaign = true
      this.actual_step = "table";
    }

    this.getConstants()
    this.getCatalogs(1)
    this.getCampaigns(1)
    this.getQrCode(this.page.qrcode)
    this.getTags(this.page.tag)
    this.getGroups(this.page.group)
    // this.getDesktop()
  }

  getCampaignId() {
    this.campaignService.getCampaignId(this.campaign_id).subscribe(data => {
      this.create_campaign = false;
      // console.log('AFTER Campaign', data)

      this.campaign_obj = data;
      this.campaign_name = data.name;
      this.campaign_header = data.header_text;

      if (data.qr_codes != null) {
        this.status_qr = true;
        data.qr_codes.forEach(qr => {
          this.arrayChips.push(qr.qr_code_obj[0])
        });
      }

      if (data.accesses != null) {
        this.accesses = data.accesses;
        data.accesses.forEach(access => {
          if (access.device != null) {
            if (access.device == 1) {
              this.mobile.ios = true
            }
            else if (access.device == 2) {
              this.mobile.android = true
            }
          }
          if (access.group != null) {
            this.arrayGroup.push(access.group_obj)
            this.listGroup.push(access)
          }
        });
      }

      this.listQrCode = data.qr_codes;
      this.registration_type = data.request_registration;
      this.color_background = data.header_color;
      this.color_text = data.text_color;
      this.color_icons = data.icons_color;
      this.selected_colors.bg = data.header_color != null ? true : false;
      this.selected_colors.text = data.text_color != null ? true : false;
      this.selected_colors.icon = data.icons_color != null ? true : false;
      this.whatsapp = (data.whatsapp != null && data.whatsapp != '') ? data.whatsapp : '+55';
      this.selected_homepage_type = data.homepage_format_obj;
      this.id_qrcode = data?.qr_codes != null ? data?.qr_codes[0]?.qr_code : null;
      this.image_logo.url = data?.logo != null ? data?.logo : '';
      this.side_menu.url = data?.side_menu_image != null ? data?.side_menu_image : '';
      this.input_facebook = data.facebook;
      this.input_instagram = data.instagram;
      this.input_youtube = data.youtube;
      this.campaign_select = data.campaing;
      this.show_header = data.show_header;
      this.is_point_control = data.is_point_control;

      if (data.show_header && data.term != null) {
        this.pdfTerm = data.term;
        let aux_term = data.terms.split('images/');
        this.pdfTerm_name = aux_term[1];
      }

      this.dataSourceSidebar = new MatTableDataSource(data.desktop_items);


      this.loading.screen = false;
      // console.log('BEFORE Campaign', this.homepage)
    })

    // this.chechListTags()
  }

  getCampaignsPosts() {
    // console.log(this.page.campaign);

    this.campaignService.getCampaignsPosts(this.page.campaign, this.campaign_id, 10).subscribe(data => {
      this.posts_list = null;
      this.dataSource = new MatTableDataSource([]);

      this.page.backup = Object.assign({}, this.page.campaign)

      this.feed_obj = data;
      this.dataSource = new MatTableDataSource(data.results.sort(this.ordenarList));
      this.campaign_response = data;

      this.posts_list = data.results.sort(this.ordenarList)

      // pagination calc
      this.count_total = data.count;
      this.page_count1 = 1 + ((this.page.campaign - 1) * this.page_size)
      if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
        this.page_count2 = this.count_total;
      }
      else {
        this.page_count2 = this.page_count1 + (this.page_size - 1);
      }

      setTimeout(() => {
        let save_order = false
        this.posts_list.forEach((post, index) => {
          if (post.order != (this.page_count1 - 1) + index) {
            console.log(post.order, (this.page_count1 - 1) + index);
            save_order = true
            post.order = ((this.page_count1 - 1) + index)
          }
        });
        if (save_order) this.saveOrder()

        this.setListBackup(this.posts_list, this.page.campaign)

        if (this.campaign_response.next != null) {
          this.page.backup = + 1;
          this.addNewPageList()
        }
      }, 100);

      // this.dataSource.sort = this.sort;
    },
      error => {
      })
  }

  setListBackup(list, page) {
    let result = this.posts_list_backup.filter(el => el.page == page)

    if (result == null || result.length == 0) {
      this.posts_list_backup.push({ page: page, list: list })
    }
  }

  addNewPageList() {
    if (this.page.campaign > this.page.backup)
      this.page.backup = Object.assign({}, this.page.campaign)

    this.campaignService.getCampaignsPosts(this.page.backup, this.campaign_id, 10).subscribe(data => {
      this.setListBackup(data.results.sort(this.ordenarList), this.page.backup)
    },
      error => {
      })
  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  orderSection(obj_main, index, type) {
    let post_move_up, post_move_down
    let page_atual = this.page.campaign

    // console.log(obj_main);
    console.log(index);
    console.log(type);

    if (type == 'up') {
      post_move_up = obj_main;
      post_move_down = this.posts_list[index];

      if (post_move_down == null) {
        page_atual--
        post_move_down = this.returnObjList(page_atual, type)
      }
    }
    else if (type == 'down') {
      post_move_down = obj_main;
      post_move_up = this.posts_list[index];

      if (post_move_up == null) {
        page_atual++
        post_move_up = this.returnObjList(page_atual, type)
      }
    }

    setTimeout(() => {
      console.log('up: ', post_move_up);
      console.log('down: ', post_move_down);


      if (type == 'up') {
        post_move_up.order = post_move_down.order;
        post_move_down.order = Number(post_move_down.order) + 1;
      }
      else if (type == 'down') {
        post_move_down.order = post_move_up.order;
        post_move_up.order = Number(post_move_up.order) - 1;
      }

      if (post_move_down.order < 0) post_move_down.order = 0;
      if (post_move_up.order < 0) post_move_up.order = 0;

      this.editOrderProduct(post_move_up)
      this.editOrderProduct(post_move_down)

      setTimeout(() => {
        this.posts_list_backup = []
        this.getCampaignsPosts()
      }, 100);
    }, 100);

  }

  returnObjList(page_aux, type) {
    let result = this.posts_list_backup.filter(el => el.page == page_aux)
    console.log(result);

    if (result != null && result.length > 0) {
      console.log(1);

      if (type == 'down') return result[0].list[0]
      else if (type == 'up') return result[0].list[result[0].list.length - 1]
    }
    else {
      console.log(2);
      if (type == 'up') page_aux--
      else if (type == 'down') page_aux++

      let result = this.posts_list_backup.filter(el => el.page == page_aux)
      console.log(result);

      if (result != null && result.length > 0) {
        console.log(3);
        if (type == 'down') return result[0].list[0]
        else if (type == 'up') return result[0].list[result[0].list.length - 1]
      }
    }

  }

  nextPage() {
    // this.saveOrder()

    setTimeout(() => {
      if (this.campaign_response.next != null) {
        this.page.campaign += 1;
        this.getCampaignsPosts()
      }
    }, 100);
  }

  backPage() {
    // this.saveOrder()

    setTimeout(() => {
      if (this.campaign_response.previous != null) {
        this.page.campaign -= 1;
        this.getCampaignsPosts()
      }
    }, 100);
  }

  saveOrder() {
    this.posts_list.forEach((post, index) => {
      // post.order = ((this.page_count1 - 1) + index)

      setTimeout(() => {
        this.campaignService.editOrderPost(post).subscribe()
      }, 10);
    });
  }

  editOrderProduct(item) {
    this.campaignService.editOrderPost(item).subscribe(data => {
    })
  }

  getLastItem() {

  }

  getConstants() {
    this.loading.constants = true;

    this.constantsService.getConstants().subscribe(data => {

      this.homepage_types = data.homepage_types;
      this.page_types = data.page_types;

      // hotfix Lara
      data.homepage_types = data.homepage_types.splice(AppConfig.qtd_homepage_types)
      data.page_types = data.page_types.splice(AppConfig.qtd_page_types)

      var position_1 = this.homepage_types.findIndex(el => el.id == 7)
      this.homepage_types.splice(position_1, 1)

      this.loading.constants = false;

    },
      error => {
        this.loading.constants = false;
      })
  }

  getCatalogs(page) {
    this.catalogService.getCatalogs(page).subscribe(data => {
      this.catalogs = this.catalogs.concat(data.results);
      if (data.next != null) {
        this.getCatalogs(page + 1)
      }
    })
  }

  getCampaigns(page) {
    this.campaignService.getCampaigns(page).subscribe(data => {
      this.campaigns = this.campaigns.concat(data.results);

      if (data.next != null) {
        this.getCampaigns(page + 1)
      }
      else {
        // console.log(this.campaigns)
      }
    })
  }

  getQrCode(page) {
    this.qrCodeService.getQrCodes(page).subscribe(data => {
      this.qrcodes = this.qrcodes.concat(data.results)

      if (data.next != null) {
        this.page.qrcode += 1
        this.getQrCode(this.page.qrcode)
      }
      else {
        this.filteredQrcodes = this.qrcodeChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter(item) : this.qrcodes.slice()));
      }
    },
      error => {
      })
  }

  private _filter(value: string): string[] {
    if (value != null) {
      const filterValue = value;
      return this.qrcodes.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  removeQrCodeChips(qrcode: string): void {
    const index = this.arrayChips.indexOf(qrcode);

    if (index >= 0) {
      this.deliteQrcode.push(this.listQrCode[index])
      this.arrayChips.splice(index, 1);
      this.listQrCode.splice(index, 1);
    }
  }

  qrcodeChipsSelected(event: MatAutocompleteSelectedEvent): void {
    if (!this.arrayChips.includes(event.option.value.name)) {

      this.arrayChips.push(event.option.value);

      if (this.listQrCode == null || this.listQrCode.length == 0) {
        this.listQrCode = []
        // this.listQrCode.push({ qr_code_obj: [] })
      }
      var size = this.listQrCode.length != 0 ? this.listQrCode.length - 1 : 0
      // this.listQrCode[size].qr_code_obj.push(event.option.value);
      let aux = event.option.value
      this.listQrCode.push({ campaign: Number(this.campaign_id), campaign_obj: null, id: null, qr_code: aux.id, qr_code_obj: [aux] });
    }

    // console.log(this.listQrCode)

    this.establishmentInput.nativeElement.value = '';
    this.qrcodeChipsFormControl.setValue(null);
  }

  getTags(page) {
    this.tagService.getAllTags(page).subscribe(data => {
      this.tags = this.tags.concat(data.results)

      if (data.next != null) {
        this.page.tag += 1
        this.getTags(this.page.tag)
      }
      else {
        this.filteredTag = this.tagChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter2(item) : this.tags.slice()));
      }
    },
      error => {
      })
  }

  private _filter2(value: string): string[] {
    if (value != null) {
      const filterValue = value;
      return this.tags.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  removeTagChips(qrcode: string): void {
    const index = this.tagArrayChips.indexOf(qrcode);

    if (index >= 0) {
      this.deliteTag.push(this.listTag[index])
      this.tagArrayChips.splice(index, 1);
      this.listTag.splice(0, 1);
    }
  }

  tagChipsSelectedTag(event: MatAutocompleteSelectedEvent): void {
    if (!this.tagArrayChips.includes(event.option.value.name)) {

      this.tagArrayChips.push(event.option.value);

      if (this.listTag == null || this.listTag.length == 0) {
        this.listTag = []
        // this.listTag.push(tag_obj)
      }
      var size = this.listTag.length != 0 ? this.listTag.length - 1 : 0;
      this.listTag.push(event.option.value);
    }

    this.establishmentInputTag.nativeElement.value = '';
    this.tagChipsFormControl.setValue(null);

    // console.log(this.listTag)
  }

  getGroups(page) {
    this.groupService.getGroups(page, null).subscribe(data => {
      this.groups = this.groups.concat(data.results)

      if (data.next != null) {
        this.page.group += 1
        this.getGroups(this.page.group)
      }
      else {
        this.filteredGroups = this.groupChipsFormControl.valueChanges.pipe(
          startWith(null),
          map((item: string | null) => item ? this._filter3(item) : this.groups.slice()));
      }
    })
  }

  // getDesktop() {
  //   this.desktopService.getDesktops().subscribe(data => {
  //     this.dataSourceSidebar = new MatTableDataSource(data.results);
  //   },
  //     error => {
  //       console.log(error)
  //     })
  // }

  removeGroupChips(group: string): void {
    const index = this.arrayGroup.indexOf(group);

    if (index >= 0) {
      this.deliteGroup.push(this.listGroup[index])
      this.arrayGroup.splice(index, 1);
      this.listGroup.splice(index, 1);
    }
  }

  groupChipsSelected(event: MatAutocompleteSelectedEvent): void {
    if (!this.arrayGroup.includes(event.option.value.name)) {

      this.arrayGroup.push(event.option.value);

      if (this.listGroup == null || this.listGroup.length == 0) {
        this.listGroup = []
      }

      this.listGroup.push(event.option.value);
    }

    this.establishmentInputGroup.nativeElement.value = '';
    this.groupChipsFormControl.setValue(null);
  }

  private _filter3(value: string): string[] {
    if (value != null) {
      const filterValue = value;
      return this.groups.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  nextStep() {
    if (this.checkInputs('home', null)) {
      if (this.create_campaign) {
        this.postCampaign()
      }
      else {
        this.pacthCampaign()
      }
    }
  }

  checkInputs(text, position) {
    this.warningMap = new Map();


    if (text == 'home') {
      if (this.campaign_name == "") {
        this.warningMap.set('campaign_name', true);
      }
      if (this.show_header && this.campaign_header == "") {
        this.warningMap.set('campaign_header', true);
      }
      if (!this.selected_colors.bg) {
        this.warningMap.set('bg_color', true)
      }
      if (!this.selected_colors.text) {
        this.warningMap.set('text_color', true)
      }
      if (this.show_header && !this.selected_colors.text) {
        this.warningMap.set('icon_color', true)
      }
    }

    if (this.warningMap.size == 0) {
      return true
    }
    else {
      return false
    }
  }

  deletLogo() {
    this.image_logo.name = '';
    this.image_logo.url = '';
  }

  deletSide() {
    this.side_menu.name = '';
    this.side_menu.url = '';
  }

  onFilePdf(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onloadend = (e) => {
        this.pdfTerm = reader.result
        this.pdfTerm_name = event?.target?.files[0]?.name;

      }
    }

  }

  openPdf(link) {
    if (link != null) {
      var aux_link = link.replace(/\s/g, '');
      window.open(aux_link, '_blanck');
    }
  }

  async addLogo(event) {

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])
        reader.onloadend = async (e) => {
          var final_file = reader.result

          this.image_logo.name = filename
          this.image_logo.url = final_file
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            this.image_logo.name = this.compressed_image[0].name
            this.image_logo.url = this.compressed_image[0].url
            this.compressed_image = []
          }
        });
      }
    }

    // console.log(this.image_logo)
  }

  async addImageSide(event) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/gif') {
        const valid = this.validTypes.filter(e => { if (event.target.files[0].name.includes(e)) return e })

        if (!event.target.files[0].name.includes(valid[0])) {
          return
        }

        var file = event.target.files[0];
        var filename = file.name;
        var target = event.target.files

        var reader = new FileReader()
        reader.readAsDataURL(target[0])
        reader.onloadend = async (e) => {
          var final_file = reader.result

          this.side_menu.name = filename
          this.side_menu.url = final_file
        }
      }
      else {
        var target = event.target.files
        const compress = this.recursiveCompress(target[0], 0, target).pipe(
          expand(res => {
            return res.index > res.array.length - 1
              ? EMPTY
              : this.recursiveCompress(target[res.index], res.index, target);
          }),
        );
        compress.subscribe(res => {
          if (res.index > res.array.length - 1) {
            this.side_menu.name = this.compressed_image[0].name
            this.side_menu.url = this.compressed_image[0].url
            this.compressed_image = []
          }
        });
      }
    }

    // console.log(this.image_logo)
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        if (this.remove.homepage.length >= 1) {
          // this.compressed_image.push(this.remove.homepage[index].url)
          var aux_id = this.remove.homepage[index].id != null ? this.remove.homepage[index].id : null
          this.compressed_image.push({ id: aux_id, url: response, name: array[index].name, quantity: null, type: null, link: null, })
        }
        else
          this.compressed_image.push({ url: response, name: array[index].name, quantity: null, type: null, link: null, })
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );

  }

  postCampaign() {

    this.campaignService.postCampaign(this.campaign_name, this.show_header,
      this.campaign_header, this.registration_type, this.color_background,
      this.color_text, this.color_icons, this.pdfTerm, null, null, this.whatsapp,
      this.image_logo.url, this.input_facebook, this.input_instagram,
      this.input_youtube, this.side_menu.url, true).subscribe(data => {

        this.campaign_id = data.id

        if (this.arrayChips.length != 0) {
          this.listQrCode.forEach((qrcode, index) => {
            this.qrCodeService.postQrCodeCampaign(data.id, this.arrayChips[index].id).subscribe(data => {
              // success
            }, error => {
            })
          })
        }

        if (this.campaign_select != null) {
          // console.log(this.campaign_select)

          this.campaignService.postNextCampaign(this.campaign_id, this.campaign_select).subscribe(data => {
            // console.log(data)
          })
        }

        // hotfix Lara
        if (this.mobile.android) {
          this.campaignService.postCampaignAccess(data.id, 2, null).subscribe(data => {
            // success
          }, error => { })
        }
        if (this.mobile.ios) {
          this.campaignService.postCampaignAccess(data.id, 1, null).subscribe(data => {
            // success
          }, error => { })
        }

        if (this.arrayGroup.length != 0) {
          this.listGroup.forEach((group, index) => {
            this.campaignService.postCampaignAccess(data.id, null, this.arrayGroup[index].id).subscribe(data => {
              // success
            }, error => { })
          })
        }

        this.steps.campaign = true
        this.actual_step = "table";
        this.router.navigate([`/application/content/content-feed/${this.campaign_id}`])
      })
  }

  pacthCampaign() {
    this.campaignService.patchCampaign(this.campaign_id, this.campaign_name, this.show_header,
      this.campaign_header, this.registration_type, this.color_background,
      this.color_text, this.color_icons, this.pdfTerm, null, null, this.whatsapp,
      this.image_logo.url, this.input_facebook, this.input_instagram,
      this.input_youtube, this.side_menu.url, this.is_point_control).subscribe(data => {

        if (this.arrayChips.length != 0) {
          this.listQrCode.forEach((qrcode, index) => {
            if (qrcode.id == null) {
              this.qrCodeService.postQrCodeCampaign(data.id, qrcode.qr_code).subscribe(data => {
                // success
              })
            }
          })
        }

        if (this.campaign_select != null) {
          // console.log(this.campaign_select)

          this.campaignService.postNextCampaign(this.campaign_id, this.campaign_select).subscribe(data => {
            // console.log(data)
          })
        }

        if (this.deliteQrcode.length > 0) {
          this.deliteQrcode.forEach(qrcode => {
            if (qrcode.id != null) {
              this.qrCodeService.deleteQrCodeCampaign(qrcode.id).subscribe(data => {
                // success
              })
            }
          });
        }

        this.checkSegmentationDevice(1, this.mobile.ios)
        this.checkSegmentationDevice(2, this.mobile.android)

        this.accesses.filter(el => el.group != null).forEach(obj => {
          this.campaignService.deleteCampaignAccess(obj.id).subscribe(
            // data => console.log('ok'),
            error => console.log('error')
          )
          //console.log('del ', obj.id)
        })

        this.arrayGroup.forEach(el => {
          this.campaignService.postCampaignAccess(this.campaign_id, null, el.id).subscribe(
            // data => console.log('ok'),
            error => console.log('error')
          )
          //console.log('add ', el.id)
        })

        this.openSnackBar("Alteração salva!", "Fechar")
      })
  }

  checkSegmentationDevice(device_id, value) {
    let devices = this.accesses.filter(el => el.device == device_id)
    if (value && devices.length <= 0) {
      this.campaignService.postCampaignAccess(this.campaign_id, device_id, null).subscribe(
        // data => console.log('ok'),
        error => console.log('error')
      )
    }
    else if (!value && devices.length > 0) {
      this.campaignService.deleteCampaignAccess(devices[0].id).subscribe(
        // data => console.log('ok'),
        error => console.log('error')
      )
    }
  }

  previewFeed() {
    // console.log(this.campaign_header)
    // console.log(this.campaign_obj)
    // console.log(this.feed_obj)

    // this.feed_obj.forEach(el => {
    //   this.list_campaign_feed.push({ obj: el, sendFormat: el.homepage_format_obj, qrcode: '' })
    // });

    this.storageService.header.title = this.campaign_obj.header_text;
    this.storageService.header.description = this.campaign_obj.header_text;
    this.storageService.header.color = this.campaign_obj.text_color;
    this.storageService.header.back = this.campaign_obj.header_color;
    this.storageService.header.whatsapp = this.campaign_obj.whatsapp != '55' ? this.campaign_obj.whatsapp : null;
    this.storageService.header.logo = this.campaign_obj.logo;
    this.storageService.header.facebook = this.campaign_obj.facebook;
    this.storageService.header.instagram = this.campaign_obj.instagram;
    this.storageService.header.youtube = this.campaign_obj.youtube;
    var color_ = this.campaign_obj.icons_color != null && this.campaign_obj.icons_color != '' ? this.campaign_obj.icons_color : 'grey';
    this.storageService.header.show_header = this.campaign_obj.show_header;

    // console.log(this.list_campaign_feed)

    this.modalRef = this.modalService.show(PreviewPostComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        campaign: this.campaign_obj,
        color_primary: color_
      }
    })
  }

  addPost() {
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}/new-posts`])
  }

  editPost(obj) {
    // console.log(obj)
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}/posts/${obj.id}`])
  }

  deletePost(obj) {
    this.campaignService.deletePost(obj.id).subscribe(data => {
      this.getCampaignsPosts()
      this.steps.campaign = true
      this.loading.establishment = false;
      this.actual_step = "table";
    })
  }

  addSidebar() {
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}/new-sidebar`])
  }

  editSidebar(obj) {
    // console.log(obj)
    this.router.navigate([`/application/content/content-feed/${this.campaign_id}/sidebar/${obj.id}`])
  }

  deleteSidebar(obj) {
    this.loading.establishment = true;
    // console.log(obj)
    this.desktopService.deleteDesktop(obj).subscribe(data => {
      // this.getDesktop()
      this.getCampaignId()
      this.loading.establishment = false;
      this.steps.campaign = true
      this.actual_step = "table";
    })
  }

  checkDeletPost(obj) {
    this.modalDelet = this.modalService.show(ModalChoiceComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        title: 'Excluir content',
        text: 'Deseja excluir o post ' + obj.name + '?',
      }
    })
    this.modalDelet.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.loading.establishment = true;
        this.deletePost(obj)
      }
    });

  }

  checkDeletSidebar(obj) {
    this.modalDelet = this.modalService.show(ModalChoiceComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        title: 'Excluir content',
        text: 'Deseja excluir a sidebar ' + obj.title + '?',
      }
    })
    this.modalDelet.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.loading.establishment = true;
        this.deleteSidebar(obj)
      }
    });

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
