import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ModalTypeLinkComponent } from './modal-type-link/modal-type-link.component';

@Component({
  selector: 'app-sidebar-lateral',
  templateUrl: './sidebar-lateral.component.html',
  styleUrls: ['./sidebar-lateral.component.css']
})
export class SidebarLateralComponent implements OnInit {

  @Input() sidebar: any;
  @Input() header: any;
  @Input() index: any;
  @Input() size: any;
  @Input() preview_lateral: any;

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('divCarousel') divCarousel: ElementRef;
  @ViewChild('divGallery') divGallery: ElementRef;

  ngAfterViewChecked() {
    if (this.divCarousel != null) {
      let gallery = document.getElementById('divCarousel') as any
      let list_div = gallery.children[1].firstChild

      var heights = [];

      for (var i = 0; i < list_div.children.length; i++) {
        if (list_div.children[i].className == "slide slick-slide slick-current slick-active") {
          heights.push(list_div.children[i].clientHeight);
        };
      }

      let size_height = Math.max.apply(null, heights)
      let list = document.getElementById('divCarousel') as HTMLDivElement
      list.style.height = `${size_height}px`;
    }
    
    else if (this.divGallery != null) {
      let gallery = document.getElementById('divGallery') as any
      let list_div = gallery.children[1].firstChild
      
      var heights = [];
      // var heights_info = [];
      
      for (var i = 0; i < list_div.children.length; i++) {
        if (list_div.children[i].className == "slide slick-slide slick-active" || list_div.children[i].className == "slide slick-slide slick-current slick-active") {
          // console.log(list_div.children[i]);

          heights.push(list_div.children[i].clientHeight);
          // heights_info.push(list_div.children[i].firstChild.children[1].clientHeight);
          // list_div.children[i].firstChild.children[1].style.height = `${Math.max.apply(null, heights_info)}px`
        };
      }
      
      let size_height = Math.max.apply(null, heights)
      let list = document.getElementById('divGallery') as HTMLDivElement
      list.style.height = `${size_height}px`;
    }
  }

  constructor(
    private modalService: MDBModalService,
  ) { }

  modalRef: MDBModalRef;
  is_dragging: boolean = false;

  list_options = []

  ngOnInit(): void {
    // console.log(this.sidebar);
  }

  onResized(event) {
    console.log('-----', event);
  }


  beforeChange(e) {
    this.is_dragging = true;
  }

  afterChange(e) {
    this.is_dragging = false;
  }

  openLink(link) {
    if (!this.is_dragging) {
      if (link != null && link != "") {
        if (link.substring(0, 4) != 'http') {
          window.open(`http://${link}`);
        }
        else {
          window.open(link);
        }
      }
    }
  }

  openTypeLink(sidebar) {
    // console.log(sidebar);

    this.list_options = []

    if (sidebar.images != null && sidebar?.images[0]?.spotify != null && sidebar.images[0].spotify != '') {
      this.list_options.push({ name: 'Spotify', link: sidebar.images[0].spotify })
    }
    if (sidebar.images != null && sidebar?.images[0]?.deezer != null && sidebar.images[0].deezer != '') {
      this.list_options.push({ name: 'Deezer', link: sidebar.images[0].deezer })
    }
    if (sidebar.images != null && sidebar?.images[0]?.itunes != null && sidebar.images[0].itunes != '') {
      this.list_options.push({ name: 'iTunes', link: sidebar.images[0].itunes })
    }
    if (sidebar.images != null && sidebar?.images[0]?.castbox != null && sidebar.images[0].castbox != '') {
      this.list_options.push({ name: 'Castbox', link: sidebar.images[0].castbox })
    }
    if (sidebar.images != null && sidebar?.images[0]?.google_podcast != null && sidebar.images[0].google_podcast != '') {
      this.list_options.push({ name: 'Google Podcast', link: sidebar.images[0].google_podcast })
    }

    this.openPopup()
  }

  openPopup() {
    this.modalRef = this.modalService.show(ModalTypeLinkComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-ms',
      containerClass: '',
      animated: false,
      data: {
        sidebar: this.sidebar,
        list_options: this.list_options
      }
    });
  }


  slideConfigGallery = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "arrows": true,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "lazyLoad": 'ondemand',
    "adaptiveHeight": true,
    "centerMode": false,
    "responsive":
      [{
        "breakpoint": 600,
        "settings": {
          "dots": false,
        }
      }]
  };

  slideConfigCarousel = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "lazyLoad": 'ondemand',
    "adaptiveHeight": true,
    "centerMode": false,
    "responsive":
      [{
        "breakpoint": 600,
        "settings": {
          "dots": false,
        }
      }]
  };

  slideConfigGalleryCenter = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "lazyLoad": 'ondemand',
    "adaptiveHeight": true,
    "centerMode": false,
    "responsive":
      [{
        "breakpoint": 1170,
        "settings": {
          "slidesToShow": 3,
          "dots": false,
        }
      },
      {
        "breakpoint": 991,
        "settings": {
          "slidesToShow": 4,
          "dots": false,
        }
      },
      {
        "breakpoint": 675,
        "settings": {
          "slidesToShow": 3,
          "dots": false,
        }
      },
      {
        "breakpoint": 520,
        "settings": {
          "slidesToShow": 2,
          "dots": false,
        }
      },]
  };

  slideConfigCarouselCenter = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "arrows": false,
    "infinite": false,
    "autoplay": false,
    "swipeToSlide": true,
    "lazyLoad": 'ondemand',
    "adaptiveHeight": true,
    "centerMode": false,
    "responsive":
      [{
        "breakpoint": 600,
        "settings": {
          // "dots": false,
        }
      }]
  };
}
