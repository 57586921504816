import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})

export class TagService {

    constructor(private http: HttpClient) { }

    establishment = "id_establishment"

    tags = "tags"

    getTagId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/tags/${id}`, { headers });
    }

    getAllTags(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/tags/`, options);
    }

    getTagsCampaign(id_campaign): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`campaing`, id_campaign)
        };

        return this.http.get<any>(AppConfig.path + `core/campaign/filter-campaing/`, options);
    }

    filterHomeTags(id_campaign, id_tag): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`campaing`, id_campaign)
                .set(`tag`, id_tag)
        };

        return this.http.get<any>(AppConfig.path + `core/homepage-tags/filter-tags`, options);
    }

    filterPageTags(id_campaign, id_tag): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`campaing`, id_campaign)
                .set(`tag`, id_tag)
        };

        return this.http.get<any>(AppConfig.path + `core/page-tags/filter-tags`, options);
    }

    filterInternalTags(id_campaign, id_tag): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`campaing`, id_campaign)
                .set(`tag`, id_tag)
        };

        return this.http.get<any>(AppConfig.path + `core/internal-page-tags/filter-tags`, options);
    }

    // GET SUB TAGS

    getSubTagId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        // .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/sub-tags/${id}`, { headers });
    }

    postSubTag(id_tags, subcategory): Observable<any> {
        let body = new HttpParams()
            .set(`name`, subcategory.name)
            .set(`tags`, id_tags)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/sub-tags/`, body, { headers })
    }

    editSubTag(subcategory): Observable<any> {
        let body = new HttpParams()
            .set(`name`, subcategory.name)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/sub-tags/${subcategory.id}/`, body, { headers })
    }

    deletSubTag(subcategory): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/sub-tags/${subcategory.id}/`, { headers })
    }

    // GET ALL SUB TAGS

    getSub(id_tags): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`id_tags`, id_tags)
        };

        return this.http.get<any>(AppConfig.path + `core/sub-tags/`, options);
    }

    getAllSubTags(name, id_tags): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`id_tags`, localStorage.getItem(id_tags))
                .set(`name`, name)
        };

        return this.http.get<any>(AppConfig.path + `core/sub-tags/${id_tags}`, options);
    }

    getTagHomepage(home_id, page_id, internal_id, page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        if (home_id != null)
            options.params = options.params.set(`home_page`, home_id)
        if (page_id != null)
            options.params = options.params.set(`image_page`, page_id)
        if (internal_id != null)
            options.params = options.params.set(`internal_page`, internal_id)


        return this.http.get<any>(AppConfig.path + `core/homepage-tags/`, options);
    }

    getTagPage(id, page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`image_page`, id)
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/page-tags/`, options);
    }

    getTagInternalpage(id, page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            // .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`internal_page`, id)
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/internal-page-tags/`, options);
    }

    postTag(name, image): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, name)
            .set(`image`, encodeURI(image))
            .set(`establishment`, localStorage.getItem(this.establishment))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/tags/`, body, { headers })
    }

    editTag(id_tag, name, image): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, name)
            .set(`image`, encodeURI(image))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/tags/${id_tag}/`, body, { headers })
    }

    editOrderProduct(tag): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`order`, tag.order)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/tags/${tag.id}/`, body, { headers })
    }

    deleteTag(id_tag): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/tags/${id_tag}/`, { headers })
    }

    postTagHomepage(id_homepage, id_page, id_internal, id_campaign, id_tag, id_sub): Observable<any> {
        let body: any = JSON.stringify({
            home_page: id_homepage,
            page: id_page,
            internal_page: id_internal,
            campaign: id_campaign,
            establishment: localStorage.getItem(this.establishment),
            tag: id_tag,
            sub_tags: id_sub
        })

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/homepage-tags/`, body, { headers })
    }

    postTagPage(id_page, id_tag): Observable<any> {
        let body: any = JSON.stringify({
            image_page: id_page,
            establishment: localStorage.getItem(this.establishment),
            tag: id_tag
        })

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/page-tags/`, body, { headers })
    }

    postTagInternalPage(id_internal, id_tag): Observable<any> {
        let body: any = JSON.stringify({
            internal_page: id_internal,
            establishment: localStorage.getItem(this.establishment),
            tag: id_tag
        })

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/internal-page-tags/`, body, { headers })
    }

    deleteTagHomepage(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        };

        return this.http.delete<any>(AppConfig.path + `core/homepage-tags/${id}/`, options);
    }

    deleteTagPage(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        };

        return this.http.delete<any>(AppConfig.path + `core/page-tags/${id}/`, options);
    }

    deleteTagInternalpage(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
        };

        return this.http.delete<any>(AppConfig.path + `core/internal-page-tags/${id}/`, options);
    }

}
