import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Campaign_qrcode } from '../../model/campaign';
import { CampaignService } from '../../service/campaign.service';
import { AppConfig } from '../../app.config';
import { Router } from '@angular/router';
import { CatalogService } from 'src/app/service/catalog.service';
import { StorageService } from 'src/app/service/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-catalog',
	templateUrl: './catalog.component.html',
	styleUrls: ['./catalog.component.css']
})

export class CatalogComponent {

	constructor(
		private campaignService: CampaignService,
		private router: Router,
		private catalogService: CatalogService,
		private storageService: StorageService,
		private _snackBar: MatSnackBar
	) {
		var url = this.router.url;
		this.url_screen = router.url;


		if (url.indexOf("redirect") > 0) {
			// console.log('WEB')
			this.web = true;
			this.phone = false;
		}
		else if (url.indexOf("content") > 0) {
			// console.log('PHONE')
			this.phone = true;
			this.web = false;
		}
	}

	@Input() list: any;
	@Input() header: any;
	@Input() preview_campaign: any;
	@Input() type_campaign: any;
	@Input() campaign_id;
	@Input() campaign: Campaign_qrcode;
	@Output() newItemEvent = new EventEmitter();

	web: boolean = false;
	phone: boolean = false;

	url_screen;
	url = AppConfig.path;
	listImage = [];
	obj_array;
	qrcode_id;

	loading: boolean = false;

	panelOpenState = false;

	shoppingCart: any;
	qtdItens: number = 0;
	viewShopping: number = 0;
	catalog_obj;
	check_login: boolean = false;
	type_page: string = '';

	ngOnInit() {
		// console.log("-> Catalogo <-")
		// console.log(this.list);
		

		this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

		this.storageService.screen.format = 'Catalogo';
		this.storageService.screen.id = this.list.id;

		// this.campaignService.watchList().subscribe( data => {
		//     this.checkStatusPreview()
		// })

		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.shoppingCart = this.storageService.getShoppingCart()
		this.checkStatusPreview()
	}

	checkStatusPreview() {
		this.loading = true;
		// let catalog = this.list.catalog != null ? this.list.catalog : (this.list.images != null ? this.list?.images[0].catalog : this.list.page_images[0].catalog)
		let catalog = this.list.catalog != null ? this.list.catalog : null;

		let status = true;

		this.campaignService.watchList().subscribe(data => {
			status = false;

			if (this.preview_campaign && catalog != null) {
				this.catalogService.getCatalog(catalog).subscribe(data => {
					this.loading = false;
					this.listImage = data.sections;
				})
			}
			else {
				this.loading = false;
				this.listImage = this.tratamento(this.list)
			}
		})

		if (status) {
			this.listImage = this.tratamento(this.list)
			this.loading = false;
		}

	}

	getQtd() {
		return this.storageService.shoppingCart.qtd
	}

	async addNewItem(list) {
		if (list == 'back') {
			setTimeout(() => {
				this.newItemEvent.emit('registerBack');
			}, 10);
		}

		if (this.url_screen != '/application/dashboard') {
			await this.storageService.saveHistoric(this.campaign_id, list.id, (list.homepage_format || list.page_format), 2, this.type_page, this.qrcode_id)
		}


		if (this.preview_campaign) {
			if (list?.type?.name == "Link") {
				if (list.link.substring(0, 4) != 'http') {
					window.open(`http://${list.link}`);
					return true
				}
				else {
					window.open(list.link);
					return true
				}
			}
			else
				this.newItemEvent.emit(list);
		}
		else {
			if (list?.page_format_obj?.name == "Link") {
				window.open(list.page_images[0].link)
			}
			else if (list?.internal_images != null) {
				list.internal_images.forEach(el => {
					if (el.catalog_obj != null) {
						this.newItemEvent.emit(list);
						return true
					}
					if (el.link != null && el.link != "") {
						if (el.link.substring(0, 4) != 'http') {
							window.open(`http://${el.link}`);
							return true
						}
						else {
							window.open(el.link);
							return true
						}
					}
				});
			}
			else
				this.newItemEvent.emit(list);
		}
	}

	tratamento(list) {
		if (this.preview_campaign) {
			if (this.list?.images != undefined) {
				// console.log('5', list.images)
				return list.images;
			}
			else {
				// console.log('null')
				return null
			}
		}
		else {
			if (list?.catalog != null) {
				// console.log('4',list.catalog_obj)
				this.catalog_obj = list.catalog_obj;
				this.type_page = 'home';
				this.obj_array = list;
				return list?.catalog_obj.sections;
			}
			else if (list?.internal_images != undefined) {
				// console.log('3',list.internal_images)
				this.catalog_obj = list.internal_images[0].catalog_obj;
				this.type_page = 'internal';
				this.obj_array = list.internal_images[0];
				return list.internal_images[0].catalog_obj.sections;
			}
			else if (list?.page_images != undefined) {
				// console.log('2',list.page_images)
				this.catalog_obj = list.page_images[0].catalog_obj;
				this.type_page = 'page';
				this.obj_array = list.page_images[0];
				return list.page_images[0].catalog_obj.sections;
			}
			else if (this.list?.home_images != undefined) {
				// console.log('1',list.home_images)
				this.catalog_obj = list.page_images[0].catalog_obj;
				this.type_page = 'home';
				this.obj_array = list.page_images[0];
				return list.page_images[0].catalog_obj.sections;
			}
			else {
				// console.log('0',list.catalog_obj)
				this.type_page = 'home';
				this.obj_array = list;
				return list.catalog_obj.sections;
			}
		}

	}

	checkPage(page) {
		if (this.storageService.getPageShoppingCart() == page) {
			return true
		}
		else {
			return false
		}
	}

	trocaPage(page) {
		this.storageService.page_shoppingCart = page;
	}

	addProduct(product) {
		this.storageService.addProduct(product, 1);
		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.openSnackBar("Produto adicionado ao carrinho!", "Fechar")
	}

	convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
	}

	finish(newItem) {

		if (newItem == 'LOGIN') {
			this.check_login = true;
		}
		else if (newItem == 'FINISH') {
			this.check_login = false;
		}
		else if (newItem == null) {
			this.qtdItens = 0;
		}
		else {
			this.reset('')
			this.check_login = false;

			if (this.url_screen != '/application/dashboard') {
				this.storageService.saveHistoric(this.campaign_id, this.obj_array.id, (this.obj_array.homepage_format || this.obj_array.page_format), 11, this.type_page, this.qrcode_id)
			}

			// console.log('finish')
			this.trocaPage(0)
			this.newItemEvent.emit('registerBack');
			setTimeout(() => {
				this.trocaPage(0)
				this.newItemEvent.emit('registerBack');
			}, 100);
		}
	}

	reset(newItem) {
		this.qtdItens = 0;
		this.qtdItens = this.storageService.shoppingCart.qtd;
		this.storageService.clear()
	}

	login(newItem) {
		this.trocaPage(1)
		this.check_login = false;
		// console.log(newItem)
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 4000,
		});
	}
}
