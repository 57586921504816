<div class="screen">
    <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">

        <div class="cards" style="margin-right: 10px;">
            <div style="width: 100%; display: flex; justify-content: space-between;">
                <img src="../../assets/icon/azul/eye (1).png" class="icons">
                <!-- <div class="porcent"> 12% ∧ </div> -->
                <!-- <div class="porcent"> 12% ∨ </div> -->
            </div>
            <div class="view"> {{ information?.acesso != null ? information?.acesso : 0 }} </div>
            <div class="title"> Acessos </div>
        </div>

        <div class="cards" style="margin-right: 10px;" *ngIf="type_screen == 'Pergunta'">
            <div class="view"> {{ information?.answer != null ? information?.answer : 0 }} </div>
            <div class="title"> Respostas Enviadas </div>
        </div>

        <div class="cards" style="margin-right: 10px;" *ngIf="type_screen == 'Cupom'">
            <div class="view"> {{ information?.cupom != null ? information?.cupom : 0 }} </div>
            <div class="title"> Cupom resgatados </div>
        </div>

        <div class="cards" style="margin-right: 10px;" *ngIf="type_screen == 'Cadastro'">
            <div class="view"> {{ information?.register != null ? information?.register : 0 }} </div>
            <div class="title"> Usuários cadastrados </div>
        </div>

        <div class="cards" style="margin-right: 10px;" *ngIf="type_screen == 'Vídeo'">
            <div class="view"> {{ information?.video != null ? information?.video : 0 }} </div>
            <div class="title"> Clicks no Vídeo  </div>
        </div>
    </div>

    <div class="screen_popup">
        <img src="../assets/image/iphone_x.png" class="img-phone">
       
        
        <div class="custom-scrollbar component" *ngIf="mobile" (click)="getScreen()">
            <div class="item_loading" *ngIf="loading">
                <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
            </div>
            <app-redirect [sendList]="isCampaign" ></app-redirect>
        </div>

        <div class="loading" *ngIf="!mobile">
            <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
        </div>
    </div>

    <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="cards" style="margin-right: 10px;">
            <div class="view"> {{ information?.link != null ? information?.link : 0 }} </div>
            <div class="title"> Clicks no links </div>
        </div>
    </div>

</div>