import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from '../modals/cell-phone/cell-phone.component';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { StorageService } from '../service/storage.service';
import { SurveyService } from '../service/survey.service';
import { ModalSurveyPreviewComponent } from './modal-survey-preview/modal-survey-preview.component';

@Component({
    selector: 'app-create-survey',
    templateUrl: './create-survey.component.html',
    styleUrls: ['./create-survey.component.css']
})
export class CreateSurveyComponent implements OnInit {

    constructor(
        private surveyService: SurveyService,
        private modalService: MDBModalService,
        private storageService: StorageService
    ) { }

    @ViewChild(MatSort) sort: MatSort;

    modalDelet: MDBModalRef;
    modalRef: MDBModalRef;

    displayedColumns: string[] = ['name', 'description', 'status', 'view'];
    dataSource = new MatTableDataSource<any>();

    loading: boolean = false;
    catalogs_response; // var to save last get catalogs response

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    id_establishment = null;

    color_primary
    
    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')

        if(this.id_establishment != null){
            this.getSurvey()
        }
    }

    getSurvey(){
        this.loading = true;
        this.surveyService.getSurvey_EstablishmentId(this.id_establishment, this.page).subscribe(data=>{
            this.catalogs_response = data;
            this.dataSource.data = data.results;
            this.loading = false;

            // pagination calc  
            this.count_total = data.count;
            this.page_count1 = 1 + ((this.page-1) * this.page_size)
            if((this.page_count1 + (this.page_size-1)) > this.count_total){
                this.page_count2 = this.count_total;
            }
            else{
                this.page_count2 = this.page_count1 + (this.page_size-1);
            }
        },
        error=>{
            this.loading = false
        })
    }

    nextPage(){
        if(this.catalogs_response.next != null){
            this.page += 1;
            this.getSurvey()
        }
    }

    backPage(){
        if(this.catalogs_response.previous != null){
            this.page -= 1;
            this.getSurvey()
        }
    }

    deleteSurvey(id){
        this.loading = true;
        this.surveyService.deleteSurvey(id).subscribe(data=>{
            this.loading = false;
            this.getSurvey()
        },
        error=>{
            this.loading = false;
        })
    }

    openPreview(obj){
        this.modalRef = this.modalService.show(CellPhoneComponent, {
            // backdrop: 'static',
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                isList: obj,
                isPopup: 'survey'
            }
        })   
        
    }

    openDelete(obj){
        this.modalDelet = this.modalService.show(ModalChoiceComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                title: 'Excluir pergunta',
                text: 'Deseja excluir a pergunta '+obj.name+'?',
            }
        })   
        this.modalDelet.content.action.subscribe( (result: any) => { 
            if(result == 'yes'){
                this.deleteSurvey(obj.id)
            }
        }); 

    }
}
