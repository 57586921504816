import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { NoticeService } from 'src/app/service/notice.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-view-notice',
  templateUrl: './view-notice.component.html',
  styleUrls: ['./view-notice.component.css']
})
export class ViewNoticeComponent implements OnInit {


  @Input() list: any;
  @Input() header: any;
  @Input() preview_campaign: any;
  @Input() type_campaign: any;
  @Input() campaign_id: any;
  @Output() newItemEvent = new EventEmitter();

  constructor(
    private campaignService: CampaignService,
    private storageService: StorageService,
    private router: Router,
    private noticeService: NoticeService
  ) {
    this.url_screen = router.url;
  }

  url_screen;
  listImage;
  qrcode_id;

  loading: boolean = true;
  url = AppConfig.path;

  type_page: string = '';
  notice_obj: any;
  view_more: boolean = false
  image_notice: string = null;

  ngOnInit(): void {
    // console.log("-> Notícia <-")
    // console.log("->", this.header)

    this.qrcode_id = (this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null) ? this.list.qr_codes[0].qr_code : null;

    this.campaignService.watchList().subscribe(data => {
      this.listImage = this.tratamento(this.list)
    })

    this.listImage = this.tratamento(this.list)

    this.getNoticeId()
  }

  getNoticeId() {
    this.noticeService.getNoticeId(this.listImage[0].eye_news).subscribe(data => {
      this.notice_obj = data;
      this.image_notice = this.notice_obj?.news[0]?.image != null ? this.notice_obj?.news[0]?.image : null;
      this.loading = false;
    },
    error => {
      console.log(error)
    })
  }


  tratamento(list) {

    if (this.preview_campaign) {
      if (this.list?.images != undefined) {
        return list.images.sort(this.ordenarList);
      }
      else
        return null
    }
    else {
      if (list?.home_images != undefined) {
        this.type_page = 'home';
        return list.home_images.sort(this.ordenarList);
      }
      else if (list?.internal_images != undefined) {
        this.type_page = 'internal';
        return list.internal_images.sort(this.ordenarList);
      }
      else if (list?.page_images != undefined) {
        this.type_page = 'page';
        return list.page_images.sort(this.ordenarList);
      }
      else if (this.list?.home_images != undefined) {
        this.type_page = 'home';
        return list.home_images.sort(this.ordenarList);
      }
      else
        return null
    }

  }

  ordenarList(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }
}
