import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';

import { Workbook } from 'exceljs';
import { Importer } from '../../../assets/libs/xlsx-import/Importer';
import { ConfigImportService } from 'src/app/service/config-import.service';
import { SpreadsheetService } from 'src/app/service/spreadsheet.service';

@Component({
  selector: 'app-popup-import-file',
  templateUrl: './popup-import-file.component.html',
  styleUrls: ['./popup-import-file.component.css']
})

export class PopupImportFileComponent implements OnInit {

  constructor(
    private modalRef: MDBModalRef,
    private storageService: StorageService,
    private configImport: ConfigImportService,
    private spreadsheetService: SpreadsheetService
  ) { }

  action: Subject<any> = new Subject();
  loading: boolean = false;

  list_type_file = [
    { type: 'Casa de Bombas' },
    { type: 'Checklist Extintores' },
    { type: 'Checklist Hidrante' },
    { type: 'Checklist Alarme' },
    { type: "Checklist VG's" },
    { type: "Pintura" },
    { type: "Gás FM" },
    { type: "Saídas Emergências" },
    { type: "Cavaletes Gases" },
    { type: "VG's Minimax" }
  ]

  type_file: any = null;
  file: any = null;

  nomeArquivo: string = '';
  color_primary;


  externo = null;
  energyCenter = null;
  restaurant = null;
  reservation = null;
  assembly = null;
  pdi = null;
  paint = null;
  body = null;

  bodyRestaurant = null;
  matrix = null;
  alarm = null;
  vg_checklist = null
  co2_paint = null;
  gas_fm = null;
  close_external = null;
  exit = null;

  establishment= null;
  description_json = {}

  ngOnInit(): void {
    this.establishment = localStorage.getItem('id_establishment')

    this.color_primary = this.storageService.establishment.color;

    this.getSpreadsheets()
  }

  async onUpload(files) {
    this.file = files
    let file = files.item(0);
    this.nomeArquivo = file.name;

    this.convertXLSX() // <---- TESTE
  }

  closePopup() {
    this.modalRef.hide();
  }

  next() {
    this.loading = false;
    let fire_obj = {}

    if (this.type_file == 'Checklist Extintores' ) {
      fire_obj = {
        externo: this.externo,
        energyCenter: this.energyCenter,
        restaurant: this.restaurant,
        reservation: this.reservation,
        assembly: this.assembly,
        pdi: this.pdi,
        paint: this.paint,
        body: this.body,
      }
    }
    else if (this.type_file == 'Checklist Hidrante' ) {
      fire_obj = {
        bodyRestaurant: this.bodyRestaurant,
        pdi: this.pdi,
        paint: this.paint,
        assembly: this.assembly,
        energyCenter: this.energyCenter,
        matrix: this.matrix
      }
    }
    else if (this.type_file == 'Checklist Alarme' ) {
      fire_obj = {
        bodyRestaurant: this.bodyRestaurant,
        pdi: this.pdi,
        paint: this.paint,
        assembly: this.assembly,
        energyCenter: this.energyCenter,
        matrix: this.matrix
      }
    }
    else if (this.type_file == "Checklist VG's" ) {
      fire_obj = {
        vg_checklist: this.vg_checklist,
      }
    }
    else if (this.type_file == "Pintura" ) {
      fire_obj = {
        co2_paint: this.co2_paint,
      }
    }
    else if (this.type_file == "Gás FM" ) {
      fire_obj = {
        gas_fm: this.gas_fm,
      }
    }
    else {
      fire_obj = {
        close_external: this.close_external,
        energyCenter: this.energyCenter,
        exit: this.exit
      }
    }

    this.action.next({ status: 'yes', file: fire_obj });
    this.modalRef.hide();
  }

  async convertXLSX() {
    if ( this.nomeArquivo != '' && this.file != null ){
      this.loading = true;
  
      const invoiceFile = this.file[0]
      const reader = new FileReader();
      reader.readAsArrayBuffer(invoiceFile);
  
      switch (this.type_file) {
        case 'Checklist Extintores': //ok
          this.fireExtinguisherChecklist(reader)
          break;
        case 'Checklist Hidrante': //ok
          this.hydrantChecklist(reader)
          break;
        case 'Checklist Alarme': //ok
          this.alarmChecklist(reader)
          break;
        case "Checklist VG's":
          this.vGChecklist(reader)
          break;
        case 'Pintura':
          this.paintChecklist(reader)
          break;
        case 'Gás FM':
          this.gasFmChecklist(reader)
          break;
        case 'Saídas Emergências':
          this.emergencyChecklist(reader)
          break;
        case 'Cavaletes Gases':
          this.gasEaselsChecklist(reader)
          break;
        case 'Casa de Bombas':
          this.pumpHouseChecklist(reader)
          break;
        case "VG's Minimax":
          this.VGsMinimax(reader)
          break;
        default:
          this.loading = false;
          break;
      }
    }
    else {

    }

  }


  fireExtinguisherChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setfireExtinguisherChecklist(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }


  hydrantChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setHydrantChecklist(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });

  }


  alarmChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setAlarmlist(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });

  }


  vGChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setVgList(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  paintChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setPaintList(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  gasFmChecklist(reader){
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setGasFmList(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  emergencyChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setEmergencyList(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  gasEaselsChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setGasEaselsList(importer)
        
        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  pumpHouseChecklist(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setPumpHouseList(importer)
        
        // console.log('---->', _list);

        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  VGsMinimax(reader) {
    reader.addEventListener('loadend', async () => {
      if (reader.result instanceof ArrayBuffer) {
        const wb = new Workbook();
        await wb.xlsx.load(reader.result);
        const importer = new Importer(wb);

        var _list = this.configImport.setVGsMinimaxList(importer)
        
        console.log('---->', _list);

        _list.forEach(table => {
          this.setDescriptionJson(table);
        });      

        this.postSpreadsheet(_list[0].title, _list[0].type, _list, this.establishment );
      }
    });
  }

  getSpreadsheets(){
    this.spreadsheetService.getSpreadsheets(this.establishment, 1).subscribe(data =>{
      // console.log(data);
      
    })
  }

  setDescriptionJson(aba_excel){
    this.description_json[aba_excel.descriptio_json.Local] = aba_excel.descriptio_json;
  }

  postSpreadsheet(title, type, list_aba, establishment){

    this.spreadsheetService.postSpreadsheet(title, type, JSON.stringify(this.description_json), establishment).subscribe(data =>{

      list_aba.forEach((table, index) => {

        var description_json = JSON.stringify(table.footer);
        var columns_json = JSON.stringify(Object.values(table.columns_array));
        var rows_json = JSON.stringify(table.list_table);

        var title = table?.descriptio_json?.Titulo_aba != null ? table.descriptio_json.Titulo_aba : table.title;
        var subtitle = table?.descriptio_json?.Local != null ? table.descriptio_json.Local : table.subtitle;

        this.postSpreadsheetTable(data.id, title, subtitle, description_json, columns_json, rows_json)

        if (table.description != null){
          var descr_columns_json = JSON.stringify(Object.values(table.title_description));
          var descr_rows_json = JSON.stringify(table.description);
  
          this.postSpreadsheetTable(data.id, `Descrição (${title})`, `Descrição (${subtitle})`, description_json, descr_columns_json, descr_rows_json)
        }

      });
    })

    setTimeout(() => {
      this.loading = false;
      this.action.next({ status: 'yes', file: list_aba });
      this.modalRef.hide();
    }, 500);
  }

  postSpreadsheetTable(spreadsheet, title, subtitle, footer, columns_array, rows_arrayt){
    this.spreadsheetService.postSpreadsheetTable(spreadsheet, title, subtitle, footer, columns_array, rows_arrayt).subscribe(data =>{
      // console.log(data);
    })
  }

}
