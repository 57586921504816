<div *ngIf="sidebar.side != 'CENTER'">

    <!-- BANNER -->
    <div style="width: 90%; margin: 0 auto;" *ngIf="sidebar.type == 'BANNER'">
        <div class="card_header">
            <!-- <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div> -->
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div>

        <div class="card_body" (click)="openLink(sidebar?.images[0]?.link)" style="cursor: pointer;">
            <img [src]="sidebar?.images != null && sidebar?.images[0]?.image != null ? sidebar.images[0].image : 'https://via.placeholder.com/600.png/09f/fff'"
                class="image_sidebar">
        </div>

        <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div>

        <div class="line" *ngIf="index != null && index != size-1"></div>
    </div>

    <!-- PODCAST -->
    <div style="width: 90%; margin: 0 auto;" *ngIf="sidebar.type == 'PODCAST'">
        <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div>

        <div class="card_body">
            <img [src]="sidebar?.images != null && sidebar?.images[0]?.image != null ? sidebar.images[0].image : 'https://via.placeholder.com/600.png/09f/fff'"
                class="image_sidebar">
        </div>

        <div class="card_footer" *ngIf="sidebar.link_text != null && sidebar.link_text != ''">
            <button mat-button class="btn_view_more" (click)="openTypeLink(sidebar)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div>

        <div class="line" *ngIf="index != null && index != size-1"></div>
    </div>

    <!-- CAROUSEL / 1 imagem -->
    <div style="width: 90%; margin: 0 auto;" *ngIf="sidebar.type == 'CAROUSEL'">
        <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back" style="cursor: pointer;">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div>

        <div style="position: relative; width: 100%;">
            <ngx-slick-carousel class="carousel" id="divCarousel"  #divCarousel (resized)="onResized($event)" #slickModalGallery="slick-carousel" [config]="slideConfigGallery"
                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">

                <div ngxSlickItem *ngFor="let slide of sidebar.images" class="slide"
                    style="position: relative; width: 100%; height: 100%;">
                    <div class="card_body" *ngIf="slide.image != null && slide.image != ''">
                        <img [src]="slide.image" class="image_slide" >
                    </div>
                    <!-- 
                        <div class="card_body_slide"
                        [ngStyle]="{'height': preview_lateral ? '180px' : '220px', 'font-size': index == null ? '15px' : ''}"
                        [style.background]="header.back" style="color: white;">
                     -->
                    <div class="card_body_slide"
                        [style.background]="header.back" style="color: white;">

                        <div class="brand_line_5 custom-scrollbar"
                            *ngIf="slide.text != null && slide.text != ''" 
                            [ngStyle]="{'font-size': preview_lateral ? '12px' : '13px'}">
                            {{ slide.text}}
                        </div>

                        <div class="card_footer" *ngIf="slide.link != null && slide.link != ''">
                            <button mat-button class="btn_view_more" (click)="openLink(slide.link)"
                                [ngStyle]="{'font-size': preview_lateral ? '10px' : '13px'}"
                                style="background-color: white;" [style.color]="header.back">
                                {{ slide.link_text }}
                            </button>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div>

        <div class="line" *ngIf="index != null && index != size-1"></div>
    </div>


    <!-- GALLERY / 3 imagens-->
    <div style="width: 90%; margin: 0 auto;" *ngIf="sidebar.type == 'GALLERY'">
        <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div>

        <div style="position: relative;">
            <ngx-slick-carousel class="carousel" id="divGallery" #divGallery  #slickModalCarousel="slick-carousel" [config]="slideConfigCarousel"
                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">

                <div ngxSlickItem *ngFor="let slide of sidebar.images" class="slide"
                    style="position: relative; width: auto;">
                    <div class="card_revista">
                        <img
                            src="{{ slide.image != null ? slide.image : 'https://via.placeholder.com/600.png/09f/fff' }}">
                        <div class="card_info_bottom">
                            <div class="text_title" style="margin: 5px 0 10px;"> {{ slide.title }} </div>
                            <div class="text_title" style="font-size: 10px;"> {{ slide.text }} </div>
                            <!-- <div class="text_title brand_line_2"
                                [ngStyle]="{'font-size': preview_lateral ? '0.6rem' : '13px'}"> {{ slide.title }} </div> -->
                            <!-- <div class="text_description brand_line_4"> {{ slide.text }} </div> -->
                            <!-- <button mat-button class="btn_view_more" (click)="openLink(slide.link)"
                                [ngStyle]="{'font-size': preview_lateral ? '10px' : '13px'}"
                                *ngIf="slide.link != null && slide.link != ''">
                                {{ slide.link_text }}
                            </button> -->
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div>

        <div class="line" *ngIf="index != null && index != size-1"></div>
    </div>
</div>

<div *ngIf="sidebar.side == 'CENTER'">

    <!-- BANNER -->
    <div class="card_center" *ngIf="sidebar.type == 'BANNER'">
        <!-- <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div> -->

        <div class="card_body" (click)="openLink(sidebar?.images[0]?.link)" style="cursor: pointer;">
            <img [src]="sidebar?.images != null && sidebar?.images[0]?.image != null ? sidebar.images[0].image : 'https://via.placeholder.com/600.png/09f/fff'"
                class="image_sidebar_top">
        </div>

        <!-- <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div> -->

    </div>


    <!-- CAROUSEL -->
    <div class="card_center" *ngIf="sidebar.type == 'CAROUSEL'" style="background: #08396c;">
        <!-- <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title"> {{ sidebar.title }} </div>
        </div> -->

        <div style="position: relative; width: 90%; margin: 0 auto;">
            <ngx-slick-carousel class="carousel" #slickModalGallery="slick-carousel" [config]="slideConfigGalleryCenter"
                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">

                <div ngxSlickItem *ngFor="let slide of sidebar.images" class="slide"
                    style="position: relative; width: 100%;">
                    <div class="card_body" *ngIf="slide.image != null && slide.image != ''" (click)="openLink(sidebar.link)" style="cursor: pointer;">
                        <img [src]="slide.image" class="image_slide">
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

        <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div>

    </div>

    <!-- GALLERY -->
    <div class="card_center" *ngIf="sidebar.type == 'GALLERY'">
        <!-- <div class="card_header">
            <div class="card_icon" [style.borderColor]="header.back">
                <img [src]="sidebar.icon" class="image">
            </div>
            <div class="title" [style.color]="header.back"> {{ sidebar.title }} </div>
        </div> -->

        <div style="position: relative;">
            <ngx-slick-carousel class="carousel" #slickModalCarousel="slick-carousel"
                [config]="slideConfigCarouselCenter" (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">

                <div ngxSlickItem *ngFor="let slide of sidebar.images" class="slide"
                    style="position: relative; width: auto;">
                    <div class="card_revista" (click)="openLink(slide.link)"
                        [ngStyle]="{'cursor': (slide.link != null && slide.link != '') ? 'pointer' : ''}">
                        <img
                            src="{{ slide.image != null ? slide.image : 'https://via.placeholder.com/600.png/09f/fff' }}">
                        <div class="card_info">
                            <!-- <div class="text_title brand_line_2"
                                [ngStyle]="{'font-size': preview_lateral ? '0.6rem' : '13px'}"> {{ slide.title }} </div>
                            <div class="text_description brand_line_4"> {{ slide.text }} </div> -->
                            <!-- <button mat-button class="btn_view_more" (click)="openLink(slide.link)"
                                [ngStyle]="{'font-size': preview_lateral ? '10px' : '13px'}"
                                *ngIf="slide.link != null && slide.link != ''">
                                {{ slide.link_text }}
                            </button> -->
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

        <!-- <div class="card_footer" *ngIf="sidebar.link != null && sidebar.link != ''">
            <button mat-button class="btn_view_more" (click)="openLink(sidebar.link)" style="font-size: 13px;"
                [style.background]="header.back">
                {{ sidebar.link_text }}
            </button>
        </div> -->

    </div>
</div>