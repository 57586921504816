<div class="import-csv-container">

    <svg (click)="closePopup()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
        [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path [style.fill]="color_primary"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>

    <div style="height: 20px;"></div>

    <mat-form-field class="card_select"
        style="padding: 0 !important; border: 1px solid transparent; margin-top: -14px;">
        <p> Selecione o tipo do arquivo </p>
        <mat-select class="select-box" [(ngModel)]="type_file">
            <mat-option *ngFor="let item of list_type_file;" [value]="item.type">
                {{item.type}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="input" style="width: 100%; display: flex; 
            justify-content: space-between; align-items: center; margin: 10px 0 30px;">

        <button mat-button class="button-cor" [style.background]="color_primary" (click)="file.click()">
            Selecione o arquivo
        </button>

        <input matInput [(ngModel)]="nomeArquivo" readonly style="width: calc(100% - 190px);"
            placeholder="Selecione o arquivo">

        <input type="file" #file accept=".csv, .xlsx, .pdf" style="display: none;"
            (change)="onUpload($event.target.files)" />
    </div>

    <button class="next-btn main-button"
        [style.background]="color_primary != null ? color_primary : 'rgb(247, 113, 40)'" (click)="convertXLSX()"
        mat-button [disabled]="type_file == null || file == null"
        [style.opacity]="(type_file == null || file == null) ? '0.5' : '1'">
        Converter Arquivo
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
</div>