import { Component, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { CatalogService } from "src/app/service/catalog.service";

@Component({
  selector: "catalogs-view",
  templateUrl: "./catalog-view.component.html",
  styleUrls: ["./catalog-view.component.css"],
})
export class CatalogViewComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private catalogService: CatalogService,
    private route: ActivatedRoute,
  ) {
    this.catalog_id = this.route.snapshot.paramMap.get('id');
  }

  loading: boolean = true;

  catalog_id: string = '';

  catalog: any;
  selectedIndex: number = 0;
  selectedSubIndex: number = 0;
  imageCarousel: string = '../../../assets/image/city.png';
  imageMenu: string = "../../../assets/home.png"

  slides = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    nextArrow: '#customNext'
  };

  ngOnInit() {
    if (this.catalog_id != null && this.catalog_id != '') {
      this.getCatalog(this.catalog_id)
      this.getImages()
    }
  }

  setIndex(index) {
    this.selectedIndex = index;
  }

  openLink(link){
    if(link != '' && link != null){
      if (link.substring(0, 4) != 'http') {
        window.open(`http://${link}`);
      }
      else {
          window.open(link);
      }
    }
  }

  setSubIndex(index) {
    const el: HTMLElement = document.getElementById(`target${index}`);
    el.scrollIntoView();
    this.selectedSubIndex = index;
  }

  getCatalog(id) {
    this.catalogService.getCatalog(id).subscribe(data => {
      // console.log(data);
      this.catalog = data;
      this.loading = false;
    })
  }

  getImages() {
    this.catalogService.getCatalogImages(this.catalog_id).subscribe(data => {
      // console.log(data);
      this.slides = data;
      //this.catalog['id'] = data?.id;
    })
  }

}

export class LanguageDialog { }
