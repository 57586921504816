<div style="margin: 0 50px;">
  <br><br>

  <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
    src="../../../../assets/image/logo_principal_SBS.png">
  <h2>Formulário de Inspeção no Alarme de incêndio DIÁRIO COM DUAS MEDIÇÕES DIA GERAL NA FÁBRICA</h2>
  <br><br>
<table *ngIf="local == 'GERAL NA FÁBRICA'">
  <tr style="font-size: 9px;">
      <th> Dia</th>
      <th> 1° Hora </th>
      <th> 2° Hora </th>
      <th> Nome </th>
  </tr>

  <tr *ngFor="let row of table_1" style="font-size: 8px;">
      <td> {{ row['DIA'] }} </td>
      <td> {{ row['1 ° HORA'] }} </td>
      <td> {{ row['2° HORA'] }} </td>
      <td> {{ row['NOME'] }} </td>
  </tr>
</table>

<table *ngIf="local == 'GERAL NA FÁBRICA'">
  <tr style="font-size: 9px;">
      <th> Descrição </th>
      <th> Status </th>
  </tr>

  <tr *ngFor="let row of table_2" style="font-size: 8px;">
      <td>{{ row["Descrição"] }} </td>
      <td> {{ row['Status'] }} </td>
  </tr>
</table>

<br><br>

<table>
  <tr style="font-size: 9px;">
      <th> Aprovado: </th>
      <th> Data: </th>
      <th> Elaborado: </th>
      <th> Referência: </th>
  </tr>
<!-- 
  <tr style="font-size: 8px;">
      <td> {{ footer['Aprovado'] }} </td>
      <td> </td>
      <td> {{ footer['Data'] }} </td>
      <td> </td>
      <td> {{ footer['Elaborado'] }} </td>
      <td> {{ footer['Referência'] }} </td>
  </tr> -->
</table>
</div>