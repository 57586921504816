import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from '../modals/cell-phone/cell-phone.component';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { NoticeService } from '../service/notice.service';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  constructor(
    private noticeService: NoticeService,
    private modalService: MDBModalService,
    private storageService: StorageService
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  modalDelet: MDBModalRef;
  modalRef: MDBModalRef;

  displayedColumns: string[] = ['title', 'date', 'view'];
  dataSource = new MatTableDataSource<any>();

  loading: boolean = false;
  notice_response; // var to save last get catalogs response

  /* pagination related variables */
  page: number = 1
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  id_establishment = null;

  color_primary

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    this.id_establishment = localStorage.getItem('id_establishment')

    if (this.id_establishment != null) {
      this.getNotices()
      this.dataSource.data = []
    }

  }

  getNotices() {
    this.loading = true;

    this.noticeService.getNotices(this.page).subscribe(data => {
      this.notice_response = data;
      this.dataSource.data = data.results;
      this.loading = false;

      // pagination calc
      this.count_total = data.count;
      this.page_count1 = 1 + ((this.page - 1) * this.page_size)
      if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
        this.page_count2 = this.count_total;
      }
      else {
        this.page_count2 = this.page_count1 + (this.page_size - 1);
      }
    })
  }

  nextPage() {
    if (this.notice_response.next != null) {
      this.page += 1;
      this.getNotices()
    }
  }

  backPage() {
    if (this.notice_response.previous != null) {
      this.page -= 1;
      this.getNotices()
    }
  }

  deleteNotice(id) {
    this.loading = true;
    this.noticeService.deleteNotice(id).subscribe(data => {
      this.loading = false;
      this.getNotices()
    })
  }

  openDelete(obj) {
    this.modalDelet = this.modalService.show(ModalChoiceComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        title: 'Excluir notícia',
        text: 'Deseja excluir a notícia ' + obj.name + '?',
      }
    })
    this.modalDelet.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.deleteNotice(obj.id)
      }
    });

  }

  openPreview(obj) {
    this.modalRef = this.modalService.show(CellPhoneComponent, {
      // backdrop: 'static',
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered popup-transparent',
      containerClass: '',
      animated: false,
      data: {
        isList: obj,
        isPopup: 'notice'
      }
    })

  }

}
