import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
    @Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    constructor(
        private campaignService: CampaignService,
        private storageService: StorageService,
        private router: Router
    ) {
        this.url_screen = router.url;
    }

    url_screen;
    listImage;
	qrcode_id;

    loading: boolean = true;
    url = AppConfig.path;
    type_page;

    async ngOnInit() {
        // console.log("-> Banner <-")
        // console.log(this.list);
        

        this.qrcode_id = (this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null) ? this.list.qr_codes[0].qr_code : null;

        this.storageService.screen.format = 'Banner';
        this.storageService.screen.id = this.list.id;

        this.campaignService.watchList().subscribe(data => {
            this.listImage = this.tratamento(this.list)
        })

        this.listImage = this.tratamento(this.list)
    }
    
    async addNewItem(list) {
        
        
        if (this.url_screen != '/application/dashboard'){
            await this.storageService.saveHistoric(this.campaign_id, list.id, (list.homepage_format || list.page_format), 2, this.type_page, this.qrcode_id)
        }


        if (this.preview_campaign) {
            if (list?.type?.name == "Link") {
                this.openLink2(list)
            }
            else {
                this.newItemEvent.emit(list);
            }
        }
        else {
            if (list?.page_format_obj?.name == "Link") {
                if (list.link != null) {
                    this.openLink(list.link)
                }
                else if (list.page_images != null) {
                    this.openLink(list.page_images[0].link)
                }
                else if (list.internal_images != null) {
                    this.openLink(list.internal_images[0].link)
                }
            }
            else if (list?.page_format_obj?.name == "Vazio") {
                // console.log("-> Vazio <-")
                return
            }
            else if (list?.page_format_obj?.name != null) {
                this.newItemEvent.emit(list);
            }

        }

    }

    openLink2(link) {
        if (link.link != null && link.link != '' && link.option_link != null) {
            if (link.option_link == "") {
                if(link.link.substring(0, 3) == 'sbs'){
                    this.newItemEvent.emit(`openReport/${link}`);
                }
                else if (link.link.substring(0, 4) != 'http') {
                    window.open(`http://${link.link}`);
                }
                else {
                    window.open(link.link);
                }
            }
            else {
                window.open(link.option_link + link.link);
            }

        }
    }

    openLink(link) {
        if (link != null && link != '') {
            if(link.substring(0, 3) == 'sbs'){
                this.newItemEvent.emit(`openReport/${link}`);
            }
            else if (link.substring(0, 3) == 'tel') {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
            else if (link.substring(0, 6) == 'mailto') {
                window.open(link);
            }
            else if (link.substring(0, 4) != 'http') {
                var aux_link = link.replace(/\s/g, '');
                window.open(`http://${aux_link}`);
            }
            else {
                var aux_link = link.replace(/\s/g, '');
                window.open(aux_link);
            }
        }
    }

    setType(list){
        var id = list?.id != null ? list.id : 
            (list != null && list[0]?.id != null ? list[0].id : null)

        this.type_page = this.storageService.checkTypePage(this.list, id)
    }

    tratamento(list) {
        if (this.preview_campaign) {
            if (this.list?.images != undefined) {
                return list.images;
            }
            else
                return null
        }
        else {
            if (list?.home_images != undefined) {
                this.type_page = 'home';
                return list.home_images;
            }
            else if (list?.internal_images != undefined) {
                // console.log('3',list.internal_images)
                this.type_page = 'internal';
                return list.internal_images;
            }
            else if (list?.page_images != undefined) {
                // console.log('2',list.page_images)
                this.type_page = 'page';
                return list.page_images;
            }
            else if (this.list?.home_images != undefined) {
                // console.log('1',list.home_images)
                this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }

    }
}
