<div class="screen">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> Gerar o Relatório </b>
        </h1>

    </div>

    <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 100%; position: relative;">
            <span class="title_video"> Selecione a Data </span>

            <div style="margin: 0 auto 0;" style="width: 100%;">
                <mat-select [(ngModel)]="date_select" class="input select-box"
                    [ngStyle]="{'border-color': warningMap.get('date')? 'red' : ''}">

                    <mat-option *ngFor="let obj of list_date; let i = index" (click)="setDateSelect(obj.day)"
                        [value]="obj.id">
                        {{ obj.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>

    <div class="div-input" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 100%; position: relative;">
            <span class="title_video"> Selecione a Campanha </span>

            <div style="margin: 0 auto 0;" style="width: 100%;">
                <mat-select [(ngModel)]="campaign_select" class="input select-box"
                    [ngStyle]="{'border-color': warningMap.get('campaign')? 'red' : ''}">

                    <mat-option *ngFor="let obj of list_campaigns; let i = index" (click)="select(obj)"
                        [value]="obj.id">
                        {{ obj.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>

    <input type="text" [(ngModel)]="email" placeholder="E-mail" class="input"
        [ngStyle]="{'border-color': warningMap.get('email')? 'red' : ''}">

    <!-- <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <input style="width: 70%; margin: 8px auto; cursor: pointer;" mat-input [(ngModel)]="link_download" matTooltip="Copiar"
            placeholder="Link de download"  class="input" readonly (click)="copyLink()" id="myLink" [disabled]="link_download == ''">
    </div> -->

    <div
        style="width: 70%; max-width: 450px; margin: 10px auto 0; display: flex; justify-content: space-between; align-items: center;">

        <button mat-button [style.background]="color_primary" class="b-border" (click)="close()">
            Fechar
        </button>

        <button mat-button [style.background]="color_primary" class="b-border" (click)="gerar()">
            Enviar
            <span *ngIf="loading_download" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
        </button>

        <!-- <a download="Relatório.xlsx" [href]="file_url" style="display: none;" #downloadLink id="downloadLink" ></a> -->

    </div>

</div>