<div style="margin: 0 50px;">
    <br><br>

    <img style="max-width: 120px; max-height: 80px; object-fit: contain;"
        src="../../../../assets/image/logo_principal_SBS.png">
    <h2>Formulário de Inspeção no Alarme de incêndio DIÁRIA</h2>

    <br><br>

    <table>
        <tr style="font-size: 9px;">
            <th> Local </th>
            <th> Verificado por </th>
            <th> Data </th>
            <th> Hora </th>
            <th> Mês </th>
        </tr>

        <tr style="font-size: 7px;">
            <td> {{ title }} </td>
            <td> {{ header['verification'] }} </td>
            <td> {{ header['data'] }} </td>
            <td> {{ header['hour'] }} </td>
            <td> {{ header['mother'] }} </td>
        </tr>
    </table>
<br><br>

    <table >
        <tr style="font-size: 10px;">
            <th style="width: 20%"> Questão </th>
            <th style="width: 20%"> Descrição </th>
        </tr>

        <tr *ngFor="let row of table_1" style="font-size: 8px;">
            <td> {{ row['Questão'] }} </td>
            <td> {{ row['Descrição'] }} </td>
        </tr>
    </table>

    <br><br>

    <table >
        <tr style="font-size: 10px;">
            <th style="width: 20%"> Dia </th>
            <th style="width: 20%"> Horário 1ºVerificação (Diurno) </th>
            <th style="width: 10%"> VG Nº12 </th>
            <th style="width: 20%"> VG Nº13 </th>
            <th style="width: 20%"> VG Nº14 </th>
            <th style="width: 20%"> Nome </th>
        </tr>

        <tr *ngFor="let row of table_header" style="font-size: 8px;">
            <td> {{ row['Dia'] }} </td>
            <td> {{ row['Horário 1ºVerificação (Diurno)'] }} </td>
            <td> {{ row['VG Nº12'] }} </td>
            <td> {{ row['VG Nº13'] }} </td>
            <td> {{ row['VG Nº14'] }} </td>
            <td> {{ row['Nome'] }} </td>
        </tr>
    </table>

    <br><br>

    <table >
        <tr style="font-size: 10px;">
            <th style="width: 20%"> Dia </th>
            <th style="width: 20%"> Horário 1ºVerificação (Diurno) </th>
            <th style="width: 10%"> VG Nº12 </th>
            <th style="width: 20%"> VG Nº13 </th>
            <th style="width: 20%"> VG Nº14 </th>
            <th style="width: 20%"> Nome </th>
        </tr>

        <tr *ngFor="let row of table_header" style="font-size: 8px;">
            <td> {{ row['Dia'] }} </td>
            <td> {{ row['Horário 1ºVerificação (Diurno)'] }} </td>
            <td> {{ row['VG Nº12'] }} </td>
            <td> {{ row['VG Nº13'] }} </td>
            <td> {{ row['VG Nº14'] }} </td>
            <td> {{ row['Nome'] }} </td>
        </tr>
    </table>

    <br><br>

    <table>
        <tr style="font-size: 10px;">
            <th> Elaborado </th>
            <th> Revisado: </th>
            <th> Aprovado </th>
            <th> Revisão: </th>
            <th> Data: </th>
            <th> Referência </th>
        </tr>

        <tr style="font-size: 8px;">
            <td> {{ footer ['Elaborado'] }} </td>
            <td> </td>
            <td> {{ footer['Aprovado'] }} </td>
            <td> </td>
            <td> {{ footer['Data'] }} </td>
            <td> {{ footer['Referência'] }} </td>
        </tr>
    </table>
</div>