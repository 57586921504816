<div class="catalog-v-container" *ngIf="!loading && checkPage(0)">
    <div class="catalog-v-header">
        <div class="header-logo">
            <img *ngIf="catalog.logo_menu == null" alt="logo" src="../../../assets/image/logo_principal_SBS.png" />
            <img *ngIf="catalog.logo_menu != null" alt="logo" [src]="catalog.logo_menu" />
        </div>
        <div>
            <div class="header-title"> {{ catalog.name != null ? catalog.name : ''}} </div>
            <div class="header-subtitle"> {{ catalog.description != null ? catalog.description : ''}} </div>
        </div>
        <div class="right-header">
            <span>.</span>
        </div>
    </div>

    <!-- <div class="catalog-v-caroussel">
        <ngx-slick-carousel class="carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img src="{{ slide.icon }}" class="image_slide" width="100%">
            </div>
        </ngx-slick-carousel>
    </div> -->

    <div class="allergin">
        {{catalog.informative_text != null && catalog.informative_text != '' ? catalog.informative_text : 'Consulte a lista de alergênicos' }}
        <a style="cursor: pointer;" (click)="openLink(catalog.link)">aqui</a>
    </div>

    <div class='subcategories'>
        <ul class="custom-scrollbar">
            <li *ngFor="let section of list_sections; let i = index"
                [ngClass]="{'sub-active': selectedSubIndex == i}" (click)="setSubIndex(i)">
                <div class="sub-card">
                    {{ section.name }}
                </div>
            </li>
        </ul>
        <div *ngFor="let section of list_sections; let i = index">
            <div class="sub-header" attr.id="target{{i}}">
                {{ section.name }}
                <div class="sub-description">
                    {{ section.description }}
                </div>
            </div>
            <div class="sub-items-container">
                <div class="item-card" *ngFor="let item of section.products">
                    <div class="item-info">

                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-description">
                            {{ item.description }}
                        </div>
                        <div class="item-price">
                            R$ {{ item.price.toFixed(2)}}
                        </div>
                        <div class="item-more-info" style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                            <a style="cursor: pointer;" (click)="openProduct(item)">Saiba mais</a>
                            <a style="cursor: pointer;" (click)="addProduct(item)" *ngIf="catalog.purchase">Adicionar</a>
                        </div>
                    </div>
                    <div *ngIf="item.image != null" class="item-img">
                        <img src="{{ item.image }}" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="footer-text" *ngIf="catalog.footer_text != null && catalog.footer_text != ''">
            {{catalog.footer_text}}
        </div>
        <div class="footer-img">
            <img src='' alt='' />
        </div>
    </div>

</div>

<div *ngIf="getQtd() != 0" class="btn_circle" [ngClass]="{'btn_buy_web' : web, 'btn_buy_cellphone' : phone}" matTooltip="Ver carrinho"
    [style.background]="header.back" (click)="trocaPage(1)">
    <span [matBadge]="getQtd()" matBadgeOverlap="false" matBadgeColor="warn" class="qtd-itens"></span>
    <svg class="icons" viewBox="0 0 256.000000 256.000000">
        <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" [style.fill]="header.color" stroke="none">
            <path d="M115 2535 c-60 -59 -28 -90 185 -180 l175 -73 5 -819 c6 -912 0 -849 77 -933 37 -42 120 -90 156 -90 11 0 17 -5 13 -12 -55 -134 -42 -239 44 -332 168 -183 468 -79 487 168 4 45 1 79 -11 112 -9 26 -16 49 -16 51 0 2 142 3 315 3 173 0 315 -2 315 -4 0 -2 -7 -20 -15 -39 -21 -51 -19 -162 4 -213 27 -59 77 -112 135 -142 73 -37 179 -37 252 0 100 52 154 140 154 253 0 112 -56 205 -155 258 -40 22 -45 22 -775 27 -690 5 -737 6 -765 23 -61 38 -70 60 -73 195 -2 67 -2 122 1 122 2 0 24 -7 47 -15 36 -13 153 -15 810 -15 l767 0 61 31 c64 32 110 79 140 146 15 33 17 81 20 380 l3 343 -23 22 c-21 22 -88 32 -925 148 l-903 124 0 137 c0 116 -3 140 -17 157 -18 20 -419 192 -448 192 -9 0 -27 -11 -40 -25z m795 -640 c140 -20 500 -69 800 -110 300 -41 562 -78 583 -81 l38 -6 -3 -293 c-3 -280 -4 -295 -24 -322 -12 -15 -36 -37 -55 -48 -33 -20 -52 -20 -774 -20 -728 0 -741 0 -775 21 -19 11 -45 35 -57 54 -23 33 -23 34 -23 436 0 382 1 404 18 404 10 0 132 -16 272 -35z m144 -1484 c43 -29 66 -70 66 -121 0 -113 -113 -183 -208 -130 -102 57 -107 191 -9 250 42 26 113 26 151 1z m1134 -1 c43 -27 76 -99 67 -146 -3 -19 -20 -50 -36 -69 -117 -132 -314 10 -233 167 34 65 135 89 202 48z"/>
        </g>
    </svg>        
</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!check_login && checkPage(1)">
    <app-shopping-cart style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog" (newItemEvent)="finish($event)"></app-shopping-cart>
</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="!check_login && checkPage(2)">
    <app-finish-buy style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog" (newItemEvent)="reset($event)"></app-finish-buy>
</div>

<div class="card_shopping_cart" [ngClass]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="check_login && checkPage(1)">
    <app-login-user style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="catalog" (newItemEvent)="login($event)"></app-login-user>
</div>

<div *ngIf="checkPage(4)">
    <app-view-product [product]="obj_product" [purchase]="catalog.purchase">
    </app-view-product>
</div>