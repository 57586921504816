<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>

    </div>

    <div class="modal-body">

        {{text}}

        <div style="width: 100%; margin-top: 40px; margin-bottom: 5px;">
            <button mat-button class="main-button button" [style.background]="color_primary" (click)="close('no')"> 
                NÃO
            </button> 

            <button mat-button class="main-button button" [style.background]="color_primary" (click)="close('yes')"> 
                SIM
            </button> 
        </div>
        
    </div>

</div>