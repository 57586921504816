<div class="modal-content">

    <svg (click)="closePopup()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
        [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path [style.fill]="color_primary"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> Links de Podcast </b>
        </h1>

        <div class="card_links">
            <div *ngFor="let obj of list_options" style="width: 100%;">
                <div class="button_link _spotify" *ngIf="obj.name == 'Spotify' && obj.link != '' && obj.link != null ">
                    <img src="../../../../assets/logo_podcast/spotify.png" class="logo">
                    <a [href]="obj.link" target="_black"> {{ obj.name }} </a>
                </div>

                <div class="button_link _deezer" *ngIf="obj.name == 'Deezer' && obj.link != '' && obj.link != null ">
                    <img src="../../../../assets/logo_podcast/deezer.png" class="logo">
                    <a [href]="obj.link" target="_black"> {{ obj.name }} </a>
                </div>

                <div class="button_link _itunes" *ngIf="obj.name == 'iTunes' && obj.link != '' && obj.link != null ">
                    <img src="../../../../assets/logo_podcast/itunes.png" class="logo">
                    <a [href]="obj.link" target="_black"> {{ obj.name }} </a>
                </div>

                <div class="button_link _castbox" *ngIf="obj.name == 'Castbox' && obj.link != '' && obj.link != null ">
                    <img src="../../../../assets/logo_podcast/castbox.png" class="logo">
                    <a [href]="obj.link" target="_black"> {{ obj.name }} </a>
                </div>

                <div class="button_link _google" *ngIf="obj.name == 'Google Podcast' && obj.link != '' && obj.link != null ">
                    <img src="../../../../assets/logo_podcast/google_podcasts.png" class="logo">
                    <a [href]="obj.link" target="_black"> {{ obj.name }} </a>
                </div>
            </div>
        </div>
    </div>

</div>