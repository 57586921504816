<div class="screen">
    <div class="title">
        <h1><b> Venda muito mais! </b></h1>
        Dispare suas Campanhas!
    </div>

    <div class="options" *ngIf="!loading && selected_tab == null">
        <div class="channel" (click)="selected_tab = 0">
            <!-- <img src="/assets/icon/channels/svg/criar qrcode.png" class="image-channels"> -->
            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 215 0 215 0 0 55 0 55 -160 0 -160 0 0 295 0 295
                295 0 295 0 0 -165 0 -165 55 0 55 0 0 215 0 215 -405 0 -405 0 0 -400z" />
                <path fill="#7c7c7c" d="M2170 2760 l0 -210 55 0 55 0 0 160 0 160 295 0 295 0 0 -295 0 -295
                -160 0 -160 0 0 -55 0 -55 215 0 215 0 0 400 0 400 -405 0 -405 0 0 -210z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 100 0 c99 0 100 0 114 28 16 31 90 102 106 102 6
                0 10 42 10 105 l0 105 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2410 2635 c0 -98 1 -105 23 -116 33 -17 77 -60 93 -91 14 -28 16
                -28 114 -28 l100 0 0 170 0 170 -165 0 -165 0 0 -105z" />
                <path [style.fill]="color_primary" d="M713 2480 c-56 -13 -110 -56 -139 -110 -27 -50 -34 -348 -10 -385 21
                -31 70 -34 86 -6 5 11 10 86 10 171 0 227 3 230 231 230 85 0 159 5 170 10 27
                15 25 64 -3 84 -18 13 -51 16 -168 15 -80 -1 -160 -4 -177 -9z" />
                <path [style.fill]="color_primary" d="M1972 2474 c-27 -18 -29 -55 -4 -77 16 -14 44 -17 181 -17 l163 0 29
                -31 29 -31 0 -163 c0 -144 2 -164 18 -178 26 -23 55 -21 80 6 21 22 22 33 22
                168 0 80 -4 149 -8 154 -5 6 -14 29 -21 52 -14 52 -75 107 -136 122 -24 6
                -108 11 -187 11 -114 0 -148 -3 -166 -16z" />
                <path [style.fill]="color_primary" d="M967 2176 c-87 -31 -107 -75 -107 -233 0 -126 6 -143 46 -143 52 0
                54 4 54 124 0 154 2 156 150 156 85 0 112 3 124 16 28 27 19 62 -21 83 -30 15
                -199 13 -246 -3z" />
                <path [style.fill]="color_primary" d="M1813 2175 c-25 -18 -31 -63 -11 -83 8 -8 49 -12 118 -12 150 0 150
                0 150 -144 0 -121 6 -136 50 -136 45 0 50 15 50 151 0 114 -2 129 -23 159 -39
                60 -78 74 -202 78 -89 3 -114 0 -132 -13z" />
                <path [style.fill]="color_primary" d="M572 1558 c-17 -17 -15 -54 4 -72 14 -14 112 -16 935 -16 615 0 927
                3 940 10 19 11 25 43 13 74 -6 14 -98 16 -943 16 -715 0 -940 -3 -949 -12z" />
                <path [style.fill]="color_primary" d="M889 1246 c-23 -11 -24 -16 -27 -137 -2 -116 -1 -128 20 -159 46 -67
                66 -75 203 -78 114 -3 125 -2 144 17 25 25 26 40 7 67 -13 16 -29 19 -129 22
                -148 5 -145 2 -149 151 -3 105 -5 114 -24 122 -12 4 -31 2 -45 -5z" />
                <path [style.fill]="color_primary" d="M2082 1238 c-8 -8 -12 -48 -12 -116 0 -145 3 -142 -128 -142 -123 0
                -152 -11 -152 -60 0 -42 23 -50 147 -50 93 0 115 3 153 23 67 34 80 68 80 209
                0 64 -3 123 -6 132 -7 19 -64 22 -82 4z" />
                <path [style.fill]="color_primary" d="M566 1068 c-13 -18 -16 -52 -16 -166 0 -165 10 -215 52 -266 53 -64
                73 -70 261 -74 142 -3 171 -2 192 12 32 21 33 59 3 80 -18 13 -54 16 -181 16
                l-159 0 -29 31 -29 31 0 163 c0 144 -2 164 -18 178 -26 23 -58 21 -76 -5z" />
                <path [style.fill]="color_primary" d="M2386 1068 c-13 -18 -16 -54 -16 -181 l0 -159 -29 -29 -29 -29 -159
                0 c-127 0 -163 -3 -181 -16 -30 -21 -29 -59 3 -80 21 -14 50 -15 192 -12 116
                2 176 8 193 17 59 32 110 97 110 141 0 11 5 20 10 20 6 0 10 57 10 150 0 140
                -1 152 -22 175 -27 31 -62 32 -82 3z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 405 0 405 0 0 210 0 210 -55 0 -55 0 0 -155 0 -155
                -295 0 -295 0 0 295 0 295 160 0 160 0 0 50 0 50 -215 0 -215 0 0 -400z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 100 c0 61 -4 100 -10 100 -18 0 -97
                79 -110 110 l-12 30 -99 0 -99 0 0 -170z" />
                <path fill="#7c7c7c" d="M2535 619 c-10 -29 -63 -87 -96 -105 -29 -14 -29 -15 -29 -114 l0
                -100 165 0 165 0 0 170 0 170 -99 0 c-92 0 -100 -2 -106 -21z" />
            </svg>

            <div class="text-channel"> QRCode </div>
        </div>

        <!--<div class="channel" (click)="selected_tab = 1">
            
            <svg class="image-channels img_svg"viewBox="0 0 3000 3000" >
                <path fill="#7c7c7c" d="M70 2570 l0 -400 361 0 360 0 -2 264 c-2 258 -1 265 21 300 12 21 33
                44 46 53 22 14 24 22 24 99 l0 84 -405 0 -405 0 0 -400z m700 5 l0 -295 -295
                0 -295 0 0 295 0 295 295 0 295 0 0 -295z"/>
                <path fill="#7c7c7c" d="M310 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z"/>
                <path fill="#7c7c7c" d="M2170 2880 c0 -78 2 -91 19 -100 11 -5 29 -26 42 -46 21 -35 22 -42
                20 -300 l-2 -264 365 0 366 0 0 400 0 400 -405 0 -405 0 0 -90z m700 -305 l0
                -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z"/>
                <path fill="#7c7c7c" d="M2410 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z"/>
                <path [style.fill]="color_primary" d="M943 2711 c-24 -12 -55 -37 -72 -62 l-31 -42 0 -1081 0 -1081 23 -38
                c25 -41 71 -77 100 -77 9 0 17 -4 17 -10 0 -7 185 -10 535 -10 350 0 535 3
                535 10 0 6 8 10 18 10 28 0 89 47 103 80 8 16 17 30 21 30 5 0 8 483 8 1074
                l0 1073 -25 46 c-20 34 -39 52 -77 71 l-52 26 -530 0 c-500 0 -533 -2 -573
                -19z m847 -110 c7 -13 7 -28 0 -45 l-10 -26 -259 0 c-249 0 -261 1 -271 20 -6
                11 -8 31 -4 45 l6 25 264 0 c250 0 264 -1 274 -19z m278 -948 l-3 -778 -550 0
                -550 0 -3 778 -2 777 555 0 555 0 -2 -777z m-477 -972 c29 -29 39 -47 39 -70
                0 -92 -100 -153 -170 -104 -41 28 -49 45 -50 99 0 42 4 54 28 76 55 52 101 51
                153 -1z"/>
                <path fill="#7c7c7c" d="M70 470 l0 -400 405 0 405 0 0 78 c0 71 -2 81 -26 103 -14 13 -35 39
                -46 57 -20 33 -21 46 -19 298 l2 264 -360 0 -361 0 0 -400z m700 5 l0 -295
                -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z"/>
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z"/>
                <path fill="#7c7c7c" d="M2410 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z"/>
            </svg>
            <div class="text-channel"> App </div>
        </div> -->

        <div class="channel" (click)="selected_tab = 1">
            <!-- <img src="/assets/icon/channels/svg/embed.png" class="image-channels"> -->
            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 180 0 180 0 0 55 0 55 -125 0 -125 0 0 295 0 295
                295 0 295 0 0 -155 0 -155 50 0 50 0 0 205 0 205 -400 0 -400 0 0 -400z" />
                <path fill="#7c7c7c" d="M2170 2765 l0 -205 55 0 55 0 0 155 0 155 295 0 295 0 0 -295 0 -295
                -130 0 -130 0 0 -55 0 -55 185 0 185 0 0 400 0 400 -405 0 -405 0 0 -205z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 59 0 58 0 7 40 c4 26 19 54 40 77 31 34 39 37 100
                41 l66 4 0 89 0 89 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2410 2650 l0 -90 40 0 c84 0 142 -46 155 -120 l7 -40 64 0 64 0 0
                170 0 170 -165 0 -165 0 0 -90z" />
                <path [style.fill]="color_primary" d="M610 2479 c0 -5 -5 -7 -10 -4 -13 8 -80 -58 -80 -79 0 -9 -4 -16 -10
                -16 -6 0 -10 -55 -10 -145 0 -90 4 -145 10 -145 6 0 10 -8 10 -19 0 -22 41
                -68 72 -80 13 -5 431 -9 931 -9 l908 -1 34 31 c20 16 38 41 41 54 3 13 10 24
                15 24 5 0 9 60 9 133 0 167 -16 209 -97 255 -30 16 -1823 18 -1823 1z m1758
                -240 l3 -126 -212 -6 c-269 -9 -630 -9 -1116 0 l-383 6 0 122 c0 67 3 125 7
                128 3 4 387 6 852 5 l846 -3 3 -126z" />
                <path [style.fill]="color_primary" d="M571 1789 c-18 -11 -38 -33 -44 -49 -6 -17 -15 -30 -19 -30 -4 0 -8
                -253 -8 -562 l1 -563 27 -40 c53 -77 28 -74 655 -75 306 0 557 4 557 8 0 4 16
                14 35 21 65 23 64 6 66 648 1 433 -1 585 -10 602 -7 12 -27 31 -44 41 -31 19
                -55 20 -607 20 -562 0 -576 -1 -609 -21z m1157 -640 c2 -419 0 -508 -12 -518
                -10 -8 -157 -10 -537 -9 l-524 3 -3 505 c-1 278 0 511 3 518 3 10 116 12 537
                10 l533 -3 3 -506z" />
                <path [style.fill]="color_primary" d="M948 1469 c-16 -9 -18 -33 -18 -277 0 -272 3 -292 38 -292 14 0 508
                244 530 262 6 6 12 20 12 32 0 19 -40 42 -269 154 -148 73 -270 132 -272 132
                -2 0 -12 -5 -21 -11z" />
                <path [style.fill]="color_primary" d="M2085 1800 c-11 -4 -31 -20 -45 -35 l-25 -27 -3 -561 c-2 -309 0
                -576 3 -594 7 -38 58 -93 86 -93 10 0 19 -4 19 -10 0 -6 54 -10 143 -10 129 0
                146 2 182 23 23 13 49 39 62 62 21 40 21 45 22 595 l1 555 -25 38 c-42 61 -63
                67 -242 66 -87 0 -167 -4 -178 -9z m345 -660 l0 -520 -165 0 -165 0 -2 516
                c-2 284 -1 518 1 520 2 2 78 4 168 4 l163 0 0 -520z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 400 0 400 0 0 155 0 155 -50 0 -50 0 0 -105 0 -105
                -295 0 -295 0 0 295 0 295 125 0 125 0 0 55 0 55 -180 0 -180 0 0 -400z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 39 0 38 -58 6 c-76 8 -81 11 -119 59
                -31 38 -33 45 -33 119 l0 79 -60 0 -60 0 0 -170z" />
                <path fill="#7c7c7c" d="M2610 562 c0 -87 -21 -129 -80 -159 -24 -13 -61 -23 -82 -23 l-38 0
                0 -45 0 -45 165 0 165 0 0 170 0 170 -65 0 -65 0 0 -68z" />
            </svg>
            <div class="text-channel"> Incorporar </div>
        </div>

        <div class="channel" (click)="selected_tab = 2">
            <!-- <img src="/assets/icon/channels/svg/mail-mkt.png" class="image-channels"> -->
            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 355 0 355 0 0 190 c0 145 3 190 13 191 6 1 26 2 42
                3 l30 1 3 208 2 207 -400 0 -400 0 0 -400z m700 5 l0 -295 -295 0 -295 0 0
                295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2170 2760 l0 -210 28 0 c49 0 52 -10 52 -202 l0 -178 365 0 365 0 0
                400 0 400 -405 0 -405 0 0 -210z m700 -185 l0 -295 -295 0 -295 0 0 295 0 295
                295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M2410 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path [style.fill]="color_primary" d="M842 2468 c-9 -9 -12 -79 -12 -259 l0 -247 -82 -63 c-44 -35 -103
                -83 -130 -105 -27 -23 -73 -59 -103 -80 l-54 -39 -1 -632 0 -633 1052 0 1051
                0 -7 558 c-8 653 -10 700 -40 723 -12 9 -88 71 -171 138 l-150 122 -5 262 -5
                262 -665 3 c-510 2 -669 -1 -678 -10z m1298 -537 l0 -499 -141 -92 -141 -92
                -25 24 c-14 12 -88 94 -166 181 -77 86 -147 157 -156 157 -13 0 -198 -197
                -312 -332 l-26 -32 -35 21 c-20 11 -85 54 -147 94 l-111 74 0 491 c0 270 3
                494 7 497 3 4 287 7 630 7 l623 0 0 -499z m-1314 -458 c-5 -6 -236 145 -236
                154 1 5 54 49 118 98 l117 90 3 -169 c1 -93 1 -171 -2 -173z m1495 245 c65
                -51 115 -96 111 -100 -10 -10 -231 -148 -237 -148 -3 0 -5 77 -5 170 0 94 3
                170 6 170 3 0 59 -41 125 -92z m-1561 -267 c85 -55 203 -131 263 -168 59 -37
                107 -71 107 -74 0 -4 -105 -122 -232 -263 -128 -141 -253 -281 -278 -311 -25
                -29 -47 -52 -50 -50 -3 2 -4 224 -2 494 2 463 3 491 20 481 9 -5 87 -54 172
                -109z m1700 -378 c0 -422 -2 -495 -14 -483 -7 8 -116 130 -241 270 -125 140
                -247 275 -271 299 -24 25 -44 47 -44 50 0 3 37 28 81 55 45 28 170 107 278
                178 108 70 199 127 204 128 4 0 7 -224 7 -497z m-663 161 c375 -418 434 -484
                464 -513 14 -15 55 -61 92 -103 l68 -78 -906 -2 c-498 -2 -905 0 -905 3 0 5
                745 842 819 920 13 13 34 39 48 57 14 18 29 31 34 30 4 -2 133 -143 286 -314z" />
                <path [style.fill]="color_primary" d="M1110 2328 c-30 -51 -38 -58 -74 -58 -19 0 -39 -7 -46 -15 -11 -13
                -8 -21 21 -51 31 -32 34 -40 31 -85 -4 -60 12 -71 63 -39 l35 22 36 -21 c61
                -36 76 -26 63 44 -7 37 -5 43 28 77 31 31 34 40 23 53 -7 8 -27 15 -46 15 -36
                0 -44 7 -74 58 -11 17 -24 32 -30 32 -6 0 -19 -15 -30 -32z m45 -77 c3 -10 17
                -22 31 -25 25 -7 26 -8 9 -26 -10 -11 -15 -28 -12 -38 4 -17 -1 -20 -43 -19
                -39 1 -46 4 -43 17 3 10 -3 25 -13 34 -14 15 -15 19 -3 27 8 5 19 9 26 9 6 0
                15 9 18 20 8 25 22 26 30 1z" />
                <path [style.fill]="color_primary" d="M1480 2328 c-30 -51 -38 -58 -74 -58 -19 0 -39 -7 -46 -15 -11 -13
                -8 -22 22 -53 34 -35 36 -40 31 -89 -5 -46 -3 -53 12 -53 10 0 32 9 50 20 l33
                21 47 -23 c55 -27 66 -18 55 44 -7 37 -5 44 27 79 29 32 33 42 23 54 -7 8 -27
                15 -46 15 -36 0 -44 7 -74 58 -11 17 -24 32 -30 32 -6 0 -19 -15 -30 -32z m45
                -77 c3 -10 17 -22 30 -25 29 -7 31 -18 10 -36 -9 -7 -13 -20 -10 -30 4 -13 -3
                -16 -44 -17 -43 -1 -48 1 -44 19 3 10 -2 27 -11 37 -16 18 -16 19 8 25 14 3
                28 15 31 26 8 25 22 26 30 1z" />
                <path [style.fill]="color_primary" d="M1848 2318 c-20 -37 -29 -44 -62 -48 -59 -8 -68 -32 -28 -73 30 -31
                32 -37 26 -80 -8 -58 7 -67 65 -38 l40 21 33 -20 c18 -11 42 -20 52 -20 17 0
                18 5 13 52 -6 49 -5 54 25 86 40 40 31 65 -26 70 -32 3 -41 10 -64 47 -15 25
                -32 45 -39 45 -6 0 -22 -19 -35 -42z m52 -73 c0 -7 12 -16 26 -19 25 -7 26 -8
                9 -26 -10 -11 -15 -28 -13 -38 5 -16 0 -19 -39 -18 -36 1 -43 4 -40 18 3 9 -4
                25 -14 34 -19 17 -19 18 11 30 17 7 33 20 36 29 7 17 24 11 24 -10z" />
                <path [style.fill]="color_primary" d="M987 1926 c-13 -34 10 -36 529 -36 459 0 512 2 518 16 3 9 4 20 0 25
                -3 5 -223 9 -524 9 -431 0 -519 -2 -523 -14z" />
                <path [style.fill]="color_primary" d="M1090 1765 c-11 -13 -10 -18 6 -30 29 -22 816 -22 834 0 10 12 10 18
                0 30 -19 23 -821 23 -840 0z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 400 0 400 0 0 135 0 135 -50 0 -50 0 0 -85 0 -85
                -295 0 -295 0 0 295 0 295 100 0 100 0 0 55 0 55 -155 0 -155 0 0 -400z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 c158 0 165 1 165 20 0 19 -7 20 -130 20
                l-130 0 0 150 0 150 -35 0 -35 0 0 -170z" />
                <path fill="#7c7c7c" d="M2640 485 l0 -145 -115 0 -115 0 0 -25 0 -25 165 0 165 0 0 170 0
                170 -50 0 -50 0 0 -145z" />
            </svg>

            <div class="text-channel"> E-mail </div>
        </div>

        <div class="channel" (click)="selected_tab = 3">
            <!-- <img src="/assets/icon/channels/svg/whats-sms.png" class="image-channels"> -->
            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 229 0 229 0 36 50 c20 28 36 52 36 55 0 3 -94 5
                -210 5 l-210 0 0 295 0 295 295 0 295 0 0 -219 0 -219 50 27 50 26 0 243 0
                242 -400 0 -400 0 0 -400z" />
                <path fill="#7c7c7c" d="M2170 2570 l0 -400 405 0 405 0 0 400 0 400 -405 0 -405 0 0 -400z
                m700 5 l0 -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M2410 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path [style.fill]="color_primary" d="M1165 2500 c-3 -5 -21 -10 -38 -10 -52 0 -199 -45 -268 -81 -41 -22
                -96 -66 -154 -124 -73 -72 -98 -105 -128 -167 -58 -123 -71 -185 -71 -328 0
                -109 4 -135 27 -205 15 -44 36 -95 46 -114 l18 -33 -49 -175 c-27 -96 -47
                -177 -45 -180 3 -2 85 17 182 43 161 44 179 47 208 35 116 -49 177 -64 272
                -69 102 -5 115 -10 115 -47 0 -11 16 -54 35 -94 61 -126 191 -235 342 -285 77
                -26 318 -43 386 -27 38 9 41 8 100 -46 34 -31 79 -74 100 -95 26 -26 46 -38
                60 -36 21 3 22 8 25 153 l3 149 54 54 c64 64 120 169 136 255 31 170 -62 353
                -239 468 -86 56 -165 84 -287 99 -49 6 -90 12 -91 13 -1 1 3 31 8 67 12 77 -2
                208 -32 294 -60 176 -193 332 -350 411 -55 28 -185 65 -228 65 -17 0 -34 5
                -37 10 -3 6 -26 10 -50 10 -24 0 -47 -4 -50 -10z m167 -125 c210 -44 391 -216
                454 -430 24 -80 24 -220 0 -300 -61 -206 -223 -367 -422 -420 -68 -18 -215
                -21 -294 -6 -30 6 -85 26 -122 44 l-67 33 -106 -29 c-59 -16 -109 -26 -112
                -23 -3 2 9 52 26 111 l30 106 -29 47 c-55 90 -74 165 -74 287 0 92 4 121 26
                182 105 293 396 461 690 398z m768 -824 c84 -27 182 -84 239 -142 120 -119
                149 -294 74 -444 -14 -27 -55 -78 -91 -113 l-64 -62 1 -106 2 -107 -78 72
                c-73 67 -80 72 -113 66 -118 -20 -171 -24 -246 -15 -250 31 -429 170 -478 372
                -6 25 -3 27 51 43 32 9 59 13 60 8 26 -78 84 -121 147 -109 92 17 128 119 67
                188 l-28 32 58 54 c63 58 135 166 165 246 l18 49 75 -6 c42 -3 105 -15 141
                -26z m-486 -387 c19 -18 21 -55 4 -72 -19 -19 -55 -14 -72 9 -16 23 -12 47 12
                67 21 16 37 15 56 -4z" />
                <path [style.fill]="color_primary" d="M908 2093 c-63 -68 -75 -151 -34 -238 29 -64 127 -187 196 -247 93
                -81 241 -148 328 -148 83 0 156 55 167 125 l6 40 -83 38 c-46 20 -88 37 -95
                37 -7 0 -24 -16 -39 -35 -42 -56 -53 -58 -124 -24 -64 30 -157 116 -192 177
                -17 29 -17 30 18 75 l36 46 -39 88 c-39 88 -39 88 -79 91 -32 2 -44 -2 -66
                -25z" />
                <path [style.fill]="color_primary" d="M1841 1226 c-33 -18 -50 -53 -51 -104 0 -78 72 -127 151 -103 121 37
                93 222 -34 221 -23 -1 -53 -7 -66 -14z m91 -58 c42 -34 18 -91 -35 -86 -42 4
                -60 47 -32 78 21 23 44 26 67 8z" />
                <path [style.fill]="color_primary" d="M2160 1227 c-35 -17 -63 -79 -55 -117 16 -74 87 -114 159 -90 67 22
                96 96 64 165 -22 50 -110 71 -168 42z m92 -64 c21 -18 24 -53 6 -71 -19 -19
                -55 -14 -72 9 -16 23 -12 47 12 67 21 16 32 15 54 -5z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 400 0 400 0 0 400 0 400 -400 0 -400 0 0 -400z m700
                -5 l0 -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2410 460 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
            </svg>

            <div class="text-channel"> Whatsapp </div>
        </div>

        <div class="channel" (click)="selected_tab = 4">
            <!-- <img src="/assets/icon/channels/svg/megafone.png" class="image-channels"> -->
            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 405 0 405 0 0 400 0 400 -405 0 -405 0 0 -400z
                m700 5 l0 -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2170 2570 l0 -400 405 0 405 0 0 400 0 400 -405 0 -405 0 0 -400z
                m700 5 l0 -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M2410 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path [style.fill]="color_primary" d="M1760 2148 c-210 -129 -421 -223 -646 -287 -168 -47 -294 -67 -476
                -76 -159 -8 -157 -7 -183 -120 -13 -55 -14 -77 -4 -126 16 -84 45 -123 91
                -127 36 -3 38 -5 116 -150 44 -81 108 -202 143 -269 l64 -123 108 0 c59 0 107
                2 107 4 0 3 -123 338 -161 439 -12 31 -20 60 -17 62 7 7 178 -29 263 -55 190
                -59 423 -167 609 -282 43 -27 80 -47 82 -45 2 2 3 276 2 609 l-3 604 -95 -58z" />
                <path [style.fill]="color_primary" d="M2350 2077 c-30 -19 -96 -58 -147 -87 -51 -30 -95 -60 -98 -68 -8
                -21 13 -52 36 -52 18 0 279 143 317 173 7 6 12 22 10 36 -5 40 -50 39 -118 -2z" />
                <path [style.fill]="color_primary" d="M1960 1600 l0 -170 25 0 c35 0 91 53 111 105 21 55 13 115 -21 166
                -21 33 -76 69 -104 69 -8 0 -11 -53 -11 -170z" />
                <path [style.fill]="color_primary" d="M2197 1633 c-11 -11 -8 -48 5 -61 8 -8 65 -12 189 -12 171 0 178 1
                193 22 11 15 12 26 5 40 -9 16 -25 18 -198 18 -103 0 -191 -3 -194 -7z" />
                <path [style.fill]="color_primary" d="M2110 1320 c-7 -13 -7 -26 -1 -38 7 -14 195 -128 299 -181 30 -16 62
                2 62 34 0 19 -14 32 -72 66 -40 23 -110 64 -154 91 -89 52 -118 58 -134 28z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 405 0 405 0 0 355 0 355 -33 0 c-29 0 -36 5 -55 45
                l-22 45 -350 0 -350 0 0 -400z m700 5 l0 -295 -295 0 -295 0 0 295 0 295 295
                0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2410 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
            </svg>
            <div class="text-channel"> SMS </div>
        </div>

        <div class="channel" (click)="selected_tab = 5">

            <svg class="image-channels img_svg" viewBox="0 0 3000 3000">
                <path fill="#7c7c7c" d="M70 2570 l0 -400 361 0 360 0 -2 264 c-2 258 -1 265 21 300 12 21 33
                44 46 53 22 14 24 22 24 99 l0 84 -405 0 -405 0 0 -400z m700 5 l0 -295 -295
                0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2170 2880 c0 -78 2 -91 19 -100 11 -5 29 -26 42 -46 21 -35 22 -42
                20 -300 l-2 -264 365 0 366 0 0 400 0 400 -405 0 -405 0 0 -90z m700 -305 l0
                -295 -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M2410 2570 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path [style.fill]="color_primary" d="M943 2711 c-24 -12 -55 -37 -72 -62 l-31 -42 0 -1081 0 -1081 23 -38
                c25 -41 71 -77 100 -77 9 0 17 -4 17 -10 0 -7 185 -10 535 -10 350 0 535 3
                535 10 0 6 8 10 18 10 28 0 89 47 103 80 8 16 17 30 21 30 5 0 8 483 8 1074
                l0 1073 -25 46 c-20 34 -39 52 -77 71 l-52 26 -530 0 c-500 0 -533 -2 -573
                -19z m847 -110 c7 -13 7 -28 0 -45 l-10 -26 -259 0 c-249 0 -261 1 -271 20 -6
                11 -8 31 -4 45 l6 25 264 0 c250 0 264 -1 274 -19z m278 -948 l-3 -778 -550 0
                -550 0 -3 778 -2 777 555 0 555 0 -2 -777z m-477 -972 c29 -29 39 -47 39 -70
                0 -92 -100 -153 -170 -104 -41 28 -49 45 -50 99 0 42 4 54 28 76 55 52 101 51
                153 -1z" />
                <path fill="#7c7c7c" d="M70 470 l0 -400 405 0 405 0 0 78 c0 71 -2 81 -26 103 -14 13 -35 39
                -46 57 -20 33 -21 46 -19 298 l2 264 -360 0 -361 0 0 -400z m700 5 l0 -295
                -295 0 -295 0 0 295 0 295 295 0 295 0 0 -295z" />
                <path fill="#7c7c7c" d="M310 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
                <path fill="#7c7c7c" d="M2410 470 l0 -170 165 0 165 0 0 170 0 170 -165 0 -165 0 0 -170z" />
            </svg>
            <div class="text-channel"> Site </div>
        </div>

        <!-- <div class="channel">
            <img src="/assets/icon/channels/site.png" class="image-channels">
            <div class="text-channel"> Sites </div>
        </div>

        <div class="channel">
            <img src="/assets/icon/channels/google.png" class="image-channels">
            <div class="text-channel"> Google </div>
        </div>

        <div class="channel">
            <img src="/assets/icon/channels/facebook.png" class="image-channels">
            <div class="text-channel"> Facebook </div>
        </div> -->

        <div class="div-empty"></div>
        <div class="div-empty"></div>
        <div class="div-empty"></div>
        <div class="div-empty"></div>
    </div>

    <mat-tab-group *ngIf="selected_tab != null" [(selectedIndex)]="selected_tab">
        <mat-tab label="QRCode">
            <app-qrcodes></app-qrcodes>
        </mat-tab>
        <!--<mat-tab label="Aplicativo"> <app-app-channel></app-app-channel> </mat-tab>-->
        <mat-tab label="Incorporar">
            <app-embed></app-embed>
        </mat-tab>
        <mat-tab label="E-mail">
            <app-emails></app-emails>
        </mat-tab>
        <mat-tab label="Whatsapp">
            <app-whatsapp></app-whatsapp>
        </mat-tab>
        <mat-tab label="SMS">
            <app-sms></app-sms>
        </mat-tab>
        <mat-tab label="Site">
            <app-site></app-site>
        </mat-tab>
    </mat-tab-group>
</div>

<div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
    <span class="spinner-border spinner-border-sm m_loading" role="status" aria-hidden="true"></span>
</div>