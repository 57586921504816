import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/service/storage.service';
import { SurveyService } from 'src/app/service/survey.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-modal-survey-preview',
    templateUrl: './modal-survey-preview.component.html',
    styleUrls: ['./modal-survey-preview.component.css']
})
export class ModalSurveyPreviewComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
		private surveyService: SurveyService,
        private storageService: StorageService
    ) { }

    @Input() isList: any;
    @Input() listImage: any;
    @Input() isEdit: any;

    loading: boolean = true;

    // isList;
    // isEdit;
    // listImage;
	url = AppConfig.path;

	selection = new SelectionModel<any>(false, []);

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        if(this.isList != null){ this.getSurveyId() }
        else this.loading = false
    }

    getSurveyId(){
		this.loading = true;

        this.surveyService.getSurveyId(this.isList.id).subscribe(data=>{
            this.listImage = data.questions;
    		this.loading = false;
        })
    }
}
