import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})

export class TabloideService {

    constructor(private http: HttpClient) { }

    establishment = "id_establishment"
  
    getTabloide(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`page`, page)
                .set(`establishment`, localStorage.getItem('id_establishment'))
        };

        return this.http.get<any>(AppConfig.path + `core/tabloide/`, options);
    }

    getTabloideID(id: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/tabloide/${id}/`, { headers });
    }

    postTabloide(tabloide): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, tabloide.name)
            .set(`footer_text`, tabloide.footer_text)
            .set(`banner_image`, encodeURI(tabloide.banner_image))
            .set(`header_image`, encodeURI(tabloide.header_image))
            .set(`establishment`, localStorage.getItem('id_establishment'))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/tabloide/`, body, { headers })
    }

    patchTabloide(tabloide): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, tabloide.name)
            .set(`footer_text`, tabloide.footer_text)
            .set(`banner_image`, encodeURI(tabloide.banner_image))
            .set(`header_image`, encodeURI(tabloide.header_image))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/tabloide/${tabloide.id}/`, body, { headers })
    }

    deleteTabloide(tabloide_id: any): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/tabloide/${tabloide_id}/`, { headers })
    }

    // TABLOIDE CATEGORY 

    getListCategoryTabloide(): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()

        };

        return this.http.get<any>(AppConfig.path + `core/category-product-tabloide/`, options);
    }

    postCategoryTabloide(category): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, category.name)
            .set(`image`, encodeURI(category.image))
            .set(`tabloide`, category.tabloide)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/category-product-tabloide/`, body, { headers })
    }


    patchCategoryTabloide(tabloide): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, tabloide.name)
            .set(`image`, encodeURI(tabloide.image))
            .set(`tabloide`, tabloide.tabloide)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/category-product-tabloide/${tabloide.id}/`, body, { headers })
    }


    deleteCategoryTabloideId(category_id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/category-product-tabloide/${category_id}/`, { headers })
    }



    // TABLOIDE PRODUCT 

    getListProductsTabloide(): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.get<any>(AppConfig.path + `core/product-tabloide/`, { headers });
    }

    postProductTabloide(tabloide): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, tabloide.name)
            .set(`text_description`, tabloide.text_description)
            .set(`image`, encodeURI(tabloide.image))
            .set(`price`, tabloide.price)
            .set(`category`, tabloide.category)

        if(tabloide.promotion_price != null){
            body = body.set(`promotion_price`, tabloide.promotion_price)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/product-tabloide/`, body, { headers })
    }

    patchProductTabloide(tabloide): Observable<any> {
        let body = new HttpParams({ encoder: new CustomEncoder() })
            .set(`name`, tabloide.name)
            .set(`text_description`, tabloide.text_description)
            .set(`image`, encodeURI(tabloide.image))
            .set(`price`, tabloide.price)
            .set(`category`, tabloide.category)

        if(tabloide.promotion_price != null){
            body = body.set(`promotion_price`, tabloide.promotion_price)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/product-tabloide/${tabloide.id}/`, body, { headers })
    }

    likeProductTabloide(product_tabloide): Observable<any> {
        let body = new HttpParams()
            .set(`product_tabloide`, product_tabloide)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/like-product-tabloide/`, body, { headers })
    }

    deleteProductTabloideId(product_id: any): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/product-tabloide/${product_id}/`, { headers })
    }

    getOrder(page, status): Observable<any> {
        let options = {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`),
          params: new HttpParams()
            .set(`tabloide__establishment`, localStorage.getItem(this.establishment))
            .set(`page`, page)
            .set(`only_tabloide`, 'true')
        };
        
        if (status != null && status != ''  && status != 'ALL') {
            options.params = options.params.set(`status`, status)
          }
    
        return this.http.get<any>(AppConfig.path + `core/order/`, options);
    }

    postOrder(id_tabloide, address, list_products, token, establishment_id): Observable<any> {
        let body: any = JSON.stringify({
            tabloide: id_tabloide,
            products_tabloide: list_products,
            address: address.name,
            number: address.number,
            cep: address.cep,
            district: address.district,
            establishment: establishment_id
        })
    
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            // .set('User', `token ${localStorage.getItem('token')}`)
            .set('User', `${token}`)
    
        return this.http.post<any>(AppConfig.path + `core/order/`, body, { headers })
    }

}
