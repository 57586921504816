<div class="primary-div" *ngIf="
    !loading && (
    isList.image != null ||
    (isList.title != null && isList.title != '') ||
    (isList.description != null && isList.description != '') ||
    isList.name ||
    isList.email ||
    isList.birthdate ||
    isList.cpf ||
    isList.gender ||
    isList.cep ||
    isList.phone)
">
    <div style="width: 100%; margin-top: 15px; display: flex; justify-content: center; flex-direction: column; align-items: center;">
        <img *ngIf="isList.image!=null && !isEdit" [src]="isList.image" class="img-config">
        <img *ngIf="isList.image!=null && isEdit" [src]="isList.image" class="img-config">
        <h2 *ngIf="isList.title!=null"> {{ isList.title }} </h2>
        <div *ngIf="isList.description!=null"> {{ isList.description }} </div>
    </div>

    <div *ngFor="let item of list_order" style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <input mat-input [(ngModel)]="item.input" [mask]="item.mask"
            *ngIf="item.active && (item.title == 'Telefone' || item.title == 'E-mail' || item.title == 'Nome' || item.title == 'CPF' || item.title == 'CEP')"
            placeholder="{{ item.active_mandatory? '*' : '' }}{{ item.placeholder }}"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}" class="input">

        <mat-select [(ngModel)]="item.input" class="input" 
            *ngIf="item.active && item.title == 'Orientação Sexual'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}"
            placeholder="{{ item.active_mandatory? '*' : '' }}Selecione a Orientação Sexual">
            <mat-option *ngFor="let item of list_select.orientation; let i = index" [value]="item.title">
                {{item.title}}
            </mat-option>
        </mat-select>
        
        <mat-select [(ngModel)]="item.input" class="input" 
            *ngIf="item.active && item.title == 'Identidade de Gênero'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}"
            placeholder="{{ item.active_mandatory? '*' : '' }}Selecione a Identidade de Gênero">
            <mat-option> Selecione a Identidade de Gênero </mat-option>
            <mat-option *ngFor="let item of list_select.identity; let i = index" [value]="item.title">
                {{item.title}}
            </mat-option>
        </mat-select>

        <mat-form-field color="accent" style="width: 100%; padding: 0 20px;" class="input" 
            *ngIf="item.active && item.title == 'Data de nascimento'"
            [ngStyle]="{'border-color': warningMap.get(item.name_api)? 'red' : ''}">

            <input matInput [matDatepicker]="picker1" placeholder="{{ item.active_mandatory? '*' : '' }}{{ item.placeholder }}" 
                [(ngModel)]="item.input" readonly>

            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="terms" *ngIf="isList?.terms != null" 
        [ngStyle]="{'border-color': warningMap.get('check')? 'red' : ''}">

        <mat-slide-toggle [(ngModel)]="input.check">
            Concordar com os 
            <a (click)="openPdf(isList?.terms)" [style.color]="color_primary"> 
                Termos de Uso
            </a> 
        </mat-slide-toggle>
    </div>

    <mat-checkbox class="example-margin"
        [(ngModel)]="input.checkInfo">Confirmo que todas as informações <br> são verdadeiras.</mat-checkbox>

    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <button mat-button class="button-cor" [style.background]="color_primary" (click)="check()" > 
            Cadastrar
        </button>
    </div>
</div>
