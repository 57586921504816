import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class GroupService {

    constructor(private http : HttpClient) { }

    establishment = "id_establishment"

    getGroups(page, id_establishment): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/group/`, options);
    }

    getGroupId(id): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })

        return this.http.get<any>(AppConfig.path + `core/group/${id}/`, {headers});
    }

    postGroup(name, active, id_establishment): Observable<any>{
        let body = new HttpParams()
            .set(`name`, name)
            .set(`active`, active)
            .set(`establishment`, localStorage.getItem(this.establishment))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/group/`, body, { headers })
    }

    patchGroup(id, name, active, id_establishment): Observable<any>{
        let body = new HttpParams()
            .set(`name`, name)
            .set(`active`, active)
            .set(`establishment`, localStorage.getItem(this.establishment))

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/group/${id}/`, body, { headers })
    }

    deleteGroup(id): Observable<any>{
    
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/group/${id}/`, { headers })
    }


    // UserGroup
    // getUserGroup(id): Observable<any> {
    //     const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //         .set('Authorization', `token ${localStorage.getItem('token')}`)

    //     return this.http.get<any>(AppConfig.path + `core/group/${id}`, {headers});
    // }

    getUserGroup(id_group): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`group`, id_group)
        };

        return this.http.get<any>(AppConfig.path + `core/group-usercampaign/groups-users-campaign/`, options);
    }

    postUserGroup(id_user, id_group): Observable<any>{
        let body = new HttpParams()
            .set(`group`, id_group)
            .set(`user_campaign`, id_user)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/group-usercampaign/`, body, { headers })
    }

    deleteUserGroup(id_user): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/group-usercampaign/${id_user}/`, { headers })
    }


    // postGroupCampaign(campaign, group): Observable<any>{
    //     let body = new HttpParams({encoder: new CustomEncoder()})
    //         .set(`campaign`, campaign)
    //         .set(`group`, group)

    //     const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //         .set('Authorization', `token ${localStorage.getItem('token')}`)

    //     return this.http.post<any>(AppConfig.path + `core/group-campaign/`, body, { headers })
    // }

    // patchGroupCampaign(id, campaign, group): Observable<any>{
    //     let body = new HttpParams({encoder: new CustomEncoder()})
    //         .set(`campaign`, campaign)
    //         .set(`group`, group)
        
    //     const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //         .set('Authorization', `token ${localStorage.getItem('token')}`)

    //     return this.http.patch<any>(AppConfig.path + `core/group-campaign/${id}/`, body, { headers })
    // }

    // deleteGroupCampaign(id): Observable<any>{

    //     const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //         .set('Authorization', `token ${localStorage.getItem('token')}`)

    //     return this.http.delete<any>(AppConfig.path + `core/group-campaign/${id}/`, { headers })
    // }
}