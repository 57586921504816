import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { CouponService } from 'src/app/service/coupon.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-cupom',
    templateUrl: './cupom.component.html',
    styleUrls: ['./cupom.component.css']
})
export class CupomComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
	@Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    constructor(
        private campaignService: CampaignService,
        private couponService: CouponService,
        private storageService: StorageService,
        private router: Router
    ) {
        this.url_screen = router.url;
    }

    url_screen;
    listImage;
    loading: boolean = true;
    url = AppConfig.path;
	qrcode_id;

    name;
    email;
    password;

    textButton = "Resgatar";

    cupom;
    page: number = 0;
    type_page: string = '';

    async ngOnInit() {
        // console.log("-> Cupom <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;
        
        this.storageService.screen.format = 'Cupom';
        this.storageService.screen.id = this.list.id;

        this.listImage = this.tratamento(this.list)

        if (this.url_screen != '/application/dashboard'){
            await this.storageService.saveHistoric(this.campaign_id, this.list.id, this.list.homepage_format, 2, this.type_page, this.qrcode_id)
        }

        if(this.url_screen != '/application/dashboard')
        
        if(this.listImage[0]?.coupon_obj != null){
            this.cupom = this.listImage[0].coupon_obj;
            this.loading = false
        }
        else{
            let coupon = this.list.coupon == null ? this.listImage[0].coupon : this.list.coupon
            this.getCouponId(coupon)
        }
    }

    getCouponId(id){
        if(id != -1){
            this.couponService.getCouponId(id).subscribe(data=>{
                this.cupom = data;
                this.loading = false
            })
        }
    }

    addItem(newItem){
        // console.log(newItem)
        this.page = 0
    }

    async rescue(){
        let user = JSON.parse(localStorage.getItem("_user"));
        if((this.listImage[0].registration_config_obj != null || this.listImage[0].coupon_obj.registration_config_obj != null) && user == null){
            this.page = 1;
        }
        else{
            this.textButton = 'Resgatado!';
            if(this.url_screen != '/application/dashboard')
                await this.storageService.saveHistoric(this.campaign_id, this.list.id, this.list.homepage_format, 3, this.type_page, this.qrcode_id)
        }
    }

    tratamento(list){
        if(this.preview_campaign){
            if(this.list?.images != undefined){
                return list.images;
            }
            else
                return null
        }
        else{
            if(list?.home_images != undefined){
                this.type_page = 'home';
                return list.home_images;
            }
            else if(list?.internal_images != undefined){
                // console.log('3',list.internal_images)
				this.type_page = 'internal';
                return list.internal_images;
            }
            else if(list?.page_images != undefined){
                // console.log('2',list.page_images)
				this.type_page = 'page';
                return list.page_images;
            }
            else if(this.list?.home_images != undefined){
                // console.log('1',list.home_images)
				this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }
       
    }
}
