import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { Tabloide } from 'src/app/model/tabloide';
// import { Question, Survey, Answer } from 'src/app/model/tabloide';
import { StorageService } from 'src/app/service/storage.service';
import { TabloideService } from 'src/app/service/tabloide.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../service/compressor.service';

@Component({
    selector: 'app-detail-tabloide',
    templateUrl: './detail-tabloide.component.html',
    styleUrls: ['./detail-tabloide.component.css']
})
export class DetailTabloideComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: MDBModalService,
        private storageService: StorageService,
        private tabloideService: TabloideService,
        private compressor: CompressorService,
    ) {
        this.tabloide_id = this.route.snapshot.paramMap.get('id');
    }

    modalRef: MDBModalRef;
    loading: boolean = false;
    loading_btn: boolean = false;

    tabloide_id;

    warningMap = new Map();
    indexExpanded: number = -1;
    

    remove = {
        category: [],
        product: []
    }

    tabloide: Tabloide = {} as Tabloide;
    compressed_image = []; // as the name says...


    list_question = [{ name: 'Escolha única', type: 'SINGLE' }, { name: 'Múltipla escolha', type: 'MULTIPLE' }]
    selectedQuestion
    viewSection: boolean = false;

    color_primary

    limit = {
        name: 50,
        footer: 100,
        name_c: 25,
        name_p: 30,
        description_p: 50,
    }

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.selectedQuestion = this.list_question[0]

        if (this.tabloide_id != null) this.getTabloideID()
        else this.addQuestion();
    }

    getTabloideID() {
        this.tabloideService.getTabloideID(this.tabloide_id).subscribe(data => {
            // console.log(data);

            this.tabloide = data;
            this.viewSection = true
        })
    }

    togglePanels(index: number) {
        this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }

    addQuestion() {
        if (this.tabloide.category == null) {
            this.tabloide.category = []
        }

        this.tabloide.category.push({
            id: null,
            name: '',
            image: '',
            tabloide: null,
            product: [{
                id: null,
                name: '',
                text_description: '',
                price: null,
                promotion_price: null,
                image: '',
                category: null,
                is_liked: false,
                likes: null
            }]
        })
        this.viewSection = true;
    }

    addAnswer(position) {
        this.tabloide.category[position].product.push({ id: null, name: '', text_description: '', price: null, promotion_price: null, image: '', category: null, is_liked: false, likes: null })
    }


    async onFileChanged(event, idx_cat, idx_prod, type) {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type == 'image/gif') {
                var file = event.target.files[0];
                //var filename = file.name;
                var target = event.target.files

                var reader = new FileReader()
                reader.readAsDataURL(target[0])
                reader.onloadend = async (e) => {
                    var final_file: any = reader.result

                    if (type == 'icon') {
                        this.tabloide.category[idx_cat].image = final_file
                        this.compressed_image = []
                    }
                    else if (type == 'image') {
                        this.tabloide.category[idx_cat].product[idx_prod].image = final_file
                        this.compressed_image = []
                    }
                    else if (type == 'banner') {
                        this.tabloide.banner_image = final_file
                        this.compressed_image = []
                    }
                    else if (type == 'header') {
                        this.tabloide.header_image = final_file
                        this.compressed_image = []
                    }
                }
            }
            else{
                var target = event.target.files
                const compress = this.recursiveCompress(target[0], 0, target).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(target[res.index], res.index, target);
                    }),
                );
                compress.subscribe(res => {
                    if (res.index > res.array.length - 1) {
                        if (type == 'icon') {
                            this.tabloide.category[idx_cat].image = this.compressed_image[0]
                            this.compressed_image = []
                        }
                        else if (type == 'image') {
                            this.tabloide.category[idx_cat].product[idx_prod].image = this.compressed_image[0]
                            this.compressed_image = []
                        }
                        else if (type == 'banner') {
                            this.tabloide.banner_image = this.compressed_image[0]
                            this.compressed_image = []
                        }
                        else if (type == 'header') {
                            this.tabloide.header_image = this.compressed_image[0]
                            this.compressed_image = []
                        }
    
                    }
                });
            }
        }
    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {
                this.compressed_image.push(response)
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );
    }


    save() {
        this.warningMap = new Map();

        if (this.tabloide.name == "" || this.tabloide.name == null) {
            this.warningMap.set('name', true);
        }

        if (this.tabloide.category != null && this.tabloide.category.length > 0) {
            this.tabloide.category.forEach((cat, x) => {
                if (cat.name == "" || cat.name == null) {
                    this.warningMap.set('name' + x, true);
                }
                if (cat.image == "" || cat.image == null) {
                    this.warningMap.set('image' + x, true);
                }

                if (cat.product != null && cat.product.length > 0) {
                    cat.product.forEach((prod, y) => {
                        if (prod.name == "" || prod.name == null) {
                            if (this.indexExpanded != x) this.togglePanels(x);
                            this.warningMap.set('name' + x + y, true);
                        }
                        if (prod.text_description == "" || prod.text_description == null) {
                            if (this.indexExpanded != x) this.togglePanels(x);
                            this.warningMap.set('text_description' + x + y, true);
                        }
                        if (prod.price == "" || prod.price == '0' || prod.price == null) {
                            if (this.indexExpanded != x) this.togglePanels(x);
                            this.warningMap.set('price' + x + y, true);
                        }
                        if (prod.image == "" || prod.image == null) {
                            if (this.indexExpanded != x) this.togglePanels(x);
                            this.warningMap.set('image' + x + y, true);
                        }
                    })
                }
            })
        }

        console.log(this.warningMap.size);


        if (this.warningMap.size == 0) {
            this.loading = true;

            if (this.tabloide_id == null)
                this.postTabloide()
            else {
                this.patchTabloide()
            }

            if (this.remove.product != null && this.remove.product.length > 0) {
                this.remove.product.forEach(item => {
                    this.deleteProduct(item.id)
                });
            }

            if (this.remove.category != null && this.remove.category.length > 0) {
                this.remove.category.forEach(item => {
                    this.deleteCategory(item.id)
                });
            }

            setTimeout(() => {
                this.router.navigate(['/application/tabloide'])
            }, 1000);
        }


    }

    postTabloide() {
        this.loading_btn = true;
        this.tabloideService.postTabloide(this.tabloide).subscribe(data => {

            this.tabloide.category.forEach(el => {
                el.tabloide = data.id;
                this.postCategoryTabloide(el)
            });
            // this.router.navigate(['/application/tabloide'])
        },
            error => {
                this.loading = false;
                this.loading_btn = false;
            })
    }

    postCategoryTabloide(category) {
        this.tabloideService.postCategoryTabloide(category).subscribe(data => {

            category.product.forEach(el => {
                el.category = data.id;
                this.postProductTabloide(el)
            });
        },
            error => {
                this.loading = false;
                this.loading_btn = false;
            })
    }

    postProductTabloide(product) {
        this.tabloideService.postProductTabloide(product).subscribe(data => {
        },
            error => {
                this.loading = false;
                this.loading_btn = false;
            })
    }

    patchTabloide() {
        this.tabloideService.patchTabloide(this.tabloide).subscribe(data => {

            this.tabloide.category.forEach(cat => {
                cat.tabloide = data.id;

                if (cat.id == null)
                    this.postCategoryTabloide(cat)
                else
                    this.patchCategoryTabloide(cat)
            });

            setTimeout(() => {
                this.router.navigate(['/application/tabloide'])
            }, 1000);
        },
            error => {
                this.loading = false;
            })
    }

    patchCategoryTabloide(category) {
        this.tabloideService.patchCategoryTabloide(category).subscribe(data => {

            category.product.forEach(prod => {
                prod.category = data.id;

                if (prod.id == null)
                    this.postProductTabloide(prod)
                else
                    this.patchProductTabloide(prod)
            });

        },
            error => {
                this.loading = false;
            })
    }

    patchProductTabloide(prod) {
        this.tabloideService.patchProductTabloide(prod).subscribe(data => {

        },
            error => {
                this.loading = false;
            })
    }

    addListDelete(item, type, position_questions, position_answer) {
        if (type == 'product') {
            if (item.id != null)
                this.remove.product.push(item)

            this.tabloide.category[position_questions].product.splice(position_answer, 1)
        }
        else if (type == 'category') {
            if (item.id != null)
                this.remove.category.push(item)

            this.tabloide.category.splice(position_questions, 1)
        }
        console.log(this.remove);
    }

    deleteCategory(id) {
        this.tabloideService.deleteCategoryTabloideId(id).subscribe(data => {
            console.log(data);
        }, error => {
            this.loading = false;
        })
    }

    deleteProduct(id) {
        this.tabloideService.deleteProductTabloideId(id).subscribe(data => {
            console.log(data);
        }, error => {
            this.loading = false;
        })
    }


    openView() {
        // this.modalRef = this.modalService.show(CellPhoneComponent, {
        //     backdrop: 'static',
        //     keyboard: false,
        //     focus: true,
        //     show: true,
        //     ignoreBackdropClick: false,
        //     class: 'modal-dialog-centered popup-transparent',
        //     containerClass: '',
        //     animated: false,
        //     data: {
        //         listImage: this.tabloide.category,
        //         isEdit: true,
        //         isPopup: 'tabloide'
        //     }
        // })
    }
}
