import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as ApexCharts from 'apexcharts';
import { ChartComponent } from 'ng-apexcharts';
import { CampaignService } from '../service/campaign.service';
import { StorageService } from '../service/storage.service';
import { UserCampaignService } from '../service/user-campaign.service';

@Component({
    selector: 'app-dashboard-midia',
    templateUrl: './dashboard-midia.component.html',
    styleUrls: ['./dashboard-midia.component.css']
})

export class DashboardMidiaComponent implements OnInit {

    constructor(
       private campaignService: CampaignService,
       private storageService: StorageService,
       private userCampaignService: UserCampaignService
    ) { }

    @ViewChild("chart-3") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
        
    id_establishment
    loading: boolean = true;
    list = []

    preview: boolean = false;

    campaign_select;
    obj_campaign;

    date = {
        start_date: null,
        end_date: null,
        send_start_date: null,
        send_end_date: null
    }
    
    list_campaign = []
    list_screen = []
    list_logs = []
    description;
    user_access;

    list_logs_all = []
    list_logs2 = []
    description2;
    user_access2;
    list_acess = [];
    
    list_color=[]
    list_navegation = []
    list_devices = []
    list_register = [];
    list_week = []
    number_access: number = 0;

    // graphics 
    graphic_navegation;
    graphic_devices;
    graphic_date;

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.date.start_date = this.startDayWeek(new Date())
        this.date.end_date = this.endDayWeek(new Date())
        this.date.send_start_date = this.convertDate(this.startDayWeek(new Date()))
        this.date.send_end_date = this.convertDate(this.endDayWeek(new Date()))

        this.list_color = this.storageService.setListColor(this.color_primary)        
        this.getCampaigns(1)
        // this.getLogs(1)
    }  
       
    ngOnDestroy() {
        // console.log('exit')
        this.loading = false;
        if(this.list_devices != null && this.list_devices.length > 0){
		    this.graphic_navegation.destroy()
        }
	}

    getCampaigns(page){
        this.loading = true;
        this.campaignService.getCampaigns(page).subscribe(data=>{

            this.list_campaign = this.list_campaign.concat(data.results);

            if (data.next != null) {
                this.getCampaigns(page + 1)
            }
            else{
                if(this.list_campaign != null && this.list_campaign.length > 0){
                    this.campaign_select = this.list_campaign[0]?.id;
                    this.obj_campaign = this.list_campaign[0]

                    // this.setScreen(this.list_campaign[0])
                    // this.getLogCampaign(this.campaign_select, 1)
                    this.selectCampaign(this.list_campaign[0])
                }         
            }

        },
        error=>{
            this.loading = false;
        })
    }

    setScreen(list){
        this.list_screen = []

        if(list.home_images != null && list.home_images.length > 0){
            list.home_images.forEach(home => {
                this.list_screen = this.list_screen .concat(home)

                if(home.page_images != null && home.page_images.length > 0){
                    home.page_images.forEach(page => {
                        this.list_screen = this.list_screen .concat(page)

                        if(page.internal_images != null && page.internal_images.length > 0){
                            page.internal_images.forEach(internal => {
                                this.list_screen = this.list_screen .concat(internal)
                            });
                        }
                    });
                }
            });
        }

    }
    
    async selectCampaign(campaign){
        if(campaign.id != null){
            this.obj_campaign = campaign
        }

        this.number_access = 0
        this.campaign_select = campaign.id != null ? campaign.id : campaign;

        await this.filterLogCampaign(this.campaign_select)
        await this.setScreen(campaign)
        await this.clearVariables()
        await this.getLogCampaign(this.campaign_select, 1)
        // this.loading = false;        

        this.campaignService.changeList()
    }
    
    // getLogs(page){
    //     this.userCampaignService.getLogs(page).subscribe(data=>{

    //         this.list_logs_all = this.list_logs_all.concat(data.results);

    //         if(data.next != null){
    //             this.getLogs(page+1)
    //         }
    //         else{
    //             this.filterLogCampaign(this.campaign_select)
    //         }
    //     })
    // }

    filterLogCampaign(id_campaign){
        this.list_logs2 = this.list_logs_all.filter(item => item.campaign == id_campaign)

        this.list_acess = []
        this.list_screen.forEach(screen => {
            this.list_acess.push({id_sreen: screen.id, acess: 0, user: null});
        });

    }

    async createGraphic(){
        await this.graphicNavegation()
        await this.graphicDevices()
        await this.graphicDate()
        // await this.graphicScreeen()
    }

    clearVariables(){
        this.list_navegation = []
        this.list_devices = []
        this.list_logs = []
        this.number_access = 0
        this.list_week = []

        var today = new Date()
        var day
        
        for (let index = 0; index != 7; index++) {

            if(index == 0) day = today.setDate(today.getDate());
            else day = today.setDate(today.getDate() - 1);
            
            this.list_week.push({date: new Date(day).getDate()+'/'+(new Date(day).getMonth() + 1), registered: 0, not_registered: 0})
        }
    }

    getLogCampaign(id, page){
        this.loading = true;

        this.campaignService.getLogCampaign(id, page, this.date.send_start_date+'T00:00:00', this.date.send_end_date+'T23:59:59', 100).subscribe(data=>{
            this.list_logs = this.list_logs.concat(data.results);
            if(data.next != null){
                this.getLogCampaign(id, page + 1)
            }
            else if(data.count == 0){
                this.loading = false;
            }
            else{
                this.list_logs.forEach(item => {
                    this.tratamento(item)
                });
                this.loading = false;
                setTimeout(() => {
                    this.createGraphic()
                }, 100);
            }

        },
        error=>{
            this.loading = false;
        })

    }
    
    addEvent(event: MatDatepickerInputEvent<Date>, item) {
        if(event.value != null){
            this.date.start_date = item.start_date
            this.date.end_date = item.end_date
            this.date.send_start_date = this.convertDate(item.start_date)
            this.date.send_end_date = this.convertDate(item.end_date)

            this.selectCampaign(this.campaign_select)
        }
    }

    convertDate(data){
        if(data != null){
            var d = new Date(data),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            var aux_data = [year, month, day].join('-')
    
            return aux_data;
        }
        else{
            return null
        }
        
    }

    tratamento(item){
        this.description = JSON.parse(item.description) 
        this.user_access = item.user_campaign;

        if(this.description.navegation != null){

            // visualizações na campanha
            this.number_access += 1;


            //navegador
            var p_navegation = this.list_navegation.findIndex( item => item.name === this.description.navegation.browser );
            if(p_navegation < 0) this.list_navegation.push({name: this.description.navegation.browser, access: 1})
            else this.list_navegation[p_navegation].access += 1;


            //ambiente
            var p_ambiente = this.list_devices.findIndex( item => item.name === this.description.navegation.deviceType );
            if(p_ambiente < 0) this.list_devices.push({name: this.description.navegation.deviceType, access: 1})
            else this.list_devices[p_ambiente].access += 1;


            //user register
            if(this.user_access != null){
                var p_register = this.list_register.findIndex( item => item.user === this.user_access.name );
                if(p_register < 0) this.list_register.push({user: this.user_access.name, access: 1})
                else this.list_register[p_register].access += 1;
            }
           

            // date access        
            var day = new Date(item.created_at).getDate()
            var month = new Date(item.created_at).getMonth()
            var date = day+'/'+(month+1)

            if(this.user_access != null){
                var p_date_access = this.list_week.findIndex( item => item.date === date );
                if(p_date_access >= 0)
                    this.list_week[p_date_access].registered += 1;
                
            }
            else{
                var p_date_access = this.list_week.findIndex( item => item.date === date );
                if(p_date_access >=0 )
                    this.list_week[p_date_access].not_registered  += 1;
            
            }

        }
        
    }

    graphicNavegation(){
        var list_series = []
        var list_labels = []
        var list_color = []

        if(this.list_navegation.length != 0){
            this.list_navegation.forEach((item, index) => {
                list_series.push(item.access)
                list_labels.push(item.name)
                list_color.push(this.list_color[index])
            });
            
            var options = {
                series: list_series,
                colors: list_color,
                chart: {
                    id: 'navegation',
                    height: 200,
                    type: "pie"
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return  ""
                    },
                },
                labels: list_labels,
                responsive: []
            };
            
            this.graphic_navegation = new ApexCharts(document.querySelector("#chart-navegation"), options);
            this.graphic_navegation.render();
        }
        else{
            // this.graphic_navegation.destroy()
        }
        
    }

    graphicDevices(){
        var list_series = []
        var list_labels = []
        var list_color = []

        if(this.list_devices.length > 0){
            this.list_devices.forEach((item, index) => {
                list_series.push(item.access)
                list_labels.push(item.name)
                list_color.push(this.list_color[index])
            });
    
            var  options2 = {
                series: list_series,
                colors: list_color,
                chart: {
                    height: 200,
                    type: "pie"
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return  ""
                    },
                },
                labels: list_labels,
                responsive: []
                
            }
    
            this.graphic_devices = new ApexCharts(document.querySelector("#chart-devices"), options2);
            this.graphic_devices.render();
        }
        else{
            // this.graphic_devices.destroy()
        }
       
    }

    graphicDate(){
        var list_registered = []
        var list_not_registered = []
        var list_day = []
        var list_color = []

        if(this.number_access > 0){

            if(this.list_week != null && this.list_week.length > 0){
                this.list_week.forEach((day, index) => {
                    list_registered.push(day.registered)
                    list_not_registered.push(day.not_registered)
                    list_day.push(day.date)
                    list_color.push(this.list_color[index])
                });
            }
    
            var chartOptions = {
                series: [
                    {
                        name: "Cadastrados",
                        data: list_registered.reverse()
                    },
                    {
                        name: "Não Cadastrados",
                        data: list_not_registered.reverse()
                    }
                ],
                colors: list_color,
                chart: {
                    height: 250,
                    type: "line"
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 5,
                    curve: "straight",
                    dashArray: [0, 0, 0]
                },
                title: {
                    // text: "Page Statistics",
                    align: "left"
                },
                legend: {
                    tooltipHoverFormatter: function(val, opts) {
                        return (
                            val +
                            " - <strong>" +
                            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                            "</strong>"
                        );
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                    sizeOffset: 6
                    }
                },
                xaxis: {
                    labels: {
                        trim: false
                    },
                    categories: list_day.reverse()
                },
                tooltip: {
                    y: [
                    {
                        title: {
                            formatter: function(val) {
                                return val + " .";
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function(val) {
                                return val + " .";
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function(val) {
                                return val;
                            }
                        }
                    }
                    ]
                },
                grid: {
                    borderColor: "#f1f1f1"
                }
            };
    
            this.graphic_date = new ApexCharts(document.querySelector("#chart-line"), chartOptions);
            this.graphic_date.render();
        }
        else{
            // this.graphic_date.destroy()
        }
      
    }

    graphicScreeen(){
        var list_registered = []
        var list_not_registered = []
        var list_screen = []
        var list_color = []


        if(this.list_acess != null && this.list_acess.length > 0){
                this.list_acess.forEach((screen, index) => {
                    // if(screen.user != null && screen.user != ''){
                        // list_registered.push(screen.user)
                    // }
                    list_registered.push(screen.acess)
                    // list_not_registered.push(index+1)
                    list_screen.push(screen.id)
                    list_color.push(this.list_color[index])
                });
            
    
            var chartOptions = {
                series: [
                    {
                        name: "Acessos",
                        data: list_registered
                    }
                    // {
                    //     name: "Cadastrados",
                    //     data: list_registered
                    // },
                    // {
                    //     name: "Não Cadastrados",
                    //     data: list_not_registered
                    // }
                ],
                colors: list_color,
                chart: {
                    type: "bar",
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"]
                },
                xaxis: {
                    categories: list_screen
                },
                yaxis: {
                    title: {
                        text: "Acessos"
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val;
                        }
                    }
                }
            };

            this.graphic_date = new ApexCharts(document.querySelector("#chart-screen"), chartOptions);
            this.graphic_date.render();
        }
        else{
            // this.graphic_date.destroy()
        }
      
    }
    

    graphicColls(){
        // console.log(this.list_week)

        var options = {
            series: [
                {
                    name: "Cadastrados",
                    data: [14, 25]  
                },
                {
                    name: "Não Cadastrados",
                    data: [46, 55]
                }
            ],
            colors: ['#f77128', '#252f52'],
            chart: {
                type: "bar",
                height: 200
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "80%",
                    endingShape: "rounded"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
            },
            xaxis: {
                categories: [
                    "15:00",
                    "16:00",
                    "17:00",
                    "18:00",
                    "19:00",
                    "20:00",
                    "21:00"
                ]
            },
            yaxis: {
                title: {
                    text: "Campanha"
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function(val) {
                        return "" + val + "%";
                    }
                }
            }
        }
        
        var chart = new ApexCharts(document.querySelector("#chart-coll"), options);
        
        chart.render();
    }
 
    endDayWeek(date){
        let day, month, year;
        day = new Date(date).getDate()
        month = (new Date(date).getMonth() + 1)
        year = new Date(date).getFullYear()
        
        return new Date(year+'-'+month+'-'+day)
    }

    startDayWeek(date){
        let day, month, year;
        var d = new Date(date);
        d.setDate(d.getDate() - 7);
        day = new Date(d).getDate() 
        month = (new Date(d).getMonth() + 1)
        year = new Date(d).getFullYear()

        return new Date(year+'-'+month+'-'+day)
    }
}

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    tooltip: any; // ApexTooltip;
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
  };