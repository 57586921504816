import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CellPhoneComponent } from 'src/app/modals/cell-phone/cell-phone.component';
import { Question, Survey, Answer } from 'src/app/model/survey';
import { StorageService } from 'src/app/service/storage.service';
import { SurveyService } from 'src/app/service/survey.service';
import { ModalSurveyPreviewComponent } from '../modal-survey-preview/modal-survey-preview.component';
@Component({
    selector: 'app-create-survey-detail',
    templateUrl: './create-survey-detail.component.html',
    styleUrls: ['./create-survey-detail.component.css']
})
export class CreateSurveyDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private surveyService: SurveyService,
        private modalService: MDBModalService,
        private storageService: StorageService
    ) { 
        this.survey_id = this.route.snapshot.paramMap.get('id');
    }
    modalRef: MDBModalRef;
    
    loading: boolean = false;
    
    survey_id;
    warningMap = new Map();
    selection = new SelectionModel<any>(false, []);
    panelOpenState = false;

    id_establishment;

    survey: Survey = {
        id: null, 
        name: '', 
        description: '',  
        questions: [{
            id: null,
            description: '',
            survey_type: null,
            answers: [{
                id: null,
                title: ''
            }],
        }],
    };

    remove={
        answer: [],
        question: []
    }
    
    list_question=[{name:'Escolha única', type: 'SINGLE'}, {name:'Múltipla escolha', type: 'MULTIPLE'}]
    selectedQuestion
    viewSection: boolean = false;
    
    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.id_establishment = localStorage.getItem('id_establishment')

        this.selectedQuestion = this.list_question[0]

        if(this.survey_id != null) this.getSurvey()
    }

    getSurvey(){
        this.surveyService.getSurveyId(this.survey_id).subscribe( data => {
            this.viewSection = true
            this.panelOpenState = true
            this.survey = data;
        })
    }

    addQuestion(){
        if(this.viewSection){
            this.survey.questions.push({
                id: null,
                description: '', 
                survey_type: this.list_question[0], 
                answers: [{
                    title: '', 
                    id: null
                }]
            })
        }
        else{
            this.viewSection = true;
        }
    }

    addAnswer(position){
        this.survey.questions[position].answers.push({title: '', id: null})    
    }

    save(){
        this.warningMap = new Map();
        if(this.survey.name == ""){
            this.warningMap.set('name', true);
        }
        if(this.survey.description == ""){
            this.warningMap.set('description', true);
        }

        this.survey.questions.forEach( (el, x) => {
            if(el.description == ""){
                this.warningMap.set('description'+x, true);
            }
            if(el.survey_type == null){
                this.warningMap.set('survey_type'+x, true);
            }

            el.answers.forEach( (el, y) => {
                if(el.title == ""){
                    this.warningMap.set('title'+x+y, true);
                }
            })
        })

        if(this.warningMap.size == 0){
            this.loading = true;

            if(this.survey_id == null)
                this.postSurvey()
            else
                this.patchSurvey()


            if(this.remove.answer != null && this.remove.answer.length > 0){
                this.remove.answer.forEach(item => {
                    this.deleteAnswer(item.id)
                });
            }
    
            if(this.remove.question != null && this.remove.question.length > 0){
                this.remove.question.forEach(item => {
                    this.deleteQuestion(item.id)
                });
            }
    
            setTimeout(() => {
                this.router.navigate(['/application/survey'])
            }, 1000);
        }

       
    }

    postSurvey(){
        this.surveyService.postSurvey(this.survey.name, this.survey.description, this.id_establishment).subscribe(data=>{

            this.survey.questions.forEach(el => {
                this.postQuestion(data.id, el)
            });
            // this.router.navigate(['/application/survey'])
        },
        error=>{
            this.loading = false;
        })
    }

    postQuestion(id_survey, question: Question){
        this.surveyService.postQuestion(id_survey, question.description, question.survey_type).subscribe(data=>{

            question.answers.forEach(el => {
                this.postAnswer(data.id, el)
            });
        },
        error=>{
            this.loading = false;
        })
    }

    postAnswer(id_question, answer: Answer){
        this.surveyService.postAnswer(id_question, answer.title).subscribe(data=>{
        },
        error=>{
            this.loading = false;
        })
    }

    patchSurvey(){
        this.surveyService.patchSurvey(this.survey_id, this.survey.name, this.survey.description).subscribe(data=>{
            this.survey.questions.forEach(el => {
                if(el.id == null)
                    this.postQuestion(data.id, el)
                else
                    this.patchQuestion(el)
            });
            // setTimeout(() => {
            //     this.router.navigate(['/application/survey'])
            // }, 1000);
        },
        error=>{
            this.loading = false;
        })
    }

    patchQuestion(question: Question){
        this.surveyService.patchQuestion(question.id, question.description, question.survey_type).subscribe(data=>{
            question.answers.forEach(el => {
                if(el.id == null)
                    this.postAnswer(data.id, el)
                else
                    this.patchAnswer(el)
            });
        },
        error=>{
            this.loading = false;
        })
    }

    patchAnswer(answer: Answer){
        this.surveyService.patchAnswer(answer.id, answer.title).subscribe(data=>{
            
        },
        error=>{
            this.loading = false;
        })
    }

    addListDelete(item, type, position_questions, position_answer){
        if(type == 'answer'){
            if(item.id != null)
                this.remove.answer.push(item)
            
            this.survey.questions[position_questions].answers.splice(position_answer, 1)
        }
        else if(type == 'questions'){
            if(item.id != null)
                this.remove.question.push(item)
            
            this.survey.questions.splice(position_questions, 1)
        }

    }

    deleteQuestion(id){
        this.surveyService.deleteQuestion(id).subscribe(data=>{
        },
        error=>{
            this.loading = false;
        })
    }

    deleteAnswer(id){
        this.surveyService.deleteAnswer(id).subscribe(data=>{
        },
        error=>{
            this.loading = false;
        })
    }


    openView(){
        this.modalRef = this.modalService.show(CellPhoneComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data:{
                listImage: this.survey.questions,
                isEdit: true,
                isPopup: 'survey'
            }
        })   
    }
}
