import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../service/constants.service';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

    constructor(
        private userService: UserService,
        private router: Router,
        private constantsService: ConstantsService
    ) { }

    email;
    password;

    warningMap = new Map();
    msg_error: string = '';
    loading: boolean = false;
    textButton: string = 'Entrar'

    viewLogin: boolean = true;
    list_establishment;
    establishment;

    ngOnInit(): void {

    }

    login() {
        this.msg_error = "";
        this.loading = true;
        this.textButton = 'Entrando'

        this.warningMap = new Map();
        if (this.email == null || this.email == "") {
            this.warningMap.set('email', true);
            this.loading = false;
            this.textButton = 'Entrar'
        }
        if (this.password == null || this.password == "") {
            this.warningMap.set('password', true);
            this.loading = false;
            this.textButton = 'Entrar'
        }

        if (this.warningMap.size == 0) {
            this.userService.auth(this.email, this.password).subscribe(data => {
                localStorage.setItem('token', data.token)
                this.loading = false;
                this.textButton = 'Entrar'

                this.getEstablishment()
            },
                error => {
                    this.msg_error = "Usuário ou senha inválidos."
                    this.loading = false;
                    this.textButton = 'Entrar'
                })
        }

    }

    getEstablishment() {
        this.constantsService.getEstablishment().subscribe(data => {

            if(data.count <= 0){
                this.msg_error = "Nenhum estabelecimento encontrado"
                this.textButton = 'Entrar'
            }
            else{
                localStorage.setItem('id_establishment', data.results[0].id)
                this.router.navigate(['/application'])
            }

            /*if (data.count == 1) {
                this.viewLogin = false;
                this.list_establishment = data.results
            }*/
        })
    }

    nextPage() {
        localStorage.setItem('id_establishment', this.establishment.id)

        this.router.navigate(['/application'])
        this.loading = false;
        this.textButton = 'Entrar'
    }

}
