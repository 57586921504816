<div class="screen">

    <div style="width: 93%; display: flex; justify-content: space-between; align-items: center; margin: auto;">
        <h3> Arquivos - Lista atualizada à cada 5 minutos </h3>

        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <button mat-button (click)="importCSV()">Importar Arquivo</button>

            <!-- <input #fileUpload type="file" accept=".csv, .xlsx, .pdf" style="display: none;"
                (change)="onUpload($event.target.files)" /> -->

            <button mat-button style="display: flex; align-items: center; justify-content: space-evenly;">
                <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                <span> Atualizar </span>
            </button>
        </div>
    </div>

    <div class="div-table" *ngIf="!loading">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Nome </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{ element.title }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Tipo </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{ element.type }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="max-width: 180px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde div_row_icons mw_150 p_5">
                        <div [routerLink]="'/application/file/'+ element.id">
                            <img src="/assets/icon/icon_edit.png" matTooltip="Editar" [style.background]="color_primary"
                                class="icon">
                        </div>

                        <div (click)="openDelete(element)">
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" class="icon view-delet">
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhum resultado encontrado. </td>
            </tr>
        </table>

        <div class="table-paginator" *ngIf="!loading && dataSource.data.length != 0">
            <div style="display: flex;">
                <div class="paginator-button prev" (click)="backPage()">
                    <img src="/assets/icon/prev.png" width="12px" height="12px">
                </div>
                <div class="paginator-button next" (click)="nextPage()">
                    <img src="/assets/icon/next.png" width="12px" height="12px">
                </div>
            </div>

            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
        </div>
    </div>

</div>