<div class="screen">

    <div class="title" style="width: 100%; display: flex; align-items: center; gap: 20px;">
        <h3> Planilha </h3>
        <button mat-button class="button-cor" [style.background]="color_primary" id="button_pdf" (click)="isCreatePdf()">
            Gerar PDF <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true" style="margin-left: 10px;"></span>
        </button>
    </div>

    <div>
        <label> Título </label>
        <input mat-input [(ngModel)]="spreadsheet.title" placeholder="" class="input">
    </div>

    <div style="opacity: 0.5;">
        <label> Tipo </label>
        <input mat-input [(ngModel)]="spreadsheet.type" placeholder="" class="input" readonly>
    </div>

    <mat-tab-group mat-align-tabs="start" *ngIf="loaded">
        <mat-tab *ngFor="let key_parent of parent_keys" [label]="key_parent">
            <div *ngFor="let key of description_keys[key_parent]">
                <label> {{ formatKey(key) }} </label>
                <input mat-input [(ngModel)]="description_json[key_parent][key]" placeholder="" class="input">
            </div>
        </mat-tab>
    </mat-tab-group>

    <div style="margin: 20px 0;">
        <button mat-button class="button-cor" [style.background]="color_primary" (click)="save()">
            Salvar <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true" style="margin-left: 10px;"></span>
        </button>
    </div>

    <div class="title"> 
        <h3> Tabelas </h3> 
    </div>

    <div class="div-table">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde"> Título </div>
                </th>

                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{ element.title }} - {{ element.spreadsheet_subtitle }} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="view" style="max-width: 180px;">
                <th mat-header-cell *matHeaderCellDef style="max-width: 180px;">
                    <div class="title-borde" style="max-width: 180px;"> Ações </div>
                </th>

                <td mat-cell *matCellDef="let element; let i = index" style="max-width: 180px;">
                    <div class="div-borde div_row_icons mw_150 p_5">
                        <div routerLink="/application/file/{{spreadsheet.id}}/{{element.id}}">
                            <img src="/assets/icon/icon_edit.png" matTooltip="Editar" [style.background]="color_primary"
                                class="icon">
                        </div>

                        <div (click)="openDelete(element)">
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Excluir" class="icon view-delet">
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> 
                    Nenhum resultado encontrado. 
                </td>
            </tr>

        </table>
    </div>

</div>

<div #priceListPDF></div>