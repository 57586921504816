<div class="screen">

    <!-- page 1 -->
    <div class="content" *ngIf="screen == 1">

        <img src="../../../assets/image/logo_hashlar.png" class="icon_logo">

        <div class="sub-title"> A <b>Hashlar</b> é o primeiro gateway que integra pagamentos com criptoativos direto no setor imobiliário. </div>
        
        <div class="title"> Pagamento </div>

        <div class="div-input">
            <label>Email</label>
            <input mat-input [(ngModel)]="email" placeholder="Email" type="email"
                [ngStyle]="{'border-color': warningMap.get('email')? 'red' : ''}" class="input">
        </div>

        <div class="div-input">
            <label>Moeda</label>
            <mat-select [(ngModel)]="currency" class="input" placeholder="Moeda"
                [ngStyle]="{'border-color': warningMap.get('currency')? 'red' : ''}">
                <mat-option *ngFor="let item of currencies; let i = index" [value]="item" (click)="getCoinPrice(item)">
                    {{ item?.name != null ? item.name : '-' }}
                </mat-option>
            </mat-select>
        </div>

        <div class="div-input">
            <label>Valor em Reais (R$)</label>
            <input mat-input [(ngModel)]="value" placeholder="Valor" (keyup)="convertCurrencyValue()"
                [ngStyle]="{'border-color': warningMap.get('value')? 'red' : ''}" class="input" mask="separator.8"
                [thousandSeparator]="'.'" [decimalMarker]="','">
        </div>

        <div class="div-input">
            <label>Valor {{ currency?.name != null ? 'em '+currency?.name : 'da moeda' }}</label>
            <input mat-input [(ngModel)]="value_coin" placeholder="Valor"
                [ngStyle]="{'border-color': warningMap.get('value')? 'red' : ''}" class="input" mask="separator.8"
                [thousandSeparator]="'.'" [decimalMarker]="','" readonly>
            <span *ngIf="coin_price != null && coin_price != '' " style="text-align: right; font-size: 10px;"> valor
                atual {{ convertMoney(coin_price) }} </span>
        </div>

        <div style="max-width: 350px; margin: 0 auto; display: flex; justify-content: flex-end;">
            <button mat-button class="btn" [style.background]="'#4d9310'" [style.color]="'#fff'"
                (click)="pay()">
                Pagar <span *ngIf="loading_btn" class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </div>

    <!-- page 2 -->
    <div class="content" *ngIf="screen == 2">

        <img src="../../../assets/image/logo_hashlar.png" class="icon_logo">

        <div class="title"> Pagamento </div>

        <div *ngIf="currency" style="font-size: 18px; width: 100%; max-width: 350px; margin: 0 auto;">
            Valor: {{ value_coin }} <br>
            Moeda: {{ currency?.name }} <br>
            Rede: {{ order_network != null ? order_network : '-' }} <br>
            <span *ngIf="memo != null"> MEMO: {{ memo }} </span> <br><br>
        </div>

        <div class="div-input">
            <label>Endereço para o pagamento</label>
            <textarea mat-input [(ngModel)]="address" placeholder="Endereço" class="input" readonly lines="3"
                style="resize: none;"> </textarea>
        </div>

        <div style="text-align: center;">
            <button mat-button class="btn" [style.background]="header.back" [style.color]="header.color"
                [cdkCopyToClipboard]="address" (click)="copy()">
                Copiar endereço
            </button>
        </div>


        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <qrcode id="qrcode" [qrdata]="address" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'">
            </qrcode>
        </div>

        <div style="font-size: 18px; margin-top: 50px; width: 100%; max-width: 350px; margin: 0 auto;">
            Status: {{ payment_status ? 'APROVADO' : 'AGUARDANDO PAGAMENTO' }}
        </div>
        <div *ngIf="!payment_status" style="width: 100%; max-width: 350px; margin: 0 auto;">
            Status atualizado a cada 15 segundos.
        </div>

    </div>

    <div class="card_footer">
        <div class="powered"> Powered by: </div>

        <img src="../../../assets/image/logo_hashlar.png" class="logo_footer">
        <img src="../../../assets/image/waterMark.png" class="mask_footer">
    </div>

</div>

<!-- valor, moeda, pagar -->