<div class="modal-content">
    <div class="screen_popup">
        <img src="../assets/image/iphone_x.png" class="img-phone">

        <button mat-button class="icon-close b-border" [style.background]="color_primary" (click)="modalRef.hide()"> Fechar </button>
    
        <div class="custom-scrollbar component">
            <div class="div-header" [ngStyle]="{'background-color': header.back}">
                <div class="title" [ngStyle]="{'color': header.color}"> {{ header.title }} </div>
            </div>
            
            <!-- preview screen -->
            <app-modal-coupon-preview *ngIf="isPopup == 'coupon'"
                [isList]="isList"
                [isEdit]="isEdit">
            </app-modal-coupon-preview>
            
            <app-modal-config-preview *ngIf="isPopup == 'config'"
                [isList]="isList"
                [isEdit]="isEdit">
            </app-modal-config-preview>

            <app-modal-survey-preview *ngIf="isPopup == 'survey'"
                [listImage]="listImage"
                [isList]="isList"
                [isEdit]="isEdit">
            </app-modal-survey-preview>
        </div>
        
    </div>

</div>
