import { Component, Input, OnInit, Output, EventEmitter, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';
import Player from "@vimeo/player";
import YTPlayer from "yt-player";
import { CourseService } from 'src/app/service/course.service';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

    @Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
    @Input() type_campaign: any;
    @Input() campaign_id: any;
    @Output() newItemEvent = new EventEmitter();

    private player: Player;

    constructor(
        private campaignService: CampaignService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private storageService: StorageService,
    ) {
        var url = this.router.url;
        var aux = url.split('/')
        var tam = aux.length
        this.urlPage = aux[tam - 2]
    }

    urlPage;
    listImage;
    loading: boolean = true;
    url = AppConfig.path;

    url_link;
    iframeURL;
    send_log_video: boolean = true;

    type_video: string = '';
    code_video: string = '';
	type_page: string = '';

    // player.getPlayerState():Number

    ngOnInit(): void {
        // console.log("-> Vídeo <-")

        this.storageService.screen.format = 'Vídeo';
        this.storageService.screen.id = this.list.id;
        this.campaign_id = this.list.id;

        this.campaignService.watchList().subscribe(data => {
            this.listImage = this.tratamento(this.list)
            this.url_link = this.listImage != null ? this.listImage : null
        })

        this.listImage = this.tratamento(this.list)
        this.url_link = this.listImage != null ? this.listImage : null

        if (this.preview_campaign) {
            this.setLinkVideo(this.listImage.link)
        }
        else {
            if (this.url_link != null && this.url_link.length > 0) {
                this.addNewItem(this.url_link[0])
            }
            else {
                this.setLinkVideo(this.list.link)
            }
        }

    }

    configVimeo() {
        const options = {
            id: this.code_video,
            width: (document.body.clientWidth > 1000 ? 1000 : document.body.clientWidth) ,
            loop: false
        };

        this.player = new Player('video_vimeo', options);

        this.player.on('play', function () {
            // console.log('play the video!');

            // if (this.url_screen != '/application/dashboard'){
			// 	this.storageService.saveHistoric(this.campaign_id, this.obj_array.id, (this.obj_array.homepage_format || this.obj_array.page_format), 12, this.type_page, this.qrcode_id)
			// }
        });

        this.player.on('ended', function () {
            // console.log('ended the video!');
        });
    }

    configYoutube() {
        const options = {
            width: (document.body.clientWidth) ,
            height: (window.screen.height / 2),
            loop: false
        };

        const player = new YTPlayer('#video_youtube', options)

        player.load(this.code_video)

        player.on('playing', function () {
            // console.log('play the video!');
            // if (this.url_screen != '/application/dashboard'){
			// 	this.storageService.saveHistoric(this.campaign_id, this.obj_array.id, (this.obj_array.homepage_format || this.obj_array.page_format), 12, this.type_page, this.qrcode_id)
			// }
        });

        player.on('ended', function () {
            // console.log('ended the video!');
        });
    }
   
    addNewItem(list) {
        if (this.preview_campaign) {
            if (list?.type?.name == "Vídeo") {
                this.setLinkVideo(list.link)
            }
            else if (list?.link != null) {
                this.setLinkVideo(list.link)
            }
            // else
            // this.newItemEvent.emit(list);
        }
        else if (list != null) {
            if (list.link != null && list.link != "") {
                this.setLinkVideo(list.link)
            }
            else if (list.page_images != null && list.page_images != "") {
                this.setLinkVideo(list.page_images[0].link)
            }
            else if (list.internal_images != null) {
                this.setLinkVideo(list.internal_images[0].link)
            }

        }

    }

    setLinkVideo(link) {
        var link
        if (link != null) {
            var vimeoCheck = link.indexOf('vimeo')
            var youtubeCheck = link.indexOf('youtube')

            if (vimeoCheck == -1 && youtubeCheck != -1) {
                this.type_video = 'youtube';

                var param = link.split('watch?v=')
                if (param.length > 1) {
                    this.code_video = param[1];
                    link = 'https://www.youtube.com/embed/' + param[1] + '?enablejsapi=1';
                }
                else {
                    link = link
                }

                this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(link);
            }
            else if (vimeoCheck != -1 && youtubeCheck == -1) {
                this.type_video = 'vimeo';

                var param = link.split('https://vimeo.com/')
                if (param.length > 1) {
                    this.code_video = param[1];
                    link = 'https://player.vimeo.com/video/' + param[1] + '?enablejsapi=1';
                }
                else {
                    link = link
                }

                this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(link);
            }
            // else {
            //     console.log('Não compativel !');
            // }

        }

        if (this.type_video == 'vimeo') this.configVimeo();
        else if (this.type_video == 'youtube') this.configYoutube();
    }

    openLink(link) {
        //     if(link.substring(0,4) != 'http'){
        //         window.open(`http://${link}`);
        //     }
        //     else{
        //         window.open(link);
        //     }
    }

    tratamento(list) {
        if (this.preview_campaign) {
            if (list?.background_image?.url != null) {
                return list;
            }
            else if (list?.images != null && list?.images.length > 0) {
                return list.images[0];
            }
            else
                return list;
        }
        else {
            if (list?.internal_images != undefined) {
                // console.log('3',list.internal_images)
                this.type_page = 'internal';
                return list.internal_images;
            }
            else if (list?.page_images != undefined) {
                // console.log('2',list.page_images)
                this.type_page = 'page';
                return list.page_images;
            }
            else if (this.list?.home_images != undefined) {
                // console.log('1',list.home_images)
                this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }

    }
}
