import { I } from '@angular/cdk/keycodes';
import { registerLocaleData } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from 'src/app/service/storage.service';
import { UserCampaignService } from 'src/app/service/user-campaign.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-login-assai',
  templateUrl: './login-assai.component.html',
  styleUrls: ['./login-assai.component.css']
})
export class LoginAssaiComponent implements OnInit {

  @Input() header: any;
  @Output() newItemEvent = new EventEmitter();

  constructor(
    private userCampaignService: UserCampaignService,
    private storageService: StorageService,
    private _snackBar: MatSnackBar
  ) { }

  screen = { login: true, register: false, code: false };
  fase: number = 1;
  checked: boolean = false;
  warningMap = new Map();

  loading = { sendEmail: false, login: false, sendCode: false }

  validator = new ValidatorUtils()

  user_register = {
    name: '',
    cpf: '',
    email: '',
    password: '',
    password2: '',
    entrepreneur: '',
    gender: '',
    authorization: '',
    checked_1: false,
    checked_2: false,
    establishment: null,
    token: null
  }


  checkUser

  login_email: string = '';
  login_code: string = '';
  array_user: any;

  logged_in_user: boolean = false;
  info_user: any;

  ngOnInit(): void {
    this.storageService.checkLoginUser()

    if (localStorage.getItem('tokenUser') != null) {
      this.logged_in_user = true;
      this.info_user = JSON.parse(localStorage.getItem('_user'));
    }
  }

  openRegister() {
    this.screen.login = false;
    this.screen.register = true;
  }

  sendCode() {
    this.warningMap = new Map();

    if (this.login_email == null || this.login_email == "") {
      this.warningMap.set('login_email', true);
    }

    if (this.warningMap.size == 0) {
      this.checkLogin(this.login_email)
    }
  }

  checkCode() {
    this.warningMap = new Map();

    if (this.login_code == null || this.login_code == "") {
      this.warningMap.set('login_code', true);
    }

    if (this.warningMap.size == 0) {
      this.loading.sendCode = true;

      if (this.array_user == null)
        this.array_user = this.storageService.getInfoUser()

      this.userCampaignService.validedHash(this.array_user.token, this.user_register.email, null, this.login_code).subscribe(data => {

        var list_login_user = JSON.parse(localStorage.getItem("_list_accounts"));

        if (list_login_user == null) list_login_user = []
        list_login_user.push(this.array_user)

        localStorage.removeItem('_user')
        localStorage.removeItem('tokenUser')

        localStorage.setItem('_user', JSON.stringify(this.array_user))
        localStorage.setItem('tokenUser', this.array_user.token)
        localStorage.setItem('_list_accounts', JSON.stringify(list_login_user))

        this.newItemEvent.emit('loginOK');
        this.loading.sendCode = false;
      },
        error => {
          this.loading.sendCode = false;
        })
    }
  }

  checkLogin(email) {
    this.loading.sendEmail = true;

    this.userCampaignService.getLoginUser(email).subscribe(data => {

      if (data != null && data.length > 0) {
        this.array_user = data[0];
        this.login_email = data[0].email != null ? data[0].email : '';

        this.userCampaignService.sendEmailUserCampaign(this.login_email, null, null).subscribe(data => {
          this.loading.sendEmail = false;
          this.screen.code = true;
        })

      }
      else {
        this.array_user = null
      }
    },
      error => {
        this.loading.sendEmail = false;
      })
  }

  next() {
    this.warningMap = new Map();

    if (this.fase == 1) {
      if (this.user_register.name == null || this.user_register.name == "") {
        this.warningMap.set('login_name', true);
      }

      if (this.user_register.email == null || this.user_register.email == "") {
        this.warningMap.set('login_email', true);
      }
      if (this.validator.isValidEmail(this.user_register.email)) {
        this.warningMap.set('login_email', true);
      }

      if (this.checkLoginUser(this.user_register.email)) {
        this.screen.login = true;
        this.screen.register = false;
      }

      if (this.warningMap.size == 0) {
        this.fase = 2;
      }
    }
    else if (this.fase == 2) {
      if (!this.validator.isValidCPF(this.user_register.cpf)) {
        this.warningMap.set('login_cpf', true);
      }

      if (this.warningMap.size == 0) {
        this.fase = 3;
      }
    }

    else if (this.fase == 3) {
      if (this.user_register.entrepreneur == "") {
        this.warningMap.set('entrepreneur', true);
      }

      if (this.user_register.gender == "") {
        this.warningMap.set('gender', true);
      }

      if (this.user_register.authorization == "") {
        this.warningMap.set('authorization', true);
      }

      if (this.user_register.checked_1 == false) {
        this.warningMap.set('checked_1', true);
      }

      if (this.user_register.checked_2 == false) {
        this.warningMap.set('checked_2', true);
      }

      if (this.warningMap.size == 0) {
        this.registerUser()
      }

    }
    else if (this.fase == 4) {
      this.fase = 1;
      this.screen.login = true;
      this.screen.register = false;
    }
  }

  checkLoginUser(email) {
    this.userCampaignService.getLoginUser(email).subscribe(data => {
      if (data != null && data.length > 0) {
        this.screen.login = true;
        this.screen.code = true;
        this.screen.register = false;
        this.login_email = data[0].email != null ? data[0].email : '';
        this.user_register.email = data[0].email != null ? data[0].email : '';

        this.userCampaignService.sendEmailUserCampaign(this.login_email, null, null).subscribe(data => {
          this.loading.sendEmail = false;
          this.screen.code = true;
          // this.newItemEvent.emit('loginOK');
        })

        this.openSnackBar("Já existe um usuário com esse e-mail!", "Fechar")
        this.checkUser = true;
      }
      else {
        this.checkUser = false;
      }
    })

    return this.checkUser
  }

  createToken() {
    var create_token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
    return create_token;
  };

  registerUser() {
    let establishment = this.storageService.getEstablishment()
    let token = this.createToken()

    this.userCampaignService.postUserCampaignAssai(establishment, token, this.user_register).subscribe(data => {
      // console.log(data)

      this.user_register.cpf = data.cpf;
      this.user_register.name = data.name;
      this.user_register.email = data.email;
      this.user_register.establishment = data.establishment;
      this.user_register.gender = data.gender;
      this.user_register.token = token;

      this.array_user = this.user_register;

      this.openSnackBar("Já existe um usuário com esse e-mail!", "Fechar")

      this.userCampaignService.sendEmailUserCampaign(data.email, null, null).subscribe(data => {
        this.loading.sendEmail = false;
        this.screen.code = true;
      })


      this.fase = 4;
    },
      error => { console.log(error) })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  logoff() {
    this.newItemEvent.emit('logoff_user');
  }

}
