import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class PaymentService {

    constructor(private http: HttpClient) { }

    token = "725ef6d3412c66cbe417940f70dda6969e147c59";
    // token;
    host = "https://api.hashlar.com.br/"

    createOrder(coin_id, amount, email, token): Observable<any> {
        const body = new HttpParams()
            .set(`coin`, coin_id)
            .set(`amount`, amount)
            .set(`email`, email)

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${token}`)

        return this.http.post<any>(this.host + `core/order/`, body.toString(), { headers })
    }

    getCurrencies(page, token): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`page`, page),
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.get<any>(this.host + `core/coin/`, options);
    }

    getCoinPrice(coin, token): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`coin`, coin),
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.get<any>(this.host + `core/get-coin-price/`, options);
    }

    getOrder(order_id, token): Observable<any> {
        const options = {
            params: new HttpParams()
                .set(`id`, order_id),
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${token}`)
        };

        return this.http.get<any>(this.host + `core/order/by-id`, options);
    }

}
