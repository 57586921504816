import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../app.config';
import { CampaignService } from '../service/campaign.service';
import { CatalogService } from '../service/catalog.service';
import { ConstantsService } from '../service/constants.service';
import { QrCodeService } from '../service/qrcode.service';
import { StorageService } from '../service/storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserCampaignService } from '../service/user-campaign.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MessageQrcodeComponent } from '../modals/message-qrcode/message-qrcode.component';

@Component({
	selector: 'app-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['./redirect.component.css']
})

export class RedirectComponent {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private campaignService: CampaignService,
		private userCampaignService: UserCampaignService,
		private catalogService: CatalogService,
		private constantsService: ConstantsService,
		private storageService: StorageService,
		private qrCodeService: QrCodeService,
		private deviceService: DeviceDetectorService,
		private modalService: MDBModalService,
	) {
		this.url_screen = router.url;
		var url = this.router.url;
		var aux = url.split('/')
		var tam = aux.length
		this.slugPage = aux[tam - 1]

		this.screen_url = this.route.snapshot.params.id
	}

	@Input() sendList: any
	@HostListener('window:scroll', ['$event']) onScrollEvent($event) {
		this.scroll($event)
	}

	modalRef: MDBModalRef;

	url_screen;
	slugPage;

	screen_url;
	active_campaign: any;
	// sendList: any;
	sendFormat: any;

	check_input: boolean = true
	bar_search: boolean = false;
	warningMap = new Map();

	name;
	email;
	phone;
	password;

	component: boolean = false;
	loading: boolean = true;
	image_link: string;

	header = {
		title: null,
		color: null,
		back: null,
		whatsapp: null,
		logo: null,
		show_header: true
	}

	listTypeStatus = []

	screenBack = [];
	error: boolean = false;

	deviceInfo = null;

	all_access = [{
		campaign: null,
		last_access: null,
		amount_of_access: 0,
		registration: false,
	}]

	campaign;
	campaign_id;
	qrcode_id;
	last_access;
	length_list: number = 0;

	user;
	list_login_user = []
	navegationInfo = null;

	id_qrcode: any = null;

	perfil: boolean = false;
	login_status: boolean = false;
	send_code: boolean = false;
	time: number = 300000; // tempo de reenvio código

	page: number = 1;
	login_email: string = '';
	loading_more: boolean = false;
	registration_obj: any;

	type_page: string = '';
	registration: boolean = true;
	popup_registration: boolean = true;

	screen_report: boolean = false;
	establishment_report: any;

	blockScreen: boolean = false;
	already_check_login: boolean = false;

	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem("_user"));
		this.storageService.checkLoginUser()

		if (this.sendList == null) {
			if (Number(this.slugPage) > -1) {
				this.getCampaign(this.screen_url)
			}
			else {
				this.getQrCodesSlug(this.slugPage)
			}
		}
		else {
			this.setData(this.sendList)
		}
	}

	scroll(e) {
		if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 1)) {
			this.loading_more = true;
			this.moreCampaign()
		}
	}

	moreCampaign() {
		setTimeout(() => {
			this.loading_more = false;
		}, 5000);
	}

	openWhatsapp() {
		window.open('https://wa.me/' + this.header.whatsapp)
	}

	createLogs(token, user) {
		if (this.campaign_id == null) this.campaign_id = this.screen_url;

		// this.id_qrcode = aux_list[position]?.qr_code_obj != null ? aux_list[position].qr_code_obj : null

		// ## PEGAR O ID DO QRCODE CORRETO ##
		// this.userCampaignService.createLogs(token, this.campaign_id, user, this.id_qrcode, this.navegationInfo).subscribe(data => {
		// 	this.user = data;
		// 	this.storageService.token_user = token;
		// })
	}

	checkAssai(campaign) {
		if (this.url_screen != '/application/content' && this.url_screen != '/application/dashboard') {
			if (campaign.is_feed) {
				this.router.navigate(['/redirect-sbs/' + this.screen_url])
			}
		}
	}

	EventClick(event) {

	}

	getQrCodesSlug(slug) {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		let device = this.deviceInfo.os == 'iOS' ? 1 : (this.deviceInfo.os == 'Android' ? 2 : null)
		let user_token = localStorage.getItem("tokenUser");

		this.qrCodeService.getQrCodesSlug(slug, user_token, device).subscribe(data => {
			// console.log(data)

			if (data != null && data.length == 0) {
				this.getCheckSlug(slug)
			}
			else {
				var campaign_obj = this.getPosition(data)

				this.campaign = campaign_obj

				this.campaign_id = campaign_obj;

				var result = data.filter(el => el.campaign == campaign_obj)
				this.qrcode_id = result[0].qr_code;

				if (campaign_obj != false)
					this.getCampaign(campaign_obj)
				else {
					this.error = true;
				}
			}
		}, error => {
			this.loading = false;
			this.error = true;
			// console.log(error)
		})
	}

	getCheckSlug(slug) {
		this.qrCodeService.getQrCodesSlugOnly(slug).subscribe(data => {

			if (data != null && data[0]?.establishment != null) {
				this.storageService.id_establishment = data[0].establishment;
				localStorage.setItem('id_establishment', data[0].establishment)
				this.qrcode_id = data[0].id;
			}

			if (data != null && data[0]?.registration_config != null && !this.already_check_login && localStorage.getItem('tokenUser') == null) {
				this.registration = true;
			}
			else {
				this.registration = false;
				this.error = true
			}

			this.registration_obj = data[0];
			this.loading = false;

			if (data != null && data[0]?.id != null && this.storageService.qrcode_reading) {
				if (localStorage.getItem('tokenUser') == null && localStorage.getItem('tokenAnonimo') == null) {
					this.createAnonimo()
				}

				this.checkNextQRCode(data[0].id)
			}
		}, error => {
			this.loading = false;
		})
	}

	separar(base, maximo) {
		var resultado = [[]];
		var grupo = 0;

		for (var indice = 0; indice < base.length; indice++) {

			if (resultado[grupo] == null) resultado[grupo] = [];
			resultado[grupo].push(base[indice]);

			grupo++;
			if (grupo === maximo) grupo = 0;
		}

		return resultado;
	}

	getPosition(list) {
		if (list == null || list.length <= 0)
			return false

		var position = list.length - 1;

		this.id_qrcode = list[position]?.qr_code_obj != null ? list[position].qr_code_obj : null

		// ANONIMO
		if (this.id_qrcode[0]?.id != null && this.storageService.qrcode_reading) {
			if (localStorage.getItem('tokenUser') == null && localStorage.getItem('tokenAnonimo') == null) {
				this.createAnonimo()
			}

			this.checkNextQRCode(this.id_qrcode[0].id)
		}

		return list[position].campaign
	}

	createAnonimo() {
		var token = this.createToken()
		localStorage.setItem('tokenAnonimo', token);
	}

	createToken() {
		var create_token = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
		return create_token;
	};

	setData(data) {
		this.storageService.id_establishment = data.establishment;
		localStorage.setItem('id_establishment', data.establishment)
		this.campaign = data;

		this.checkAssai(data)

		if (this.url_screen != '/application/dashboard')
			this.storageService.saveHistoric(this.campaign_id, this.campaign_id, (this.campaign.homepage_format || this.campaign.page_format), 1, 'campaign', this.qrcode_id)

		this.active_campaign = data;


		this.header.title = this.active_campaign.header_text;
		this.header.color = this.active_campaign.text_color;
		this.header.back = this.active_campaign.header_color;
		this.header.whatsapp = this.active_campaign.whatsapp != '55' ? this.active_campaign.whatsapp : null;
		this.header.logo = this.active_campaign.logo;
		this.header.show_header = this.active_campaign.show_header;

		//ERRO COR
		// this.storageService.establishment.color = this.header.back;


		this.sendList = this.active_campaign;

		if (this.active_campaign.home_images != null && this.active_campaign.home_images.length > 0)
			this.sendList.images = this.active_campaign.home_images.sort(this.ordenarList);
		else
			this.sendList.images = this.active_campaign.home_images

		this.sendFormat = this.active_campaign.homepage_format_obj

		this.screenBack.push(this.sendList)
		// this.loading = false;


		if (this.active_campaign.active_type_campaign == "LINK") {
			if (this.active_campaign.link != null) {
				if (this.active_campaign.link.substring(0, 4) != 'http') {
					var aux_link = this.active_campaign.link.replace(/\s/g, '');
					window.location.href = `http://${aux_link}`;
				}
				else {
					var aux_link = this.active_campaign.link.replace(/\s/g, '');
					window.location.href = aux_link;
				}
			}
			else {
				window.location.href = "https://www.google.com";
			}
		}
		else if (this.active_campaign.active_type_campaign == "IMAGE") {
			this.image_link = `${AppConfig.path}${this.active_campaign.image}`;
		}

		this.checkQRCode()
	}

	checkQRCode() {
		var registration_config = null;

		if (this.campaign?.qr_codes != null && this.campaign?.qr_codes[0]?.qr_code_obj[0]?.registration_config != null) {
			var registration_config = this.campaign.qr_codes[0]?.qr_code_obj[0]?.registration_config
		}

		if (registration_config != null && localStorage.getItem("tokenUser") == null) {
			this.registration = true;
			this.popup_registration = true;
			this.registration_obj = this.campaign;
		}
		else {
			this.registration = false;
			this.popup_registration = false;
		}

		this.loading = false;
	}

	getCampaign(id) {
		if (id == null) {
			this.loading = false;

			this.error = true;
			return
		}
		else {
			this.loading = true;

			this.campaignService.getCampaignId(id).subscribe(data => {
				this.storageService.id_establishment = data.establishment;
				this.campaign = data;

				this.checkUser()
				this.setData(data)
				this.loading = false;

			}, error => {
				// if(list.length > index+1){
				// 	this.getCampaign(list[index+1].campaign, list, index+1)
				// }
				// else{
				this.loading = false;

				this.error = true;
				// }
			})
		}
	}

	checkUser() {
		this.navegationInfo = this.deviceService.getDeviceInfo();

		if (this.user != null && this.user.tokens != null &&
			this.user.tokens.length > 0) {

			this.user.tokens.forEach(token => {
				this.createLogs(token, this.user)
			});
		}
		else {
			this.createLogs(null, null)
		}

	}

	ordenarList(a, b) {
		if (a.order < b.order)
			return -1;
		if (a.order > b.order)
			return 1;
		return 0;
	}

	closeReport(newItem) {
		this.screen_report = false;
	}

	returnLogin(event) {
		this.already_check_login = true
		this.registration = false;

		if (Number(this.slugPage) > -1) {
			this.getCampaign(this.screen_url)
		}
		else {
			this.getQrCodesSlug(this.slugPage)
		}
	}

	addItem(newItem) {
		console.log(newItem);
		
		// mocked HASHLAR payment
		// if(newItem.page_format_obj.id == 17){
		// 	console.log('MODE HASHLAR');
		// 	console.log('hashlar')
		// 	this.sendFormat = {name: "Payment"}
		// 	this.screenBack.push(newItem)
        //     return
		// }
		
        // if (
		// 	(newItem.page && AppConfig.hashlarItemsInternalPage.includes(newItem.id)) ||
		// 	(newItem.homepage && AppConfig.hashlarItemsPage.includes(newItem.id)) ||
		// 	(newItem.campaign && AppConfig.hashlarItemsHomepage.includes(newItem.id))
		// ) {
        //     console.log('hashlar')
		// 	this.sendFormat = {name: "Payment"}
		// 	this.screenBack.push(newItem)
		// 	this.sendList = newItem
        //     return
        // }

		if (newItem?.id == null && newItem.substring(0, 10) == 'openReport') {
			var report = newItem.indexOf('openReport')
			// console.log(report)
			if (report != -1) {
				var aux = newItem.split('establishment')
				// console.log(aux)
				newItem = 'openReport';
				this.establishment_report = aux[1]
			}
		}

		if (newItem == 'registerBack') {
			this.back()
		}
		else if (newItem == 'doubleBack') {
			this.back()

			this.back()
		}
		else if (newItem == 'openReport') {
			this.screen_report = true;
			this.establishment_report
		}
		else if (newItem?.page_format_obj != null) {
			if (newItem.page_format_obj.name == 'Link') {
			}
			else {
				this.sendFormat = newItem.page_format_obj;

				this.sendList = newItem;

				var tam = (this.screenBack.length) - 1
				if (this.sendList != this.screenBack[tam]) {
					this.screenBack.push(this.sendList)
				}

				setTimeout(() => {
					this.campaignService.changeList()
				}, 500);
			}
		}
		else if (newItem?.internal_images != null) {
			this.sendFormat = newItem.internal_images[0].catalog_obj;

			if (this.sendFormat != null) {
				this.sendList = newItem;

				var tam = (this.screenBack.length) - 1
				if (this.sendList != this.screenBack[tam]) {
					this.screenBack.push(this.sendList)
				}

				setTimeout(() => {
					this.campaignService.changeList()
				}, 500);
			}

		}

	}

	back() {
		// console.log(this.storageService.getPageShoppingCart());
		
		if (this.storageService.getPageShoppingCart() != 0) {
			if (this.storageService.getPageShoppingCart() == 1)
				this.storageService.page_shoppingCart = 0;
			else if (this.storageService.getPageShoppingCart() == 2)
				this.storageService.page_shoppingCart = 1;
			else if (this.storageService.getPageShoppingCart() == 4)
				this.storageService.page_shoppingCart = 0;
		}
		else {
			var tam = (this.screenBack.length) - 1;

			if (tam > 0) {
				this.sendList = this.screenBack[tam - 1];

				if (this.sendList?.homepage_format_obj != null)
					this.sendFormat = this.sendList.homepage_format_obj;

				else if (this.sendList?.page_format_obj != null)
					this.sendFormat = this.sendList.page_format_obj;

				else if (this.sendList?.internal_images != null)
					this.sendFormat = this.sendList.internal_images[0].catalog_obj;

				this.screenBack.splice(tam, 1)
			}

			setTimeout(() => {
				this.campaignService.changeList()
			}, 500);

			if (this.sendList?.page_format_obj?.name == "Cadastro" && this.storageService.token_user != null) {
				this.back()
			}
		}
	}

	sendCode() {
		if (this.email == null || this.email == "") {
			this.warningMap.set('email', true);
		}
		if (this.phone == null || this.phone == "") {
			this.warningMap.set('photo', true);
		}
		if (this.warningMap.size == 0) {

			if (this.loading) {
				this.loading = false

				this.userCampaignService.sendEmailUserCampaign(this.email, this.phone, this.user.token).subscribe(data => {
					this.send_code = !this.send_code;
				})
			}

			setTimeout(() => {
				this.loading = true;

			}, this.time);

		}

	}

	loginSendEmail() {
		if (this.login_email == null || this.login_email == "") {
			this.warningMap.set('login_email', true);
		}

		if (this.warningMap.size == 0) {

		}
	}

	login() {
		this.login_status = !this.login_status;
	}

	checkNextQRCode(id_qrcode) {
		this.qrCodeService.nextQrcodeFlow(id_qrcode).subscribe(data => {
			this.storageService.qrcode_reading = false;

			if (data.worked == true) {
				this.blockScreen = false;
			}
			else if (data.detail == "QrCode não é o primeiro") {
				this.openMessage()
				this.blockScreen = true;
			}
			else if (data.worked != true) {
				this.openMessage()
				this.blockScreen = true;
			}
		}, error => {
			console.log(error)
			this.openMessage()
			this.blockScreen = true;
		})
	}

	openMessage() {
		this.modalRef = this.modalService.show(MessageQrcodeComponent, {
			backdrop: 'static',
			keyboard: false,
			focus: true,
			show: true,
			ignoreBackdropClick: false,
			class: 'modal-dialog-centered popup-transparent',
			containerClass: '',
			animated: false
		})

	}

	logoff() {
		this.user = null;
		this.storageService.user_id = null;
		this.storageService.token_user = null;
		this.storageService.info_user = null;
		localStorage.removeItem('_user')
		localStorage.removeItem('tokenUser')
		localStorage.removeItem('_list_accounts')
	}

}
