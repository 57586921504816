import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Survey } from 'src/app/model/survey';
import { CampaignService } from '../../service/campaign.service';
import { AppConfig } from '../../app.config';
import { SurveyService } from 'src/app/service/survey.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.css']
})

export class SurveyComponent {

	@Input() list: any;
    @Input() header: any;
    @Input() preview_campaign: any;
	@Input() type_campaign: any;
    @Input() campaign: any;
    @Output() newItemEvent = new EventEmitter();

  	constructor(
		private campaignService: CampaignService,
		private router: Router,
        private surveyService: SurveyService,
        private storageService: StorageService

	) {
        this.url_screen = router.url;
		var url = this.router.url;
		var aux = url.split('/')
		var tam = aux.length
		this.urlPage = aux[tam-1]
	}

	@Input() campaign_id;

	urlPage;

	selection = new SelectionModel<any>(false, []);
    
    url_screen;
    answer: any;
    list_answer = [];
	survey: Survey;
	listImage;
    qrcode_id;

    loading: boolean = true;
    loading_btn: boolean = false;
    msg_error: string = null;
    url = AppConfig.path;

    survey_answer;
    status_answer: boolean = false;

    answer_user = []

    type_page: string = '';

    ngOnInit(){
        // console.log("-> Pergunta <-")
        this.qrcode_id = this.list?.qr_codes != null && this.list?.qr_codes[0]?.qr_code != null ? this.list.qr_codes[0].qr_code : null;

        this.campaign_id = this.list.id;
        
        this.storageService.screen.format = 'Pergunta';
        this.storageService.screen.id = this.list.id;

        this.survey_answer = this.storageService.survey_answer;

		// this.campaignService.watchList().subscribe( data => {
        //     this.checkStatusPreview()
        // })

        this.checkStatusPreview()
    }
	
    selectAnswers(obj, question){

        let position = this.answer_user.findIndex((x) => (x.question == question.id));

        if(position < 0){
            this.answer_user.push({question: question.id, id: obj.id, answer: obj})
        }
        else{
            this.answer_user[position].id = obj.id;
            this.answer_user[position].answer = obj;
        }
    }

    checkStatusPreview(){
		this.loading = true;

        // let survey = this.list.survey == null ? (this.listImage != null ? this.listImage[0].survey : this.list.page_images[0].survey) : this.list.survey
        this.survey = this.list.survey == null ? null: this.list.survey

        let status = true;

		this.campaignService.watchList().subscribe( data => {
            status = false;

            if(this.preview_campaign && this.survey != null){
				this.surveyService.getSurveyId(this.survey).subscribe(data=>{
                    this.listImage = data.questions;
                    this.checkAnswerSave()
					this.loading = false;
				})
			}
			else{
                this.listImage = this.tratamento(this.list)
               
                if(this.campaign_id != null && this.list?.id != null && this.list?.homepage_format != null){
                    if(this.url_screen != '/application/dashboard')        {
                        this.storageService.saveHistoric(this.campaign_id, this.list.id, this.list.homepage_format, 6, this.type_page, this.qrcode_id)
                    }
                }

                this.checkAnswerSave()
				this.loading = false;
			}
        },error=>{
        })

        if(status){
            this.listImage = this.tratamento(this.list)
            setTimeout(() => {
                var id = this.listImage?.id != null ? this.listImage.id : 
                        (this.listImage != null && this.listImage[0]?.id != null ? this.listImage[0].id : null)
    
                this.type_page = this.storageService.checkTypePage(this.list, id)
    
            }, 200);

            this.loading = false;
        }
	}

    checkAnswerSave(){
        if(this.survey_answer.length > 0){

            this.survey_answer.forEach(item => {     

                if(item.id == this.listImage[0].survey){
                    this.status_answer = true;

                    if(item.type == 'SINGLE') this.answer = item.answer;
                    else if(item.type == 'MULTIPLE'){
                        
                        if(this.listImage[0]?.question?.answers != null && this.listImage[0]?.question?.answers.lenth > 0){
                            this.listImage[0].question.answers.forEach(answers => {
                                item.list.forEach(item => {
                                    
                                    let unit_index = answers.findIndex((x) => (x.id == item));
                                    this.selection.select(unit_index)
            
                                });
                            });
                        }
                            
                    }
                }
            });

            
        }
    }

	addNewItem(list) {
        
        if(this.preview_campaign){
            if(list?.type?.name == "Link"){
                if(list.link.substring(0,4) != 'http'){
                    window.open(`http://${list.link}`);
                    return true
                }
                else{
                    window.open(list.link);
                    return true
                }
            }
            else
                this.newItemEvent.emit(list);
        }
        else{
            if(list?.page_format_obj?.name == "Link"){
                window.open(list.page_images[0].link)
            }
            if(list?.page_format_obj?.name != null){
                this.newItemEvent.emit(list);
            }
        }
        
    }

	tratamento(list){
        if(this.preview_campaign){
            if(this.list?.images != undefined){
                // console.log('0',list.images)
                return list.images;
            }
            else{
                // console.log('null')
                return null
            }
        }
        else{
			if(list?.survey_obj != undefined){
                // console.log('4',list.survey_obj)
                this.type_page = 'home';
                return list.survey_obj?.questions;
            }
            if(list?.internal_images != undefined){
                // console.log('3',list.internal_images)
                this.type_page = 'internal';
                return list.internal_images[0]?.survey_obj?.questions;
            }
            else if(list?.page_images != undefined){
                // console.log('2',list.page_images)
                this.type_page = 'page';
                return list.page_images[0]?.survey_obj?.questions;
            }
            else if(this.list?.home_images != undefined){
                // console.log('1',list.home_images)
                this.type_page = 'home';
                return list.home_images;
            }
            else
                return null
        }
       
    }

    answerSurvey(){
        if(!this.loading_btn){
            this.msg_error = "";
            this.loading_btn = true;

            // if(this.listImage[0].survey_type == 'SINGLE'){

            //     if(this.answer == null || this.answer == ""){
            //         this.msg_error = "Selecione uma opção antes de enviar.";
            //         this.loading_btn = false;
            //     }
            //     else{
            //         var list = [this.answer.id] 
            //         this.sendAnswer(list)
            //     }
            // }
            // else if(this.listImage[0].survey_type == 'MULTIPLE'){

                if((this.answer_user == null || this.answer_user.length <= 0) || this.answer_user.length != this.listImage.length){
                    this.msg_error = "Selecione pelo menos uma opção antes de enviar.";
                    this.loading_btn = false;
                }
                else{
                    this.sendAnswer(this.answer_user)
                }
            // }
        }
        
    }

    async sendAnswer(list_answers){
        let ids = []

        list_answers.forEach(element => {
            ids.push(element.id)
        });

        await this.storageService.saveHistoric(this.campaign_id, this.list.id, this.list.homepage_format, 7, this.type_page, this.qrcode_id)

        let token = this.storageService.token_user;
        let id_qrcode = (this.campaign?.qrcode != null && this.campaign?.qrcode[0]?.qr_code != null) ? this.campaign.qrcode[0].qr_code : null; 
        let id_campaign = this.campaign.obj.campaign;

        this.surveyService.answerSurvey(ids, token, id_qrcode, id_campaign).subscribe(data=>{
            this.storageService.survey_answer.push({id: this.listImage[0].survey, answer: this.answer, list: ids, type: this.listImage[0].survey_type});
            this.survey_answer = this.storageService.survey_answer;
            this.status_answer = true;
            this.loading_btn = false;
        },
        error=>{
            console.log('ERRO')
            this.loading_btn = false;
        })

    }
    
}
