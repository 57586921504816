<div *ngIf="!loading && page == 0" class="primary-div">

    <div class="coupon">
        <img [src]="cupom?.image" class="img-coupon" loop=infinite>
        <!-- <img [src]="url + cupom?.image" class="img-coupon"> -->
        <h2> {{ cupom?.name }} </h2>
        <h3> {{ cupom?.description }} </h3>
    </div>

    <div class="rule">
        <div class="title" [style.color]="header.back"> Regras: </div>
        {{ cupom?.rule }} <br>
        <a [href]="cupom?.rules" [style.color]="header.back"   target="_blank"> veja as regras </a>
    </div>

    <button mat-button class="b-border" 
    [style.background]="header.back" [style.color]="header.color" (click)="rescue()"> 
        {{ textButton }} 
    </button>
</div>

<app-user-registration style="width: 100%;" *ngIf="page == 1"
    [list]="listImage[0].coupon_obj"
    [header]="header"
    [preview_campaign]="false"
    (newItemEvent)="addItem($event)">
</app-user-registration>