<div class="screen">

    <div style="width: 93%; display: flex; justify-content: space-between; align-items: center; margin: auto;">
        <h3 style="font-weight: 400;"> 
            Crie automaticamente o aplicativo do seu estabelecimento. 
            Crie, modifique e publique campanhas personalizadas para os seus clientes.
        </h3>
    </div>

    <div style="width: 93%; margin: auto;" *ngIf="!loading">
        <mat-tab-group *ngIf="selected_tab != null" [(selectedIndex)]="selected_tab">
            <mat-tab label="Início">  
                <div style="background-color: white; border-radius: 20px; padding: 30px;">
                    <div style="display: flex; font-size: 30px; color: gray; align-items: center;">
                        <img src="/assets/image/googleplay.jpg" style="width: 210px; object-fit: contain;">
                        <img src="/assets/image/appstore.png" style="width: 50px; object-fit: contain; margin: 0 10px 0 20px;">
                        App Store
                    </div>

                    <div style="max-width: 500px; margin: 15px 0; font-size: 16px;">
                        A geração automática de aplicativo para a empresa ________________________________________, 
                        CPNJ _____________________. Será cobrado um valor de R$ xx,xx (xxx reais) por cada loja selecionada, 
                        clique no aceite abaixo e em seguida no botão continuar para prosseguir e acompanhar o andamento da 
                        aprovação do seu APP nas respectivas lojas. A aprovação para liberação do seu aplicativo pela APPLE ou GOOGLE 
                        pode levar ate 30 dias. Você acompanhará todo o processo por aqui.
                    </div>
                    
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <mat-checkbox> Aceito </mat-checkbox>
                        <button mat-button class="main-button"> Continuar </button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Configuração" disabled>  </mat-tab>
            <mat-tab label="Processamento" disabled>  </mat-tab>
            <mat-tab label="Aprovação" disabled>  </mat-tab>
        </mat-tab-group>
    </div>

    <div style="width: 100%; display: flex; justify-content: center; padding: 150px 0;" *ngIf="loading">
        <span class="spinner-border spinner-border-sm m_5" role="status" aria-hidden="true"></span>
    </div>
            
</div>