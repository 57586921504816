import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/service/storage.service';
import { AppConfig } from '../../app.config';

@Component({
    selector: 'app-modal-config-preview',
    templateUrl: './modal-config-preview.component.html',
    styleUrls: ['./modal-config-preview.component.css']
})
export class ModalConfigPreviewComponent implements OnInit {

    constructor(
        public modalRef: MDBModalRef,
        private storageService: StorageService
    ) { }

    @Input() isList: any;
    @Input() isEdit: any;
    @Output() newItemEvent = new EventEmitter();

	loading: boolean = true;
    warningMap = new Map();

    // isList;
    // isEdit;
    listImage
	url = AppConfig.path;

    list_select = {
        orientation: [
            {title:'Não Apurado'},
            {title:'Heterossexual'},
            {title:'Homossexual'},
            {title:'Bissexual'},
            {title:'Assexual'},
            {title:'Panssexual'}
        ],
        identity: [
            {title:'Não Apurado'},
            {title:'Mulher Cisgênero'},
            {title:'Homem Cisgênero'},
            {title:'Mulher Transgênero'},
            {title:'Homem Transgênero'},
            {title:'Gênero não Binário'},
            {title:'Agênero'},
        ]
    }

    list_order = []

    input = {
        name: null,
        email: null,
        birthdate: null,
        cpf: null,
        gender: null,
        sexual_orientation: null,
        gender_identity: null,
        cep: null,
        phone: null,
        check: false,
        password: null,
        password2: null,
        checkInfo: false,
    }

    color_primary

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        this.orderList(this.isList)
    }

    orderList(data){
        var list_aux = []

        if(data.order_fields != null)
            list_aux = data.order_fields.split(', ')
        else{
            list_aux = ['Telefone', 'E-mail', 'Nome', 'CPF', 'Data de nascimento', 'Orientação Sexual', 'Identidade de Gênero', 'CEP']
        }

        list_aux.forEach(item => {
            switch(item){
                case "Telefone":
                    this.list_order.push( { title: 'Telefone', name_api: 'phone', placeholder: 'Digite o seu telefone', mask: '(00) 0 0000-0000', input: null, active: data.phone, active_mandatory: data.phone_mandatory, options: true, check_sms: data.check_sms, checkbox_sms: false})
                    break;
                case "E-mail":
                    this.list_order.push({title: 'E-mail', name_api: 'email', placeholder: 'Digite o seu email', mask: null, input: null, active: data.email, active_mandatory: data.email_mandatory, options: false})
                    break;
                case "Nome":
                    this.list_order.push({title: 'Nome', name_api: 'name', placeholder: 'Digite o seu nome', mask: null, input: null, active: data.name, active_mandatory: data.name_mandatory, options: false})
                    break;
                case "CPF":
                    this.list_order.push({title: 'CPF', name_api: 'cpf', placeholder: 'Digite o seu CPF', mask: '000.000.000-00', input: null, active: data.cpf, active_mandatory: data.cpf_mandatory, options: false})
                    break;
                case "Data de nascimento":
                    this.list_order.push({title: 'Data de nascimento', name_api: 'birthdate', placeholder: 'Data de nascimento', mask: null, input: null, active: data.birthdate, active_mandatory: data.birthdate_mandatory, options: false})
                    break;
                case "Orientação Sexual":
                    this.list_order.push({title: 'Orientação Sexual', name_api: 'sexual_orientation', placeholder: 'Selecione a Orientação Sexual', mask: null, input: null, active: data.sexual_orientation, active_mandatory: data.sexual_orientation_mandatory, options: false})
                    break;
                case "Identidade de Gênero":
                    this.list_order.push({title: 'Identidade de Gênero', name_api: 'gender_identity', placeholder: 'Selecione a Identidade de Gênero', mask: null, input: null, active: data.gender_identity, active_mandatory: data.gender_identity_mandatory, options: false})
                    break;
                case "CEP":
                    this.list_order.push({title: 'CEP', name_api: 'cep', placeholder: 'Digite o seu CEP', mask: '00000-000', input: null, active: data.cep, active_mandatory: data.cep_mandatory, options: false})
                    break;           
            }
        });       

        this.loading = false;
    }

    openPdf(link){
        if(link != null){
            if(link.substring(0,4) == 'http'){
                window.open(`${link}`);
            }
            // else{
            //     window.open(link, '_blanck');
            // }
        }
    }

    check(){
        this.warningMap = new Map();

        this.list_order.forEach(item => {
            if(item.active_mandatory && (item.input==null || item.input == "")){
                this.warningMap.set(item.name_api, true);
            }
        });

        if(!this.input.check){
            this.warningMap.set('check', true);
        }
        if(!this.input.checkInfo){
            this.warningMap.set('checkInfo', true);
        }
        if (this.warningMap.size == 0) {
            this.send()
        }
        
        
    }
    
    send(){
        this.newItemEvent.emit(this.isList);
    }
}
