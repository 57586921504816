import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalEditStatusComponent } from '../order/modal-edit-status/modal-edit-status.component';
import { PreviwOrderComponent } from '../order/previw-order/previw-order.component';
import { OrderService } from '../service/order.service';
import { StorageService } from '../service/storage.service';
import { TabloideService } from '../service/tabloide.service';

@Component({
  selector: 'app-order-tabloide',
  templateUrl: './order-tabloide.component.html',
  styleUrls: ['./order-tabloide.component.css']
})
export class OrderTabloideComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private orderService: OrderService,
    private modalService: MDBModalService,
    private tabloideService: TabloideService
  ) { }

  loading: boolean = false;
  displayedColumns: string[] = ['products', 'price', 'qtd', 'last_change', 'status', 'view'];
  dataSource = new MatTableDataSource<any>();
  modalRef: MDBModalRef;

  /* pagination related variables */
  order_response;
  num: number;
  page: number = 1
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;
  total_results: number;
  color_primary: string = '';

  categories_list = [];

  list_status = [
    {id: 0, status: 'ALL', name: 'Todos'},
    {id: 1, status: 'WAITING', name: 'Aguardando pagamento'},
    {id: 2, status: 'PAYMENT APPROVED', name: 'Pagamento aprovado'},
    {id: 3, status: 'PAYMENT REFUSED', name: 'Pagamento recusado'},
    {id: 4, status: 'IN PROGRESS', name: 'Em andamento'},
    {id: 5, status: 'FINISHED', name: 'Finalizado'}
  ]
  filterStatus;
  obj_filter;

  ngOnInit(): void {
    this.filterStatus = this.list_status[0].id;
    this.num = 10;
    this.page = 1
    this.total_results = 0;

    this.color_primary = this.storageService.establishment.color;

    this.getOrder()
  }

  nextPage(){
    if(this.order_response.next != null){
        this.page += 1;
        this.getOrder()
    }
  }

  backPage(){
      if(this.order_response.previous != null){
          this.page -= 1;
          this.getOrder()
      }
  }

  filterTable(obj){
    this.obj_filter = obj.status;
    this.getOrder()
  }

  editStatus(obj){
    this.modalRef = this.modalService.show(ModalEditStatusComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      containerClass: '',
      animated: false,
      data:{
        order: obj,
        color_primary: this.color_primary,
        _type: 'tabloide'
      }
    })  
    this.modalRef.content.action.subscribe( (result: any) => { 
      if(result == 'yes'){
          this.getOrder()
      }
    });    
  }

  openPreview(obj){
    this.modalRef = this.modalService.show(PreviwOrderComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      containerClass: '',
      animated: false,
      data:{
        order: obj,
        color_primary: this.color_primary,
        _type: 'tabloide'
      }
    })    
  }

  getOrder(){
    this.loading = true;
    // this.dataSource.data = []
    
    this.tabloideService.getOrder(this.page, this.obj_filter).subscribe(data =>{
      // console.log(data)
      
      this.order_response = data;
      this.dataSource.data = data.results;
      this.loading = false

      // pagination calc
      this.count_total = data.count;
      this.page_count1 = 1 + ((this.page-1) * this.page_size)
      if((this.page_count1 + (this.page_size-1)) > this.count_total){
          this.page_count2 = this.count_total;
      }
      else{
          this.page_count2 = this.page_count1 + (this.page_size-1);
      }
    },
    error=>{
        console.log(error)
    })
  }

  
  openDelete(item){

  }

  convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
  }
  
}
