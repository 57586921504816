import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/service/storage.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-popup-edit-produt-catalog',
  templateUrl: './popup-edit-produt-catalog.component.html',
  styleUrls: ['./popup-edit-produt-catalog.component.css']
})
export class PopupEditProdutCatalogComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private storageService: StorageService,
    private compressor: CompressorService,
  ) { }

  title: string = '';
  text_button: string = '';
  action: Subject<any> = new Subject();
  warningMap = new Map();

  product: any;
  type: string;

  obj_product = {
    id: null,
    section: null,
    active: true,
    name: null,
    price: 0,
    image: null,
    description: '',
    classification_food: [],
    table_nutrition: []
  };

  header = {
    title: 'Preview catálogo',
    color: '#000',
    back: '#2fa1d6'
  }

  aux_table_nutrition = [
    { id: null, name: "Carboidratos", quant: "0", value: "0" },
    { id: null, name: "Fibra", quant: "0", value: "0" },
    { id: null, name: "Sódio", quant: "0", value: "0" },
    { id: null, name: "Proteína", quant: "0", value: "0" },
    { id: null, name: "Gorduras Trans", quant: "0", value: "0" },
    { id: null, name: "Gordura total", quant: "0", value: "0" },
  ]

  delet_classification_food = []

  color_primary;
  compressed_image = []; // as the name says...

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fruits = [];

  ngOnInit(): void {
    this.color_primary = this.storageService.establishment.color;

    if (this.product != null) this.obj_product = this.product;

    this.setTabletNutrition()

    if (this.type == 'new') {
      this.title = 'Novo Produto';
      this.text_button = 'Adicionar'
    }
    else if (this.type == 'edit') {
      this.title = 'Editar Produto';
      this.text_button = 'Atualizar'
    }

    console.log(this.obj_product);

  }

  setTabletNutrition() {
    if (this.obj_product.table_nutrition == null || this.obj_product.table_nutrition.length == 0) {
      this.obj_product.table_nutrition = this.aux_table_nutrition
    }
  }

  save() {
    this.warningMap = new Map();

    if (this.obj_product.name == null || this.obj_product.name == "") {
      this.warningMap.set('name', true);
    }
    if (this.obj_product.price == null || this.obj_product.price == 0) {
      this.warningMap.set('price', true);
    }
    if (this.obj_product.description == null || this.obj_product.description == "") {
      this.warningMap.set('description', true);
    }

    if (this.warningMap.size == 0) {
      this.action.next({ status: 'yes', product: this.obj_product, delet: this.delet_classification_food });
      this.modalRef.hide();
    }
  }

  close() {
    this.modalRef.hide();
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (this.obj_product.classification_food == null) this.obj_product.classification_food = []

      this.obj_product.classification_food.push({ id: null, name: value });
    }

    // Clear the input value

    if (event.input) {
      event.input.value = null;
    }
  }

  remove(fruit): void {
    const index = this.obj_product.classification_food.indexOf(fruit);
    console.log(index);

    if (index >= 0) {
      let obj = this.obj_product.classification_food[index]
      if (obj?.id != null) this.delet_classification_food.push(obj)

      this.obj_product.classification_food.splice(index, 1);
    }
  }


  async onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand(res => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        }),
      );
      compress.subscribe(res => {
        if (res.index > res.array.length - 1) {
          this.obj_product.image = this.compressed_image[0]
          this.compressed_image = []
        }
      });
    }
  }

  recursiveCompress = (image: File, index, array) => {
    return this.compressor.compress(image).pipe(
      map(response => {
        this.compressed_image.push(response)
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      }),
    );
  }
}
