import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-pump-house',
  templateUrl: './pump-house.component.html',
  styleUrls: ['./pump-house.component.css']
})
export class PumpHouseComponent implements OnInit, AfterViewInit {

  obj: any;
  list_table: any;
  header: any;

  emitter: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.list_table);
    
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

}
