<div class="bs-body">
    <div class="cookies-content">
        <p class="pt-3 pr-2 text"> 
            Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência 
            em nossos serviços, ao prosseguir você estará concordando com esta utilização.
        </p>

        <button mat-raised-button class="cookies-btn" (click)="continue()"> 
            PROSSEGUIR 
        </button>
    </div>
</div>