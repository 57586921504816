import { dateMapper } from "src/app/utils/validator.utils";

export const getPage1Config = () => ({

    
    // Table 1
    header_table_1: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 9, col: 1, key: 'place' },
        ],
    },
    table_1: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 8,
        endRows: 16,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 2
    header_table_2: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 17, col: 1, key: 'place' },
        ],
    },
    table_2: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 16,
        endRows: 24,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 3
    header_table_3: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 25, col: 1, key: 'place' },
        ],
    },
    table_3: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 24,
        endRows: 32,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },


    // Table 4
    header_table_4: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 33, col: 1, key: 'place' },
        ],
    },
    table_4: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 32,
        endRows: 36,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // Table 5
    header_table_5: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 37, col: 1, key: 'place' },
        ],
    },
    table_5: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 36,
        endRows: 41,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },

    // Table 6
    header_table_6: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 1, key: 'title' },
            { row: 42, col: 1, key: 'place' },
        ],
    },
    table_6: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 41,
        endRows: 47,
        columns: [
            { index: 1, key: 'name' },
            { index: 2, key: 'ok' },
            { index: 3, key: 'np' },
            { index: 4, key: 'value' },
        ],
    },



    // ------- RIGHT -------

    // Table 7
    header_table_7: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 9, col: 6, key: 'place' },
        ],
    },
    table_7: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 8,
        endRows: 16,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },



    // Table_8
    header_table_8: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 17, col: 6, key: 'place' },
        ],
    },
    table_8: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 16,
        endRows: 24,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },



    // Table_9
    header_table_9: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 25, col: 6, key: 'place' },
        ],
    },
    table_9: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 24,
        endRows: 32,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },


    // Table_10
    header_table_10: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 33, col: 6, key: 'place' },
        ],
    },
    table_10: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 32,
        endRows: 36,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },



    // Table_11
    header_table_11: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 37, col: 6, key: 'place' },
        ],
    },
    table_11: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 36,
        endRows: 41,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },

    // Table_12
    header_table_12: {
        worksheet: 'Casa de bombas ',
        type: 'object',
        fields: [
            { row: 8, col: 6, key: 'title' },
            { row: 42, col: 6, key: 'place' },
        ],
    },
    table_12: {
        worksheet: 'Casa de bombas ',
        type: 'list',
        rowOffset: 41,
        endRows: 47,
        columns: [
            { index: 6, key: 'name' },
            { index: 7, key: 'ok' },
            { index: 8, key: 'np' },
            { index: 9, key: 'value' },
        ],
    },
});
