<div class="import-csv-container">

    <svg (click)="closePopup()" class="icons_close"
        width="25px" height="25px" viewBox="0 0 24 24" [style.fill]="color_primary">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path [style.fill]="color_primary" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
    </svg>
    
    <label for="fileUpload" class="import-btn" [style.background]="color_primary != null ? color_primary : 'rgb(247, 113, 40)'">
        <!-- <mat-icon class="icon">cloud_upload</mat-icon> -->
        <input 
            id="fileUpload"
            type="file" 
            accept=".csv, .xlsx, .pdf" 
            style="display: none;"
            (change)="changeListener($event.target.files)"
        />
    </label>
    <span class="filename">{{nomeArquivo}}</span>

    <button class="next-btn main-button" [style.background]="color_primary != null ? color_primary : 'rgb(247, 113, 40)'" (click)="next()" mat-button> 
        Salvar 
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
</div>