import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AppConfig } from '../app.config';
import { CustomEncoder } from "./custom-encoder";

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    constructor(private http : HttpClient) { }

    establishment = "id_establishment"

    getRegistration(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };   

        return this.http.get<any>(AppConfig.path + `core/registration-config/`, options);
    }

    getRegistrationId(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        };   

        return this.http.get<any>(AppConfig.path + `core/registration-config/${id}`, options);
    }


    postRegistration(title, description, image, terms, order_fields, list_register ): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`establishment`, localStorage.getItem(this.establishment))
            .set(`title`, title)
            .set(`description`, description)
            .set(`image`, encodeURI(image))
            .set(`terms`, encodeURI(terms))
            .set(`order_fields`, order_fields)

            list_register.forEach(item => {
                switch(item.title){
                    case "Telefone":
                        body = body.set(`phone`, item.active)
                        body = body.set(`phone_mandatory`, item.active_mandatory)
                        body = body.set(`check_sms`, item.check_sms)
                        break;
                    case "E-mail":
                        body = body.set(`email`, item.active)
                        body = body.set(`email_mandatory`, item.active_mandatory)
                        break;
                    case "Nome":
                        body = body.set(`name`, item.active)
                        body = body.set(`name_mandatory`, item.active_mandatory)
                        break;
                    case "CPF":
                        body = body.set(`cpf`, item.active)
                        body = body.set(`cpf_mandatory`, item.active_mandatory)
                        break;
                    case "Data de nascimento":
                        body = body.set(`birthdate`, item.active)
                        body = body.set(`birthdate_mandatory`, item.active_mandatory)
                        break;
                    case "Orientação Sexual":
                        body = body.set(`sexual_orientation`, item.active)
                        body = body.set(`sexual_orientation_mandatory`, item.active_mandatory)
                        break;
                    case "Identidade de Gênero":
                        body = body.set(`gender_identity`, item.active)
                        body = body.set(`sexual_orientation_mandatory`, item.active_mandatory)
                        break;
                    case "CEP":
                        body = body.set(`cep`, item.active)
                        body = body.set(`cep_mandatory`, item.active_mandatory)
                        break;           
                }
            });     

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/registration-config/`, body, { headers })
    }

    patchRegistration(register): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})

        for (let key of Object.keys(register)) {
            if((key == 'image' || key == 'rules') && 
                register[key] != null && register[key] != ""){
                    
                body = body.set(key, encodeURI(register[key]));
            }
            else if(key != 'id' && 
                ((register[key] == false || register[key] == true) || 
                register[key] != null && register[key] != "")){

                body = body.set(key, register[key]);
            }
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/registration-config/${register.id}/`, body, { headers })
    }

    deleteRegistration(id): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`active`, 'false')

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/registration-config/${id}/`, body, { headers })
    }
}
