import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-emergency-exit',
  templateUrl: './emergency-exit.component.html',
  styleUrls: ['./emergency-exit.component.css']
})
export class EmergencyExitComponent implements OnInit {

  table: any;
  header: any;
  footer: any;
  list_description: any;
  title: string;
  local: string;

  emitter: EventEmitter<void> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit(): void {
    console.log(this.local);
    console.log(this.table);
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }


}
