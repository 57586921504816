import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/service/compressor.service';
import { SectionService } from 'src/app/service/section.service';
import { StorageService } from 'src/app/service/storage.service';
import { CatalogService } from '../../service/catalog.service';
import { ModalCatalogPreviewComponent } from '../modal-catalog-preview/modal-catalog-preview.component';
import { PopupEditProdutCatalogComponent } from '../popup-edit-produt-catalog/popup-edit-produt-catalog.component'
@Component({
    selector: 'app-catalog-detail',
    templateUrl: './catalog-detail.component.html',
    styleUrls: ['./catalog-detail.component.css']
})

export class CatalogDetailComponent implements OnInit {

    constructor(
        private catalogService: CatalogService,
        private route: ActivatedRoute,
        private compressor: CompressorService,
        private router: Router,
        private sectionService: SectionService,
        private modalService: MDBModalService,
        private storageService: StorageService
    ) {
        this.catalog_id = this.route.snapshot.paramMap.get('id');
    }

    modalRef: MDBModalRef;
    modalDelet: MDBModalRef;

    csvData = null;
    carrouselImages = [];
    list_delet_carrousel = []

    loading: boolean = false;
    loading_count: number = 0;
    warningMap = new Map();
    catalog_id;
    compressed_image = []; // as the name says...

    name: string = "";
    description: string = "";

    items = [];

    panelOpenState = false;

    displayedColumns: string[] = ['name', 'view'];
    refresh_table: boolean = true;

    list_hours = [
        { id: 0, name: 'Manha', status: false, hour_start: '06:00:00', hour_end: '12:00:00' },
        { id: 1, name: 'Tarde', status: false, hour_start: '12:00:00', hour_end: '18:00:00' },
        { id: 2, name: 'Noite', status: false, hour_start: '18:00:00', hour_end: '00:00:00' },
        { id: 3, name: 'Madrugada', status: false, hour_start: '00:00:00', hour_end: '06:00:00' },
    ]

    list_status = [{name: 'Ativo', status: true}, {name: 'Desativado', status: false}]

    toppings = new FormControl();
    selection = new SelectionModel<any>(true, []);
    list_hours_select = []

    catalog: any = {
        id: 0,
        name: '',
        description: '',
        logo_menu: null,
        informative_text: '',
        link: '',
        shipping_title: '',
        shippin_description: '',
        footer_text: '',
        section: [{
            name: '',
            morning_period: false,
            afternoon_period: false,
            night_period: false,
            dawn_period: false,
            order:0,
            products: [{
                name: '',
                price: 0,
                image: null,
                description: '',
                id: null,
                section: null,
            }],
            id: null,
            catalog_id: null
        }],
    };

    delete = {
        product: [],
        section: []
    };

    list_viewSection = []
    viewSection: boolean = false;

    list_delet_food = []

    color_primary
    indexExpanded: number = -1;

    ngOnInit(): void {
        this.color_primary = this.storageService.establishment.color;

        if (this.catalog_id != null && this.catalog_id != '') {
            this.getCatalog()
            this.getImages()
        }
    }

    getCatalog() {

        this.catalogService.getCatalog(this.catalog_id).subscribe(data => {
            this.catalog.id = this.catalog_id;
            this.catalog.name = data.name;
            this.catalog.description = data.description;
            this.catalog.logo_menu = data.logo_menu;
            this.catalog.informative_text = data.informative_text;
            this.catalog.link = data.link;
            this.catalog.shipping_title = data.shipping_title;
            this.catalog.shippin_description = data.shippin_description;
            this.catalog.footer_text = data.footer_text;
            this.catalog.purchase = data.purchase;

            if (data.sections != null) {
                this.catalog.section = data.sections;

                data.sections.forEach((el, index) => {
                    var aux_period = []
                    this.catalog.section[index].id = el.id;
                    this.catalog.section[index].catalog_id = el.catalog;
                    this.catalog.section[index].name = el.name;
                    this.catalog.section[index].morning_period = el.morning_period;
                    this.catalog.section[index].afternoon_period = el.afternoon_period;
                    this.catalog.section[index].night_period = el.night_period;
                    this.catalog.section[index].dawn_period = el.dawn_period;
                    this.catalog.section[index].order = el.order;

                    this.list_viewSection.push({ toppings: new FormControl() })

                    if (el.morning_period)
                        aux_period.push('Manha')
                    if (el.afternoon_period)
                        aux_period.push('Tarde')
                    if (el.night_period)
                        aux_period.push('Noite')
                    if (el.dawn_period)
                        aux_period.push('Madrugada')

                    this.list_viewSection[index].toppings.setValue(aux_period, {
                        emitModelToViewChange: false
                    });

                    if (el.products != null) {
                        el.products.forEach((el, index_2) => {
                            this.catalog.section[index].products[index_2].id = el.id;
                            this.catalog.section[index].products[index_2].name = el.name;
                            this.catalog.section[index].products[index_2].price = el.price;
                            this.catalog.section[index].products[index_2].image = el.image;
                            this.catalog.section[index].products[index_2].description = el.description;
                        })
                    }

                })

                this.catalog.section.sort(this.ordenarList)

                this.viewSection = true;
            }
        })
    }

    ordenarList(a, b) {
        if (a.order < b.order)
          return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }

    addSection() {
        this.list_viewSection.push({ toppings: new FormControl() })

        if (this.viewSection) {
            this.catalog.section.push({
                name: '', 
                dawn_period: false, 
                morning_period: false, 
                afternoon_period: false,
                night_period: false, 
                order: this.catalog.section.length,
                products: [{ name: '', price: 0, image: null, description: '', id: null, section: null }], id: null, catalog_id: null
            })
        }
        else {
            this.viewSection = true;
        }
    }

    addItem(position_section) {
        if (this.catalog.section[position_section].products == null)
            this.catalog.section[position_section].products = []

        this.catalog.section[position_section].products.push({ name: '', price: 0, image: null, description: '', id: null, section: null })
    }

    deleteItem(position_section, position) {
        this.refresh_table = false;
        var product = this.catalog.section[position_section].products[position]

        if (product.id != null) {
            this.delete.product.push(product)
            this.catalog.section[position_section].products.splice(position, 1)
        }
        else {
            this.catalog.section[position_section].products.splice(position, 1)
        }

        console.log(this.catalog.section[position_section].products);
        setTimeout(() => {
            this.refresh_table = true;
        }, 200);
    }

    selectHour(item, position) {

        if (item == "Manha")
            this.catalog.section[position].morning_period = !this.catalog.section[position].morning_period;
        else if (item == "Tarde")
            this.catalog.section[position].afternoon_period = !this.catalog.section[position].afternoon_period;
        else if (item == "Noite")
            this.catalog.section[position].night_period = !this.catalog.section[position].night_period;
        else if (item == "Madrugada")
            this.catalog.section[position].dawn_period = !this.catalog.section[position].dawn_period;

    }

    deleteSectionId(position) {

        this.list_viewSection.splice(position, 1)

        var section = this.catalog.section[position]

        if (section.id != null) {
            this.delete.section.push(section)
            this.catalog.section.splice(position, 1)
        }
        else {
            this.catalog.section.splice(position, 1)
        }

        this.delete.section.forEach(element => {
            if (element.products != null && element.products.length > 0) {
                section.products.forEach(item => {
                    if (item.id != null)
                        this.delete.product.push(item)
                });
            }
        });

    }

    togglePanels(index: number) {
        this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }

    save() {
        this.warningMap = new Map();
        if (this.catalog.name == "") {
            this.warningMap.set('name', true);
        }
        if (this.catalog.description == "") {
            this.warningMap.set('description', true);
        }

        this.list_delet_food.forEach(food => {
            console.log(food);
            this.deleteClssificationFood(food.id)
        });

        this.catalog.section.forEach((el, x) => {
            if (el.name == "") {
                this.warningMap.set('name' + x, true);
            }

            if (el.products != null && el.products.length > 0) {
                el.products.forEach((el, y) => {
                    if (el.name == "") {
                        this.warningMap.set('name' + x + y, true);
                        if (this.indexExpanded != x) this.togglePanels(x);
                    }
                    // if (el.description == "") {
                    //     this.warningMap.set('description' + x + y, true);
                    // }
                    if (el.price <= 0) {
                        this.warningMap.set('price' + x + y, true);
                        if (this.indexExpanded != x) this.togglePanels(x);
                    }
                    // if (el.image == null || el.image == "") {
                    //     this.warningMap.set('image' + x + y, true);
                    // }
                })
            }

        })

        if (this.warningMap.size == 0) {
            if (this.catalog_id == null)
                this.postCatalog()
            else
                this.patchCatalog()
        }
    }

    patchCatalog() {

        if (this.loading) return

        this.loading = true;

        if (this.delete.product.length > 0) {
            this.delete.product.forEach(el => {
                this.deleteCatalogProduct(el.id)
            });
        }

        if (this.delete.section.length > 0) {
            this.delete.section.forEach(el => {
                this.deleteSection(el.id)
            });
        }

        //  PATCH CATALOG
        this.catalog.id = this.catalog_id;

        this.catalogService.patchCatalog(this.catalog).subscribe(data => {

            this.catalog.section.forEach(section => {
                this.loading_count += 1;

                this.postImages(data);

                if (section.id == null) {

                    //  POST SECTION
                    this.postSection(section, this.catalog)

                }
                else {

                    //  PATCH SECTION
                    this.sectionService.patchSection(section.name, section.id, section.morning_period, section.afternoon_period, section.night_period, section.dawn_period, section.order).subscribe(data_section => {

                        this.loading_count -= 1;
                        if (this.loading_count == 0) {
                            // this.router.navigate(['/application/catalog'])
                        }

                        if (section.products != null && section.products.length > 0) {
                            section.products.forEach(item => {

                                if (item.id == null) {

                                    //  POST ITEM
                                    this.catalogService.postCatalogItem(data_section.id, item.name,
                                        item.description, item.price, item.image).subscribe(data_item => {

                                            if (item.classification_food != null && item.classification_food.length > 0) {
                                                item.classification_food.forEach(food => {
                                                    if (food?.id == null) {
                                                        this.catalogService.postClssificationFood(food.name, data_item.id).subscribe(data => {
                                                        })
                                                    }
                                                });
                                            }

                                            if (item.table_nutrition != null && item.table_nutrition.length > 0) {
                                                item.table_nutrition.forEach(nutrition => {
                                                    if (nutrition.id == null) {
                                                        this.catalogService.postTableNutrition(nutrition.name, nutrition.quant, nutrition.value, null, data_item.id).subscribe()
                                                    }
                                                    else{
                                                        this.catalogService.patchTableNutrition(nutrition).subscribe()
                                                    }
                                                });
                                            }

                                            this.loading_count -= 1;
                                            if (this.loading_count == 0) {
                                                // this.router.navigate(['/application/catalog'])
                                            }
                                        })
                                }
                                else {

                                    //  PUT ITEM
                                    this.catalogService.putCatalogItem(item.id, section.id, item.name,
                                        item.description, item.price, item.image).subscribe(data_item => {

                                            if (item.classification_food != null && item.classification_food.length > 0) {
                                                item.classification_food.forEach(food => {
                                                    if (food?.id == null) {
                                                        this.catalogService.postClssificationFood(food.name, data_item.id).subscribe()
                                                    }
                                                });
                                            }

                                            if (item.table_nutrition != null && item.table_nutrition.length > 0) {
                                                item.table_nutrition.forEach(nutrition => {
                                                     if (nutrition.id == null) {
                                                        this.catalogService.postTableNutrition(nutrition.name, nutrition.quant, nutrition.value, null, data_item.id).subscribe()
                                                    }
                                                    else{
                                                        if (nutrition.id == null) {
                                                            this.catalogService.postTableNutrition(nutrition.name, nutrition.quant, nutrition.value, null, data_item.id).subscribe()
                                                        }
                                                        else{
                                                            this.catalogService.patchTableNutrition(nutrition).subscribe()
                                                        }
                                                    }
                                                });
                                            }

                                            this.loading_count -= 1;
                                            if (this.loading_count == 0) {
                                                // this.router.navigate(['/application/catalog'])
                                            }
                                        })
                                }

                            });
                        }

                    })

                }
            })
        })

        this.router.navigate(['/application/catalog'])
    }

    postCatalog() {
        if (this.loading) return

        this.loading = true;


        //  POST CATALOG
        this.catalogService.postCatalog(this.catalog).subscribe(data_catalog => {

            this.catalog.section.forEach(section => {
                this.loading_count += 1;

                this.postImages(data_catalog);

                //  POST SECTION
                this.postSection(section, data_catalog)

            })
        })
    }


    getImages() {
        this.catalogService.getCatalogImages(this.catalog_id).subscribe(data => {
            this.carrouselImages = data;
            this.catalog.id = data.id;
        })
    }


    postImages(catalog) {

        if (this.list_delet_carrousel != null && this.list_delet_carrousel.length > 0) {
            this.list_delet_carrousel.forEach(img => {
                this.catalogService.deleteCatalogImages(img).subscribe(data => {
                    // console.log(data);
                })
            });
        }

        for (let image of this.carrouselImages) {

            if (image?.id == null) {
                this.catalogService.postCatalogImages(image.icon, catalog.id).subscribe(data => {
                    // console.log(data)
                })
            }
            else {
                this.catalogService.patchCatalogImages(image).subscribe(data => {
                    // console.log(data)
                })
            }
        }
    }

    postSection(section, catalog) {
        //  POST SECTION
        this.sectionService.postSection(section.name, catalog.id, section.morning_period, section.afternoon_period, section.night_period, section.dawn_period, section.order).subscribe(data_section => {

            section.products.forEach(item => {

                //  POST ITEM
                this.catalogService.postCatalogItem(data_section.id, item.name,
                    item.description, item.price, item.image).subscribe(data_item => {

                        if (item.classification_food != null && item.classification_food.length > 0) {
                            item.classification_food.forEach(food => {
                                this.catalogService.postClssificationFood(food.name, data_item.id).subscribe(data => {
                                })
                            });
                        }

                        if (item.table_nutrition != null && item.table_nutrition.length > 0) {
                            item.table_nutrition.forEach(nutrition => {
                                if (nutrition.id == null) {
                                    this.catalogService.postTableNutrition(nutrition.name, nutrition.quant, nutrition.value, null, data_item.id).subscribe()
                                }
                                else{
                                    this.catalogService.patchTableNutrition(nutrition).subscribe()
                                }
                            });
                        }

                        this.loading_count -= 1;
                        if (this.loading_count == 0) {
                            setTimeout(() => {
                                this.router.navigate(['/application/catalog'])
                            }, 500);
                        }

                    })
            });
        })
    }

    deleteCatalogProduct(id) {
        this.catalogService.deleteCatalogProduct(id).subscribe(data => {
        })
    }

    deleteSection(id) {
        this.sectionService.deleteSection(id).subscribe(data => {
        })
    }

    deleteClssificationFood(id) {
        this.catalogService.deleteClssificationFood(id).subscribe(data => {
        })
    }

    patchTableNutrition(obj) {
        this.catalogService.patchTableNutrition(obj).subscribe(data => {
        })
    }

    async onFileChanged(event, indexX, indexY) {
        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {
                    this.catalog.section[indexX].products[indexY].image = this.compressed_image[0]
                    // this.items[indexX].image = this.compressed_image[0]
                    // this.items[indexX]['edited'] = true;
                    this.compressed_image = []

                }
            });
        }
    }

    async onFileLogo(event) {
        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {
                    this.catalog.logo_menu = this.compressed_image[0]
                    // this.items[indexX].image = this.compressed_image[0]
                    // this.items[indexX]['edited'] = true;
                    this.compressed_image = []

                }
            });
        }
    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {
                this.compressed_image.push(response)
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );
    }

    openView() {
        this.modalRef = this.modalService.show(ModalCatalogPreviewComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered popup-transparent',
            containerClass: '',
            animated: false,
            data: {
                listImage: this.catalog.section,
                isEdit: true
            }
        })
    }

    detailProduct(obj, status, position_section, position_product) {
        this.modalRef = this.modalService.show(PopupEditProdutCatalogComponent, {
            backdrop: 'static',
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-lg',
            containerClass: '',
            animated: false,
            data: {
                product: obj,
                type: status
            }
        })
        this.modalRef.content.action.subscribe((result: any) => {
            if (result.status == 'yes') {
                console.log(result.product);
                console.log(result.delet);

                this.list_delet_food = result.delet;

                if (result?.product?.id != null)
                    this.catalog.section[position_section].products[position_product] = result.product
                else {
                    this.refresh_table = false;

                    this.catalog.section[position_section].products.push(result.product)
                    setTimeout(() => {
                        console.log(this.catalog.section[position_section].products);
                        this.refresh_table = true;
                    }, 300);
                }
            }
        });

    }

    removeImage(img) {
        let index_img = this.carrouselImages.findIndex(el => el.id == img.id)

        if (img.id != null) {
            this.list_delet_carrousel.push(img)
        }

        this.carrouselImages.splice(index_img, 1)
    }

    changedImagesListener(files: FileList) {

        if (files.length > 0) {
            // const preview = document.getElementById('images-preview');
            for (let i in files) {
                const file: File = files[i];
                const reader: FileReader = new FileReader();

                reader.onload = () => {
                    const imgString = String(reader.result);
                    this.carrouselImages.push({ icon: imgString });
                    // const image: HTMLImageElement = new Image();
                    // image.src = imgString;
                    // image.className = "image-grid"
                    // preview.insertBefore(image, preview.firstChild);
                }
                // console.log(this.carrouselImages);

                reader.readAsDataURL(file);
            }
        }
    }
}

export interface Catalog {
    id: number,
    name: string,
    description: string,
    logo_menu: string,
    informative_text: string,
    link: string,
    footer_text: string,
    section: Section[],
}

export interface Section {
    name: string,
    morning_period: boolean,
    afternoon_period: boolean,
    night_period: boolean,
    dawn_period: boolean,
    products: Product[],
    id: number,
    catalog_id: number,
    order: number,
}

export interface Product {
    id: number,
    name: string,
    price: number,
    image: string,
    description: string,
    section: number,
}