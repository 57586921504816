<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title" [style.color]="color_primary">
            <b> {{title}} </b>
        </h1>

    </div>

    <input mat-input [(ngModel)]="name" placeholder="Escreva aqui o nome da seção"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input">

    <div style="position: relative; width: 100%; display: flex; justify-content: center; align-items: center;">
        <img [src]="url_image" (click)="filePages.click()" class="image_logo" *ngIf="url_image != ''">

        <div class="image_logo div_select" (click)="filePages.click()" *ngIf="url_image == ''">
            <svg enable-background="new 0 0 24 24" height="35px" viewBox="0 0 24 24" width="35px" fill="#000000">
                <g>
                    <rect fill="none" height="24" width="24" />
                </g>
                <g>
                    <path
                        d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
                </g>
            </svg>
            Selecione a imagem
        </div>
        <!-- <input mat-input [(ngModel)]="name_image" placeholder="Selecione uma imagem da seção" (click)="filePages.click()"
                [style.paddingLeft]="url_image != '' ? '85px' : '20px'"
                [ngStyle]="{'border-color': warningMap.get('image')? 'red' : ''}" class="input" readonly>
    
            <svg width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" *ngIf="url_image != ''" class="btn_delet" (click)="deletImage()">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
                    <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                </g>
            </svg> -->

    </div>

    <input #filePages type="file" accept="image/*" (change)="addImage($event)" multiple="multiple"
        style="display: none;">

    <h1 class="modal-title" [style.color]="color_primary">
        <b> Sub Seção </b>
    </h1>

    <input #filePages type="file" accept="image/*" (change)="addImage($event)" multiple="multiple"
        style="display: none;">

    <input mat-input [(ngModel)]="subcategory" placeholder="Escreva aqui o nome da sub seção"
        [ngStyle]="{'border-color': warningMap.get('subcategory')? 'red' : ''}" class="input">

    <button mat-button class="btn_add button-cor" [style.background]="color_primary"
        style="width: 150px; height: 35px; margin: 0 0 20px auto; border-radius: 10px; color: white;" (click)="setTable()">
        Adicionar
    </button>

    <div class="div-table">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Icon </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde">
                        <img *ngIf="element.icon != null" [src]="element.icon" style="width: 40px; object-fit: cover; aspect-ratio: 1/1; border-radius: 5px;">
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header> Nome </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="div-borde"> {{element.name}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="title-borde" mat-sort-header style="max-width: 100px;"> Ações </div>
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="div-borde"
                        style="max-width: 100px; padding: 5px !important; display: flex; justify-content: center;">
                        <div (click)="remover(element, i)">
                            <img src="/assets/icon/icon_excluir.png" matTooltip="Remover" class="icon view-red">
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center;"> Nenhuma subcategoria selecionada. </td>
            </tr>
        </table>
    </div>

    <div style="width: 100%; display: flex; justify-content: space-evenly; 
        align-items: center; text-align: center; margin-bottom: 15px;">

        <button mat-button class="btn_add button-cor" [style.background]="color_primary"
            style="width: 150px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;" (click)="close()">
            Fechar
        </button>

        <button mat-button class="btn_add button-cor" [style.background]="color_primary"
            style="width: 150px; height: 35px; margin: 20px 0; border-radius: 10px; color: white;" (click)="save()">
            {{button_text}}
        </button>
    </div>

</div>