import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { CampaignService } from 'src/app/service/campaign.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-preview-post',
  templateUrl: './preview-post.component.html',
  styleUrls: ['./preview-post.component.css']
})
export class PreviewPostComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private storageService: StorageService,
    private campaignService: CampaignService
  ) { }

  campaign;
  color_primary

  list_post:any = []
  page: number = 1;

  ngOnInit(): void {
    console.log(this.campaign);
    
    this.color_primary = this.storageService.establishment.color;

    this.getCampaignsPosts()
  }

  getCampaignsPosts() {
    this.campaignService.getCampaignsPosts(this.page, this.campaign.id, 10).subscribe(data => {
      this.list_post = this.list_post.concat(data.results)
      
      if (data.next != null) {
        this.page =+ 1; 
        this.getCampaignsPosts()
      }
      else{
        this.campaign['post'] = this.list_post;
      }

    },
    error => {
    })
  }
}
