<div class="screen">

    <div class="card_shopping_cart" [ngStyle]="{'min-height': web ? '100vh' : 'auto'}" *ngIf="check_login">
        <app-login-user style="width: 100%;" [preview]="preview_campaign" [header]="header" [catalog]="null"
            (newItemEvent)="login($event)"></app-login-user>
    </div>

    <div *ngIf="!check_login && course != null && list_videos != null" class="card_curse">
        <div class="border-gray space-between-center" style="width: 100%;">
            <h3 style="margin: 0; color: white;"> {{ course.title }} </h3>
        </div>
        <div class="description"> {{ course.description }} </div>

        <div class="border-gray space-between-center" style="width: 100%; margin-top: 20px;">
            <h3 style="margin: 0; color: white; width: 100%; display: flex; justify-content: space-between;">
                Vídeos
                <span style="font-size: 14px; font-weight: 600; margin-left: auto;">
                    {{ list_videos[position_video] != null && list_videos[position_video].title ?
                    list_videos[position_video].title : ''}}
                </span>
            </h3>
        </div>

        <div *ngFor="let video of list_videos; let i = index" class="options_video">
            <div class="title_video" (click)="selectVideo(video)"
                [ngClass]="{'video_true': video.is_finished == true, 'video_false': video.is_finished == false}">
                {{ video.title }} {{ video.is_finished ? '- Completo' : (position_video == i ? '- assistindo' : '') }}
            </div>
        </div>
    </div>


    <div style="width: 100%;" *ngIf="reload">

        <!-- VIMEO -->
        <div id="video_vimeo" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
            'video-mobile' : urlPage != 'redirect'}"></div>

        <!-- YOUTUBE -->
        <div id="video_youtube" class="screen_video" [ngClass]="{'video-web' : urlPage =='redirect',
            'video-mobile' : urlPage != 'redirect'}"></div>

    </div>

    <div style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center;">
        <!-- <div style="width: 50%; margin: 10px 0 0; display: flex; justify-content: flex-start;">
            <button [disabled]="check_login || list_videos[position_video-1] == null || list_videos[position_video-1].is_finished == false"
            *ngIf="position_video > 0"
            (click)="backVideo()" [style.background]="header.back" [style.color]="header.color" mat-button style="margin: 0 10px 0 0; display: flex; align-items: center; justify-content: space-evenly;">
                <span> Voltar </span>
            </button>
        </div>
    
        <div style="width: 50%; margin: 10px 0 0; display: flex; justify-content: flex-end;">
            <button [disabled]="check_login || list_videos[position_video+1] == null || list_videos[position_video+1].is_finished == false"
                (click)="nextVideo()" [style.background]="header.back" [style.color]="header.color" mat-button style="margin: 0 10px 0 0; display: flex; align-items: center; justify-content: space-evenly;">
                <span> Próximo </span>
            </button>
        </div> -->
        
        <div *ngIf="certificate" style="width: 100%; margin: 10px 0 0; display: flex; justify-content: flex-end;">
            <button (click)="gerarCertificate()" [style.background]="header.back" [style.color]="header.color" mat-button style="margin: 0 10px 0 0; display: flex; align-items: center; justify-content: space-evenly;">
                <span> Baixar Certificado </span>
            </button>
        </div>
    </div>


</div>