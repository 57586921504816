import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActiveCampaign, Campaign } from "../model/campaign";
import { CustomEncoder } from './custom-encoder';

@Injectable({
    providedIn: 'root'
})

export class QrCodeService {

    constructor(private http : HttpClient) { }

    establishment = "id_establishment"

    getQrCodes(page): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `token ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set(`establishment`, localStorage.getItem(this.establishment))
                .set(`page`, page)
        };

        return this.http.get<any>(AppConfig.path + `core/qrcode/`, options);
    }

    getQrCodesSlug(slug, token, device): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('User', `${token}`), // token user
            params: new HttpParams().set(`slug`, slug)
        }; 

        if(device != null && device != ""){
            options.params = options.params.append(`device`, device) // device 1 ios / 2 android
        }
            
        return this.http.get<any>(AppConfig.path + `core/qrcode/slug/`, options);
    }

    getQrCodesSlugOnly(slug): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set(`slug`, slug)
        }; 
            
        return this.http.get<any>(AppConfig.path + `core/qrcode/slug_only/`, options);
    }

    getQrCodesByEstablishment(id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`),

            params: new HttpParams()
                .set(`establishment`, id)
            }; 

        return this.http.get<any>(AppConfig.path + `core/qrcode/`, options);
    }

    getQrCodesById(id): Observable<any> {

        return this.http.get<any>(AppConfig.path + `core/qrcode/${id}`);
    }

    postQrCode(name): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`name`, name)
      
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/qrcode/`, body, { headers })
    }

    getQrCodeCampaign(page): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set(`page`, page)
            }; 

        return this.http.get<any>(AppConfig.path + `core/qrcode-campaign/`, options)
    }

    postQrCodeCampaign(campaign, qr_code): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`campaign`, campaign)
            .set(`qr_code`, qr_code)
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/qrcode-campaign/`, body, { headers })
    }

    patchQrCodeCampaign(id, campaign, qr_code): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`campaign`, campaign)
            .set(`qr_code`, qr_code)
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/qrcode-campaign/${id}/`, body, { headers })
    }
    
    deleteQrCodeCampaign(id): Observable<any>{

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.delete<any>(AppConfig.path + `core/qrcode-campaign/${id}/`, { headers })
    }

    patchNewQrCode(qr_code, id, name, store, establishment, adress, information, link, registration_config): Observable<any>{

        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`qr_code`, encodeURI(qr_code))
            .set(`name`, name)
            .set(`establishment`, establishment)
            .set(`qr_code_link`, link)

            if( store != null && store != ""){
                body = body.append('store', store);
            }
            if( adress != null && adress != ""){
                body = body.append('adress', adress);
            }
            if( information != null && information != ""){
                body = body.append('information', information);
            }
            if( registration_config != null && registration_config != ''){
                body = body.append('registration_config', registration_config);
            }
            if( registration_config == -1){
                body = body.append('registration_config', '');
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/qrcode/${id}/`, body.toString(), { headers })
    }

    patchQrCode(id, name, store, establishment, adress, information, link, registration_config): Observable<any>{

        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`establishment`, establishment)
            .set(`qr_code_link`, link)

            if( name != null && name != ""){
                body = body.append('name', name);
            }
            if( store != null && store != ""){
                body = body.append('store', store);
            }
            if( adress != null && adress != ""){
                body = body.append('adress', adress);
            }
            if( information != null && information != ""){
                body = body.append('information', information);
            }
            if( registration_config != null && registration_config != ''){
                body = body.append('registration_config', registration_config);
            }
            if( registration_config == -1){
                body = body.append('registration_config', '');
            }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/qrcode/${id}/`, body.toString(), { headers })
    }


    EditNextQrcode(qr_code, next_qr_code, first_of_flow): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
        
            .set(`qr_code_id`, qr_code)

            if(next_qr_code == -1) body = body.set(`next_qr_code`, '')
            else body = body.set(`next_qr_code`, next_qr_code)

            if( first_of_flow != null){
                body = body.set('is_the_first_of_the_flow', first_of_flow);
            }
        
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + `core/next-qr-code/`, body, { headers })
    }


    statusPublishQrCode(id, active): Observable<any>{
        let body = new HttpParams()
        if (active != null) {
            body = body.append(`active`, active)
        }

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/qrcode/${id}/`, body.toString(), { headers })
    }

    deleteQrCode(id): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`deleted`, 'true')

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  })
            .set('Authorization', `token ${localStorage.getItem('token')}`)

        return this.http.patch<any>(AppConfig.path + `core/qrcode/${id}/`, body.toString(), { headers })
    }

    nextQrcodeFlow(qr_code): Observable<any>{
        let body = new HttpParams({encoder: new CustomEncoder()})
            .set(`qr_code_id`, qr_code)
        
        var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        
        if(localStorage.getItem('tokenUser') != null)   // TOKEN USER CAMPAIGN
            headers = headers.set('user', `${localStorage.getItem('tokenUser')}`)

        else if(localStorage.getItem('tokenAnonimo') != null) // TOKEN ANONIMO
            headers = headers.set('user', `${localStorage.getItem('tokenAnonimo')}`)

        return this.http.post<any>(AppConfig.path + `core/next-qr-code-flow/`, body, { headers })
    }


    getRelatorio(date_from, date_to, qr_code_id): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
            .set('Authorization', `token ${localStorage.getItem('token')}`)
    
        return this.http.get<any>(AppConfig.path + `core/generate-flow-report/?establishment=${localStorage.getItem(this.establishment)}&date_from=${date_from}&date_to=${date_to}&qrcode=${qr_code_id}`,
             { headers, responseType: 'blob' as 'json', observe: 'response' })
    }
}
