import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'app-previw-order',
  templateUrl: './previw-order.component.html',
  styleUrls: ['./previw-order.component.css']
})
export class PreviwOrderComponent implements OnInit {

  constructor(
		public modalRef: MDBModalRef,
  ) { }

  order;
  color_primary;
  _type;

  displayedColumns: string[] = ['name', 'price', 'qtd'];
  dataSource = new MatTableDataSource<any>();

  status_aux = 'wait';

  ngOnInit(): void {
    if(this._type == 'tabloide'){
      this.dataSource.data = this.order.products_tabloide;
    }
    else{
      this.dataSource.data = this.order.products;
    }
  }

  close(){
    // this.action.next('yes')
    this.modalRef.hide();
  }

  convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
  }

}