<div class="div-title">
    <svg class="logo" viewBox="0 0 512.000000 512.000000">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" 
            [style.fill]="color_primary" stroke="none">
            <path d="M2824 5110 c-54 -22 -54 -18 -54 -503 0 -271 4 -455 10 -466 16 -31 39 -36 220 -41 l175 -5 0 -50 0 -50 -357 -3 -358 -2 -1 257 c0 292 -5 318 -59 345 -32 17 -88 18 -713 18 -596 0 -682 -2 -708 -16 -54 -28 -59 -56 -59 -344 l0 -260 -123 0 c-159 0 -208 -14 -281 -81 -57 -52 -103 -131 -125 -214 -6 -23 -42 -250 -81 -505 -39 -256 -82 -535 -95 -620 -14 -85 -65 -420 -115 -745 -49 -324 -93 -602 -96 -618 l-6 -27 2562 0 2562 0 -6 27 c-3 16 -37 235 -76 488 -39 253 -91 593 -116 755 -25 162 -76 502 -115 755 -38 253 -76 485 -85 515 -34 117 -92 193 -182 237 -57 28 -59 28 -302 33 l-245 5 0 50 0 50 178 5 179 5 24 28 24 28 0 449 0 449 -24 28 -24 28 -754 2 c-414 1 -763 -2 -774 -7z m-574 -1530 l0 -820 -560 0 -560 0 0 820 0 820 560 0 560 0 0 -820z m1964 -14 c48 -20 100 -70 119 -113 9 -21 30 -141 48 -268 36 -260 34 -293 -24 -353 -68 -71 -24 -67 -726 -70 -447 -2 -651 1 -689 9 -46 9 -64 20 -107 63 -43 42 -55 62 -64 105 -14 67 -14 429 0 485 13 53 58 110 107 136 35 18 64 19 670 20 515 0 639 -3 666 -14z m-1084 -1031 c38 -20 50 -64 50 -185 0 -187 -13 -200 -210 -200 -189 0 -200 11 -200 200 0 187 12 199 203 200 95 0 135 -4 157 -15z m631 -14 l29 -29 0 -142 0 -142 -29 -29 -29 -29 -142 0 -142 0 -29 29 -29 29 0 142 0 142 29 29 29 29 142 0 142 0 29 -29z m608 3 l31 -26 0 -148 0 -148 -31 -26 c-30 -25 -35 -26 -167 -26 -192 1 -202 10 -202 195 0 122 12 174 47 194 10 6 76 11 155 11 132 0 137 -1 167 -26z m-1222 -617 c26 -26 28 -33 31 -137 5 -133 -2 -175 -33 -206 -23 -23 -30 -24 -169 -24 -139 0 -146 1 -173 24 l-28 24 0 145 c0 139 1 146 24 171 31 33 42 35 193 33 125 -2 128 -3 155 -30z m582 22 c51 -18 61 -51 61 -194 0 -193 -2 -194 -203 -195 -144 0 -149 1 -172 25 -24 23 -25 29 -25 165 0 82 5 150 11 161 19 36 66 48 185 49 61 0 125 -5 143 -11z m616 -3 c18 -8 38 -23 44 -34 6 -12 11 -80 11 -162 0 -136 -1 -142 -25 -165 -23 -24 -28 -25 -169 -25 -139 0 -146 1 -173 24 l-28 24 0 145 c0 139 1 146 24 171 29 31 40 33 174 35 73 1 119 -4 142 -13z"/>
            <path d="M1332 3993 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
            <path d="M1332 3583 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
            <path d="M1332 3173 l3 -98 355 0 355 0 3 98 3 97 -361 0 -361 0 3 -97z"/>
            <path d="M2 672 l3 -407 33 -67 c38 -77 92 -130 171 -167 l56 -26 2295 0 2295 0 56 26 c79 37 133 90 171 167 l33 67 3 407 3 408 -2561 0 -2561 0 3 -408z m2619 137 c89 -30 139 -101 139 -196 0 -72 -28 -125 -88 -168 -38 -26 -52 -30 -112 -30 -60 0 -74 4 -112 30 -60 43 -88 96 -88 168 0 69 25 125 72 160 60 45 125 57 189 36z"/>
        </g>
    </svg>
    Vendas - Aqui você cadastra, busca e edita seus produtos
</div>

<div class="cards">
    <div class="card">
        <h2> Subtotal </h2>
        <div class="price"> R$ 00,00 </div>
    </div>

    <div class="card">
        <h2> Desconto </h2>
        <div class="price"> R$ 00,00 </div>
    </div>

    <div class="card">
        <h2> Total </h2>
        <div class="price"> R$ 00,00 </div>
    </div>

    <div class="card">
        <h2> Recebido </h2>
        <div class="price"> R$ 00,00 </div>
    </div>

    <div class="card">
        <h2> Troco </h2>
        <div class="price"> R$ 00,00 </div>
    </div>

    <div class="empty"></div>
    <div class="empty"></div>
</div>

<div class="line"></div>

<div class="tools">
    <span> Ferramentas </span>

    <div class="option">
        <svg class="icons" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m330 -653 l0 -189 58 -7 c126 -15 281 -62 380 -115 138 -73 275 -205 347 -335 42 -75 92 -223 101 -298 l7 -57 -223 2 -223 3 -14 54 c-29 117 -117 238 -220 307 -55 36 -167 84 -198 84 -13 0 -15 -76 -15 -553 l0 -553 73 -18 c120 -31 362 -113 441 -151 192 -91 342 -265 390 -452 27 -102 37 -244 26 -346 -36 -312 -227 -573 -522 -712 -88 -41 -221 -81 -303 -90 -116 -12 -104 10 -107 -198 l-3 -182 -125 0 -125 0 -5 184 -5 185 -90 13 c-342 48 -645 248 -764 503 -35 74 -67 196 -74 282 l-6 58 224 0 224 0 6 -37 c13 -73 47 -162 84 -220 87 -133 203 -210 374 -247 l27 -6 0 569 0 569 -82 22 c-134 35 -344 108 -423 145 -208 99 -363 284 -412 492 -25 106 -25 341 0 432 43 158 101 262 207 371 159 162 359 258 613 291 l97 12 0 189 0 188 130 0 130 0 0 -189z"/>
                <path d="M2260 3874 c-248 -65 -390 -241 -390 -486 0 -200 70 -324 240 -424 65 -38 174 -86 248 -109 l22 -6 0 520 0 521 -32 -1 c-18 0 -58 -7 -88 -15z"/>
                <path d="M2640 1869 l0 -532 51 7 c150 20 299 114 381 240 58 89 80 171 80 291 0 211 -78 332 -282 439 -70 37 -194 86 -216 86 -12 0 -14 -86 -14 -531z"/>
            </g>
        </svg>

        Calculadora
    </div>

    <div class="option">
        <svg class="icons" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m315 -427 c39 -21 51 -43 333 -613 l294 -591 181 -26 c154 -23 737 -107 1026 -149 121 -17 179 -63 188 -149 3 -34 -1 -54 -19 -85 -13 -25 -190 -204 -423 -429 -220 -213 -426 -413 -458 -444 l-58 -57 111 -643 c67 -390 108 -652 104 -669 -9 -38 -50 -89 -86 -108 -72 -37 -88 -30 -697 290 -310 163 -568 296 -574 296 -10 0 -33 -12 -724 -374 -388 -203 -438 -226 -480 -226 -91 0 -153 63 -153 156 0 28 5 76 10 105 6 30 26 146 46 259 19 113 48 284 65 380 17 96 44 256 61 354 l30 179 -108 106 c-60 59 -272 265 -471 459 -270 263 -365 362 -373 388 -23 70 18 161 84 189 26 11 403 68 1229 187 l77 11 37 76 c90 189 533 1072 546 1091 21 30 85 60 127 60 19 0 53 -10 75 -23z"/>
            </g>
        </svg>
        
        Cliente
    </div>

    <div class="option">
        <svg class="icons" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m972 -1191 l308 -308 0 -618 0 -618 101 3 100 3 2 205 2 204 90 -2 c143 -5 238 -62 291 -177 l24 -50 0 -686 c0 -791 5 -746 -90 -841 -96 -96 37 -89 -1571 -86 l-1404 3 -54 30 c-67 37 -115 92 -141 163 -20 53 -20 71 -18 741 l3 686 30 54 c37 67 103 126 163 145 55 17 66 19 149 17 l63 -1 2 -202 3 -203 98 -3 97 -3 0 926 0 925 722 0 723 0 307 -307z"/>
                <path d="M1745 3988 c-3 -7 -4 -377 -3 -823 l3 -810 818 -3 817 -2 0 515 0 515 -305 0 -305 0 -2 308 -3 307 -508 3 c-401 2 -509 0 -512 -10z m815 -503 l0 -105 -307 2 -308 3 -3 89 c-2 49 -1 95 2 103 4 10 66 13 311 13 l305 0 0 -105z m620 -410 l0 -105 -620 0 -620 0 0 98 c0 54 3 102 7 105 3 4 282 7 620 7 l613 0 0 -105z m0 -415 l0 -100 -617 2 -618 3 -3 98 -3 97 621 0 620 0 0 -100z"/>
                <path d="M1122 2048 l3 -103 203 -3 202 -2 0 105 0 105 -205 0 -206 0 3 -102z"/>
                <path d="M1330 1430 l0 -100 1230 0 1230 0 0 100 0 100 -1230 0 -1230 0 0 -100z"/>
            </g>
        </svg>
        
        Imprimir
    </div>

    <div class="option">
        <svg class="icons" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                <path d="M2355 5084 c-16 -2 -73 -9 -125 -15 -349 -39 -761 -190 -1060 -386 -625 -411 -1027 -1051 -1131 -1803 -18 -124 -18 -506 0 -630 66 -475 251 -909 541 -1269 407 -506 988 -830 1650 -922 124 -18 506 -18 630 0 473 65 904 249 1265 538 504 402 834 991 926 1653 18 124 18 506 0 630 -66 475 -251 909 -541 1269 -400 497 -971 821 -1615 917 -95 14 -476 27 -540 18z m385 -684 c130 -32 136 -34 232 -80 70 -34 145 -92 196 -151 25 -29 38 -35 74 -37 75 -4 140 -23 238 -70 80 -38 109 -59 182 -132 131 -132 233 -307 296 -510 72 -234 92 -384 92 -684 l0 -209 48 -46 c76 -74 86 -103 90 -264 4 -173 -11 -220 -95 -297 -47 -43 -56 -58 -72 -117 -60 -223 -214 -393 -441 -486 -95 -39 -139 -42 -695 -42 l-540 0 -45 25 c-25 14 -57 45 -74 70 -27 39 -30 53 -30 115 0 63 3 74 34 118 54 75 99 92 245 92 135 0 178 -15 229 -77 15 -18 34 -41 43 -52 14 -18 31 -18 351 -14 241 4 346 9 367 18 69 29 82 36 132 72 28 21 63 52 76 70 36 48 69 117 61 129 -3 6 -27 9 -53 7 l-46 -3 -6 325 c-7 364 -13 423 -69 675 -52 238 -185 517 -324 680 -29 34 -36 38 -49 26 -9 -7 -19 -23 -23 -34 -24 -78 -165 -249 -269 -326 -93 -70 -283 -159 -385 -182 -33 -7 -166 -38 -258 -59 -23 -6 -54 -10 -69 -10 -15 0 -40 -4 -57 -9 -17 -5 -62 -15 -101 -21 -286 -47 -449 -112 -557 -222 -85 -86 -122 -168 -142 -318 l-11 -85 -62 -3 c-47 -2 -65 -7 -71 -20 -5 -9 -5 -69 -2 -133 6 -100 11 -123 34 -165 32 -58 98 -113 156 -130 50 -15 80 -40 80 -67 0 -27 83 -197 136 -280 97 -149 218 -272 354 -358 64 -41 163 -89 182 -89 8 0 18 -4 24 -8 13 -13 145 -40 248 -52 50 -6 256 -10 470 -9 209 0 381 -3 383 -7 5 -18 -222 -148 -327 -187 -64 -23 -151 -46 -250 -64 -108 -20 -299 -28 -313 -14 -6 6 -27 11 -46 11 -68 1 -248 46 -341 87 -302 132 -547 376 -710 709 -46 93 -53 103 -91 118 -91 37 -189 160 -241 306 -23 65 -23 66 -23 590 0 597 0 600 83 855 79 243 279 524 481 679 110 83 141 106 147 106 3 0 18 9 33 20 33 23 208 114 236 123 11 3 54 17 95 32 97 35 157 50 255 64 44 7 87 14 95 16 31 8 367 -5 410 -15z"/>
            </g>
        </svg>
    
        Suporte
    </div>

    <div class="empty"></div>
    <div class="empty"></div>
</div>

<div class="line"></div>

<div class="div_info">
    <div class="info_product">
        
        <div class="coll_busca" id="busca">
            <div class="input">
                <h2> Buscar Produto </h2>
                <input mat-input type="text">
            </div>
    
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                <div class="input w-50">
                    <h2> Quantidade </h2>
                    <input mat-input type="number">
                </div>
    
                <div class="input w-50">
                    <h2> Categoria </h2>
                    <input mat-input type="text">
                </div>
            </div>
        </div>

        <div class="line ocult" id="line"></div>

        <div class="coll_product" id="product">
            <h2> Novo Produto </h2>
            <div class="new_product">

                <img [src]="image" class="image_of_product" *ngIf="image != null" (click)="filePages.click()">

                <div class="image" *ngIf="image == null" (click)="filePages.click()">
                    <svg class="icon" viewBox="0 0 1056.000000 1056.000000">
                        <g transform="translate(0.000000,1056.000000) scale(0.100000,-0.100000)" [style.fill]="color_primary" stroke="none">
                            <path d="M672 9979 c-171 -22 -330 -158 -382 -328 -20 -62 -20 -118 -20 -4370 0 -3902 1 -4312 16 -4362 43 -146 155 -263 304 -315 l70 -24 4619 0 c4231 0 4624 1 4668 16 170 58 286 177 329 339 21 78 20 8616 -1 8697 -46 179 -210 321 -398 347 -94 13 -9105 13 -9205 0z m7070 -1521 l3 -703 703 -3 702 -2 0 -350 0 -350 -702 -2 -703 -3 -3 -702 -2 -703 -345 0 -345 0 -2 703 -3 702 -708 3 -707 2 2 348 3 347 705 5 705 5 3 703 2 702 345 0 345 0 2 -702z m-3837 -2426 c93 -47 129 -88 421 -473 56 -74 135 -177 175 -229 40 -52 188 -246 329 -430 141 -184 285 -373 321 -420 36 -47 101 -132 145 -190 79 -103 108 -142 314 -410 57 -74 138 -180 180 -235 217 -287 216 -285 294 -337 158 -103 357 -102 513 3 26 17 174 159 328 315 268 272 283 285 360 321 77 36 84 38 185 38 98 0 110 -2 173 -32 83 -40 168 -122 250 -243 32 -47 86 -125 121 -175 226 -322 467 -667 538 -769 46 -65 119 -169 163 -232 181 -257 321 -459 343 -495 31 -52 62 -145 62 -186 0 -81 -59 -158 -149 -195 l-56 -23 -3560 0 -3560 0 -55 23 c-148 62 -211 203 -164 363 16 53 164 354 529 1074 100 198 364 720 585 1160 793 1574 824 1634 869 1686 44 51 93 87 146 107 45 17 153 9 200 -16z"/>
                        </g>
                    </svg>
                    <h2 style="padding: 0;"> Clique para inserir <br> imagem do produto </h2>
                </div>

                <input #filePages type="file" accept="image/*" (change)="onFileChanged($event)" multiple="multiple" style="display: none;">

                <div class="dados">
                    <div class="input">
                        <h2> Nome Produto </h2>
                        <input mat-input type="text">
                    </div>

                    <div class="input">
                        <h2> Categoria </h2>
                        <input mat-input type="text">
                    </div>

                    <div class="input">
                        <h2> Valor </h2>
                        <input mat-input type="number">
                    </div>
                </div>
                <div class="description">
                    <h2> Descrição </h2>
                    <textarea style="resize: none;"></textarea>
                </div>
                <button mat-button class="btn_blue"> 
                    <span> <img src="../../assets/icon/icon-ok.png" class="icon"></span>
                    Adicionar Product
                </button>
            </div>
        </div>

        <div class="input coll_coupom" id="coupom">
            <div style="width: 100%; flex-wrap: wrap; display: flex; justify-content: space-between; align-items: center;">
                <h2> Adicionar Cupom </h2>

                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button style="margin: 0 5px;" value="true"> SIM </mat-radio-button>
                    <mat-radio-button style="margin: 0 5px;" value="false"> NÂO </mat-radio-button>
                </mat-radio-group>
            </div>

            <input mat-input type="text" style="width: 100%; margin: 0 0 5px;">

            <button mat-button class="btn_blue"> 
                Fechar Venda
            </button>
        </div>

        
    </div>
    <div class="div_table">
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
            <h2>Lista de Produtos</h2>
            <div style="display: flex; justify-content: space-evenly; align-items: center;">
                <button mat-button class="btn_update">
                    <span> <img src="/assets/icon/icon-refresh.png" class="icon-mini"> </span>
                    <span> Atualizar </span>
                </button>
            </div>
        </div>
        <div class="table">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> PRODUTO </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
              
                <ng-container matColumnDef="qtd">
                  <th mat-header-cell *matHeaderCellDef> Qtd </th>
                  <td mat-cell *matCellDef="let element"> {{element.qtd}} </td>
                </ng-container>
              
                <ng-container matColumnDef="valorUnid">
                  <th mat-header-cell *matHeaderCellDef> Valor Unid. </th>
                  <td mat-cell *matCellDef="let element"> {{ convertMoney(element.valorUnid)}} </td>
                </ng-container>
              
                <ng-container matColumnDef="valorTotal">
                  <th mat-header-cell *matHeaderCellDef> Valor Total. </th>
                  <td mat-cell *matCellDef="let element"> {{ convertMoney(element.valorTotal)}} </td>
                </ng-container>
    
                <ng-container matColumnDef="icons">
                    <th mat-header-cell *matHeaderCellDef> Excluir </th>
                    <td mat-cell *matCellDef="let element">
                        <svg class="icon_delet" viewBox="0 0 512.000000 512.000000">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" 
                            [style.fill]="color_primary" stroke="none">
                                <path d="M2310 5084 c-403 -48 -731 -155 -1049 -343 -586 -347 -1011 -918 -1170 -1573 -57 -234 -66 -319 -66 -603 0 -284 9 -369 66 -603 220 -906 945 -1631 1851 -1851 234 -57 319 -66 603 -66 284 0 369 9 603 66 906 220 1631 945 1851 1851 57 234 66 319 66 603 0 284 -9 369 -66 603 -214 879 -896 1583 -1775 1832 -216 61 -373 82 -639 85 -132 2 -256 1 -275 -1z m1508 -1217 l392 -392 -435 -435 -435 -435 425 -425 425 -425 -375 -375 -375 -375 -425 425 -425 425 -435 -435 -435 -435 -395 395 -395 395 430 430 c236 237 430 435 430 440 0 5 -189 199 -420 430 l-420 420 375 375 375 375 425 -425 425 -425 433 433 c237 237 434 432 437 432 3 0 182 -177 398 -393z"/>
                            </g>
                        </svg>
                        
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>